import { FunctionComponent, ReactNode } from "react"
import { Button, ButtonProps, H2, H3, P } from "@spillchat/puddles"
import { CheckIcon } from "@heroicons/react/24/outline"

type PlanDetails = {
  title: string
  subtitle: string
  formattedPrice: string
  priceDescription: ReactNode
  buttonText: string
  buttonVariant: ButtonProps["variant"]
  handleButtonClick: () => void
  buttonDisabled: boolean
  features: {
    title: string
    list: string[]
  }
}

interface UpgradePlanProps {
  plan: PlanDetails
}

export const UpgradePlanItem: FunctionComponent<UpgradePlanProps> = ({
  plan,
}) => {
  return (
    <div className="border rounded-lg p-6 lg:p-8 min-h-96 h-full">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <H3>{plan.title}</H3>
          <P>{plan.subtitle}</P>
          <hr className="my-2" />
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <H2>{plan.formattedPrice}</H2>
              <P size="xs">per person per month</P>
            </div>
            <div className="h-8">{plan.priceDescription}</div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Button
            variant={plan.buttonVariant}
            onClick={() => plan.handleButtonClick()}
            disabled={plan.buttonDisabled}
          >
            {plan.buttonText}
          </Button>
          <P>{plan.features.title}</P>
          <div className="flex flex-col gap-2">
            {plan.features.list.map((item, index) => {
              return (
                <div className="flex items-center gap-2" key={index}>
                  <CheckIcon className="text-teal-400 h-4 w-4" />
                  <P>{item}</P>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
