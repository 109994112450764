/**
 * A checkbox that renders a text input when checked.
 */
import { FunctionComponent, useState } from "react"

import {
  Checkbox,
  CheckboxProps,
} from "common/components/FormElements/Checkbox"
import { TextInput } from "common/components/FormElements/TextInput"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"

interface TextInputCheckboxProps {
  label: CheckboxProps<FormValues>["label"]
  name: string
  onChange: (value: string) => void
}

export const TextInputCheckbox: FunctionComponent<
  TextInputCheckboxProps
> = props => {
  const [showTextInput, setShowTextInput] = useState(false)

  return (
    <div className="flex gap-2 h-8 items-center">
      <Checkbox
        name={props.name}
        label={props.label}
        onChange={e => {
          setShowTextInput(e.currentTarget.checked)
          props.onChange("")
        }}
      />
      {showTextInput && (
        <TextInput
          name={props.name}
          onChange={e => {
            props.onChange(e.currentTarget.value)
          }}
          placeholder="Please specify"
          size="sm"
        />
      )}
    </div>
  )
}
