import { useForm } from "react-hook-form"
import { gql, useMutation } from "@apollo/client"
import { Form, H2, P, Button, Input } from "@spillchat/puddles"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"

import { useAuth } from "common/context/authContext"

import type { FunctionComponent } from "react"
import type { SubmitHandler } from "react-hook-form"
import type {
  UserSignUpFormSignUpUserMutation as SignUpUserData,
  UserSignUpFormSignUpUserMutationVariables as SignUpUserVariables,
} from "types/graphql"

const mutations = {
  signUpUser: gql`
    mutation UserSignUpFormSignUpUser(
      $email: String!
      $inviteCode: String!
      $hasAgreedToTsAndCs: Boolean!
      $hasAgreedToHealthData: Boolean!
      $hasConfirmedAtLeast18: Boolean!
    ) {
      signUpUser(
        email: $email
        inviteCode: $inviteCode
        hasAgreedToTsAndCs: $hasAgreedToTsAndCs
        hasAgreedToHealthData: $hasAgreedToHealthData
        hasConfirmedAtLeast18: $hasConfirmedAtLeast18
      ) {
        deviceId
        preAuthSessionId
      }
    }
  `,
}

interface UserSignUpFormValues {
  email: string
  hasAgreedToHealthData: boolean
  hasAgreedToTsAndCs: boolean
  hasConfirmedAtLeast18: boolean
}

interface UserSignUpFormProps {
  inviteCode: string
  onSubmit: (values: SignUpUserVariables) => void
  title: string
}

const formSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: "Please enter your email address",
    })
    .email({ message: "Please enter a valid email address" }),
  hasAgreedToTsAndCs: z.boolean().default(true).catch(true),
  hasAgreedToHealthData: z.boolean().default(true).catch(true),
  hasConfirmedAtLeast18: z.boolean().default(true).catch(true),
})

export const UserSignUpForm: FunctionComponent<UserSignUpFormProps> = ({
  ...props
}) => {
  const { setLoginAttemptInfo } = useAuth()

  const form = useForm<UserSignUpFormValues>({
    defaultValues: {
      email: "",
      hasAgreedToHealthData: false,
      hasAgreedToTsAndCs: false,
      hasConfirmedAtLeast18: false,
    },
    resolver: zodResolver(formSchema),
  })

  const { handleSubmit } = form

  const [signUpUser, { loading: signUpMutationLoading }] = useMutation<
    SignUpUserData,
    SignUpUserVariables
  >(mutations.signUpUser)

  const onSubmit: SubmitHandler<UserSignUpFormValues> = async values => {
    if (signUpMutationLoading) return
    const response = await signUpUser({
      variables: {
        ...values,
        hasConfirmedAtLeast18: true,
        hasAgreedToHealthData: true,
        hasAgreedToTsAndCs: true,
        inviteCode: props.inviteCode,
      },
    })

    if (!response.data || response.data.signUpUser == null) return

    await setLoginAttemptInfo({
      attemptInfo: {
        deviceId: response.data.signUpUser.deviceId,
        flowType: "USER_INPUT_CODE",
        preAuthSessionId: response.data.signUpUser.preAuthSessionId,
      },
    })

    props.onSubmit({
      ...values,
      inviteCode: props.inviteCode,
    })
  }

  return (
    <Form.Root {...form}>
      <form
        className="flex flex-col gap-4 relative"
        onSubmit={handleSubmit(onSubmit)}
      >
        <H2>Create your account</H2>
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="col-span-2">
            <Form.Field
              control={form.control}
              name="email"
              render={({ field }) => (
                <Form.Item>
                  <Form.Control>
                    <Input
                      placeholder="Email address"
                      {...field}
                      label={{ children: "Email address" }}
                    />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
          </div>
        </div>

        <P>
          I’ve read and agreed to the terms and understood the{" "}
          <a
            href="https://www.spill.chat/legals/privacy-notice"
            className="underline"
            title="The Spill privacy notice"
          >
            privacy notice
          </a>
          .
        </P>
        <Button variant="primary" type="submit">
          Create account
        </Button>
      </form>
    </Form.Root>
  )
}
