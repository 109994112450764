interface Pack {
  sessionCount: number
  price: string
  lowerBand: number
  upperBand?: number
}

export const packMap = [
  { sessionCount: 1, price: "£79", lowerBand: 1, upperBand: 10 },
  { sessionCount: 2, price: "£139", lowerBand: 11, upperBand: 30 },
  { sessionCount: 3, price: "£199", lowerBand: 31, upperBand: 50 },
  { sessionCount: 4, price: "£259", lowerBand: 51, upperBand: 70 },
  { sessionCount: 5, price: "£319", lowerBand: 71, upperBand: 100 },
  { sessionCount: 6, price: "£369", lowerBand: 101, upperBand: 130 },
  { sessionCount: 7, price: "£419", lowerBand: 131, upperBand: 160 },
  { sessionCount: 8, price: "£469", lowerBand: 161, upperBand: 200 },
  { sessionCount: 9, price: "£519", lowerBand: 201, upperBand: 250 },
  { sessionCount: 10, price: "£569", lowerBand: 251 },
]

export const getSessionPackForNumberEmployees = (
  employeeCount: number
): Pack | undefined => {
  return packMap.find(pack => {
    return (
      employeeCount >= pack.lowerBand &&
      (pack.upperBand !== undefined
        ? employeeCount <= pack.upperBand && employeeCount >= pack.lowerBand
        : true)
    )
  })
}

export const getSessionPackPrice = (sessionCount: number): Pack | undefined => {
  return packMap.find(pack => {
    return sessionCount === pack.sessionCount
  })
}
