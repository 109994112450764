import { PageHeader } from "common/components/PageHeader"

import { ExerciseCard } from "../components/ExerciseCard"
import { EXERCISES } from "../constants/exercises"

export function Exercises(): JSX.Element {
  return (
    <main className="p-0">
      <PageHeader
        title="Exercises"
        subtitle="We’ve gathered together some therapist-approved exercises to help you think about different aspects of your life more objectively."
      />

      {Object.values(EXERCISES).map(topic => {
        return (
          <section key={topic.title} className="flex flex-col gap-md">
            <h3>{topic.title}</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-md items-start">
              {topic.exercises.map((exercise, index) => (
                <ExerciseCard
                  key={`${topic.title}-${index}`}
                  exercise={exercise}
                />
              ))}
            </div>
          </section>
        )
      })}
    </main>
  )
}
