import { FunctionComponent } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"

import { NotFound404Page } from "common/components/NotFound404Page"
import { Meeting } from "features/pulse/pages/Meeting"
import { useAuth } from "common/context/authContext"

export const PulseRouter: FunctionComponent = () => {
  const { pathname, search } = useLocation()
  const { user, isUserLoading } = useAuth()

  if (isUserLoading) return <></>

  const isPulseEnabled = user?.featuresAndSettings.pulse.value ?? false

  if (!isPulseEnabled) {
    return <Navigate replace to="/" />
  }

  // Follow this suggestion to remove trailing foward slash from url
  // https://jasonwatmore.com/post/2020/03/23/react-router-remove-trailing-slash-from-urls
  return (
    <>
      <Routes>
        <Route
          path=":url*(/+)"
          element={<Navigate replace to={pathname.slice(0, -1)} />}
        />
        <Route path=":meetingUrlId/*" element={<Meeting />} />
        <Route
          path="check-in/summary"
          element={
            <Navigate to={{ pathname: "/admin/check-in/summary", search }} />
          }
        />
        <Route path="*" element={<NotFound404Page />} />
      </Routes>
    </>
  )
}

export const CheckInRouter: FunctionComponent = () => {
  const { pathname, search } = useLocation()

  // Follow this suggestion to remove trailing foward slash from url
  // https://jasonwatmore.com/post/2020/03/23/react-router-remove-trailing-slash-from-urls
  return (
    <>
      <Routes>
        <Route
          path=":url*(/+)"
          element={<Navigate replace to={pathname.slice(0, -1)} />}
        />
        <Route
          path="summary"
          element={
            <Navigate to={{ pathname: "/admin/check-in/summary", search }} />
          }
        />
        <Route path="*" element={<NotFound404Page />} />
      </Routes>
    </>
  )
}
