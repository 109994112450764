import { gql } from "@apollo/client"

export const mutations = {
  enrolEmployee: gql(`
    mutation AdminTherapySpecialisedSupportEnrol(
      $adminNote: String
      $companyPackageSettingId: ID
      $expirationDate: DateTime!
      $numberOfSessions: Float!
      $packageType: PackageType!
      $userIds: [ID!]!
    ) {
      createTherapyPackages(
        adminNote: $adminNote
        companyPackageSettingId: $companyPackageSettingId
        expirationDate: $expirationDate
        numberOfSessions: $numberOfSessions
        packageType: $packageType
        userIds: $userIds
      ) {
        id
      }
    }
  `),
}
