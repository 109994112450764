import { gql, useQuery } from "@apollo/client"
import {
  H1,
  TextArea,
  P,
  Button,
  H2,
  Checkbox,
  Indicator,
  Tooltip,
} from "@spillchat/puddles"
import { useFormContext } from "react-hook-form"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline"

import { useUser } from "common/context/userContext"
import { useAnalytics } from "common/context/analyticsContext"
import { LoadingPage } from "common/components/LoadingPage"
import { FeedbackFormFeedbackCompanyMainGroupsQuery } from "types/graphql"

import { FeedbackFormFields, FeedbackFormStepLabels } from "../feedback.types"

const queries = {
  companyMainGroups: gql`
    query FeedbackFormFeedbackCompanyMainGroups {
      user {
        company {
          mainGroups {
            name
          }
        }
      }
    }
  `,
}

type FeedbackFormFeedbackProps = {
  stepNumber: number
  onSetStep: (step: FeedbackFormStepLabels) => void
}

export const FeedbackFormFeedback = ({
  stepNumber,
  onSetStep: setStep,
}: FeedbackFormFeedbackProps): JSX.Element => {
  const user = useUser()
  const { track } = useAnalytics()
  const formMethods = useFormContext<FeedbackFormFields>()
  formMethods.watch()

  const { data, loading } =
    useQuery<FeedbackFormFeedbackCompanyMainGroupsQuery>(
      queries.companyMainGroups
    )

  const updateSelected = (
    nameOfScale: string,
    newSelectedValue: number | string
  ): void => {
    formMethods.setValue(
      nameOfScale as keyof FeedbackFormFields,
      newSelectedValue
    )
  }

  const canSubmit = [
    formMethods.getValues("messageToAdmin.message"),
    formMethods.getValues("messageToTherapist"),
    formMethods.getValues("channelReview.message"),
  ].some(value => value !== undefined && value !== "")

  const includeNameWithAdminMessage = formMethods.getValues(
    "messageToAdmin.includeName"
  )
  const includeNameWithChannelReview = formMethods.getValues(
    "channelReview.includeName"
  )

  if (loading) return <LoadingPage />

  const [oldestMainGroup, ...otherMainGroups] =
    data?.user?.company?.mainGroups ?? []

  return (
    <>
      <div className="flex flex-col gap-3">
        <H1>We're so glad to hear&nbsp;it</H1>
        <P>
          We'd love it if you could share a few words about how your experience
          has impacted you and why you'd recommend Spill. We'll only share what
          you say with people you opt in to sharing&nbsp;with.
        </P>
      </div>
      <div className="flex flex-col gap-3">
        <P size="xs" muted>
          Step {stepNumber} of 3
        </P>
        <H2>Share feedback</H2>
        <P>Give thanks to the people that made your therapy&nbsp;possible.</P>
      </div>
      <div className="flex flex-col gap-3">
        <TextArea
          id="messageToAdmin"
          className="h-24 w-full max-w-full border-0"
          placeholder="The session really helped me. Thank you guys!"
          label={{
            children: (
              <div className="flex gap-2">
                <P weight="medium">Write a note for your Spill administrator</P>
                <Indicator variant="info">Private</Indicator>
              </div>
            ),
          }}
          onChange={e => {
            updateSelected("messageToAdmin.message", e.target.value)
          }}
        />
        <div className="flex items-center justify-between gap-4">
          <div className="grow">
            <P weight="regular">
              {includeNameWithAdminMessage ? user.displayName : "Anonymous"}
            </P>
          </div>
          <div>
            <Checkbox
              id="includeName"
              value={+formMethods.getValues("messageToAdmin.includeName")}
              onCheckedChange={value =>
                updateSelected("messageToAdmin.includeName", +value)
              }
              label={{
                children: <>Include my name</>,
              }}
            />
          </div>
        </div>
      </div>
      <TextArea
        id="messageToTherapist"
        className="h-24 w-full max-w-full border-0"
        placeholder="Thanks so much for the helpful advice."
        label={{
          children: (
            <div className="flex flex-col gap-1">
              <div className="flex gap-2">
                <P weight="medium">Write a note for your therapist</P>
                <Indicator variant="info">Private</Indicator>
              </div>
              <P>
                We'll share this note and include the scores you just gave us
                alongside your name, so they can reflect on the session and
                continue&nbsp;improving.
              </P>
            </div>
          ),
        }}
        onChange={e => updateSelected("messageToTherapist", e.target.value)}
      />

      {oldestMainGroup != undefined && (
        <>
          <TextArea
            id="channelReview"
            className="h-24 w-full max-w-full border-0"
            placeholder="I’ve just had a great therapy session with Spill and would recommend it to anyone. Try booking in!"
            label={{
              children: (
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2">
                    <P weight="medium">
                      Shout out Spill in your #{oldestMainGroup.name}{" "}
                      {otherMainGroups.length > 0 ? (
                        <>
                          and{" "}
                          <Tooltip.Provider>
                            <Tooltip.Root>
                              <Tooltip.Content>
                                <P>
                                  Your company has Spill on multiple platforms,
                                  so we'll post it once in each one. The other
                                  channels we'll post to are:
                                </P>
                                <ol>
                                  {otherMainGroups.map(({ name }, i) => (
                                    <li key={i}>
                                      <P>- #{name}</P>
                                    </li>
                                  ))}
                                </ol>
                              </Tooltip.Content>
                              <Tooltip.Trigger>
                                <div className="flex">
                                  {otherMainGroups.length} other
                                  <QuestionMarkCircleIcon className="size-4" />
                                </div>
                              </Tooltip.Trigger>
                            </Tooltip.Root>
                          </Tooltip.Provider>
                          channels
                        </>
                      ) : (
                        "channel"
                      )}
                    </P>
                    <Indicator variant="ok">Public</Indicator>
                  </div>
                  <P>
                    One of the most important factors in somebody feeling
                    comfortable accessing mental health support is if it's been
                    vouched for by somebody they know.
                  </P>
                </div>
              ),
            }}
            onChange={e =>
              updateSelected("channelReview.message", e.target.value)
            }
          />
          <div className="flex items-center justify-between gap-4">
            <div className="grow">
              <P weight="regular">
                {includeNameWithChannelReview ? user.displayName : "Anonymous"}
              </P>
            </div>
            <div>
              <Checkbox
                id="includeNameChannel"
                value={+formMethods.getValues("channelReview.includeName")}
                onCheckedChange={value =>
                  updateSelected("channelReview.includeName", +value)
                }
                label={{
                  children: <>Include my name</>,
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className="flex gap-4 items-center justify-between">
        <Button variant="secondary" onClick={() => setStep("session")}>
          Back
        </Button>
        <div className="flex gap-2">
          <Button
            type="submit"
            variant="tertiary"
            onClick={() => track("Skipped Feedback Step")}
          >
            Skip
          </Button>
          <Button type="submit" disabled={!canSubmit}>
            Next
          </Button>
        </div>
      </div>
    </>
  )
}
