import { useEffect } from "react"
import { useController, useFormContext } from "react-hook-form"
import { Button, H2, P, Indicator } from "@spillchat/puddles"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"

import { TagInput } from "common/components/TagInput"
import { useBreakpoint } from "common/hooks/useBreakpoint"
import { useAnalyticsPulse } from "features/pulse/hooks/useAnalyticsPulse"

import { FEELINGS } from "../constants/emotions"

import type { MouseEventHandler } from "react"
import type { Control } from "react-hook-form"
import type { V4FieldValues } from "features/pulse/types"

type V4FeelingsQuestionProps = {
  nextQuestion: () => void
  prevQuestion: () => void
}

export function V5FeelingsQuestion({
  nextQuestion,
  prevQuestion,
}: V4FeelingsQuestionProps): JSX.Element {
  const { track } = useAnalyticsPulse()
  const { control } = useFormContext<V4FieldValues>()
  const isDesktop = useBreakpoint("md")

  useEffect(() => {
    track("User Opened Feelings Chooser")
  }, [])

  return (
    <main className="justify-center">
      <Header />

      <div className="shadow-sm bg-mono-white p-4 rounded-lg flex flex-col gap-4">
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 auto-rows-min gap-4">
          {Object.values(FEELINGS).map(({ adjective, noun, src }) => (
            <FeelingCheckbox
              key={noun}
              adjective={adjective}
              noun={noun}
              src={src}
            />
          ))}
        </div>

        <details className="col-span-4 group" open={isDesktop}>
          <summary className="summary px-0">
            <div className="grow">
              <P weight="medium">
                Something else? <Indicator variant="ok">Now sharable</Indicator>
              </P>
            </div>

            <ChevronUpIcon
              className={`hidden group-open:block ${
                isDesktop ? "group-open:hidden" : "group-open:block"
              }
              `}
            />
            <ChevronDownIcon className="group-open:hidden" />
          </summary>
          <div className="flex flex-col gap-4">
            <P>
              If the options above don't reflect what you're feeling, add up to
              3 other feelings below.
            </P>
            <OtherFeelingsInput control={control} />
          </div>
        </details>

        <footer className="flex gap-4 justify-between items-center">
          <Button onClick={prevQuestion} type="button" variant="secondary">
            Back
          </Button>
          <NextButton onClick={nextQuestion} />
        </footer>
      </div>
    </main>
  )
}

function Header() {
  const { watch } = useFormContext<V4FieldValues>()
  const mood = watch("mood")

  return (
    <div className="flex gap-2 flex-col">
      <H2>What does {mood ?? ""}/10 feel like?</H2>
      <P>
        This is private by default, you can choose what to share with your team
        before submitting.
      </P>
    </div>
  )
}

type FeelingCheckboxProps = {
  adjective: string
  noun: string
  src: string
}

function FeelingCheckbox({
  adjective,
  noun,
  src,
}: FeelingCheckboxProps): JSX.Element {
  const { track } = useAnalyticsPulse()
  const { register, watch } = useFormContext<V4FieldValues>()

  const feelings = watch("feelings")

  return (
    <label key={noun} className="cursor-pointer flex">
      <input
        className="peer opacity-0 h-0 w-0 pointer-events-none"
        disabled={!feelings.includes(noun) && feelings.length >= 3}
        type="checkbox"
        value={noun}
        {...register("feelings", {
          onChange: ({ target: { checked, value } }) => {
            // react-hook-form onChange typing is weak, hence the assertion.
            // This is what they suggest in their docs unfortunately.
            track(
              (checked as boolean)
                ? "User Selected Feeling"
                : "User Unselected Feeling",
              { Feeling: value as string }
            )
          },
        })}
      />
      <div className="bg-grey-100 flex flex-col hover:transition-all peer-checked:bg-yellow-400 hover:bg-yellow-200 items-center justify-center p-2 sm:p-4 peer-checked:bg-opacity-100 rounded-lg w-full">
        <img
          alt={`${adjective} emoji`}
          className="aspect-square h-12 sm:h-16 select-none"
          src={src}
        />
        <span className="text-sm sm:text-lg font-semibold text-center tracking-wide lowercase first-letter:uppercase">
          {adjective}
        </span>
      </div>
    </label>
  )
}

type OtherFeelingsInputProps = {
  control: Control<V4FieldValues>
}

function OtherFeelingsInput({ control }: OtherFeelingsInputProps): JSX.Element {
  const { track } = useAnalyticsPulse()
  const { getValues } = useFormContext<V4FieldValues>()
  const {
    field: { onChange, ref, value },
  } = useController<V4FieldValues, "otherFeelings">({
    name: "otherFeelings",
    control,
    defaultValue: [],
  })

  return (
    <TagInput
      maxTags={3}
      onChange={e => {
        const { otherFeelings } = getValues()
        const newOtherFeeling = e.filter(x => !otherFeelings.includes(x))[0]
        if (newOtherFeeling !== undefined) {
          track("User Added Other Feeling", { Feeling: newOtherFeeling })
        }
        onChange(e)
      }}
      ref={ref}
      size="lg"
      tags={value}
    />
  )
}

function NextButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>
}) {
  const { track } = useAnalyticsPulse()
  const { watch } = useFormContext<V4FieldValues>()

  const feelings = watch("feelings")
  const otherFeelings = watch("otherFeelings")

  return (
    <Button
      disabled={feelings.length === 0 && otherFeelings.length === 0}
      onClick={e => {
        track("User Continued From Feelings Chooser", { Feelings: feelings })
        onClick(e)
      }}
      type="button"
    >
      Continue
    </Button>
  )
}
