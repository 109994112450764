/**
 * Creates a list of recommended actions for the admin to take
 */

import { useMutation, useQuery } from "@apollo/client"
import {
  EnvelopeIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline"
import { ActionCard, H4 } from "@spillchat/puddles"
import { differenceInDays } from "date-fns"
import { FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"

import RelaxedSpilly from "common/assets/images/spilly/relaxed.svg"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useAnalytics } from "common/context/analyticsContext"
import {
  DismissRecommendedActionMutation,
  DismissRecommendedActionMutationVariables,
  RecommendedActionIcon,
  RecommendedActionsGetCompanyQuery,
  RecommendedActionsGetCompanyQueryVariables,
} from "types/graphql"

import { mutations } from "./recommended-actions.mutations"
import { queries } from "./recommended-actions.queries"

export const RecommendedActions: FunctionComponent = () => {
  const { data, loading } = useQuery<
    RecommendedActionsGetCompanyQuery,
    RecommendedActionsGetCompanyQueryVariables
  >(queries.getCompany, {
    fetchPolicy: "cache-and-network",
  })

  const [dismissAction] = useMutation<
    DismissRecommendedActionMutation,
    DismissRecommendedActionMutationVariables
  >(mutations.dimissRecommendedAction, { refetchQueries: [queries.getCompany] })

  const { track } = useAnalytics()

  const navigate = useNavigate()

  const recommendedActions = data?.user?.company?.recommendedActions ?? []

  if (loading) {
    return (
      <div className="flex flex-col flex-1 gap-6 items-center justify-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const trialPeriodEndDate = data?.user?.company?.trialPeriodEndDate

  const daysLeftInTrial =
    trialPeriodEndDate != null
      ? differenceInDays(new Date(trialPeriodEndDate), new Date())
      : 0

  const hasTrial = trialPeriodEndDate != null

  const getTrialDescription = () => {
    if (daysLeftInTrial > 0) {
      return `Your trial is ending in ${daysLeftInTrial} ${daysLeftInTrial === 1 ? "day" : "days"}. Talk to Spill to see what your options are.`
    }

    return "Your trial has ended. Talk to Spill to see what your options are."
  }

  // We can get rid of this when we have HeroIcons in Puddles!!
  const getActionIcon = (icon?: RecommendedActionIcon | null) => {
    switch (icon) {
      case RecommendedActionIcon.LIGHTBULB:
        return <LightBulbIcon />
      case RecommendedActionIcon.USER_GROUP:
        return <UserGroupIcon />
      case RecommendedActionIcon.SHIELD_CHECK:
        return <ShieldCheckIcon />
      case RecommendedActionIcon.ENVELOPE:
        return <EnvelopeIcon />
      default:
        return <EnvelopeIcon />
    }
  }

  const hasActions = recommendedActions.length > 0

  return (
    <div className="flex flex-col flex-1 gap-8 items-center">
      {hasActions || hasTrial ? (
        <>
          <H4 sectionHeader>Actions</H4>
          <div className="flex flex-col gap-4 w-full">
            {hasTrial && (
              <ActionCard
                key="TRIAL_NOTICE"
                title="Keep your team's access to Spill"
                description={getTrialDescription()}
                action={{
                  onClick: () => navigate("/admin/help"),
                  type: "request",
                }}
                icon={<EnvelopeIcon />}
              />
            )}
            <>
              {recommendedActions.map(card => {
                const expiryDate =
                  card.expiryDate != null && card.expiryDate != undefined
                    ? new Date(card.expiryDate)
                    : undefined
                const createdAt =
                  card.createdAt != null && card.createdAt != undefined
                    ? new Date(card.createdAt)
                    : undefined

                return (
                  <ActionCard
                    key={card.id}
                    title={card.title}
                    description={card.message}
                    icon={getActionIcon(card.icon)}
                    action={{
                      onClick: () => {
                        navigate(card.url)
                        track("Admin Clicks Recommended Action", {
                          actionId: card.id,
                          actionTitle: card.title,
                          actionMessage: card.message,
                        })
                      },
                      type: !card.dismissable ? "request" : "feature",
                    }}
                    expiresAt={expiryDate}
                    createdAt={createdAt}
                    dismissible={{
                      onDismiss: async () => {
                        if (!card.dismissable) return
                        await dismissAction({ variables: { id: card.id } })
                      },
                    }}
                  />
                )
              })}
            </>
          </div>
        </>
      ) : (
        <>
          <H4 sectionHeader>No actions</H4>
          <img src={RelaxedSpilly} alt="Relaxed Spilly" className="max-w-sm" />
        </>
      )}
    </div>
  )
}
