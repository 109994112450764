import { ComponentProps, FunctionComponent, useEffect } from "react"
import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet-async"
import { Button, P } from "@spillchat/puddles"

import Mood3 from "common/assets/images/moods/3.svg?react"
import Mood6 from "common/assets/images/moods/6.svg?react"
import Mood9 from "common/assets/images/moods/9.svg?react"
import { usePollWhenDocumentHasFocus } from "common/hooks/usePollWhenDocumentHasFocus"
import { useAnalyticsPulse } from "features/pulse/hooks/useAnalyticsPulse"
import { useCurrentMeetingMood } from "features/pulse/hooks/useCurrentMeetingMood"
import { CurrentTeamFeelingsChart } from "features/pulse/components/CurrentTeamFeelingsChart"
import { CurrentTeamMood } from "features/pulse/components/CurrentTeamMood"
import { FeelingsFeed } from "features/pulse/components/FeelingsFeed"
import {
  PulseResultsGetMeetingQuery as QueryData,
  PulseResultsGetMeetingQueryVariables as QueryVariables,
  PulseResultsGetPolledMeetingQuery as PolledQueryData,
  PulseResultsGetPolledMeetingQueryVariables as PolledQueryVariables,
} from "types/graphql"

export const fragments = {
  meetingFields: gql`
    fragment PulseResultsMeetingFields on Meeting {
      urlId
      redirectUrl
      ...CurrentTeamFeelingsChartMeetingFields
      ...CurrentTeamMoodMeetingFields
      ...FeelingsFeedMeetingFields
    }
    ${CurrentTeamFeelingsChart.fragments.meetingFields}
    ${CurrentTeamMood.fragments.meetingFields}
    ${FeelingsFeed.fragments.meetingFields}
  `,
  polledMeetingFields: gql`
    fragment PulseResultsPolledMeetingFields on Meeting {
      ...CurrentTeamFeelingsChartMeetingFields
      ...CurrentTeamMoodMeetingFields
    }
    ${CurrentTeamFeelingsChart.fragments.meetingFields}
    ${CurrentTeamMood.fragments.meetingFields}
  `,
}

export const queries = {
  getMeeting: gql`
    query PulseResultsGetMeeting($meetingUrlId: ID!) {
      meeting(urlId: $meetingUrlId) {
        ...PulseResultsMeetingFields
      }
    }
    ${fragments.meetingFields}
  `,
  getPolledMeeting: gql`
    query PulseResultsGetPolledMeeting($meetingUrlId: ID!) {
      meeting(urlId: $meetingUrlId) {
        ...PulseResultsMeetingFields
        ...PulseResultsPolledMeetingFields
      }
    }
    ${fragments.meetingFields}
    ${fragments.polledMeetingFields}
  `,
}

const moodIconClassName = "h-12 sm:h-16 lg:h-16 w-12 sm:w-16 lg:w-16"

interface PulseResultsProps {
  meetingUrlId: string
}

export const PulseResults: FunctionComponent<PulseResultsProps> = props => {
  const { track } = useAnalyticsPulse()
  const { data } = useQuery<QueryData, QueryVariables>(queries.getMeeting, {
    fetchPolicy: "cache-only",
    variables: { meetingUrlId: props.meetingUrlId },
  })

  const { refetch, startPolling, stopPolling } = useQuery<
    PolledQueryData,
    PolledQueryVariables
  >(queries.getPolledMeeting, {
    fetchPolicy: "cache-only",
    variables: { meetingUrlId: props.meetingUrlId },
  })

  usePollWhenDocumentHasFocus({
    pollInterval: 10000,
    refetch,
    startPolling,
    stopPolling,
  })

  const meeting = data?.meeting
  const currentMeetingMood = useCurrentMeetingMood(props.meetingUrlId)

  useEffect(() => {
    track("User Opened Pulse Summary")
  }, [])

  if (!meeting) return null

  return (
    <>
      <Helmet title="Spill | Pulse" />

      <main className="gap-sm">
        <header className="py-4 space-y-4 md:py-6 md:space-y-6 lg:py-8 lg:space-y-8 text-center">
          {/* <p>This page with update as your colleagues enter their responses.</p> */}
          <div className="flex items-center justify-center gap-lg">
            {currentMeetingMood !== null && (
              <>
                {currentMeetingMood < 6 && (
                  <Mood3 className={moodIconClassName} />
                )}
                {currentMeetingMood >= 6 && currentMeetingMood < 8 && (
                  <Mood6 className={moodIconClassName} />
                )}
                {currentMeetingMood >= 8 && (
                  <Mood9 className={moodIconClassName} />
                )}
                <h4 className="title">{currentMeetingMood.toFixed(1)}</h4>
              </>
            )}
          </div>
          <div className="space-y-4">
            <P>
              See how your team's feeling today, then{" "}
              <ContinueToMeetingButton
                className="underline"
                redirectUrl={meeting.redirectUrl}
                style={{
                  color: "inherit",
                  fontStyle: "inherit",
                  fontWeight: "inherit",
                }}
              >
                join the meeting
              </ContinueToMeetingButton>{" "}
              when you're ready.
            </P>
          </div>
        </header>
        <div className="grid grid-cols-6 gap-md">
          <div className="aspect-video sm:aspect-[2/1] col-span-6 sm:col-span-4">
            <CurrentTeamFeelingsChart meetingUrlId={meeting.urlId} />
          </div>

          <div className="col-span-6 sm:col-span-2 relative w-full aspect-video sm:h-full">
            <FeelingsFeed meetingUrlId={meeting.urlId} />
          </div>

          <div className="col-span-6 text-center">
            <Button asChild>
              <ContinueToMeetingButton redirectUrl={meeting.redirectUrl}>
                Join meeting
              </ContinueToMeetingButton>
            </Button>
          </div>
        </div>
      </main>
    </>
  )
}

interface ContinueToMeetingButtonProps extends ComponentProps<"button"> {
  redirectUrl: string
}

const ContinueToMeetingButton: FunctionComponent<
  ContinueToMeetingButtonProps
> = props => {
  const { children, redirectUrl, ...rest } = props
  const { track } = useAnalyticsPulse()

  return (
    // Ideally this should be an anchor tag
    <button
      type="button"
      onClick={() => {
        track("User Clicked Enter Meeting")
        window.open(redirectUrl, "_blank", "noreferrer")
      }}
      {...rest}
    >
      {children}
    </button>
  )
}
