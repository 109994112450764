import { Fragment, ReactElement, useEffect } from "react"
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form"
import { get, isEmpty, pick } from "lodash"

import { useSearchParams } from "common/helpers/useSearchParams"

type PathOrConfig<FormValues extends FieldValues> =
  | Path<FormValues>
  | {
      defaultValue?: PathValue<FormValues, Path<FormValues>>
      formName: Path<FormValues>
      isArray?: boolean
      searchParamName: string
    }

interface FormSearchParamsSyncProps<FormValues extends FieldValues> {
  paths: PathOrConfig<FormValues>[]
}

export const FormSearchParamsSync = <FormValues extends FieldValues>(
  props: FormSearchParamsSyncProps<FormValues>
): ReactElement<FormValues> => {
  const { getValues, setValue, watch } = useFormContext<FormValues>()
  const [searchParams, { delete: deleteSearchParam, set: setSearchParam }] =
    useSearchParams()

  const getFormName = (path: PathOrConfig<FormValues>): Path<FormValues> => {
    return typeof path === "string" ? path : path.formName
  }

  const getSearchParamName = (path: PathOrConfig<FormValues>): string => {
    return typeof path === "string" ? path : path.searchParamName
  }

  const watchedValues = pick(watch(), props.paths.map(getFormName))

  useEffect(() => {
    props.paths.forEach(path => {
      const isArray = typeof path !== "string" && path.isArray === true
      const value = searchParams[isArray ? "getAll" : "get"](
        getSearchParamName(path)
      ) as PathValue<FormValues, Path<FormValues>>
      setValue(getFormName(path), value)
    })
  }, [searchParams.toString()])

  useEffect(() => {
    props.paths.forEach(path => {
      const value = getValues(getFormName(path))
      const defaultValue = get(path, "defaultValue")
      if (!isEmpty(value)) {
        const isArray = typeof path !== "string" && path.isArray === true
        if (!isArray) setSearchParam(getSearchParamName(path), value)
        else {
          searchParams.delete(getSearchParamName(path))
          Array.from(value).forEach(v => {
            // Be good to not have to cast here
            searchParams.append(getSearchParamName(path), v as string)
          })
        }
      } else if (typeof defaultValue === "string") {
        setSearchParam(getSearchParamName(path), defaultValue)
      } else deleteSearchParam(getSearchParamName(path))
    })
  }, [JSON.stringify(watchedValues)])

  return <Fragment />
}
