import { P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import { SpillSubscriptionPlan } from "types/graphql"

import { OnboardingStep } from "./step"

export type OnboardingEnabledPlans = Extract<
  SpillSubscriptionPlan,
  "STARTER" | "TEAM" | "ESSENTIAL"
>

export type OnboardingSteps = {
  [key in OnboardingEnabledPlans]: {
    label: string
    completed: boolean
    href: string
    disabled?: boolean
  }[]
}

type OnboardingStepsProps = {
  steps: OnboardingSteps
  subscriptionPlan: OnboardingEnabledPlans
}

export const OnboardingSteps: FunctionComponent<OnboardingStepsProps> = ({
  steps,
  subscriptionPlan,
}) => {
  return (
    <div className="flex flex-col gap-4">
      {steps[subscriptionPlan]
        .filter(step => step.disabled !== true)
        .map((step, index) => {
          return (
            <Link to={step.href} key={step.label}>
              <OnboardingStep completed={step.completed}>
                <P weight="medium">
                  {index + 1}. {step.label}
                </P>
              </OnboardingStep>
            </Link>
          )
        })}
    </div>
  )
}
