import { addMinutes, format } from "date-fns"

interface ICSData {
  description?: string
  location?: string
  startsAt: Date
  title: string
  duration: number
}

export const createICSFileURL = ({
  description = "",
  location = "",
  startsAt,
  title,
  duration,
}: ICSData): string => {
  const endDate = addMinutes(startsAt, duration)

  const data = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART;VALUE=DATE:${formatDateForICS(startsAt)}
DTEND;VALUE=DATE:${formatDateForICS(endDate)}
SUMMARY:${title}
DESCRIPTION;ENCODING=QUOTED-PRINTABLE:${description.replaceAll("\n", "\\n")}
${location ? `LOCATION:${location}` : ""}
END:VEVENT
END:VCALENDAR`

  return window.URL.createObjectURL(
    new Blob([data], { type: "text/calendar;charset=utf-8" })
  )
}

const formatDateForICS = (date: Date): string => {
  return format(date, "yyyyMMdd'T'HHmmss")
}
