import { FunctionComponent } from "react"
import { useFormContext } from "react-hook-form"
import { Tag } from "@spillchat/puddles"
import { gql } from "@apollo/client"

import { SelectProps } from "common/components/FormElements/Select"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { Gender, GenderSelectQueryFieldsFragment } from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment GenderSelectQueryFields on MatchingCriteriaOptions {
      genders
    }
  `,
}

interface GenderSelectProps
  extends Omit<SelectProps<FormValues>, "options" | "register"> {
  data?: GenderSelectQueryFieldsFragment
}

export const GenderSelect: FunctionComponent<GenderSelectProps> = ({
  data,
}) => {
  const { setValue, getValues } = useFormContext<FormValues>()
  const supportedGenders = data?.genders ?? []

  const options = [
    { key: Gender.FEMALE, label: "Female", value: Gender.FEMALE },
    { key: Gender.MALE, label: "Male", value: Gender.MALE },
    { key: Gender.NON_BINARY, label: "Non-binary", value: Gender.NON_BINARY },
  ].filter(option => supportedGenders.includes(option.key))

  const onGenderSelect = (value: Gender) => {
    setValue("filter.previousCounsellorId", null)
    if (value === getValues("filter.gender")) {
      setValue("filter.gender", null)
    } else {
      setValue("filter.gender", value)
    }
  }

  return (
    <div className="flex gap-2 flex-wrap">
      {options.map(option => (
        <Tag
          key={option.value}
          onSelect={() => onGenderSelect(option.value)}
          selected={getValues("filter.gender") === option.value}
          size="xs"
        >
          {option.label}
        </Tag>
      ))}
    </div>
  )
}
