import { FEELINGS } from "features/pulse/constants/emotions"

import type { Feeling, SharedPulseResponse } from "types/graphql"

/**
 * Currently a pulse response has a warning if any of the following are true
 * - only negative feelings are shared
 * - more than 1 negative feeling is shared
 * - mood is 5 or below
 */
export function pulseResponseHasWarning(
  response: Pick<SharedPulseResponse, "feelings" | "score">
): boolean {
  const numFeelings = response.feelings.length
  // update numNegativeFeelings to ignore a feeling if it doesn't have a .connotation property
  const numNegativeFeelings = response.feelings.filter(feeling => {
    const feelingInfo = FEELINGS[feeling as Feeling]
    return feelingInfo?.connotation === "negative"
  }).length

  return (
    (numFeelings > 0 && numNegativeFeelings === numFeelings) ||
    numNegativeFeelings > 1 ||
    (typeof response.score === "number" && response.score < 6)
  )
}
