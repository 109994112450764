import { gql } from "@apollo/client"

export const queries = {
  getUser: gql`
    query PmiPageGetUser {
      user {
        id
        company {
          id
          pmi {
            id
            provider
            userInstructions
          }
        }
      }
    }
  `,
}
