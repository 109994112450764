import { gql, useQuery } from "@apollo/client"

import { FeelingsFeedItem } from "features/pulse/components/FeelingsFeedItem"

import type {
  CurrentTeamFeelingsQuery as QueryData,
  CurrentTeamFeelingsQueryVariables as QueryVariables,
} from "types/graphql"

const fragments = {
  meetingFields: gql`
    fragment FeelingsFeedMeetingFields on Meeting {
      urlId
      sharedPulseResponses {
        id
        ...FeelingsFeedItemPulseResponseFields
      }
    }
    ${FeelingsFeedItem.fragments.pulseResponseFields}
  `,
}

const queries = {
  GET_MEETING: gql`
    query CurrentTeamFeelings($meetingUrlId: ID!) {
      meeting(urlId: $meetingUrlId) {
        ...FeelingsFeedMeetingFields
      }
    }
    ${fragments.meetingFields}
  `,
}

type CurrentTeamFeelingsProps = {
  meetingUrlId: string
}

export function FeelingsFeed({
  meetingUrlId,
}: CurrentTeamFeelingsProps): JSX.Element | null {
  const { data } = useQuery<QueryData, QueryVariables>(queries.GET_MEETING, {
    fetchPolicy: "cache-only",
    variables: { meetingUrlId },
  })

  return (
    <ul className="absolute box flex flex-col-reverse gap-sm overflow-auto h-full w-full">
      {data?.meeting?.sharedPulseResponses
        .map(({ id }) => <FeelingsFeedItem key={id} pulseResponseId={id} />)
        .reverse()}
    </ul>
  )
}

FeelingsFeed.fragments = fragments
FeelingsFeed.queries = queries
