import { useForm } from "react-hook-form"
import { Button, H3, Form, P } from "@spillchat/puddles"

import { createArray } from "common/helpers/createArray"

import { OTPInput } from "./OTPInput"
import { LogoWordmark } from "./logo/LogoWordmark"

import type { FunctionComponent } from "react"

interface FieldValues {
  code: string[]
  staySignedIn: boolean
}

interface OTPInputPageProps {
  email: string
  onCancel: () => void
  onSuccess: () => void
}

export const OTPInputPage: FunctionComponent<OTPInputPageProps> = props => {
  const form = useForm<FieldValues>({
    defaultValues: {
      // Init with empty string for each character
      code: createArray(9, () => ""),
      staySignedIn: false,
    },
  })

  return (
    <Form.Root {...form}>
      <div className="fixed top-8 left-8 flex gap-2 items-center">
        <LogoWordmark />
      </div>
      <div className="min-h-screen flex items-center justify-center text-center">
        <div className="max-w-sm mx-auto">
          <div className="flex flex-col items-center gap-4">
            <div className="flex flex-col gap-4 px-4 md:px-0">
              <H3>Check your email for a&nbsp;code</H3>
              <P>
                We've sent a 9-character code to <strong>{props.email}</strong>.
                <br />
                The code expires shortly so please enter it soon.
              </P>
              <OTPInput
                align="center"
                email={props.email}
                onOTPSuccess={props.onSuccess}
                onOTPFailure={props.onCancel}
              />
            </div>
            <Button asChild variant="tertiary">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://spill.notion.site/Having-trouble-logging-in-89edca1ea7f9451386fdfb389dbcf744"
              >
                Having trouble logging in?
              </a>
            </Button>
          </div>
        </div>
      </div>
      <div className="fixed bottom-4 left-4">
        <Button variant="tertiary" onClick={() => props.onCancel()}>
          &larr; Go back
        </Button>
      </div>
    </Form.Root>
  )
}
