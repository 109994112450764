import { H3, H2, P } from "@spillchat/puddles"
import { FC } from "react"

import { config } from "config"
import { ProductUpdatesGetProductUpdatesFragment } from "types/graphql"

export const ProductUpdates: FC<{
  productUpdates?: ProductUpdatesGetProductUpdatesFragment
}> = ({ productUpdates }) => {
  if (
    productUpdates == null ||
    productUpdates.getRecentProductUpdates.length == 0
  ) {
    return null
  }
  return (
    <div className="flex flex-1 flex-col gap-8">
      <div className="flex flex-col items-start gap-3">
        <H2>More from Spill</H2>
        <P>The latest news from us</P>
      </div>
      <div className="flex gap-4 items-start self-stretch">
        {productUpdates.getRecentProductUpdates
          .slice(0, 3)
          .map(productUpdate => (
            <div
              role="presentation"
              key={productUpdate.id}
              className="flex flex-1 flex-col items-start gap-4 self-stretch cursor-pointer"
              onClick={() =>
                window.open(
                  new URL(productUpdate.link!, config.spill.webApp.baseUrl)
                )
              }
            >
              <div
                className="h-40 self-stretch rounded-xl bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: `url(${productUpdate.image})` }}
              />
              <div className="flex gap-2 flex-col self-stretch items-start">
                <H3>{productUpdate.header}</H3>
                <P>{productUpdate.subtitle ?? undefined}</P>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
