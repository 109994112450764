/**
 * Component that renders the children props only if the user has
 * a specific role set as their roleName property within their user object.
 */
import { gql, useQuery } from "@apollo/client"

import { NotAuthorisedPage } from "common/components/NotAuthorisedPage"
import {
  RequireRoleGetUserQuery as QueryData,
  RequireRoleGetUserQueryVariables as QueryVars,
  UserRole,
} from "types/graphql"

import type { FunctionComponent, PropsWithChildren, ReactNode } from "react"

export const fragments = {
  userFields: gql`
    fragment RequireRoleUserFields on User {
      id
      role
      company {
        subscriptionPlan
      }
    }
  `,
}

export const queries = {
  getUser: gql`
    query RequireRoleGetUser {
      user {
        ...RequireRoleUserFields
      }
    }
    ${fragments.userFields}
  `,
}

interface RequireRoleProps extends PropsWithChildren {
  fallback?: ReactNode
  role: string | string[]
}

export const RequireRole: FunctionComponent<RequireRoleProps> = props => {
  const { fallback = <NotAuthorisedPage /> } = props

  const { data } = useQuery<QueryData, QueryVars>(queries.getUser, {
    fetchPolicy: "cache-and-network",
  })

  const userRoles = [data?.user?.role.toLowerCase()]

  const requiredRoles = [props.role].flat().map(role => role.toLowerCase())

  if (
    requiredRoles.includes(UserRole.STANDARD.toLowerCase()) &&
    userRoles.includes(UserRole.ADMIN.toLowerCase())
  ) {
    userRoles.push(UserRole.STANDARD.toLowerCase())
  }

  for (const requiredRole of requiredRoles) {
    if (!userRoles.includes(requiredRole)) {
      return <>{fallback}</>
    }
  }

  return <>{props.children}</>
}
