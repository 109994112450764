import { FunctionComponent } from "react"
import { gql, useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import { Alert, P } from "@spillchat/puddles"

import {
  SpillSubscriptionStatus,
  UserInvitesListUserQuery,
} from "types/graphql"
import { LoadingPage } from "common/components/LoadingPage"

import { SupportTable } from "../SupportTable"

import { UserInvitesEmpty } from "./UserInvitesEmpty"

const queries = {
  getUser: gql`
    query UserInvitesListUser {
      user {
        id
        company {
          id
          subscriptionStatus
          subscriptionPlan
          billableUserCount
          pendingUserInvites {
            id
            firstName
            lastName
            inviteUrl
            userId
          }
          maxUsers
        }
      }
    }
  `,
}

export const UserInvitesList: FunctionComponent = () => {
  const { data, loading } = useQuery<UserInvitesListUserQuery>(
    queries.getUser,
    { fetchPolicy: "cache-and-network" }
  )

  const { subscriptionPlan, subscriptionStatus } = data?.user?.company ?? {}

  const hasActiveSubscription =
    (subscriptionStatus ?? SpillSubscriptionStatus.INACTIVE) ===
    SpillSubscriptionStatus.ACTIVE

  if (loading) {
    return <LoadingPage />
  }

  /**
   * We need to be able to sort the pending user invites.
   * We can't do this with the gql response as it's readonly - so let's make a copy.
   */
  const sortablePendingUsers = [
    ...(data?.user?.company?.pendingUserInvites ?? []),
  ].map(invite => ({
    id: invite.id,
    fullName: `${invite.firstName ?? ""} ${invite.lastName ?? ""}`,
    inviteUrl: invite.inviteUrl,
    userId: invite.userId,
  }))

  const companyMaxUsers = data?.user?.company?.maxUsers
  // if maxUsers is set to NULL, we treat it as infinite
  const maxInvites = companyMaxUsers ?? Number.MAX_SAFE_INTEGER

  const hasUnlimitedUserInvites =
    companyMaxUsers === null || companyMaxUsers === undefined

  const availableInvites =
    maxInvites - (data?.user?.company?.billableUserCount ?? 0)

  const canInviteUsers = hasUnlimitedUserInvites || availableInvites > 0

  return (
    <>
      <div className="flex flex-col space-y-4">
        {!hasActiveSubscription && (
          <Link to={"/admin/billing"}>
            <Alert
              title="Add payment method to share invites"
              variant="warning"
            >
              <P size="xs">
                Sign up with card or direct debit to be able to share your links
                with employees to book.
              </P>
            </Alert>
          </Link>
        )}
        {subscriptionPlan != null && (
          <>
            {data?.user?.company?.pendingUserInvites.length === 0 &&
            data?.user.company.billableUserCount <= 1 ? (
              <UserInvitesEmpty {...{ subscriptionPlan }} />
            ) : (
              <SupportTable
                data={sortablePendingUsers}
                {...{ canInviteUsers, subscriptionPlan, hasActiveSubscription }}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}
