import { gql, useMutation, useQuery } from "@apollo/client"
import { setLoginAttemptInfo } from "supertokens-auth-react/recipe/passwordless"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Form, H2, Input, P } from "@spillchat/puddles"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "sonner"

import {
  PlusOneSignUpFormIsPlusOneInviteValidQuery,
  PlusOneSignUpFormIsPlusOneInviteValidQueryVariables,
  PlusOneSignUpFormSignUpPlusOneUserMutation,
  PlusOneSignUpFormSignUpPlusOneUserMutationVariables,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"

const queries = {
  isPlusOneInviteValid: gql`
    query PlusOneSignUpFormIsPlusOneInviteValid($publicId: String!) {
      isPlusOneInviteValid(publicId: $publicId)
    }
  `,
}

const mutations = {
  signUpPlusOneUser: gql`
    mutation PlusOneSignUpFormSignUpPlusOneUser(
      $email: String!
      $invitePublicId: String!
    ) {
      signUpPlusOneUser(
        email: $email
        invitePublicId: $invitePublicId
        hasAgreedToTsAndCs: true
        hasAgreedToHealthData: true
        hasConfirmedAtLeast18: true
      ) {
        deviceId
        preAuthSessionId
      }
    }
  `,
}

const formSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: "Please enter your email address",
    })
    .email({ message: "Please enter a valid email address" }),
})

type signUpForm = z.infer<typeof formSchema>

export const PlusOneSignUpForm: React.FunctionComponent = () => {
  const { publicId } = useParams()
  const navigate = useNavigate()
  const form = useForm<signUpForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  })

  const plusOneInvitePublicId = publicId ?? ""

  const { data, loading: isInviteValidLoading } = useQuery<
    PlusOneSignUpFormIsPlusOneInviteValidQuery,
    PlusOneSignUpFormIsPlusOneInviteValidQueryVariables
  >(queries.isPlusOneInviteValid, {
    variables: { publicId: plusOneInvitePublicId },
  })

  const [signUpPlusOneUser, { loading: mutationLoading }] = useMutation<
    PlusOneSignUpFormSignUpPlusOneUserMutation,
    PlusOneSignUpFormSignUpPlusOneUserMutationVariables
  >(mutations.signUpPlusOneUser, {
    onError: () => {
      toast.error(
        "We were unable to create your account at this time. Please try again or contact us at support@spill.chat"
      )
    },
    onCompleted: async data => {
      const { deviceId, preAuthSessionId } = data.signUpPlusOneUser
      await setLoginAttemptInfo({
        attemptInfo: {
          deviceId,
          preAuthSessionId,
          flowType: "USER_INPUT_CODE",
        },
      })
      navigate(`/signup/confirm?email=${form.getValues("email")}`)
    },
  })

  if (isInviteValidLoading) {
    return <LoadingSpinner sizeInPixels={30} />
  }

  if (data?.isPlusOneInviteValid !== true) {
    return (
      <div className="flex flex-col gap-5">
        <H2>This link is not valid</H2>
        <P>Please contact us on hi@spill.chat if you think this is an error</P>
      </div>
    )
  }

  const onSubmit = (values: signUpForm) => {
    void signUpPlusOneUser({
      variables: {
        ...values,
        invitePublicId: plusOneInvitePublicId,
      },
    })
  }

  return (
    <>
      <div className="flex flex-col justify-center py-4">
        <H2>Create your account</H2>
      </div>

      <Form.Root {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <Form.Field
            control={form.control}
            name="email"
            render={({ field }) => (
              <Form.Item>
                <Form.Control>
                  <Input
                    placeholder="Email address"
                    {...field}
                    label={{ children: "Email address" }}
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
          <P size="xs">
            I’ve read and agreed to the{" "}
            <a
              href="https://www.spill.chat/legals/ts-and-cs"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              terms
            </a>{" "}
            and understood the{" "}
            <a
              href="https://www.spill.chat/legals/privacy-notice"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              privacy notice.
            </a>
          </P>
          <Button type="submit" variant="primary" loading={mutationLoading}>
            Create account
          </Button>
        </form>
      </Form.Root>
    </>
  )
}
