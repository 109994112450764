export const formatPounds = (
  value: number,
  options?: Intl.NumberFormatOptions
): string => {
  return Intl.NumberFormat("en-GB", {
    compactDisplay: "short",
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
    ...options,
  }).format(value)
}
