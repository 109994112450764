import { FunctionComponent, useEffect } from "react"
import { gql, useQuery } from "@apollo/client"
import { redirect } from "react-router-dom"
import { CountryCode } from "libphonenumber-js"
import { getCountryForTimezone } from "countries-and-timezones"
import { PACKAGE_FIELDS } from "graphql/fragments/therapy-packages.fragment"

import { useSearchParams } from "common/helpers/useSearchParams"
import { useApp } from "common/context/appContext"
import {
  Appointment,
  AppointmentBookingPageGetDataQuery as QueryData,
  AppointmentBookingPageGetDataQueryVariables as QueryVars,
  BaselineQuestion,
  BookableAppointmentType,
  Gender,
  Counsellor,
  AppointmentTypeFeature,
  PackageType,
} from "types/graphql"
import { extractPhoneNumberAndCountryCode } from "common/helpers/phone-country-codes"
import { AppointmentBookingForm } from "features/therapy/components/AppointmentBookingForm"
import { LoadingPage } from "common/components/LoadingPage"

import { fragments as AppointmentBookingPageFormSectionsFragments } from "./AppointmentBookingPageFormSections"

export const fragments = {
  queryFields: gql`
    fragment AppointmentBookingPageQueryFields on Query {
      user {
        id
        appointments {
          id
          startsAt
        }
        company {
          id
        }
        ...PackageFields
      }
      ...AppointmentBookingPageFormSectionsQueryFields
    }
    ${AppointmentBookingPageFormSectionsFragments.queryFields}
    ${PACKAGE_FIELDS}
  `,
}

export const queries = {
  getData: gql`
    query AppointmentBookingPageGetData {
      ...AppointmentBookingPageQueryFields
    }
    ${fragments.queryFields}
  `,
}

export interface FormValues {
  // Omit date as is deprecated in favour of startTime
  appointment?:
    | (Pick<Appointment, "appointmentType" | "id" | "startsAt" | "status"> & {
        counsellor: Pick<
          Counsellor,
          | "avatarUrl"
          | "bio"
          | "calendarId"
          | "firstName"
          | "gender"
          | "id"
          | "videoCallUrl"
        >
      })
    | null
  appointmentType?: BookableAppointmentType
  startTime?: string
  timeZone: string
  baselineQuestions: BaselineQuestion[]
  filter: {
    counsellorIds: string[] | null
    gender: Gender | "" | null
    language: string | null
    previousCounsellorId: string | undefined | null
    specialism: string | null
    lifeEvent: string | null
    pmiOnly: boolean | null
  }
  userInfo?: {
    boundaries: boolean
    dayOfBirth: string | undefined
    monthOfBirth: string | undefined
    yearOfBirth: string | undefined
    email: string | null | undefined
    emergencyContactName: string | null | undefined
    emergencyContactPhone: string | null | undefined
    emergencyCountryCode: CountryCode
    firstName: string | null | undefined
    lastName: string | null | undefined
    location: string | null | undefined
    notice: boolean
    phone: string | undefined
    phoneCountryCode: CountryCode
    pronouns: string | null | undefined
  }
}

export const AppointmentBookingPage: FunctionComponent = () => {
  const { appTimeZone } = useApp()
  const [searchParams] = useSearchParams()
  const appointmentTypeSearchParam = searchParams.get("appointmentType")

  const { data } = useQuery<QueryData, QueryVars>(queries.getData)

  const [yearOfBirth, monthOfBirth, dayOfBirth] =
    data?.user?.therapyProfile?.dateOfBirth?.split("-") ?? []

  const { therapyProfile } = data?.user ?? {}

  const parsedPhoneNumber = extractPhoneNumberAndCountryCode(
    therapyProfile?.phoneNumber ?? "",
    appTimeZone ? getCountryForTimezone(appTimeZone)?.id : undefined
  )
  const phoneNumber = parsedPhoneNumber?.phoneNumber
  const phoneNumberCountryInfo = parsedPhoneNumber?.countryInfo

  const parsedEmergencyPhoneNumber = extractPhoneNumberAndCountryCode(
    therapyProfile?.emergencyContactNumber ?? "",
    appTimeZone ? getCountryForTimezone(appTimeZone)?.id : undefined
  )
  const emergencyContactPhoneNumber = parsedEmergencyPhoneNumber?.phoneNumber
  const emergencyContactCountryInfo = parsedEmergencyPhoneNumber?.countryInfo

  const bereavementTypes = [
    AppointmentTypeFeature.BEREAVEMENT_CONSULTATION,
    AppointmentTypeFeature.BEREAVEMENT_SESSION,
  ]

  const parenthoodTypes = [
    AppointmentTypeFeature.PARENTHOOD_CONSULTATION,
    AppointmentTypeFeature.PARENTHOOD_SESSION,
  ]

  const appointmentType = searchParams.get(
    "appointmentType"
  ) as AppointmentTypeFeature
  const isBereavement = bereavementTypes.includes(appointmentType)
  const isParenthood = parenthoodTypes.includes(appointmentType)

  const therapyPackages = data?.user?.therapyPackages
  const bereavementPackage = therapyPackages?.find(
    packageDetails =>
      packageDetails.identifier === PackageType.BEREAVEMENT.toString()
  )
  const parenthoodPackage = therapyPackages?.find(
    packageDetails =>
      packageDetails.identifier === PackageType.PARENTHOOD.toString()
  )

  const hasLifeEvents =
    bereavementPackage !== null || parenthoodPackage !== undefined

  useEffect(() => {
    if ((isBereavement || isParenthood) && !hasLifeEvents) {
      redirect("/therapy/sessions")
    }
  }, [])

  const defaultValues: FormValues = {
    appointment: undefined,
    appointmentType: appointmentTypeSearchParam as BookableAppointmentType,
    startTime: undefined,
    timeZone: appTimeZone,
    baselineQuestions: [],
    filter: {
      counsellorIds: searchParams.getAll("counsellorId") ?? "",
      gender: (searchParams.get("gender") as Gender) ?? "",
      language: searchParams.get("language") ?? "",
      previousCounsellorId: searchParams.get("previousCounsellorId") ?? "",
      specialism: searchParams.get("specialism") ?? "",
      lifeEvent: searchParams.get("lifeEvent") ?? "",
      pmiOnly: false,
    },
    userInfo: {
      boundaries: false,
      notice: false,
      dayOfBirth,
      monthOfBirth,
      yearOfBirth,
      email: therapyProfile?.email,
      emergencyContactName: therapyProfile?.emergencyContactName,
      emergencyContactPhone: emergencyContactPhoneNumber,
      emergencyCountryCode: emergencyContactCountryInfo.countryCode,
      firstName: therapyProfile?.firstName,
      lastName: therapyProfile?.lastName,
      location: therapyProfile?.approximateLocation,
      phone: phoneNumber,
      phoneCountryCode: phoneNumberCountryInfo.countryCode,
      pronouns: therapyProfile?.pronouns,
    },
  }

  if (data?.user?.id == null) {
    return <LoadingPage />
  }

  return (
    <AppointmentBookingForm
      {...{ defaultValues }}
      userId={data?.user?.id ?? ""}
    />
  )
}
