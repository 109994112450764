import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { Combobox } from "@headlessui/react"
import cn from "classnames"
import { ChevronDownIcon } from "@heroicons/react/24/outline"

import { useFuse } from "common/hooks/useFuse"

import type { FunctionComponent } from "react"
import type { UserAccountLinkingModalUsersForPlatformFieldsFragment } from "types/graphql"
import type { FieldValues } from "./UserAccountLinkingModal.types"

interface SearchExternalUsersComboboxProps {
  options: UserAccountLinkingModalUsersForPlatformFieldsFragment[]
}

export const SearchExternalUsersCombobox: FunctionComponent<
  SearchExternalUsersComboboxProps
> = props => {
  const { setValue, watch, register } = useFormContext<FieldValues>()

  const query = watch("query")
  const pendingUser = watch("pendingUser")

  const fuse = useFuse(props.options, { keys: ["name", "email"] })

  const filteredExternalUsers = useMemo(() => {
    return query ? fuse.search(query).map(result => result.item) : props.options
  }, [query])

  return (
    <Combobox value={pendingUser} onChange={v => setValue("pendingUser", v)}>
      <div className="border-2 border-blue-200 flex flex-col rounded-md transition-all ring-0 focus:outline-none focus:ring-2 focus:ring-blue-800">
        <Combobox.Button onClick={() => setValue("pendingUser", null)}>
          <div className="h-12 flex">
            <Combobox.Input
              className="bg-transparent grow outline-none px-3"
              autoComplete="off"
              placeholder="Search name"
              {...register("query")}
              displayValue={externalUser =>
                externalUser != null &&
                typeof externalUser == "object" &&
                "name" in externalUser &&
                "email" in externalUser
                  ? `${String(externalUser.name)} (${String(externalUser.email)})`
                  : ""
              }
            />
            <div className="h-12 w-12 flex items-center justify-center">
              <ChevronDownIcon className="size-5" />
            </div>
          </div>
        </Combobox.Button>
        <Combobox.Options
          className={cn("max-h-48 overflow-y-scroll", {
            "pb-1.5": filteredExternalUsers.length > 0,
          })}
        >
          {filteredExternalUsers.length === 0 && (
            <div className="flex gap-3 items-center px-3 py-1.5">
              No results
            </div>
          )}
          {filteredExternalUsers.map(externalUser => (
            <Combobox.Option key={externalUser.id} value={externalUser}>
              <div className="cursor-pointer flex gap-3 items-center px-3 py-1.5 hover:bg-blue-100">
                <span>{externalUser.name}</span>
                <span>({externalUser.email})</span>
              </div>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}
