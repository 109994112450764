import type { FunctionComponent } from "react"

interface PageHeaderProp {
  title: string
  subtitle?: string
}

export const PageHeader: FunctionComponent<PageHeaderProp> = props => (
  <header className="space-y-xs max-w-5xl">
    <h1>{props.title}</h1>
    <p>{props.subtitle}</p>
  </header>
)
