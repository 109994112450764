import { gql } from "@apollo/client"

export const mutations = {
  createUserInvite: gql(`
    mutation InvitePeopleCreateUserInvite(
      $firstName: String!
      $lastName: String!
    ) {
      createUserInvite(firstName: $firstName, lastName: $lastName) {
        userId
        firstName,
        lastName,
        inviteUrl
      }
    }
  `),
  giftTherapyExtension: gql(`
    mutation InvitePeopleGiftTherapyExtension(
      $gift: GiftTherapyExtensionInput!
    ) {
      giftTherapyExtension(gift: $gift) {
        id
      }
    }
  `),
}
