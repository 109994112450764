import { gql } from "@apollo/client"

export const mutations = {
  updateCompanySessionPack: gql`
    mutation ChangeSessionPackUpdate($upcomingSessionPackSize: Int!) {
      updateSessionPack(upcomingSessionPackSize: $upcomingSessionPackSize) {
        upcomingSessionPackSize
      }
    }
  `,
}
