import { Route, Routes } from "react-router-dom"

import { SPILL_EXTERNAL_LINKS } from "common/constants"
import { NotFound404Page } from "common/components/NotFound404Page"
import { NavigateExternal } from "common/components/NavigateExternal"

export function DocumentRouter(): JSX.Element {
  return (
    <Routes>
      <Route
        path="terms-of-service"
        element={<NavigateExternal to={SPILL_EXTERNAL_LINKS.T_AND_C.link} />}
      />
      <Route
        path="privacy-policy"
        element={
          <NavigateExternal to={SPILL_EXTERNAL_LINKS.PRIVACY_POLICY.link} />
        }
      />
      <Route path="*" element={<NotFound404Page />} />
    </Routes>
  )
}
