import { ComponentProps, FunctionComponent, useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { MutationBaseOptions } from "@apollo/client/core/watchQueryOptions"
import { P, Button, CopyField } from "@spillchat/puddles"
import { LinkIcon } from "@heroicons/react/24/outline"
import { toast } from "sonner"

import GoogleCalendarMeetingSnippet from "common/assets/images/googleCalendarMeetingSnippet.svg?react"
import { useAnalytics } from "common/context/analyticsContext"
import { Dialog } from "common/components/Dialog"
import { Select } from "common/components/FormElements/Select"
import { TextInput } from "common/components/FormElements/TextInput"
import { config } from "config"
import {
  CreateMeetingDialogCreateMeetingMutation as MutationData,
  CreateMeetingDialogCreateMeetingMutationVariables as MutationVars,
} from "types/graphql"

export const fragments = {
  mutationFields: gql`
    fragment CreateMeetingDialogMutationFields on Mutation {
      createMeeting(
        frequency: $frequency
        name: $name
        redirectUrl: $redirectUrl
      ) {
        urlId
        name
      }
    }
  `,
}

export const mutations = {
  createMeeting: gql`
    mutation CreateMeetingDialogCreateMeeting(
      $frequency: String!
      $name: String!
      $redirectUrl: String!
    ) {
      ...CreateMeetingDialogMutationFields
    }
    ${fragments.mutationFields}
  `,
}

interface FormValues {
  frequency: string
  name: string
  redirectUrl: string
}

interface CreateMeetingDialogProps
  extends Pick<ComponentProps<typeof Dialog>, "isOpen" | "onClose"> {
  onCreateMeetingRefetchQueries?: MutationBaseOptions["refetchQueries"]
}

export const CreateMeetingDialog: FunctionComponent<
  CreateMeetingDialogProps
> = props => {
  const { isOpen = false } = props

  const { track } = useAnalytics()

  const formMethods = useForm<FormValues>({
    defaultValues: {
      frequency: "weekly",
      name: "",
      redirectUrl: "",
    },
  })
  const { handleSubmit, register } = formMethods

  const [newMeeting, setNewMeeting] = useState<MutationData["createMeeting"]>()

  const [createMeeting] = useMutation<MutationData, MutationVars>(
    mutations.createMeeting,
    {
      onCompleted: data => {
        setNewMeeting(data.createMeeting)
        track("User gets Pulse creation confirmation")
      },
      refetchQueries: props.onCreateMeetingRefetchQueries,
    }
  )

  const onSubmit: SubmitHandler<FormValues> = data => {
    void createMeeting({ variables: data })
  }

  useEffect(() => {
    if (isOpen) track("User opens Pulse creation main modal")
  }, [isOpen])

  const webAppBaseUrl = new URL(config.spill.webApp.baseUrl)

  return (
    <Dialog
      canClose
      isOpen={isOpen}
      maxWidth="2xl"
      onClose={props.onClose}
      title="Set up Team Check-ins"
    >
      {newMeeting ? (
        <div className="space-y-6">
          <P>Perfect! Here’s your new meeting link:</P>
          <CopyField
            copyValue={`${webAppBaseUrl.host}/pulse/${newMeeting.urlId}`}
            onCopied={() => toast.success("Link copied to clipboard")}
            icon={<LinkIcon />}
          />
          <P>
            You can use this to see how your Check-in looks. But there’s one
            more important job to do:
          </P>
          <P>
            <strong>Add your link</strong> to your meeting’s original calendar
            invite where it says <strong>‘location’</strong>. This means your
            team will go straight to the Check-in questions before your big
            meeting.
          </P>
          <GoogleCalendarMeetingSnippet />
          <P>
            Stuck?{" "}
            <a
              className="font-bold underline"
              href="https://spill.notion.site/Team-Pulse-548d4287dddf47ea961740b817702f26#f23ccdc182e54c3c83a610e12d5ae015"
              rel="noreferrer"
              target="_blank"
            >
              Watch a demo video
            </a>
          </P>
          <div className="flex gap-2 justify-end">
            <Button onClick={props.onClose} variant="secondary">
              Back to settings
            </Button>
          </div>
        </div>
      ) : (
        <FormProvider {...formMethods}>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <P>
              Adding a new check-in to a regular meeting redirects your team to
              answer 2 quick questions before they join your usual call.
            </P>
            <ol className="space-y-2">
              <li className="flex gap-2 items-center">
                <span className="border border-black flex font-bold h-8 items-center justify-center rounded-full text-sm w-8">
                  1
                </span>
                <P>
                  Pick a regular video meeting you have with your whole team
                </P>
              </li>
              <li className="flex gap-2 items-center">
                <span className="border border-black flex font-bold h-8 items-center justify-center rounded-full text-sm w-8">
                  2
                </span>
                <P>Copy and paste the link below to add a Check-in</P>
              </li>
            </ol>
            <TextInput
              autoComplete="off"
              id="name"
              label="Meeting name"
              name="name"
              placeholder="E.g. Friday Marketing planning"
              register={register}
              required
            />
            <TextInput
              autoComplete="off"
              id="redirectUrl"
              label="Meeting URL"
              name="redirectUrl"
              placeholder="E.g. https://meet.google.com/fbj-gfw-trn"
              register={register}
              required
              type="url"
            />
            <Select
              id="frequency"
              label="Meeting frequency"
              name="frequency"
              register={register}
              required
              options={[
                { key: "weekly", label: "Weekly", value: "weekly" },
                {
                  key: "fortnightly",
                  label: "Fortnightly",
                  value: "fortnightly",
                },
                { key: "monthly", label: "Monthly", value: "monthly" },
                { key: "other", label: "Other", value: "other" },
              ]}
            />
            <div className="flex gap-2 justify-end">
              <Button type="submit" variant="primary">
                Add new Check-in
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </Dialog>
  )
}
