import { PlatformType } from "types/graphql"

export function convertPlatformTypeToName(platformType: PlatformType): string {
  return {
    [PlatformType.SLACK]: "Slack",
    [PlatformType.TEAMS]: "Teams",
    [PlatformType.EMAIL]: "Email",
    [PlatformType.CUSTOM]: "Custom",
  }[platformType]
}
