import { LoadingSpinner } from "./LoadingSpinner"

type LoadingPageProps = {
  message?: string
}

export function LoadingPage({ message }: LoadingPageProps): JSX.Element {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <LoadingSpinner sizeInPixels={100} />

      <div className="p-10 text-center italic text-blue-800 text-2xl">
        {message}
      </div>
    </div>
  )
}
