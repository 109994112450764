import sumBy from "lodash/sumBy"

/**
 * Calculates the mean of values in a collection.
 * @param arr The array to iterate over.
 * @param key The key of the property to calculate the mean of.
 * @returns The mean of the values of a given key in the array.
 */
export function meanBy<T>(arr: T[], key: string): number | null {
  return arr.length === 0 ? null : sumBy(arr, key) / arr.length
}
