import { FunctionComponent, useEffect } from "react"
import { useFormContext, useController } from "react-hook-form"
import { useBoolean, useMap } from "react-use"
import { Entries } from "type-fest"
import { P } from "@spillchat/puddles"

import { cn } from "common/helpers/cn"
import { useElementBreakpoint } from "common/hooks/useElementBreakpoint"
import { useAnalytics } from "common/context/analyticsContext"
import { Checkbox } from "common/components/FormElements/Checkbox"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"

import { FormSection } from "./FormSection"
import { TextInputCheckbox } from "./TextInputCheckbox"

type Goal = "beHeard" | "beChalleneged" | "gainPerspective" | "workTowardGoals"

type GoalsData = Record<Goal, string>

const goalsData: GoalsData = {
  beHeard: "A space to be heard",
  beChalleneged: "Someone to challenge my thoughts",
  gainPerspective: "Clarity & perspective on my situation",
  workTowardGoals: "Set and work towards explicit goals",
}

type Props = {
  idx: number
  question: string
}

export const GoalsSection: FunctionComponent<Props> = ({ idx, question }) => {
  const [ref, , breakpoint] = useElementBreakpoint<
    HTMLTableSectionElement,
    600
  >([600])

  const { control, register } = useFormContext<FormValues>()

  // React Hook Form goals controller
  const {
    field: { onChange },
    fieldState: { isDirty },
  } = useController<FormValues>({
    control,
    defaultValue: [],
    name: `baselineQuestions.${idx}.answer`,
    rules: { required: "Required" },
  })

  // Local goals state
  const [goals, { remove: removeGoal, set: setGoal }] = useMap<
    GoalsData & { other: string }
  >()

  // Update form values when goals change
  useEffect(() => {
    onChange(Object.values(goals).join("\n"))
  }, [goals, onChange])

  // Track first change to goals
  const { track } = useAnalytics()
  const [hasTracked, toggleHasTracked] = useBoolean(false)
  useEffect(() => {
    if (!hasTracked && isDirty) {
      track(
        "User enters baseline question",
        { Field: "Goals" },
        toggleHasTracked
      )
    }
  }, [hasTracked, isDirty])

  return (
    <FormSection
      name="baselineQuestions.goals"
      subtitle="Pick as many as apply"
      ref={ref}
      title={`${idx}. ${question}*`}
    >
      <input
        hidden
        value={"baseline"}
        {...register(`baselineQuestions.${idx}.section`)}
      />
      <input
        hidden
        value={question}
        {...register(`baselineQuestions.${idx}.question`)}
      />
      <ul
        className={cn("grid gap-4", {
          "grid-cols-2": breakpoint === 600,
        })}
      >
        {(Object.entries(goalsData) as Entries<GoalsData>).map(
          ([key, value]) => (
            <Checkbox
              key={key}
              label={<P>{value}</P>}
              name={`baselineQuestions.goals.${key}`}
              onChange={e => {
                if (e.currentTarget.checked) setGoal(key, value)
                else removeGoal(key)
              }}
              value={key}
            />
          )
        )}
        <TextInputCheckbox
          label={<P>Other</P>}
          name="goals"
          onChange={value => {
            if (value === "") removeGoal("other")
            else setGoal("other", value)
          }}
        />
      </ul>
    </FormSection>
  )
}
