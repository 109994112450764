import { gql } from "@apollo/client"

export const fragments = {
  getProductUpdates: gql`
    fragment ProductUpdatesGetProductUpdates on Query {
      getRecentProductUpdates {
        id
        header
        image
        subtitle
        link
      }
    }
  `,
}
