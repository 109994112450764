/**
 * A generic error page that relates to the book session page.
 */

import { H2, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

export const SessionPageError: FunctionComponent = () => {
  return (
    <div className="flex flex-col w-full h-full items-start md:items-center justify-center gap-4 text-center">
      <H2>We're currently unable to load any available sessions</H2>
      <P>
        Please try logging out and refreshing before logging in and trying
        again.
      </P>
      <P>If the issue persists, please contact support@spill.chat</P>
    </div>
  )
}
