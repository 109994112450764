import { gql } from "@apollo/client"

export const mutations = {
  updatePackage: gql(`
    mutation AdminTherapySpecialisedSupportUpdate(
      $packageSettingId: ID!
      $numberSessions: Float!
      $forAllEmployees: Boolean!
      $numberMonthsToUse: Float
      $adminReason: String
      $iconUrl: String
      $userFacingDescription: String
      $visibleToAllEmployees: Boolean
      $icon: String
      $name: String
    ) {
      updateCustomCompanyPackageSetting(
        packageSettingId: $packageSettingId
        numberSessions: $numberSessions
        forAllEmployees: $forAllEmployees
        numberMonthsToUse: $numberMonthsToUse
        adminReason: $adminReason
        iconUrl: $iconUrl
        userFacingDescription: $userFacingDescription
        visibleToAllEmployees: $visibleToAllEmployees
        icon: $icon
        name: $name
      ) {
        companyId
      }
    }
  `),
  disablePackage: gql(`
    mutation AdminTherapySpecialisedSupportDisable($packageSettingId: ID!) {
      disableCustomCompanyPackageSetting(packageSettingId: $packageSettingId) {
        state
      }
    }
  `),
}
