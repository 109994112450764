import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useBoolean, useSet } from "react-use"
import { P } from "@spillchat/puddles"

import { useAnalytics } from "common/context/analyticsContext"
import { TagInput } from "common/components/TagInput"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"

import { FormSection } from "./FormSection"

type Props = {
  idx: number
  question: string
}

export const FeelingsSection: FunctionComponent<Props> = ({
  idx,
  question,
}) => {
  const tagInputRef = useRef<HTMLInputElement>(null)

  // React Hook Form selected feelings controller
  const { control, register } = useFormContext<FormValues>()
  const {
    field: { onChange },
    fieldState: { isDirty },
  } = useController<FormValues>({
    control,
    name: `baselineQuestions.${idx}.answer`,
    rules: { required: "Required" },
  })

  // Show "Other feelings" text/tag input
  const [showOtherInput, setShowOtherInput] = useState(false)
  // Local checked feelings state
  const [checkedFeelings, { toggle }] = useSet()
  // "Other" feelings state
  const [otherFeelings, setOtherFeelings] = useState<string[]>([])
  // Combination of checked feelings and "Other" feelings
  const selectedFeelings = useMemo(
    () => [...Array.from(checkedFeelings), ...otherFeelings],
    [checkedFeelings, otherFeelings]
  )

  // Update form values when selected feelings change
  useEffect(() => {
    onChange(selectedFeelings.join(" "))
  }, [onChange, selectedFeelings])

  // Reset otherFeelings when "Other" checkbox is unchecked
  useEffect(() => {
    if (showOtherInput) tagInputRef.current?.focus?.()
    else setOtherFeelings([])
  }, [showOtherInput])

  // Track first change to selected feelings
  const { track } = useAnalytics()
  const [hasTracked, toggleHasTracked] = useBoolean(false)
  useEffect(() => {
    if (!hasTracked && isDirty) {
      track(
        "User enters baseline question",
        { Field: "Feelings" },
        toggleHasTracked
      )
    }
  }, [hasTracked, isDirty])

  return (
    <FormSection
      name="baselineQuestions.selectedFeelings"
      subtitle="Pick as many as apply"
      title={`${idx}. ${question}*`}
    >
      <input
        hidden
        value={"baseline"}
        {...register(`baselineQuestions.${idx}.section`)}
      />
      <input
        hidden
        value={question}
        {...register(`baselineQuestions.${idx}.question`)}
      />
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[inherit]">
        {/* Feelings checkboxes */}
        {[
          "angry",
          "anxious",
          "ashamed",
          "curious",
          "drained",
          "frustrated",
          "guilty",
          "hopeless",
          "lonely",
          "sad",
          "stressed",
        ].map(feeling => (
          <li
            className="border border-blue-800 h-10 rounded w-full"
            key={feeling}
          >
            <input
              className="peer"
              hidden
              id={feeling}
              onChange={() => toggle(feeling)}
              type="checkbox"
            />
            <label
              className="capitalize cursor-pointer flex h-full items-center justify-center w-full peer-checked:bg-blue-800 peer-checked:text-mono-white"
              htmlFor={feeling}
            >
              <P>{feeling}</P>
            </label>
          </li>
        ))}
        {/* "Other" checkbox */}
        <li className="border border-blue-800 h-10 rounded w-full">
          <input
            className="peer"
            hidden
            id="other"
            onChange={e => setShowOtherInput(e.target.checked)}
            type="checkbox"
          />
          <label
            className="capitalize cursor-pointer flex  h-full items-center justify-center w-full peer-checked:bg-blue-800 peer-checked:text-mono-white"
            htmlFor="other"
          >
            <P>Other</P>
          </label>
        </li>
        {/* Other text input */}
        {showOtherInput && (
          <TagInput
            onChange={setOtherFeelings}
            ref={tagInputRef}
            tagColor="blue"
            tags={otherFeelings}
          />
        )}
      </ul>
    </FormSection>
  )
}
