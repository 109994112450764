import { Link as RouterLink } from "react-router-dom"

import type { ReactNode } from "react"

type LinkProps = {
  children: ReactNode
  to: string
}

export function Link({ children, to, ...props }: LinkProps): JSX.Element {
  if (
    to.startsWith("http") ||
    to.startsWith("www") ||
    to.startsWith("mailto:")
  ) {
    return (
      <a
        className="text-teal-600"
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }
  return (
    <RouterLink className="text-teal-600 cursor-pointer" to={to} {...props}>
      {children}
    </RouterLink>
  )
}
