import {
  ComponentPropsWithoutRef,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  cloneElement,
  useMemo,
  useRef,
  useState,
} from "react"
import { usePopper } from "react-popper"
import { useHoverDirty, useMouseHovered, useWindowScroll } from "react-use"
import { AnimatePresence, motion } from "framer-motion"

export interface TooltipProps
  extends PropsWithChildren,
    Pick<ComponentPropsWithoutRef<"div">, "className" | "id"> {
  /** Content to show in tooltip. */
  content?: ReactNode
  /** Follow mouse. */
  followMouse?: boolean
}

export const Tooltip: FunctionComponent<TooltipProps> = ({
  children,
  className = "tooltip tooltip-sm",
  content = null,
  followMouse = false,
  id,
}) => {
  const ref = useRef<Element>(null)

  const mousePos = useMouseHovered(ref, { whenHovered: true })
  const windowScroll = useWindowScroll()

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const isHovered = useHoverDirty(ref)

  const virtualRef = useMemo(() => {
    return {
      getBoundingClientRect: (): DOMRect => ({
        bottom: mousePos.docY - windowScroll.y,
        height: 0,
        left: mousePos.docX,
        right: mousePos.docX,
        top: mousePos.docY - windowScroll.y,
        width: 0,
        x: mousePos.docX,
        y: mousePos.docY - windowScroll.y,
        // usePopper expects a DOMRect, which expects a toJSON property
        toJSON() {},
      }),
    }
  }, [mousePos, windowScroll])

  const { attributes, styles } = usePopper(
    followMouse ? virtualRef : ref.current,
    popperElement,
    {
      placement: "top-start",
      modifiers: [
        {
          name: "computeStyles",
          options: {
            gpuAcceleration: false, // true by default
          },
        },
      ],
    }
  )

  return (
    <>
      {cloneElement(children as JSX.Element, { ref, "aria-describedby": id })}
      <AnimatePresence>
        {isHovered && content !== null && (
          <motion.div
            animate={{ opacity: 1, scale: 1, transition: { delay: 0.3 } }}
            className={className}
            exit={{ opacity: 0, scale: 0.6 }}
            initial={{ opacity: 0, scale: 0.6 }}
            ref={setPopperElement}
            role="tooltip"
            style={styles["popper"]}
            transition={{ duration: 0.1 }}
            {...attributes["popper"]}
          >
            {content}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
