import { createContext, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { app, pages, authentication } from "@microsoft/teams-js"

import type { FunctionComponent, ProviderProps } from "react"

const SEARCH_PARAMS = {
  TEAMS: "teams",
}

type SdkWrapper = {
  /** The url must be hosted on one of the `validDomains` in the Teams app manifest. */
  openPopup: (url: string) => Promise<void>
}

export type TeamsContextData = {
  isTeams: boolean
  sdk: SdkWrapper | null
}

export const TeamsContext = createContext<TeamsContextData>({
  isTeams: false,
  sdk: null,
})

export const useTeams = (): TeamsContextData => useContext(TeamsContext)

type TeamContextProviderProps = {
  children: ProviderProps<TeamsContextData>["children"]
  value?: ProviderProps<TeamsContextData>["value"]
}

// We add a query param whenever loading Spill in MS Teams
// This is currently the only way to know if the user is viewing from Teams
export const isTeams =
  new URLSearchParams(document.location.search)
    .get(SEARCH_PARAMS.TEAMS)
    ?.toLowerCase() === "true"

export const TeamsContextProvider: FunctionComponent<
  TeamContextProviderProps
> = props => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [prevPathname] = useState(pathname)

  const [rawSdk, setRawSdk] = useState<typeof app | null>(null)

  useEffect(() => {
    if (!isTeams) return

    if (rawSdk != null || app.isInitialized()) {
      return
    }

    const initialize = async () => {
      await app.initialize()
      app.notifySuccess()
      setRawSdk(app)

      pages.backStack.registerBackButtonHandler(() => {
        navigate(-1)
        if (pathname === prevPathname) {
          void pages.backStack.navigateBack()
        }
        return true
      })
      const {
        page: { subPageId },
      } = await app.getContext()
      if (subPageId === undefined || subPageId === "") return

      navigate(subPageId, { replace: true })
    }
    void initialize()
  }, [])

  const sdk: SdkWrapper | null = rawSdk
    ? {
        openPopup: async (url: string) => {
          await authentication.authenticate({
            url,
          })
        },
      }
    : null

  return (
    <TeamsContext.Provider value={{ isTeams, sdk, ...(props.value ?? {}) }}>
      {props.children}
    </TeamsContext.Provider>
  )
}
