import cn from "classnames"

import { ErrorText } from "./ErrorText"
import { Label } from "./Label"

import type { ComponentProps } from "react"
import type {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form"

interface TextAreaProps<T extends FieldValues>
  extends ComponentProps<"textarea"> {
  grow?: boolean
  /**
   * Id for the text area. Required in order to create useful labels.
   */
  id?: string
  /**
   * Optional label for the textarea. This will be displayed above the input.
   */
  label?: string
  /**
   * Name of the field that we're adding to the form. This is used to create the
   * react-hook-form register function.
   */
  name: Path<T>
  /**
   * We use the register from the useForm hook to register the textarea with react-hook-form.
   */
  register?: UseFormRegister<T>
  /**
   * Allows us to provide a set of additional options and validation rules to the textarea.
   */
  registerOptions?: RegisterOptions<T, Path<T>>
}

/**
 * Textarea component that wraps the primitive textarea element.
 *
 * It's a React.ReactElement as we're using the react-hook-form library to handle the field state and type defintions.
 * Which involves taking a value from the parent and passing it as a generic to the
 * interface.
 */
export const TextArea = <FormValues extends FieldValues>({
  className,
  grow,
  id,
  label,
  name,
  register,
  registerOptions,
  ...rest
}: TextAreaProps<FormValues>): React.ReactElement<FormValues> => (
  <div className={cn("flex flex-col gap-1 w-full", { grow })}>
    {label != null && <Label htmlFor={id}>{label}</Label>}
    <textarea
      className={cn(
        "border border-grey-200 p-4 ring-0 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-800",
        { grow },
        className
      )}
      id={id ?? name}
      {...(register && register(name, registerOptions))}
      {...rest}
    />
    <ErrorText name={name} />
  </div>
)
