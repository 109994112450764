import { FunctionComponent } from "react"
import { Routes, Route } from "react-router-dom"

import { CheckoutSuccess } from "./CheckoutSuccess"
import { CheckoutFailure } from "./CheckoutFailure"

export const CheckoutRouter: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/success" element={<CheckoutSuccess />} />
      <Route path="/failure" element={<CheckoutFailure />} />
    </Routes>
  )
}
