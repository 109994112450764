import { gql } from "@apollo/client"

export const queries = {
  getActivityData: gql`
    query AdminTherapyActivity {
      user {
        id
        company {
          id
          featuresAndSettings {
            userCanRequestTherapy {
              value
            }
          }
        }
      }
    }
  `,
}
