/**
 * An animated version of the ' heavy rain' icon that can be found as a static SVG in
 * /src/common/assets/images/moods/2.svg
 **/

import React from "react"
import { motion, useAnimation } from "framer-motion"

import { AnimatedIconProps } from "./types"
import { rain } from "./animations"

export const HeavyRain: React.FunctionComponent<AnimatedIconProps> = props => {
  // We have two different animations for rain, so we can offset some of the paths
  const rainAnimation = useAnimation()
  const rainAnimation2 = useAnimation()

  // Create an array of the animations, so we can orchestrate them nicer.
  const rainAnimations = [rainAnimation, rainAnimation2]

  React.useEffect(() => {
    if (props.isAnimating) {
      rainAnimation.start(rain(0.75)).catch(() => {})
      rainAnimation2.start(rain(0.75, 0.25)).catch(() => {})
    } else {
      rainAnimations.forEach(animation => animation.stop())
    }
  }, [props.isAnimating])

  return (
    <>
      <motion.svg
        width="49"
        height="55"
        viewBox="0 0 49 55"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path d="M42.8818 21.8952C42.9217 21.5634 42.9417 21.2294 42.9418 20.8952C42.9418 18.5083 41.9936 16.2191 40.3057 14.5312C38.6179 12.8434 36.3287 11.8952 33.9418 11.8952C32.4656 11.8915 31.0118 12.2558 29.7118 12.9552C28.3189 10.9368 26.3156 9.41791 23.9961 8.62156C21.6765 7.8252 19.1629 7.79327 16.8238 8.53043C14.4848 9.2676 12.4436 10.7351 10.9999 12.7175C9.55609 14.6999 8.78573 17.0928 8.80181 19.5452C8.80165 20.1749 8.85181 20.8035 8.9518 21.4252C6.73724 21.4729 4.63235 22.3985 3.10018 23.9981C1.56801 25.5978 0.734049 27.7407 0.781788 29.9552C0.829527 32.1698 1.75504 34.2747 3.35473 35.8068C4.95441 37.339 7.09723 38.1729 9.31179 38.1252H39.9518C41.9133 38.1321 43.8147 37.4483 45.3225 36.1936C46.8303 34.9388 47.8482 33.1933 48.1977 31.2631C48.5472 29.333 48.2061 27.3414 47.2341 25.6376C46.262 23.9338 44.7212 22.6266 42.8818 21.9452V21.8952ZM39.9518 35.5452H9.31179C8.52254 35.5778 7.73486 35.4505 6.99605 35.171C6.25725 34.8915 5.58257 34.4655 5.01259 33.9186C4.44262 33.3717 3.98909 32.7151 3.67928 31.9885C3.36947 31.2619 3.20977 30.4801 3.20977 29.6902C3.20977 28.9003 3.36947 28.1185 3.67928 27.3919C3.98909 26.6653 4.44262 26.0088 5.01259 25.4619C5.58257 24.915 6.25725 24.4889 6.99605 24.2094C7.73486 23.9299 8.52254 23.8026 9.31179 23.8352H12.2518L11.7318 22.2052C11.4449 21.3203 11.2997 20.3955 11.3018 19.4652C11.3262 17.475 12.0097 15.549 13.2453 13.9886C14.4808 12.4281 16.1988 11.3213 18.1305 10.8413C20.0621 10.3612 22.0984 10.535 23.9207 11.3355C25.743 12.136 27.2486 13.5179 28.2018 15.2652L28.8918 16.5552L30.0618 15.6752C31.179 14.8309 32.5414 14.3745 33.9418 14.3752C35.664 14.3752 37.3158 15.0586 38.5345 16.2755C39.7532 17.4923 40.4391 19.143 40.4418 20.8652C40.439 21.3986 40.3684 21.9295 40.2318 22.4452L39.9218 23.6952L41.1918 23.9652C42.5979 24.2781 43.8395 25.0979 44.6795 26.2681C45.5195 27.4383 45.899 28.8769 45.7456 30.3092C45.5923 31.7415 44.9167 33.0671 43.848 34.033C42.7793 34.9988 41.3923 35.5371 39.9518 35.5452Z" />
        <motion.path
          animate={rainAnimation}
          d="M11.1617 40.1445C10.8302 40.1445 10.5122 40.2762 10.2778 40.5107C10.0434 40.7451 9.91168 41.063 9.91168 41.3945V49.3445C9.91168 49.676 10.0434 49.994 10.2778 50.2284C10.5122 50.4628 10.8302 50.5945 11.1617 50.5945C11.4932 50.5945 11.8111 50.4628 12.0456 50.2284C12.28 49.994 12.4117 49.676 12.4117 49.3445V41.3945C12.4117 41.063 12.28 40.7451 12.0456 40.5107C11.8111 40.2762 11.4932 40.1445 11.1617 40.1445Z"
        />
        <motion.path
          animate={rainAnimation2}
          d="M22.1216 40.1445C21.7901 40.1445 21.4722 40.2762 21.2378 40.5107C21.0033 40.7451 20.8716 41.063 20.8716 41.3945V49.3445C20.8716 49.676 21.0033 49.994 21.2378 50.2284C21.4722 50.4628 21.7901 50.5945 22.1216 50.5945C22.4532 50.5945 22.7711 50.4628 23.0056 50.2284C23.24 49.994 23.3716 49.676 23.3716 49.3445V41.3945C23.3716 41.063 23.24 40.7451 23.0056 40.5107C22.7711 40.2762 22.4532 40.1445 22.1216 40.1445Z"
        />
        <motion.path
          animate={rainAnimation}
          d="M27.5914 40.1446C27.2598 40.1446 26.9419 40.2763 26.7075 40.5107C26.4731 40.7451 26.3414 41.0631 26.3414 41.3946V53.6046C26.3414 53.9361 26.4731 54.254 26.7075 54.4884C26.9419 54.7229 27.2598 54.8546 27.5914 54.8546C27.9229 54.8546 28.2408 54.7229 28.4753 54.4884C28.7097 54.254 28.8414 53.9361 28.8414 53.6046V41.3946C28.8427 41.23 28.8113 41.0669 28.7489 40.9147C28.6866 40.7624 28.5946 40.624 28.4782 40.5077C28.3619 40.3914 28.2236 40.2994 28.0713 40.237C27.9191 40.1747 27.7559 40.1432 27.5914 40.1446Z"
        />
        <motion.path
          animate={rainAnimation2}
          d="M16.6417 40.1445C16.3101 40.1445 15.9922 40.2762 15.7578 40.5107C15.5234 40.7451 15.3917 41.063 15.3917 41.3945V53.6045C15.3917 53.936 15.5234 54.254 15.7578 54.4884C15.9922 54.7228 16.3101 54.8545 16.6417 54.8545C16.9732 54.8545 17.2911 54.7228 17.5255 54.4884C17.76 54.254 17.8917 53.936 17.8917 53.6045V41.3945C17.8917 41.063 17.76 40.7451 17.5255 40.5107C17.2911 40.2762 16.9732 40.1445 16.6417 40.1445Z"
        />
        <motion.path
          animate={rainAnimation}
          d="M33.0416 40.1445C32.71 40.1445 32.3921 40.2762 32.1577 40.5107C31.9233 40.7451 31.7916 41.063 31.7916 41.3945V49.3445C31.7916 49.676 31.9233 49.994 32.1577 50.2284C32.3921 50.4628 32.71 50.5945 33.0416 50.5945C33.3731 50.5945 33.691 50.4628 33.9254 50.2284C34.1599 49.994 34.2916 49.676 34.2916 49.3445V41.3945C34.2916 41.063 34.1599 40.7451 33.9254 40.5107C33.691 40.2762 33.3731 40.1445 33.0416 40.1445Z"
        />
        <motion.path
          animate={rainAnimation2}
          d="M38.2017 40.1445C37.8702 40.1445 37.5523 40.2762 37.3178 40.5107C37.0834 40.7451 36.9517 41.063 36.9517 41.3945V45.3945C36.9517 45.7261 37.0834 46.044 37.3178 46.2784C37.5523 46.5128 37.8702 46.6445 38.2017 46.6445C38.5332 46.6445 38.8512 46.5128 39.0856 46.2784C39.32 46.044 39.4517 45.7261 39.4517 45.3945V41.3945C39.4517 41.063 39.32 40.7451 39.0856 40.5107C38.8512 40.2762 38.5332 40.1445 38.2017 40.1445Z"
        />
      </motion.svg>
    </>
  )
}
