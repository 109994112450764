import { gql, useQuery } from "@apollo/client"
import { useIntl } from "react-intl"

import { Avatar } from "common/components/Avatar"

import { FEELINGS } from "../constants/emotions"

import type {
  Feeling,
  FeelingsFeedItemGetPulseResponseQuery,
  FeelingsFeedItemGetPulseResponseQueryVariables,
} from "types/graphql"

const pulseResponseReactionFields = gql`
  fragment FeelingsFeedItemPulseResponseReactionFields on PulseResponseReaction {
    createdAt
    emojis
    id
    name
    pulseResponseId
    userId
  }
`

const pulseResponseFields = gql`
  fragment FeelingsFeedItemPulseResponseFields on SharedPulseResponse {
    feelings
    id
    name
    reactions {
      ...FeelingsFeedItemPulseResponseReactionFields
    }
  }
  ${pulseResponseReactionFields}
`

const fragments = { pulseResponseFields, pulseResponseReactionFields }

const queries = {
  GET_PULSE_RESPONSE: gql`
    query FeelingsFeedItemGetPulseResponse($id: ID!) {
      pulseResponse(id: $id) {
        ...FeelingsFeedItemPulseResponseFields
      }
    }
    ${fragments.pulseResponseFields}
  `,
  REACT_TO_PULSE_RESPONSE: gql`
    mutation FeelingsFeedItemReactToPulseResponse(
      $emojis: [String!]!
      $name: String
      $pulseResponseId: ID!
    ) {
      reactToPulseResponse(
        emojis: $emojis
        name: $name
        pulseResponseId: $pulseResponseId
      ) {
        ...FeelingsFeedItemPulseResponseReactionFields
      }
    }
    ${fragments.pulseResponseReactionFields}
  `,
}

type FeelingsFeedItemProps = {
  pulseResponseId: string
}

export function FeelingsFeedItem({
  pulseResponseId,
}: FeelingsFeedItemProps): JSX.Element | null {
  const intl = useIntl()

  const { data: { pulseResponse } = {} } = useQuery<
    FeelingsFeedItemGetPulseResponseQuery,
    FeelingsFeedItemGetPulseResponseQueryVariables
  >(queries.GET_PULSE_RESPONSE, {
    fetchPolicy: "cache-only",
    variables: { id: pulseResponseId },
  })

  if (!pulseResponse) return null

  const feelingsAdjByName = pulseResponse.feelings.map(
    feelingKey => FEELINGS[feelingKey as Feeling]?.adjective ?? feelingKey
  )

  return (
    <li className="flex flex-col gap-2">
      {feelingsAdjByName.length > 0 && (
        <div className="flex gap-2 items-center">
          <div className="shrink-0">
            <Avatar name={pulseResponse.name} />
          </div>
          <span>
            <strong>{pulseResponse.name}</strong> is feeling{" "}
            {intl.formatList(feelingsAdjByName)}
          </span>
        </div>
      )}
    </li>
  )
}

FeelingsFeedItem.fragments = fragments
FeelingsFeedItem.queries = queries
