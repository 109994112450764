import { gql } from "@apollo/client"

export const queries = {
  getUser: gql`
    query UpgradePlanGetUser {
      user {
        company {
          id
          planPrice {
            perSession
          }
        }
      }
    }
  `,
}
