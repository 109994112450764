/**
 * A container page that contains information about booking private sessions
 * on Spill. Allows us to set up card details etc.
 */

import { gql, useMutation, useQuery } from "@apollo/client"
import { Button, H1, H2, H4, P, Modal } from "@spillchat/puddles"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { toast } from "sonner"
import { loadStripe } from "@stripe/stripe-js"
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js"
import { captureException } from "@sentry/react"

import {
  PrivateCompleteUserCheckoutMutation,
  PrivateCompleteUserCheckoutMutationVariables,
  PrivateGetCompanyQuery,
  PrivateGetPaymentSecretMutation,
  PrivateGetPaymentSecretMutationVariables,
} from "types/graphql"
import { config } from "config"

const queries = {
  getCompany: gql(`
    query PrivateGetCompany {
      user {
        id
        company {
          id
          subscriptionPlan
        }
       billing {
        paymentsEnabled
       }
      }
    }
  `),
}

const mutations = {
  getPaymentSecret: gql`
    mutation PrivateGetPaymentSecret {
      createUserCheckoutSession
    }
  `,
  completeUserCheckout: gql`
    mutation PrivateCompleteUserCheckout($sessionId: String!) {
      completeUserCheckout(sessionId: $sessionId)
    }
  `,
}

export const Private: FunctionComponent = () => {
  const stripePromise = useMemo(
    async () => await loadStripe(config.stripe.key),
    []
  )

  const { data, loading } = useQuery<PrivateGetCompanyQuery>(queries.getCompany)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [getPaymentSecret, { data: paymentSecretData }] = useMutation<
    PrivateGetPaymentSecretMutation,
    PrivateGetPaymentSecretMutationVariables
  >(mutations.getPaymentSecret)

  const [searchParams] = useSearchParams()

  const id = searchParams.get("session_id")

  const [
    completeCheckout,
    { data: completeCheckoutData, loading: completeLoading },
  ] = useMutation<
    PrivateCompleteUserCheckoutMutation,
    PrivateCompleteUserCheckoutMutationVariables
  >(mutations.completeUserCheckout)

  const onShowModal = async () => {
    await getPaymentSecret()
    setIsModalVisible(true)
  }

  useEffect(() => {
    if (id != null) {
      completeCheckout({
        variables: { sessionId: id },
      }).catch(captureException)
    }
  }, [id])

  if (loading || completeLoading) {
    return null
  }
  const options = { clientSecret: paymentSecretData?.createUserCheckoutSession }

  const state = completeCheckoutData?.completeUserCheckout

  if (id != null && state !== "complete") {
    toast.error(
      "We were unable to process your payment. Please try again, and if that fails, contact us at help@spill.chat"
    )
  }

  if (id != null && state === "complete") {
    return (
      <section className="grid grid-flow-col grid-cols-6 gap-y-12">
        <div className="col-span-4 gap-y-12 flex flex-col">
          <div className="flex flex-col gap-y-5">
            <H1>Payment method confirmed!</H1>
            <P>You're all set up to pay privately for Spill therapy.</P>
            <P>
              To book a session, please{" "}
              <Link className="underline" to="/therapy/sessions">
                return to the Sessions page.
              </Link>
            </P>
          </div>
        </div>
      </section>
    )
  }

  const hasBillingEnabled = data?.user?.billing?.paymentsEnabled === true

  return (
    <section className="grid grid-flow-col grid-cols-6 gap-y-12">
      {paymentSecretData && (
        <Modal.Root open={isModalVisible} onOpenChange={setIsModalVisible}>
          <Modal.Content
            hideDefaultClose={true}
            className="md:min-w-[1024px]"
            onInteractOutside={e => {
              e.preventDefault()
              setIsModalVisible(false)
            }}
          >
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Modal.Content>
        </Modal.Root>
      )}
      <div className="col-span-3 gap-y-12 flex flex-col">
        <div className="flex flex-col gap-y-5">
          <H1>Pay privately for Spill therapy</H1>
          {hasBillingEnabled === false ? (
            <>
              <P>
                If you’ve run out credits, Spill offers a way for you to access
                therapy, by paying for therapy yourself.
              </P>
              <P>
                To pay privately for Spill therapy, please enter your payment
                details. You’ll be billed after your first session.
              </P>
              <Button variant="primary" size="sm" onClick={onShowModal}>
                Enter payment details
              </Button>
            </>
          ) : (
            <>
              <P>You're all set up to pay privately for Spill therapy.</P>
              <P>
                To book a session, please{" "}
                <Link className="underline" to="/therapy/sessions">
                  return to the Sessions page.
                </Link>
              </P>
            </>
          )}
        </div>
        <div className="flex flex-col gap-y-5">
          <H2>About paying privately</H2>
          <H4>Payment</H4>
          <P>
            We'll charge you £65 each 50 minute private therapy session. We'll
            bill you for sessions you use at the start of the following calendar
            month.
          </P>
          <H4>Number of sessions</H4>
          <P>
            You can have one session or many, depending on your goals and
            expectations. Your Spill therapist can help you decide.
          </P>
          <H4>Cancellations</H4>
          <P>
            We won't charge you for sessions you cancel or reschedule with more
            than 24 hours' notice. We'll have to charge you for last minute
            cancellations, as we always pay therapists for missed sessions.
          </P>
          <P>
            Any questions? Please email{" "}
            <a
              href="mailto:therapy@spill.chat"
              rel="_blank"
              className="underline underline-offset-2"
            >
              therapy@spill.chat
            </a>
          </P>
        </div>
      </div>
    </section>
  )
}
