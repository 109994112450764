/**
 * Total the coverages for a given period
 */
export const caclulateTotalCoverages = (
  /**
   * A number indicating the amount of courses provided in a period
   */
  lastPeriodMonthsCourseCovered: number,
  /**
   * A number indicating the amount of one-offs provided in a period
   */
  lastPeriodOneOffsCovered: number
): number =>
  Math.round(lastPeriodMonthsCourseCovered * 6.5 + lastPeriodOneOffsCovered)
