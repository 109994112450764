import { ColumnDef } from "@tanstack/react-table"
import { DataTable, Indicator, IndicatorProps, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { humanize } from "inflection"

import { CompanySharedFeedback, FeedbackTherapyType } from "types/graphql"

export type FeedbackTableRow = Omit<CompanySharedFeedback, "id">

type FeedbackTableProps = {
  data: FeedbackTableRow[]
}

export const FeedbackTable: FunctionComponent<FeedbackTableProps> = ({
  data,
}: FeedbackTableProps) => {
  const columns: ColumnDef<FeedbackTableRow>[] = [
    {
      accessorKey: "fullName",
      header: "Person",
      cell: ({ row }) => {
        const feedback = row.original
        const userName = feedback.userName ?? "Anonymous"
        return (
          <div className="block py-2">
            <span className={userName === "Anonymous" ? "italic" : ""}>
              <P>{userName}</P>
            </span>
          </div>
        )
      },
    },
    {
      id: "therapyType",
      header: "Therapy type",
      cell: ({ row }) => {
        const feedback = row.original

        const getVariant = (): IndicatorProps["variant"] => {
          if (feedback.therapyType === FeedbackTherapyType.SINGLE_SESSION) {
            return "ok"
          }

          if (feedback.therapyType === FeedbackTherapyType.COURSE) {
            return "info"
          }

          return "warning"
        }

        return (
          <Indicator variant={getVariant()}>
            <span className="whitespace-nowrap">
              {humanize(feedback.therapyType)}
            </span>
          </Indicator>
        )
      },
    },
    {
      id: "month",
      header: "Month",
      cell: ({ row }) => {
        const feedback = row.original
        const date = new Date(feedback.month)
        return (
          <span className="whitespace-nowrap">
            <P>
              {date.toLocaleString("default", { month: "short" })}{" "}
              {date.getFullYear()}
            </P>
          </span>
        )
      },
    },
    {
      id: "feedback",
      header: "Feedback",
      cell: ({ row }) => {
        const feedback = row.original
        return <P>{feedback.feedbackText}</P>
      },
    },
  ]

  return <DataTable data={data} columns={columns} showPagination={true} />
}
