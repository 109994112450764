import { gql } from "@apollo/client"

export const mutations = {
  updatePersonalEmail: gql`
    mutation LoginMethodsUpdatePersonalEmail($email: String!) {
      addLoginEmail(email: $email) {
        id
        email
      }
      updateTherapyProfile(userInfo: { email: $email }) {
        email
      }
    }
  `,
}
