/**
 * The contents and the functionality for when a user decides to change their plan.
 */

import { FunctionComponent, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { gql, useMutation, useQuery } from "@apollo/client"
import { toast } from "sonner"

import {
  ChangePlanModalGetCompanyQuery,
  ChangePlanModalUpgradeToDiscountedTeamPlanMutation,
  ChangePlanModalUpgradeToDiscountedTeamPlanMutationVariables,
  ChangePlanModalClearBillingAdviceMutation,
  ChangePlanModalClearBillingAdviceMutationVariables,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { queries as TherapyDashboardQueries } from "features/admin-therapy/admin-therapy.queries"

import { PlanCard } from "./PlanCard"
import { ConfirmChanges } from "./ConfirmChanges"

const cardAnimationVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    delay: 0.1,
  },
}

export const queries = {
  getCompany: gql`
    query ChangePlanModalGetCompany {
      user {
        id
        company {
          id
          billableUserCount
        }
      }
    }
  `,
}

export const mutations = {
  updateToOffTheShelf: gql`
    mutation ChangePlanModalUpgradeToDiscountedTeamPlan {
      upgradeToDiscountedTeamPlan {
        id
      }
    }
  `,
  clearBillingAdvice: gql`
    mutation ChangePlanModalClearBillingAdvice {
      clearBillingAdvice
    }
  `,
}

interface ChangePlanModalProps {
  onCloseModal: () => void
}

export const ChangePlanModal: FunctionComponent<
  ChangePlanModalProps
> = props => {
  const [confirmingChange, setConfirmingChange] = useState(false)
  const [confirmedPaygSwitch, setConfirmedPaygSwitch] = useState(false)
  const { data: companyData, loading: initialLoadingState } =
    useQuery<ChangePlanModalGetCompanyQuery>(queries.getCompany)
  const [clearBillingAdvice, { loading: billingAdviceClearing }] = useMutation<
    ChangePlanModalClearBillingAdviceMutation,
    ChangePlanModalClearBillingAdviceMutationVariables
  >(mutations.clearBillingAdvice)
  const [updateToOffTheShelf, { loading: updatingPricingPlan }] = useMutation<
    ChangePlanModalUpgradeToDiscountedTeamPlanMutation,
    ChangePlanModalUpgradeToDiscountedTeamPlanMutationVariables
  >(mutations.updateToOffTheShelf, {
    refetchQueries: [TherapyDashboardQueries.getUsage],
  })

  /**
   * Stays on the current plan, hiding the modal after we clear the billing advice mutation.
   */
  const stayOnCurrentPlan = async () => {
    const response = await clearBillingAdvice()
    if (
      response.data?.clearBillingAdvice != null &&
      !!response.data.clearBillingAdvice
    ) {
      props.onCloseModal()
    } else {
      toast.error(
        "We were unable to save your changes. Please try again, or get in touch with us if the problem persists. You'll remain on your current plan."
      )
    }
  }

  /**
   * Sets the company to use the PAYG plan, hides the modal after we clear the billing advice mutation.
   */
  const switchToPaygPlan = async () => {
    if (companyData?.user?.company?.id == null) return
    const response = await updateToOffTheShelf()

    const data =
      response.data as unknown as ChangePlanModalUpgradeToDiscountedTeamPlanMutation
    if (data?.upgradeToDiscountedTeamPlan?.id != null) {
      setConfirmedPaygSwitch(true)
    } else {
      toast.error(
        "We were unable to save your changes. Please try again, or get in touch with us if the problem persists. You'll remain on your current plan."
      )
    }
  }

  if (initialLoadingState) {
    return (
      <div className="flex w-full gap-8 px-10 absolute inset-0 bg-mono-white z-50 justify-center items-center bg-opacity-50 backdrop-blur-sm">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  return (
    <>
      {(billingAdviceClearing || updatingPricingPlan) && (
        <div className="flex w-full gap-8 px-10 absolute inset-0 bg-mono-white z-50 justify-center items-center bg-opacity-50 backdrop-blur-sm">
          <LoadingSpinner sizeInPixels={32} />
        </div>
      )}
      <div className="flex flex-col sm:flex-row w-full gap-8 px-10">
        <AnimatePresence>
          {!confirmingChange && (
            <>
              <motion.div className="flex-1" {...cardAnimationVariants}>
                <PlanCard isCurrent onSelect={stayOnCurrentPlan} />
              </motion.div>
              <motion.div className="flex-1" {...cardAnimationVariants}>
                <PlanCard
                  isCurrent={false}
                  onSelect={() => setConfirmingChange(true)}
                />
              </motion.div>
            </>
          )}
          {confirmingChange && (
            <ConfirmChanges
              onConfirm={() => void switchToPaygPlan()}
              confirmationComplete={confirmedPaygSwitch}
              onRequestCloseModal={() => props.onCloseModal()}
              userCount={companyData?.user?.company?.billableUserCount ?? 0}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  )
}
