import { config } from "config"

type GatewayFetchProps = {
  body?: Record<string, unknown>
  method: string
  path: string
}

export async function gatewayFetch({
  body,
  method,
  path,
}: GatewayFetchProps): Promise<Response> {
  return await fetch(`${config.spill.gateway.baseUrl}${path}`, {
    method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: body ? JSON.stringify(body) : undefined,
  })
}
