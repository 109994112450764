import { gql } from "@apollo/client"

export const mutations = {
  triggerPersonalEmailMergeCodes: gql`
    mutation LinkAccountTriggerPersonalEmailMergeCodes($email: String!) {
      triggerPersonalEmailMergeCodes(email: $email)
    }
  `,
  verifyPersonalMergeCode: gql`
    mutation LinkAccountVerifyPersonalMergeCode(
      $code: String!
      $email: String!
    ) {
      verifyPersonalMergeCode(code: $code, email: $email) {
        aatsMerged
        appointmentsMerged
        profilesMerged
        userBillingSnapshotsMerged
        userBillingsMerged
      }
    }
  `,
}
