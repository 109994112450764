import { FormEvent, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { captureException } from "@sentry/react"

import { FeedbackFormTriage } from "../components/FeedbackFormTriage"
import { FeedbackConfirmationTriage } from "../components/FeedbackConfirmationTriage"

const queries = {
  createTriageFeedback: gql`
    mutation CreateTriageFeedback(
      $feedback: String!
      $spillRecommendationScore: Int
    ) {
      createTriageFeedback(
        feedback: $feedback
        spillRecommendationScore: $spillRecommendationScore
      ) {
        id
      }
    }
  `,
}

export const TriageFeedback = (): JSX.Element => {
  const [comment, setComment] = useState<string>("")
  const [score, setScore] = useState<number | null>(null)
  const [showError, setShowError] = useState<boolean>(false)
  const [createFeedback] = useMutation(queries.createTriageFeedback)
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const { errors } = await createFeedback({
      variables: {
        feedback: comment,
        spillRecommendationScore: score,
      },
    })

    if (errors) {
      captureException(errors)
      return setShowError(true)
    }

    setShowError(false) // set this if user was shown an error, then resubmits successfully
    return setHasSubmitted(true)
  }

  return hasSubmitted ? (
    <FeedbackConfirmationTriage />
  ) : (
    <FeedbackFormTriage
      feedbackOn={"your triage message"}
      score={score}
      setScore={setScore}
      comment={comment}
      setComment={setComment}
      showError={showError}
      onSubmit={onSubmit}
    />
  )
}
