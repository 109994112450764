import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export const useSearchParams = (): [
  URLSearchParams,
  {
    delete: (name: string) => void
    set: (name: string, value: string) => void
  },
] => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(window.location.search)
  )

  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search))
  }, [location])

  return [
    searchParams,
    {
      delete: name => {
        const prevSearchParams = new URLSearchParams(window.location.search)
        prevSearchParams.delete(name)
        const url = new URL(window.location.href)
        url.search = prevSearchParams.toString()
        window.history.replaceState({}, "", url.toString())
        setSearchParams(prevSearchParams)
      },
      set: (name, value) => {
        const prevSearchParams = new URLSearchParams(window.location.search)
        prevSearchParams.set(name, value)
        const url = new URL(window.location.href)
        url.search = prevSearchParams.toString()
        window.history.replaceState({}, "", url.toString())
        setSearchParams(prevSearchParams)
      },
    },
  ]
}
