import { isEmpty, omitBy } from "lodash"

import { AvailableAppointmentSlotsFilter } from "types/graphql"

import { FormValues } from "../pages/AppointmentBookingPage/AppointmentBookingPage"

export const transformFiltersForGQL = (
  filter: FormValues["filter"]
): AvailableAppointmentSlotsFilter => {
  return {
    ...omitBy(
      {
        counsellorIds: !isEmpty(filter.counsellorIds)
          ? filter.counsellorIds // Use counsellorIds if present (custom booking form)
          : !isEmpty(filter.previousCounsellorId)
            ? [filter.previousCounsellorId] // Otherwise use previousCounsellorId if present
            : null,
        gender: filter.gender,
        languageCode: filter.language,
        specialism: filter.specialism,
        lifeEvent: filter.lifeEvent,
      },
      isEmpty
    ),
    pmiOnly: filter.pmiOnly,
  }
}
