import { ReactNode } from "react"
import { P } from "@spillchat/puddles"

export interface FeedbackFormOpinionScaleProps {
  id: string
  required: boolean
  question: string
  selectedValue: number
  values: number[]
  labels: string[]
  minWidth?: string
  callback: (id: string, value: number) => void
}

export function FeedbackFormOpinionScale({
  id,
  required,
  question,
  selectedValue,
  values,
  labels,
  minWidth,
  callback,
}: FeedbackFormOpinionScaleProps): ReactNode {
  return (
    <>
      <div className="space-y-3 w-full">
        <P weight="medium">
          {question}
          {required && <span className="text-spill-red-600">*</span>}
        </P>
        <div className="flex flex-wrap gap-2 lg:max-w-full">
          {Array.from(labels, (_, i): ReactNode => {
            const label = labels[i]
            const value = values[i]

            const buttonWidth = minWidth ?? "w-10"
            const classNameShared =
              "cursor-pointer flex items-center justify-center h-10 " +
              buttonWidth +
              " rounded-md "
            const classNameSelected =
              classNameShared +
              "bg-spill-yellow-200 border-solid border-2 border-[#EFB108]"
            const classNameUnselected = classNameShared + "bg-spill-mono-white"

            return (
              <div className="flex w-fit" key={i}>
                <input
                  type="radio"
                  id={id + "_" + i.toString()}
                  value={value}
                  className="hidden"
                  onClick={() => callback(id, values[i]!)}
                />
                <label
                  htmlFor={id + "_" + i.toString()}
                  className={
                    selectedValue === value
                      ? classNameSelected
                      : classNameUnselected
                  }
                >
                  {label}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
