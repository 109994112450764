import { z } from "zod"

export const formSchema = z.object({
  firstName: z.string().min(1, {
    message: "Please enter a first name",
  }),
  lastName: z.string().min(1, {
    message: "Please enter a last name",
  }),
  sessionCount: z.enum(["4", "6", "8"]),
  notificationType: z.enum(["link", "email"]),
  email: z.union([z.literal(""), z.string().email()]),
  note: z
    .string()
    .max(250, {
      message: "Please limit your note to 250 characters",
    })
    .optional(),
})

export type InvitePeopleForm = z.infer<typeof formSchema>
