import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "@spillchat/puddles"
import React, {
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { DefaultValues, useForm, UseFormReturn } from "react-hook-form"
import { z, ZodEffects, ZodObject, ZodRawShape } from "zod"

import { MultiStepFormStepProps } from "./MultiStepFormStepProps"
import { ExternalStepIndexAndModifiers } from "./ExternalStepIndexAndModifiers"

/**
 * Infer type of the current form based on incoming schema
 */
type MultiStepFormType<TSchema extends ZodRawShape> = z.infer<
  ZodObject<TSchema>
>

/**
 * Each step of the form should consist of a function component
 * plus optional actions for navigating forwards and backwards
 */
interface StepAndNavigationActions<TSchema extends ZodRawShape> {
  step: React.FunctionComponent<
    MultiStepFormStepProps<MultiStepFormType<TSchema>>
  >
  advanceAction?: (
    form: UseFormReturn<MultiStepFormType<TSchema>>,
    incrementStep: () => void,
    jumpToStep: (action: SetStateAction<number>) => void
  ) => Promise<void>
  reverseAction?: (
    form: UseFormReturn<MultiStepFormType<TSchema>>,
    decrementStep: () => void,
    jumpToStep: (action: SetStateAction<number>) => void
  ) => void
}

/**
 * The multi-step form needs to know the following:
 * - Optionally, how to set the step index and associated modifiers externally,
 *   so that navigation elements can be created outside the form
 * - The form schema
 * - The form default values
 * - What step components it should attempt to render
 *   and their associated advance and reverse actions
 * - Whether or not to display a series of clickable progress bars (defaults to false)
 * - Whether or not to prevent propagation of submit events beyond component (defaults to false)
 */
interface MultiStepFormProps<TSchema extends ZodRawShape> {
  setExternalStepIndexAndModifiers?: (
    externalStepIndexAndModifiers: ExternalStepIndexAndModifiers
  ) => void
  schema: ZodObject<TSchema> | ZodEffects<ZodObject<TSchema>>
  defaultValues: DefaultValues<MultiStepFormType<TSchema>>
  stepsAndNavigationActions: StepAndNavigationActions<TSchema>[]
  showProgressBars?: boolean
  stopSubmitPropagation?: boolean
}

/**
 * Specialised component for a multi-step form that extends FunctionComponent
 */
interface MultiStepFormComponent
  extends FunctionComponent<MultiStepFormProps<ZodRawShape>> {
  <TSchema extends ZodRawShape>(
    props: MultiStepFormProps<TSchema>
  ): ReturnType<FunctionComponent<MultiStepFormProps<TSchema>>>
}

export const MultiStepForm: MultiStepFormComponent = <
  TSchema extends ZodRawShape,
>({
  setExternalStepIndexAndModifiers,
  schema,
  defaultValues,
  stepsAndNavigationActions,
  showProgressBars = false,
  stopSubmitPropagation = false,
}: MultiStepFormProps<TSchema>) => {
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const incrementStep = () =>
    setCurrentStepIndex(currentStepIndex => currentStepIndex + 1)

  const decrementStep = () =>
    setCurrentStepIndex(currentStepIndex => currentStepIndex - 1)

  useEffect(() => {
    setExternalStepIndexAndModifiers?.({
      currentStepIndex,
      setCurrentStepIndex,
      incrementStep,
      decrementStep,
    })
  }, [currentStepIndex, setCurrentStepIndex])

  const handleProgressBarClick = (targetStepIndex: number) => {
    if (targetStepIndex <= currentStepIndex) {
      setCurrentStepIndex(targetStepIndex)
    }
  }

  const handleSubmit = async () => {
    await stepsAndNavigationActions[currentStepIndex]?.advanceAction?.(
      form,
      incrementStep,
      setCurrentStepIndex
    )
  }

  const stepInstances = stepsAndNavigationActions.map(
    stepAndNavigationActions => {
      const Step = stepAndNavigationActions.step
      return (
        <Step
          key={Step.displayName}
          form={form}
          reverseAction={() => {
            stepAndNavigationActions.reverseAction?.(
              form,
              decrementStep,
              setCurrentStepIndex
            )
          }}
        />
      )
    }
  )

  return (
    <>
      {showProgressBars && (
        <div className="flex gap-2 max-w-lg w-full">
          {Array.from(
            { length: stepsAndNavigationActions.length },
            (_, stepIndex) => {
              return (
                <button
                  key={stepIndex}
                  onClick={() => handleProgressBarClick(stepIndex)}
                  className={`rounded-sm w-1/5 h-1.5 ${stepIndex <= currentStepIndex ? "bg-spill-blue-800 cursor-pointer" : "bg-spill-grey-200 pointer-events-none"} transition-colors`}
                ></button>
              )
            }
          )}
        </div>
      )}
      <Form.Root {...form}>
        {
          // The same action is taken whether the form state is valid or invalid,
          // since we don't care about full validation until the final step of the form.
          // Remember to validate the necessary form fields as part of each advance action!
        }
        <form
          onSubmit={async event => {
            if (stopSubmitPropagation) {
              event.stopPropagation()
            }
            await form.handleSubmit(handleSubmit, handleSubmit)(event)
          }}
        >
          {stepInstances[currentStepIndex]}
        </form>
      </Form.Root>
    </>
  )
}
