/**
 * Once the user has added the Spill app to their company platform, they'll need
 * to link already existing Spill accounts to the Slack/Teams accounts.
 */

import { FunctionComponent } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { H2, P } from "@spillchat/puddles"

import { AccountLinking } from "features/admin/components/AccountLinking"
import { NotFoundPage } from "common/components/NotFoundPage"

export const LinkUserAccounts: FunctionComponent = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const companyPlatformId = searchParams.get("companyPlatformId")

  if (companyPlatformId === null) return <NotFoundPage />

  return (
    <div className="flex flex-col gap-8">
      <H2>Link existing accounts</H2>
      <P>
        Some of your employees already have Spill access. Link the current
        accounts so you're only charged once per user and so that they can
        access their old data in their new account.
      </P>
      <AccountLinking onLinkAll={() => navigate("/admin/access")} />
    </div>
  )
}
