import { gql } from "@apollo/client"

export const queries = {
  getUser: gql`
    query InvitePeopleGetUser {
      user {
        primaryEmail
        company {
          planPrice {
            perSession
          }
          subscriptionPlan
          subscriptionStatus
        }
      }
    }
  `,
}
