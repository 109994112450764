import { AnimatePresence, motion } from "framer-motion"
import { FunctionComponent } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { Button, CopyField } from "@spillchat/puddles"
import { toast } from "sonner"
import { LinkIcon } from "@heroicons/react/24/outline"

import { NotFound404Page } from "common/components/NotFound404Page"

import { platformSetupAnimationVariants, platformTypesInfo } from "."

export const SetupSuccessUnauthenticated: FunctionComponent = () => {
  const { platformType } = useParams()
  const { pathname } = useLocation()
  const platformTypeInfo = platformTypesInfo[platformType ?? ""]

  const url = new URL(window.location.href)

  if (!platformTypeInfo) return <NotFound404Page />

  return (
    <AnimatePresence>
      <motion.div
        key="content"
        variants={platformSetupAnimationVariants}
        className="flex justify-center align-center items-center flex-col gap-12"
        transition={{
          duration: 1.25,
          staggerChildren: 0.25,
          delay: 0.2,
        }}
        initial="initial"
        animate="animate"
      >
        <motion.h2
          className="text-center text-blue-800 text-5xl"
          variants={platformSetupAnimationVariants}
        >
          That's all done!
        </motion.h2>
        <motion.div
          className="flex justify-center text-center items-center flex-col gap-4"
          variants={platformSetupAnimationVariants}
        >
          <p className="text-lg text-blue-800">
            Spill has been securely added to your {platformTypeInfo.name}{" "}
            {platformTypeInfo.companyPlatformTerm}.
          </p>
          <p className="text-lg text-blue-800">
            Send the Spill owner on your team a link so they can finish setting
            up Spill.
          </p>
          <CopyField
            copyValue={`${url.toString()}?showSignIn=true`}
            icon={<LinkIcon />}
            onCopied={() => {
              toast.success(
                `Link copied to clipboard! You can now paste it into a message to your ${
                  platformTypeInfo?.name ?? ""
                } admin.`
              )
            }}
            onFail={() =>
              toast.error(
                "There was an issue copying the link. You can copy it manually from the URL bar."
              )
            }
          />
        </motion.div>
        <motion.div
          className="flex flex-col text-center border border-grey-200 rounded-lg p-6 my-6 gap-4"
          variants={platformSetupAnimationVariants}
        >
          <p className="text-lg text-grey-200">
            If you're the owner of this Spill account, you can continue setting
            up Spill by signing in.
          </p>
          <div className="flex justify-center">
            <Link to={`/signin?redirect=${encodeURIComponent(pathname)}`}>
              <Button>Sign in</Button>
            </Link>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  )
}
