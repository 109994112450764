import { gql } from "@apollo/client"

export const queries = {
  getSupportData: gql`
    query AdminTherapySpecialisedSupport {
      user {
        id
        company {
          id
          name
          subscriptionPlan
          featuresAndSettings {
            adhdSupport {
              active
              value
            }
            customSupport {
              active
            }
          }
          allCustomCompanyPackageSettings {
            id
            name
            icon
            iconUrl
            numberMonthsToUse
            numberSessions
            state
          }
        }
      }
    }
  `,
}
