import { gql } from "@apollo/client"

export const mutations = {
  upgradeToTeamPlan: gql`
    mutation upgradeToTeamPlan {
      upgradeToTeamPlan {
        id
        subscriptionPlan
      }
    }
  `,
}
