import Spinner from "common/assets/images/spinner.svg?react"

type LoadingSpinnerProps = {
  sizeInPixels: number
}

export function LoadingSpinner({
  sizeInPixels,
}: LoadingSpinnerProps): JSX.Element {
  return (
    <Spinner
      className="animate-spin"
      title="Loading spinner"
      style={{ height: sizeInPixels, width: sizeInPixels }}
    />
  )
}
