/**
 * A component that's displayed at the top of the Spill App.
 * It's rendered as a full width fixed position element.
 * It renders a children prop that is passed in.
 */
import type { FunctionComponent, PropsWithChildren } from "react"

export const AppBanner: FunctionComponent<PropsWithChildren> = props => (
  <div className="bg-blue-800 sticky top-0 z-30 flex h-10 items-center justify-center text-mono-white w-full">
    {props.children}
  </div>
)
