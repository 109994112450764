import { Button, Form, H1, H4, SelectableCard } from "@spillchat/puddles"
import React, { useEffect } from "react"

import { MultiStepFormStepProps } from "common/components/MultiStepForm/MultiStepFormStepProps"

import { QuoteSignupForm } from "./QuoteSignup.schema"

export const sizeCardTitles = [
  "Nobody",
  "1 person",
  "2 people",
  "3 people",
  "4+ people",
]

export const QuoteSignupSize: React.FunctionComponent<
  MultiStepFormStepProps<QuoteSignupForm>
> = React.forwardRef<HTMLInputElement, MultiStepFormStepProps<QuoteSignupForm>>(
  ({ form, reverseAction }, ref) => {
    useEffect(() => {
      form.clearErrors("size")
    }, [])

    return (
      <div className="flex flex-col items-center gap-8">
        <H1>
          How many people are struggling with their mental health in your
          company at the moment?
        </H1>
        <H4>
          Spill works by first giving support to the people who need it now.
        </H4>
        <div className="flex flex-col items-center gap-12 w-full">
          <Form.Field
            control={form.control}
            name="size"
            render={({ field }) => (
              <Form.Item className="w-full">
                <Form.Control>
                  <div className="grid md:grid-cols-5 gap-4">
                    {Array.from({ length: sizeCardTitles.length }, (_, i) => {
                      return (
                        <SelectableCard
                          key={i}
                          subtitle=""
                          size="sm"
                          title={sizeCardTitles[i] ?? ""}
                          value={i.toString()}
                          checked={i === field.value}
                          onClick={() => {
                            // Reset support types array if we are changing size to or away from 1
                            if (
                              (i === 1 || form.getValues("size") === 1) &&
                              i !== form.getValues("size")
                            ) {
                              form.setValue("types", [])
                            }
                            // Reset total number of employees to 1 if we are changing size to 1
                            if (i === 1) {
                              form.setValue("numberEmployees", 1)
                            }
                            form.setValue("size", i)
                          }}
                          {...form.register("size")}
                          ref={ref}
                          type="radio"
                        />
                      )
                    })}
                  </div>
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
          <div className="flex gap-2">
            <Button type="button" variant="secondary" onClick={reverseAction}>
              Back
            </Button>
            <Button
              type="submit"
              variant="primary"
              loading={form.formState.isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    )
  }
)

QuoteSignupSize.displayName = "QuoteSignupSize"
