import { Button, Form, H2, Input, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"

import { PmiRequest, pmiRequestSchema } from "../PmiRequestForm.schema"

interface PmiRequestFormDetailsProps {
  accessInstructions: string
  handleSubmit: (values: PmiRequest) => Promise<void>
}

export const PmiRequestFormDetails: FunctionComponent<
  PmiRequestFormDetailsProps
> = ({ accessInstructions, handleSubmit }) => {
  const form = useForm<PmiRequest>({
    resolver: zodResolver(pmiRequestSchema),
    defaultValues: {
      accountNumber: "",
      sessionsRequested: "",
    },
  })
  return (
    <>
      <H2>Request details</H2>
      <P>
        Your company has provided these details on where to find your account
        information:
      </P>
      <blockquote className="pl-4 border-l-4 border-gray-100 whitespace-pre-line">
        <P size="xs" muted>
          {accessInstructions}
        </P>
      </blockquote>
      <Form.Root {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex flex-col gap-8 mt-4"
        >
          <Form.Field
            control={form.control}
            name="accountNumber"
            render={({ field }) => (
              <Form.Item>
                <Form.Control>
                  <Input
                    {...field}
                    label={{
                      children: (
                        <>
                          Insurance membership or account number{" "}
                          <span className="text-grey-400 font-normal">
                            (optional)
                          </span>
                        </>
                      ),
                    }}
                    placeholder="See instructions above"
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
          <Form.Field
            control={form.control}
            name="sessionsRequested"
            render={({ field }) => (
              <Form.Item>
                <Form.Control>
                  <Input
                    {...field}
                    {...form.register("sessionsRequested", {
                      setValueAs: (value: string) => {
                        form.clearErrors("sessionsRequested")
                        if (isNaN(parseInt(value))) {
                          return ""
                        } else {
                          return parseInt(value)
                        }
                      },
                    })}
                    label={{
                      children: (
                        <>
                          Number of sessions requested{" "}
                          <span className="text-grey-400 font-normal">
                            (optional)
                          </span>
                        </>
                      ),
                    }}
                    placeholder="Recommended 6-8"
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
          <Button type="submit" loading={form.formState.isSubmitting}>
            Submit request
          </Button>
        </form>
      </Form.Root>
      <P size="xs" muted>
        Somebody from Spill will reach out with next steps
      </P>
    </>
  )
}
