import { useQuery } from "@apollo/client"
import { FunctionComponent } from "react"
import {
  CalendarDateRangeIcon,
  HeartIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline"
import { H2, P, StatusButtonProps } from "@spillchat/puddles"

import { SpecialisedSupportItem } from "features/specialised-support/components/SpecialisedSupportItem"
import imageParenthood from "common/assets/images/product/specialised-support/therapy-parenthood.png"
import imageBereavement from "common/assets/images/product/specialised-support/therapy-bereavement.png"
import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import customPlaceholder from "common/assets/images/product/specialised-support/therapy-custom-grey.png"
import {
  AdminTherapySpecialisedSupportQuery,
  AdminTherapySpecialisedSupportQueryVariables,
  PackageState,
} from "types/graphql"
import { SpecialisedSupportItemCreate } from "features/specialised-support/components/SpecialisedSupportItemCreate"

import { queries } from "./admin-therapy-specialised-support.queries"

export const AdminTherapySpecialisedSupport: FunctionComponent = () => {
  const { data } = useQuery<
    AdminTherapySpecialisedSupportQuery,
    AdminTherapySpecialisedSupportQueryVariables
  >(queries.getSupportData, {
    nextFetchPolicy: "network-only",
  })

  const hasAdhdSupport =
    data?.user?.company?.featuresAndSettings.adhdSupport.active ?? false
  const hasAdhdSupportEnabled =
    data?.user?.company?.featuresAndSettings.adhdSupport.value ?? false
  const hasCustomSupport =
    data?.user?.company?.featuresAndSettings.customSupport.active ?? false
  const hasArchived =
    data?.user?.company?.allCustomCompanyPackageSettings.some(
      pkg => pkg.state === PackageState.DISABLED
    ) ?? false

  const getPackageState = (packageState: PackageState | null | undefined) => {
    if (packageState == null) {
      return undefined
    }

    const states: {
      [PackageState: string]: {
        variant: StatusButtonProps["state"]
        text: string
      }
    } = {
      [PackageState.AWAITING_COUNSELLORS]: {
        variant: "pending",
        text: "Processing",
      },
    }

    return states[packageState]
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-3">
          <H2>Preset support packages</H2>
          <P muted>Suggested packages tailored to different life events</P>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-5">
          <SpecialisedSupportItem
            title="Bereavement support"
            subtitle="For grieving employees who might be on leave, or struggling on the anniversary of losing a loved one."
            list={[
              {
                Icon: UserPlusIcon,
                text: "Sessions with a bereavement specialist",
              },
              {
                Icon: HeartIcon,
                text: "A default of 6 sessions per package",
              },
              {
                Icon: CalendarDateRangeIcon,
                text: "Sessions can be used over a period of two years",
              },
            ]}
            primaryButton={{
              text: "Support an employee",
              href: "/admin/access/invite/bereavement",
            }}
            secondaryButton={{
              text: "Read manager guide",
              href: "https://spill.notion.site/9ba168e8b01a4f578765d1cbd4d97630?pvs=4",
            }}
            image={imageBereavement}
          />
          <SpecialisedSupportItem
            title="Parenthood support"
            subtitle="For new parents going through the joys of welcoming a child and the challenges around returning to work."
            list={[
              {
                Icon: UserPlusIcon,
                text: "Sessions with a parenthood specialist",
              },
              {
                Icon: HeartIcon,
                text: "A recommended 8 sessions per package",
              },
              {
                Icon: CalendarDateRangeIcon,
                text: "Sessions can be used over a period of two years",
              },
            ]}
            primaryButton={{
              text: "Support an employee",
              href: "/admin/access/invite/parenthood",
            }}
            image={imageParenthood}
          />
          {hasAdhdSupport && (
            <SpecialisedSupportItem
              title="ADHD support"
              subtitle="For employees that experience symptoms of Attention Deficit Hyperactivity Disorder, whether they've had a diagnosis or not."
              list={[
                {
                  Icon: UserPlusIcon,
                  text: "Sessions with an ADHD specialist",
                },
                {
                  Icon: HeartIcon,
                  text: "A recommended 2 sessions per employee",
                },
                {
                  Icon: CalendarDateRangeIcon,
                  text: "Sessions come out of employee's existing credits",
                },
              ]}
              primaryButton={{
                text: hasAdhdSupportEnabled
                  ? "Edit support"
                  : "Activate for your team",
                href: "/admin/access/invite/adhd",
              }}
              image={imageAdhd}
            />
          )}
        </div>
      </div>
      {hasCustomSupport && (
        <>
          <hr />
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <H2>Custom support</H2>
              <P muted>Create your own custom support package</P>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-5">
              <div className="lg:col-span-2">
                <SpecialisedSupportItemCreate />
              </div>
              <>
                {data?.user?.company?.allCustomCompanyPackageSettings
                  .filter(pkg => pkg.state !== PackageState.DISABLED)
                  .map((pkg, index) => {
                    return (
                      <SpecialisedSupportItem
                        key={index}
                        title={pkg.name ?? "Custom package"}
                        subtitle={`Custom package for ${data?.user?.company?.name ?? "your company"}`}
                        primaryButton={{
                          text: "Edit support",
                          href: "/admin/therapy/specialised-support/" + pkg.id,
                        }}
                        statusButton={getPackageState(pkg.state)}
                        image={pkg.iconUrl ?? customPlaceholder}
                        list={[
                          {
                            Icon: HeartIcon,
                            text: `Up to ${pkg.numberSessions} sessions per person`,
                          },
                          {
                            Icon: CalendarDateRangeIcon,
                            text:
                              pkg.numberMonthsToUse == null
                                ? "No time limit to access support"
                                : `Access support for up to ${pkg.numberMonthsToUse} months`,
                          },
                        ]}
                      />
                    )
                  })}
              </>
            </div>
          </div>
          {hasArchived && (
            <>
              <hr />
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-3">
                  <H2>Archived support packages</H2>
                  <P muted>
                    Packages you set up in the past that you've archived
                  </P>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-5">
                  {hasCustomSupport && (
                    <>
                      {data?.user?.company?.allCustomCompanyPackageSettings
                        .filter(pkg => pkg.state === PackageState.DISABLED)
                        .map((pkg, index) => {
                          return (
                            <SpecialisedSupportItem
                              key={index}
                              title={pkg.name ?? "Custom package"}
                              subtitle={`Custom package for ${data?.user?.company?.name ?? "your company"}`}
                              secondaryButton={{
                                text: "Support details",
                                href:
                                  "/admin/therapy/specialised-support/" +
                                  pkg.id,
                              }}
                              image={pkg.iconUrl ?? customPlaceholder}
                              list={[
                                {
                                  Icon: HeartIcon,
                                  text: `Up to ${pkg.numberSessions} sessions per person`,
                                },
                                {
                                  Icon: CalendarDateRangeIcon,
                                  text:
                                    pkg.numberMonthsToUse == null
                                      ? "No time limit to access support"
                                      : `Access support for up to ${pkg.numberMonthsToUse} months`,
                                },
                              ]}
                            />
                          )
                        })}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
