import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { H2, H6, P } from "@spillchat/puddles"

import TeamsLogo from "common/assets/images/Microsoft-Teams-logo.svg?react"
import SlackLogo from "common/assets/images/Slack-logo.svg?react"
import {
  PlatformSelectGetPlatformsQuery as GetPlatformsQuery,
  PlatformType,
} from "types/graphql"
import { convertPlatformTypeToName } from "features/admin/helpers/convertPlatformTypeToName"

const queries = {
  getPlatforms: gql`
    query PlatformSelectGetPlatforms {
      user {
        id
        company {
          id
          platforms {
            id
            name
            hasCompletedIntegration
            platformType
          }
        }
      }
    }
  `,
}

export const PlatformSelect: FunctionComponent = () => {
  const { data } = useQuery<GetPlatformsQuery>(queries.getPlatforms)

  const incompletePlatforms = data?.user?.company?.platforms.filter(
    ({ platformType, hasCompletedIntegration }) =>
      platformType !== PlatformType.EMAIL && !hasCompletedIntegration
  )

  return (
    <div className="flex flex-col gap-4">
      <H2>Create accounts for your team</H2>
      {incompletePlatforms?.map(platform => (
        <Link
          key={platform.id}
          to={`/admin/access/install/${platform.platformType.toLowerCase()}/success?companyPlatformId=${
            platform.id
          }`}
        >
          {platform.platformType === PlatformType.TEAMS ? (
            <TeamsLogo className="max-w-[64px]" />
          ) : (
            <SlackLogo className="max-w-[64px]" />
          )}
          <div className="flex-1">
            <p className="text-lg">
              Would you like to continue setting up Spill for{" "}
              {convertPlatformTypeToName(platform.platformType)} on{" "}
              <span className="font-bold">{platform.name}</span>?
            </p>
          </div>
          <h3 className="text-4xl">→</h3>
        </Link>
      ))}

      <div className="mb-4">
        <P>
          Adding Spill to your company workspace will give employees an easy way
          to book therapy in just a few clicks.
        </P>
        <P>
          Don’t worry, your employees won’t be contacted by Spill until later in
          the process.
        </P>
      </div>

      <div className="w-full text-center flex flex-col sm:flex-row justify-between rounded-lg gap-8">
        <Link
          to="/admin/access/install/slack"
          className="flex flex-col items-center w-full sm:w-1/2 bg-mono-white hover:bg-grey-50 border border-grey-200 rounded-lg transition-colors justify-center gap-4 h-60"
        >
          <div className="my-2 w-full flex flex-col items-center">
            <SlackLogo className="py-4 max-w-[48px]" />
            <P weight="medium">Add to Slack</P>
          </div>
        </Link>
        <div className="self-center">
          <H6>or</H6>
        </div>
        <Link
          to="/admin/access/install/teams"
          className="flex flex-col cursor-pointer items-center w-full sm:w-1/2 bg-mono-white hover:bg-grey-50 border border-grey-200 rounded-lg transition-colors justify-center gap-4 h-60"
        >
          <div className="my-2 w-full flex flex-col items-center">
            <TeamsLogo className="py-4 max-w-[48px]" />
            <P weight="medium">Add to Teams</P>
          </div>
        </Link>
      </div>
    </div>
  )
}
