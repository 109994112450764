import type { ReactNode } from "react"

type ExternalLinkProps = {
  children: ReactNode
  to: string
}

export function ExternalLink({ children, to }: ExternalLinkProps): JSX.Element {
  return (
    <a
      className="border-b-2 border-grey-400"
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
