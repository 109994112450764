// This will be made generic and moved into Puddles

import { ButtonProps, InputStepper, Label } from "@spillchat/puddles"
import { FunctionComponent, ReactNode, useEffect, useState } from "react"

type DurationState = "preset" | "custom"

const ToggleButton = (
  props: ButtonProps & { children?: ReactNode; selected?: boolean }
) => {
  if (props.selected === true) {
    return (
      <button
        {...props}
        className="inline-flex min-w-32 items-center justify-center ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-spill-blue-400 active:outline-none disabled:pointer-events-none disabled:text-spill-grey-400 rounded-lg h-auto w-full lg:w-max px-5 py-2 text-sm border bg-blue-800 text-mono-white border-blue-800 disabled:border-spill-grey-400 font-semibold"
      >
        {props.children}
      </button>
    )
  }

  return (
    <button
      {...props}
      className="inline-flex min-w-32 items-center justify-center ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-spill-blue-400 active:outline-none disabled:pointer-events-none disabled:text-spill-grey-400 rounded-lg h-auto w-full lg:w-max px-5 py-2 text-sm border bg-grey-100 border-blue-800 text-blue-800 disabled:border-spill-grey-400 font-semibold"
    >
      {props.children}
    </button>
  )
}

type AdminTherapyToggleButtonsProps = {
  onButtonClick: (value: number | null) => void
  onStepperChange: (value: number) => void
  value: number | null
}

export const AdminTherapyToggleButtons: FunctionComponent<
  AdminTherapyToggleButtonsProps
> = ({
  onButtonClick,
  onStepperChange,
  value,
}: AdminTherapyToggleButtonsProps) => {
  const [durationState, setDurationState] = useState<DurationState>("preset")

  const durationPresets = [
    {
      label: "1 month",
      value: 1,
    },
    {
      label: "3 months",
      value: 3,
    },
    {
      label: "6 months",
      value: 6,
    },
    {
      label: "2 years",
      value: 24,
    },
  ]

  useEffect(() => {
    const isPreset = durationPresets.find(preset => preset.value === value)

    if (isPreset == null && value !== null) {
      setDurationState("custom")
    }
  }, [value])

  return (
    <>
      <div className="flex gap-2 flex-wrap items-center">
        {durationPresets.map(preset => {
          return (
            <ToggleButton
              key={preset.value}
              selected={value === preset.value && durationState === "preset"}
              onClick={e => {
                e.preventDefault()
                setDurationState("preset")
                onButtonClick(preset.value)
              }}
            >
              {preset.label}
            </ToggleButton>
          )
        })}

        <ToggleButton
          selected={durationState === "custom"}
          onClick={e => {
            e.preventDefault()
            setDurationState("custom")
          }}
        >
          Custom
        </ToggleButton>

        <ToggleButton
          selected={value === null && durationState === "preset"}
          onClick={e => {
            e.preventDefault()
            setDurationState("preset")
            onButtonClick(null)
          }}
        >
          {"No expiry"}
        </ToggleButton>
      </div>
      {durationState === "custom" && (
        <div className="flex flex-col gap-2">
          <Label>Custom duration (in months)</Label>
          <InputStepper
            value={value?.toString() ?? undefined}
            onStepperChange={value => {
              onStepperChange(+value)
            }}
          />
        </div>
      )}
    </>
  )
}
