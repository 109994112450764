import { Accordion, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

const accordionItems = [
  {
    trigger: "How many sessions will I get?",
    content: (
      <P>
        This depends on your policy and how many sessions your private medical
        insurer approves. To understand exactly what&apos;s covered by your
        policy, follow your company&apos;s access instructions. If you&apos;re
        still unclear, you can always email{" "}
        <a className="underline" href="mailto:therapy@spill.chat">
          therapy@spill.chat
        </a>{" "}
        and we will help coordinate with your company to find out what
        you&apos;re entitled to request on private medical insurance.
      </P>
    ),
  },
  {
    trigger: "Can I still see the same therapist?",
    content: (
      <P>
        Currently around 15% of Spill therapists also work with private medical
        insurers. When you make the initial request for sessions from your
        private medical insurance, we will let you know whether your current
        therapist is already registered. If not, we&apos;ll ask your therapist
        if they would like to register now so they can continue working with
        you. In the case that they&apos;re not able to do that immediately,
        we&apos;ll send over some options for other registered therapists. Or,
        if you&apos;d prefer to continue seeing your original therapist,
        we&apos;ll let you know how you can pay for more sessions with them
        yourself.
      </P>
    ),
  },
  {
    trigger: "Will this cost me money?",
    content: (
      <P>
        If you have an excess in your policy, you may have to contribute towards
        the sessions, but the bulk of the cost (if not all of it) will be paid
        by your private medical insurer. To find out more information about your
        excess, follow your company&apos;s access instructions.
      </P>
    ),
  },
]

export const PmiPageFAQ: FunctionComponent = () => {
  return <Accordion items={accordionItems} />
}
