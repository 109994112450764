import { FunctionComponent, useState } from "react"
import { useFormContext } from "react-hook-form"
import { gql, useQuery } from "@apollo/client"
import { isEmpty } from "lodash"
import { Button, P, Tooltip, cn } from "@spillchat/puddles"
import { motion, AnimatePresence } from "framer-motion"

import { FormSearchParamsSync } from "common/components/FormSearchParamsSync"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import {
  BookableAppointmentType,
  FiltersQueryFieldsQuery,
  FiltersQueryFieldsQueryVariables,
  Gender,
} from "types/graphql"
import { isEnum } from "types/typeguards"
import { useUser } from "common/context/userContext"

import {
  CounsellorSelect,
  fragments as CounsellorSelectFragments,
} from "./CounsellorSelect"
import {
  GenderSelect,
  fragments as GenderSelectFragments,
} from "./GenderSelect"
import {
  LanguageSelect,
  fragments as LanguageSelectFragments,
} from "./LanguageSelect"
import {
  SpecialismSelect,
  fragments as SpecialismSelectFragments,
} from "./SpecialismSelect"
import { PmiSelect, fragments as PmiSelectFragments } from "./PmiSelect"

export const queries = {
  queryFields: gql`
    query FiltersQueryFields(
      $appointmentType: BookableAppointmentType!
      $filter: AvailableAppointmentSlotsFilter!
    ) {
      matchingCriteriaOptions(
        appointmentType: $appointmentType
        filter: $filter
      ) {
        pmi
        ...GenderSelectQueryFields
        ...LanguageSelectQueryFields
        ...SpecialismSelectQueryFields
      }
      user {
        ...CounsellorSelectQueryFields
        ...PmiSelectQueryFields
      }
    }
    ${CounsellorSelectFragments.queryFields}
    ${LanguageSelectFragments.queryFields}
    ${SpecialismSelectFragments.queryFields}
    ${GenderSelectFragments.queryFields}
    ${PmiSelectFragments.queryFields}
  `,
}

interface FiltersProps {
  appointmentType: BookableAppointmentType
}

export const Filters: FunctionComponent<FiltersProps> = props => {
  const { watch, getValues } = useFormContext<FormValues>()
  const [viewingFilters, setViewingFilters] = useState(false)
  const [viewingPreviousCounsellors] = useState(false)
  const { flags } = useUser()
  const appointmentTypes = [
    BookableAppointmentType.BEREAVEMENT_CONSULTATION,
    BookableAppointmentType.BEREAVEMENT_SESSION,
    BookableAppointmentType.PARENTHOOD_CONSULTATION,
    BookableAppointmentType.PARENTHOOD_SESSION,
  ]

  const pmiOnly = watch("filter.pmiOnly")
  const gender = watch("filter.gender")
  const language = watch("filter.language")
  const specialism = watch("filter.specialism")

  const { data: newData, previousData } = useQuery<
    FiltersQueryFieldsQuery,
    FiltersQueryFieldsQueryVariables
  >(queries.queryFields, {
    variables: {
      appointmentType: props.appointmentType,
      filter: {
        pmiOnly,
        gender: gender == "" ? undefined : gender,
        languageCode: language,
        specialism: specialism == "" ? undefined : specialism,
      },
    },
  })
  const data = newData ?? previousData

  const isPrevCounsellorIdSet = !isEmpty(watch("filter.previousCounsellorId"))
  const areNonCounsellorFiltersSet =
    isEnum(getValues().filter.gender, Gender) ||
    !isEmpty(getValues().filter.specialism)
  const showCounsellorSelect =
    (data?.user?.previousCounsellors ?? []).length > 0

  const showPmi =
    data?.matchingCriteriaOptions.pmi == true && flags["web-app.pmiFilter"]

  return (
    <>
      <FormSearchParamsSync<FormValues>
        paths={[
          { formName: "filter.gender", searchParamName: "gender" },
          {
            formName: "filter.language",
            searchParamName: "language",
          },
          {
            formName: "filter.previousCounsellorId",
            searchParamName: "previousCounsellorId",
          },
          { formName: "filter.specialism", searchParamName: "specialism" },
        ]}
      />

      <div
        className={cn("w-full flex flex-col lg:flex-row justify-between", {
          "pb-4 border-b border-b-spill-grey-200":
            !viewingFilters && !viewingPreviousCounsellors,
        })}
      >
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger>
              {showCounsellorSelect && (
                <CounsellorSelect
                  data={data?.user ?? undefined}
                  name="filter.previousCounsellorId"
                />
              )}
            </Tooltip.Trigger>
            {areNonCounsellorFiltersSet && (
              <Tooltip.Content>
                Selecting a counsellor when you have selected other filters will
                clear those filters.
              </Tooltip.Content>
            )}
          </Tooltip.Root>
          <Tooltip.Root>
            <Tooltip.Trigger>
              <Button
                variant="tertiary"
                size="sm"
                onClick={() => setViewingFilters(!viewingFilters)}
              >
                {viewingFilters ? "Hide filters" : "Show filters"}
              </Button>
            </Tooltip.Trigger>
            {isPrevCounsellorIdSet && (
              <Tooltip.Content>
                Selecting filters when you have selected a previous counsellor
                will clear the previous counsellor.
              </Tooltip.Content>
            )}
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>

      <AnimatePresence>
        {viewingFilters && (
          <motion.div
            className="w-full flex flex-col lg:flex-row items-start gap-8 border-b border-b-spill-grey-200 pb-4 pt-2"
            initial={{ opacity: 0, y: -4 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div className="flex flex-col gap-2 min-w-[180px]">
              <P size="xs" muted>
                Counsellor gender
              </P>
              <div className="flex gap-2 flex-wrap">
                <GenderSelect
                  name="filter.gender"
                  data={data?.matchingCriteriaOptions}
                />
              </div>
            </div>
            {!appointmentTypes.includes(props.appointmentType) && (
              <div className="flex flex-col gap-2">
                <P size="xs" muted>
                  Session Focus
                </P>
                <SpecialismSelect
                  name="filter.specialism"
                  data={data?.matchingCriteriaOptions}
                />
              </div>
            )}
            <div className="flex flex-col gap-2 min-w-[120px]">
              <P size="xs" muted>
                Preferred Language
              </P>
              <LanguageSelect
                name="filter.language"
                data={data?.matchingCriteriaOptions}
              />
            </div>
            {showPmi && (
              <div className="flex flex-col gap-2 min-w-[120px]">
                <P size="xs" muted>
                  Health Insurance registration
                </P>
                <PmiSelect
                  name="filter.pmiOnly"
                  data={data?.user ?? undefined}
                />
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
