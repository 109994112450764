import { Button, H4, Indicator, P } from "@spillchat/puddles"
import { FunctionComponent, SetStateAction } from "react"
import { createPortal } from "react-dom"
import { ApolloQueryResult } from "@apollo/client"
import { Link } from "react-router-dom"

import { PmiSettingsGetPmiSettingValueAndDetailsQuery } from "types/graphql"

import { PmiSetupForm } from "../../PmiSetupForm"

interface PmiSetupCardProps {
  active: boolean
  isPmiFormOpen: boolean
  setPmiFormOpen: (value: SetStateAction<boolean>) => void
  refetch: () => Promise<
    ApolloQueryResult<PmiSettingsGetPmiSettingValueAndDetailsQuery>
  >
}

export const PmiSetupCard: FunctionComponent<PmiSetupCardProps> = ({
  active,
  isPmiFormOpen,
  setPmiFormOpen,
  refetch,
}) => {
  return (
    <>
      <div className="col-span-2 flex flex-col justify-center gap-4 px-6 md:px-0">
        <div className="flex items-center gap-2">
          <H4>Add PMI pathway</H4>
          <Indicator>Beta</Indicator>
        </div>
        <P size="xs">
          Add a pathway from Spill to your company's private medical insurance
        </P>
        {!active ? (
          <Button size="sm" variant="secondary" asChild>
            <Link to="/admin/help?topic=therapy_access">Register interest</Link>
          </Button>
        ) : (
          <Button size="sm" onClick={() => setPmiFormOpen(true)}>
            Set up
          </Button>
        )}
      </div>
      {isPmiFormOpen &&
        createPortal(
          <PmiSetupForm setOpen={setPmiFormOpen} refetch={refetch} />,
          document.body
        )}
    </>
  )
}
