import { z } from "zod"

export const formSchema = z.object({
  userId: z.string(),
  selectedIds: z.array(
    z.object({
      id: z.string(),
    })
  ),
  notificationType: z.enum(["email", "none"]),
  note: z
    .string()
    .max(250, {
      message: "Please limit your note to 250 characters",
    })
    .optional(),
})

export type AdminTherapySpecialisedSupportForm = z.infer<typeof formSchema>
