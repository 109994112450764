import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "tailwind.config.js"

import type { DefaultTheme } from "tailwindcss/types/generated/default-theme"

const fullConfig = resolveConfig(tailwindConfig)

// Ideally would generate types from definitions in config/tailwind.js
// rather than duplicating them here
type ExtendedColors = {
  transparent: "transparent"
  mono: {
    black: "#000000"
    white: "#FFFFFF"
  }
  grey: {
    100: "#F6F5F9"
    200: "#D3D3DB"
    400: "#707078"
    600: "#6C6C71"
  }
  blue: {
    100: "#E8EDFF"
    200: "#C8D5FF"
    400: "#8DA9FF"
    800: "#0C3CAD"
    900: "#0C1A66"
  }
  teal: {
    100: "#D6F5EF"
    200: "#A4EDE3"
    400: "#35D0BA"
    600: "#08B89F"
  }
  red: {
    100: "#F9D3CF"
    200: "#FFB0A8"
    400: "#FB7466"
    600: "#D82C29"
  }
  yellow: {
    100: "#FFF8E7"
    200: "#FFECBE"
    400: "#F8CF60"
    600: "#EFB108"
  }
}

type Theme = DefaultTheme & { colors: ExtendedColors }

export function useTheme(): Theme {
  return (fullConfig as unknown as { theme: Theme }).theme
}
