import {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { useFormContext } from "react-hook-form"
import { capitalize } from "inflection"
import { gql } from "@apollo/client"
import { Tag } from "@spillchat/puddles"

import { SelectProps } from "common/components/FormElements/Select"
import { Skeleton } from "common/components/Skeleton"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { SpecialismSelectQueryFieldsFragment } from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment SpecialismSelectQueryFields on MatchingCriteriaOptions {
      specialisms
    }
  `,
}

interface SpecialismSelectProps
  extends Omit<SelectProps<FormValues>, "options" | "register"> {
  data?: SpecialismSelectQueryFieldsFragment
}

export const SpecialismSelect: FunctionComponent<SpecialismSelectProps> = ({
  data,
}) => {
  const { setValue, getValues, watch } = useFormContext<FormValues>()
  const value = watch("filter.specialism")
  const supportedSpecialisms = data?.specialisms ?? []
  const isInvalid = value !== null && !supportedSpecialisms.includes(value)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [canExpandSection, setCanExpandSection] = useState(false)

  useEffect(() => {
    if (isInvalid) setValue("filter.specialism", "")
  }, [isInvalid])

  useLayoutEffect(() => {
    if (scrollRef.current?.clientHeight == null) {
      return
    }

    if (scrollRef.current?.clientHeight < scrollRef.current.scrollHeight) {
      setCanExpandSection(true)
    }
  }, [scrollRef])

  const onSpecialismSelect = (value: string) => {
    setValue("filter.previousCounsellorId", null)

    if (value === getValues("filter.specialism")) {
      setValue("filter.specialism", null)
    } else {
      setValue("filter.specialism", value)
    }
  }

  if (!data) return <Skeleton className="h-10 rounded-md" />

  return (
    <div className="flex flex-col w-full relative">
      <div
        className="flex gap-2 flex-wrap lg:max-h-44 overflow-auto"
        ref={scrollRef}
      >
        {supportedSpecialisms.map(option => {
          return (
            <Tag
              key={option}
              selected={getValues("filter.specialism") === option}
              onSelect={() => onSpecialismSelect(option)}
              size="xs"
            >
              {["adhd", "cbt", "ptsd"].includes(option)
                ? option.toUpperCase()
                : capitalize(option)}
            </Tag>
          )
        })}
      </div>
      {
        canExpandSection && null
        // @TODO - Add mechanism to expand section
      }
    </div>
  )
}
