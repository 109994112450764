import { type FunctionComponent } from "react"
import { gql, useMutation } from "@apollo/client"
import { Alert, Button, H4, IconCard, P, Tooltip } from "@spillchat/puddles"
import { format } from "date-fns"
import { toast } from "sonner"
import { Link, useNavigate } from "react-router-dom"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

import { useAnalytics } from "common/context/analyticsContext"
import {
  CompanyPackageSetting,
  ExtensionRequestListUpdateCompanyCapMutation,
  ExtensionRequestListUpdateCompanyCapMutationVariables,
  ExtensionRequestsListItemApproveExtensionMutationVariables,
  User,
} from "types/graphql"
import { useApp } from "common/context/appContext"

import { queries } from "./ExtensionRequestList"

export const mutations = {
  approveExtension: gql`
    mutation ExtensionRequestsListItemApproveExtension($id: ID!) {
      approveTherapyExtension(id: $id) {
        id
        status
      }
    }
  `,

  updateCompanyCap: gql`
    mutation ExtensionRequestListUpdateCompanyCap(
      $yearlyTherapyBudget: Int!
      $userTherapyCap: Int
      $userCanRequestTherapy: Boolean!
    ) {
      updateCompanySettings(
        yearlyTherapyBudget: $yearlyTherapyBudget
        userTherapyCap: $userTherapyCap
        userCanRequestTherapy: $userCanRequestTherapy
      ) {
        id
        yearlyTherapyBudget {
          value
        }
        userTherapyCap {
          value
        }
        userCanRequestTherapy {
          value
        }
      }
    }
  `,
}

interface ExtensionRequestListItemProps {
  extension: {
    id: string
    expiryDate: string
    isAnonymous: boolean
    noteFromUser?: string | null | undefined
    numberOfSessions?: number | null
    user?: Pick<User, "id" | "displayName"> | null
    companyPackageSetting?: Partial<CompanyPackageSetting> | null
  }
  doesApprovalRequireBudgetIncrease: boolean
  newYearlyBudget?: {
    totalBillableUsage?: number
    numberOfSessions?: number
  }
  companyBudget: number | null | undefined
  userTherapyCap: number | null | undefined
  userCanRequestTherapy: boolean | null | undefined
  companyHasActiveSubscription: boolean
  singleSessionPrice: number | null | undefined
  companyHasSessionPack: boolean
  companyInActiveTrial: boolean
}

export const ExtensionRequestListItem: FunctionComponent<
  ExtensionRequestListItemProps
> = ({
  extension,
  doesApprovalRequireBudgetIncrease,
  newYearlyBudget,
  companyBudget,
  userTherapyCap,
  userCanRequestTherapy,
  companyHasActiveSubscription,
  singleSessionPrice,
  companyHasSessionPack,
  companyInActiveTrial,
}) => {
  const { setAppModalContent } = useApp()
  const { track } = useAnalytics()
  const navigate = useNavigate()
  const [approveExtensionMutation, { loading: isApproving }] =
    useMutation<ExtensionRequestsListItemApproveExtensionMutationVariables>(
      mutations.approveExtension,
      { refetchQueries: [queries.getData] }
    )

  const [updateCompanyCap, { loading: isUpdatingCompanyCap }] = useMutation<
    ExtensionRequestListUpdateCompanyCapMutation,
    ExtensionRequestListUpdateCompanyCapMutationVariables
  >(mutations.updateCompanyCap, { refetchQueries: [queries.getData] })

  const approveExtension = async (newYearlyBudget?: number | null) => {
    await approveExtensionMutation({
      variables: {
        id: extension.id,
      },
      onCompleted: () => {
        setAppModalContent(null)
      },
      onError: () => {
        toast.error("Something went wrong. Please try again.")
      },
    })

    if (newYearlyBudget != null) {
      if (userTherapyCap == null || userCanRequestTherapy == null) {
        toast.error("Something went wrong. Please try again.")
        return
      }
      await updateCompanyCap({
        variables: {
          yearlyTherapyBudget: newYearlyBudget,
          userTherapyCap: userTherapyCap,
          userCanRequestTherapy: userCanRequestTherapy,
        },
        onCompleted: () => {
          if (newYearlyBudget == null) {
            setAppModalContent(null)
          }
        },
        onError: () => {
          toast.error("Something went wrong. Please try again.")
        },
      })
    }
    track("Admin approved therapy extension", { id: extension.id })
  }

  const openConfirmationDialog = () => {
    const calculateIncreaseTotal = () => {
      if (
        newYearlyBudget?.numberOfSessions == null ||
        newYearlyBudget?.totalBillableUsage == null
      ) {
        toast.error(
          "We were unable to determine the budget increase. Please try again."
        )
        return
      }

      const increaseTotal =
        Math.ceil(newYearlyBudget.totalBillableUsage ?? 0) +
        newYearlyBudget.numberOfSessions
      return increaseTotal
    }

    const increaseAmount = doesApprovalRequireBudgetIncrease
      ? calculateIncreaseTotal()
      : null

    setAppModalContent({
      size: "lg",
      title: "Approve extension request",
      content: (
        <div className="flex flex-col gap-4">
          <H4>
            {extension.isAnonymous || extension?.user?.displayName == null
              ? "Someone "
              : extension.user.displayName}{" "}
            has requested {extension.numberOfSessions} extra sessions
          </H4>
          {extension.noteFromUser != null && extension.noteFromUser !== "" && (
            <P>{extension.noteFromUser}</P>
          )}
          {doesApprovalRequireBudgetIncrease && (
            <Alert variant="warning" title="Company budget reached">
              <P size="xs">
                Your company has an annual therapy budget of {companyBudget}{" "}
                sessions. Approving this request will increase your company's
                budget to {increaseAmount} sessions.
              </P>
            </Alert>
          )}
          <Button
            onClick={async () => await approveExtension(increaseAmount)}
            loading={isApproving || isUpdatingCompanyCap}
          >
            Approve
          </Button>
        </div>
      ),
    })
    track("Admin opened confirmation therapy extension dialog", {
      id: extension.id,
    })
  }

  const nonTrialPaymentRequiredComponent = (
    <div className="flex flex-col gap-2 md:flex-row">
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger>
            <Button
              variant="primary"
              size="sm"
              onClick={openConfirmationDialog}
              disabled={!companyHasActiveSubscription}
            >
              Approve
            </Button>
          </Tooltip.Trigger>
          {!companyHasActiveSubscription && (
            <Tooltip.Content title="Add payment to unlock">
              <Link
                to="/admin/billing"
                className="underline underline-offset-2"
              >
                Add your payment
              </Link>{" "}
              details to approve therapy requests
            </Tooltip.Content>
          )}
        </Tooltip.Root>
      </Tooltip.Provider>
      <Button
        variant="secondary"
        size="sm"
        onClick={() =>
          navigate(`/admin/therapy/extension/decline/${extension.id}`)
        }
      >
        Decline with note
      </Button>
    </div>
  )

  const trialPaymentRequiredComponent = (
    <div className="flex flex-col gap-2 md:flex-row">
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger>
            <Button
              variant="primary"
              size="sm"
              onClick={openConfirmationDialog}
              disabled={!companyHasActiveSubscription}
            >
              Approve
            </Button>
          </Tooltip.Trigger>
          {!companyHasActiveSubscription && (
            <Tooltip.Content title="Add payment details">
              You need to add your payment details to handle requests for extra
              sessions. You can do this by getting in touch with your Spill
              contact.
            </Tooltip.Content>
          )}
        </Tooltip.Root>
      </Tooltip.Provider>
      <Button
        variant="secondary"
        size="sm"
        onClick={() =>
          navigate(`/admin/therapy/extension/decline/${extension.id}`)
        }
      >
        Decline with note
      </Button>
    </div>
  )

  const sessionPackPricingNoteComponent = (
    <div className="flex flex-col gap-2 md:flex-row">
      <InformationCircleIcon className="size-4" />
      <P size="xs" muted>
        {" "}
        Additional sessions will be charged at £{singleSessionPrice ?? 70} per
        session and will not affect session&nbsp;packs{" "}
      </P>
    </div>
  )

  return (
    <li key={extension.id}>
      <IconCard
        title={
          extension.isAnonymous || extension?.user?.displayName == null
            ? "Someone "
            : extension.user.displayName
        }
        subtitle={`Request for ${extension.numberOfSessions ?? extension.companyPackageSetting?.numberSessions ?? "more"} sessions`}
        icon={{
          name: "Rotate",
          size: "sm",
          type: "outline",
          background: {
            color: "blue",
            shape: "circle",
          },
        }}
        description={
          <>
            <P muted size="xs">
              Respond by: {format(new Date(extension.expiryDate), "d MMM yyyy")}
            </P>
            {extension.noteFromUser != null &&
              extension.noteFromUser !== "" && (
                <P muted size="xs">
                  {extension.noteFromUser}
                </P>
              )}
          </>
        }
      >
        {!companyInActiveTrial && nonTrialPaymentRequiredComponent}
        {!companyInActiveTrial &&
          companyHasSessionPack &&
          sessionPackPricingNoteComponent}
        {companyInActiveTrial && trialPaymentRequiredComponent}
      </IconCard>
    </li>
  )
}
