import { gql } from "@apollo/client"

import { fragments as profileInfoFragments } from "../components/ProfileInfo.fragments"
import { fragments as loginMethodsFragments } from "../components/LoginMethods.fragments"

export const queries = {
  getUser: gql`
    query ProfilePageGetUser {
      user {
        id
        ...ProfileInfoGetUser
        ...LoginMethodsGetLoginMethods
      }
    }
    ${profileInfoFragments.getUser}
    ${loginMethodsFragments.getLoginMethods}
  `,
}
