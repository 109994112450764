import { FunctionComponent, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useBoolean } from "react-use"

import { useAnalytics } from "common/context/analyticsContext"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { ErrorText } from "common/components/FormElements/ErrorText"

import { FormSection } from "./FormSection"

type Props = {
  idx: number
  question: string
}

export const BereavementCheckSection: FunctionComponent<Props> = ({
  idx,
  question,
}: Props) => {
  const { formState, getFieldState, register } = useFormContext<FormValues>()

  const isDirty = getFieldState(
    `baselineQuestions.${idx}.answer`,
    formState
  ).isDirty

  const { track } = useAnalytics()
  const [hasTracked, toggleHasTracked] = useBoolean(false)

  useEffect(() => {
    if (!hasTracked && isDirty) {
      track(
        "User enters baseline question",
        { Field: "Experienced loss" },
        toggleHasTracked
      )
    }
  }, [hasTracked, isDirty])

  return (
    <FormSection title={`${idx}. ${question}*`}>
      <ul className="flex flex-col-reverse lg:flex-row-reverse gap-[inherit]">
        {Array.from({ length: 2 }, (_val, index) => (
          <li
            className="border border-blue-800 h-10 rounded w-full"
            key={index}
          >
            <input
              hidden
              value={"baseline"}
              {...register(`baselineQuestions.${idx}.section`)}
            />
            <input
              hidden
              value={question}
              {...register(`baselineQuestions.${idx}.question`)}
            />
            <input
              className="peer"
              hidden
              id={`experiencedLoss${index}`}
              type="radio"
              value={index ? "Yes" : "No"}
              {...register(`baselineQuestions.${idx}.answer`, {
                required: "Required",
              })}
            />
            <label
              className="cursor-pointer flex h-full items-center justify-center w-full peer-checked:bg-blue-800 peer-checked:text-mono-white"
              htmlFor={`experiencedLoss${index}`}
            >
              {index ? "Yes" : "No"}
            </label>
          </li>
        ))}
      </ul>
      <ErrorText name="baselineQuestions.experiencedLoss" />
    </FormSection>
  )
}
