import { ReactElement } from "react"
import { FieldValues, Path, useFormContext } from "react-hook-form"
import { get } from "lodash"

interface ErrorTextProps<T extends FieldValues> {
  /**
   * Name of the field that we're adding to the form.
   * This is used to get the error state for correct input.
   */
  name: Path<T>
}

export const ErrorText = <FormValues extends FieldValues>({
  name,
}: ErrorTextProps<FormValues>): ReactElement<FormValues> => {
  const {
    formState: { errors },
  } = useFormContext<FormValues>()
  const error = get(errors, name)

  if (error?.message != undefined) {
    return (
      <p className="font-inter text-red-400 text-sm tracking-[0.2px]">
        {typeof error.message == "string" ? error.message : "unknown error"}
      </p>
    )
  }

  return <></>
}
