import { FunctionComponent } from "react"
import { useFormContext } from "react-hook-form"
import { gql } from "@apollo/client"
import { Tag, Form, Select, Tooltip } from "@spillchat/puddles"

import { SelectProps } from "common/components/FormElements/Select"
import { Skeleton } from "common/components/Skeleton"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { CounsellorSelectQueryFieldsFragment } from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment CounsellorSelectQueryFields on User {
      previousCounsellors {
        id
        firstName
      }
    }
  `,
}

interface CounsellorSelectProps
  extends Omit<
    SelectProps<FormValues>,
    "disabled" | "options" | "register" | "showClearButton" | "tooltip"
  > {
  data?: CounsellorSelectQueryFieldsFragment
}

export const CounsellorSelect: FunctionComponent<CounsellorSelectProps> = ({
  data,
}) => {
  const { setValue, getValues, control } = useFormContext<FormValues>()

  const counsellorArray = data?.previousCounsellors ?? []

  const options = Array.from(
    new Map(
      counsellorArray.map(counsellor => [
        counsellor.id,
        {
          key: counsellor.id,
          label: counsellor.firstName,
          value: counsellor.id,
        },
      ])
    ).values()
  )

  if (!data) return <Skeleton className="grow h-10 rounded-md" />

  return (
    <>
      {getValues("filter.previousCounsellorId") == null ? (
        <Form.Field
          name="filter.previousCounsellorId"
          control={control}
          render={() => (
            <Select.Root
              onValueChange={value => {
                setValue("filter.specialism", null)
                setValue("filter.gender", null)
                setValue("filter.language", null)
                setValue("filter.previousCounsellorId", value)
              }}
              value={getValues("filter.previousCounsellorId") ?? ""}
              defaultValue={options.find(element => element.key === "–")?.value}
            >
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger>
                    <Select.Trigger className="min-w-[180px]" size="sm">
                      <Select.Value placeholder="Previous counsellor" />
                    </Select.Trigger>
                  </Tooltip.Trigger>
                  <Tooltip.Content side="right">
                    Availability of previous counsellors excluded unless
                    selected
                  </Tooltip.Content>
                </Tooltip.Root>
              </Tooltip.Provider>
              <Select.Content>
                {options.map(option => (
                  <Select.Item key={option.key} value={option.value}>
                    {option.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          )}
        />
      ) : (
        <Tag
          selected
          onSelect={() => setValue("filter.previousCounsellorId", undefined)}
        >
          {
            options.find(
              counsellor =>
                counsellor.value === getValues("filter.previousCounsellorId")
            )?.label
          }
        </Tag>
      )}
    </>
  )
}
