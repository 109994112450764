import { FunctionComponent } from "react"
import { Helmet } from "react-helmet-async"
import { useQuery } from "@apollo/client"
import { H1 } from "@spillchat/puddles"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import { ProfilePageGetUserQuery } from "types/graphql"

import { ProfileInfo } from "../components/ProfileInfo"
import { LoginMethods } from "../components/LoginMethods"
import { ProfileLinkAccount } from "../components/ProfileLinkAccount"

import { queries } from "./Profile.queries"

export const ProfilePage: FunctionComponent = () => {
  const { data, loading } = useQuery<ProfilePageGetUserQuery>(queries.getUser)

  return (
    <>
      <Helmet>
        <title>Account Details | Spill</title>
      </Helmet>
      {loading ? (
        <LoadingSpinner sizeInPixels={32} />
      ) : (
        <div className="flex flex-col gap-12 max-w-screen-sm">
          <H1>Account Details</H1>
          <ProfileInfo profileInfo={data?.user} />
          <hr />
          <LoginMethods loginMethods={data?.user} />
          <hr />
          <ProfileLinkAccount />
        </div>
      )}
    </>
  )
}
