import { FunctionComponent, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { Button, DropdownMenu, P } from "@spillchat/puddles"
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  EllipsisHorizontalIcon,
  TrashIcon,
} from "@heroicons/react/24/outline"

import {
  AccountStatus,
  PeopleListItemActivateUserMutation,
  PeopleListItemActivateUserMutationVariables,
  PeopleListItemDeactivateUserMutation,
  PeopleListItemDeactivateUserMutationVariables,
  PeopleListItemMakeAdminMutation,
  PeopleListItemMakeAdminMutationVariables,
  PeopleListItemMakeAppUserMutation,
  PeopleListItemMakeAppUserMutationVariables,
  PlatformType,
  UserRole,
} from "types/graphql"
import { Dialog } from "common/components/Dialog"

import { TableUser } from "./AccessTable"

type AccessTableActionProps = {
  person: TableUser
  originalPlatform: PlatformType | undefined
  loggedInUserId?: string
}

const mutations = {
  makeAppUser: gql`
    mutation PeopleListItemMakeAppUser($platformUserId: ID!) {
      makeAppUserForPlatformUser(platformUserId: $platformUserId) {
        id
        user {
          id
        }
      }
    }
  `,
  activateUser: gql`
    mutation PeopleListItemActivateUser($targetUserId: ID!) {
      activateUser(targetUserId: $targetUserId) {
        id
        accountStatus
      }
    }
  `,
  deactivateUser: gql`
    mutation PeopleListItemDeactivateUser($targetUserId: ID!) {
      deactivateUser(targetUserId: $targetUserId) {
        id
        accountStatus
        role
      }
    }
  `,
  changeUserRole: gql`
    mutation PeopleListItemMakeAdmin($targetUserId: ID!, $role: UserRole!) {
      changeUserRole(targetUserId: $targetUserId, role: $role) {
        id
        role
      }
    }
  `,
}

export const AccessTableAction: FunctionComponent<AccessTableActionProps> = ({
  person,
  originalPlatform,
  loggedInUserId,
}: AccessTableActionProps) => {
  const isCurrentUser = "userId" in person && person.userId === loggedInUserId
  const accountNotFound = person.accountStatus !== AccountStatus.NOT_FOUND
  const name = person.fullName
  const originallySlackOrTeams =
    originalPlatform === PlatformType.SLACK ||
    originalPlatform === PlatformType.TEAMS

  const [activateUser] = useMutation<
    PeopleListItemActivateUserMutation,
    PeopleListItemActivateUserMutationVariables
  >(mutations.activateUser)
  const [deactivateUser] = useMutation<
    PeopleListItemDeactivateUserMutation,
    PeopleListItemDeactivateUserMutationVariables
  >(mutations.deactivateUser)
  const [makeAppUser] = useMutation<
    PeopleListItemMakeAppUserMutation,
    PeopleListItemMakeAppUserMutationVariables
  >(mutations.makeAppUser, {
    refetchQueries: ["PeopleListGetUser"],
    awaitRefetchQueries: true,
  })
  const [changeUserRole] = useMutation<
    PeopleListItemMakeAdminMutation,
    PeopleListItemMakeAdminMutationVariables
  >(mutations.changeUserRole)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false)
  const [confirmMessage, setConfirmMessage] = useState("")
  const [submitAction, setSubmitAction] = useState(
    () => async () => await Promise.resolve()
  )

  const makeDropdownAction = ({
    icon,
    text,
    confirmationMessage,
    submitAction,
  }: {
    icon: typeof CheckIcon
    text: string
    confirmationMessage: string
    submitAction: () => Promise<void>
  }): { text: string; icon: typeof CheckIcon; onClick: () => void } => {
    return {
      icon,
      text,
      onClick: () => {
        setSubmitAction(() => async () => {
          await submitAction()
          setIsConfirmationModalOpen(false)
        })
        setConfirmMessage(confirmationMessage)
        setIsConfirmationModalOpen(true)
      },
    }
  }

  const actions = []
  if (person.accountStatus === AccountStatus.NO_ACCESS) {
    if ("userId" in person) {
      const targetUserId = person.userId
      actions.push(
        makeDropdownAction({
          text: "Activate account",
          confirmationMessage: `Confirm the re-activation of access to Spill for ${name}.`,
          submitAction: async () => {
            await activateUser({
              variables: { targetUserId },
            })
          },
          icon: CheckIcon,
        })
      )
    }
  } else {
    actions.push(
      makeDropdownAction({
        text: "Deactivate account",
        confirmationMessage: `Confirm the deactivation of access to Spill for ${name}. ${originallySlackOrTeams ? "It will always be possible to regain access by leaving and re-entering the channel Spill is linked to." : ""}`,
        submitAction: async () => {
          let targetUserId
          if ("userId" in person) {
            targetUserId = person.userId
          } else {
            const { data } = await makeAppUser({
              variables: { platformUserId: person.platformUserId },
            })
            if (data?.makeAppUserForPlatformUser?.user?.id == null) {
              throw Error("Crap")
            }
            targetUserId = data.makeAppUserForPlatformUser.user.id
          }
          await deactivateUser({
            variables: { targetUserId },
          })
        },
        icon: TrashIcon,
      })
    )

    if (person.role === UserRole.STANDARD) {
      actions.push(
        makeDropdownAction({
          text: "Make admin",
          confirmationMessage: `This will give ${name} full permissions to manage all users and their data. Continue?`,
          submitAction: async () => {
            let targetUserId
            if ("userId" in person) {
              targetUserId = person.userId
            } else {
              const { data } = await makeAppUser({
                variables: { platformUserId: person.platformUserId },
              })
              if (data?.makeAppUserForPlatformUser?.user?.id == null) {
                throw Error("Crap")
              }
              targetUserId = data.makeAppUserForPlatformUser.user.id
            }
            await changeUserRole({
              variables: {
                targetUserId: targetUserId,
                role: UserRole.ADMIN,
              },
            })
          },
          icon: ArrowUpIcon,
        })
      )
    } else if (!isCurrentUser) {
      if ("userId" in person) {
        const targetUserId = person.userId
        actions.push(
          makeDropdownAction({
            text: "Remove admin",
            confirmationMessage: `This will remove ${name}'s permissions to manage users and their data. Continue?`,
            submitAction: async () => {
              await changeUserRole({
                variables: {
                  targetUserId,
                  role: UserRole.STANDARD,
                },
              })
            },
            icon: ArrowDownIcon,
          })
        )
      }
    }
  }

  return (
    <>
      <Dialog
        canClose
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title="Confirm changes"
        buttons={[
          {
            key: "cancel",
            variant: "secondary",
            children: "Cancel",
            onClick: () => setIsConfirmationModalOpen(false),
          },
          {
            key: "confirm",
            variant: "destructive",
            children: "Confirm",
            onClick: async () => {
              await submitAction()
            },
          },
        ]}
      >
        <P>{confirmMessage}</P>
      </Dialog>
      <div
        className={`text-right flex items-center justify-end ${!isCurrentUser && accountNotFound ? "" : "invisible"}`}
      >
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <Button variant="tertiary" size="sm">
              <EllipsisHorizontalIcon className="size-6" />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">
            {actions.map((action, index) => {
              return (
                <div key={action.text}>
                  <DropdownMenu.Item onClick={action.onClick}>
                    {action.text}
                  </DropdownMenu.Item>
                  {index < actions.length - 1 && <DropdownMenu.Separator />}
                </div>
              )
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </>
  )
}
