/**
 * The dashboard page for the admin.
 * Displays the number of users who have signed up.
 * As well as some other information spread accross various tabs.
 */

import { FunctionComponent } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet-async"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  DashboardGetUserQuery as QueryData,
  DashboardGetUserQueryVariables as QueryVars,
} from "types/graphql"

import {
  TeamEngagement,
  fragments as TeamEngagementFragments,
} from "./TeamEngagement"
import { TeamCheckin } from "./TeamCheckin"

export const fragments = {
  queryFields: gql`
    fragment DashboardQueryFields on Query {
      user {
        id
        displayName
        firstName
        lastName
        company {
          id
          subscriptionStatus
          platforms {
            id
            hasAnnouncedOnboarding
            hasCompletedIntegration
            platformType
          }
          pulseMeetings {
            urlId
          }
        }
      }
      ...TeamEngagementQueryFields
    }
    ${TeamEngagementFragments.queryFields}
  `,
}

export const queries = {
  getUser: gql`
    query DashboardGetUser {
      ...DashboardQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const Dashboard: FunctionComponent = () => {
  const { data, loading, error } = useQuery<QueryData, QueryVars>(
    queries.getUser
  )

  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoadingSpinner sizeInPixels={50} />
      </div>
    )
  }

  // If the user is not logged in. Redirect them to the signin page.
  // We also want to check in for the error just in case we somehow get
  // redirected to this page (which is the default route for admin.)
  if (!data?.user && !loading && !error) return <Navigate to={"/signin"} />

  return (
    <>
      <Helmet title="Dashboard | Spill" />

      <div className="flex flex-col space-y-10">
        <div className="flex flex-col gap-0 max-w-screen-2xl w-full">
          <div className="flex flex-col h-full w-full">
            <Routes>
              <Route path="/" element={<Navigate replace to="engagement" />} />
              <Route path="/engagement/*" element={<TeamEngagement />} />
              <Route path="/checkins/*" element={<TeamCheckin />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}
