import { useEffect, useState } from "react"
import { domAnimation, LazyMotion, m } from "framer-motion"
import cn from "classnames"

import { Tooltip } from "common/components/Tooltip"

import type { HTMLMotionProps } from "framer-motion"

interface AvatarProps extends HTMLMotionProps<"div"> {
  name: string
  showTooltip?: boolean
  size?: "sm" | "md" | "lg"
  url?: string
}

export function Avatar({
  className,
  name,
  showTooltip = false,
  size = "md",
  url,
  ...rest
}: AvatarProps): JSX.Element {
  const [isInvalidURL, setIsInvalidURL] = useState(false)

  useEffect(() => {
    setIsInvalidURL(url === undefined)
  }, [url])

  const initial = name.charAt(0).toUpperCase()

  let color = "bg-teal-600"

  switch (initial) {
    case "G":
    case "H":
    case "I":
    case "J":
    case "K":
    case "L":
      color = "bg-blue-800"
      break
    case "M":
    case "N":
    case "O":
    case "P":
    case "Q":
    case "R":
      color = "bg-red-400"
      break
    case "S":
    case "T":
    case "U":
    case "V":
    case "W":
    case "X":
    case "Y":
    case "Z":
      color = "bg-yellow-400"
      break
  }

  const renderAvatar = (
    <LazyMotion features={domAnimation}>
      <m.div
        className={cn(
          "aspect-square cursor-default flex font-bold items-center justify-center overflow-hidden relative rounded-full select-none text-base text-mono-white",
          color,
          {
            "h-8 w-8": size === "sm",
            "h-10 w-10": size === "md",
            "h-12 w-12": size === "lg",
          },
          className
        )}
        {...rest}
      >
        {url !== undefined && !isInvalidURL && (
          <img
            alt="Avatar"
            className="absolute inset-0 object-cover"
            onError={e => {
              e.currentTarget.onerror = null
              setIsInvalidURL(true)
            }}
            src={url}
          />
        )}
        {initial}
      </m.div>
    </LazyMotion>
  )

  if (!showTooltip) {
    return renderAvatar
  }

  return (
    <Tooltip id="user-initial" content={name}>
      {renderAvatar}
    </Tooltip>
  )
}
