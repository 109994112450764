import { FunctionComponent, useState } from "react"
import { useQuery } from "@apollo/client"

import { PmiSettingsGetPmiSettingValueAndDetailsQuery } from "types/graphql"

import { PmiLoadingCard } from "./cards/PmiLoadingCard"
import { PmiSetupCard } from "./cards/PmiSetupCard"
import { PmiEnabledCard } from "./cards/PmiEnabledCard"
import { queries } from "./PmiSettings.queries"

export const PmiSettings: FunctionComponent = () => {
  const [isPmiFormOpen, setPmiFormOpen] = useState(false)

  const { data, loading, refetch } =
    useQuery<PmiSettingsGetPmiSettingValueAndDetailsQuery>(
      queries.getPmiSettingValueAndDetails
    )

  if (loading) {
    return <PmiLoadingCard />
  }

  if (
    data?.user?.company?.featuresAndSettings.pmi.active === false ||
    data?.user?.company?.featuresAndSettings.pmi.value === false
  ) {
    return (
      <PmiSetupCard
        active={data?.user?.company.featuresAndSettings.pmi.active ?? false}
        isPmiFormOpen={isPmiFormOpen}
        setPmiFormOpen={setPmiFormOpen}
        refetch={refetch}
      />
    )
  }

  if (data?.user?.company?.featuresAndSettings.pmi.value === true) {
    return (
      <PmiEnabledCard
        provider={data.user.company.pmi?.provider}
        refetch={refetch}
      />
    )
  }

  return null
}
