/**
 * A chart that we show when we suggest a company downgrade their plan
 */

import { FunctionComponent, useEffect } from "react"
import {
  ResponsiveContainer,
  AreaChart,
  Legend,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  Tooltip,
  Area,
} from "recharts"
import { gql, useQuery } from "@apollo/client"
import { format, add } from "date-fns"
import { P } from "@spillchat/puddles"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useTheme } from "common/hooks/useTheme"
import {
  DowngradeChartGetUsageQuery,
  DowngradeChartGetUsageQueryVariables,
  RemainingAllowance,
} from "types/graphql"

import { useTherapyContext } from ".."

export const fragments = {
  queryFields: gql`
    fragment DowngradeChartQueryFields on Query {
      user {
        id
        company {
          id
          allUsage {
            month
            therapyHours
          }
          remainingAllowance {
            month
            oneOffsCovered
            coursesCovered
          }
          billableUserCount
        }
      }
    }
  `,
}

export const queries = {
  getUsage: gql`
    query DowngradeChartGetUsage {
      ...DowngradeChartQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const DowngradeChart: FunctionComponent = () => {
  const { data, loading } = useQuery<
    DowngradeChartGetUsageQuery,
    DowngradeChartGetUsageQueryVariables
  >(queries.getUsage)
  const theme = useTheme()
  const { currentSessionCoverage, setCurrentSessionCoverage } =
    useTherapyContext()

  /**
   * All the remaining allowance data. We'll need to extract the last 12 months of data
   */
  const coverageData = data?.user?.company?.remainingAllowance

  /**
   * Last 12 months of allUsage
   */
  const last12MonthsAllUsage = data?.user?.company?.allUsage?.slice(-12) ?? []

  const expectedUsage = last12MonthsAllUsage?.reduce(
    (acc, { therapyHours }) => acc + therapyHours,
    0
  )

  /**
   * Get the user count estimate based on the total coverages
   */

  const getLastCoverageData = (
    usageData: Partial<RemainingAllowance>[] | undefined
  ) => {
    return usageData?.[usageData.length - 1] ?? {}
  }

  const calculateCoverage = (lastCoverageData: Partial<RemainingAllowance>) => {
    const oneOffsCovered = lastCoverageData.oneOffsCovered ?? 0
    const coursesCovered = lastCoverageData.coursesCovered ?? 0

    const oneOffsUsage = oneOffsCovered
    const coursesUsage = coursesCovered * 6.5

    return oneOffsUsage + coursesUsage
  }

  const lastCoverageData = getLastCoverageData(coverageData)

  useEffect(() => {
    setCurrentSessionCoverage(
      coverageData ? Math.round(calculateCoverage(lastCoverageData) * 12) : 0
    )
  }, [lastCoverageData])

  // Shows the current usage of therapy as one data point, and the estimated usage for the rest of the year
  const therapyData = [
    {
      name: format(new Date(), "MMM yyyy"),
      currentSessionCoverage: 0,
      expectedUsage: 0,
    },
    {
      // name is next year
      name: format(add(new Date(), { years: 1 }), "MMM yyyy"),
      currentSessionCoverage,
      expectedUsage,
    },
  ]

  if (loading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )

  return (
    <>
      <div className="w-full h-40 mt-8 -ml-10">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={therapyData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <Legend
              wrapperStyle={{ top: -45, left: 25 }}
              formatter={value => (
                <span className="text-grey-600 text-sm font-inter">
                  {value}
                </span>
              )}
            />

            <XAxis
              dataKey="name"
              tickMargin={16}
              stroke={`${theme.colors.grey[400]}`}
              fontSize={12}
            />
            <YAxis />
            <ReferenceLine
              y={currentSessionCoverage}
              x1={therapyData[0]!.name}
              x2={therapyData[1]!.name}
              stroke={`${theme.colors.yellow[400]}`}
              strokeDasharray={6}
              strokeDashoffset={6}
              strokeWidth={2}
            />
            <ReferenceLine
              y={expectedUsage}
              x1={therapyData[0]!.name}
              x2={therapyData[1]!.name}
              stroke={`${theme.colors.yellow[400]}`}
              strokeDasharray={6}
              strokeDashoffset={6}
              strokeWidth={2}
            />
            <ReferenceArea
              ifOverflow="extendDomain"
              fillOpacity={0}
              y1={currentSessionCoverage}
              y2={expectedUsage}
              x1={therapyData[0]!.name}
              x2={therapyData[1]!.name}
            />
            <Tooltip
              content={({ active }) => {
                if (active === true) {
                  return (
                    <div className="backdrop-blur-md bg-mono-black/50 grid gap-xs overflow-hidden p-sm rounded-lg text-sm text-mono-white">
                      <p>
                        Pay for{" "}
                        {Math.abs(currentSessionCoverage - expectedUsage)} fewer
                        sessions and save.
                      </p>
                    </div>
                  )
                }

                return null
              }}
            />

            <Area
              type="monotone"
              dataKey="expectedUsage"
              legendType="circle"
              name="Sessions we expect you to use"
              stroke={`${theme.colors.yellow[400]}`}
              fill={`${theme.colors.yellow[400]}`}
            />
            <Area
              type="monotone"
              dataKey="currentSessionCoverage"
              legendType="circle"
              name="Sessions in plan"
              stroke={`${theme.colors.yellow[200]}`}
              fill={`${theme.colors.yellow[200]}`}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <P muted>
        Your plan gives you {currentSessionCoverage} sessions a year, but so far
        your team is on track to use less than that. Change plans to support the
        team for less.
      </P>
    </>
  )
}
