import { gql } from "@apollo/client"
import { PACKAGE_FIELDS } from "graphql/fragments/therapy-packages.fragment"

export const queries = {
  user: gql`
    query BereavementGetUserQuery {
      user {
        appointments {
          startsAt
          status
        }
        ...PackageFields
      }
    }
    ${PACKAGE_FIELDS}
  `,
}
