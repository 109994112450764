import { FunctionComponent } from "react"
import { Outlet } from "react-router-dom"
import { gql } from "@apollo/client"
import cn from "classnames"

import { useApp } from "common/context/appContext"
import {
  Navigation,
  fragments as NavigationFragments,
} from "common/components/Navigation/Navigation"
import { NavigationLayoutQueryFieldsFragment } from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment NavigationLayoutQueryFields on Query {
      user {
        id
      }
      ...NavigationQueryFields
    }
    ${NavigationFragments.queryFields}
  `,
}

interface NavigationLayoutProps {
  data?: NavigationLayoutQueryFieldsFragment
}

export const NavigationLayout: FunctionComponent<
  NavigationLayoutProps
> = props => {
  const { isBannerVisible } = useApp()

  return (
    <>
      <div
        className={cn(
          "relative flex flex-col md:flex-row",
          isBannerVisible ? "min-h-[calc(100vh-40px)]" : "min-h-screen"
        )}
      >
        <Navigation data={props.data} />

        <div
          className={cn(
            "bg-mono-white flex flex-col lg:grow order-2 lg:order-1 space-y-6 lg:space-y-8 w-full md:ml-60 max-w-[1100px] p-4 lg:p-16",
            {
              "min-h-[calc(100vh-104px)] lg:min-h-[calc(100vh-40px)]":
                isBannerVisible,
              "min-h-[calc(100vh-64px)] lg:min-h-screen": !isBannerVisible,
            }
          )}
        >
          <Outlet />
        </div>
      </div>
    </>
  )
}
