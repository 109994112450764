import { gql } from "@apollo/client"
import { CUSTOM_SUPPORT_FIELDS } from "graphql/fragments/custom-support.fragment"
import { PACKAGE_FIELDS } from "graphql/fragments/therapy-packages.fragment"

export const queries = {
  getPackages: gql`
    query TherapySpecialisedSupportIndex {
      user {
        id
        visibleUnclaimedTherapyPackages {
          id
          name
          userFacingDescription
          icon
          iconUrl
          numberSessions
          numberMonthsToUse
          mostRecentRequest {
            status
          }
        }
        ...PackageFields
        ...CustomSupportFields
      }
    }
    ${CUSTOM_SUPPORT_FIELDS}
    ${PACKAGE_FIELDS}
  `,
}
