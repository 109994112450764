import { gql } from "@apollo/client"

export const queries = {
  getQuote: gql`
    query QuoteViewGetQuote {
      getCompanyTherapyNeeds
      getCompanyTherapyQuoteNumberEmployees
      getCompanyTherapyQuoteRecommendedPlan
      user {
        company {
          planPrice {
            perSession
          }
        }
      }
    }
  `,
}
