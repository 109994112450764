import {
  Button,
  H3,
  P,
  StatusButton,
  StatusButtonProps,
} from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import customPlaceholder from "common/assets/images/product/specialised-support/therapy-custom-grey.png"
import { HeroIcon } from "common/helpers/heroIcons"

type SpecialisedSupportItemProps = {
  title: string
  subtitle: string
  list: {
    Icon: HeroIcon
    text: string
  }[]
  primaryButton?: {
    href: string
    text: string
  }
  secondaryButton?: {
    href: string
    text: string
  }
  statusButton?: {
    variant: StatusButtonProps["state"]
    text: string
  }
  image?: string
}

export const SpecialisedSupportItem: FunctionComponent<
  SpecialisedSupportItemProps
> = (body: SpecialisedSupportItemProps) => {
  return (
    <div className="flex flex-col gap-6 w-full relative p-5 lg:p-8 border border-spill-grey-100 rounded-md">
      <div className="flex flex-col items-start grow gap-6">
        <div className="flex lg:flex-row flex-col-reverse justify-between gap-8 lg:gap-4 w-full">
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-1">
              <H3>{body.title}</H3>
            </div>
            <P muted>{body.subtitle}</P>
          </div>
          <div className="lg:bg-transparent bg-grey-100 rounded-lg flex items-center justify-center min-w-32 min-h-32 aspect-video lg:aspect-square">
            <img
              src={
                body.image != null && body.image != ""
                  ? body.image
                  : customPlaceholder
              }
              alt=""
              className="rounded-lg max-w-32 max-h-32 flex aspect-ratio object-cover"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          {body.list.map(item => {
            return (
              <div className="flex items-center gap-4" key={item.text}>
                <item.Icon className="text-blue-800 size-6" width={24} />
                <P muted>{item.text}</P>
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex flex-wrap lg:items-center gap-2">
        {body.primaryButton !== undefined && (
          <Button size="sm" variant="primary" asChild>
            <Link to={body.primaryButton.href}>{body.primaryButton.text}</Link>
          </Button>
        )}
        {body.secondaryButton !== undefined && (
          <Button size="sm" variant="secondary" asChild>
            <Link to={body.secondaryButton.href}>
              {body.secondaryButton.text}
            </Link>
          </Button>
        )}
        {body.statusButton !== undefined && (
          <>
            <StatusButton size="sm" state={body.statusButton.variant}>
              {body.statusButton.text}
            </StatusButton>
          </>
        )}
      </div>
    </div>
  )
}
