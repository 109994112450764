import { FunctionComponent, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
  Button,
  H1,
  P,
  H3,
  H4,
  Switch,
  Indicator,
  cn,
  InputStepper,
} from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { BookOpenIcon, UsersIcon } from "@heroicons/react/24/outline"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import ImageCounsellor1 from "common/assets/images/product/counsellor-7.png"
import ImageCounsellor2 from "common/assets/images/product/counsellor-8.png"
import ImageCounsellor3 from "common/assets/images/product/counsellor-9.png"
import {
  AddOnType,
  InviteAdhdSettingsQuery,
  InviteAdhdSettingsQueryVariables,
  InviteAdhdToggleMutation,
  InviteAdhdToggleMutationVariables,
  InviteAdhdUpdateCoPayMutation,
  InviteAdhdUpdateCoPayMutationVariables,
} from "types/graphql"
import LogoNoBackground from "common/assets/logo/no-background-logo.png"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"

import { queries } from "./invite-adhd.queries"
import { mutations } from "./invite-adhd.mutations"

const FULL_ASSESSMENT_COST = 600
const DEFAULT_COMPANY_COPAY_CONTRIBUTION = 200

const counsellorImages = [ImageCounsellor1, ImageCounsellor2, ImageCounsellor3]

export const InviteAdhd: FunctionComponent = () => {
  const { flags } = useUser()

  const {
    data,
    called,
    loading: queryLoading,
  } = useQuery<InviteAdhdSettingsQuery, InviteAdhdSettingsQueryVariables>(
    queries.getSettings
  )

  const [updateSetting, { loading: settingMutationLoading }] = useMutation<
    InviteAdhdToggleMutation,
    InviteAdhdToggleMutationVariables
  >(mutations.toggleAdhdSupport, { refetchQueries: [queries.getSettings] })

  const [updateCoPay, { loading: coPayMutationLoading }] = useMutation<
    InviteAdhdUpdateCoPayMutation,
    InviteAdhdUpdateCoPayMutationVariables
  >(mutations.updateAdhdCoPayAddon, { refetchQueries: [queries.getSettings] })

  const featureState =
    data?.user?.company?.featuresAndSettings.adhdSupport.value === true

  const coPayProperties =
    data?.user?.company?.adhdCompanyPackageSetting?.addOns.find(
      addOn => addOn.addOnType === AddOnType.CO_PAY
    )?.properties

  const userTherapyCap =
    data?.user?.company?.featuresAndSettings.userTherapyCap.value

  const [editingCoPaySettings, setEditingCoPaySettings] = useState(false)
  const [editedCompanyCoPayContribution, setEditedCompanyCoPayContribution] =
    useState(
      coPayProperties?.companyContribution ?? DEFAULT_COMPANY_COPAY_CONTRIBUTION
    )

  const resetEditedCoPaySettings = () => {
    setEditingCoPaySettings(false)
    setEditedCompanyCoPayContribution(
      coPayProperties?.companyContribution ?? DEFAULT_COMPANY_COPAY_CONTRIBUTION
    )
  }

  const toggleSetting = async (
    adhdSupport: boolean,
    userTherapyCap: number | null
  ) => {
    if (flags[FEATURE_FLAGS.ADHD_CO_PAY] && !adhdSupport) {
      resetEditedCoPaySettings()
    }
    await updateSetting({ variables: { adhdSupport, userTherapyCap } })
  }

  if (!called || queryLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  return (
    <>
      <Helmet title="ADHD support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex justify-between gap-8">
          <div className="flex flex-col gap-4 max-w-screen-sm">
            <H1>ADHD support</H1>
            <H3>How it works</H3>
            <P>
              With ADHD support, you can provide specialist help for employees
              who experience ADHD.
            </P>
            <P>
              This can be turned on for your whole team, and employees can
              access it regardless of whether they have received an official
              diagnosis or not. Employees do not need to make themselves known
              to an admin in order to access the support.
            </P>
          </div>
          <div className="hidden lg:flex">
            <img
              className="rounded-lg"
              src={imageAdhd}
              alt="Someone has a Spill session using their laptop"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <H4 sectionHeader>Support for your team</H4>
          <P weight="medium" muted>
            Choose whether you want to turn ADHD support on.
          </P>
        </div>
        <div className="grid grid-cols-2 gap-16 items-start">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <Switch
                defaultChecked={featureState}
                disabled={settingMutationLoading}
                onCheckedChange={async value =>
                  await toggleSetting(value, userTherapyCap ?? null)
                }
              />
              <div className="flex flex-col gap-1">
                <H3>Support is turned {featureState ? "on" : "off"}</H3>
                <P muted>Anyone at your company can access this support.</P>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <P weight="medium">What's included</P>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <UsersIcon className="size-4" />
                  <P>Sessions with a Spill ADHD specialist</P>
                </div>
                <div className="flex items-center gap-2">
                  <BookOpenIcon className="size-4" />
                  <P>Resources and advice from experts</P>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-spill-grey-100 p-4 rounded-lg flex flex-col gap-4">
            <div className="flex items-center -space-x-2">
              {counsellorImages.map((image, index) => {
                return (
                  <div
                    key={index}
                    className="w-12 h-12 bg-teal-400 border-2 border-white rounded-full flex items-center justify-center uppercase tracking-wide font-bold text-xs text-teal-100"
                  >
                    <img
                      src={image}
                      alt="An ADHD support counsellor"
                      className="rounded-full"
                    />
                  </div>
                )
              })}
            </div>
            <P weight="medium">Our ADHD specialists</P>
            <P muted size="xs">
              Our specialists can give you practical tools and tips for
              managing&nbsp;ADHD
            </P>
            <Button size="sm" variant="tertiary" asChild>
              <Link
                to="https://spill.notion.site/Spill-s-ADHD-specialists-10449f92fc6b80529e0dc34ff0e4e724?pvs=4"
                target="_blank"
              >
                Learn more
              </Link>
            </Button>
          </div>
        </div>
        {flags[FEATURE_FLAGS.ADHD_CO_PAY] && (
          <div
            className={cn(
              "flex flex-col gap-6 opacity-60 pointer-events-none transition-opacity",
              {
                "opacity-100 pointer-events-auto": featureState,
              }
            )}
          >
            <div className="flex flex-col gap-4">
              <H4 sectionHeader>Assessment add-on</H4>
              <P weight="medium" muted>
                Choose whether to enable additional features.
              </P>
            </div>
            <div className="grid lg:grid-cols-2 gap-6 lg:gap-16">
              <div>
                <div className="flex flex-col gap-4 max-w-96 lg:max-w-full relative p-6 border border-spill-grey-100 rounded-md">
                  <div className="flex justify-between">
                    <Indicator>£</Indicator>
                    <Switch
                      checked={
                        featureState && coPayProperties?.enabled === true
                      }
                      aria-disabled={
                        !featureState ||
                        coPayMutationLoading ||
                        settingMutationLoading
                      }
                      onCheckedChange={async value => {
                        if (!value) {
                          resetEditedCoPaySettings()
                        }
                        await updateCoPay({
                          variables: {
                            enabled: value,
                            companyContribution:
                              coPayProperties?.companyContribution ??
                              DEFAULT_COMPANY_COPAY_CONTRIBUTION,
                          },
                        })
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <H4>Co-pay ADHD assessment</H4>
                    <P muted>Partly pay for a private assessment.</P>
                  </div>
                  <div className="flex flex-col gap-4 p-4 bg-spill-blue-100 rounded-md">
                    <P>
                      An ADHD assessment may give people a better understanding
                      of things they experience, as well as access to further
                      treatment or medication.
                    </P>
                    <P>
                      Make this more accessible to your team by offering to pay
                      part of the fee.
                    </P>
                    <Button variant="tertiary">Learn more</Button>
                  </div>
                </div>
              </div>
              <div
                className={cn(
                  "flex flex-col gap-6 opacity-60 pointer-events-none transition-opacity",
                  {
                    "opacity-100":
                      // Either ADHD support is off, in which case the whole section already
                      // has opacity turned down and we don't need to turn it down twice,
                      // or ADHD support is on, in which case we should only have full opacity
                      // for the co-pay settings when co-pay is actually on
                      !featureState || coPayProperties?.enabled === true,
                    "pointer-events-auto": coPayProperties?.enabled === true,
                  }
                )}
              >
                <div className="flex flex-col gap-4">
                  <div className="flex gap-4 lg:justify-between items-center">
                    <H4>Co-pay settings</H4>
                    {editingCoPaySettings ? (
                      <div className="flex gap-2">
                        <Button
                          variant="primary"
                          size="sm"
                          disabled={coPayMutationLoading}
                          onClick={async () => {
                            await updateCoPay({
                              variables: {
                                enabled: true,
                                companyContribution:
                                  editedCompanyCoPayContribution,
                              },
                            })
                            setEditingCoPaySettings(false)
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant="secondary"
                          size="sm"
                          disabled={coPayMutationLoading}
                          onClick={resetEditedCoPaySettings}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <Button
                        variant="secondary"
                        size="sm"
                        aria-disabled={
                          settingMutationLoading ||
                          !featureState ||
                          coPayProperties?.enabled !== true
                        }
                        onClick={() => setEditingCoPaySettings(true)}
                      >
                        Edit settings
                      </Button>
                    )}
                  </div>
                  <div className="flex flex-col gap-4">
                    <P weight="medium">Diagnosis provider</P>
                    <div className="flex gap-4">
                      <img
                        src={LogoNoBackground}
                        alt="Spill logo"
                        className="w-16 h-16 p-2 object-contain border border-spill-grey-100 rounded-md"
                      />
                      <div className="flex flex-col justify-center gap-1">
                        <p className="font-medium">Spill</p>
                        <P weight="medium" muted>
                          Powered by Psychiatry UK
                        </P>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <P weight="medium">Total budget</P>
                    {editingCoPaySettings ? (
                      <InputStepper
                        prefix="£"
                        value={editedCompanyCoPayContribution.toString()}
                        disabled={coPayMutationLoading}
                        min={0}
                        max={FULL_ASSESSMENT_COST}
                        step={50}
                        onStepperChange={newValue => {
                          setEditedCompanyCoPayContribution(Number(newValue))
                        }}
                      />
                    ) : (
                      <p className="font-medium">
                        £
                        {featureState && coPayProperties?.enabled === true
                          ? coPayProperties.companyContribution
                          : 0}
                      </p>
                    )}
                    <P size="xs" muted>
                      An assessment on Spill is £{FULL_ASSESSMENT_COST}
                    </P>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}
