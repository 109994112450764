import { FunctionComponent } from "react"

import mood1 from "common/assets/images/moods/1.svg"
import mood10 from "common/assets/images/moods/10.svg"
import mood2 from "common/assets/images/moods/2.svg"
import mood3 from "common/assets/images/moods/3.svg"
import mood4 from "common/assets/images/moods/4.svg"
import mood5 from "common/assets/images/moods/5.svg"
import mood6 from "common/assets/images/moods/6.svg"
import mood7 from "common/assets/images/moods/7.svg"
import mood8 from "common/assets/images/moods/8.svg"
import mood9 from "common/assets/images/moods/9.svg"
import anxiety from "common/assets/images/openmoji/anxiety.svg"
import calm from "common/assets/images/openmoji/calm.svg"
import curiosity from "common/assets/images/openmoji/curiosity.svg"
import enthusiasm from "common/assets/images/openmoji/enthusiasm.svg"
import excitement from "common/assets/images/openmoji/excitement.svg"
import focused from "common/assets/images/openmoji/focused.svg"
import frustration from "common/assets/images/openmoji/frustration.svg"
import hope from "common/assets/images/openmoji/hope.svg"
import joy from "common/assets/images/openmoji/joy.svg"
import nothingSpecial from "common/assets/images/openmoji/nothing-special.svg"
import stress from "common/assets/images/openmoji/stress.svg"
import tiredness from "common/assets/images/openmoji/tiredness.svg"
import {
  ClearSun,
  Cloudy,
  Drizzle,
  HeavyRain,
  LightRain,
  Thunder,
  SunnyRain,
  SunnyDrizzle,
  Sun,
  SunnyCloud,
} from "common/components/AnimatedIcons"
import { AnimatedIconProps } from "common/components/AnimatedIcons/types"
import { Feeling } from "types/graphql"

import type { Connotation } from "features/pulse/types"

export const FEELINGS: {
  [key in Feeling]: {
    adjective: string
    noun: Feeling
    src: string
    connotation: Connotation
  }
} = {
  enthusiasm: {
    adjective: "enthusiastic",
    noun: Feeling.enthusiasm,
    src: enthusiasm,
    connotation: "positive",
  },
  excitement: {
    adjective: "excited",
    noun: Feeling.excitement,
    src: excitement,
    connotation: "positive",
  },
  hope: {
    adjective: "hopeful",
    noun: Feeling.hope,
    src: hope,
    connotation: "positive",
  },
  calm: {
    adjective: "calm",
    noun: Feeling.calm,
    src: calm,
    connotation: "positive",
  },
  tiredness: {
    adjective: "tired",
    noun: Feeling.tiredness,
    src: tiredness,
    connotation: "negative",
  },
  joy: {
    adjective: "joyful",
    noun: Feeling.joy,
    src: joy,
    connotation: "positive",
  },
  curiosity: {
    adjective: "curious",
    noun: Feeling.curiosity,
    src: curiosity,
    connotation: "positive",
  },
  anxiety: {
    adjective: "anxious",
    noun: Feeling.anxiety,
    src: anxiety,
    connotation: "negative",
  },
  stress: {
    adjective: "stressed",
    noun: Feeling.stress,
    src: stress,
    connotation: "negative",
  },
  mediocrity: {
    adjective: "mediocre",
    noun: Feeling.mediocrity,
    src: nothingSpecial,
    connotation: "negative",
  },
  frustration: {
    adjective: "frustrated",
    noun: Feeling.frustration,
    src: frustration,
    connotation: "negative",
  },
  focus: {
    adjective: "focused",
    noun: Feeling.focus,
    src: focused,
    connotation: "positive",
  },
}

export const MOOD_IMAGE_MAP: { [key: string]: string } = {
  1: mood1,
  2: mood2,
  3: mood3,
  4: mood4,
  5: mood5,
  6: mood6,
  7: mood7,
  8: mood8,
  9: mood9,
  10: mood10,
}

/**
 * A map of moods and the corresponding animated react function component.
 */
export const ANIMATED_IMAGE_MOOD_MAP: {
  [key: string]: FunctionComponent<AnimatedIconProps>
} = {
  1: Thunder,
  2: HeavyRain,
  3: LightRain,
  4: Drizzle,
  5: Cloudy,
  6: SunnyRain,
  7: SunnyDrizzle,
  8: SunnyCloud,
  9: ClearSun,
  10: Sun,
}
