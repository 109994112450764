import { ComponentProps, useEffect, useMemo, useRef } from "react"
import { gql, useQuery } from "@apollo/client"
import { differenceInCalendarMonths, format, subMonths } from "date-fns"
import {
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  LineProps,
} from "recharts"
import { useMeasure, usePrevious } from "react-use"
import { capitalize, clamp, find, get, max } from "lodash"
import { inflect } from "inflection"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import cn from "classnames"

import { createArray } from "common/helpers/createArray"
import { useTheme } from "common/hooks/useTheme"
import { Feeling } from "types/graphql"
import { formatMonth } from "common/helpers/formatMonth"
import { useAnalytics } from "common/context/analyticsContext"

import type {
  EmotionsBenchmarkLineChartGetDataQuery as QueryData,
  EmotionsBenchmarkLineChartGetDataQueryVariables as QueryVariables,
} from "types/graphql"

type EntityData = {
  feelingACount: number | null
  feelingAPercent: number | null
  feelingAPercentMTD: number | null
  feelingBCount: number | null
  feelingBPercent: number | null
  feelingBPercentMTD: number | null
  someFeelingsSharedResponseCount: number | null
  responseCount: number | null
}

type ChartDataItem = {
  benchmarkGroup: EntityData
  company: EntityData
  month: string
}

type FormFieldValues = {
  feelingA: Feeling
  feelingB: Feeling | "none"
  showBenchmarks: boolean
}

const fragments = {
  companyFields: gql`
    fragment EmotionsBenchmarkLineChartCompanyFields on Company {
      id
      benchmarkGroupPulseResponseAggregatesByMonth(
        fromMonth: $fromMonth
        toMonth: $toMonth
      ) {
        month
        someFeelingsSharedResponseCount
        responseCount
        feelingAggregates {
          feeling
          sharedCount
          sharedPercent
        }
      }
      pulseResponseAggregatesByMonth(fromMonth: $fromMonth, toMonth: $toMonth) {
        everythingSharedResponseCount
        month
        responseCount
        someFeelingsSharedResponseCount
        feelingAggregates {
          feeling
          sharedCount
          sharedPercent
        }
      }
    }
  `,
}

const queries = {
  getData: gql`
    query EmotionsBenchmarkLineChartGetData(
      $fromMonth: Month!
      $toMonth: Month!
    ) {
      company {
        ...EmotionsBenchmarkLineChartCompanyFields
      }
    }
    ${fragments.companyFields}
  `,
}

const variables = {
  fromMonth: formatMonth(subMonths(new Date(), 6)),
  toMonth: formatMonth(new Date()),
}

// Common Recharts component props (static)
const commonLineProps = {
  animationDuration: 500,
  connectNulls: false,
  strokeWidth: 3,
}
const commonMTDLineProps = {
  dot: { strokeDasharray: 0 },
  strokeDasharray: "4 4",
}

export function EmotionsBenchmarkLineChart(): JSX.Element | null {
  const [chartWrapperRef, { height, width }] = useMeasure<HTMLDivElement>()
  const { track } = useAnalytics()
  const { colors } = useTheme()
  /**
   * The chart may re-render a few times due to the way the form elements are populated
   * and watched. We set a ref to count the amount of times this happens. This is only
   * useful for the analytics and ignoring the initial couple re-renders.
   *  */
  const analyticsRefCount = useRef(0)

  const formMethods = useForm<FormFieldValues>({
    defaultValues: {
      feelingA: Feeling.anxiety,
      feelingB: Feeling.calm,
      showBenchmarks: false,
    },
  })

  const feelingA = formMethods.watch("feelingA")
  const feelingB = formMethods.watch("feelingB")
  const showBenchmarks = formMethods.watch("showBenchmarks")
  const previousShowBenchmarkValue = usePrevious<boolean>(showBenchmarks)

  const { data } = useQuery<QueryData, QueryVariables>(queries.getData, {
    fetchPolicy: "cache-first",
    variables,
  })

  const companyAggregates = data?.company.pulseResponseAggregatesByMonth
  const benchmarkGroupAggregates =
    data?.company.benchmarkGroupPulseResponseAggregatesByMonth

  // Set selected feelings to highest two shared this month
  useEffect(() => {
    // Highest two most shared feelings this month
    const [a, b] = [
      ...(find(companyAggregates, {
        month: formatMonth(new Date()),
      })?.feelingAggregates ?? []),
    ].sort((a, b) => b.sharedPercent - a.sharedPercent)
    if (!a || !b) return
    // Set select inputs to above feelings
    formMethods.resetField("feelingA", { defaultValue: a.feeling })
    formMethods.resetField("feelingB", { defaultValue: b.feeling })
  }, [companyAggregates, benchmarkGroupAggregates])

  useEffect(() => {
    // We only want these effects to run after the initial mount
    if (analyticsRefCount.current <= 1) {
      analyticsRefCount.current = analyticsRefCount.current + 1
      return
    }
    track("Admin comparing emotions on company emotions benchmark chart", {
      emotionOne: feelingA,
      emotionTwo: feelingB,
    })

    if (showBenchmarks !== previousShowBenchmarkValue) {
      track(
        "Admin toggling benchmark group on company emotions benchmark chart",
        {
          displayingBenchmark: showBenchmarks,
        }
      )
    }
  }, [feelingA, feelingB, showBenchmarks])

  // Maximum percent of a feeling shared from either company or benchmark group
  // across all fetched months. Used to set Y axis range.
  const maxFeelingSharedPercent = useMemo(() => {
    if (!companyAggregates || !benchmarkGroupAggregates) return 100
    return Math.round(
      max([
        ...getFeelingPercentages(companyAggregates),
        ...getFeelingPercentages(benchmarkGroupAggregates),
      ]) ?? 100
    )
  }, [companyAggregates, benchmarkGroupAggregates])

  // Calculate upper range of line chart.
  const upperRange =
    Math.ceil(clamp(maxFeelingSharedPercent + 10, 0, 100) / 10) * 10

  const chartData: ChartDataItem[] = useMemo(() => {
    if (!data) return []

    // Number of months we've requested data for.
    // Used to determine number of chart data items.
    const numMonths = differenceInCalendarMonths(
      new Date(variables.toMonth),
      new Date(variables.fromMonth)
    )

    return createArray(numMonths + 1, index => {
      const month = formatMonth(subMonths(new Date(), numMonths - index))
      const company = find(companyAggregates, { month })
      const benchmarkGroup = find(benchmarkGroupAggregates, { month })
      // Prefix company variables with co and benchmark group variables with bg
      // to make code easier to scan.
      const coFeelingAAggregates = find(company?.feelingAggregates, {
        feeling: feelingA,
      })
      const coFeelingBAggregates =
        feelingB !== "none"
          ? find(company?.feelingAggregates, { feeling: feelingB })
          : null
      const bgFeelingAAggregates = find(benchmarkGroup?.feelingAggregates, {
        feeling: feelingA,
      })
      const bgFeelingBAggregates =
        feelingB !== "none"
          ? find(benchmarkGroup?.feelingAggregates, { feeling: feelingB })
          : null
      const coFeelingAPercent = coFeelingAAggregates?.sharedPercent ?? null
      const coFeelingBPercent = coFeelingBAggregates?.sharedPercent ?? null
      const bgFeelingAPercent = bgFeelingAAggregates?.sharedPercent ?? null
      const bgFeelingBPercent = bgFeelingBAggregates?.sharedPercent ?? null

      const isLastMonth = index === numMonths - 1
      const isMTD = index === numMonths

      return {
        benchmarkGroup: {
          feelingACount: bgFeelingAAggregates?.sharedCount ?? null,
          feelingAPercent: isMTD ? null : bgFeelingAPercent,
          feelingAPercentMTD: isLastMonth || isMTD ? bgFeelingAPercent : null,
          feelingBCount: bgFeelingBAggregates?.sharedCount ?? null,
          feelingBPercent: isMTD ? null : bgFeelingBPercent,
          feelingBPercentMTD: isLastMonth || isMTD ? bgFeelingBPercent : null,
          someFeelingsSharedResponseCount:
            benchmarkGroup?.someFeelingsSharedResponseCount ?? null,
          responseCount: benchmarkGroup?.responseCount ?? null,
        },
        company: {
          feelingACount: coFeelingAAggregates?.sharedCount ?? null,
          feelingAPercent: !isMTD ? coFeelingAPercent : null,
          feelingAPercentMTD: isLastMonth || isMTD ? coFeelingAPercent : null,
          feelingBCount: coFeelingBAggregates?.sharedCount ?? null,
          feelingBPercent: !isMTD ? coFeelingBPercent : null,
          feelingBPercentMTD: isLastMonth || isMTD ? coFeelingBPercent : null,
          someFeelingsSharedResponseCount:
            company?.someFeelingsSharedResponseCount ?? null,
          responseCount: company?.responseCount ?? null,
        },
        month,
      }
    })
  }, [JSON.stringify(data), feelingA, feelingB])

  // Common Recharts component props (dynamic)
  const commonAxisProps = {
    color: colors.grey[600],
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontWeight: "medium",
    stroke: colors.grey[600],
    strokeWidth: 2,
    tick: { fill: colors.grey[600] },
    tickLine: false,
  }
  const commonBenchmarkGroupLineProps = {
    ...commonLineProps,
    activeDot: showBenchmarks,
    visibility: showBenchmarks ? "visible" : "hidden",
  }

  if (!data) return null

  return (
    <FormProvider {...formMethods}>
      <div className="cursor-default flex flex-col gap-md">
        <header>
          <h4>Emotions over time</h4>
          <p>
            See whether the proportion of people sharing an emotion has changed
            over time. Choose up to two emotions to see how they relate.
          </p>
        </header>

        <form className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-[inherit]">
          {/* Sidebar */}
          <div className="flex flex-col gap-sm shrink-0 sm:w-40 md:w-auto lg:w-40">
            <label className="checkbox-label">
              <input
                className="checkbox"
                type="checkbox"
                {...formMethods.register("showBenchmarks")}
              />
              <span className="label-text">Show benchmarks</span>
            </label>

            <div className="flex flex-row sm:flex-col md:flex-row lg:flex-col gap-sm">
              <select
                className="select bg-blue-400 grow sm:grow-0 md:grow lg:grow-0"
                {...formMethods.register("feelingA", { required: true })}
              >
                {Object.values(Feeling).map(feeling => (
                  <option
                    key={feeling}
                    hidden={feeling === feelingB}
                    value={feeling}
                  >
                    {capitalize(feeling)}
                  </option>
                ))}
              </select>

              <span className="divider">COMPARE</span>

              <select
                className="select bg-yellow-400 grow sm:grow-0 md:grow lg:grow-0"
                {...formMethods.register("feelingB")}
              >
                <option value="none">None</option>
                {Object.values(Feeling).map(feeling => (
                  <option
                    key={feeling}
                    hidden={feeling === feelingA}
                    value={feeling}
                  >
                    {capitalize(feeling)}
                  </option>
                ))}
              </select>
            </div>

            {companyAggregates && companyAggregates.length < 2 && (
              <div className="bg-grey-100 p-sm space-y-xs leading-snug rounded-md">
                <p className="text-sm">
                  Keep using check-ins regularly with your teams to continue
                  seeing monthly trends.
                </p>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-sm grow">
            <Legend className="ml-[41px]" />

            <div className="aspect-[2/1] xl:aspect-[3/1] grow relative w-full">
              <div
                className="absolute top-0 right-0 left-0 bottom-0"
                ref={chartWrapperRef}
              >
                <LineChart
                  className="absolute"
                  data={chartData}
                  height={height}
                  margin={{
                    left: -18, // Aligns left side of X axis with legend
                    right: 5,
                    top: 5,
                    bottom: -10,
                  }}
                  width={width}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    {...commonAxisProps}
                    dataKey="month"
                    tickFormatter={(value: string) =>
                      format(new Date(value), "LLL")
                    }
                  />
                  <YAxis
                    {...commonAxisProps}
                    domain={[0, upperRange]}
                    ticks={createArray(
                      Math.floor(upperRange / 20) + 1,
                      i => i * 20
                    )}
                    tickFormatter={(value: number) => `${Math.round(value)}%`}
                    type="number"
                  />

                  <Tooltip animationDuration={50} content={CustomTooltip} />

                  {/* Benchmark Group Lines */}
                  <Line
                    {...commonBenchmarkGroupLineProps}
                    {...commonMTDLineProps}
                    dataKey="benchmarkGroup.feelingBPercentMTD"
                    name="benchmarkGroup.feelingBPercentMTD"
                    onMouseOver={(line: LineProps) =>
                      track(
                        `Admin hover over ${
                          line.name ?? "emotion"
                        } benchmark line`
                      )
                    }
                    stroke={colors.yellow[200]}
                  />
                  <Line
                    {...commonBenchmarkGroupLineProps}
                    dataKey="benchmarkGroup.feelingBPercent"
                    name="benchmarkGroup.feelingBPercent"
                    onMouseOver={(line: LineProps) =>
                      track(
                        `Admin hover over ${
                          line.name ?? "emotion"
                        } benchmark line`
                      )
                    }
                    stroke={colors.yellow[200]}
                    fill={colors.yellow[200]}
                  />
                  <Line
                    {...commonBenchmarkGroupLineProps}
                    {...commonMTDLineProps}
                    dataKey="benchmarkGroup.feelingAPercentMTD"
                    name="benchmarkGroup.feelingAPercentMTD"
                    stroke={colors.blue[200]}
                    onMouseOver={(line: LineProps) =>
                      track(
                        `Admin hover over ${
                          line.name ?? "emotion"
                        } benchmark line`
                      )
                    }
                  />
                  <Line
                    {...commonBenchmarkGroupLineProps}
                    dataKey="benchmarkGroup.feelingAPercent"
                    name="benchmarkGroup.feelingAPercent"
                    stroke={colors.blue[200]}
                    dot={{ fill: colors.blue[200] }}
                    onMouseOver={(line: LineProps) =>
                      track(
                        `Admin hover over ${
                          line.name ?? "emotion"
                        } benchmark line`
                      )
                    }
                  />

                  {/* Company Lines */}
                  <Line
                    {...commonLineProps}
                    {...commonMTDLineProps}
                    dataKey="company.feelingBPercentMTD"
                    name="company.feelingBPercentMTD"
                    onMouseOver={(line: LineProps) =>
                      track(`Admin hover over ${line.name ?? "emotion"} line`)
                    }
                    stroke={colors.yellow[400]}
                  />
                  <Line
                    {...commonLineProps}
                    dataKey="company.feelingBPercent"
                    name="company.feelingBPercent"
                    onMouseOver={(line: LineProps) =>
                      track(`Admin hover over ${line.name ?? "emotion"} line`)
                    }
                    dot={{ fill: colors.yellow[400] }}
                    stroke={colors.yellow[400]}
                  />
                  <Line
                    {...commonLineProps}
                    {...commonMTDLineProps}
                    dataKey="company.feelingAPercentMTD"
                    name="company.feelingAPercentMTD"
                    onMouseOver={(line: LineProps) =>
                      track(`Admin hover over ${line.name ?? "emotion"} line`)
                    }
                    stroke={colors.blue[400]}
                  />
                  <Line
                    {...commonLineProps}
                    dataKey="company.feelingAPercent"
                    name="company.feelingAPercent"
                    onMouseOver={(line: LineProps) =>
                      track(`Admin hover over ${line.name ?? "emotion"} line`)
                    }
                    dot={{ fill: colors.blue[400] }}
                    stroke={colors.blue[400]}
                  />
                </LineChart>
              </div>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  )
}

EmotionsBenchmarkLineChart.fragments = fragments
EmotionsBenchmarkLineChart.queries = queries
EmotionsBenchmarkLineChart.variables = variables

function Legend({ className }: Pick<ComponentProps<"div">, "className">) {
  const { watch } = useFormContext<FormFieldValues>()

  const feelingA = watch("feelingA")
  const feelingB = watch("feelingB")
  const showBenchmarks = watch("showBenchmarks")

  return (
    <div className={cn("legend", className)}>
      <p className="legend-item">
        <span className="legend-item-indicator bg-blue-400" />
        {capitalize(feelingA)}
      </p>
      {feelingB !== "none" && (
        <p className="legend-item">
          <span className="legend-item-indicator bg-yellow-400" />
          {capitalize(feelingB)}
        </p>
      )}
      {showBenchmarks && (
        <p className="legend-item">
          <span className="legend-item-indicator bg-blue-200" />
          {capitalize(feelingA)} Benchmark
        </p>
      )}
      {feelingB !== "none" && showBenchmarks && (
        <p className="legend-item">
          <span className="legend-item-indicator bg-yellow-200" />
          {capitalize(feelingB)} Benchmark
        </p>
      )}
    </div>
  )
}

function CustomTooltip({ active, payload }: ComponentProps<typeof Tooltip>) {
  const { watch } = useFormContext<FormFieldValues>()

  const feelingA = watch("feelingA")
  const feelingB = watch("feelingB")
  const showBenchmarks = watch("showBenchmarks")

  if (active === true && payload && payload.length) {
    const getPercentage = (dataKey: string) => {
      const value = payload.find(p => p.name === dataKey)?.value
      if (value === undefined) return null
      return `${Math.round(Number(value))}%`
    }

    const companyFeelingACount = get(
      payload,
      "[0].payload.company.feelingACount"
    ) as unknown as number
    const companyFeelingAPercent =
      getPercentage("company.feelingAPercent") ??
      getPercentage("company.feelingAPercentMTD")
    const companyFeelingBCount = get(
      payload,
      "[0].payload.company.feelingBCount"
    ) as unknown as number
    const companyFeelingBPercent =
      getPercentage("company.feelingBPercent") ??
      getPercentage("company.feelingBPercentMTD")
    const companySomeFeelingsSharedResponseCount = get(
      payload,
      "[0].payload.company.someFeelingsSharedResponseCount"
    ) as unknown as number
    const benchmarkGroupFeelingACount = get(
      payload,
      "[0].payload.benchmarkGroup.feelingACount"
    ) as unknown as number
    const benchmarkGroupFeelingAPercent =
      getPercentage("benchmarkGroup.feelingAPercent") ??
      getPercentage("benchmarkGroup.feelingAPercentMTD")
    const benchmarkGroupFeelingBCount = get(
      payload,
      "[0].payload.benchmarkGroup.feelingBCount"
    ) as unknown as number
    const benchmarkGroupFeelingBPercent =
      getPercentage("benchmarkGroup.feelingBPercent") ??
      getPercentage("benchmarkGroup.feelingBPercentMTD")
    const benchmarkGroupSomeFeelingsSharedResponseCount = get(
      payload,
      "[0].payload.benchmarkGroup.someFeelingsSharedResponseCount"
    ) as unknown as number
    const month = get(payload, "[0].payload.month") as unknown as string

    return (
      <div
        className="backdrop-blur-md bg-mono-black/50 grid gap-xs overflow-hidden rounded-lg text-sm text-mono-white p-sm"
        style={{ gridTemplateColumns: "fit-content(30%) 1fr fit-content(30%)" }}
      >
        <p className="col-span-3 font-bold font-display pb-xs">
          {format(new Date(month), "MMMM yyyy")}
        </p>

        <span className="legend-item-indicator bg-blue-400" />
        <span className="capitalize font-bold">{feelingA}</span>
        <span className="pl-sm place-self-end font-bold">
          {companyFeelingAPercent}
        </span>
        <span className="col-start-2 leading-none pb-xs last:pb-0">
          {companyFeelingACount} of {companySomeFeelingsSharedResponseCount}{" "}
          {inflect("response", companySomeFeelingsSharedResponseCount)}
        </span>
        <div />

        {feelingB !== "none" && (
          <>
            <span className="legend-item-indicator bg-yellow-400" />
            <span className="capitalize font-bold">{feelingB}</span>
            <span className="pl-sm place-self-end font-bold">
              {companyFeelingBPercent}
            </span>
            <span className="col-start-2 leading-none pb-xs last:pb-0">
              {companyFeelingBCount} of {companySomeFeelingsSharedResponseCount}{" "}
              {inflect("response", companySomeFeelingsSharedResponseCount)}
            </span>
            <div />
          </>
        )}

        {showBenchmarks && benchmarkGroupFeelingAPercent !== null && (
          <>
            <span className="legend-item-indicator bg-blue-200" />
            <span className="capitalize font-bold">{feelingA} benchmark</span>
            <span className="pl-sm place-self-end font-bold">
              {benchmarkGroupFeelingAPercent}
            </span>
            <p className="col-start-2 leading-none text-sm pb-xs last:pb-0">
              {benchmarkGroupFeelingACount} of{" "}
              {benchmarkGroupSomeFeelingsSharedResponseCount}{" "}
              {inflect(
                "response",
                benchmarkGroupSomeFeelingsSharedResponseCount
              )}
            </p>
            <div />
          </>
        )}

        {showBenchmarks &&
          feelingB !== "none" &&
          benchmarkGroupFeelingBPercent !== null && (
            <>
              <span className="legend-item-indicator bg-yellow-200" />
              <span className="capitalize font-bold">{feelingB} benchmark</span>
              <span className="pl-sm place-self-end font-bold">
                {benchmarkGroupFeelingBPercent}
              </span>
              <p className="col-start-2 leading-none text-sm pb-xs last:pb-0">
                {benchmarkGroupFeelingBCount} of{" "}
                {benchmarkGroupSomeFeelingsSharedResponseCount}{" "}
                {inflect(
                  "response",
                  benchmarkGroupSomeFeelingsSharedResponseCount
                )}
              </p>
              <div />
            </>
          )}
      </div>
    )
  }

  return null
}

function getFeelingPercentages(
  entity:
    | QueryData["company"]["pulseResponseAggregatesByMonth"]
    | QueryData["company"]["benchmarkGroupPulseResponseAggregatesByMonth"]
) {
  return entity.flatMap(a => a.feelingAggregates.map(b => b.sharedPercent))
}
