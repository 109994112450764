import { FunctionComponent, PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { parseISO } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { isNil } from "lodash"
import cn from "classnames"
import { H4, P } from "@spillchat/puddles"
import {
  CalendarIcon,
  ClockIcon,
  ComputerDesktopIcon,
  Square2StackIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import { useApp } from "common/context/appContext"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { BookableAppointmentType } from "types/graphql"
import { useUser } from "common/context/userContext"
import {
  renderLifeEvent,
  renderSessionType,
} from "common/helpers/renderAppointmentType"
import { HeroIcon } from "common/helpers/heroIcons"

interface TherapistSidebarProps {
  /**
   * The ref that the sidebar should be attached to. This allows us to handle
   * ui based changes.
   */
  sidebarRef: React.RefObject<HTMLDivElement>
}

export const TherapistSidebar: FunctionComponent<
  TherapistSidebarProps
> = props => {
  const { isBannerVisible, appTimeZone } = useApp()
  const { id: userId } = useUser()

  const { watch } = useFormContext<FormValues>()

  const {
    appointment,
    appointmentType,
    startTime,
    timeZone,
    filter: { lifeEvent },
  } = watch()

  const startsAt = startTime ?? appointment?.startsAt

  const halfSessions = [
    BookableAppointmentType.SERIES_CONSULTATION_SESSION,
    BookableAppointmentType.COURSE_SESSION_25_MINUTES,
    BookableAppointmentType.PRIVATE_COURSE_SESSION_25_MINUTES,
    BookableAppointmentType.BEREAVEMENT_CONSULTATION,
    BookableAppointmentType.PARENTHOOD_CONSULTATION,
  ]

  const duration =
    appointmentType != null && halfSessions.includes(appointmentType)
      ? "25 mins"
      : "50 mins"

  return (
    <div
      className={cn(
        "bg-grey-100 flex flex-col gap-6 h-fit order-1 overflow-hidden m-0 py-6 w-full lg:h-fit lg:order-2 lg:fixed lg:right-0 lg:max-w-xs lg:-top-8",
        {
          "top-[104px] md:top-10": isBannerVisible === true,
          "top-16 md:top-0": isBannerVisible === false,
          "lg:min-h-[calc(100vh-40px)]": isBannerVisible === true,
          "lg:min-h-screen": isBannerVisible === false,
        }
      )}
      ref={props.sidebarRef}
    >
      {!isNil(appointmentType) && (
        <div className="flex flex-col gap-3 px-6">
          <H4>Session details</H4>
          {appointment?.counsellor && (
            <>
              <AppointmentDetailsItem icon={UsersIcon}>
                Your therapist will be {appointment.counsellor.firstName}
              </AppointmentDetailsItem>
              <hr className="border-grey-400" />
            </>
          )}
          <AppointmentDetailsItem icon={Square2StackIcon}>
            {lifeEvent != null ? (
              <>{renderLifeEvent(lifeEvent)}</>
            ) : (
              <>{renderSessionType(appointmentType)}</>
            )}
          </AppointmentDetailsItem>
          <AppointmentDetailsItem icon={ComputerDesktopIcon}>
            {duration}, remote
          </AppointmentDetailsItem>
          {startsAt !== undefined && (
            <>
              <AppointmentDetailsItem icon={CalendarIcon}>
                {formatInTimeZone(parseISO(startsAt), timeZone, "eeee do MMMM")}
              </AppointmentDetailsItem>
              <AppointmentDetailsItem icon={ClockIcon}>
                {formatInTimeZone(parseISO(startsAt), timeZone, "h:mmaaa")} (
                {appTimeZone})
              </AppointmentDetailsItem>
            </>
          )}
        </div>
      )}

      <div className="grow" />
      <div className="flex-col gap-[inherit] hidden px-6 place-self-end lg:flex">
        <div className="flex flex-col gap-4">
          <H4>Looking for something specific?</H4>
          <P>
            Let us know anything additional you're looking for that you can't
            find by using the filters with{" "}
            <a
              className="underline"
              href={`https://spillchat.typeform.com/to/rml3m0eO#user_id=${userId ?? ""}&session_type=${appointmentType ?? ""}&life_event=${lifeEvent ?? ""}&location=side_panel&`}
              rel="noreferrer"
              target="_blank"
            >
              this form
            </a>
            .
          </P>
        </div>
        <div>
          <H4>Have a question?</H4>
          <P>
            Get help{" "}
            <Link className="underline" to="/help?topic=therapy_access">
              here.
            </Link>{" "}
          </P>
        </div>
      </div>
    </div>
  )
}

interface AppointmentDetailsItemProps extends PropsWithChildren {
  icon: HeroIcon
}

const AppointmentDetailsItem: FunctionComponent<
  AppointmentDetailsItemProps
> = props => (
  <div className="flex gap-2">
    <props.icon className="size-5" />
    <P weight="medium">{props.children}</P>
  </div>
)
