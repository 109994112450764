/**
 * Show the confirmation view for a user when they decide to change their plan.
 */

import { motion } from "framer-motion"
import { FunctionComponent, useState } from "react"
import { Button, Indicator, P } from "@spillchat/puddles"
import { useNavigate } from "react-router-dom"
import { CheckIcon, LinkIcon } from "@heroicons/react/24/outline"

import { Tooltip } from "common/components/Tooltip"

import { useTherapyContext } from ".."

const cardAnimationVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    delay: 0.1,
  },
}

interface ConfirmChangesProps {
  onConfirm: () => void
  onRequestCloseModal: () => void
  confirmationComplete: boolean
  userCount: number
}

export const ConfirmChanges: FunctionComponent<ConfirmChangesProps> = props => {
  const { estimatedMonthlyCost, individualUnitPrice } = useTherapyContext()
  const navigate = useNavigate()
  const [isConfirming, setIsConfirming] = useState(false)

  if (props.confirmationComplete) {
    return (
      <div className="flex flex-col gap-4 justify-center p-10 w-full items-center">
        <h2 className="text-2xl">Done. 🚀</h2>
        <h4>Your plan changes will take effect immediately.</h4>
        <p className="text-sm max-w-md text-center">
          We’ve set default therapy budgets for a company of your size in
          Therapy settings. You can change them any time.
        </p>
        <div className="flex flex-col gap-4 max-w-xs items-center">
          <Button onClick={() => props.onRequestCloseModal()}>
            Back to the dashboard
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              navigate("/therapy/settings")
              props.onRequestCloseModal()
            }}
          >
            Go to Therapy settings
          </Button>
        </div>
      </div>
    )
  }
  return (
    <>
      <motion.div
        className="flex-1 flex flex-col gap-4"
        {...cardAnimationVariants}
      >
        <h4>Switch to pay–as–you–go</h4>
        <hr />
        <ul className="flex flex-col gap-5">
          <li>
            <div className="flex items-center gap-2">
              <CheckIcon className="size-4 text-teal-400" />
              <P>Pay for therapy when used</P>
              <Indicator variant="ok">New</Indicator>
            </div>
          </li>
          <li>
            <div className="flex items-center gap-2">
              <CheckIcon className="size-4 text-teal-400" />
              <div className="grow">
                <P>
                  Next day{" "}
                  <Tooltip content="Spill Therapy reliably reduces negative mental health symptoms in 72% of cases. We do this by only hiring the top 13% of qualified therapist applicants and then providing extra supervision and training, as well as by letting clients select therapists by specialism.">
                    <span className="inline-flex underline decoration-dotted">
                      Spill Therapy
                    </span>
                  </Tooltip>{" "}
                  sessions
                </P>
              </div>
            </div>
          </li>
          <li>
            <div className="flex items-center gap-2">
              <CheckIcon className="size-4 text-teal-400" />
              <P>
                Proactive care with{" "}
                <Tooltip content="Spill regularly asks your team how they’re feeling and then flags anyone at risk of poor mental health or burnout. A Spill counsellor will then reach out to anyone at risk.">
                  <span className="inline-flex underline decoration-dotted">
                    Spill Safety Net
                  </span>
                </Tooltip>
              </P>
            </div>
          </li>
          <li>
            <div className="flex items-center gap-2">
              <CheckIcon className="size-4 text-teal-400" />
              <P>Easy access via Slack or MS Teams</P>
            </div>
          </li>
          <li>
            <div className="flex items-center gap-2">
              <CheckIcon className="size-4 text-teal-400" />
              <P>
                Manage spend with{" "}
                <a
                  href="https://spill.notion.site/spill/Introducing-Therapy-Budgets-4610a28dd2ad42b1ad573e9ba8742f27"
                  title="Introducing Therapy Budgets"
                  className="inline-flex underline items-center gap-0.5"
                >
                  <span>Therapy Budgets</span>
                  <LinkIcon className="size-4" />
                </a>
              </P>
              <Indicator variant="ok">New</Indicator>
            </div>
          </li>
        </ul>
      </motion.div>
      <motion.div className="flex-1" {...cardAnimationVariants}>
        <div className="flex flex-col gap-8 relative w-full h-full border-grey-200 border rounded-lg p-8">
          <h4>Order Summary</h4>
          <div className="flex items-center gap-2">
            <CheckIcon className="size-4 text-teal-400" />
            Effective from today
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between w-full">
              <div>Pay–as–you–go cover</div>
              <div>
                <strong>{individualUnitPrice}</strong>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div>Users covered</div>
              <div>
                <strong>{props.userCount}</strong>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex justify-between w-full">
            <div>Total cover</div>
            <div>
              <strong>{estimatedMonthlyCost} / month</strong>
            </div>
          </div>
          <Button
            disabled={isConfirming}
            onClick={() => {
              setIsConfirming(true)
              props.onConfirm()
            }}
          >
            Confirm changes
          </Button>
          <a
            className="text-sm text-center text-grey-700 underline"
            href="https://spill.notion.site/Spill-Terms-of-Service-08928e3d230245d1865996004f2f2a1e"
            target="_blank"
            rel="noreferrer"
          >
            By switching you agree to Spill's Terms
          </a>
        </div>
      </motion.div>
    </>
  )
}
