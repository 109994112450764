import { FunctionComponent, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useBoolean } from "react-use"

import { cn } from "common/helpers/cn"
import { useElementBreakpoint } from "common/hooks/useElementBreakpoint"
import { useAnalytics } from "common/context/analyticsContext"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"

import { FormSection } from "./FormSection"

type Props = {
  idx: number
  question: string
}

export const AbilityToCopeSection: FunctionComponent<Props> = ({
  idx,
  question,
}) => {
  const [ref, , breakpoint] = useElementBreakpoint<
    HTMLTableSectionElement,
    500
  >([500])

  const { formState, getFieldState, register } = useFormContext<FormValues>()
  const isDirty = getFieldState(
    `baselineQuestions.${idx}.answer`,
    formState
  ).isDirty

  // Track first change to ability to cope
  const { track } = useAnalytics()
  const [hasTracked, toggleHasTracked] = useBoolean(false)
  useEffect(() => {
    if (!hasTracked && isDirty) {
      track(
        "User enters baseline question",
        { Field: "Reasons for booking" },
        toggleHasTracked
      )
    }
  }, [hasTracked, isDirty])

  return (
    <FormSection
      name="baselineQuestions.abilityToCope"
      ref={ref}
      title={`${idx}. ${question}*`}
    >
      {breakpoint === 0 && <span className="text-sm">Not coping at all</span>}
      <ul
        className={cn("grid gap-[inherit]", {
          "grid-cols-5": breakpoint === 0,
          "grid-cols-10": breakpoint === 500,
        })}
      >
        {/* 1-10 radio button scale */}
        {new Array(10).fill(null).map((_, index) => (
          <li
            className="border border-blue-800 h-10 rounded w-full"
            key={index}
          >
            <input
              hidden
              value={"baseline"}
              {...register(`baselineQuestions.${idx}.section`)}
            />
            <input
              hidden
              value={question}
              {...register(`baselineQuestions.${idx}.question`)}
            />
            <input
              className="peer"
              hidden
              id={`coping${index}`}
              type="radio"
              value={index + 1}
              {...register(`baselineQuestions.${idx}.answer`, {
                required: "Required",
              })}
            />
            <label
              className="cursor-pointer flex h-full items-center justify-center w-full peer-checked:bg-blue-800 peer-checked:text-mono-white"
              htmlFor={`coping${index}`}
            >
              {index + 1}
            </label>
          </li>
        ))}
      </ul>
      <div className="flex justify-between text-sm">
        <span>{breakpoint === 500 ? "Not coping at all" : ""}</span>
        <span>Coping well</span>
      </div>
    </FormSection>
  )
}
