import cn from "classnames"

import type { ComponentProps } from "react"

type PulseSummaryAlertProps = {
  body: string
  className?: ComponentProps<"div">["className"]
  children?: ComponentProps<"div">["children"]
  title: string
}

export function PulseSummaryAlert({
  body,
  className,
  children,
  title,
}: PulseSummaryAlertProps): JSX.Element {
  return (
    <div className={cn("box-warning p-2 lg:p-3 overflow-visible", className)}>
      <div className="p-2 lg:p-3">
        <strong className="font-display">{title}</strong>
        <p>{body}</p>
      </div>
      {children}
    </div>
  )
}
