import { SubmitHandler, useFormContext } from "react-hook-form"
import { gql, useMutation, useQuery } from "@apollo/client"
import { FunctionComponent, useEffect } from "react"
import { isNil } from "lodash"
import { useCounter } from "react-use"
import { toast } from "sonner"
import { getCountryCallingCode } from "libphonenumber-js"

import { useApp } from "common/context/appContext"
import { transformFiltersForGQL } from "features/therapy/helpers/transformFiltersForGQL"
import { AppointmentConfirmation } from "features/therapy/components/AppointmentConfirmation"
import { AppointmentBaselineQuestionsForm } from "features/therapy/components/AppointmentBaselineQuestionsForm"
import {
  AppointmentDetailsForm,
  fragments as AppointmentDetailsFormFragments,
} from "features/therapy/components/AppointmentDetailsForm"
import { AppointmentFormSection } from "features/therapy/components/AppointmentFormSection"
import { AppointmentTimeSelection } from "features/therapy/components/AppointmentTimeSelection"
import {
  AppointmentBookingPageFormSectionsBookAppointmentMutation as MutationData,
  AppointmentBookingPageFormSectionsBookAppointmentMutationVariables as MutationVars,
  AppointmentBookingPageFormSectionsGetDataQuery as QueryData,
  AppointmentBookingPageFormSectionsGetDataQueryVariables as QueryVars,
  SpillSubscriptionPlan,
} from "types/graphql"
import { isEnum } from "types/typeguards"
import { Step } from "features/therapy/types"
import { validateDateOfBirth } from "common/helpers/validateDateOfBirth"

import { queries as sessionsPageQueries } from "../SessionsPage/index"

import { FormValues } from "./AppointmentBookingPage"

export const fragments = {
  queryFields: gql`
    fragment AppointmentBookingPageFormSectionsQueryFields on Query {
      user {
        id
        company {
          id
          subscriptionPlan
        }
      }
      ...AppointmentDetailsFormQueryFields
    }
    ${AppointmentDetailsFormFragments.queryFields}
  `,
}

export const queries = {
  getData: gql`
    query AppointmentBookingPageFormSectionsGetData {
      ...AppointmentBookingPageFormSectionsQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const mutations = {
  bookAppointment: gql`
    mutation AppointmentBookingPageFormSectionsBookAppointment(
      $appointmentType: BookableAppointmentType!
      $baselineQuestions: [BaselineQuestion]!
      $filter: AvailableAppointmentSlotsFilter
      $startTime: DateTime!
      $timeZone: String!
      $userInfo: BookAppointmentUserInfoInput!
      $email: String!
    ) {
      bookAppointment(
        appointmentType: $appointmentType
        baselineQuestions: $baselineQuestions
        filter: $filter
        startTime: $startTime
        timeZone: $timeZone
        userInfo: $userInfo
      ) {
        id
        appointmentType
        startsAt
        status
        roomUrl
        counsellor {
          id
          avatarUrl
          calendarId
          firstName
          lastName
          videoCallUrl
        }
      }
      addLoginEmail(email: $email) {
        id
      }
    }
  `,
}

export const AppointmentBookingPageFormSections: FunctionComponent = () => {
  const { appTimeZone } = useApp()
  const formMethods = useFormContext<FormValues>()
  const {
    formState,
    formState: { isSubmitSuccessful },
    getFieldState,
    resetField,
    getValues,
    setValue,
  } = formMethods
  const { appointmentType } = formMethods.watch()
  const startTime = formMethods.watch("startTime")

  const { data } = useQuery<QueryData, QueryVars>(queries.getData)

  const subscriptionPlan = data?.user?.company?.subscriptionPlan

  const [bookAppointment] = useMutation<MutationData, MutationVars>(
    mutations.bookAppointment,
    {
      onError: () => {
        toast.error("Something went wrong, please try again.")
      },
      refetchQueries: [
        sessionsPageQueries.user,
        "AppointmentDetailsFormGetData",
        "TherapyRouterGetData",
        "TherapistSelectionCarouselGetData",
      ],
    }
  )

  const [openStepIndex, { inc: incOpenStepIndex, set: setOpenStepIndex }] =
    useCounter(0)

  useEffect(() => {
    if (appointmentType == null) setOpenStepIndex(0)
  }, [appointmentType])

  const onSubmit: SubmitHandler<FormValues> = async values => {
    if (
      !isNil(values.appointmentType) &&
      !isNil(values.startTime) &&
      !isNil(values.userInfo) &&
      !isNil(values.userInfo.emergencyContactName) &&
      !isNil(values.userInfo.email) &&
      values.userInfo.boundaries &&
      values.userInfo.notice
    ) {
      const variables: MutationVars = {
        appointmentType: values.appointmentType,
        filter: transformFiltersForGQL(values.filter),
        startTime: values.startTime,
        timeZone: appTimeZone,
        userInfo: {
          dateOfBirth: [
            values.userInfo.yearOfBirth,
            values.userInfo.monthOfBirth,
            values.userInfo.dayOfBirth,
          ].join("-"),
          email: values.userInfo.email,
          firstName: values.userInfo.firstName,
          lastName: values.userInfo.lastName,
          location: values.userInfo.location,
          phoneNumber:
            getCountryCallingCode(values.userInfo.phoneCountryCode) +
            values.userInfo.phone,
          pronouns: values.userInfo.pronouns,
          emergencyContact: {
            name: values.userInfo.emergencyContactName,
            phone:
              getCountryCallingCode(values.userInfo.emergencyCountryCode) +
              values.userInfo.emergencyContactPhone,
          },
        },
        email: values.userInfo.email,
        baselineQuestions: values.baselineQuestions,
      }

      const { data } = await bookAppointment({ variables })

      if (data && data.bookAppointment) {
        // Add ?confirm to the URL to prevent the user from refreshing the page and booking the same appointment again
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}?confirm=true`
        )
        setValue("appointment", data.bookAppointment)
        setOpenStepIndex(steps.length - 1)
      }

      if (data && data.bookAppointment == null) {
        toast.error("Something went wrong, please try again or get in touch")
      }
    }
  }

  const userInfoState = getFieldState("userInfo", formState)
  const dateOfBirth = [
    getValues("userInfo.yearOfBirth"),
    getValues("userInfo.monthOfBirth"),
    getValues("userInfo.dayOfBirth"),
  ].join("-")
  const dateOfBirthValid = validateDateOfBirth(dateOfBirth).valid
  const isUserInfoComplete =
    userInfoState.isTouched && !userInfoState.invalid && dateOfBirthValid

  const steps: Step[] = data?.user
    ? [
        {
          title: "Find appointment slot",
          isComplete: !isNil(startTime),
          component:
            appointmentType != null ? (
              <AppointmentTimeSelection
                allowFilters
                appointmentType={appointmentType}
                onSelect={() => incOpenStepIndex()}
              />
            ) : (
              <></>
            ),
        },
        {
          title: "Your details",
          isComplete: isUserInfoComplete,
          component: (
            <AppointmentDetailsForm
              data={data}
              onSubmit={() => incOpenStepIndex()}
            />
          ),
        },
        {
          title: "Information for your therapist",
          isComplete: isSubmitSuccessful,
          component: (
            <AppointmentBaselineQuestionsForm
              onSubmit={formMethods.handleSubmit(onSubmit)}
            />
          ),
        },
        {
          title: "Booking confirmation",
          isComplete: false,
          component: <AppointmentConfirmation />,
        },
      ]
    : []

  useEffect(() => {
    if (
      appointmentType != null &&
      isEnum(subscriptionPlan, SpillSubscriptionPlan)
    ) {
      resetField("startTime")
    }
  }, [appointmentType, subscriptionPlan])

  return (
    <div className="space-y-8">
      {steps.map((step, index) => (
        <AppointmentFormSection
          key={step.title}
          index={index}
          openStepIndex={openStepIndex}
          setOpenStepIndex={setOpenStepIndex}
          steps={steps}
        />
      ))}
    </div>
  )
}
