import { gql } from "@apollo/client"

export const queries = {
  getUser: gql`
    query QuoteSignupGetUser {
      user {
        company {
          id
        }
      }
    }
  `,
}
