import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { Helmet } from "react-helmet-async"
import { AnimatePresence, m as motion, domMax, LazyMotion } from "framer-motion"

import { NotFound404Page } from "common/components/NotFound404Page"
import {
  achievingLessThanIShould,
  chronicallyTired,
  hopelessAtWork,
} from "features/pulse/constants/burnoutQuestions"
import { V7BurnoutQuestion } from "features/pulse/components/V7BurnoutQuestion"
import { BurnoutIntroPage } from "features/pulse/components/BurnoutIntroPage"
import {
  BurnoutResults,
  fragments as BurnoutResultsFragments,
} from "features/pulse/pages/BurnoutResults"
import { V7FieldValues } from "features/pulse/types"
import {
  BurnoutGetMeetingQuery as QueryData,
  BurnoutGetMeetingQueryVariables as QueryVariables,
  BurnoutCreatePulseResponseV7Mutation as MutationData,
  BurnoutCreatePulseResponseV7MutationVariables as MutationVariables,
  BurnoutBucket,
} from "types/graphql"

export const fragments = {
  meetingFields: gql`
    fragment BurnoutMeetingFields on Meeting {
      urlId
      redirectUrl
      ...BurnoutResultsMeetingFields
    }
    ${BurnoutResultsFragments.meetingFields}
  `,
}

export const queries = {
  getMeeting: gql`
    query BurnoutGetMeeting($meetingUrlId: ID!) {
      meeting(urlId: $meetingUrlId) {
        ...BurnoutMeetingFields
      }
    }
    ${fragments.meetingFields}
  `,
  createPulseResponse: gql`
    mutation BurnoutCreatePulseResponseV7(
      $achievingLessThanIShould: Int!
      $chronicallyTired: Int!
      $hopelessAtWork: Int!
      $meetingUrlId: ID!
    ) {
      createBurnoutResponse(
        meetingUrlId: $meetingUrlId
        content: {
          achievingLessThanIShould: $achievingLessThanIShould
          chronicallyTired: $chronicallyTired
          hopelessAtWork: $hopelessAtWork
        }
      ) {
        ...BurnoutResultsBurnoutResponseFields
      }
    }
    ${BurnoutResultsFragments.burnoutResponseFields}
  `,
}

const questionIdFieldMap: Record<number, keyof V7FieldValues> = {
  1: "chronicallyTired",
  2: "hopelessAtWork",
  3: "achievingLessThanIShould",
}

const QuestionTransition: FunctionComponent<PropsWithChildren> = props => (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 10 }}
    transition={{ duration: 0.5, ease: "easeInOut" }}
  >
    {props.children}
  </motion.div>
)

export const Burnout: FunctionComponent = () => {
  const { meetingUrlId } = useParams()
  const navigate = useNavigate()

  const [questionId, setQuestionId] = useState<number>(0)
  const nextQuestion = () => setQuestionId(questionId + 1)

  const [newResponseBucket, setNewResponseBucket] =
    useState<BurnoutBucket | null>(null)

  const methods = useForm<V7FieldValues>({
    defaultValues: {
      achievingLessThanIShould: null,
      chronicallyTired: null,
      hopelessAtWork: null,
    },
    mode: "onChange",
    shouldUseNativeValidation: true,
  })

  const { getValues, handleSubmit, watch } = methods

  const currentField = questionIdFieldMap[questionId]
  const currentValue = currentField ? watch(currentField) : null

  const [getMeeting, { data }] = useLazyQuery<QueryData, QueryVariables>(
    queries.getMeeting
  )

  useEffect(() => {
    if (meetingUrlId !== undefined) {
      void getMeeting({ variables: { meetingUrlId } })
    }
  }, [meetingUrlId])

  const meeting = data?.meeting

  const [submitPulseResponseV7] = useMutation<MutationData, MutationVariables>(
    queries.createPulseResponse,
    {
      onCompleted() {
        // Not sure what we want to do here with the results page, so for now
        // let's just redirect to it
        return navigate("results")
      },
    }
  )

  const onSubmit = async () => {
    const { achievingLessThanIShould, hopelessAtWork, chronicallyTired } =
      getValues()

    if (
      achievingLessThanIShould === null ||
      hopelessAtWork === null ||
      chronicallyTired === null
    ) {
      return
    }

    const newestResponse = await submitPulseResponseV7({
      variables: {
        meetingUrlId: meeting!.urlId,
        achievingLessThanIShould: parseInt(achievingLessThanIShould),
        hopelessAtWork: parseInt(hopelessAtWork),
        chronicallyTired: parseInt(chronicallyTired),
      },
    })

    if (newestResponse.data?.createBurnoutResponse === undefined) return // Should I throw an error instead?

    const { bucket } = newestResponse.data.createBurnoutResponse

    setNewResponseBucket(bucket)
  }

  useEffect(() => {
    if (currentValue !== null) {
      if (questionId < Object.keys(questionIdFieldMap).length) nextQuestion()
      else void handleSubmit(onSubmit)().then(nextQuestion)
    }
  }, [currentValue])

  if (!meeting) return null

  return (
    <>
      <Helmet title="Spill | Team Check-in" />

      <FormProvider {...methods}>
        <form
          className="flex flex-col grow z-10"
          onSubmit={handleSubmit(onSubmit)}
        >
          <LazyMotion features={domMax}>
            <AnimatePresence mode="wait">
              <Routes>
                <Route
                  path=""
                  element={
                    <div className="absolute bg-grey-600 flex flex-col items-center justify-center left-0 min-h-screen p-md top-0 w-screen">
                      {questionId === 0 && (
                        <QuestionTransition key="introPage">
                          <BurnoutIntroPage nextQuestion={nextQuestion} />
                        </QuestionTransition>
                      )}
                      {questionId === 1 && (
                        <QuestionTransition key="chronicallyTired">
                          <V7BurnoutQuestion question={chronicallyTired} />
                        </QuestionTransition>
                      )}
                      {questionId === 2 && (
                        <QuestionTransition key="hopelessAtWork">
                          <V7BurnoutQuestion question={hopelessAtWork} />
                        </QuestionTransition>
                      )}
                      {questionId === 3 && (
                        <QuestionTransition key="achievingLessThanIShould">
                          <V7BurnoutQuestion
                            question={achievingLessThanIShould}
                          />
                        </QuestionTransition>
                      )}
                    </div>
                  }
                />
                <Route
                  path="results"
                  element={
                    <div className="absolute bg-grey-600 flex flex-col items-center justify-center left-0 min-h-screen top-0 w-screen">
                      <QuestionTransition key="results">
                        <BurnoutResults
                          redirectUrl={meeting.redirectUrl}
                          newestBurnoutRisk={newResponseBucket}
                        />
                      </QuestionTransition>
                    </div>
                  }
                />

                <Route path="*" element={<NotFound404Page />} />
              </Routes>
            </AnimatePresence>
          </LazyMotion>
        </form>
      </FormProvider>
    </>
  )
}
