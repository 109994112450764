import { useMemo, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { differenceInCalendarMonths, format, subMonths } from "date-fns"
import { find, get } from "lodash"
import { useMeasure } from "react-use"
import { inflect } from "inflection"
import {
  CartesianGrid,
  Line,
  LineChart,
  LineProps,
  ReferenceLine,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts"

import { useTheme } from "common/hooks/useTheme"
import { formatMonth } from "common/helpers/formatMonth"
import { createArray } from "common/helpers/createArray"
import { useAnalytics } from "common/context/analyticsContext"
import { Tooltip } from "common/components/Tooltip"

import type {
  MoodBenchmarkChartGetCompanyQuery as QueryData,
  MoodBenchmarkChartGetCompanyQueryVariables as QueryVariables,
} from "types/graphql"

// Gql fragment for the data we wants to display
const fragments = {
  companyFields: gql`
    fragment MoodBenchmarkChartCompanyFields on Company {
      id
      benchmarkGroupPulseResponseAggregatesByMonth(
        fromMonth: $fromMonth
        toMonth: $toMonth
      ) {
        month
        moodScore
        moodSharedResponseCount
      }
      pulseResponseAggregatesByMonth(fromMonth: $fromMonth, toMonth: $toMonth) {
        month
        moodScore
        moodSharedResponseCount
      }
    }
  `,
}

const queries = {
  getData: gql`
    query MoodBenchmarkChartGetCompany($fromMonth: Month!, $toMonth: Month!) {
      company {
        ...MoodBenchmarkChartCompanyFields
      }
    }
    ${fragments.companyFields}
  `,
}

const variables = {
  fromMonth: formatMonth(subMonths(new Date(), 6)),
  toMonth: formatMonth(new Date()),
}

/**
 * Component that displays the spill mood as a line graph for a company.
 * Allows the user to see the one for their company, as well as an
 * average for all companies.
 */
export const MoodBenchmarkChart = (): JSX.Element | null => {
  const { colors } = useTheme()
  const { track } = useAnalytics()

  const [displayBenchmark, setDisplayBenchmark] = useState(true)
  const [chartWrapperRef, chartDimensions] = useMeasure<HTMLDivElement>()
  const { data } = useQuery<QueryData, QueryVariables>(queries.getData, {
    fetchPolicy: "cache-first",
    variables,
  })

  const companyAggregates = data?.company.pulseResponseAggregatesByMonth
  const benchmarkGroupAggregates =
    data?.company.benchmarkGroupPulseResponseAggregatesByMonth

  /**
   * Generates the graph data for the benchmark chart. Will need to make a few assumptions based on missing months,
   * especially for the benchmark data.
   */
  const chartData = useMemo(() => {
    if (!companyAggregates || !benchmarkGroupAggregates) return []

    // Number of months we've requested data for.
    // Used to determine number of chart data items.
    const numMonths = differenceInCalendarMonths(
      new Date(variables.toMonth),
      new Date(variables.fromMonth)
    )

    return createArray(numMonths + 1, index => {
      const month = formatMonth(subMonths(new Date(), numMonths - index))

      const companyAggregatesForMonth = find(companyAggregates, {
        month,
      })
      const benchmarkGroupAggregatesForMonth = find(benchmarkGroupAggregates, {
        month,
      })

      /**
       * We use this const to determine if it's the last available record for the company.
       * If it is, we want to show the 'estimate' line for the month (the dotted one).
       * Having this bool allows us to do that.
       */
      const isLastRecordedBenchmark = index === numMonths
      /**
       * Check if the record is the current month -1 (the month before the current month).
       * We need to do this in order to set the start of the `estimate` line from the previous
       * month.
       */
      const isPrecursorToLastRecordedBenchmark = index === numMonths - 1

      const mood = companyAggregatesForMonth?.moodScore ?? null
      const moodBenchmark = benchmarkGroupAggregatesForMonth?.moodScore ?? null

      return {
        month,
        benchmarkGroupMoodSharedResponseCount:
          benchmarkGroupAggregatesForMonth?.moodSharedResponseCount,
        companyMoodSharedResponseCount:
          companyAggregatesForMonth?.moodSharedResponseCount,
        mood: !isLastRecordedBenchmark ? mood : null,
        moodBenchmark: !isLastRecordedBenchmark ? moodBenchmark : null,
        // If it's the previous to last month, we need to duplicate the current month
        // so we can graph the estimate
        moodMTD:
          isPrecursorToLastRecordedBenchmark || isLastRecordedBenchmark
            ? mood
            : null,
        moodBenchmarkMTD:
          isPrecursorToLastRecordedBenchmark || isLastRecordedBenchmark
            ? moodBenchmark
            : null,
      }
    })
  }, [data])

  const commonAxisProps = {
    color: colors.grey[600],
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontWeight: "medium",
    stroke: colors.grey[600],
    strokeWidth: 2,
    tick: { fill: colors.grey[600] },
    tickLine: false,
  }
  const commonLineProps = {
    connectNulls: false,
    isAnimationActive: false,
    strokeWidth: 3,
  }
  const commonSpillBenchmarkLineProps = {
    ...commonLineProps,
    activeDot: displayBenchmark,
    stroke: colors.blue[200],
    visibility: displayBenchmark ? "visible" : "hidden",
    onMouseOver: (lineDetails: LineProps) => {
      track(`Admin hover Spill Mood ${lineDetails?.name ?? ""} line`)
    },
  }

  return (
    <div className="cursor-default flex flex-col gap-md">
      <header>
        <h4>Mood NPS</h4>
        <div className="space-y-xs">
          <p>
            See how your company mood has changed over the last 6 months, and
            how you compare against similar companies.
          </p>
          <p>
            Rather than showing your average mood score, we use the same logic
            as a Net Promoter Score.{" "}
            <Tooltip
              followMouse
              content={
                <div className="space-y-xs">
                  <p className="text-sm">
                    Your team’s average mood score (between 1 and 10) tends to
                    change very little over time. To show peaks and troughs in a
                    more useful way, we calculate your Mood NPS.
                  </p>
                  <p className="text-sm">
                    That means we take the percentage of people giving low mood
                    scores (6 and below) away from the percentage of people
                    giving high mood scores (9 or 10) to get a value between
                    -100 and 100. That’s what you’ll see plotted on your chart.
                  </p>
                </div>
              }
              id="mood-nps-info"
            >
              <span className="cursor-help underline">Show me why.</span>
            </Tooltip>
          </p>
        </div>
      </header>

      <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-[inherit]">
        {/* Controls */}
        <div className="space-y-sm sm:w-40 md:w-auto lg:w-40">
          <label className="checkbox-label" htmlFor="displaySpillBenchmark">
            <input
              checked={displayBenchmark}
              className="checkbox"
              id="displaySpillBenchmark"
              name="displaySpillBenchmark"
              onChange={() => {
                setDisplayBenchmark(!displayBenchmark)
                track(
                  "Admin viewing benchmarks for Spill Mood Benchmark Chart",
                  { viewingBenchmark: !displayBenchmark }
                )
              }}
              type="checkbox"
            />
            <span className="label-text">Show benchmarks</span>
          </label>

          {companyAggregates && companyAggregates.length < 2 && (
            <div className="bg-grey-100 p-sm space-y-xs leading-snug rounded-md">
              <p className="text-sm">Congratulations for starting check-ins!</p>
              <p className="text-sm">Looks a bit sparse? Don't worry.</p>
              <p className="text-sm">
                After another month or two, you'll have an awesome graph to
                view.
              </p>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-sm grow">
          {/* Legend */}
          <div className="legend ml-[41px]">
            <p className="legend-item">
              <span className="legend-item-indicator bg-blue-400" />
              Mood
            </p>
            {displayBenchmark && (
              <p className="legend-item">
                <span className="legend-item-indicator bg-blue-200" />
                Mood Benchmark
              </p>
            )}
          </div>

          {/* Chart */}
          <div className="aspect-[2/1] xl:aspect-[3/1] grow relative w-full">
            <div
              className="absolute top-0 right-0 left-0 bottom-0"
              ref={chartWrapperRef}
            >
              <LineChart
                data={chartData}
                height={chartDimensions.height}
                margin={{
                  left: -18, // Aligns left side of X axis with legend
                  right: 5,
                  top: 5,
                }}
                width={chartDimensions.width}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  {...commonAxisProps}
                  dataKey="month"
                  tickFormatter={(value: string) => {
                    return value === "auto"
                      ? ""
                      : format(new Date(value), "LLL")
                  }}
                  axisLine={false}
                />
                <YAxis {...commonAxisProps} type="number" />
                <ReferenceLine {...commonAxisProps} y={0} />
                <RechartsTooltip
                  animationDuration={50}
                  content={({ active, payload }) => {
                    if (active === true && payload && payload.length) {
                      if (payload[0] == null) {
                        return null
                      }

                      const month = get(
                        payload[0],
                        "payload.month",
                        ""
                      ) as string
                      const mood = (get(payload, "[0].payload.mood") ??
                        get(
                          payload,
                          "[0].payload.moodMTD"
                        )) as unknown as number
                      const moodBenchmark = (get(
                        payload,
                        "[0].payload.moodBenchmark"
                      ) ??
                        get(
                          payload,
                          "[0].payload.moodBenchmarkMTD"
                        )) as unknown as number
                      const benchmarkGroupMoodSharedResponseCount = get(
                        payload[0],
                        "payload.benchmarkGroupMoodSharedResponseCount",
                        0
                      ) as number
                      const companyMoodSharedResponseCount = get(
                        payload[0],
                        "payload.companyMoodSharedResponseCount",
                        0
                      ) as number

                      return (
                        <div
                          key={month}
                          className="backdrop-blur-md bg-mono-black/50 grid gap-xs overflow-hidden p-sm rounded-lg text-sm text-mono-white"
                        >
                          <p className="col-span-3 font-bold font-display pb-xs">
                            {format(new Date(month), "MMMM yyyy")}
                          </p>

                          <span className="legend-item-indicator bg-blue-400" />
                          <span className="font-bold">Mood</span>
                          <span className="font-bold pl-sm place-self-end">
                            {mood}
                          </span>
                          <span className="col-start-2 leading-none pb-xs last:pb-0">
                            {companyMoodSharedResponseCount}{" "}
                            {inflect(
                              "response",
                              companyMoodSharedResponseCount
                            )}
                          </span>
                          <div />

                          {displayBenchmark && moodBenchmark && (
                            <>
                              <span className="legend-item-indicator bg-blue-200" />
                              <span className="font-bold">Mood benchmark</span>
                              <span className="font-bold pl-sm place-self-end">
                                {moodBenchmark}
                              </span>
                              <span className="col-start-2 leading-none pb-xs last:pb-0">
                                {benchmarkGroupMoodSharedResponseCount}{" "}
                                {inflect(
                                  "response",
                                  benchmarkGroupMoodSharedResponseCount
                                )}
                              </span>
                              <div />
                            </>
                          )}
                        </div>
                      )
                    }

                    return null
                  }}
                />
                {/* The bottom two Line components are the known values, whereas the top two Line components represent
              the estimated values of the current month */}
                <Line
                  {...commonSpillBenchmarkLineProps}
                  dataKey="moodBenchmarkMTD"
                  dot={{ strokeDasharray: 0 }}
                  strokeDasharray="4 4"
                  name="Spill Benchmark"
                  onMouseOver={(line: LineProps) =>
                    commonSpillBenchmarkLineProps.onMouseOver(line)
                  }
                />
                <Line
                  {...commonSpillBenchmarkLineProps}
                  dataKey="moodBenchmark"
                  dot={{ fill: colors.blue[200] }}
                  name="Mood Benchmark"
                  onMouseOver={(line: LineProps) =>
                    commonSpillBenchmarkLineProps.onMouseOver(line)
                  }
                />
                <Line
                  {...commonLineProps}
                  dataKey="moodMTD"
                  dot={{ strokeDasharray: 0 }}
                  stroke={colors.blue[400]}
                  strokeDasharray="4 4"
                  onMouseOver={(line: LineProps) =>
                    commonSpillBenchmarkLineProps.onMouseOver(line)
                  }
                />
                <Line
                  {...commonLineProps}
                  dataKey="mood"
                  dot={{ fill: colors.blue[400] }}
                  name="Mood"
                  stroke={colors.blue[400]}
                  onMouseOver={(line: LineProps) =>
                    commonSpillBenchmarkLineProps.onMouseOver(line)
                  }
                />
              </LineChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MoodBenchmarkChart.fragments = fragments
MoodBenchmarkChart.queries = queries
MoodBenchmarkChart.variables = variables
