/**
 * An animated version of the 'sun' icon that can be found as a static SVG in
 * /src/common/assets/images/moods/10.svg
 **/

import React from "react"
import { motion, useAnimation } from "framer-motion"

interface IconAnimationProps {
  isAnimating: boolean
}

export const Sun: React.FunctionComponent<IconAnimationProps> = props => {
  const iconAnimation = useAnimation()

  React.useEffect(() => {
    if (props.isAnimating) {
      iconAnimation
        .start({
          scale: [0.75, 1.25, 1],
          transition: {
            duration: 1.5,
            repeat: 0,
          },
        })
        .catch(() => {})
    } else {
      iconAnimation.stop()
    }
  }, [props.isAnimating])

  return (
    <motion.svg
      width="34"
      height="50"
      viewBox="0 0 34 50"
      xmlns="http://www.w3.org/2000/svg"
      animate={iconAnimation}
    >
      <motion.path d="M17.0865 42C13.8227 42 10.6322 41.0614 7.91852 39.3028C5.20489 37.5443 3.09005 35.0449 1.8415 32.1207C0.592956 29.1965 0.266803 25.9789 0.904284 22.8749C1.54177 19.771 3.11425 16.9201 5.42283 14.6828C7.73142 12.4456 10.6724 10.9225 13.8737 10.3062C17.0751 9.68999 20.393 10.0083 23.4078 11.2208C26.4226 12.4333 28.9989 14.4856 30.8107 17.1181C32.6225 19.7505 33.5885 22.845 33.5865 26.0099C33.5811 30.2508 31.8407 34.3164 28.7472 37.3143C25.6537 40.3122 21.46 41.9974 17.0865 42ZM17.0865 12.4775C14.3264 12.4775 11.6283 13.2711 9.33336 14.7581C7.03843 16.2451 5.24974 18.3585 4.19349 20.8313C3.13725 23.304 2.8609 26.0249 3.39937 28.6499C3.93784 31.2749 5.26694 33.6862 7.21862 35.5787C9.1703 37.4713 11.6569 38.7601 14.364 39.2822C17.071 39.8044 19.8769 39.5364 22.4269 38.5122C24.9769 37.4879 27.1565 35.7535 28.6899 33.5281C30.2233 31.3027 31.0418 28.6863 31.0418 26.0099C31.0337 22.4251 29.5602 18.9897 26.9442 16.4567C24.3282 13.9237 20.7833 12.4998 17.0865 12.4972V12.4775Z" />
    </motion.svg>
  )
}
