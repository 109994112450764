import { useForm } from "react-hook-form"
import { Button, P } from "@spillchat/puddles"
import { Fragment, type ChangeEvent, type FunctionComponent } from "react"

import { createArray } from "common/helpers/createArray"

import type { SubmitHandler } from "react-hook-form"

export interface FieldValues {
  code: string[]
}

interface CodeInputProps {
  onSubmit: (values: FieldValues) => Promise<void>
}

export const CodeInput: FunctionComponent<CodeInputProps> = props => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setFocus,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: {
      code: createArray(9, () => ""),
    },
  })

  const onSubmit: SubmitHandler<FieldValues> = async values => {
    await props.onSubmit(values)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 text-left"
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-1 lg:gap-2 font-bold items-center text-base lg:text-2xl rounded-md w-fit">
          {createArray(9, index => (
            <Fragment key={index}>
              <input
                {...register(`code.${index}`, {
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value !== "" && index < 8) {
                      setFocus(`code.${index + 1}`)
                    }
                    if (e.target.value === "" && index > 0) {
                      setFocus(`code.${index - 1}`)
                    }
                  },
                })}
                autoComplete="off"
                // This autofocuses the first input so we're happy with it
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={index === 0}
                className="aspect-square w-8 h-8 border border-grey-200 capitalize rounded-md text-center max-w-[48px] focus:outline-none focus:ring-2"
                maxLength={1}
                onFocus={e => {
                  e.target.select()
                }}
                onPasteCapture={e => {
                  setFocus("code.8")
                  const pastedCode = e.clipboardData
                    .getData("Text")
                    .replace(/\s|-/g, "")
                  for (let i = 0; i < 9; i++) {
                    setValue(`code.${i}`, pastedCode[i] ?? "")
                  }
                }}
              />
              {[2, 5].includes(index) && <span key={`${index}dash`}>-</span>}
            </Fragment>
          ))}
        </div>
        {errors.code && errors.code[8] && (
          <span className="text-red-400">
            <P size="xs">{errors.code[8].message}</P>
          </span>
        )}
        <P size="xs">Can't find your code? Check your spam folder!</P>
      </div>
      <div className="flex flex-col gap-2 items-start">
        <Button loading={isSubmitting} type="submit">
          Confirm code
        </Button>
      </div>
    </form>
  )
}
