import { FunctionComponent } from "react"
import { useMutation, gql, useQuery } from "@apollo/client"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { Helmet } from "react-helmet-async"
import { toast } from "sonner"
import { H1, P, Button, Tooltip } from "@spillchat/puddles"
import { z } from "zod"
import { captureMessage } from "@sentry/react"

import { useAnalytics } from "common/context/analyticsContext"
import { TextArea } from "common/components/FormElements/TextArea"
import {
  AskATherapistPageGetDataQuery,
  AskATherapistPageCreateAskATherapistQuestionMutation as MutationData,
  AskATherapistPageCreateAskATherapistQuestionMutationVariables as MutationVars,
  TherapyAccessReason,
  SpillSubscriptionStatus,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"

import { AskATherapistError } from "./error"

export const queries = {
  getData: gql(`
    query AskATherapistPageGetData {
      user {
        id
        company{
          subscriptionStatus
          inActiveTrialPeriod
        }
        accessToTherapyFeatures {
          askATherapist {
            hasAccess
            reason
          }
        }
      }
    }
  `),
}

export const fragments = {
  askATherapistQuestionFields: gql`
    fragment AskATherapistPageAskATherapistQuestionFields on AskATherapistQuestion {
      id
      question
    }
  `,
}

export const mutations = {
  createAskATherapistQuestion: gql`
    mutation AskATherapistPageCreateAskATherapistQuestion($question: String!) {
      createAskATherapistQuestion(
        question: $question
        questionType: ASK_A_THERAPIST
      ) {
        ...AskATherapistPageAskATherapistQuestionFields
      }
    }
    ${fragments.askATherapistQuestionFields}
  `,
}

interface FormValues {
  question: string
}

export const AskATherapistPage: FunctionComponent = () => {
  const { track } = useAnalytics()
  const { data: usageData, loading: usageDataLoading } =
    useQuery<AskATherapistPageGetDataQuery>(queries.getData)

  const formMethods = useForm<FormValues>({
    defaultValues: {
      question: "",
    },
  })

  const [submitAskATherapistQuestion, { data, reset }] = useMutation<
    MutationData,
    MutationVars
  >(mutations.createAskATherapistQuestion, {
    onError: () => {
      toast.error("Something went wrong, please try again or get in touch.")
    },
    onCompleted: () => {
      track("User submitted Ask a Therapist Question")
    },
  })

  if (usageDataLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  // Validate the user data, mainly the rules around the accessToTherapyFeatures
  const requiredRulesUI = z.object({
    accessToTherapyFeatures: z.object({
      askATherapist: z.array(
        z.object({
          hasAccess: z.boolean().refine(value => value === true, {
            message: "User has no access to Ask a Therapist",
          }),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
    }),
    company: z.object({
      subscriptionStatus: z.nullable(z.string()),
      inActiveTrialPeriod: z.boolean(),
    }),
  })

  const sessionBookingRules = requiredRulesUI.safeParse(usageData?.user)
  if (!sessionBookingRules.success) {
    captureMessage(
      `User ${
        usageData?.user?.id ?? ""
      } unable to ask question: ${sessionBookingRules.error.issues
        .map(issue => issue.message)
        .join(", ")}`
    )

    if (usageData?.user?.accessToTherapyFeatures == null) {
      return (
        <>We were unable to display the ask a therapist page at this time.</>
      )
    }

    return (
      <AskATherapistError
        featureAccessReason={
          usageData?.user?.accessToTherapyFeatures.askATherapist.at(0)
            ?.reason ?? TherapyAccessReason.OK
        }
      />
    )
  }

  const hasSubmitted = data?.createAskATherapistQuestion?.id !== undefined

  const onSubmit: SubmitHandler<FormValues> = values => {
    if (
      sessionBookingRules.data.company.subscriptionStatus !==
      SpillSubscriptionStatus.ACTIVE
    ) {
      return
    }
    if (!hasSubmitted) {
      void submitAskATherapistQuestion({
        variables: { question: values.question },
      })
    } else {
      // Reset form and mutation to show original form UI
      formMethods.reset()
      reset()
    }
  }

  return (
    <>
      <Helmet title="Ask a Therapist | Spill" />

      <FormProvider {...formMethods}>
        <form
          className="flex flex-col gap-[inherit] items-start space-y-6 lg:space-y-8 min-h-full"
          onSubmit={formMethods.handleSubmit(onSubmit)}
        >
          <H1>Ask a Therapist</H1>
          {!hasSubmitted ? (
            <>
              <P>
                Send a message to a Spill therapist about anything that&apos;s
                weighing on your mind — a relationship issue, sleeping problems,
                or uncertainty around your life goals.
              </P>

              <P>You&apos;ll get a considered reply within 1-2 working days.</P>
            </>
          ) : (
            <P>
              Thanks. We got your message and we'll try to get back within one
              or two working days. Very rarely is there a longer wait – but if
              there is, do please write to us at{" "}
              <a className="underline" href="mailto:hi@spill.chat">
                hi@spill.chat
              </a>
            </P>
          )}

          {!hasSubmitted && (
            <TextArea
              grow
              id="question"
              name="question"
              placeholder="e.g. How do I overcome imposter syndrome?"
              register={formMethods.register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Required",
                },
              }}
            />
          )}

          <P muted>
            If you feel you need urgent support or that you may be at risk of
            hurting yourself or others, please contact emergency services on 999
            or NHS advice on 111 (in the UK), or attend your nearest emergency
            department.{" "}
            <a className="underline" href="/resources/crisis-lines">
              You can also see a list of alternative crisis services here.
            </a>
          </P>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger>
                <Button
                  disabled={
                    sessionBookingRules.data.company.subscriptionStatus !==
                      SpillSubscriptionStatus.ACTIVE &&
                    !sessionBookingRules.data.company.inActiveTrialPeriod
                  }
                  type="submit"
                >
                  {!hasSubmitted ? (
                    <>Ask question</>
                  ) : (
                    <>I have another question</>
                  )}
                </Button>
                {sessionBookingRules.data.company.subscriptionStatus === null &&
                  !sessionBookingRules.data.company.inActiveTrialPeriod && (
                    <Tooltip.Content>
                      Your company hasn't yet added their payment details.
                    </Tooltip.Content>
                  )}
                {sessionBookingRules.data.company.subscriptionStatus ===
                  SpillSubscriptionStatus.INACTIVE && (
                  <Tooltip.Content>Company not active.</Tooltip.Content>
                )}
              </Tooltip.Trigger>
            </Tooltip.Root>
          </Tooltip.Provider>
        </form>
      </FormProvider>
    </>
  )
}
