import { FunctionComponent, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { Button } from "@spillchat/puddles"

import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { useSearchParams } from "common/helpers/useSearchParams"
import { AppointmentTypeFeature } from "types/graphql"

import { AbilityToCopeSection } from "./AbilityToCopeSection"
import { ContextSection } from "./ContextSection"
import { FeelingsSection } from "./FeelingsSection"
import { FeelingsFrequencySection } from "./FeelingsFrequencySection"
import { BereavementCheckSection } from "./BereavementCheckSection"
import { PreviousCounsellingSection } from "./PreviousCounsellingSection"
import { ReasonsForBookingSection } from "./ReasonsForBookingSection"
import { GoalsSection } from "./GoalsSection"
import { ParentalLeaveSection } from "./ParentalLeaveSection"

interface AppointmentBaselineQuestionsFormProps {
  onSubmit: () => void
}

export const AppointmentBaselineQuestionsForm: FunctionComponent<
  AppointmentBaselineQuestionsFormProps
> = props => {
  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    setFocus,
  } = useFormContext<FormValues>()

  const [searchParams] = useSearchParams()
  const appointmentType = searchParams.get(
    "appointmentType"
  ) as AppointmentTypeFeature

  const parenthoodTypes = [
    AppointmentTypeFeature.PARENTHOOD_CONSULTATION,
    AppointmentTypeFeature.PARENTHOOD_SESSION,
  ]
  const bereavementTypes = [
    AppointmentTypeFeature.BEREAVEMENT_CONSULTATION,
    AppointmentTypeFeature.BEREAVEMENT_SESSION,
  ]

  const isParenthood = parenthoodTypes.includes(appointmentType)
  const isBereavement = bereavementTypes.includes(appointmentType)

  useEffect(() => {}, [errors, setFocus])

  // Due to the current way we we're validating the following subforms, we just go to the top of the baseline questions
  // when there's a validation error. Not as ideal as our usual behaviour.
  const scrollToFormTop = () => {
    const form = document.getElementById("baseline-questions")
    if (form) {
      form.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <form
      className="flex flex-col gap-8"
      onSubmit={handleSubmit(props.onSubmit, () => scrollToFormTop())}
      id="baseline-questions"
    >
      {isBereavement && !isParenthood && (
        <>
          <BereavementCheckSection
            idx={1}
            question="Have you recently lost someone close to you?"
          />
          <ContextSection
            idx={2}
            question="How has this affected you? What has it brought up for you?"
            placeholder={`e.g. "My friend died almost a year ago and I don't know how to cope with the anniversary" or "I recently lost a family member and feel completely numb"`}
          />
          <PreviousCounsellingSection
            idx={3}
            question="Have you received any form of support like bereavement counselling elsewhere?"
          />
          <AbilityToCopeSection
            idx={4}
            question="How do you feel you have been coping?"
          />
          <FeelingsSection
            idx={4}
            question="And in the last 2 weeks have you been feeling any of the following?"
          />
          <FeelingsFrequencySection
            idx={5}
            question="How often in the last 2 weeks have you been feeling like this?"
          />
        </>
      )}

      {!isBereavement && isParenthood && (
        <>
          <ParentalLeaveSection
            idx={1}
            question="Which best describes where you are in your parental leave?"
          />
          <AbilityToCopeSection
            idx={2}
            question="With this in mind, in the last 2 weeks how have you been coping?"
          />
          <FeelingsSection
            idx={3}
            question="And in the last 2 weeks have you been feeling any of the following?"
          />
          <FeelingsFrequencySection
            idx={4}
            question="How often in the last 2 weeks have you been feeling like this?"
          />
          <ContextSection
            idx={5}
            question="Finally, are there or were there any complications around birth or pregnancy that you'd like your therapist to know about?"
            placeholder=""
          />
        </>
      )}

      {!isBereavement && !isParenthood && (
        <>
          <ContextSection
            idx={1}
            question="What brought you to Spill today?"
            placeholder={`e.g. "I've been feeling constantly anxious because" or "I would like to explore why I'm feeling so drained"`}
          />
          <ReasonsForBookingSection
            idx={2}
            question="What area of your life are you looking for support with?"
          />
          <AbilityToCopeSection
            idx={3}
            question="With this in mind, in the last 2 weeks how have you been coping?"
          />
          <FeelingsSection
            idx={4}
            question="And in the last 2 weeks have you been feeling any of the following?"
          />
          <FeelingsFrequencySection
            idx={5}
            question="How often in the last 2 weeks have you been feeling like this?"
          />
          <GoalsSection
            idx={6}
            question="What are you looking to get out of the session, what is your goal?"
          />
        </>
      )}
      <Button loading={isSubmitting} type="submit">
        {isSubmitting ? "Confirming" : "Confirm"} booking
      </Button>
    </form>
  )
}
