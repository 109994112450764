import { debounce } from "lodash"
import { ReactElement, useEffect, useRef, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { FormSearchParamsSync } from "common/components/FormSearchParamsSync"
import { cn } from "common/helpers/cn"
import { useApp } from "common/context/appContext"
import { useBreakpoint } from "common/hooks/useBreakpoint"

import { FormValues } from "../pages/AppointmentBookingPage"
import { AppointmentBookingPageFormSections } from "../pages/AppointmentBookingPage/AppointmentBookingPageFormSections"

import { TherapistSidebar } from "./TherapistSidebar"

export const AppointmentBookingForm = ({
  defaultValues,
  userId,
}: {
  defaultValues: FormValues
  userId: string
}): ReactElement => {
  const { isBannerVisible } = useApp()

  const breakpoint = useBreakpoint("lg")

  const sidebarRef = useRef<HTMLDivElement>(null)
  const bookingFormContainerRef = useRef<HTMLDivElement>(null)

  const [isOverlapping, setIsOverlapping] = useState(0)

  /**
   * Calculates the horizontal overlap between the booking form and the sidebar
   */
  const getHorizontalOverlap = () => {
    const bookingFormRef =
      bookingFormContainerRef.current?.getBoundingClientRect()
    const detailsRef = sidebarRef.current?.getBoundingClientRect()

    // The 1024 here is the breakpoint for which the sidebar overlaps the booking form
    // Anything below 1024 works as expected
    if (!bookingFormRef || !detailsRef || window.innerWidth <= 1024) return 0

    // Check if there is a horizontal overlap
    if (
      bookingFormRef.right > detailsRef.left &&
      bookingFormRef.left < detailsRef.right
    ) {
      // Calculate and return the horizontal overlap
      return (
        Math.min(bookingFormRef.right, detailsRef.right) -
        Math.max(bookingFormRef.left, detailsRef.left)
      )
    }

    return 0
  }

  const handleCheck = () => {
    setIsOverlapping(getHorizontalOverlap())
  }

  useEffect(() => {
    handleCheck() // Call on initial render
    window.addEventListener("resize", debounce(handleCheck, 125))

    return () => {
      window.removeEventListener("resize", debounce(handleCheck, 125))
    }
  }, [])

  const formMethods = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
    shouldFocusError: true,
  })

  return (
    <FormProvider {...formMethods}>
      <FormSearchParamsSync<FormValues>
        paths={[
          "appointmentType",
          {
            formName: "filter.counsellorIds",
            isArray: true,
            searchParamName: "counsellorId",
          },
          {
            formName: "filter.lifeEvent",
            searchParamName: "lifeEvent",
          },
        ]}
      />

      <div className="w-full" ref={bookingFormContainerRef}>
        <div
          className={cn(
            "bg-mono-white flex flex-col lg:grow order-2 lg:order-1 space-y-8 w-full",
            {
              "min-h-[calc(100vh-104px)] lg:min-h-[calc(100vh-40px)]":
                isBannerVisible,
              "min-h-[calc(100vh-64px)] lg:min-h-screen": !isBannerVisible,
            }
          )}
          // Due to the sidebar being a fixed element, we need to add padding to the right of the booking form
          // so there's no overlap between the two elements
          style={{
            paddingRight: breakpoint ? `calc(64px + ${isOverlapping}px)` : "",
          }}
        >
          <AppointmentBookingPageFormSections />

          <div className="bg-grey-200 p-8 space-y-4 lg:hidden">
            <h6>Looking for something specific?</h6>

            <p className="text-sm">
              Let us know anything additional you're looking for that you can't
              find by using the filters with{" "}
              <a
                className="underline"
                href={`https://spillchat.typeform.com/to/rml3m0eO#user_id=${userId ?? ""}&session_type=${formMethods.getValues("appointmentType") ?? ""}&location=side_panel`}
                rel="noreferrer"
                target="_blank"
              >
                this form
              </a>
              .
            </p>

            <p className="font-bold text-sm">
              Have a question? Email us at{" "}
              <a className="underline" href="mailto:therapy@spill.chat">
                therapy@spill.chat
              </a>
            </p>
          </div>
        </div>
      </div>
      <TherapistSidebar sidebarRef={sidebarRef} />
    </FormProvider>
  )
}
