import { FunctionComponent, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { Button, H2, P } from "@spillchat/puddles"

import { useAnalyticsPulse } from "features/pulse/hooks/useAnalyticsPulse"
import { AnimatedIconProps } from "common/components/AnimatedIcons/types"

import { ANIMATED_IMAGE_MOOD_MAP } from "../constants/emotions"

import type { MouseEventHandler } from "react"
import type { V4FieldValues } from "features/pulse/types"

type V4MoodScoreQuestionProps = {
  nextQuestion: () => void
}

export function V5MoodScoreQuestion({
  nextQuestion,
}: V4MoodScoreQuestionProps): JSX.Element {
  const { track } = useAnalyticsPulse()
  const { trigger } = useFormContext<V4FieldValues>()

  useEffect(() => {
    track("User Opened Mood Score")
    void trigger(["mood"])
  }, [])

  return (
    <main className="justify-center">
      <div className="flex flex-col gap-2">
        <H2>How's your mood?</H2>
        <P>
          This is private by default, you can choose what to share with your
          team before submitting.
        </P>
      </div>

      <div className="shadow-sm bg-mono-white p-4 rounded-lg flex flex-col gap-4">
        <div className="flex justify-between w-full p-0 mt-0">
          <P muted>1/10 Terrible</P>
          <P muted>Great 10/10</P>
        </div>
        <div className="w-full grid grid-cols-5 md:grid-cols-10 gap-4">
          {
            // Iterate over ANIMATED_IMAGE_MOOD_MAP and render each element inside a <RadioInput>
            Object.entries(ANIMATED_IMAGE_MOOD_MAP).map(([key, element]) => {
              return <RadioInput key={key} value={key} icon={element} />
            })
          }
        </div>
        <div className="flex flex-row-reverse w-full p-0 mt-0 italic"></div>
        <footer className="flex gap-sm justify-end">
          <NextButton onClick={nextQuestion} />
        </footer>
      </div>
    </main>
  )
}

type RadioInputProps = {
  value: string
  icon: FunctionComponent<AnimatedIconProps>
}

const RadioInput: FunctionComponent<RadioInputProps> = props => {
  const { track } = useAnalyticsPulse()
  const { register, getValues } = useFormContext<V4FieldValues>()
  const [iconHover, setIconHover] = useState(false)

  /**
   * We animate the icon when the user has it selected as well so that it runs
   * even when the user isn't hovering.
   */
  useEffect(() => {
    setIconHover(getValues("mood") === props.value)
  }, [getValues("mood")])

  return (
    <label key={props.value} className="aspect-square cursor-pointer flex">
      <input
        className="peer opacity-0 h-0 w-0 pointer-events-none"
        type="radio"
        value={props.value}
        {...register("mood", {
          onChange: () => {
            const newRating = getValues("mood")
            track("User Selected Mood Score", { Score: newRating })
          },
          validate: val => val !== null,
        })}
      />

      <div
        onMouseEnter={() => setIconHover(true)}
        onMouseLeave={() =>
          // We don't want to set the iconHover state to false if the user has selected the icon
          getValues("mood") !== props.value
            ? setIconHover(false)
            : setIconHover(true)
        }
        onFocus={() => setIconHover(true)}
        onBlur={() => setIconHover(false)}
        className="bg-grey-100 flex flex-col hover:transition-all peer-checked:bg-yellow-400 hover:bg-yellow-200 items-center justify-center p-2 sm:p-4 peer-checked:bg-opacity-100 rounded-lg w-full"
      >
        <props.icon isAnimating={iconHover} />
      </div>
    </label>
  )
}

function NextButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>
}) {
  const { formState, getFieldState, watch } = useFormContext<V4FieldValues>()
  const { invalid } = getFieldState("mood", formState)

  watch("mood")

  return (
    <Button disabled={invalid} onClick={onClick} type="button">
      Continue
    </Button>
  )
}
