import { Accordion, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

const accordionItems = [
  {
    trigger: "Who are the therapists on Spill?",
    content: (
      <div className="flex flex-col gap-4">
        <P>
          Spill therapists are made up of a wide group of practitioners from a
          wide variety of backgrounds, with an equally wide range of experience
          - 80+ areas of expertise, in fact!
        </P>
        <P>
          What they have in common is extensive clinical experience and a huge
          amount of knowledge; we only hire the top 13% of therapists after our
          five-step hiring process, so we&apos;re confident that we work with
          some of the very best therapists available.
        </P>
        <P>
          We also offer training and supervision to all Spill counsellors, as
          well as regularly reviewing client feedback, in order to make sure
          that every client finds the best therapist for them.
        </P>
      </div>
    ),
  },
  {
    trigger: "Which languages can employees have therapy in?",
    content: (
      <div className="flex flex-col gap-4">
        <P>
          We offer therapy in more than 15 languages, almost entirely with
          native speakers, so that your team talk to a therapist in whichever
          language they feel most comfortable with.
        </P>
        <P>
          These are Armenian, Croatian, French, German, Greek, Hebrew, Hindi,
          Igbo, Italian, Polish, Portuguese, Russian, Slovenian, Spanish,
          Tagalog and Ukrainian.
        </P>
        <P>
          We&apos;re adding to this list all the time, so do let us know if you
          have any requests.
        </P>
      </div>
    ),
  },
  {
    trigger: "What if my question isn't answered here?",
    content: (
      <div className="flex flex-col gap-4">
        <P>
          Book a 30 min demo{" "}
          <a
            href="https://www.spill.chat/book-demo"
            target="_blank"
            className="underline underline-offset-2"
            rel="noreferrer"
          >
            here
          </a>
          .
        </P>
        <P>
          You can also email Anna who wrote these questions at atm@spill.chat to
          let her know what&apos;s missing.
        </P>
      </div>
    ),
  },
  {
    trigger:
      "What will I do with all my free time now that people will be feeling more harmonious, self-aware and cared for?!?",
    content: (
      <>
        <P>
          Sit back and rejoice in your success (and then inevitably move on to
          the next thing on your to-do&nbsp;list)
        </P>
      </>
    ),
  },
]

export const QuoteViewFAQ: FunctionComponent = () => {
  return <Accordion items={accordionItems} />
}
