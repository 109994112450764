import { P, Button } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

export const ProfileLinkAccount: FunctionComponent = () => {
  return (
    <>
      <div className="flex flex-col gap-8">
        <P>
          If you had access to Spill in your old company and want to transfer
          over your therapy history you can link your accounts using the button
          below.
        </P>
        <Button variant="secondary" asChild>
          <Link to="/profile/link-account">Link old account</Link>
        </Button>
      </div>
    </>
  )
}
