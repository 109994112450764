/* eslint-disable jsx-a11y/label-has-associated-control */
import { CheckIcon } from "@heroicons/react/24/outline"
import cn from "classnames"

import { ErrorText } from "common/components/FormElements/ErrorText"
import { Label } from "common/components/FormElements/Label"

import type { ComponentProps } from "react"
import type {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form"

export interface CheckboxProps<T extends FieldValues>
  extends Omit<ComponentProps<"input">, "size"> {
  /**
   * Id for the checkbox input.
   * Optional as many times it can be the same as the name.
   */
  id?: string
  /**
   * Optional label for the input.
   */
  label?: ComponentProps<"label">["children"]
  /**
   * Label component props primarily used for tweaking label styling contextually.
   */
  labelProps?: ComponentProps<typeof Label>
  /**
   * Name of the field that we're adding to the form. This is used to create the
   * react-hook-form register function.
   */
  name: Path<T>
  /**
   * We use the register from the useForm hook to register the input with react-hook-form.
   * The key name should match the id of the input. This will be passed from the parent component.
   *
   */
  register?: UseFormRegister<T>
  /**
   * Allows us to provide a set of additional options and validation rules to the input.
   */
  registerOptions?: RegisterOptions<T, Path<T>>
  /**
   *
   */
  size?: "sm" | "md"
}

export const Checkbox = <FormValues extends FieldValues>({
  label,
  labelProps = {},
  name,
  register,
  registerOptions,
  size,
  ...rest
}: CheckboxProps<FormValues>): React.ReactElement<FormValues> => {
  const id = rest.id ?? name

  return (
    <div>
      <div className="flex items-center">
        <input
          className="h-0 opacity-0 peer w-0"
          id={id}
          type="checkbox"
          {...(register && register(name, registerOptions))}
          {...rest}
        />
        <label
          className={cn(
            "bg-mono-white border border-grey-200 cursor-pointer flex rounded-md shrink-0 peer-checked:hidden peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-800",
            {
              "h-5 w-5": size === "sm",
              "h-6 w-6": size === "md",
              "h-5 w-5 md:h-6 md:w-6": size === undefined,
            }
          )}
          htmlFor={id}
        >
          <span />
        </label>
        <label
          className={cn(
            "bg-blue-800 border border-grey-200 cursor-pointer hidden items-center justify-center rounded-md shrink-0 text-mono-white peer-checked:flex peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-800",
            {
              "h-5 w-5": size === "sm",
              "h-6 w-6": size === "md",
              "h-5 w-5 md:h-6 md:w-6": size === undefined,
            }
          )}
          htmlFor={id}
        >
          <CheckIcon className="child h-4 w-4" />
        </label>
        <Label className="ml-2" htmlFor={id} {...labelProps}>
          {label}
        </Label>
      </div>
      {register != null && <ErrorText name={name} />}
    </div>
  )
}
