import { FunctionComponent, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { useFormContext } from "react-hook-form"
import { isNil } from "lodash"
import { toast } from "sonner"

import { AppointmentConfirmation } from "features/therapy/components/AppointmentConfirmation"
import { AppointmentFormSection } from "features/therapy/components/AppointmentFormSection"
import { AppointmentTimeSelection } from "features/therapy/components/AppointmentTimeSelection"
import { Step } from "features/therapy/types"
import {
  AppointmentReschedulePageFormSectionsQueryFieldsFragment,
  BookableAppointmentType,
  AppointmentReschedulePageFormSectionsRescheduleAppointmentMutation as MutationData,
  AppointmentReschedulePageFormSectionsRescheduleAppointmentMutationVariables as MutationVars,
} from "types/graphql"

import { FormValues } from "../AppointmentBookingPage"

export const fragments = {
  queryFields: gql`
    fragment AppointmentReschedulePageFormSectionsQueryFields on Query {
      appointment(id: $id) {
        id
        appointmentType
        counsellor {
          id
        }
      }
    }
  `,
}

export const mutations = {
  rescheduleAppointment: gql`
    mutation AppointmentReschedulePageFormSectionsRescheduleAppointment(
      $id: ID!
      $counsellorId: ID!
      $startsAt: String!
    ) {
      rescheduleAppointment(
        id: $id
        counsellorId: $counsellorId
        startsAt: $startsAt
      ) {
        id
        appointmentType
        startsAt
        status
        counsellor {
          id
          calendarId
          firstName
          videoCallUrl
        }
      }
    }
  `,
}

interface FormSectionsProps {
  data?: AppointmentReschedulePageFormSectionsQueryFieldsFragment
}

export const FormSections: FunctionComponent<FormSectionsProps> = props => {
  const { resetField, setValue } = useFormContext<FormValues>()

  const [openStepIndex, setOpenStepIndex] = useState(0)

  const [rescheduleAppointment, { data: rescheduleAppointmentData }] =
    useMutation<MutationData, MutationVars>(mutations.rescheduleAppointment, {
      onCompleted: data => {
        setValue("appointment", data.rescheduleAppointment)
        setOpenStepIndex(1)
      },
      onError: () => {
        resetField("startTime")
        toast.error("Appointment could not be rescheduled. Please try again.")
      },
    })

  const appointment = props.data?.appointment

  const steps: Step[] = [
    {
      title: "Select a time",
      isComplete: !!rescheduleAppointmentData,
      component:
        appointment?.appointmentType != null ? (
          <AppointmentTimeSelection
            appointmentType={
              appointment.appointmentType as unknown as BookableAppointmentType
            }
            onSelect={startTime => {
              if (!isNil(appointment)) {
                void rescheduleAppointment({
                  variables: {
                    id: appointment.id,
                    counsellorId: appointment.counsellor.id,
                    startsAt: startTime,
                  },
                })
              }
            }}
          />
        ) : (
          <></>
        ),
    },
    {
      title: "Booking confirmation",
      isComplete: false,
      component: <AppointmentConfirmation />,
    },
  ]

  return (
    <>
      {steps.map((step, index) => (
        <AppointmentFormSection
          key={step.title}
          index={index}
          openStepIndex={openStepIndex}
          setOpenStepIndex={setOpenStepIndex}
          steps={steps}
        />
      ))}
    </>
  )
}
