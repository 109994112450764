/**
 * Allows the user to set their session cap settings.
 */

import { H4, P, InputStepper, cn, Tooltip, Button } from "@spillchat/puddles"
import { FunctionComponent, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { z } from "zod"
import { useSearchParams } from "react-router-dom"
import { format } from "date-fns"

import { SpillSubscriptionPlan } from "types/graphql"

import { BillingFormValues } from "."

interface SessionCapSettingsProps {
  recommendedYearlyBudget?: {
    lowerBound?: number
    upperBound?: number
    spillRecommendation?: number
    therapyBudgetResetDate?: string | null
  }
  yearlyTherapyBudget?:
    | {
        active?: boolean | undefined
        value?: number | null | undefined
      }
    | null
    | undefined
  minimumBudgetLimit: number
  subscriptionPlan?: SpillSubscriptionPlan
  lockedBudget?: boolean
}

export const SessionCapSettings: FunctionComponent<
  SessionCapSettingsProps
> = props => {
  const [displayCapWarning, setDisplayCapWarning] = useState(false)
  const [hintCapInput, setHintCapInput] = useState(false)
  const { setValue, register, watch, setError, clearErrors, getValues } =
    useFormContext<BillingFormValues>()
  const watchedTherapyBudget = watch("yearlyTherapyBudget")
  const [searchParams] = useSearchParams()

  /**
   * Ensure that the values that we provide via props/API are valid, and
   * if they are not, we don't render the component. We also transform
   * the upper/lower/suggested bounds to string.
   */
  const safeRecommendedYearlyBudgetSchema = z.object({
    lowerBound: z.number().transform(value => value.toString()),
    upperBound: z.number().transform(value => value.toString()),
    spillRecommendation: z.number().transform(value => value.toString()),
    therapyBudgetResetDate: z.string(),
  })

  const minimumBudgetLimit = Math.ceil(props.minimumBudgetLimit)
  /**
   * The minimum should be no less than the minimum session cap limit.
   * When the user sets the value to be less than the minimum, we set
   * the value to be the minimum. We also show an info message.
   */
  useEffect(() => {
    if (parseInt(watchedTherapyBudget) < minimumBudgetLimit) {
      setDisplayCapWarning(true)
      setError("yearlyTherapyBudget", {
        message: `The minimum session cap is ${minimumBudgetLimit} (what's been used so far)`,
      })
    } else {
      setDisplayCapWarning(false)
      clearErrors("yearlyTherapyBudget")
    }
  }, [watchedTherapyBudget])

  /**
   * UI related schema. Not essential for the component to work, but
   * it's nice to have it.
   */
  const UISchema = z.object({
    yearlyTherapyBudget: z.object({
      active: z.boolean(),
      value: z.number().transform(value => value.toString()),
    }),
  })

  const budget = safeRecommendedYearlyBudgetSchema.safeParse(
    props.recommendedYearlyBudget
  )

  const uiDetail = UISchema.safeParse({
    yearlyTherapyBudget: props.yearlyTherapyBudget,
  })

  const requiresUpgrade = props.yearlyTherapyBudget?.active === false
  const lockedBudget = props.lockedBudget === true

  // On render, set the input to be the default value
  useEffect(() => {
    const setDefaultCapLimit = () => {
      if (uiDetail.success) {
        setValue("yearlyTherapyBudget", uiDetail.data.yearlyTherapyBudget.value)
      }
    }
    setDefaultCapLimit()
  }, [])

  /**
   * We want to hint the user that they can use the session cap input
   * So when we have the searchParams with the uiHint=sessionCap, we
   * set the hintCapInput to be true. Then set it to false after 3000ms.
   */
  useEffect(() => {
    if (searchParams.get("uiHint") === "sessionCap") {
      setHintCapInput(true)
      if (getValues("yearlyTherapyBudget") === "") {
        setValue("yearlyTherapyBudget", "0", {
          shouldDirty: true,
        })
      }
      setTimeout(() => {
        setHintCapInput(false)
      }, 3000)
    }
  }, [])

  return (
    props.subscriptionPlan !== SpillSubscriptionPlan.ESSENTIAL && (
      <div
        className={cn("flex flex-col gap-4", {
          "animate-pulse": hintCapInput,
        })}
      >
        <H4 sectionHeader>
          Set maximum annual sessions for the company{" "}
          {requiresUpgrade && "(Team Plan Only)"}
        </H4>

        <div className="flex flex-col gap-4">
          {/* Let's not show the details about the min/max if the values don't exist  */}
          {!budget.success ? null : (
            <P>
              The maximum number of sessions across the company, per year. Read
              more{" "}
              <a
                className="underline underline-offset-1"
                href="https://spill.notion.site/Managing-therapy-spend-on-Spill-a16c4fdf074f4aa6b124354976081c56"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              . It resets on{" "}
              {format(
                new Date(budget.data.therapyBudgetResetDate),
                "d MMM yyyy"
              )}
              .
            </P>
          )}

          {/* The input field */}
          <div className="flex flex-col gap-2">
            <div className="max-w-[220px]">
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger type="button">
                    <InputStepper
                      {...register("yearlyTherapyBudget")}
                      value={watchedTherapyBudget}
                      disabled={requiresUpgrade || lockedBudget}
                      onBlur={() => {
                        if (watchedTherapyBudget === "") {
                          setValue("yearlyTherapyBudget", "0", {
                            shouldDirty: true,
                          })
                        }
                      }}
                      invalid={displayCapWarning}
                      onStepperChange={inputValue => {
                        setValue("yearlyTherapyBudget", inputValue, {
                          shouldDirty: true,
                        })
                      }}
                    />
                  </Tooltip.Trigger>
                  {requiresUpgrade && !lockedBudget && (
                    <Tooltip.Content>Upgrade to access</Tooltip.Content>
                  )}
                </Tooltip.Root>
              </Tooltip.Provider>
            </div>

            {displayCapWarning && (
              <div>
                <div className="flex flex-col gap-2">
                  <P weight="medium">
                    <span className="text-red-600">
                      The minimum session cap is {minimumBudgetLimit} (what's
                      been used so far)
                    </span>
                  </P>
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      setValue(
                        "yearlyTherapyBudget",
                        minimumBudgetLimit.toString(),
                        {
                          shouldDirty: true,
                        }
                      )
                      setDisplayCapWarning(false)
                    }}
                    tabIndex={0}
                    role="button"
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        setValue(
                          "yearlyTherapyBudget",
                          minimumBudgetLimit.toString(),
                          {
                            shouldDirty: true,
                          }
                        )
                        setDisplayCapWarning(false)
                      }
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}
