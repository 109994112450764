import { FunctionComponent, useEffect, Fragment } from "react"
import { useFormContext, useController } from "react-hook-form"
import { useBoolean, useMap } from "react-use"
import { titleize } from "inflection"
import { pick } from "lodash"
import { P } from "@spillchat/puddles"

import { cn } from "common/helpers/cn"
import { useElementBreakpoint } from "common/hooks/useElementBreakpoint"
import { useAnalytics } from "common/context/analyticsContext"
import { ErrorText } from "common/components/FormElements/ErrorText"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"

import { FormSection } from "./FormSection"

type Props = {
  idx: number
  question: string
}

export const FeelingsFrequencySection: FunctionComponent<Props> = ({
  idx,
  question,
}) => {
  const [ref, , breakpoint] = useElementBreakpoint<HTMLUListElement, 600>([600])

  const { control, watch, register } = useFormContext<FormValues>()

  // Feelings selected in previous section
  const selectedFeelingsString =
    watch(`baselineQuestions.${idx - 1}.answer`) ?? ""

  const selectedFeelings =
    selectedFeelingsString == "" ? [] : selectedFeelingsString.split(" ")

  // Local feelings state
  const [feelings, { set, setAll }] = useMap<Record<string, string>>()

  // React Hook Form feelings controller
  const {
    field: { onChange, name },
    fieldState: { isDirty },
  } = useController<FormValues>({
    control,
    name: `baselineQuestions.${idx}.answer`,
    rules: {
      validate: value => {
        return (
          (value as string).split("\n").length == selectedFeelings.length ||
          "Must select a frequency"
        )
      },
    },
  })

  // Match feelings rendered in freqency section
  // with feelings selected in previous section
  useEffect(() => {
    setAll(pick(feelings, selectedFeelings))
  }, [])

  // Update form values when feelings change
  useEffect(() => {
    onChange(
      Object.entries(feelings)
        .map(([feeling, frequency]) => `${feeling} - ${frequency}`)
        .join(`\n`)
    )
  }, [onChange, feelings])

  // Track first change to feelings frequency
  const { track } = useAnalytics()
  const [hasTracked, toggleHasTracked] = useBoolean(false)
  useEffect(() => {
    if (!hasTracked && isDirty) {
      track(
        "User enters baseline question",
        { Field: "Feelings frequency" },
        toggleHasTracked
      )
    }
  }, [hasTracked, isDirty])

  return (
    <FormSection title={`${idx}. ${question}*`}>
      <ul
        className={cn("gap-[inherit] grid place-items-center", {
          "grid-cols-2": breakpoint === 0,
          "grid-cols-5": breakpoint === 600,
        })}
        ref={ref}
      >
        <input
          hidden
          value={"baseline"}
          {...register(`baselineQuestions.${idx}.section`)}
        />
        <input
          hidden
          value={question}
          {...register(`baselineQuestions.${idx}.question`)}
        />
        {(selectedFeelings.length ? selectedFeelings : ["-"]).map(feeling => (
          <Fragment key={feeling}>
            <span
              className={cn("capitalize", {
                "col-span-2": breakpoint === 0,
              })}
            >
              <P>{feeling}</P>
            </span>
            {["Rarely", "Sometimes", "Often", "Always"].map(frequency => (
              <div className="h-10 w-full" key={frequency}>
                <input
                  checked={feelings[feeling] === frequency}
                  className="peer"
                  disabled={feeling === "-"}
                  hidden
                  id={`${feeling}${frequency}`}
                  onChange={() => set(feeling, frequency)}
                  type="radio"
                  value={frequency}
                />
                <label
                  className="border border-blue-800 cursor-pointer flex h-full items-center justify-center rounded w-full peer-checked:bg-blue-800 peer-checked:text-mono-white peer-disabled:bg-grey-200 peer-disabled:border-0 peer-disabled:cursor-not-allowed peer-disabled:text-grey-600"
                  htmlFor={`${feeling}${frequency}`}
                >
                  <P>{titleize(frequency)}</P>
                </label>
              </div>
            ))}
          </Fragment>
        ))}
      </ul>

      <ErrorText name={name} />
    </FormSection>
  )
}
