import { BookingCard, cn } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { add, formatDistance, parseISO, differenceInMinutes } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"

import { getSessionLength } from "common/helpers/getSessionLength"
import { createAppointmentCalendarURLs } from "common/helpers/createAppointmentCalendarURLs"
import { ModifyAppointmentDialog } from "features/therapy/components/ModifyAppointmentDialog"
import { AppointmentStatus, AppointmentTypeFeature } from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"
import { useApp } from "common/context/appContext"

/**
 * A wrapper around the Puddles BookingCard component.
 */
export const SessionCard: FunctionComponent<{
  appointment: {
    id: string
    startsAt: string
    status: AppointmentStatus
    appointmentType?: AppointmentTypeFeature | null
    roomUrl?: string | null | undefined
    counsellor: { firstName: string; videoCallUrl?: string | null | undefined }
    title: string
  }
  isUpcoming?: boolean
}> = ({ appointment, isUpcoming }) => {
  const { appTimeZone } = useApp()
  const [cancellingSession, setCancellingSession] = useState(false)
  const { track } = useAnalytics()

  const startsAtDate = parseISO(appointment.startsAt)
  const minutesUntilAppointment = differenceInMinutes(startsAtDate, new Date())
  const { googleCalendarURL, iCalendarURL } = createAppointmentCalendarURLs(
    appointment,
    appTimeZone
  )

  // Whether video happens in our app (embeded) or directly on Whereby
  const appointmentLocationUrl = `/therapy/sessions/${appointment.id}/join`

  const privateAppointments = [
    AppointmentTypeFeature.PRIVATE_THERAPY_SESSION.toString(),
    AppointmentTypeFeature.PRIVATE_COURSE_SESSION_25_MINUTES.toString(),
  ]

  return (
    <>
      <BookingCard
        counsellorName={appointment.counsellor.firstName}
        title={formatInTimeZone(
          new Date(appointment.startsAt),
          appTimeZone,
          "d MMMM yyyy',' h:mmaaa"
        )}
        bookingType={{
          children: appointment.title,
          variant: privateAppointments.includes(
            appointment.appointmentType?.toString() ?? ""
          )
            ? // We just want it to be yellow, it's not an actual warning
              "warning"
            : "info",
        }}
        bookingDetails={{
          date: formatInTimeZone(
            new Date(appointment.startsAt),
            appTimeZone,
            "d MMMM yyyy"
          ),
          time:
            appointment.appointmentType != null
              ? `${getSessionLength(
                  appointment.appointmentType
                )} minutes, ${formatInTimeZone(
                  new Date(appointment.startsAt),
                  appTimeZone,
                  "h:mma"
                )} to ${formatInTimeZone(
                  add(new Date(appointment.startsAt), {
                    minutes: getSessionLength(appointment.appointmentType),
                  }),
                  appTimeZone,
                  "h:mma"
                )}`
              : "-",
          timeZone: appTimeZone,
          bookingMedium: "video",
        }}
        bookingStatus={
          isUpcoming == null
            ? {
                variant:
                  appointment.status === AppointmentStatus.CONFIRMED
                    ? "ok"
                    : "issue",
                children:
                  appointment.status === AppointmentStatus.CONFIRMED
                    ? "Session Held"
                    : appointment.status === AppointmentStatus.NOSHOW
                      ? "No Show"
                      : "Cancelled",
              }
            : undefined
        }
        actionButtons={
          isUpcoming === true
            ? [
                {
                  id: "join_session",
                  variant: "primary",
                  asChild: true,
                  children: (
                    <a
                      onClick={() => {
                        track("User Clicked Join Therapy Session", {
                          "Appointment ID": appointment.id,
                          Location: appointmentLocationUrl,
                        })
                      }}
                      href={appointmentLocationUrl}
                      rel="noreferrer"
                      target="_blank"
                      className={cn({
                        "opacity-20 pointer-events-none ":
                          minutesUntilAppointment > 3,
                      })}
                    >
                      Join
                      {minutesUntilAppointment > 0
                        ? ` in ${formatDistance(new Date(), startsAtDate)}`
                        : " now"}
                    </a>
                  ),
                  disabled: minutesUntilAppointment > 3,
                },
                {
                  id: "cancel_session",
                  variant: "tertiary",
                  children: "Cancel session",
                  onClick: () => setCancellingSession(true),
                },
                {
                  id: "reschedule_session",
                  variant: "tertiary",
                  asChild: true,
                  children: (
                    <a href={`/therapy/sessions/${appointment.id}/reschedule`}>
                      Reschedule session
                    </a>
                  ),
                },
                {
                  id: "add_to_calendar",
                  variant: "tertiary",
                  asChild: true,
                  children: (
                    <a
                      href={googleCalendarURL}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Add to Google calendar
                    </a>
                  ),
                  onClick: () =>
                    track("User clicked add to calendar", {
                      Calendar: "Google",
                    }),
                },
                {
                  id: "add_to_other_calendar",
                  variant: "tertiary",
                  children: (
                    <a href={iCalendarURL} download="spill_session.ics">
                      Add to iCal/Outlook
                    </a>
                  ),
                  asChild: true,
                  onClick: () =>
                    track("User clicked add to calendar", { Calendar: "iCal" }),
                },
              ]
            : [
                { id: "invisible" }, // dummy button that tricks puddles into rendering the button at all
                {
                  id: "provide_feedback",
                  variant: "tertiary",
                  asChild: true,
                  children: (
                    <a href={`/feedback/session/${appointment.id}`}>
                      Provide feedback
                    </a>
                  ),
                },
              ]
        }
      />
      <ModifyAppointmentDialog
        appointment={appointment}
        isDialogOpen={cancellingSession}
        onDialogClose={() => setCancellingSession(false)}
      />
    </>
  )
}
