import { Button, H2, H3, H4, P } from "@spillchat/puddles"
import { Link } from "react-router-dom"

export const FeedbackFormSuccess = (): JSX.Element => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <H2>Thank you - you're helping make therapy more accessible for all</H2>
        <H4>
          We're on a mission to rid the world of unnecessary emotional pain, and
          talking about therapy helps make it less intimidating. Take a look at
          how others have benefitted from therapy, and add your own note if
          you'd like.
        </H4>
      </div>
      <div className="grid lg:grid-cols-2 gap-3 lg:gap-6">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3 border rounded-lg bg-mono-white p-3">
            <H3>💙</H3>
            <P size="xs">
              Speaking from personal experience I think therapy is a great way
              to get perspective on life in general. I recommend it even to
              people who think they have nothing to talk about just as a way of
              expressing to a neutral 3rd party. I think we should look after
              our mental health like we look after our bodies by exercising;
              just because you're fit doesn't mean you stop going to the gym.
            </P>
            <P size="xs" weight="medium">
              &mdash;Charlotte
            </P>
          </div>
          <div className="flex flex-col gap-3 border rounded-lg bg-mono-white p-3">
            <H3>💙</H3>
            <P size="xs">
              “Therapy" is a scary word, but it's really just a chat with
              someone who's both impartial and also trained to help you figure
              things out. And it's really helpful to talk to someone who's not
              attached to any issues you have, and is just there to listen and
              help. Give it a go.
            </P>
            <P size="xs" weight="medium">
              &mdash;Spill user
            </P>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3 border rounded-lg bg-mono-white p-3">
            <H3>💙</H3>
            <P size="xs">
              Everyone has the need and right to be listened. Sharing thoughts
              and experiences with someone helps you getting them off your
              chest. Doing it was the best thing that I ever did for myself.
            </P>
            <P size="xs" weight="medium">
              &mdash;G
            </P>
          </div>
          <div className="flex flex-col gap-3 border rounded-lg bg-mono-white p-3">
            <H3>💙</H3>
            <P size="xs">
              It isn't as scary as you think, and it's not just for people with
              something really bad going on. Anyone can benefit from talking
              through what's on your mind. The therapists are excellent at
              helping you understand your feelings in a non-patronising,
              friendly way. It feels like you're just chatting about your
              feelings to a friend who's really good with advice
            </P>
            <P size="xs" weight="medium">
              &mdash;Billy
            </P>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-3">
        <Button asChild size="sm">
          <Link
            target="_blank"
            to="https://spill.notion.site/Should-I-do-therapy-0c870fe2b2bc4e238160ba3ae55b5eba"
          >
            See more
          </Link>
        </Button>
        <Button asChild size="sm" variant="secondary">
          <Link
            target="_blank"
            to="https://www.google.com/search?q=spill+trustpilot"
          >
            Leave a Trustpilot review
          </Link>
        </Button>
      </div>
    </div>
  )
}
