import { FunctionComponent, useRef } from "react"
import { Path, UseFormRegister, useFormContext } from "react-hook-form"
import { useIntersection } from "react-use"
import { parseISO } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { Button } from "@spillchat/puddles"

import { useAnalytics } from "common/context/analyticsContext"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"

interface AppointmentSlotProps {
  isLast: boolean
  name: Path<FormValues>
  onClickScrollToTop: () => void
  onSelect: () => void
  openStartTime: string
  register: UseFormRegister<FormValues>
  setOpenStartTime: (startTime: string) => void
  startTime: string
  timeZone: string
}

export const AppointmentSlot: FunctionComponent<
  AppointmentSlotProps
> = props => {
  const { setValue } = useFormContext<FormValues>()
  const { track } = useAnalytics()
  const intersectionRef = useRef<HTMLDetailsElement>(null)

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "-42px 0px 0px 0px",
    threshold: 1,
  })

  const isOutOfView =
    intersection &&
    intersection?.boundingClientRect.top < intersection?.intersectionRect.top

  return (
    <>
      <div>
        <input
          className="peer"
          hidden
          id={props.startTime}
          type="radio"
          value={props.startTime}
          {...props.register(props.name)}
        />
        <details
          className="border border-grey-200 cursor-pointer flex flex-col group h-12 items-center justify-center overflow-hidden rounded-md transition-all open:h-20 peer-checked:bg-blue-200"
          open={props.openStartTime === props.startTime}
          ref={props.isLast ? intersectionRef : undefined}
        >
          <summary
            className="leading-8 list-none p-2 text-center group-open:pb-0"
            onClick={e => {
              e.preventDefault()
              props.setOpenStartTime(props.startTime)
            }}
          >
            {formatInTimeZone(
              parseISO(props.startTime),
              props.timeZone,
              "h:mmaaa"
            )}
          </summary>
          <div className="px-2 transition-[height] w-full group-open:h-8 flex justify-center">
            <Button
              type="button"
              onClick={() => {
                setValue("startTime", props.startTime)
                track("User selects time of session", {
                  "Start time": props.startTime,
                })
                props.onSelect()
              }}
              size="sm"
            >
              Continue
            </Button>
          </div>
        </details>
      </div>
      {props.isLast && isOutOfView === true && (
        <Button
          onClick={props.onClickScrollToTop}
          size="sm"
          variant="secondary"
          type="button"
        >
          Scroll up to see times
        </Button>
      )}
    </>
  )
}
