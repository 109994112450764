export const SPILL_EXTERNAL_LINKS = {
  BOOK_TALK_OR_WORKSHOP: {
    link: "https://spillchat.typeform.com/to/tCvE4WdE",
    name: "Book a talk or workshop",
  },
  BOUNDARIES: {
    link: "https://spill.notion.site/Boundaries-b612e43619874fd2bbd7b9ab1764f14c",
    name: "Boundaries",
  },
  FAQ: {
    link: "https://spill.notion.site/Spill-User-FAQs-398e5ef1589d43c5a0e39c646364c780",
    name: "FAQs",
  },
  HOLIDAYS: {
    link: "https://spill.notion.site/Holidays-Spill-1bee62071401423db4a0250ad3ba8233",
    name: "Holidays at Spill",
  },
  MENTAL_HEALTH_TALKS: {
    link: "https://spill.notion.site/Mental-health-talks-for-Spill-users-188824c40c0e466da04d019711002bcb",
    name: "Mental health talks",
  },
  NEWSLETTER_SIGNUP: {
    link: "https://spillchat.typeform.com/to/GOF3kC6E",
    name: "Spill newsletter",
  },
  PRIVACY_POLICY: {
    link: "https://www.spill.chat/legals/privacy-notice",
    name: "Privacy Policy",
  },
  PULSE_DEMO: {
    link: "https://spill.notion.site/All-about-Check-ins-55fe4c0b2c874fbdbbaab3a8c43e6911",
    name: "Check-in demo",
  },
  ROADMAP: {
    link: "https://spill.notion.site/c4e09c0dee5e4a2182c7d3311e12a852?v=fbb963aa64fa4b94ab023133eb80de77",
    name: "roadmap",
  },
  T_AND_C: {
    link: "https://spill.notion.site/Spill-Platform-T-Cs-2021-504b0cb3a7f24a08872cdc25b146addd",
    name: "Platform T&Cs",
  },
  USER_GUIDE_PROPER: {
    link: "https://spill.notion.site/Spill-User-Guide-b5a12f96f52f4b14be0e1536ba9271cf",
    name: "User Guide Proper",
  },
}
