import { P } from "@spillchat/puddles"
import { FunctionComponent, useEffect } from "react"
import { useHits } from "react-instantsearch"

import { AdviceLibraryResult } from "./AdviceLibraryResult"

type SearchResultHitsProps = {
  showingResults: boolean
  setShowingResults: (value: boolean) => void
}

export type AdviceHit = {
  id: string
  aatId: string
  question: string
  answer: string
  tags: string[]
  counsellor_id: string
  answered_at: string
  answered_at_timestamp: number
  ready: string
  objectID: string
}

export const SearchResultHits: FunctionComponent<SearchResultHitsProps> = ({
  showingResults,
  setShowingResults,
}) => {
  const { results } = useHits<AdviceHit>()
  const query = results?.query

  const isQueryLongerThan2Chars = query !== undefined && query.length > 2

  // If there is already a query in the URL, immediately show results.
  useEffect(() => {
    if (isQueryLongerThan2Chars && !showingResults) {
      setShowingResults(true)
    }
  }, [])

  if (results?.hits.length === 0) {
    return (
      <div className="max-w-prose w-full flex flex-col gap-6 text-left">
        <P>{`No results for "${query}"`}</P>
      </div>
    )
  }

  const top3Results = results?.hits

  return (
    <ol className="flex flex-col divide-y max-w-prose w-full">
      {top3Results?.map(hit => (
        <AdviceLibraryResult key={hit.objectID} hit={hit} />
      ))}
    </ol>
  )
}
