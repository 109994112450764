import { useMemo } from "react"
import { Navigate } from "react-router-dom"

import type { FunctionComponent, PropsWithChildren } from "react"

interface ConditionalRedirectProps extends PropsWithChildren {
  /**
   * A condition, or an array of conditions to check.
   * If the condition, or any of the conditions are met and truthy, the redirect will be triggered.
   */
  conditions: boolean | boolean[]
  /**
   * The path to redirect to.
   * If the path is relative, it will be appended to the current path.
   * If the path is absolute, it will be used as-is.
   */
  to: string
  /**
   * In certain cases, we might need to ignore the redirect. Using the `unless` prop,
   * we can make sure that the redirect doesn't happen even if the condition are met.
   */
  unless?: boolean
}

/**
 * A component that redirects to a given path if a condition is met.
 */
export const ConditionalRedirect: FunctionComponent<
  ConditionalRedirectProps
> = props => {
  const isConditionMet = useMemo(
    () =>
      [props.conditions].flat().some(Boolean) &&
      (props.unless === undefined || !props.unless),
    [props.conditions]
  )

  if (isConditionMet) return <Navigate replace to={props.to} />

  return <>{props.children}</>
}
