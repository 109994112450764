import { gql } from "@apollo/client"

export const SESSION_PACK_FIELDS = gql`
  fragment SessionPackFields on Company {
    id
    featuresAndSettings {
      sessionPack {
        active
        value
      }
    }
    companySessionPack {
      sessionPackSize
      upcomingSessionPackSize
    }
    currentSessionPackUsage
  }
`
