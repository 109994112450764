import { gql } from "@apollo/client"

export const queries = {
  getUser: gql`
    query InviteEmailGetUser {
      user {
        primaryEmail
        company {
          platforms {
            id
            platformType
          }
        }
      }
    }
  `,
}
