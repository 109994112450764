import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { isEmpty } from "lodash"
import { useFormContext } from "react-hook-form"
import { useMap } from "react-use"
import { v4 as uuid } from "uuid"
import { Button, H3, H4, P } from "@spillchat/puddles"
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  addDays,
  compareAsc,
  differenceInCalendarISOWeeks,
  eachDayOfInterval,
  endOfDay,
  endOfHour,
  format,
  formatISO,
  isAfter,
  isBefore,
  max,
  parseISO,
  startOfToday,
  subDays,
} from "date-fns"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"

import { cn } from "common/helpers/cn"
import { useElementBreakpoint } from "common/hooks/useElementBreakpoint"
import { useAnalytics } from "common/context/analyticsContext"
import { useApp } from "common/context/appContext"
import { transformFiltersForGQL } from "features/therapy/helpers/transformFiltersForGQL"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import {
  BookableAppointmentType,
  AppointmentTimeSelectionGetAvailableAppointmentSlotsQuery as GetAvailableAppointmentSlotsData,
  AppointmentTimeSelectionGetAvailableAppointmentSlotsQueryVariables as GetAvailableAppointmentSlotsVars,
  AppointmentTimeSelectionGetNextDayWithAvailabilityQuery as GetNextDayWithAvailabilityData,
  AppointmentTimeSelectionGetNextDayWithAvailabilityQueryVariables as GetNextDayWithAvailabilityVars,
} from "types/graphql"
import { useUser } from "common/context/userContext"

import { AppointmentSlot } from "./AppointmentSlot"
import { Filters } from "./Filters"
import { TimeZoneSelect } from "./TimeZoneSelect"

export const fragments = {
  getAvailableAppointmentSlotsQueryFields: gql`
    fragment AppointmentTimeSelectionGetAvailableAppointmentSlotsQueryFields on Query {
      availableAppointmentSlots(
        appointmentType: $appointmentType
        startDate: $startDate
        endDate: $endDate
        filter: $filter
      ) {
        startTime
      }
    }
  `,
  getNextDayWithAvailabilityQueryFields: gql`
    fragment AppointmentTimeSelectionGetNextDayWithAvailabilityQueryFields on Query {
      nextDayWithAvailability(
        appointmentType: $appointmentType
        filter: $filter
      )
    }
  `,
}

export const queries = {
  getAvailableAppointmentSlots: gql`
    query AppointmentTimeSelectionGetAvailableAppointmentSlots(
      $appointmentType: BookableAppointmentType!
      $startDate: DateTime!
      $endDate: DateTime!
      $filter: AvailableAppointmentSlotsFilter
    ) {
      ...AppointmentTimeSelectionGetAvailableAppointmentSlotsQueryFields
    }
    ${fragments.getAvailableAppointmentSlotsQueryFields}
  `,
  getNextDayWithAvailability: gql`
    query AppointmentTimeSelectionGetNextDayWithAvailability(
      $appointmentType: BookableAppointmentType!
      $filter: AvailableAppointmentSlotsFilter
    ) {
      ...AppointmentTimeSelectionGetNextDayWithAvailabilityQueryFields
    }
    ${fragments.getNextDayWithAvailabilityQueryFields}
  `,
}

export interface AppointmentTimeSelectionProps {
  allowFilters?: boolean
  appointmentType: BookableAppointmentType
  onSelect?: (startTime: string) => void
  timeZone?: string
}

export const AppointmentTimeSelection: FunctionComponent<
  AppointmentTimeSelectionProps
> = ({ allowFilters = false, ...props }) => {
  const { appTimeZone } = useApp()
  const { track } = useAnalytics()
  const user = useUser()
  const [wrapperRef, , breakpoint] = useElementBreakpoint<
    HTMLDivElement,
    380 | 640 | 900
  >([380, 640, 900])

  const daysAhead = useMemo(() => {
    switch (breakpoint) {
      case 380:
        return 2
      case 640:
        return 4
      case 900:
        return 6
      default:
        return 0
    }
  }, [breakpoint])

  const { register, setValue, watch } = useFormContext<FormValues>()
  const filter = watch("filter") ?? {}
  const filterVariable = transformFiltersForGQL(filter)

  const [startDate, setStartDate] = useState(startOfToday())
  const endDate = useMemo(
    () => endOfDay(addDays(startDate, daysAhead)),
    [daysAhead, startDate]
  )
  const [openStartTime, setOpenStartTime] = useState("")

  const {
    data: getNextDayWithAvailabilityData,
    loading: getNextDayWithAvailabilityLoading,
  } = useQuery<GetNextDayWithAvailabilityData, GetNextDayWithAvailabilityVars>(
    queries.getNextDayWithAvailability,
    {
      fetchPolicy: "network-only",
      onCompleted: data => {
        if (typeof data.nextDayWithAvailability !== "string") return
        if (isAfter(parseISO(data.nextDayWithAvailability), startDate)) {
          setStartDate(parseISO(data.nextDayWithAvailability))
        }
      },
      variables: {
        appointmentType: props.appointmentType,
        filter: filterVariable,
      },
    }
  )

  const [getAvailableAppointmentSlots] = useLazyQuery<
    GetAvailableAppointmentSlotsData,
    GetAvailableAppointmentSlotsVars
  >(queries.getAvailableAppointmentSlots, { fetchPolicy: "network-only" })

  const nextDayWithAvailability =
    getNextDayWithAvailabilityData?.nextDayWithAvailability

  const [, appointmentSlotsByDay] = useMap<
    Record<string, string[] | undefined>
  >({})

  // Fetch available appointment slots for each day in the date range in series
  const fetchAvailableAppointments = async () => {
    const batchId = uuid() // Used to link individual day requests to requests for all visible days
    // Fetch available appointment slots for each day in visible date range
    await Promise.all(
      eachDayOfInterval({ start: startDate, end: endDate }).map(async day => {
        const requestEndDate = endOfDay(day)

        const responseStartDate = new Date() // Track response time for monitoring
        const { data } = await getAvailableAppointmentSlots({
          variables: {
            appointmentType: props.appointmentType,
            endDate: formatISO(requestEndDate),
            filter: filterVariable,
            // If the request start date is in the past, request from the current hour
            startDate: formatISO(max([day, endOfHour(new Date())])),
          },
        })
        const responseEndDate = new Date() // Track response time for monitoring
        if (data) {
          // Track response time, filters, and results count
          track("User sees available appointment slots for day", {
            batchId,
            endDate: formatISO(requestEndDate),
            filters: filterVariable,
            responseTime:
              responseEndDate.getTime() - responseStartDate.getTime(),
            resultsCount: data.availableAppointmentSlots.length,
            startDate: formatISO(day),
          })
          const sortedSlots = data.availableAppointmentSlots
            .map(slot => new Date(slot.startTime))
            .sort(compareAsc)

          // Add available appointment slots to local state
          appointmentSlotsByDay.set(
            formatISO(day, { representation: "date" }),
            sortedSlots.map(slot => slot.toISOString())
          )
        }
      })
    )
  }

  // Fetch available appointment slots when date range changes
  useEffect(() => {
    if (typeof nextDayWithAvailability !== "string") return
    void fetchAvailableAppointments()
  }, [formatISO(startDate ?? 0), daysAhead])

  // Clear previously fetched appointment slots and fetch new ones
  // when appointment type or filters change
  useEffect(() => {
    if (typeof nextDayWithAvailability !== "string") return
    setOpenStartTime("")
    appointmentSlotsByDay.setAll({})
    void fetchAvailableAppointments()
  }, [props.appointmentType, JSON.stringify(filter), nextDayWithAvailability])

  return (
    <div className="space-y-4 lg:space-y-8" ref={wrapperRef}>
      <div className="space-y-2 lg:space-y-4">
        {allowFilters && <Filters appointmentType={props.appointmentType} />}

        <div className="w-full flex justify-end pt-4">
          <div className="w-full md:w-1/5">
            <TimeZoneSelect />
          </div>
        </div>
      </div>

      {nextDayWithAvailability === null ? (
        <div className="flex flex-col gap-6 items-center text-center">
          <H3>Sorry! We couldn't find the perfect match.</H3>
          <P>
            We don’t currently have availability for your selected preferences,
            but our therapists are usually able to make extra time upon request.
          </P>
          <P>
            Just fill in{" "}
            <a
              className="underline"
              href={`https://spillchat.typeform.com/to/rml3m0eO#user_id=${user.id ?? ""}&session_type=${props.appointmentType}&location=booking_page`}
              rel="noreferrer"
              target="_blank"
            >
              this form
            </a>{" "}
            letting us know what you're looking for and we can get you booked
            in.
          </P>
          {isEmpty(filter.counsellorIds) && (
            <Button
              onClick={() => {
                setValue("filter", {
                  counsellorIds: [],
                  gender: null,
                  language: null,
                  previousCounsellorId: null,
                  specialism: null,
                  lifeEvent: filter.lifeEvent,
                  pmiOnly: false,
                })
              }}
            >
              Clear filters
            </Button>
          )}
        </div>
      ) : getNextDayWithAvailabilityLoading ? null : (
        <div
          className="grid relative"
          style={{ gridTemplateColumns: `repeat(${daysAhead + 1}, 1fr)` }}
        >
          <DateRangeNav
            daysAhead={daysAhead}
            endDate={endDate}
            nextDayWithAvailability={nextDayWithAvailability}
            setStartDate={setStartDate}
            startDate={startDate}
          />

          {eachDayOfInterval({ start: startDate, end: endDate }).map(
            (day, index) => {
              const appointmentsSlots = appointmentSlotsByDay.get(
                formatISO(day, { representation: "date" })
              )

              return (
                <div
                  key={day.toISOString()}
                  className="bg-spill-white rounded-t-md flex flex-col"
                >
                  {/* Header cell */}
                  <div
                    className={cn(
                      "bg-spill-grey-100 border-b border-grey-200 flex flex-col font-bold font-display h-24 items-center justify-center md:top-0 shrink-0 sticky top-16 z-10",
                      {
                        "rounded-tl-lg": index === 0,
                        "rounded-tr-lg": index === daysAhead,
                        "border-r": index < daysAhead,
                        "text-grey-600": isEmpty(appointmentsSlots),
                      }
                    )}
                  >
                    <P>{format(day, "E")}</P>
                    <H4>
                      {day.getDate()} {format(day, "MMM")}
                    </H4>
                  </div>

                  {/* Available slots column */}
                  <div
                    className={cn(
                      "border-grey-200 flex flex-col gap-4 h-full p-4 relative",
                      { "border-r": index < daysAhead }
                    )}
                  >
                    {appointmentsSlots === undefined ? (
                      <p className="flex h-12 items-center justify-center text-grey-600 text-sm">
                        Loading...
                      </p>
                    ) : appointmentsSlots.length === 0 ? (
                      <p className="flex h-12 items-center justify-center text-grey-600 text-sm">
                        No availability
                      </p>
                    ) : (
                      appointmentsSlots.map((startTime, index) => (
                        <AppointmentSlot
                          key={startTime}
                          isLast={index === appointmentsSlots.length - 1}
                          name="startTime"
                          onClickScrollToTop={() =>
                            window.scrollTo({
                              behavior: "smooth",
                              top: wrapperRef.current?.offsetTop ?? 0,
                            })
                          }
                          onSelect={() => {
                            props.onSelect?.(startTime)
                            setOpenStartTime("")
                          }}
                          openStartTime={openStartTime}
                          register={register}
                          setOpenStartTime={setOpenStartTime}
                          startTime={startTime}
                          timeZone={appTimeZone}
                        />
                      ))
                    )}
                  </div>
                </div>
              )
            }
          )}
        </div>
      )}
    </div>
  )
}

interface DateRangeNavProps {
  daysAhead: number
  endDate: Date
  nextDayWithAvailability: string | null | undefined
  setStartDate: Dispatch<SetStateAction<Date>>
  startDate: Date
}

const DateRangeNav: FunctionComponent<DateRangeNavProps> = props => (
  <div className="flex gap-[inherit] grow items-start py-4 justify-between min-w-[240px] px-4 overflow-hidden rounded-md absolute z-20 w-full h-24">
    <Button
      disabled={
        typeof props.nextDayWithAvailability !== "string" ||
        isBefore(
          props.startDate,
          addDays(parseISO(props.nextDayWithAvailability), 1)
        )
      }
      onClick={() => {
        props.setStartDate(prevStartDate => {
          return max([
            subDays(prevStartDate, props.daysAhead + 1),
            typeof props.nextDayWithAvailability === "string"
              ? parseISO(props.nextDayWithAvailability)
              : startOfToday(),
          ])
        })
      }}
      asChild
    >
      <button className="!p-1">
        <ArrowLeftIcon className="size-4" />
      </button>
    </Button>

    <Button
      disabled={
        typeof props.nextDayWithAvailability !== "string" ||
        differenceInCalendarISOWeeks(
          props.endDate,
          parseISO(props.nextDayWithAvailability)
        ) >= 6
      }
      onClick={() => {
        props.setStartDate(prevStartDate => {
          return addDays(prevStartDate, props.daysAhead + 1)
        })
      }}
      asChild
    >
      <button className="!p-1">
        <ArrowRightIcon className="size-4" />
      </button>
    </Button>
  </div>
)
