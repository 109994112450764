interface CreateAddToGoogleCalendarURLArgs {
  description?: string
  location?: string
  startsAt: Date
  timeZone?: string
  title: string
  duration: number
}

// Format date in 'YYYYMMDDTHHMMSSZ', otherwise Google Calendar on iPhone doesn't recognize the date
const formatToGoogleCalendarDate = (date: Date): string => {
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, "0") // Months are 0-based
  const day = String(date.getUTCDate()).padStart(2, "0")
  const hours = String(date.getUTCHours()).padStart(2, "0")
  const minutes = String(date.getUTCMinutes()).padStart(2, "0")
  const seconds = String(date.getUTCSeconds()).padStart(2, "0")
  return `${year}${month}${day}T${hours}${minutes}${seconds}Z`
}

export const createAddToGoogleCalendarURL = ({
  description,
  location,
  startsAt,
  timeZone,
  title,
  duration,
}: CreateAddToGoogleCalendarURLArgs): string => {
  const url = new URL(`https://www.google.com/calendar/render`)

  // Convert local time to UTC
  const localDate = new Date(startsAt.getTime())
  const utcStart = new Date(localDate.toISOString())
  const utcEnd = new Date(utcStart.getTime() + duration * 60 * 1000)

  // Format times
  const startISO = formatToGoogleCalendarDate(utcStart)
  const endISO = formatToGoogleCalendarDate(utcEnd)

  // See https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
  const searchParams = new URLSearchParams({
    action: "TEMPLATE",
    crm: "BUSY",
    dates: `${startISO}/${endISO}`,
    text: title,
    trp: "true",
  })

  if (location !== undefined) searchParams.set("location", location)
  if (description !== undefined) searchParams.set("details", description)
  if (timeZone !== undefined) searchParams.set("ctz", timeZone)

  url.search = searchParams.toString()

  return url.toString()
}
