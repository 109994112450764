import { FunctionComponent, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { Button, DropdownMenu, P } from "@spillchat/puddles"
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline"

import {
  UserInvitesListUpdateInvitesMutation as MutationData,
  UserInvitesListUpdateInvitesMutationVariables as MutationVariables,
} from "types/graphql"
import { Dialog } from "common/components/Dialog"

import { SupportTableRow } from "./SupportTable"

type SupportTableActionProps = {
  invites: SupportTableRow
  total: number
}

const mutations = {
  updateUserInvites: gql`
    mutation UserInvitesListUpdateInvites($deletion: ID!) {
      deactivateUser(targetUserId: $deletion) {
        id
      }
      removeInvite(userId: $deletion)
    }
  `,
}

export const SupportTableAction: FunctionComponent<SupportTableActionProps> = (
  props: SupportTableActionProps
) => {
  const [updateUserInvites] = useMutation<MutationData, MutationVariables>(
    mutations.updateUserInvites
  )
  const [deletingUser, setDeletingUser] = useState("")

  return (
    <>
      <Dialog
        title="Delete invite?"
        isOpen={deletingUser !== ""}
        maxWidth="md"
        buttons={[
          {
            children: "Delete invite",
            onClick: async () => {
              await updateUserInvites({
                variables: { deletion: deletingUser },
                refetchQueries: ["UserInvitesListUser"],
              }).then(() => {
                setDeletingUser("")
              })
            },
          },
          {
            children: "Cancel",
            onClick: () => setDeletingUser(""),
            variant: "secondary",
          },
        ]}
      >
        <P>
          Are you sure you want to delete this invite? This action cannot be
          undone.
        </P>
      </Dialog>
      <div className="text-right">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <Button variant="tertiary" size="sm">
              <EllipsisHorizontalIcon className="size-4" />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">
            <div>
              <DropdownMenu.Item
                onClick={() => setDeletingUser(props.invites.userId)}
              >
                Delete invite
              </DropdownMenu.Item>
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </>
  )
}
