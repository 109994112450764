import { gql } from "@apollo/client"
import { FunctionComponent } from "react"
import { useFormContext } from "react-hook-form"
import { Checkbox } from "@spillchat/puddles"

import { SelectProps } from "common/components/FormElements/Select"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { PmiSelectQueryFieldsFragment } from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment PmiSelectQueryFields on User {
      company {
        pmi {
          provider
        }
      }
    }
  `,
}

interface PmiSelectProps
  extends Omit<SelectProps<FormValues>, "options" | "register"> {
  data?: PmiSelectQueryFieldsFragment
}

export const PmiSelect: FunctionComponent<PmiSelectProps> = ({ data }) => {
  const { setValue, getValues } = useFormContext<FormValues>()

  const onPmiToggle = () => {
    setValue("filter.previousCounsellorId", null)
    setValue("filter.pmiOnly", getValues("filter.pmiOnly") != true)
  }
  return (
    <Checkbox
      name="filter.pmiOnly"
      value="true"
      checked={getValues("filter.pmiOnly") ?? false}
      onClick={onPmiToggle}
      label={{
        children:
          data?.company?.pmi?.provider ?? "Employer's medical insurance",
      }}
    />
  )
}
