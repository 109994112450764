/**
 * The billing details page. Allows an admin to setup their billing details,
 * or view and manage their details
 */

import { gql, useQuery } from "@apollo/client"
import { FunctionComponent } from "react"
import { H1 } from "@spillchat/puddles"
import { SESSION_PACK_FIELDS } from "graphql/fragments/session-pack.fragment"

import { BillingGetCompanyBillingDetailsQuery } from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"

import { PlanSettings } from "./components/PlanSettings"

const queries = {
  getCompanyBillingDetails: gql`
    query BillingGetCompanyBillingDetails {
      user {
        id
        company {
          id
          billableUserCount
          subscriptionPlan
          subscriptionStatus
          isLegacyPricing
          trialStartDate
          usesStripe
          ...SessionPackFields
        }
      }
    }
    ${SESSION_PACK_FIELDS}
  `,
}

export const BillingDetails: FunctionComponent = () => {
  const { data, loading } = useQuery<BillingGetCompanyBillingDetailsQuery>(
    queries.getCompanyBillingDetails,
    { fetchPolicy: "cache-first" }
  )

  if (loading) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const isLegacyPricing = data?.user?.company?.isLegacyPricing

  const { trialStartDate } = data?.user?.company ?? {}

  const sessionPackCount =
    data?.user?.company?.featuresAndSettings.sessionPack.value
  const hasTrial = sessionPackCount != null && trialStartDate !== null

  return (
    <>
      <H1>Billing</H1>

      <PlanSettings
        billableUserCount={data?.user?.company?.billableUserCount}
        subscriptionPlan={data?.user?.company?.subscriptionPlan}
        subscriptionStatus={data?.user?.company?.subscriptionStatus}
        numberInSessionPack={
          data?.user?.company?.featuresAndSettings?.sessionPack?.value ?? null
        }
        upcomingSessionPackSize={
          data?.user?.company?.companySessionPack?.upcomingSessionPackSize
        }
        usesStripe={data?.user?.company?.usesStripe ?? false}
        {...{ isLegacyPricing, hasTrial }}
      />
    </>
  )
}
