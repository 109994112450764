import { gql, useQuery } from "@apollo/client"
import { capitalize } from "inflection"
import { useIntl } from "react-intl"
import cn from "classnames"

import { Tooltip } from "common/components/Tooltip"

import { FEELINGS } from "../constants/emotions"

import type {
  CurrentTeamFeelingsChartGetMeetingQuery as QueryData,
  CurrentTeamFeelingsChartGetMeetingQueryVariables as QueryVariables,
} from "types/graphql"

const fragments = {
  meetingFields: gql`
    fragment CurrentTeamFeelingsChartMeetingFields on Meeting {
      urlId
      currentVersionNumber
      sharedPulseResponses {
        feelings
        name
        userId
      }
    }
  `,
}

const queries = {
  GET_MEETING: gql`
    query CurrentTeamFeelingsChartGetMeeting($meetingUrlId: ID!) {
      meeting(urlId: $meetingUrlId) {
        ...CurrentTeamFeelingsChartMeetingFields
      }
    }
    ${fragments.meetingFields}
  `,
}

type CurrentTeamFeelingsChartProps = {
  meetingUrlId: string
}

export function CurrentTeamFeelingsChart({
  meetingUrlId,
}: CurrentTeamFeelingsChartProps): JSX.Element | null {
  const intl = useIntl()

  const { data } = useQuery<QueryData, QueryVariables>(queries.GET_MEETING, {
    variables: { meetingUrlId },
  })

  if (!data) return null

  const responsesByFeeling =
    (data?.meeting?.sharedPulseResponses ?? []).reduce<{
      [key: string]: string[]
    }>((prev, { feelings, name }) => {
      feelings.forEach(feeling =>
        prev[feeling] ? prev[feeling]?.push(name) : (prev[feeling] = [name])
      )
      return prev
    }, {}) ?? {}

  const version = data.meeting!.currentVersionNumber

  return (
    <div
      className={cn(
        "box h-full flex justify-between select-none gap-sm w-full",
        {
          "md:gap-md lg:gap-lg": version !== 5,
        }
      )}
    >
      {Object.values(FEELINGS).map(({ adjective, noun, src }) => {
        const responses = responsesByFeeling[noun] ?? []

        const feelingTooltipText = responses.length
          ? `${intl.formatList(responses)} ${
              responses.length > 1 ? "feel" : "feels"
            } ${adjective}`
          : capitalize(noun)

        return (
          <div key={noun} className="flex flex-col justify-end">
            <div className="flex flex-col">
              {responses.map((name, index) => {
                const initial = name.charAt(0).toUpperCase()

                let color = "teal-600"

                switch (initial) {
                  case "G":
                  case "H":
                  case "I":
                  case "J":
                  case "K":
                  case "L":
                    color = "blue-800"
                    break
                  case "M":
                  case "N":
                  case "O":
                  case "P":
                  case "Q":
                  case "R":
                    color = "red-400"
                    break
                  case "S":
                  case "T":
                  case "U":
                  case "V":
                  case "W":
                  case "X":
                  case "Y":
                  case "Z":
                    color = "yellow-400"
                    break
                }

                return (
                  <div
                    key={`${name}${index}`}
                    className="h-2 sm:h-4 overflow-visible relative"
                  >
                    <Tooltip id="user-initial" content={name}>
                      <div
                        className={cn(
                          `aspect-square bg-${color} border-[1px] border-white flex font-bold items-center justify-center rounded-full text-mono-white absolute bottom-0 w-full`,
                          {
                            "md: border-[2px] lg:border-[3px] text-xxs md:text-sm lg:text-lg":
                              version === 5,
                            "md:border-[3px] text-sm sm:text-xl": version !== 5,
                          }
                        )}
                      >
                        {initial}
                      </div>
                    </Tooltip>
                  </div>
                )
              })}
            </div>

            <Tooltip id="feeling-emoji" content={feelingTooltipText}>
              <img
                alt="Feeling emoji"
                className="h-auto relative-child-width"
                src={src}
              />
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}

CurrentTeamFeelingsChart.fragments = fragments
CurrentTeamFeelingsChart.queries = queries
