import { gql } from "@apollo/client"
import { SESSION_PACK_FIELDS } from "graphql/fragments/session-pack.fragment"

export const queries = {
  getUsage: gql`
    query ChangeSessionPackGetUsage {
      user {
        id
        company {
          maximumAdditionalRollOverSessions
          ...SessionPackFields
        }
      }
    }
    ${SESSION_PACK_FIELDS}
  `,
}
