import { developmentConfig } from "./development"
import { productionConfig } from "./production"
import { stagingConfig } from "./staging"

export const environment =
  (import.meta.env["VITE_ENVIRONMENT"] as string) ?? "development"

const gatewayBaseUrl = import.meta.env["VITE_GATEWAY_BASE_URL"] as string
const slackClientId = import.meta.env["VITE_SLACK_APP_CLIENT_ID"] as string
const slackAppId = import.meta.env["VITE_SLACK_APP_ID"] as string
const stripeKey = import.meta.env["VITE_STRIPE_KEY"] as string
const webAppBaseUrl = import.meta.env["VITE_WEB_APP_BASE_URL"] as string
const impersonateEnabled =
  (import.meta.env["VITE_IMPERSONATE_ENABLED"] as string) === "true"

export type Config = {
  acuity: {
    isTrialFieldId: string
    ownerId: string
    userExternalIdFieldId: string
    userIdFieldId: string
    workspaceExternalIdFieldId: string
  }
  acuity3: {
    appointmentTypeIds: {
      teamPlanSingle: string
      teamPlanSeries: string
    }
    ownerId: string
    therapySessionAcuityAppointmentTypeId: string
    fieldIds: {
      spillUserId: string
      pronouns: string
      approximateLocation: string
      emergencyContactName: string
      emergencyContactNumber: string
    }
  }
  adviceLibrary: {
    appId: string
    readKey: string
    searchIndexName: string
    searchSuggetionsIndexName: string
  }
  stripe: {
    key: string
  }
  cookiebot: {
    id: string
  }
  environment: string
  mixpanel: {
    apiHost: string
    token: string
    sendEvents: boolean
  }
  s3: {
    baseUrl: string
  }
  sentry: {
    dsn?: string
  }
  spill: {
    gateway: {
      baseUrl: string
    }
    slackApp: {
      appId: string
      clientId: string
    }
    webApp: {
      baseUrl: string
    }
  }
  superTokens: {
    enableDebugLogs: boolean
  }
  google: {
    clientId: string
    analyticsTrackingId: string
  }
  unleash: {
    clientKey: string
  }
  productFruits: { workspaceCode: string }
  impersonateEnabled: boolean
}

// This holds any default values or values that are the same across all environments
const baseConfig = {
  acuity: {
    isTrialFieldId: "10168084",
    ownerId: "23547353",
    userExternalIdFieldId: "10097000",
    userIdFieldId: "10097001",
    workspaceExternalIdFieldId: "10096999",
  },
  acuity3: {
    appointmentTypeIds: {
      teamPlanSeries: "46090564",
      teamPlanSingle: "46090594",
    },
    ownerId: "23547353",
    therapySessionAcuityAppointmentTypeId: "41289382",
    fieldIds: {
      spillUserId: "10097001",
      pronouns: "12862617",
      approximateLocation: "12874357",
      emergencyContactName: "12874358",
      emergencyContactNumber: "12874374",
    },
  },
  adviceLibrary: {
    // Algolia
    appId: "SXHQW50MCI",
    readKey: "6828c62a52700f5af3553f0dc9c16c8a",
    searchIndexName: "dev_library",
    searchSuggetionsIndexName: "dev_library_query_suggestions",
  },
  stripe: {
    key: stripeKey,
  },
  cookiebot: {
    id: "5fd68424-693a-48ce-a79b-f2f20ad19f89",
  },
  environment,
  s3: {
    baseUrl: "https://spill-public-assets.s3.eu-west-2.amazonaws.com",
  },
  sentry: {
    dsn: "https://e5a9c7d5b0d54aeab0ed9d6363e7a2d6@o192511.ingest.sentry.io/5227054",
  },
  spill: {
    gateway: {
      baseUrl: gatewayBaseUrl,
    },
    slackApp: {
      appId: slackAppId,
      clientId: slackClientId,
    },
    webApp: {
      baseUrl: webAppBaseUrl,
    },
  },
  superTokens: {
    enableDebugLogs: false,
  },
  productFruits: { workspaceCode: "9hbMgooWtIdEngQ2" },
  impersonateEnabled,
}

export const config: Config =
  environment === "production"
    ? { ...baseConfig, ...productionConfig }
    : environment === "staging"
      ? { ...baseConfig, ...stagingConfig }
      : { ...baseConfig, ...developmentConfig }
