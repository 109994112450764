import { FunctionComponent } from "react"

interface StepperComponentProps {
  currentStep: number
  steps: { id: number; name: string; hidden?: boolean }[]
}

export const StepperProgress: FunctionComponent<StepperComponentProps> = ({
  steps,
  currentStep,
}) => {
  return (
    <div className="flex flex-col w-full">
      <ol className="flex items-center justify-center w-full">
        {steps
          .filter(step => step.hidden == null || !step.hidden)
          .map(({ id, name }) => (
            <li
              key={`stepper${id}`}
              className={`w-1/5 flex flex-col items-center justify-center `}
            >
              <div className="flex flex-row justify-center items-center w-full">
                <div
                  className={`box-border h-1 border-b border-grey-200 w-1/2 ${
                    id === 1 ? "invisible" : ""
                  }`}
                ></div>
                <span
                  className={`flex transition-colors items-center justify-center w-8 h-8 text-lg lg:text-xl rounded-full lg:h-10 lg:w-10 shrink-0 border-2 border-blue-800 font-bold ${
                    id <= currentStep
                      ? "bg-blue-800 text-mono-white"
                      : "bg-mono-white text-blue-800"
                  }`}
                >
                  {id}
                </span>
                <div
                  className={`box-border h-1 border-b border-grey-200 w-1/2 ${
                    id === steps[steps.length - 1]?.id ? "invisible" : ""
                  }`}
                ></div>
              </div>
              <div className="mt-4">{name}</div>
            </li>
          ))}
      </ol>
    </div>
  )
}
