import type { BurnoutBucket } from "types/graphql"

export const ADVICE_MAP: Record<BurnoutBucket, string> = {
  LOW_RISK: `Burnout is a kind of emotional exhaustion caused by prolonged or mismanaged stress at work.

You're not at risk right now, but you can look out for your team by learning to <a href="https://www.spill.chat/burnout/burnout-symptoms">spot the symptoms of burnout in others</a>.

If you'd like to talk to someone about work (or non-work) related stress, <a href="https://app.spill.chat/therapy/book/one-off">book a Spill therapy session </a> or <a href="https://app.spill.chat/therapy/ask-a-therapist">send a message to a therapist</a>.`,

  MODERATE_RISK: `Burnout is a kind of emotional exhaustion caused by prolonged or mismanaged stress at work.

Read about the <a href="https://www.spill.chat/burnout/burnout-causes">six main causes of burnout at work</a>.

If you're feeling concerned about burnout, it's a good idea to talk to your manager, <a href="https://app.spill.chat/therapy/book/one-off">book a Spill therapy session </a> or <a href="https://app.spill.chat/therapy/ask-a-therapist">send a message to a therapist</a>.`,

  HIGH_RISK: `Burnout is a kind of emotional exhaustion caused by prolonged or mismanaged stress at work. Look after yourself by taking action now.

It's a good idea to talk to your manager. You could also <a href="https://app.spill.chat/therapy/book/one-off">book a Spill therapy session </a> or <a href="https://app.spill.chat/therapy/ask-a-therapist">send a message to a therapist</a>.

Check out Spill's burnout <a href="https://www.spill.chat/burnout/burnout-recovery-plan">recovery plan</a> for some tips to beat burnout.`,
  UNKNOWN_RISK: "",
}
