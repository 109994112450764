import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react"
import { useQuery } from "@apollo/client"
import { Navigate, Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import {
  AdminTherapyIndexGetUsageQuery,
  AdminTherapyIndexGetUsageQueryVariables,
  SpillSubscriptionPlan,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { UserInvitesList } from "features/admin/components/PeopleList/UserInvitesList"
import { UserInvitesEmpty } from "features/admin/components/PeopleList/UserInvitesEmpty"
import { useAdminOnboarding } from "common/hooks/useAdminOnboarding"
import { queries } from "features/admin-therapy/admin-therapy.queries"
import { AdminTherapySpecialisedSupport } from "features/admin-therapy-specialised-support/admin-therapy-specialised-support.page"
import { AdminTherapyNeeds } from "features/admin-therapy-needs/admin-therapy-needs.page"
import { AdminTherapyActivity } from "features/admin-therapy-activity/admin-therapy-activity.page"
import { AdminTherapyNavigation } from "features/admin-therapy/components/AdminTherapyNavigation"
import { AdminTherapySpecialisedSupportEdit } from "features/admin-therapy-specialised-support-edit/admin-therapy-specialised-support-edit.page"
import { AdminTherapySpecialisedSupportEnrol } from "features/admin-therapy-specialised-support-enrol/admin-therapy-specialised-support-enrol.page"
import { AdminTherapySpecialisedSupportGuide } from "features/admin-therapy-specialised-support-guide/admin-therapy-specialised-support-guide.page"
import { AdminTherapySpecialisedSupportReview } from "features/admin-therapy-specialised-support-review/admin-therapy-specialised-support-review.page"

import { AccountSettings } from "./Settings"

interface TherapyContextData {
  /**
   * We need to know how many sessions a company has in a few places.
   * So if we calculate it anywhere, we update the context value so we
   * can access it anywhere.
   */
  currentSessionCoverage: number
  setCurrentSessionCoverage: Dispatch<SetStateAction<number>>
  /**
   * Allows us to set the estimated monthly cost of the company.
   * We set this to default as a string, as we need to format it.
   */
  estimatedMonthlyCost: string
  setEstimatedMonthlyCost: Dispatch<SetStateAction<string>>
  /**
   * Allows us to set the individual unit price of a session.
   * We set this to default as a string, as we need to format it.
   */
  individualUnitPrice: string
  setIndividualUnitPrice: Dispatch<SetStateAction<string>>
}

const TherapyContext = createContext<TherapyContextData>({
  currentSessionCoverage: 0,
  estimatedMonthlyCost: "",
  individualUnitPrice: "",
  setCurrentSessionCoverage: () => {},
  setEstimatedMonthlyCost: () => {},
  setIndividualUnitPrice: () => {},
})

export const useTherapyContext = (): TherapyContextData =>
  useContext(TherapyContext)

export const Therapy: FunctionComponent = () => {
  const [currentSessionCoverage, setCurrentSessionCoverage] = useState(0)
  const [estimatedMonthlyCost, setEstimatedMonthlyCost] = useState("")
  const [individualUnitPrice, setIndividualUnitPrice] = useState("")

  const { data, loading } = useQuery<
    AdminTherapyIndexGetUsageQuery,
    AdminTherapyIndexGetUsageQueryVariables
  >(queries.getUsage)

  const subscriptionPlan = data?.user?.company?.subscriptionPlan
  const { helpers } = useAdminOnboarding({ subscriptionPlan })

  const isLegacyPricing = data?.user?.company?.isLegacyPricing
  const canPreApproveTherapy =
    data?.user?.company?.featuresAndSettings.preApproveTherapy.value ?? false

  const hasLifeEvents =
    data?.user?.company?.featuresAndSettings?.therapyPackage?.active === true
  const legacyPlans = [SpillSubscriptionPlan.PROPER, SpillSubscriptionPlan.LITE]

  const checkInviteAndIntegration = () => {
    if (subscriptionPlan == null) {
      return false
    }

    if (legacyPlans.includes(subscriptionPlan)) {
      return true
    }

    return [helpers?.hasInvitedUsers, helpers?.hasCompletedIntegration].some(
      step => step === true
    )
  }

  const hasInvitedOrIntegrated = checkInviteAndIntegration()

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const tabLinks = [
    {
      label: "Support",
      path: "support",
      visible: canPreApproveTherapy === true,
    },
    { label: "Activity", path: "activity", visible: true },
    {
      label: "Specialised support",
      path: "specialised-support",
      visible: hasLifeEvents === true,
    },
    {
      label: "Budget",
      path: "needs",
      visible: true,
    },
    { label: "Settings", path: "settings", visible: isLegacyPricing == false },
  ]

  const TabContainer = ({
    children,
    condition,
  }: {
    children: ReactNode
    condition: boolean
  }) => {
    return (
      <>
        {condition ? (
          <>{children}</>
        ) : (
          <>
            <div className="flex justify-center items-center py-12">
              {subscriptionPlan != null && (
                <UserInvitesEmpty {...{ subscriptionPlan }} />
              )}
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Helmet title="Therapy | Spill" />
      <TherapyContext.Provider
        value={{
          currentSessionCoverage,
          setCurrentSessionCoverage,
          estimatedMonthlyCost,
          setEstimatedMonthlyCost,
          individualUnitPrice,
          setIndividualUnitPrice,
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={canPreApproveTherapy === true ? "support" : "activity"}
              />
            }
          />
          <Route
            path="/specialised-support"
            element={
              <>
                <Helmet title="Specialised support | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <TabContainer condition={hasInvitedOrIntegrated}>
                  <AdminTherapySpecialisedSupport />
                </TabContainer>
              </>
            }
          />
          <Route
            path="/specialised-support/guide"
            element={
              <>
                <Helmet title="Specialised support guide | Spill" />
                <AdminTherapySpecialisedSupportGuide />
              </>
            }
          />
          <Route
            path="/specialised-support/:packageId"
            element={
              <>
                <Helmet title="Edit Specialised support | Spill" />
                <AdminTherapySpecialisedSupportEdit />
              </>
            }
          />
          <Route
            path="/specialised-support/:packageId/enrol"
            element={
              <>
                <Helmet title="Enrol employees | Spill" />
                <AdminTherapySpecialisedSupportEnrol />
              </>
            }
          />
          <Route
            path="/specialised-support/:packageId/request/:requestId"
            element={
              <>
                <Helmet title="Review request | Spill" />
                <AdminTherapySpecialisedSupportReview />
              </>
            }
          />
          <Route
            path="/support/*"
            element={
              <>
                <Helmet title="Support | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <TabContainer condition={hasInvitedOrIntegrated}>
                  <div className="flex flex-col sm:flex-row gap-9 mt-8">
                    <div className="overflow-y-auto w-full">
                      <UserInvitesList />
                    </div>
                  </div>
                </TabContainer>
              </>
            }
          />
          <Route
            path="/activity/*"
            element={
              <>
                <Helmet title="Activity | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <TabContainer condition={hasInvitedOrIntegrated}>
                  <AdminTherapyActivity />
                </TabContainer>
              </>
            }
          />
          <Route
            path="/needs/*"
            element={
              <>
                <Helmet title="Needs | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                <AdminTherapyNeeds />
              </>
            }
          />
          <Route
            path="/settings/*"
            element={
              <>
                <Helmet title="Settings | Spill" />
                <AdminTherapyNavigation {...{ tabLinks }} />
                {isLegacyPricing == true && <Navigate to="activity" />}
                <AccountSettings />
              </>
            }
          />
        </Routes>
      </TherapyContext.Provider>
    </>
  )
}
