import { RefObject, useEffect, useMemo, useRef } from "react"
import { useMeasure } from "react-use"

/**
 * Return a breakpoint value based on the width of an element.
 */
export const useElementBreakpoint = <E extends Element, V extends number>(
  config: number[]
): [RefObject<E>, Record<0 | V, boolean>, 0 | V] => {
  const ref = useRef<E>(null)
  const [measureRef, wrapperRect] = useMeasure<E>()

  // Merge the two refs
  useEffect(() => {
    if (ref.current) measureRef(ref.current)
  }, [ref.current])

  const breakpoint = useMemo(() => {
    const maxBreakpoint = Math.max(
      0,
      ...config.filter(width => wrapperRect.width >= width)
    )
    return maxBreakpoint as 0 | V
  }, [wrapperRect.width])

  const breakpoints = Object.fromEntries(
    [0, ...config].map(width => [width, wrapperRect.width >= width])
  ) as Record<0 | V, boolean>

  return [ref, breakpoints, breakpoint]
}
