import { forwardRef, PropsWithChildren } from "react"
import { P } from "@spillchat/puddles"

import { ErrorText } from "common/components/FormElements/ErrorText"

interface FormSectionProps extends PropsWithChildren {
  name?: string
  subtitle?: string
  title: string
}

export const FormSection = forwardRef<
  HTMLTableSectionElement,
  FormSectionProps
>((props, ref) => (
  <section className="flex flex-col gap-4" ref={ref}>
    <header className="col-span-2 space-y-2">
      <P weight="medium">
        <strong>{props.title}</strong>
      </P>
      {props.subtitle !== undefined && <P muted>{props.subtitle}</P>}
    </header>
    {props.children}
    {props.name !== undefined && <ErrorText name={props.name} />}
  </section>
))
FormSection.displayName = "FormSection"
