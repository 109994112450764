import {
  ChangeEventHandler,
  ComponentProps,
  KeyboardEventHandler,
  forwardRef,
  useState,
} from "react"
import { XMarkIcon } from "@heroicons/react/24/outline"

import { cn } from "common/helpers/cn"

type TagInputProps = {
  autoFocus?: ComponentProps<"input">["autoFocus"]
  maxTagLength?: number
  maxTags?: number
  onChange: (tags: string[]) => void
  size?: "md" | "lg"
  tagColor?: "blue" | "yellow"
  tags: string[]
}

export const TagInput = forwardRef<HTMLInputElement, TagInputProps>(
  (
    {
      autoFocus = false,
      maxTagLength = 20,
      maxTags,
      onChange,
      size = "md",
      tagColor = "yellow",
      tags,
    },
    ref
  ) => {
    const [value, setValue] = useState("")

    const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
      // Prevent adding more than maxTags tags
      if (tags.length === maxTags) return setValue("")

      // Prevent typing more than maxTagLength characters
      if (target.value.trim().length > maxTagLength) return

      // Add tag when space is pressed
      if (target.value.trimStart().includes(" ")) {
        onChange(Array.from(new Set(tags).add(target.value.trim())))
        return setValue("")
      }

      const trimmedValue = target.value.replace(/[^a-zA-Z]/, "")
      setValue(trimmedValue.toLowerCase())
    }

    const handleKeyDown: KeyboardEventHandler = event => {
      // Remove tag on Backspace
      if (event.key === "Backspace" && value === "") {
        onChange(tags.slice(0, tags.length - 1))
      }
      // Add tag on Enter
      if (event.key === "Enter") {
        event.preventDefault()
        if (value.length) {
          onChange(Array.from(new Set(tags).add(value.trim())))
          setValue("")
        }
      }
    }

    /**
     * When the user clicks out of the input, we want to check if the value
     * that's in the input is taggable. If it is, we add it to the list of tags.
     * We also run it through the same checks as the `handleChange` function
     */
    const handleBlur: ComponentProps<"input">["onBlur"] = e => {
      if (e.target.value) {
        handleChange(e)
        if (value.length) {
          onChange(Array.from(new Set(tags).add(value.trim())))
          setValue("")
        }
      }
    }

    return (
      <div
        className={cn(
          "bg-mono-white border border-grey-200 flex flex-wrap gap-1 h-fit items-center rounded-md w-full",
          {
            "min-h-[40px] p-1": size === "md",
            "min-h-[48px] p-2": size === "lg",
          }
        )}
      >
        {tags.length > 0 && (
          <div
            className={cn("flex flex-wrap h-min", {
              "gap-1": size === "md",
              "gap-2": size === "lg",
            })}
          >
            {tags.map(tag => (
              <div
                key={tag}
                className={cn(
                  "flex font-semibold h-7 items-center lowercase overflow-hidden pl-2 pr-1.5 rounded select-none space-x-1",
                  {
                    "bg-blue-800 text-mono-white": tagColor === "blue",
                    "bg-yellow-400": tagColor === "yellow",
                  }
                )}
              >
                {/* 1px top margin helps text to look more centered */}
                <span className="mt-[1px] text-sm whitespace-pre">{tag}</span>
                <div
                  className={cn(
                    "aspect-square cursor-pointer flex items-center justify-center rounded-sm",
                    {
                      "hover:bg-blue-400": tagColor === "blue",
                      "hover:bg-yellow-400": tagColor === "yellow",
                    }
                  )}
                >
                  <XMarkIcon
                    onClick={() => {
                      onChange(tags.filter(t => t !== tag))
                    }}
                    className="h-4 w-4"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <input
          // We're ok with this autofocus
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          className="bg-transparent grow h-6 outline-none pl-1 z-0"
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onSubmit={event => event.preventDefault()}
          ref={ref}
          type="text"
          value={value}
        />
      </div>
    )
  }
)

TagInput.displayName = "TagInput"
