import { gql } from "@apollo/client"

export const PACKAGE_FIELDS = gql`
  fragment PackageFields on User {
    id
    featuresAndSettings {
      adhdSupport {
        value
      }
      customSupport {
        value
      }
    }
    therapyPackages {
      identifier
      expirationDate
      numberSessionsUsed
      numberSessionsGiven
    }
  }
`
