import classnames from "classnames"
import { twMerge } from "tailwind-merge"

export const cn = (...args: Parameters<typeof classnames>): string => {
  const lastParam = args.slice(-1)[0]

  if (typeof lastParam === "string") {
    return twMerge(classnames(...args.slice(0, -1)), lastParam)
  }

  return classnames(...args)
}
