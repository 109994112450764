import { CopyField } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { toast } from "sonner"
import { LinkIcon } from "@heroicons/react/24/outline"

import { SupportTableRow } from "./SupportTable"

type SupportTableLinkProps = {
  invite: SupportTableRow
  hasActiveSubscription: boolean
}

export const SupportTableLink: FunctionComponent<SupportTableLinkProps> = ({
  hasActiveSubscription,
  invite,
}: SupportTableLinkProps) => {
  return (
    <div className="flex items-center py-2">
      <CopyField
        disabled={!hasActiveSubscription}
        copyValue={invite.inviteUrl}
        onCopied={() => toast.success("Link copied!")}
        onFail={() => toast.error("Failed to copy link")}
        icon={<LinkIcon />}
        tableView
      />
    </div>
  )
}
