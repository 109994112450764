/**
 * Buyer Signup Page that allows the admin of a company to sign their company up for Spill 3.0+ access.
 */
import { Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { AnimatePresence, motion } from "framer-motion"
import { FunctionComponent, PropsWithChildren } from "react"
import { Carousel, CarouselItem } from "@spillchat/puddles"

import happyMobile from "common/assets/images/product/happy-mobile.jpeg"
import smilingLaptop from "common/assets/images/product/smiling-laptop.jpeg"
import subduedLaptop from "common/assets/images/product/subdued-laptop.jpeg"
import { LogoWordmark } from "common/components/logo/LogoWordmark"

import { SignUpForm } from "./components/SignUpForm"
import { OTPConfirmation } from "./components/OTPConfirmation"
import { PlusOneSignUpForm } from "./components/PlusOneSignUpForm"

import "./signup.css"

export interface SignupFormFieldValues {
  displayName: string
  email: string
  companyName: string
  hasAgreedToHealthData: boolean
  hasAgreedToTsAndCs: boolean
  hasConfirmedAtLeast18: boolean
}

const SignupTransition: FunctionComponent<PropsWithChildren> = props => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 10 }}
    transition={{ delayChildren: 0.5, bounceDamping: 10 }}
  >
    {props.children}
  </motion.div>
)

const items: CarouselItem[] = [
  {
    title: (
      <>
        &ldquo;Exactly what I needed. I think this service is essential to the
        wellbeing of people at this&nbsp;company.&rdquo;
      </>
    ),
    subtitle: "Employee at Moonpig",
    imageAlt: "A person looking happy on their mobile",
    image: happyMobile,
  },
  {
    title: (
      <>
        &ldquo;I feel a weight has been lifted off my shoulders after
        every&nbsp;session.&rdquo;
      </>
    ),
    subtitle: "Employee at Juro",
    imageAlt: "A smiling person using their laptop",
    image: smilingLaptop,
  },
  {
    title: (
      <>
        &ldquo;Having the ability to book a session when you&rsquo;re finding
        things difficult is really&nbsp;empowering.&rdquo;
      </>
    ),
    subtitle: "Employee at William Joseph",
    imageAlt: "A subdued person using their laptop",
    image: subduedLaptop,
  },
]

export const BuyerSignup: React.FunctionComponent = () => {
  return (
    <section className="h-screen">
      <Helmet title="Signup | Spill" />
      {/* Full width div with two columns */}
      <div className="grid grid-cols-1 lg:grid-cols-3 p-8 h-full">
        {/* Left column */}
        <div className="hidden lg:inline-flex col-span-2 order-2 lg:order-1 p-4">
          <Carousel items={items} autoPlay={true} ariaLabel="Spill perks" />
        </div>

        {/* Right column */}
        <div className="flex flex-col col-span-1 order-1 lg:order-2 justify-center p-4 gap-8 max-w-sm mx-auto lg:mx-0">
          <LogoWordmark />

          <AnimatePresence mode="wait">
            <Routes>
              <Route
                path="/"
                element={
                  <SignupTransition key="signUp">
                    <SignUpForm />
                  </SignupTransition>
                }
              />
              <Route
                path="/plus-one/:publicId"
                element={
                  <SignupTransition key="plusOne">
                    <PlusOneSignUpForm />
                  </SignupTransition>
                }
              />
              <Route
                path="/plus-one/:publicId"
                element={
                  <SignupTransition key="plusOne">
                    <PlusOneSignUpForm />
                  </SignupTransition>
                }
              />
              <Route
                path="/confirm"
                element={
                  <SignupTransition key="otpInput">
                    <OTPConfirmation />
                  </SignupTransition>
                }
              />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
    </section>
  )
}
