import { useState } from "react"
import { useInterval } from "react-use"

/**
 * Uses Document hasFocus method to determine whether user focus is on page.
 * Can be used to start/stop resource intensive tasks for performance.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/hasFocus}
 * @returns {boolean} True if the document has focus, false otherwise.
 */
export function useDocumentHasFocus(): boolean {
  const [documentHasFocus, setDocumentHasFocus] = useState(false)

  useInterval(() => {
    setDocumentHasFocus(document.hasFocus())
  }, 1000)

  return documentHasFocus
}
