/**
 * An animated version of the 'drizzle' icon that can be found as a static SVG in
 * /src/common/assets/images/moods/4.svg
 **/

import React from "react"
import { motion, useAnimation } from "framer-motion"

import { AnimatedIconProps } from "./types"

export const Drizzle: React.FunctionComponent<AnimatedIconProps> = props => {
  const drizzleAnimation = useAnimation()
  const drizzleAnimation2 = useAnimation()

  // Create an array of the animations, so we can orchestrate them nicer.
  const drizzleAnimations = [drizzleAnimation, drizzleAnimation2]

  React.useEffect(() => {
    if (props.isAnimating) {
      drizzleAnimation
        .start({
          y: 2,
          opacity: [1, 0, 1],
          transition: {
            repeat: Infinity,
            repeatType: "reverse",
            ease: "linear",
            duration: 1,
          },
        })
        .catch(() => {})
      drizzleAnimation2
        .start({
          y: 2,
          opacity: [0, 1, 0],
          transition: {
            repeat: Infinity,
            repeatType: "reverse",
            ease: "linear",
            duration: 1,
          },
        })
        .catch(() => {})
    } else {
      drizzleAnimations.forEach(animation => animation.stop())
    }
  }, [props.isAnimating])

  return (
    <motion.svg
      width="48"
      height="50"
      viewBox="0 0 48 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path d="M42.4333 21.8848C42.4736 21.553 42.4936 21.2191 42.4933 20.8848C42.4933 18.4979 41.5451 16.2087 39.8572 14.5209C38.1694 12.8331 35.8802 11.8848 33.4933 11.8848C32.0163 11.8846 30.5625 12.2523 29.2633 12.9548C27.8737 10.9414 25.8767 9.42505 23.5641 8.62718C21.2515 7.82931 18.7444 7.79174 16.4089 8.51997C14.0734 9.2482 12.032 10.7041 10.5827 12.6749C9.13341 14.6458 8.35226 17.0285 8.35329 19.4749C8.35314 20.1045 8.40329 20.7332 8.50329 21.3549C6.28873 21.4026 4.18383 22.3281 2.65166 23.9278C1.11949 25.5275 0.285533 27.6703 0.333272 29.8848C0.381011 32.0994 1.30652 34.2043 2.90621 35.7365C4.50589 37.2686 6.64871 38.1026 8.86327 38.0548H39.5033C41.4648 38.0618 43.3662 37.3779 44.874 36.1232C46.3817 34.8684 47.3996 33.1229 47.7492 31.1928C48.0987 29.2626 47.7576 27.271 46.7855 25.5672C45.8135 23.8634 44.2727 22.5562 42.4333 21.8748V21.8848ZM39.5033 35.5648H8.86327C8.07402 35.5975 7.28634 35.4702 6.54754 35.1907C5.80873 34.9112 5.13405 34.4851 4.56408 33.9382C3.9941 33.3913 3.54057 32.7348 3.23076 32.0082C2.92095 31.2816 2.76125 30.4998 2.76125 29.7099C2.76125 28.9199 2.92095 28.1381 3.23076 27.4115C3.54057 26.6849 3.9941 26.0284 4.56408 25.4815C5.13405 24.9346 5.80873 24.5086 6.54754 24.229C7.28634 23.9495 8.07402 23.8222 8.86327 23.8549H11.8033L11.2833 22.2249C10.9963 21.3399 10.8512 20.4152 10.8533 19.4849C10.8777 17.4946 11.5612 15.5686 12.7967 14.0082C14.0323 12.4478 15.7503 11.341 17.6819 10.8609C19.6136 10.3809 21.6498 10.5547 23.4722 11.3551C25.2945 12.1556 26.8 13.5376 27.7533 15.2849L28.4433 16.5748L29.6133 15.6948C30.7305 14.8506 32.0929 14.3941 33.4933 14.3949C35.2164 14.3975 36.8682 15.0832 38.0866 16.3016C39.305 17.52 39.9906 19.1718 39.9933 20.8948C39.991 21.425 39.9204 21.9527 39.7833 22.4649L39.4733 23.7249L40.7433 23.9948C42.1571 24.2979 43.4085 25.114 44.2562 26.2854C45.1039 27.4568 45.4877 28.9006 45.3336 30.3383C45.1796 31.776 44.4985 33.1056 43.4218 34.0708C42.3452 35.036 40.9492 35.5682 39.5033 35.5648Z" />
      <motion.path
        animate={drizzleAnimation}
        d="M17.843 43.7443C17.5079 43.7547 17.19 43.8952 16.9566 44.136C16.7233 44.3768 16.5929 44.699 16.593 45.0343C16.593 45.3659 16.7247 45.6838 16.9592 45.9182C17.1936 46.1526 17.5115 46.2843 17.843 46.2843C18.1746 46.2843 18.4925 46.1526 18.7269 45.9182C18.9613 45.6838 19.093 45.3659 19.093 45.0343V44.9643C19.0917 44.802 19.0583 44.6414 18.9947 44.492C18.931 44.3427 18.8384 44.2073 18.7222 44.0939C18.606 43.9805 18.4685 43.8912 18.3176 43.8312C18.1667 43.7712 18.0054 43.7417 17.843 43.7443Z"
      />
      <motion.path
        animate={drizzleAnimation}
        d="M11.513 40.6447C11.1787 40.6525 10.8608 40.7909 10.6272 41.0301C10.3936 41.2693 10.2629 41.5904 10.263 41.9247C10.263 42.2562 10.3946 42.5742 10.6291 42.8086C10.8635 43.043 11.1814 43.1747 11.513 43.1747C11.8445 43.1747 12.1624 43.043 12.3969 42.8086C12.6313 42.5742 12.763 42.2562 12.763 41.9247V41.8647C12.7617 41.7023 12.7282 41.5418 12.6646 41.3924C12.6009 41.243 12.5083 41.1077 12.3921 40.9943C12.2759 40.8809 12.1384 40.7916 11.9875 40.7316C11.8366 40.6716 11.6753 40.642 11.513 40.6447Z"
      />
      <motion.path
        animate={drizzleAnimation2}
        d="M11.513 46.8049C11.1787 46.8127 10.8608 46.951 10.6272 47.1902C10.3936 47.4294 10.2629 47.7505 10.263 48.0849C10.263 48.4164 10.3946 48.7344 10.6291 48.9688C10.8635 49.2032 11.1814 49.3349 11.513 49.3349C11.8445 49.3349 12.1624 49.2032 12.3969 48.9688C12.6313 48.7344 12.763 48.4164 12.763 48.0849V48.0249C12.7617 47.8625 12.7282 47.702 12.6646 47.5526C12.6009 47.4032 12.5083 47.2679 12.3921 47.1545C12.2759 47.041 12.1384 46.9518 11.9875 46.8918C11.8366 46.8318 11.6753 46.8022 11.513 46.8049Z"
      />
      <motion.path
        animate={drizzleAnimation}
        d="M30.513 43.7445C30.1778 43.7549 29.8599 43.8954 29.6265 44.1362C29.3932 44.377 29.2628 44.6992 29.263 45.0345C29.263 45.3661 29.3946 45.684 29.6291 45.9184C29.8635 46.1528 30.1814 46.2845 30.513 46.2845C30.8445 46.2845 31.1624 46.1528 31.3969 45.9184C31.6313 45.684 31.763 45.3661 31.763 45.0345V44.9645C31.763 44.8018 31.7305 44.6406 31.6673 44.4906C31.6041 44.3406 31.5116 44.2048 31.3951 44.0911C31.2786 43.9774 31.1405 43.8882 30.989 43.8287C30.8375 43.7692 30.6757 43.7405 30.513 43.7445Z"
      />
      <motion.path
        animate={drizzleAnimation2}
        d="M24.1829 40.6447C23.8486 40.6525 23.5307 40.7909 23.2971 41.0301C23.0635 41.2693 22.9328 41.5904 22.9329 41.9247C22.9329 42.2562 23.0646 42.5742 23.299 42.8086C23.5334 43.043 23.8514 43.1747 24.1829 43.1747C24.5144 43.1747 24.8324 43.043 25.0668 42.8086C25.3012 42.5742 25.4329 42.2562 25.4329 41.9247V41.8647C25.4316 41.7023 25.3982 41.5418 25.3345 41.3924C25.2709 41.243 25.1783 41.1077 25.062 40.9943C24.9458 40.8809 24.8083 40.7916 24.6574 40.7316C24.5065 40.6716 24.3453 40.642 24.1829 40.6447Z"
      />
      <motion.path
        animate={drizzleAnimation}
        d="M24.1829 46.8049C23.8486 46.8127 23.5307 46.951 23.2971 47.1902C23.0635 47.4294 22.9328 47.7505 22.9329 48.0849C22.9329 48.4164 23.0646 48.7344 23.299 48.9688C23.5334 49.2032 23.8514 49.3349 24.1829 49.3349C24.5144 49.3349 24.8324 49.2032 25.0668 48.9688C25.3012 48.7344 25.4329 48.4164 25.4329 48.0849V48.0249C25.4316 47.8625 25.3982 47.702 25.3345 47.5526C25.2709 47.4032 25.1783 47.2679 25.062 47.1545C24.9458 47.041 24.8083 46.9518 24.6574 46.8918C24.5065 46.8318 24.3453 46.8022 24.1829 46.8049Z"
      />
      <motion.path
        animate={drizzleAnimation2}
        d="M36.8533 40.6447C36.5191 40.6525 36.2011 40.7909 35.9675 41.0301C35.7339 41.2693 35.6032 41.5904 35.6033 41.9247C35.6033 42.2562 35.735 42.5742 35.9694 42.8086C36.2038 43.043 36.5218 43.1747 36.8533 43.1747C37.1848 43.1747 37.5028 43.043 37.7372 42.8086C37.9716 42.5742 38.1033 42.2562 38.1033 41.9247V41.8647C38.102 41.7023 38.0686 41.5418 38.0049 41.3924C37.9413 41.243 37.8487 41.1077 37.7325 40.9943C37.6162 40.8809 37.4787 40.7916 37.3278 40.7316C37.1769 40.6716 37.0157 40.642 36.8533 40.6447Z"
      />
      <motion.path
        animate={drizzleAnimation}
        d="M36.8533 46.8049C36.5191 46.8127 36.2011 46.951 35.9675 47.1902C35.7339 47.4294 35.6032 47.7505 35.6033 48.0849C35.6033 48.4164 35.735 48.7344 35.9694 48.9688C36.2038 49.2032 36.5218 49.3349 36.8533 49.3349C37.1848 49.3349 37.5028 49.2032 37.7372 48.9688C37.9716 48.7344 38.1033 48.4164 38.1033 48.0849V48.0249C38.102 47.8625 38.0686 47.702 38.0049 47.5526C37.9413 47.4032 37.8487 47.2679 37.7325 47.1545C37.6162 47.041 37.4787 46.9518 37.3278 46.8918C37.1769 46.8318 37.0157 46.8022 36.8533 46.8049Z"
      />
    </motion.svg>
  )
}
