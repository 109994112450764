import { addMonths, format } from "date-fns"

export function formatDate(date: Date): string {
  return format(date, "dd MMM y")
}

export function monthsToDate(numberOfMonths: number): Date {
  const date =
    numberOfMonths != null
      ? addMonths(new Date(), numberOfMonths).toISOString()
      : addMonths(new Date(), 6).toISOString()

  return new Date(date)
}
