import { useController, useFormContext } from "react-hook-form"
import { FunctionComponent, useEffect } from "react"
import { Entries } from "type-fest"
import { useBoolean, useMap } from "react-use"
import { P } from "@spillchat/puddles"

import { cn } from "common/helpers/cn"
import { useElementBreakpoint } from "common/hooks/useElementBreakpoint"
import { useAnalytics } from "common/context/analyticsContext"
import { Checkbox } from "common/components/FormElements/Checkbox"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"

import { FormSection } from "./FormSection"
import { TextInputCheckbox } from "./TextInputCheckbox"

type ReasonForBooking = "lifeEvent" | "mentalHealth" | "relationships" | "work"

type ReasonsForBookingData = Record<ReasonForBooking, string>

const reasonsForBookingData: ReasonsForBookingData = {
  lifeEvent: "I’ve had a major event in my life recently",
  mentalHealth: "I’m struggling with my mental health",
  relationships: "I’m struggling in one or more relationships",
  work: "I’m struggling with an issue at work",
}

type Props = {
  idx: number
  question: string
}

export const ReasonsForBookingSection: FunctionComponent<Props> = ({
  idx,
  question,
}) => {
  const [ref, , breakpoint] = useElementBreakpoint<
    HTMLTableSectionElement,
    660
  >([660])

  const { control, register } = useFormContext<FormValues>()

  // React Hook Form reasons for booking controller
  const {
    field: { onChange },
    fieldState: { isDirty },
  } = useController<FormValues>({
    control,
    name: `baselineQuestions.${idx}.answer`,
    rules: { required: "Required" },
  })

  // Local reasons for booking state
  const [
    reasonsForBooking,
    { remove: removeReasonForBooking, set: setReasonForBooking },
  ] = useMap<ReasonsForBookingData & { other: string }>()

  // Update form values when reasons for booking change
  useEffect(() => {
    onChange(Object.values(reasonsForBooking).join(", "))
  }, [onChange, reasonsForBooking])

  // Track first change to reasons for booking
  const { track } = useAnalytics()
  const [hasTracked, toggleHasTracked] = useBoolean(false)
  useEffect(() => {
    if (!hasTracked && isDirty) {
      track(
        "User enters baseline question",
        { Field: "Reasons for booking" },
        toggleHasTracked
      )
    }
  }, [hasTracked, isDirty])

  return (
    <FormSection
      name="baselineQuestions.reasonForBooking"
      ref={ref}
      subtitle="Pick as many as apply"
      title={`${idx}. ${question}*`}
    >
      <input
        hidden
        value={"baseline"}
        {...register(`baselineQuestions.${idx}.section`)}
      />
      <input
        hidden
        value={question}
        {...register(`baselineQuestions.${idx}.question`)}
      />
      <ul
        className={cn("grid gap-4", {
          "grid-cols-1": breakpoint === 0,
          "grid-cols-2": breakpoint === 660,
        })}
      >
        {(
          Object.entries(
            reasonsForBookingData
          ) as Entries<ReasonsForBookingData>
        ).map(([key, value]) => {
          if (value === null) return null
          return (
            <Checkbox
              key={key}
              label={<P>{value}</P>}
              name={`baselineQuestions.${idx}.answer.${key}`}
              onChange={e => {
                if (e.currentTarget.checked) setReasonForBooking(key, value)
                else removeReasonForBooking(key)
              }}
              value={key}
            />
          )
        })}
        <TextInputCheckbox
          label={<P>Other</P>}
          name="reasonForBooking"
          onChange={value => {
            if (value === "") removeReasonForBooking("other")
            else setReasonForBooking("other", value)
          }}
        />
      </ul>
    </FormSection>
  )
}
