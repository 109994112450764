import { useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  Form,
  H2,
  Indicator,
  Input,
  ModalFullScreenInner,
  P,
} from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { toast } from "sonner"

import {
  InviteEmailCreatePlatformUsersMutation,
  InviteEmailCreatePlatformUsersMutationVariables,
  CompanyOnboardingStep,
  InviteEmailGetUserQuery,
  PlatformType,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useOnboarding } from "common/context/onboardingContext"
import { useGoBack } from "common/hooks/useGoBack"

import { mutations } from "./invite-email.mutations"
import { queries } from "./invite-email.queries"
import { formSchema } from "./invite-email.schema"

import type { InviteEmailForm } from "./invite-email.schema"

export const InviteEmail: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const goBack = useGoBack()
  const { markAsComplete, stepsProgress } = useOnboarding()

  const { data, loading: userLoading } = useQuery<InviteEmailGetUserQuery>(
    queries.getUser,
    {
      fetchPolicy: "cache-first",
    }
  )

  const form = useForm<InviteEmailForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emailInput: "",
      emailList: [],
    },
  })

  form.watch("emailList")

  const [createPlatformUser, { loading: platformUserLoading }] = useMutation<
    InviteEmailCreatePlatformUsersMutation,
    InviteEmailCreatePlatformUsersMutationVariables
  >(mutations.createPlatformUsers)

  if (isUserLoading || userLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const handleRecipient = async () => {
    const valid = await form.trigger(["emailInput"], {
      shouldFocus: true,
    })

    const emailInput = form.getValues("emailInput")
    const emailList = form.getValues("emailList")

    if (valid) {
      if (emailList.includes(emailInput)) {
        toast.error("Email already added to batch")
        return
      }

      if (emailInput === "") {
        toast.error("Please provide an email")
        return
      }

      form.setValue("emailList", [...form.getValues("emailList"), emailInput])
      form.resetField("emailInput")
    }
  }

  const handleSubmit = async (values: InviteEmailForm) => {
    const platformId = data?.user?.company?.platforms.find(
      platform => platform.platformType === PlatformType.EMAIL
    )?.id

    if (platformId == null) {
      // No email platform - this is an error
      toast.error("Can't add emails to this company - contact hi@spill.chat")
      return
    }

    if (values.emailInput !== "") {
      form.setValue("emailList", [
        ...form.getValues("emailList"),
        values.emailInput,
      ])
      form.resetField("emailInput")
      return
    }

    const variables = {
      platformId,
      platformUsers: values.emailList.map(email => {
        return {
          externalId: email,
          name: email.split("@")[0] ?? email,
        }
      }),
    }

    const { data: platformUser } = await createPlatformUser({
      variables,
    })

    if (!stepsProgress.hasAddedTeam && markAsComplete) {
      await markAsComplete(CompanyOnboardingStep.ADDED_TEAM)
    }

    if (
      platformUser?.createPlatformUsers !== undefined &&
      platformUser.createPlatformUsers?.length > 0
    ) {
      toast.success(
        `Invited ${platformUser.createPlatformUsers.length} ${platformUser.createPlatformUsers.length === 1 ? "user" : "users"} successfully`
      )
    } else {
      toast.success(
        "Something went wrong. Please check the email address is correct"
      )
    }

    clearList()
    goBack()
  }

  const clearList = () => {
    form.setValue("emailList", [])
  }

  const removeFromList = (email: string) => {
    const newList = form.getValues("emailList").filter(item => item !== email)
    form.setValue("emailList", newList)
  }

  const isLoading = platformUserLoading

  return (
    <>
      <Helmet title="Invite via email | Spill" />

      <ModalFullScreenInner title="Invite via email" onClose={() => goBack()}>
        <div className="flex justify-center items-center px-6 overflow-scroll grow">
          <div className="max-w-lg mx-auto w-full">
            <Form.Root {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-4">
                    <H2>Invite team members via&nbsp;email</H2>
                    <P>
                      Add the email addresses of the employees you&apos;d like
                      to send Spill access to now. You can always invite more
                      people later.
                    </P>
                  </div>
                  <div className="flex flex-col gap-4">
                    <Form.Field
                      control={form.control}
                      name="emailInput"
                      render={({ field }) => (
                        <Form.Item>
                          <Form.Control>
                            <Input
                              placeholder="Type an email and press enter to add to this batch"
                              type="email"
                              {...field}
                              onKeyDown={async e => {
                                if (e.key === "Enter") {
                                  e.preventDefault()
                                  await handleRecipient()
                                }
                              }}
                              label={{
                                children: "Email addresses",
                              }}
                            />
                          </Form.Control>
                          <Form.Message />
                        </Form.Item>
                      )}
                    />
                    {form.getValues("emailList").length > 0 && (
                      <div className="flex flex-wrap gap-1">
                        {form.getValues("emailList").map(email => {
                          return (
                            <span
                              role="button"
                              tabIndex={0}
                              key={email}
                              className="inline-flex cursor-pointer rounded-lg"
                              onClick={() => removeFromList(email)}
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  removeFromList(email)
                                }
                              }}
                            >
                              <Indicator variant="info">{email}</Indicator>
                            </span>
                          )
                        })}
                      </div>
                    )}
                  </div>
                  <div className="flex items-center gap-4">
                    <Button loading={isLoading} type="submit" variant="primary">
                      Send invites
                    </Button>
                  </div>
                </div>
              </form>
            </Form.Root>
          </div>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
