import { AppointmentTypeFeature } from "types/graphql"

/**
 * Returns an appointment session length in minutes based on a given appointment type.
 */
export const getSessionLength = (
  appointmentType: AppointmentTypeFeature
): number => {
  switch (appointmentType) {
    case AppointmentTypeFeature.CONSULTATION:
    case AppointmentTypeFeature.SERIES_CONSULTATION_SESSION:
    case AppointmentTypeFeature.COURSE_SESSION_25_MINUTES:
    case AppointmentTypeFeature.PRIVATE_COURSE_SESSION_25_MINUTES:
    case AppointmentTypeFeature.BEREAVEMENT_CONSULTATION:
    case AppointmentTypeFeature.PARENTHOOD_CONSULTATION:
      return 25
    default:
      return 50
  }
}
