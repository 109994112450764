import { type ComponentPropsWithoutRef } from "react"

import { cn } from "common/helpers/cn"

import { ErrorText } from "./ErrorText"
import { Label } from "./Label"

import type {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form"

interface TextInputProps<FormValues extends FieldValues>
  extends Omit<ComponentPropsWithoutRef<"input">, "size"> {
  /**
   * Class names to apply to wrapping element.
   */
  classNameWrapper?: ComponentPropsWithoutRef<"div">["className"]
  /**
   * Id for the text area.
   */
  id?: string
  /**
   * Optional label for the input. This will be displayed above the input.
   */
  label?: string
  /**
   * Name of the field that we're adding to the form. This is used to create the
   * react-hook-form register function.
   */
  name: Path<FormValues>
  /**
   * We use the register from the useForm hook to register the input with react-hook-form.
   * The key name should match the id of the input. This will be passed from the parent component.
   *
   */
  register?: UseFormRegister<FormValues>
  /**
   * Allows us to provide a set of additional options and validation rules to the input.
   */
  registerOptions?: RegisterOptions<FormValues>
  /**
   * Size of input
   */
  size?: "sm" | "md" | "lg"
  /**
   * Label tooltip
   */
  tooltip?: ComponentPropsWithoutRef<typeof Label>["tooltip"]
  /**
   * We allow the text input to be an input that deals with alpha-numeric characters.
   */
  type?: "email" | "number" | "password" | "tel" | "text" | "url"
}

/**
 * TextInput component that wraps the primitive input element.
 *
 * It's a React.ReactElement as we're using the react-hook-form library to handle the field state and type defintions.
 * Which involves taking a value from the parent and passing it as a generic to the
 * interface.
 */
export const TextInput = <FormValues extends FieldValues>({
  className,
  classNameWrapper,
  id,
  name,
  register,
  registerOptions,
  label,
  size = "md",
  tooltip,
  type = "text",
  ...rest
}: TextInputProps<FormValues>): React.ReactElement<FormValues> => {
  return (
    <div className={cn("flex flex-col gap-1 w-full", classNameWrapper)}>
      {label !== undefined && (
        <Label htmlFor={id} tooltip={tooltip}>
          {label}
        </Label>
      )}
      <input
        className={cn(
          "border border-grey-200 rounded-md transition-all ring-0 focus:outline-none focus:ring-2 focus:ring-blue-800 w-full",
          {
            "h-8 px-2 text-sm": size === "sm",
            "h-10 px-2 text-base": size === "md",
            "h-12 px-3 text-base": size === "lg",
          },
          className
        )}
        id={id ?? name}
        type={type}
        {...(register && register(name, registerOptions))}
        {...rest}
      />
      <ErrorText name={name} />
    </div>
  )
}
