import { FunctionComponent, useEffect, useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { InstantSearch } from "react-instantsearch"
import { algoliasearch } from "algoliasearch"
import { Helmet } from "react-helmet-async"

import { useAuth } from "common/context/authContext"
import { config } from "config"

import { AdviceLibraryLanding } from "./components/AdviceLibraryLanding"
import { AdviceLibraryAnswer } from "./components/AdviceLibraryAnswer"

const searchClient = algoliasearch(
  config.adviceLibrary.appId,
  config.adviceLibrary.readKey
)

export const AdviceLibraryRouter: FunctionComponent = () => {
  const { user, isUserLoading } = useAuth()
  const [showingResults, setShowingResults] = useState(false)

  useEffect(() => {
    if (user?.id !== undefined) {
      // @ts-expect-error Using an Algolia monkeypatched property on `window` https://www.algolia.com/doc/guides/search-analytics/guides/usertoken/#set-the-user-token-in-instantsearch-and-autocomplete
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.aa("setAuthenticatedUserToken", user.id)
    }
  }, [user?.id])

  if (isUserLoading) return <></>

  if (user?.featuresAndSettings.adviceLibrary.value !== true) {
    return <Navigate to="/" />
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={config.adviceLibrary.searchIndexName}
      insights={true}
      future={{ preserveSharedStateOnUnmount: true }}
      routing
    >
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet title="Advice Library | Spill" />
              <AdviceLibraryLanding
                searchClient={searchClient}
                showingResults={showingResults}
                setShowingResults={setShowingResults}
              />
            </>
          }
        />
        <Route
          path=":objectId/*"
          element={
            <>
              <Helmet title="Advice Library | Spill" />
              <AdviceLibraryAnswer
                searchClient={searchClient}
                setShowingResults={setShowingResults}
              />
            </>
          }
        />
      </Routes>
    </InstantSearch>
  )
}
