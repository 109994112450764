import { Helmet } from "react-helmet-async"
import { Button } from "@spillchat/puddles"
import { useMutation } from "@apollo/client"
import { toast } from "sonner"
import { setLoginAttemptInfo } from "supertokens-auth-react/recipe/passwordless"
import { Link } from "react-router-dom"
import { SetStateAction, useState } from "react"
import { UseFormReturn } from "react-hook-form"

import {
  QuoteSignupCompanyMutation,
  QuoteSignupCompanyMutationVariables,
  SpillSubscriptionPlan,
} from "types/graphql"
import { mutations } from "features/auth/components/QuoteSignup.mutations"
import { LogoWordmark } from "common/components/logo/LogoWordmark"
import { useAnalytics } from "common/context/analyticsContext"
import { MultiStepForm } from "common/components/MultiStepForm/MultiStepForm"

import { QuoteSignupName } from "./QuoteSignupName"
import { QuoteSignupForm, formSchema } from "./QuoteSignup.schema"
import { QuoteSignupAuth } from "./QuoteSignupAuth"
import { QuoteSignupSize } from "./QuoteSignupSize"
import { QuoteSignupTeam } from "./QuoteSignupTeam"
import { QuoteSignupType } from "./QuoteSignupType"
import { QuoteSignupUser } from "./QuoteSignupUser"

export const QuoteSignup: React.FunctionComponent = () => {
  const { track } = useAnalytics()

  const defaultValues: QuoteSignupForm = {
    firstName: "",
    size: 1,
    types: [],
    email: "",
    companyName: "",
    numberEmployees: 1,
  }

  const [formComplete, setFormComplete] = useState(false)
  const [completeFormValues, setCompleteFormValues] =
    useState<QuoteSignupForm>(defaultValues)

  const [signUpBuyer] = useMutation<
    QuoteSignupCompanyMutation,
    QuoteSignupCompanyMutationVariables
  >(mutations.signUpBuyer, {
    onError: () => {
      toast.error(
        "We were unable to create your account at this time. Please try again or contact us at support@spill.chat"
      )
    },
    onCompleted: async data => {
      const { deviceId, preAuthSessionId } = data.signUpBuyer
      await setLoginAttemptInfo({
        attemptInfo: {
          deviceId,
          preAuthSessionId,
          flowType: "USER_INPUT_CODE",
        },
      })

      setFormComplete(true)
    },
  })

  const commonBackwardsAction = (
    form: UseFormReturn<QuoteSignupForm>,
    decrementStep: () => void
  ) => {
    void form
    decrementStep()
  }

  const nameStepAdvanceAction = async (
    form: UseFormReturn<QuoteSignupForm>,
    incrementStep: () => void
  ) => {
    const valid = await form.trigger(["firstName"], {
      shouldFocus: true,
    })

    if (valid) {
      track("Completed Step of Quote Signup", { step: "Name" })
      incrementStep()
    }
  }

  const sizeStepAdvanceAction = async (
    form: UseFormReturn<QuoteSignupForm>,
    incrementStep: () => void,
    jumpToStep: (action: SetStateAction<number>) => void
  ) => {
    const valid = await form.trigger(["size"])

    if (valid) {
      // Set the total number of employees starter value to at least equal the number of
      // employees needing immediate support
      if (form.getValues("numberEmployees") < form.getValues("size")) {
        form.setValue("numberEmployees", form.getValues("size"))
      }
      // Skip the total number of employees step if only one employee needs immediate support
      if (form.getValues("size") === 1) {
        jumpToStep(step => step + 2)
        track("Completed Step of Quote Signup", {
          step: "Size",
          skipped: true,
        })
      } else {
        incrementStep()
        track("Completed Step of Quote Signup", { step: "Size" })
      }
    }
  }

  const teamStepAdvanceAction = async (
    form: UseFormReturn<QuoteSignupForm>,
    incrementStep: () => void
  ) => {
    const valid = await form.trigger(["numberEmployees"])

    if (valid) {
      track("Completed Step of Quote Signup", { step: "Team" })
      incrementStep()
    }
  }

  const typeStepBackwardsAction = (
    form: UseFormReturn<QuoteSignupForm>,
    decrementStep: () => void,
    jumpToStep: (action: SetStateAction<number>) => void
  ) => {
    if (form.getValues("size") === 1) {
      jumpToStep(step => step - 2)
    } else {
      decrementStep()
    }
  }

  const typeStepAdvanceAction = async (
    form: UseFormReturn<QuoteSignupForm>,
    incrementStep: () => void
  ) => {
    const valid = await form.trigger(["types"])

    if (valid) {
      track("Completed Step of Quote Signup", { step: "Needs" })
      incrementStep()
    }
  }

  const userStepAdvanceAction = async (
    form: UseFormReturn<QuoteSignupForm>
  ) => {
    const valid = await form.trigger()

    if (valid) {
      await signUpBuyer({
        variables: {
          displayName: form.getValues("firstName"),
          email: form.getValues("email"),
          companyName: form.getValues("companyName"),
          hasAgreedToTsAndCs: true,
          hasAgreedToHealthData: true,
          hasConfirmedAtLeast18: true,
          subscriptionPlan:
            form.getValues("numberEmployees") === 1
              ? SpillSubscriptionPlan.STARTER
              : SpillSubscriptionPlan.ESSENTIAL,
        },
      })
      setCompleteFormValues(form.getValues())
      track("Completed Step of Quote Signup", { step: "Create Buyer" })
    }
  }

  return (
    <div className="max-w-screen-lg mx-auto px-6 h-full">
      <div className="h-full lg:h-screen w-full flex justify-center items-center py-32 lg:py-0">
        <Helmet title="Quote | Spill" />
        <div className="fixed top-8 left-8 flex gap-2 items-center">
          <Link to="https://spill.chat">
            <LogoWordmark />
          </Link>
        </div>
        <div className="flex flex-col items-center gap-12 text-center">
          {!formComplete && (
            <MultiStepForm
              schema={formSchema}
              defaultValues={defaultValues}
              stepsAndNavigationActions={[
                {
                  step: QuoteSignupName,
                  advanceAction: nameStepAdvanceAction,
                },
                {
                  step: QuoteSignupSize,
                  advanceAction: sizeStepAdvanceAction,
                  reverseAction: commonBackwardsAction,
                },
                {
                  step: QuoteSignupTeam,
                  advanceAction: teamStepAdvanceAction,
                  reverseAction: commonBackwardsAction,
                },
                {
                  step: QuoteSignupType,
                  advanceAction: typeStepAdvanceAction,
                  reverseAction: typeStepBackwardsAction,
                },
                {
                  step: QuoteSignupUser,
                  advanceAction: userStepAdvanceAction,
                  reverseAction: commonBackwardsAction,
                },
              ]}
              showProgressBars={true}
            />
          )}
          {formComplete && (
            <QuoteSignupAuth
              {...completeFormValues}
              onCancel={() => setFormComplete(false)}
            />
          )}
        </div>
      </div>
      <div className="fixed bottom-4 left-4 hidden md:block">
        <Button asChild variant="tertiary">
          <Link to="/">Or sign in</Link>
        </Button>
      </div>
    </div>
  )
}
