import { AppointmentTypeFeature, BookableAppointmentType } from "types/graphql"

/**
 * Takes a session type and returns the appropriate session type string that's appropriate for the UI.
 */
export const renderSessionType = (
  sessionType: AppointmentTypeFeature | BookableAppointmentType | null
): string => {
  switch (sessionType) {
    case AppointmentTypeFeature.COMPLIMENTARY:
      return "Complimentary"
    case AppointmentTypeFeature.CONSULTATION:
    case AppointmentTypeFeature.FIRST_THERAPY_SESSION:
    case AppointmentTypeFeature.SERIES_CONSULTATION_SESSION:
      return "Course Consultation"
    case AppointmentTypeFeature.COURSE:
    case AppointmentTypeFeature.SERIES_THERAPY_SESSION:
    case AppointmentTypeFeature.COURSE_SESSION_25_MINUTES:
      return "Course Session"
    case AppointmentTypeFeature.MANAGER_CLINIC:
      return "Manager Clinic"
    case AppointmentTypeFeature.ONE_OFF:
    case AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION:
      return "One-Off"
    case AppointmentTypeFeature.PLUS_ONE:
      return "Plus One"
    case AppointmentTypeFeature.PRIVATE_THERAPY_SESSION:
    case AppointmentTypeFeature.PRIVATE_COURSE_SESSION_25_MINUTES:
      return "Private Session"
    case AppointmentTypeFeature.BEREAVEMENT_CONSULTATION:
      return "Bereavement Consultation"
    case AppointmentTypeFeature.BEREAVEMENT_SESSION:
      return "Bereavement Session"
    case AppointmentTypeFeature.PARENTHOOD_CONSULTATION:
      return "Parenthood Consultation"
    case AppointmentTypeFeature.PARENTHOOD_SESSION:
      return "Parenthood Session"
    case AppointmentTypeFeature.PMI_SESSION:
      return "PMI Session"
    default:
      return "Unknown"
  }
}

export const renderLifeEvent = (lifeEvent: string): string => {
  switch (lifeEvent) {
    case "adhd":
      return "ADHD coaching"
    default:
      return "Unknown"
  }
}
