import { z } from "zod"

export const formSchema = z.object({
  packageSettingsId: z.string().min(1, { message: "Package is required" }),
  isAnonymous: z.boolean(),
  noteFromUser: z
    .string()
    .max(250, {
      message: "Please limit your note to 250 characters",
    })
    .optional(),
})

export type TherapySpecialisedSupportRequestForm = z.infer<typeof formSchema>
