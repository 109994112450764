import { z } from "zod"

export const loginMethodsSchema = z.object({
  personalEmail: z
    .string()
    .email({ message: "(not currently valid)" })
    .or(z.literal("")),
})

export type LoginMethodsValues = z.infer<typeof loginMethodsSchema>
