import { FC } from "react"
import { ProductFruits } from "react-product-fruits"

import { useAuth } from "common/context/authContext"
import { config } from "config"

export const ProductFruitsWrapper: FC = () => {
  const { user } = useAuth()
  if (!user) {
    return null
  }
  const userInfo = {
    username: user.id, // REQUIRED - any unique user identifier
    firstname: user.displayName,
    signUpAt: user.createdAt,
    role: user.role,
    props: { companyId: user.company?.id ?? "" },
  }
  return (
    <ProductFruits
      workspaceCode={config.productFruits.workspaceCode}
      language="en"
      user={userInfo}
    />
  )
}
