import { gql, useQuery } from "@apollo/client"
import { capitalize } from "inflection"
import cn from "classnames"

import { Tooltip } from "common/components/Tooltip"

import type {
  BenchmarkGroupGetCompanyQuery as QueryData,
  BenchmarkGroupGetCompanyQueryVariables as QueryVariables,
} from "types/graphql"

const fragments = {
  companyFields: gql`
    fragment BenchmarkGroupCompanyFields on Company {
      id
      benchmarkGroup {
        id
        companySize
        shareEverythingPercentage
        majorityMeetingDays
      }
    }
  `,
}

const queries = {
  getCompany: gql`
    query BenchmarkGroupGetCompany {
      company {
        ...BenchmarkGroupCompanyFields
      }
    }
    ${fragments.companyFields}
  `,
}
export function BenchmarkGroup(): JSX.Element | null {
  const { data } = useQuery<QueryData, QueryVariables>(queries.getCompany)

  const benchmarkGroup = data?.company.benchmarkGroup

  const BenchmarkDays = {
    MON_TO_WED: "Mon - Wed",
    THU_TO_FRI: "Thu - Fri",
  }

  const BenchmarkSharePercent = {
    ABOVE_AVERAGE: "Above Average",
    BELOW_AVERAGE: "Below Average",
  }

  if (!benchmarkGroup) return null

  return (
    <div className="cursor-default flex flex-col gap-md xl:flex-row">
      <header className="flex-1">
        <h3 className="whitespace-nowrap">Your Spill benchmark</h3>
        <p>
          We group similar companies together based on these factors so you can
          see how your data measures up.
        </p>
      </header>
      <div className="grid grid-cols-3 gap-[inherit] grow shrink-0">
        <BenchmarkGroupBox
          className="bg-blue-200"
          title="Size"
          tooltip={`Small = 1–15 people\nMedium = 16–40 people\nLarge = 41+ people`}
          value={benchmarkGroup.companySize}
        />
        <BenchmarkGroupBox
          className="bg-blue-200"
          title="Share %"
          tooltip="The proportion of people sharing all of their feelings with the team."
          value={
            BenchmarkSharePercent[benchmarkGroup.shareEverythingPercentage]
          }
        />
        <BenchmarkGroupBox
          className="bg-blue-400"
          title="Majority Days"
          tooltip="The days when you collect the most Check-in responses."
          value={BenchmarkDays[benchmarkGroup.majorityMeetingDays]}
        />
      </div>
    </div>
  )
}

BenchmarkGroup.fragments = fragments
BenchmarkGroup.queries = queries

type BenchmarkGroupBoxProps = {
  className: string
  title: string
  tooltip: string
  value: string
}

export function BenchmarkGroupBox({
  className,
  title,
  tooltip,
  value,
}: BenchmarkGroupBoxProps): JSX.Element {
  return (
    <Tooltip content={tooltip} followMouse id={title}>
      <div
        className={cn(
          "box cursor-help flex flex-col justify-center shrink-0 text-center",
          className
        )}
      >
        <p>{title}</p>
        <h5 className="capitalize">{capitalize(value)}</h5>
      </div>
    </Tooltip>
  )
}
