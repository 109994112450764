import { ColumnDef } from "@tanstack/react-table"
import { DataTable } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { startCase } from "lodash"
import { UserIcon } from "@heroicons/react/24/outline"

import { formatPounds } from "common/helpers/formatNumber"

type AccessTableProps = {
  data: string[]
  singleSessionPrice: number
  sessionCount: number
}

export const QuoteViewTable: FunctionComponent<AccessTableProps> = ({
  data,
  singleSessionPrice,
  sessionCount,
}: AccessTableProps) => {
  const columns: ColumnDef<unknown>[] = [
    {
      accessorKey: "person",
      header: "Person",
      cell: () => {
        return (
          <div className="flex">
            <UserIcon className="size-4 text-yellow-600" />
          </div>
        )
      },
    },
    {
      accessorKey: "type",
      header: "Struggling with",
      cell: ({ row }) => {
        if (row.original === "none") {
          return "Unknown"
        }

        return startCase(row.original as string)
      },
    },
    {
      accessorKey: "support",
      header: "Recommended support",
      cell: () =>
        `Up to ${sessionCount} x 50min sessions (+ initial 25min consultation)`,
    },
    {
      accessorKey: "cost",
      header: "Cost",
      cell: () =>
        `Up to ${formatPounds(singleSessionPrice * sessionCount + singleSessionPrice / 2)}`,
    },
  ]

  return <DataTable data={data} columns={columns} showPagination={false} />
}
