import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { gql, useLazyQuery } from "@apollo/client"

import { MessageButtonStyle } from "types/graphql"
import { Markdown } from "common/components/Markdown"
import { NotFound404Page } from "common/components/NotFound404Page"
import { useAnalytics } from "common/context/analyticsContext"

import type {
  MessagesRouterGetMessageQuery as QueryData,
  MessagesRouterGetMessageQueryVariables as QueryVariables,
} from "types/graphql"

const FEATURE_TITLE_MAP: Record<string, string> = {
  ask_a_therapist: "Ask a Therapist",
}

const STYLE_CLASSNAME_MAP: Record<MessageButtonStyle, string> = {
  DANGER: "btn btn-lg btn-danger grow whitespace-nowrap",
  DEFAULT: "btn btn-lg btn-gray grow whitespace-nowrap",
  PRIMARY: "btn btn-lg btn-primary grow whitespace-nowrap",
}

const fragments = {
  messageFields: gql`
    fragment MessagesRouterMessageFields on Message {
      id
      content
      feature
      buttons {
        style
        text
        url
        actionId
      }
    }
  `,
}

const queries = {
  getMessage: gql`
    query MessagesRouterGetMessage($id: ID!) {
      message(id: $id) {
        ...MessagesRouterMessageFields
      }
    }
    ${fragments.messageFields}
  `,
}

export function MessagePage(): JSX.Element {
  const { messageId } = useParams()
  const { track } = useAnalytics()

  const [getMessage, { data }] = useLazyQuery<QueryData, QueryVariables>(
    queries.getMessage
  )

  useEffect(() => {
    if (messageId !== undefined) {
      void getMessage({ variables: { id: messageId } })
    }
  }, [messageId])

  const { buttons, content = "", feature = "" } = data?.message ?? {}

  useEffect(() => {
    if (messageId === undefined || feature === "") return

    track("User Read Message on Web", { Feature: feature })
  }, [messageId, feature])

  const trackButton = (url: string, actionId?: string | null) => {
    track("User Clicked Message Button", { URL: url, Action: actionId })
  }

  return (
    <div className="flex flex-col items-center grow p-6">
      {data?.message === null ? (
        <NotFound404Page />
      ) : (
        <div className="flex flex-col gap-md max-w-xl w-full">
          <h2 className="mt-4">{FEATURE_TITLE_MAP[feature]}</h2>

          <div className="flex flex-col gap-sm">
            <Markdown
              options={{
                overrides: { h1: ({ children }) => <h4>{children}</h4> },
              }}
            >
              {content}
            </Markdown>
          </div>

          {buttons && (
            <div className="flex flex-col sm:flex-row flex-wrap gap-4">
              {buttons.map(({ style, text, url, actionId }) => {
                if (typeof url !== "string") return null

                const urlObject = new URL(url)

                if (urlObject.origin === window.location.origin) {
                  return (
                    <Link
                      key={url}
                      className={STYLE_CLASSNAME_MAP[style]}
                      to={`${urlObject.pathname}${urlObject.search}${urlObject.hash}`}
                      onClick={() => trackButton(url, actionId)}
                    >
                      {text}
                    </Link>
                  )
                }
                return (
                  <a
                    key={url}
                    className={STYLE_CLASSNAME_MAP[style]}
                    href={url}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={() => trackButton(url, actionId)}
                  >
                    {text}
                  </a>
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
