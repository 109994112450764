import { useMutation } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  Form,
  H2,
  Input,
  ModalFullScreenInner,
  P,
} from "@spillchat/puddles"
import { FunctionComponent, ReactNode } from "react"
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { CheckCircleIcon } from "@heroicons/react/24/outline"

import {
  LinkAccountTriggerPersonalEmailMergeCodesMutation,
  LinkAccountTriggerPersonalEmailMergeCodesMutationVariables,
  LinkAccountVerifyPersonalMergeCodeMutation,
  LinkAccountVerifyPersonalMergeCodeMutationVariables,
  MergeAppUsersResponse,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useGoBack } from "common/hooks/useGoBack"
import { CodeInput, FieldValues } from "common/components/CodeInput"

import { mutations } from "./link-account.mutations"
import { formSchema } from "./link-account.schema"

import type { LinkAccountForm } from "./link-account.schema"

export const LinkAccount: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const goBack = useGoBack()

  const form = useForm<LinkAccountForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  })

  const [
    triggerPersonalEmailMergeCodes,
    { data: triggerData, loading: triggerLoading },
  ] = useMutation<
    LinkAccountTriggerPersonalEmailMergeCodesMutation,
    LinkAccountTriggerPersonalEmailMergeCodesMutationVariables
  >(mutations.triggerPersonalEmailMergeCodes)

  const [verifyPersonalMergeCode, { data: verifyData }] = useMutation<
    LinkAccountVerifyPersonalMergeCodeMutation,
    LinkAccountVerifyPersonalMergeCodeMutationVariables
  >(mutations.verifyPersonalMergeCode)

  if (isUserLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const handleSubmit = async ({ email }: LinkAccountForm) => {
    const variables = {
      email,
    }

    await triggerPersonalEmailMergeCodes({
      variables,
    })
  }

  const email = form.getValues("email")

  const onSubmit = async (values: FieldValues) => {
    await verifyPersonalMergeCode({
      variables: {
        email: form.getValues("email"),
        code: values.code.join(""),
      },
    })
  }

  return (
    <>
      <Helmet title="Link old account | Spill" />
      <ModalFullScreenInner title="Link old account" onClose={() => goBack()}>
        <div className="flex justify-center items-center px-6 overflow-scroll grow">
          <div className="max-w-lg mx-auto w-full">
            {verifyData === undefined ? (
              <>
                {triggerData === undefined && (
                  <Form.Root {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)}>
                      <div className="flex flex-col gap-8">
                        <div className="flex flex-col gap-4">
                          <H2>
                            Enter the personal email address on your old account
                          </H2>
                          <P>
                            Enter the personal email address you logged in with
                            on your old account. You&apos;ll need to enter a
                            code from that email on the next screen
                          </P>
                        </div>
                        <div className="flex flex-col gap-4">
                          <Form.Field
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                              <Form.Item>
                                <Form.Control>
                                  <Input
                                    placeholder="mail@example.com"
                                    type="email"
                                    {...field}
                                    label={{
                                      children: "Old email login",
                                    }}
                                  />
                                </Form.Control>
                                <Form.Message />
                              </Form.Item>
                            )}
                          />
                        </div>
                        <div className="flex items-center gap-4">
                          <Button
                            loading={triggerLoading}
                            type="submit"
                            variant="primary"
                          >
                            Send code
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Form.Root>
                )}
                {triggerData?.triggerPersonalEmailMergeCodes === true && (
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>Confirm your old account</H2>
                      <P>Enter the code sent to {email}</P>
                    </div>
                    <CodeInput onSubmit={onSubmit} />
                  </div>
                )}
                {triggerData?.triggerPersonalEmailMergeCodes === false && (
                  <LinkAccountFailure>
                    <P>
                      We were unable to send a verification email. This could be
                      because this email is not a personal email or is not
                      associated with a deactivated Spill account. If this
                      continues, you can email us at{" "}
                      <a className="underline" href="mailto:hi@spill.chat">
                        hi@spill.chat
                      </a>
                    </P>
                  </LinkAccountFailure>
                )}
              </>
            ) : (
              <>
                {verifyData?.verifyPersonalMergeCode != null ? (
                  <LinkAccountSuccess
                    result={verifyData.verifyPersonalMergeCode}
                  />
                ) : (
                  <LinkAccountFailure>
                    <P>
                      Because your old account wasn't deactivated by your
                      company we weren't able to move it across. If you'd like
                      to speak with a specific therapist you can get in touch
                      with{" "}
                      <a className="underline" href="mailto:therapy@spill.chat">
                        therapy@spill.chat
                      </a>{" "}
                      and we can help out.
                    </P>
                  </LinkAccountFailure>
                )}
              </>
            )}
          </div>
        </div>
      </ModalFullScreenInner>
    </>
  )
}

const LinkAccountSuccess = ({ result }: { result?: MergeAppUsersResponse }) => {
  const list = [
    {
      label: "Personal log in email",
      success: true,
      id: "userMerged",
    },
    {
      label: "Previous therapy sessions and therapists",
      success: result?.appointmentsMerged,
      id: "appointmentsMerged",
    },
    {
      label: "Ask a therapist questions",
      success: result?.aatsMerged,
      id: "aatsMerged",
    },
    {
      label: "Therapy profile",
      success: result?.profilesMerged,
      id: "profilesMerged",
    },
    {
      label: "Private therapy billing information",
      success: result?.userBillingsMerged,
      id: "userBillingsMerged",
    },
  ]

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <H2>Your account has been linked</H2>
        <P>
          We've associated the following history from your old account with your
          new&nbsp;account
        </P>
      </div>
      <div className="flex flex-col gap-2">
        {list
          .filter(item => item.success)
          .map(item => {
            return (
              <div className="flex gap-4 items-center" key={item.id}>
                <CheckCircleIcon className="size-4 text-teal-400" />
                <P>{item.label}</P>
              </div>
            )
          })}
      </div>
      <div className="flex items-center gap-4">
        <Button asChild variant="primary">
          <Link to="/profile">Return to profile</Link>
        </Button>
      </div>
    </div>
  )
}

const LinkAccountFailure = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <H2>We haven't linked your accounts</H2>
        <P>{children}</P>
      </div>
      <div className="flex items-center gap-4">
        <Button asChild variant="primary">
          <Link to="/profile">Return to profile</Link>
        </Button>
      </div>
    </div>
  )
}
