import { useMutation } from "@apollo/client"
import { FormProvider, useForm } from "react-hook-form"
import { type FunctionComponent } from "react"
import { toast } from "sonner"
import { Button, H1, P, TextArea } from "@spillchat/puddles"
import { EyeSlashIcon } from "@heroicons/react/24/outline"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useAnalytics } from "common/context/analyticsContext"

import { mutations } from "../feedback.mutations"
import { FeedbackConsultationFormFields } from "../feedback.types"

import { FeedbackFormSuccess } from "./FeedbackFormSuccess"
import { FeedbackFormOpinionScale } from "./FeedbackFormOpinionScale"

import type {
  FeedbackConsultationFormMutation,
  FeedbackConsultationFormMutationVariables,
  FeedbackConsultationV1Input,
} from "types/graphql"

interface FeedbackConsultationProps {
  entityId: string
}

export const FeedbackConsultation: FunctionComponent<
  FeedbackConsultationProps
> = ({ entityId }) => {
  const { track } = useAnalytics()
  const formMethods = useForm<FeedbackConsultationFormFields>({
    defaultValues: {
      extraContext: undefined,
      metTheirGoals: undefined,
      wouldSeeAgain: undefined,
    },
  })

  const [submitFeedbackConsultation, { loading, data }] = useMutation<
    FeedbackConsultationFormMutation,
    FeedbackConsultationFormMutationVariables
  >(mutations.submitFeedbackConsultation, {
    onError: () => {
      toast.error(
        "Sorry, something went wrong. You can email us your feedback at therapy@spill.chat"
      )
    },
  })

  const onSubmit = async (formFields: FeedbackConsultationFormFields) => {
    const input: FeedbackConsultationV1Input = {
      entityId,
      extraContext: formFields.extraContext ?? null,
      metTheirGoals: formFields.metTheirGoals,
      wouldSeeAgain: formFields.wouldSeeAgain === 1 ? true : false,
    }

    await submitFeedbackConsultation({
      variables: {
        input,
      },
    })

    track("Completed Consultation Form")
  }

  formMethods.watch()

  const updateSelected = (
    nameOfScale: string,
    newSelectedValue: number | string
  ): void => {
    formMethods.setValue(
      nameOfScale as keyof FeedbackConsultationFormFields,
      newSelectedValue
    )
  }

  const canSubmit = [
    formMethods.getValues("metTheirGoals"),
    formMethods.getValues("wouldSeeAgain"),
  ].every(value => value !== undefined)

  if (loading)
    return (
      <div className="flex items-center justify-center">
        <LoadingSpinner sizeInPixels={48} />
      </div>
    )

  return (
    <div className="flex justify-center items-center">
      <div className="p-6 lg:p-8 xs:w-full lg:max-w-screen-sm">
        <FormProvider {...formMethods}>
          <form
            className="flex flex-col gap-8 w-full"
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            {data === undefined ? (
              <>
                <H1>So, how was it?</H1>
                <FeedbackFormOpinionScale
                  id="metTheirGoals"
                  required={true}
                  question="Did the session meet your goals?"
                  values={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  labels={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                  selectedValue={formMethods.getValues("metTheirGoals")}
                  callback={updateSelected}
                />
                <FeedbackFormOpinionScale
                  id="wouldSeeAgain"
                  required={true}
                  question="Would you see this counsellor again?"
                  values={[1, 0]}
                  labels={["Yes", "No"]}
                  minWidth="w-24"
                  selectedValue={formMethods.getValues("wouldSeeAgain")}
                  callback={updateSelected}
                />

                {formMethods.getValues("metTheirGoals") < 9 && (
                  <TextArea
                    id="extraContext"
                    className="h-24 w-full max-w-full border-0"
                    label={{
                      children: (
                        <P>
                          Did you get what you needed from this interaction with
                          Spill?
                        </P>
                      ),
                    }}
                    onChange={e =>
                      updateSelected("extraContext", e.target.value)
                    }
                  />
                )}

                <div>
                  <Button type="submit" variant="primary" disabled={!canSubmit}>
                    Submit
                  </Button>
                </div>

                <div className="flex space-x-2">
                  <EyeSlashIcon className="w-10 text-teal-400" />
                  <P muted>
                    Your answers will only be shared with your counsellor and
                    Spill's clinical team, and in aggregate with your company.
                    Only Spill will know who left them.
                  </P>
                </div>
              </>
            ) : (
              <FeedbackFormSuccess />
            )}
          </form>
        </FormProvider>
      </div>
    </div>
  )
}
