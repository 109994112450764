import { Button, H3, P } from "@spillchat/puddles"
import { ReactNode, useEffect, useState } from "react"
import { Form } from "@spillchat/puddles"
import { FieldValues, Path } from "react-hook-form"

import { iconList } from "common/helpers/iconPalette"

type FormFieldWrapperProps<T> = {
  children: ReactNode
  displayValue: string | number
  displayIcon?: string | null
  description?: string
  disabled?: boolean
  label: string
  values?: Path<T>[]
  onSubmit?: () => Promise<void>
}

enum State {
  OPEN = "open",
  CLOSED = "closed",
}

export function FormFieldWrapper<T extends FieldValues>({
  children,
  displayValue,
  displayIcon,
  description,
  disabled,
  label,
  values,
  onSubmit,
}: FormFieldWrapperProps<T>): ReactNode {
  const context = Form.useFormContext<T>()

  const watchSubmit = context?.formState.isSubmitSuccessful

  useEffect(() => {
    if (watchSubmit) {
      toggleState()
    }
  }, [watchSubmit])

  const isStatic = values == undefined

  const isEmpty = !isStatic
    ? context.getValues(values).every(value => Boolean(value) === false)
    : true

  const [state, setState] = useState<State>(State.CLOSED)

  const toggleState = () => {
    if (state === State.OPEN) {
      context.reset()
      setState(State.CLOSED)
    } else {
      setState(State.OPEN)
    }
  }

  const getAction = () => {
    if (state === State.OPEN) {
      return {
        label: "Cancel",
      }
    }

    if (isEmpty == true) {
      return { label: "Add" }
    }

    return { label: "Edit" }
  }

  const showDescription =
    state === State.OPEN ||
    (description != undefined && Boolean(displayValue) === false)

  const icon = iconList.find(icon => {
    return icon.displayName === displayIcon
  })

  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col gap-3 grow pb-8 border-b border-grey-100 last:border-none last:pb-0"
    >
      <div className="flex justify-between gap-4">
        <div className="flex flex-col gap-2">
          <H3>{label}</H3>
          {showDescription && <P muted>{description}</P>}
        </div>
        <div>
          {!isStatic && (
            <Button
              variant="tertiary"
              disabled={disabled}
              onClick={() => toggleState()}
            >
              {getAction().label}
            </Button>
          )}
        </div>
      </div>
      {state === State.OPEN ? (
        <div className="flex flex-col gap-4">
          <div>{children}</div>
          <Button
            type="submit"
            disabled={!context.formState.isDirty}
            loading={context.formState.isSubmitting}
          >
            Save
          </Button>
        </div>
      ) : (
        <>
          {displayValue == "" && isStatic === false ? (
            <></>
          ) : (
            <div className="overflow-x-scroll flex flex-row items-center">
              {icon !== null && icon !== undefined && (
                <icon.icon className="size-4 mx-1" />
              )}
              <P muted>
                {isStatic === false
                  ? displayValue
                  : displayValue != ""
                    ? displayValue
                    : "N/A"}
              </P>
            </div>
          )}
        </>
      )}
    </form>
  )
}
