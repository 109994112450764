import { useEffect } from "react"
import { sortBy, isNumber } from "lodash"
import cn from "classnames"
import { useBoolean } from "react-use"
import { P } from "@spillchat/puddles"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"

import { Feeling } from "types/graphql"
import { Pill } from "common/components/Pill"
import { FEELINGS } from "features/pulse/constants/emotions"
import { pulseResponseHasWarning } from "features/pulse/helpers/pulseResponseHasWarning"

import {
  decideFeelingColor,
  decideMoodColor,
} from "../helpers/decideAlertColor"

import type { ComponentProps } from "react"
import type { SharedPulseResponse } from "types/graphql"

type Response = Partial<SharedPulseResponse> &
  Pick<SharedPulseResponse, "id" | "feelings" | "name" | "score">

type PulseResponsesTableProps = Required<Pick<ComponentProps<"div">, "id">> &
  ComponentProps<"div"> & {
    persistTruncatedState?: boolean
    responses: Response[]
    showTableHead?: boolean
    showWarnings?: boolean
    truncateRows?: number
  }

export function PulseResponsesTable({
  className,
  id,
  persistTruncatedState = false,
  responses,
  showTableHead = true,
  showWarnings = true,
  truncateRows,
}: PulseResponsesTableProps): JSX.Element {
  const persistTruncatedStateKey = `isTruncated-${id}`

  const [isTruncated, toggleIsTruncated] = useBoolean(
    isNumber(truncateRows) &&
      (!persistTruncatedState ||
        ["true", null].includes(localStorage.getItem(persistTruncatedStateKey)))
  )

  useEffect(() => {
    if (persistTruncatedState) {
      localStorage.setItem(persistTruncatedStateKey, String(isTruncated))
    }
  }, [isTruncated, persistTruncatedState])

  const responsesWithWarning = sortBy(
    responses.filter(pulseResponseHasWarning),
    ["score", "name"]
  )

  const responsesWithoutWarning = sortBy(
    responses.filter(r => !pulseResponseHasWarning(r)),
    ["score", "name"]
  )

  const allResponses = [...responsesWithWarning, ...responsesWithoutWarning]

  const rows = isTruncated ? allResponses.slice(0, truncateRows) : allResponses

  return (
    <div className={cn("box overflow-visible", className)}>
      <table className="table-auto w-full">
        {showTableHead && (
          <thead>
            <tr>
              {showWarnings && <TableHeader />}
              <TableHeader>Person</TableHeader>
              <TableHeader>Mood</TableHeader>
              <TableHeader>Feelings</TableHeader>
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map(response => {
            return (
              <TableRow key={response.id}>
                {showWarnings && (
                  <TableCell>
                    {pulseResponseHasWarning(response) && (
                      <Pill className="px-0 w-6 sm:w-8" color="warning">
                        <ExclamationTriangleIcon className="shrink-0 w-3 sm:w-4" />
                      </Pill>
                    )}
                  </TableCell>
                )}
                <TableCell className="text-sm sm:text-base whitespace-pre">
                  {response.name ?? "Anonymous User"}
                </TableCell>
                <TableCell>
                  <Pills>
                    <Pill color={decideMoodColor(response.score)}>
                      {response.score ?? "–"}
                    </Pill>
                  </Pills>
                </TableCell>
                <TableCell>
                  <Pills>
                    {response.feelings.length ? (
                      response.feelings.map(feeling => {
                        if (
                          typeof FEELINGS[feeling as Feeling] == "undefined"
                        ) {
                          return (
                            <Pill
                              key={feeling}
                              className="capitalize px-1.5 sm:px-4"
                              color={decideFeelingColor(feeling as Feeling)}
                            >
                              {feeling}
                            </Pill>
                          )
                        } else {
                          return (
                            <Pill
                              key={feeling}
                              className="capitalize px-1.5 sm:px-4"
                              color={decideFeelingColor(feeling as Feeling)}
                            >
                              {FEELINGS[feeling as Feeling].adjective}
                            </Pill>
                          )
                        }
                      })
                    ) : (
                      <Pill color="gray">–</Pill>
                    )}
                  </Pills>
                </TableCell>
              </TableRow>
            )
          })}
          {isNumber(truncateRows) && truncateRows < allResponses.length && (
            <tr>
              <TableCell className="p-0">
                <span
                  className="cursor-pointer font-bold py-1 hover:text-teal-600"
                  onClick={toggleIsTruncated}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      toggleIsTruncated()
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {isTruncated ? "Show more" : "Show less"}
                </span>
              </TableCell>
              <TableCell />
              <TableCell />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

function TableHeader({ children, className, ...rest }: ComponentProps<"th">) {
  return (
    <th className={cn("px-1 sm:px-2 py-2 text-left", className)} {...rest}>
      <P weight="medium">{children}</P>
    </th>
  )
}

function TableRow({ children, className }: ComponentProps<"tr">) {
  return (
    <tr className={cn("border-t-grey-200 border-t first:border-0", className)}>
      {children}
    </tr>
  )
}

function TableCell({ children, className, ...rest }: ComponentProps<"td">) {
  return (
    <td
      className={cn(
        "px-1 sm:px-2 py-2 w-1 whitespace-nowrap last:whitespace-normal last:w-full",
        className
      )}
      {...rest}
    >
      {children}
    </td>
  )
}

function Pills({ children, className, ...rest }: ComponentProps<"div">) {
  return (
    <div className={cn("flex flex-wrap gap-xs sm:gap-sm", className)} {...rest}>
      {children}
    </div>
  )
}
