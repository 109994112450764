import { ColumnDef } from "@tanstack/react-table"
import { Button, DataTable, Indicator } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

import {
  AccountStatus,
  PlatformType,
  SpillSubscriptionPlan,
  UserRole,
} from "types/graphql"
import { convertPlatformTypeToName } from "features/admin/helpers/convertPlatformTypeToName"
import { convertPlatformTypeToIndicator } from "features/admin/helpers/convertPlatformTypeToIndicator"

import { AccessTableAction } from "./AccessTableAction"

export type TableUser = {
  accountStatus: AccountStatus
  fullName: string
  identifier?: string
  platformType?: PlatformType
  role: Exclude<UserRole, UserRole.PLUS_ONE>
  email?: string
} & ({ userId: string } | { platformUserId: string })

type AccessTableProps = {
  data: TableUser[]
  userId?: string
  subscriptionPlan?: SpillSubscriptionPlan
  hasSlack?: boolean
}

const roleMap = {
  [UserRole.ADMIN]: "Admin",
  [UserRole.STANDARD]: "Standard",
}

const statusMap = {
  [AccountStatus.ACTIVE]: "Active",
  [AccountStatus.NO_ACCESS]: "Disabled",
  [AccountStatus.NO_ACTIVE_SUBSCRIPTION]: "Active",
  [AccountStatus.NOT_FOUND]: "Not Found",
  [AccountStatus.PENDING_INTEGRATION]: "Pending",
}

export const AccessTable: FunctionComponent<AccessTableProps> = ({
  data,
  userId,
  subscriptionPlan,
  hasSlack,
}: AccessTableProps) => {
  const columns: ColumnDef<TableUser>[] = [
    {
      accessorKey: "identifier",
      header: "Person",
      cell: ({ row }) => {
        const person = row.original
        return (
          <div className="flex gap-1 items-center">
            <span>{person.identifier}</span>
            {person.accountStatus === AccountStatus.NOT_FOUND && (
              <ExclamationCircleIcon className="text-red-600 size-4" />
            )}
          </div>
        )
      },
    },
    {
      accessorKey: "role",
      header: "Role",
      cell: ({ row }) => roleMap[row.original.role],
    },
    {
      accessorKey: "accountStatus",
      header: "Billing status",
      cell: ({ row }) => statusMap[row.original.accountStatus],
    },
    {
      accessorKey: "platformType",
      header: "Invited via",
      cell: ({ row }) =>
        row.original.platformType != null ? (
          <Indicator
            variant={convertPlatformTypeToIndicator(row.original.platformType)}
          >
            {convertPlatformTypeToName(row.original.platformType)}
          </Indicator>
        ) : (
          "-"
        ),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const person = row.original
        return (
          <AccessTableAction
            person={person}
            originalPlatform={row.original.platformType}
            loggedInUserId={userId}
          />
        )
      },
    },
  ]

  const AccessTableActions: FunctionComponent = () => {
    const hasEmailInvite =
      subscriptionPlan != null
        ? [
            SpillSubscriptionPlan.TEAM,
            SpillSubscriptionPlan.ESSENTIAL,
            SpillSubscriptionPlan.LITE,
            SpillSubscriptionPlan.PROPER,
          ].includes(subscriptionPlan)
        : false

    return (
      <>
        {hasEmailInvite && (
          <div className="flex gap-2">
            {hasSlack === true && (
              <Button asChild size="sm" variant="secondary">
                <Link to="/admin/access/invite/slack">
                  Add person via Slack
                </Link>
              </Button>
            )}
            <Button asChild size="sm" variant="secondary">
              <Link to="/admin/access/invite/email">Add person via email</Link>
            </Button>
          </div>
        )}
      </>
    )
  }

  return (
    <DataTable
      data={data}
      columns={columns}
      showPagination={true}
      searchColumn="identifier"
      searchPlaceholder="Filter by person"
      tableActions={<AccessTableActions />}
    />
  )
}
