import { FunctionComponent, useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { Button, H1, P, H3, H4, Tooltip } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { isAfter, subMinutes } from "date-fns"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import ImageLifeEvents from "common/assets/images/product/specialised-support/therapy-parenthood.png"
import ImageCounsellor1 from "common/assets/images/product/counsellor-4.png"
import ImageCounsellor2 from "common/assets/images/product/counsellor-5.png"
import ImageCounsellor3 from "common/assets/images/product/counsellor-6.png"
import {
  AppointmentStatus,
  PackageType,
  ParenthoodGetUserQueryQuery,
  ParenthoodGetUserQueryQueryVariables,
} from "types/graphql"
import { FAQParenthood } from "common/components/FAQ/FAQParenthood"

import { queries } from "./parenthood.queries"

const counsellorImages = [ImageCounsellor1, ImageCounsellor2, ImageCounsellor3]

export const ParenthoodPage: FunctionComponent = () => {
  const [fetchUser, { data, called, loading }] = useLazyQuery<
    ParenthoodGetUserQueryQuery,
    ParenthoodGetUserQueryQueryVariables
  >(queries.user, {
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    void fetchUser()
  }, [])

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const therapyPackages = data?.user?.therapyPackages
  const parenthoodPackage = therapyPackages?.find(
    packageDetails =>
      packageDetails.identifier === PackageType.PARENTHOOD.toString()
  )

  const { numberSessionsUsed = 0, numberSessionsGiven } =
    parenthoodPackage ?? {}

  const appointments = data?.user?.appointments

  const upcomingSessions = appointments?.filter(session => {
    return (
      session.status === AppointmentStatus.CONFIRMED &&
      isAfter(new Date(session.startsAt), subMinutes(new Date(), 50))
    )
  })

  const disableBookingButton =
    numberSessionsUsed === 0 &&
    upcomingSessions?.length != null &&
    upcomingSessions?.length > 0

  return (
    <>
      <Helmet title="Parenthood support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex justify-between gap-8">
          <div className="flex flex-col gap-4 max-w-screen-sm">
            <H1>Parenthood support</H1>
            <P>
              You have <strong>{numberSessionsGiven ?? "6"} sessions</strong>{" "}
              with someone experienced in working with new parents to use any
              time you’d like <strong>over the next two years.</strong>
            </P>
            <P>
              You’ll have an initial 25 minute consultation together to discuss
              how you’re feeling and how you’d like to use your sessions -
              they’ll recommend how you might benefit the most, but it’s
              ultimately your choice.{" "}
            </P>
          </div>
          <div className="hidden lg:flex">
            <img
              className="rounded-lg"
              src={ImageLifeEvents}
              alt="Someone has a Spill session using their laptop"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 max-w-screen-sm items-start">
          <H3>Parenthood support</H3>
          <P>
            Speak with one of Spill’s parenthood specialists who are all fully
            qualified counsellors with a specialism in supporting new parents
          </P>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger className="text-start" type="button">
                <Button
                  variant="primary"
                  disabled={disableBookingButton}
                  asChild
                >
                  {numberSessionsUsed > 0 ? (
                    <Link to="/therapy/sessions">Go to sessions</Link>
                  ) : (
                    <Link to="/therapy/sessions/book?appointmentType=PARENTHOOD_CONSULTATION">
                      Get started
                    </Link>
                  )}
                </Button>
              </Tooltip.Trigger>
              {disableBookingButton && (
                <Tooltip.Content>
                  Since you have an upcoming therapy session, you can't book a
                  parenthood session. Email therapy@spill.chat with questions.
                </Tooltip.Content>
              )}
            </Tooltip.Root>
          </Tooltip.Provider>
          <div className="flex items-center -space-x-2">
            {counsellorImages.map((image, index) => {
              return (
                <div
                  key={index}
                  className="w-12 h-12 bg-teal-400 border-2 border-white rounded-full flex items-center justify-center uppercase tracking-wide font-bold text-xs text-teal-100"
                >
                  <img
                    src={image}
                    alt="A parenthood counsellor"
                    className="rounded-full"
                  />
                </div>
              )
            })}
          </div>
          <div className="flex items-center gap-1">
            <InformationCircleIcon className="size-4" />
            <P size="xs">
              Parenthood support sessions are in addition to your standard
              therapy credits
            </P>
          </div>
        </div>
        <div className="flex flex-col gap-3 max-w-screen-sm">
          <H4 sectionHeader>FAQs</H4>
          <FAQParenthood />
        </div>
      </section>
    </>
  )
}
