import { z } from "zod"

export const formSchema = z.object({
  requestId: z.string(),
  decision: z.boolean(),
  note: z
    .string()
    .max(250, {
      message: "Please limit your note to 250 characters",
    })
    .optional(),
})

export type AdminTherapySpecialisedSupportReviewForm = z.infer<
  typeof formSchema
>
