import bupa from "common/assets/images/brands/bupa.svg"
import vitality from "common/assets/images/brands/vitality.svg"
import safetyWing from "common/assets/images/brands/safety-wing.svg"
import axa from "common/assets/images/brands/axa.svg"

export const pmiProviders = ["Bupa", "Vitality", "Safety Wing", "AXA"] as const

export type PmiProviderType = (typeof pmiProviders)[number]

interface ProviderDetails {
  name: PmiProviderType
  image: string
}

export const pmiProviderDetails: {
  [provider in PmiProviderType]: ProviderDetails
} = {
  Bupa: { name: "Bupa", image: bupa },
  Vitality: { name: "Vitality", image: vitality },
  "Safety Wing": { name: "Safety Wing", image: safetyWing },
  AXA: { name: "AXA", image: axa },
} as const
