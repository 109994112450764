import { Button, P, H3 } from "@spillchat/puddles"
import { ChangeEvent, FunctionComponent, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Fragment } from "react/jsx-runtime"
import { useMutation } from "@apollo/client"

import { LinkUserEnterCodeVerifyMutation, PlatformType } from "types/graphql"
import { createArray } from "common/helpers/createArray"
import { LogoWordmark } from "common/components/logo/LogoWordmark"
import { useAuth } from "common/context/authContext"

import { mutations } from "./LinkUserEnterCode.mutations"

interface FieldValues {
  code: string[]
}

type Props = {
  onUserMerged: () => void
}

export const LinkUserEnterCode: FunctionComponent<Props> = (props: Props) => {
  const { unverifiedPlatformUser } = useAuth()

  const [confirmCode, { data: confirmCodeData }] =
    useMutation<LinkUserEnterCodeVerifyMutation>(mutations.confirmCode)

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setFocus,
    setValue,
    setError,
  } = useForm<FieldValues>({
    defaultValues: {
      // Init with empty string for each character
      code: createArray(9, () => ""),
    },
  })

  const onSubmit: SubmitHandler<FieldValues> = values => {
    void confirmCode({ variables: { code: values.code.join("") } })
  }

  useEffect(() => {
    if (confirmCodeData?.verifyCode === true) {
      props.onUserMerged()
    } else if (confirmCodeData?.verifyCode === false) {
      setError("code.8", { message: "Incorrect value" })
    }
  }, [confirmCodeData?.verifyCode])

  function toUserPlatformAccountName(platform: PlatformType): string {
    let platformName: string | null = null
    switch (platform) {
      case PlatformType.CUSTOM:
        platformName = "custom integration"
        break
      case PlatformType.SLACK:
        platformName = "Slack"
        break
      case PlatformType.TEAMS:
        platformName = "MS Teams"
        break
      case PlatformType.EMAIL:
        platformName = "email"
        break
    }
    return platformName
  }

  if (unverifiedPlatformUser == null) {
    return null
  }

  const oldPlatformAccountNames = unverifiedPlatformUser.appUserDetails
    .map(userDetails =>
      toUserPlatformAccountName(userDetails.platformType as PlatformType)
    )
    .join(", ")

  const oldPlatformName =
    unverifiedPlatformUser.appUserDetails.length == 1
      ? unverifiedPlatformUser.appUserDetails[0]?.name
      : "your other login methods"

  return (
    <div className="flex items-center justify-center col-span-5 lg:col-span-3 min-h-screen relative">
      <div className="fixed top-8 left-8 flex gap-2 items-center">
        <LogoWordmark />
      </div>
      <div className="min-h-screen flex items-center justify-center text-center">
        <div className="max-w-sm mx-auto">
          <div className="flex flex-col items-center gap-4">
            <div className="flex flex-col gap-4 px-4 md:px-0">
              <H3>Check your {oldPlatformAccountNames} for a code</H3>
              <P>
                We've sent a 9-character code to {oldPlatformName}. The code
                expries shortly so please enter it soon.
              </P>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`flex flex-col gap-4 text-center"`}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex gap-1 lg:gap-2 font-bold items-center text-base lg:text-2xl rounded-md w-fit">
                    {createArray(9, index => (
                      <Fragment key={index}>
                        <input
                          {...register(`code.${index}`, {
                            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value !== "" && index < 8) {
                                setFocus(`code.${index + 1}`)
                              }
                              if (e.target.value === "" && index > 0) {
                                setFocus(`code.${index - 1}`)
                              }
                            },
                          })}
                          autoComplete="off"
                          // This autofocuses the first input so we're happy with it
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus={index === 0}
                          className="aspect-square w-8 h-8 border border-grey-200 capitalize rounded-md text-center max-w-[48px] focus:outline-none focus:ring-2"
                          maxLength={1}
                          onFocus={e => {
                            e.target.select()
                          }}
                          onPasteCapture={e => {
                            setFocus("code.8")
                            const pastedCode = e.clipboardData
                              .getData("Text")
                              .replace(/\s|-/g, "")
                            for (let i = 0; i < 9; i++) {
                              setValue(`code.${i}`, pastedCode[i] ?? "")
                            }
                          }}
                        />
                        {[2, 5].includes(index) && (
                          <span key={`${index}dash`}>-</span>
                        )}
                      </Fragment>
                    ))}
                  </div>
                  {errors.code && errors.code[8] && (
                    <span className="text-red-400">
                      <P size="xs">{errors.code[8].message}</P>
                    </span>
                  )}
                  <P size="xs">Can't find your code? Check your spam folder!</P>
                </div>
                <div className={`flex flex-col gap-2 items-center`}>
                  <Button loading={isSubmitting} type="submit">
                    Confirm code
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
