import {
  CalendarDateRangeIcon,
  ChatBubbleLeftIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"
import { Button, H1, H2, H3, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

export const AdminTherapySpecialisedSupportGuide: FunctionComponent = () => {
  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col-reverse lg:flex-row justify-between gap-12 lg:gap-24 grow">
        <div className="flex flex-col gap-5 max-w-lg">
          <H1>Custom Support</H1>
          <div className="flex flex-col gap-3">
            <H3>How it works</H3>
            <P muted>
              Custom support allows you to customise Spill to the unique
              situations people on your team are in.
            </P>
            <P muted>
              Support can be offered company-wide or to a group of one or more
              individuals. Recent examples of custom support include offering
              additional sessions to people during menopause, giving support to
              an individual caring for a sick parent and rolling out additional
              company-wide support following redundancies. You can make the
              support as specific as you need.
            </P>
          </div>
        </div>
        <div>
          <img
            src="https://spill-public-assets.s3.eu-west-2.amazonaws.com/custom-support/thumbnails/generic-green.svg"
            alt="Custom support"
            className="rounded-lg aspect-square w-full lg:max-w-sm"
          />
        </div>
      </div>
      <hr className="border-t border-grey-200" />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-3">
          <H2>Set up your support</H2>
          <P muted>Get started with enabling this support for your company</P>
        </div>
        <div className="grid lg:grid-cols-2 gap-16 items-start">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <H3>What's included</H3>
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-4">
                  <UsersIcon className="text-blue-800 size-6" />
                  <P muted>Specialists tailored to your needs</P>
                </div>
                <div className="flex items-center gap-4">
                  <ChatBubbleLeftIcon
                    className="text-blue-800 size-6"
                    width={24}
                  />
                  <P muted>Set the number of sessions</P>
                </div>
                <div className="flex items-center gap-4">
                  <CalendarDateRangeIcon
                    className="text-blue-800 size-6"
                    width={24}
                  />
                  <P muted>Set the support duration and timing</P>
                </div>
              </div>
            </div>
            <Button variant="primary" asChild>
              <Link to="/admin/access/invite/custom">Get started</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
