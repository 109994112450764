import { useMutation } from "@apollo/client"
import { Input, Form, H2, Select } from "@spillchat/puddles"
import { FunctionComponent, useMemo } from "react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"
import { getCountryForTimezone } from "countries-and-timezones"
import {
  CountryCode,
  getCountryCallingCode,
  isValidPhoneNumber,
} from "libphonenumber-js"
import { FormFieldWrapper } from "components/form/FormFieldWrapper"

import { Label } from "common/components/FormElements/Label"
import {
  ProfileInfoUpdateUserProfileMutationVariables,
  ProfileInfoUpdateUserProfileMutation,
  ProfileInfoGetUserFragment,
} from "types/graphql"
import {
  monthMap,
  validateDateOfBirth,
} from "common/helpers/validateDateOfBirth"
import { formatDate } from "common/helpers/formatDate"
import { useApp } from "common/context/appContext"
import {
  extractPhoneNumberAndCountryCode,
  phoneCountryCodes,
} from "common/helpers/phone-country-codes"

import { mutations } from "./ProfileInfo.mutations"

type LoginMethodsProps = {
  profileInfo?: ProfileInfoGetUserFragment | null
}

type ProfileFormValues = {
  firstName: string
  lastName: string
  displayName: string
  pronouns: string
  dateOfBirth: string[]
  phoneNumber: string
  phoneCountryCode: CountryCode
}

type ProfileFormNameValues = Pick<ProfileFormValues, "firstName" | "lastName">
type ProfileFormDisplayNameValues = Pick<ProfileFormValues, "displayName">
type ProfileFormPronounsValues = Pick<ProfileFormValues, "pronouns">
type ProfileFormDateOfBirthValues = Pick<ProfileFormValues, "dateOfBirth">
type ProfileFormPhoneNumberValues = Pick<
  ProfileFormValues,
  "phoneNumber" | "phoneCountryCode"
>

export const ProfileInfo: FunctionComponent<LoginMethodsProps> = ({
  profileInfo,
}) => {
  const { appTimeZone } = useApp()

  const parsedPhoneNumber = extractPhoneNumberAndCountryCode(
    profileInfo?.therapyProfile?.phoneNumber ?? "",
    appTimeZone ? getCountryForTimezone(appTimeZone)?.id : undefined
  )

  const forms = {
    name: useForm<ProfileFormNameValues>({
      values: {
        firstName:
          profileInfo?.firstName ??
          profileInfo?.therapyProfile?.firstName ??
          "",
        lastName:
          profileInfo?.lastName ?? profileInfo?.therapyProfile?.lastName ?? "",
      },
    }),
    displayName: useForm<ProfileFormDisplayNameValues>({
      values: {
        displayName: profileInfo?.displayName ?? "",
      },
    }),
    pronouns: useForm<ProfileFormPronounsValues>({
      values: {
        pronouns: profileInfo?.therapyProfile?.pronouns ?? "",
      },
    }),
    dateOfBirth: useForm<ProfileFormDateOfBirthValues>({
      values: {
        dateOfBirth: profileInfo?.therapyProfile?.dateOfBirth?.split("-") ?? [],
      },
    }),
    phoneNumber: useForm<ProfileFormPhoneNumberValues>({
      values: {
        phoneNumber: parsedPhoneNumber?.phoneNumber ?? "",
        phoneCountryCode: parsedPhoneNumber?.countryInfo.countryCode ?? "",
      },
    }),
  }

  const [updateUserProfile] = useMutation<
    ProfileInfoUpdateUserProfileMutation,
    ProfileInfoUpdateUserProfileMutationVariables
  >(mutations.updateTherapyProfile, {
    refetchQueries: ["ProfilePageGetUser"],
    onCompleted: () => toast.success("Profile updated"),
    onError: () => {
      toast.error("It looks like something went wrong, please try again.")
    },
  })

  const validateDateOfBirthWrapper = (dateOfBirth: string) => {
    const dateOfBirthValidity = validateDateOfBirth(dateOfBirth)
    if (dateOfBirthValidity.error) {
      forms.dateOfBirth.setError("dateOfBirth", {
        message: dateOfBirthValidity.error,
      })
    }
    return dateOfBirthValidity.valid
  }

  const parsedDateOfBirth = useMemo(() => {
    const values = forms.dateOfBirth.getValues("dateOfBirth")
    if (values.length !== 3) return null

    const dateString = values.join("-")
    try {
      const validation = validateDateOfBirth(dateString)
      if (!validation.valid) return null

      return formatDate(new Date(dateString))
    } catch {
      return null
    }
  }, [forms.dateOfBirth.getValues("dateOfBirth")])

  const validatePhoneNumberWrapper = (
    countryCode: CountryCode,
    phoneNumber: string
  ) => {
    //If Valid or is empty, return valid
    if (
      phoneNumber === "" ||
      phoneNumber === undefined ||
      isValidPhoneNumber(phoneNumber, countryCode)
    ) {
      return true
    }

    forms.phoneNumber.setError("phoneNumber", {
      message: "Phone number is invalid",
    })

    return false
  }

  return (
    <div className="flex flex-col gap-12">
      <H2>Personal information</H2>
      <div className="flex flex-col gap-5">
        <Form.Root {...forms.name}>
          <FormFieldWrapper<ProfileFormNameValues>
            onSubmit={forms.name.handleSubmit(async values => {
              await updateUserProfile({
                variables: {
                  firstName: values.firstName ?? undefined,
                  lastName: values.lastName ?? undefined,
                },
              })
            })}
            description="We recommend using the name that's shown on your ID."
            label="Your name"
            displayValue={`
              ${forms.name.getValues("firstName")}
              ${forms.name.getValues("lastName")}
            `}
            values={["firstName", "lastName"]}
          >
            <div className="grid lg:grid-cols-2 gap-4">
              <Form.Field
                control={forms.name.control}
                name="firstName"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>First name</Form.Label>
                    <Form.Control>
                      <Input {...field} />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <Form.Field
                control={forms.name.control}
                name="lastName"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control>
                      <Input {...field} />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
            </div>
          </FormFieldWrapper>
        </Form.Root>
        <Form.Root {...forms.displayName}>
          <FormFieldWrapper<ProfileFormValues>
            onSubmit={forms.displayName.handleSubmit(async values => {
              await updateUserProfile({
                variables: {
                  displayName: values.displayName ?? undefined,
                },
              })
            })}
            label="Display name"
            displayValue={forms.displayName.getValues("displayName")}
            values={["displayName"]}
          >
            <Form.Field
              control={forms.displayName.control}
              name="displayName"
              render={({ field }) => (
                <Form.Item>
                  <Form.Label>Display name</Form.Label>
                  <Form.Control>
                    <Input {...field} />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
          </FormFieldWrapper>
        </Form.Root>
        <Form.Root {...forms.phoneNumber}>
          <FormFieldWrapper<ProfileFormValues>
            onSubmit={forms.phoneNumber.handleSubmit(async values => {
              const prefix = getCountryCallingCode(values.phoneCountryCode)
              if (
                !validatePhoneNumberWrapper(
                  values.phoneCountryCode,
                  values.phoneNumber
                )
              ) {
                return
              }

              const phoneNumber = values.phoneNumber
                ? `${prefix}${values.phoneNumber}`
                : ""

              await updateUserProfile({
                variables: {
                  phone: phoneNumber ?? undefined,
                },
              })
            })}
            label="Phone Number"
            displayValue={
              forms.phoneNumber.getValues("phoneNumber")
                ? `+${getCountryCallingCode(forms.phoneNumber.getValues("phoneCountryCode"))} ${forms.phoneNumber.getValues("phoneNumber")}`
                : ""
            }
            values={["phoneNumber"]}
          >
            <div className="flex flex-col gap-2">
              <Label className="font-medium" bold={false} htmlFor="phoneNumber">
                Phone Number
              </Label>
              <div className="flex gap-2">
                <Form.Field
                  control={forms.phoneNumber.control}
                  name="phoneCountryCode"
                  render={({ field }) => (
                    <Form.Item>
                      <Form.Control>
                        <Select.Root
                          defaultValue={field.value}
                          onValueChange={field.onChange}
                          required
                        >
                          <Select.Trigger>
                            <Select.Value placeholder="Select country code" />
                          </Select.Trigger>
                          <Select.Content>
                            {phoneCountryCodes.map(code => {
                              return (
                                <Select.Item
                                  key={code.countryCode}
                                  value={code.countryCode}
                                >
                                  +{code.phonePrefix}
                                </Select.Item>
                              )
                            })}
                          </Select.Content>
                        </Select.Root>
                      </Form.Control>
                      <Form.Message />
                    </Form.Item>
                  )}
                />
                <div className="grow">
                  <Form.Field
                    control={forms.phoneNumber.control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <Form.Item>
                        <Form.Control>
                          <Input {...field} />
                        </Form.Control>
                        <Form.Message />
                      </Form.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </FormFieldWrapper>
        </Form.Root>
        <Form.Root {...forms.pronouns}>
          <FormFieldWrapper<ProfileFormValues>
            onSubmit={forms.pronouns.handleSubmit(async values => {
              await updateUserProfile({
                variables: {
                  pronouns: values.pronouns ?? undefined,
                },
              })
            })}
            label="Pronouns"
            description="So we know how to address you"
            displayValue={forms.pronouns.getValues("pronouns")}
            values={["pronouns"]}
          >
            <Form.Field
              control={forms.pronouns.control}
              name="pronouns"
              render={({ field }) => (
                <Form.Item>
                  <Form.Label>Pronouns</Form.Label>
                  <Form.Control>
                    <Input {...field} />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
          </FormFieldWrapper>
        </Form.Root>
        <Form.Root {...forms.dateOfBirth}>
          <FormFieldWrapper<ProfileFormValues>
            onSubmit={forms.dateOfBirth.handleSubmit(async values => {
              const dateOfBirth = values.dateOfBirth.join("-")

              if (!validateDateOfBirthWrapper(dateOfBirth)) {
                return
              }

              await updateUserProfile({
                variables: {
                  dateOfBirth: dateOfBirth ?? undefined,
                },
              })
            })}
            description="So we can verify your age"
            label="Date of birth"
            displayValue={parsedDateOfBirth ?? ""}
            values={["dateOfBirth"]}
          >
            <div className="grid lg:grid-cols-3 gap-4">
              <Form.Field
                control={forms.dateOfBirth.control}
                name="dateOfBirth.2"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>Day</Form.Label>
                    <Form.Control>
                      <Input
                        placeholder="DD"
                        type="number"
                        required
                        min="1"
                        max="31"
                        {...field}
                        {...forms.dateOfBirth.register("dateOfBirth.2", {
                          required: true,
                        })}
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <Form.Field
                control={forms.dateOfBirth.control}
                name="dateOfBirth.1"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>Month</Form.Label>
                    <Form.Control>
                      <Select.Root
                        defaultValue={field.value}
                        onValueChange={field.onChange}
                        required
                      >
                        <Select.Trigger>
                          <Select.Value placeholder="Select month" />
                        </Select.Trigger>
                        <Select.Content>
                          {Object.values(monthMap).map(details => (
                            <Select.Item
                              key={details.label}
                              value={details.displayValue}
                            >
                              {details.label}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <Form.Field
                control={forms.dateOfBirth.control}
                name="dateOfBirth.0"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>Year</Form.Label>
                    <Form.Control>
                      <Input
                        {...field}
                        placeholder="YYYY"
                        type="number"
                        required
                        {...forms.dateOfBirth.register("dateOfBirth.0", {
                          required: true,
                        })}
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <div className="col-span-3">
                <Form.Field
                  control={forms.dateOfBirth.control}
                  name="dateOfBirth"
                  render={() => (
                    <Form.Item>
                      <Form.Message />
                    </Form.Item>
                  )}
                />
              </div>
            </div>
          </FormFieldWrapper>
        </Form.Root>
      </div>
    </div>
  )
}
