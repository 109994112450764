import { gql, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import { STGeneralError } from "supertokens-website/utils/error"
import { Helmet } from "react-helmet-async"
import { Carousel, CarouselItem, H3, P } from "@spillchat/puddles"
import { AnimatePresence, motion } from "framer-motion"

import { useAuth } from "common/context/authContext"
import { NotFound404Page } from "common/components/NotFound404Page"
import { UserSignUpForm } from "features/auth/components/UserSignUpForm"
import usingDesktop from "common/assets/images/product/using-desktop.jpg"
import bakerPerson from "common/assets/images/product/baker-person.jpg"
import happyLaptop from "common/assets/images/product/happy-laptop.jpg"
import { OTPInput } from "common/components/OTPInput"
import { LogoWordmark } from "common/components/logo/LogoWordmark"

import { InvalidUserInvitePage } from "./InvalidUserInvitePage"

import type { FunctionComponent, PropsWithChildren } from "react"
import type {
  UserSignUpFormSignUpUserMutationVariables as SignUpUserVariables,
  UserInviteLandingPageValidateInviteCodeQuery as QueryData,
  UserInviteLandingPageValidateInviteCodeQueryVariables as QueryVariables,
} from "types/graphql"

const items: CarouselItem[] = [
  {
    title: (
      <>
        &ldquo;Exactly what I needed. I think this service is essential to the
        wellbeing of people at this&nbsp;company.&rdquo;
      </>
    ),
    subtitle: <>Employee at Moonpig</>,
    imageAlt: "A text representation of the image",
    image: usingDesktop,
  },
  {
    title: (
      <>
        &ldquo;I feel a weight has been lifted off my shoulders after
        every&nbsp;session.&rdquo;,
      </>
    ),
    subtitle: "Employee at Juro",
    imageAlt: "A text representation of the image",
    image: bakerPerson,
  },
  {
    title: (
      <>
        &ldquo;Having the ability to book a session when you’re finding things
        difficult is really&nbsp;empowering.&rdquo;
      </>
    ),
    subtitle: "Employee at Williamjoseph",
    imageAlt: "A text representation of the image",
    image: happyLaptop,
  },
]

const SignupTransition: FunctionComponent<PropsWithChildren> = props => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 10 }}
    transition={{ delayChildren: 0.5, bounceDamping: 10 }}
  >
    {props.children}
  </motion.div>
)

const queries = {
  validateInviteCode: gql`
    query UserInviteLandingPageValidateInviteCode($inviteCode: String!) {
      isValidUserInviteCode(inviteCode: $inviteCode)
    }
  `,
}

export const UserInviteLandingPage: FunctionComponent = () => {
  const { hasInitialOTPBeenSent, isUserLoading } = useAuth()

  const [searchParams] = useSearchParams()
  const inviteCode = searchParams.get("code")

  const [showOTPInputPage, setShowOTPInputPage] = useState(false)
  const [email, setEmail] = useState<string>()

  const { data, loading: isInviteCodeValidLoading } = useQuery<
    QueryData,
    QueryVariables
  >(queries.validateInviteCode, {
    variables: { inviteCode: inviteCode ?? "" },
  })

  useEffect(() => {
    if (isUserLoading) return
    void hasInitialOTPBeenSent().then(res => {
      if (res) setShowOTPInputPage(true)
    })
  }, [isUserLoading])

  const navigate = useNavigate()

  // If `code` query param missing redirect to home (/)
  if (inviteCode === null) return <NotFound404Page />

  if (isInviteCodeValidLoading) return null

  if (data?.isValidUserInviteCode === false) return <InvalidUserInvitePage />

  const onSubmit = (values: SignUpUserVariables) => {
    try {
      // send OTP
      setEmail(values.email)
      setShowOTPInputPage(true)
    } catch (err) {
      if (STGeneralError.isThisError(err)) {
        // this may be a custom error message sent from gateway,
        // or if the email is not valid.
      }
    }
  }

  return (
    <section className="h-screen">
      <Helmet title="Invite | Spill" />
      <div className="grid grid-cols-1 lg:grid-cols-3 p-8 h-full">
        {/* Left column */}
        <div className="hidden lg:inline-flex col-span-2 order-2 lg:order-1 p-4">
          <Carousel items={items} autoPlay={true} ariaLabel="Spill perks" />
        </div>

        {/* Right column */}
        <div className="flex flex-col col-span-1 order-1 lg:order-2 justify-center p-4 gap-8 max-w-sm mx-auto lg:mx-0">
          <LogoWordmark />

          <AnimatePresence mode="wait">
            <Routes>
              <Route
                path="/"
                element={
                  showOTPInputPage && email !== undefined ? (
                    <SignupTransition key="otp">
                      <div className="flex flex-col gap-4">
                        <H3>Check your email for a code</H3>
                        <P>
                          We've sent a 9-character code to {email}. The code
                          expires shortly so please enter it soon.
                        </P>
                        <OTPInput
                          align="left"
                          email={email}
                          onOTPSuccess={() => navigate("/")}
                          onOTPFailure={() => setShowOTPInputPage(false)}
                        />
                      </div>
                    </SignupTransition>
                  ) : (
                    <SignupTransition key="signUp">
                      <UserSignUpForm
                        inviteCode={inviteCode}
                        onSubmit={values => onSubmit(values)}
                        title=""
                      />
                    </SignupTransition>
                  )
                }
              />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
    </section>
  )
}
