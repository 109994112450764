import { gql } from "@apollo/client"

export const queries = {
  getChannels: gql`
    query InviteSlackGetChannels {
      user {
        id
        company {
          platforms {
            name
            platformType
            avatarUrl
            groups {
              name
            }
          }
        }
      }
    }
  `,
}
