import { Button, Form, H1, H4, InputStepper } from "@spillchat/puddles"
import { useEffect } from "react"

import { useAnalytics } from "common/context/analyticsContext"
import { MultiStepFormStepProps } from "common/components/MultiStepForm/MultiStepFormStepProps"

import { QuoteSignupForm } from "./QuoteSignup.schema"

export const QuoteSignupTeam: React.FunctionComponent<
  MultiStepFormStepProps<QuoteSignupForm>
> = ({ form, reverseAction }: MultiStepFormStepProps<QuoteSignupForm>) => {
  const { track } = useAnalytics()

  useEffect(() => {
    form.clearErrors("numberEmployees")
  }, [])

  return (
    <div className="flex flex-col items-center gap-8">
      <H1>How many employees work at your company in&nbsp;total?</H1>
      <H4>
        We'll use this to estimate the cost of a more generalised plan for your
        business
      </H4>
      <div className="flex flex-col items-center gap-4">
        <Form.Field
          control={form.control}
          name="numberEmployees"
          render={({ field }) => (
            <Form.Item className="flex flex-col items-center">
              <Form.Control>
                <InputStepper
                  value={field.value.toString()}
                  onStepperChange={value => {
                    form.clearErrors("numberEmployees")
                    track("Quote Signup Employee Count Change", { value })
                    form.setValue("numberEmployees", parseInt(value))
                  }}
                  {...form.register("numberEmployees", {
                    setValueAs: (value: string) => {
                      form.clearErrors("numberEmployees")
                      if (isNaN(parseInt(value))) {
                        return ""
                      } else {
                        return parseInt(value)
                      }
                    },
                  })}
                />
              </Form.Control>
              <Form.Message className="!mt-0" />
            </Form.Item>
          )}
        />
        <div className="flex gap-2">
          <Button type="button" variant="secondary" onClick={reverseAction}>
            Back
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={form.formState.isSubmitting}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}
