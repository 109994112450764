/**
 * A component that displays three insights about the health of a company.
 * Will display the insights within cards, with the actual insight being
 * the card's title
 */
import { motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { random, sample } from "lodash"
import { format } from "date-fns"
import { gql, useQuery } from "@apollo/client"
import cn from "classnames"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Square2StackIcon,
} from "@heroicons/react/24/outline"

import { FEELINGS } from "features/pulse/constants/emotions"
import { useAnalytics } from "common/context/analyticsContext"

import type { ComponentProps } from "react"
import type {
  KeyInsightTrendsGetCompanyPulseStatsQuery as QueryData,
  KeyInsightTrendsGetCompanyPulseStatsQueryVariables as QueryVariables,
} from "types/graphql"

const fragments = {
  companyInsights: gql`
    fragment KeyInsightTrendsCompanyPulseStatsFields on Company {
      id
      currentPulseStats {
        sharePercentRank
        strugglingEmployeesAverage
        feelingRanks {
          rank
          feeling
          rankedCount
          percentRank
        }
      }
    }
  `,
}

//  < 0.2 ||  > 0.8

const queries = {
  getCompanyPulseStats: gql`
    query KeyInsightTrendsGetCompanyPulseStats {
      company {
        ...KeyInsightTrendsCompanyPulseStatsFields
      }
    }
    ${fragments.companyInsights}
  `,
}

// We get the type for the InsightList based on the response type from the query.
// It's safe to assume that [0] is accessible as the query will always return this shape.

type KeyInsightList = QueryData["company"]["currentPulseStats"]["feelingRanks"]

type KeyInsightTrendsProps = {
  className?: ComponentProps<"div">["className"]
}

export const KeyInsightTrends = ({
  className,
}: KeyInsightTrendsProps): JSX.Element | null => {
  const { data } = useQuery<QueryData, QueryVariables>(
    queries.getCompanyPulseStats,
    { fetchPolicy: "cache-first" }
  )
  const cardContainerRef = useRef<HTMLUListElement>(null)
  const [initialInsightList, setInitialInsightList] = useState<KeyInsightList>(
    []
  )
  const [availableInsights, setAvailableInsights] = useState<KeyInsightList>([])
  const [paginationDirection, setPaginationDirection] = useState<
    "prev" | "next" | null
  >(null)
  const { track } = useAnalytics()

  const POSITIVE_TERMS = [
    "Lovely stuff.",
    "Super!",
    "Fantastic!",
    "Great news.",
  ]
  const latestAvailablePulseStat = data?.company.currentPulseStats
  const previousMonth = new Date().setDate(0)

  useEffect(() => {
    if (
      latestAvailablePulseStat &&
      latestAvailablePulseStat.feelingRanks != null
    ) {
      /**
       * We remove any pulse stats that are not in a percentile extremity
       * Essentially, anything that isn't <= 0.2, or >= 0.8
       */
      const filteredRanks = latestAvailablePulseStat.feelingRanks.filter(
        feelingRank =>
          feelingRank.percentRank <= 0.2 || feelingRank.percentRank >= 0.8
      )

      /**
       * We then sort the filteredRanks so that we sort it by how close the
       * percent rank value is to either 0, or 1.
       */
      const sortedRanks = filteredRanks.sort((comparedTo, compare) => {
        const baselineA = compare.percentRank <= 0.2 ? 0 : 1
        const baselineB = comparedTo.percentRank <= 0.2 ? 0 : 1

        const differenceA = Math.abs(baselineA - compare.percentRank)
        const differenceB = Math.abs(baselineB - comparedTo.percentRank)

        return differenceA > differenceB
          ? -1
          : differenceA < differenceB
            ? 1
            : 0
      })

      /**
       * We only want the top three, so we splice the list of sorted
       * available insights.
       */
      const topInsights = sortedRanks.splice(0, 3)

      setAvailableInsights(topInsights)
      setInitialInsightList([...topInsights])
    }
  }, [latestAvailablePulseStat])

  /**
   * Moves the passed array index parameter to the start or end of array
   */
  const moveIndex = (index: number, direction: "start" | "end") => {
    // Get value of index
    const value = availableInsights[index]

    if (value != null) {
      // Remove value from array
      const newArray = availableInsights.filter((_, i) => i !== index)

      // Add value to start or end of array depending on direction prop
      if (direction === "start") {
        newArray.unshift(value)
      } else {
        newArray.push(value)
      }
      track("Admin Viewed key insight trend", newArray[0])
      setAvailableInsights(newArray)
    }
  }

  return (
    <div
      className={cn(
        "cursor-default flex flex-col gap-lg overflow-visible w-full min-h-[375px] min-w-[355px]",
        className
      )}
    >
      <header>
        <h4>Key Insights for {format(previousMonth, "MMMM")}</h4>
      </header>

      <div
        className={cn("sm:aspect-golden flex justify-around w-full", {
          "aspect-square": data && availableInsights.length > 0,
        })}
      >
        {availableInsights.length > 0 && (
          <motion.button
            className="hidden sm:block self-center z-10"
            whileTap={{ scale: 0.9 }}
            onPointerDown={() => setPaginationDirection("prev")}
            onPointerLeave={() => setPaginationDirection(null)}
            onClick={() => {
              moveIndex(availableInsights.length - 1, "start")
              setPaginationDirection(null)
            }}
          >
            <ChevronLeftIcon className="fill-grey-400 h-16 lg:h-20 p-4 w-16 lg:w-20" />
          </motion.button>
        )}

        {!data || availableInsights.length === 0 ? (
          <div className="flex h-full flex-col items-center justify-around relative w-full sm:w-auto text-center">
            <Square2StackIcon className="text-grey-200 h-48 w-48" />
            <div className="italic">
              <p>
                Your team submitted fewer than 30 Check-in responses last month.
              </p>
              <p>That means we can’t show any insights here yet.</p>
            </div>
          </div>
        ) : (
          <motion.ul
            className="aspect-square flex h-full justify-center relative w-full sm:w-auto"
            ref={cardContainerRef}
          >
            {availableInsights.length > 0 &&
              availableInsights.map((insight, index) => {
                // We only want to make the first card draggable
                const isDraggable = index === 0
                // We want to create a stacked card effect, so we randomise the rotation
                // for the cards.
                const MIN_CARD_ROTATION = -10
                const MAX_CARD_ROATATION = 10
                const insightConnotation = FEELINGS[insight.feeling].connotation
                const positiveTerm = sample(POSITIVE_TERMS)

                /**
                 * We figure out if the insight is good or bad by looking at the connotation
                 * If it's in the bottom percentile, and is a positive connoatation it's a bad insight,
                 * if it's in the top percentile and is a negative connotation it's a good insight.
                 */
                const isGood =
                  (insightConnotation === "positive" &&
                    insight.percentRank <= 0.2) ||
                  (insightConnotation === "negative" &&
                    insight.percentRank >= 0.8)

                return (
                  <motion.li
                    key={insight.feeling}
                    animate={{
                      left:
                        index === 0 && paginationDirection === "next"
                          ? 90
                          : index === 0 && paginationDirection === "prev"
                            ? -90
                            : 0,
                      scale: 1 - index * 0.02,
                      opacity: 1 - index * 0.025,
                      zIndex: availableInsights.length - index,
                      rotate:
                        index === 0
                          ? 0
                          : random(MAX_CARD_ROATATION, MIN_CARD_ROTATION),
                    }}
                    className="absolute aspect-square box-light cursor-grab flex flex-col h-full items-center justify-center list-none select-none shadow-md min-w-[275px] min-h-[275px]"
                    drag={isDraggable ? "x" : false}
                    dragConstraints={cardContainerRef}
                    dragSnapToOrigin
                    onDragEnd={(_, info) => {
                      // If card is going to the left, we move it go backwards
                      if (info.offset.x < 0) {
                        moveIndex(availableInsights.length - 1, "start")
                      } else {
                        moveIndex(index, "end")
                      }
                    }}
                  >
                    <header className="flex w-full">
                      <div
                        className={cn("-rotate-6 font-bold", {
                          "badge-primary": isGood,
                          "badge-danger": !isGood,
                          "badge-warning": insightConnotation === "neutral",
                        })}
                      >
                        {insight.percentRank <= 0.2 ? "High" : "Low"}{" "}
                        {insight.feeling}
                      </div>
                    </header>

                    <div className="grow flex flex-col font-bold gap-md md:gap-xs items-center justify-center leading-none text-center text-grey-600">
                      <span className="font-display text-4xl">
                        {insight.percentRank <= 0.2
                          ? "Top"
                          : insight.percentRank >= 0.8
                            ? "Bottom"
                            : ""}
                      </span>
                      <span
                        className={cn("font-display text-7xl", {
                          "text-teal-600": isGood,
                          "text-red-400": !isGood,
                          "text-amber-300": insightConnotation === "neutral",
                        })}
                      >
                        20%
                      </span>
                      <span className="font-display text-4xl">
                        for {insight.feeling}
                      </span>
                      <span className="mt-4">
                        across all Spill companies
                        {isGood ? `. ${positiveTerm ?? "Lovely stuff."}` : "."}
                      </span>
                    </div>
                  </motion.li>
                )
              })}
          </motion.ul>
        )}

        {availableInsights.length > 0 && (
          <motion.button
            className="hidden sm:block self-center z-10"
            whileTap={{ scale: 0.9 }}
            onPointerDown={() => setPaginationDirection("next")}
            onPointerLeave={() => setPaginationDirection(null)}
            onClick={() => {
              moveIndex(0, "end")
              setPaginationDirection(null)
            }}
          >
            <ChevronRightIcon className="text-grey-400 h-16 lg:h-20 p-4 w-16 lg:w-20" />
          </motion.button>
        )}
      </div>

      {initialInsightList.length > 0 && (
        <div className="flex w-full justify-center">
          {
            // Render dots for each card
            initialInsightList.map((_, index) => {
              const isSelected =
                availableInsights[0]?.feeling ===
                initialInsightList[index]?.feeling
              return (
                <div
                  key={index}
                  className={cn(
                    "bg-grey-200 h-2 inline-block m-1 mr-1 rounded-full w-2",
                    { "bg-grey-400": isSelected }
                  )}
                />
              )
            })
          }
        </div>
      )}
    </div>
  )
}

KeyInsightTrends.fragments = fragments
KeyInsightTrends.queries = queries
