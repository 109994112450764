import { FunctionComponent, useEffect } from "react"
import { gql } from "@apollo/client"
import { useFormContext } from "react-hook-form"
import {
  isValidPhoneNumber,
  CountryCode,
  getCountryCallingCode,
} from "libphonenumber-js"
import { P, Button, Select } from "@spillchat/puddles"

import { SPILL_EXTERNAL_LINKS } from "common/constants"
import { useAnalytics } from "common/context/analyticsContext"
import { useElementBreakpoint } from "common/hooks/useElementBreakpoint"
import { Checkbox } from "common/components/FormElements/Checkbox"
import { TextInput } from "common/components/FormElements/TextInput"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { AppointmentDetailsFormQueryFieldsFragment } from "types/graphql"
import { Label } from "common/components/FormElements/Label"
import { ErrorText } from "common/components/FormElements/ErrorText"
import { phoneCountryCodes } from "common/helpers/phone-country-codes"

import { validateDateOfBirth } from "../../../common/helpers/validateDateOfBirth"

export const fragments = {
  queryFields: gql`
    fragment AppointmentDetailsFormQueryFields on Query {
      user {
        id
        firstName
        lastName
        primaryEmail
        therapyProfile {
          approximateLocation
          dateOfBirth
          email
          emergencyContactName
          emergencyContactNumber
          firstName
          lastName
          phoneNumber
          pronouns
        }
      }
    }
  `,
}

interface AppointmentDetailsFormProps {
  data?: AppointmentDetailsFormQueryFieldsFragment
  onSubmit?: () => void
}

export const AppointmentDetailsForm: FunctionComponent<
  AppointmentDetailsFormProps
> = props => {
  const { track } = useAnalytics()
  const [ref] = useElementBreakpoint<HTMLFormElement, 500 | 800>([500, 800])

  const {
    formState,
    getFieldState,
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useFormContext<Pick<FormValues, "userInfo">>()

  // Track fields changed by user
  const isDirtyEmail = getFieldState("userInfo.email", formState).isDirty
  const isDirtyEmergencyContactName = getFieldState(
    "userInfo.emergencyContactName",
    formState
  ).isDirty
  const isDirtyEmergencyContactPhone = getFieldState(
    "userInfo.emergencyContactPhone",
    formState
  ).isDirty
  const isDirtyDayOfBirth = getFieldState(
    "userInfo.dayOfBirth",
    formState
  ).isDirty
  const isDirtyMonthOfBirth = getFieldState(
    "userInfo.monthOfBirth",
    formState
  ).isDirty
  const isDirtyYearOfBirth = getFieldState(
    "userInfo.yearOfBirth",
    formState
  ).isDirty
  const isDirtyFirstName = getFieldState(
    "userInfo.firstName",
    formState
  ).isDirty
  const isDirtyLastName = getFieldState("userInfo.lastName", formState).isDirty
  const isDirtyLocation = getFieldState("userInfo.location", formState).isDirty
  const isDirtyPhone = getFieldState("userInfo.phone", formState).isDirty
  const isDirtyPronouns = getFieldState("userInfo.pronouns", formState).isDirty

  useEffect(() => {
    if (isDirtyEmail) track("User changes personal details", { Field: "Email" })
  }, [isDirtyEmail])

  useEffect(() => {
    if (isDirtyEmergencyContactName) {
      track("User changes personal details", {
        Field: "Emergency contact name",
      })
    }
  }, [isDirtyEmergencyContactName])

  useEffect(() => {
    if (isDirtyEmergencyContactPhone) {
      track("User changes personal details", {
        Field: "Emergency contact phone",
      })
    }
  }, [isDirtyEmergencyContactPhone])

  useEffect(() => {
    if (isDirtyDayOfBirth) {
      track("User changes personal details", { Field: "Day of birth" })
    }
  }, [isDirtyDayOfBirth])

  useEffect(() => {
    if (isDirtyMonthOfBirth) {
      track("User changes personal details", { Field: "Month of birth" })
    }
  }, [isDirtyMonthOfBirth])

  useEffect(() => {
    if (isDirtyYearOfBirth) {
      track("User changes personal details", { Field: "Year of birth" })
    }
  }, [isDirtyYearOfBirth])

  useEffect(() => {
    if (isDirtyFirstName) {
      track("User changes personal details", { Field: "First name" })
    }
  }, [isDirtyFirstName])

  useEffect(() => {
    if (isDirtyLastName) {
      track("User changes personal details", { Field: "Last name" })
    }
  }, [isDirtyLastName])

  useEffect(() => {
    if (isDirtyLocation) {
      track("User changes personal details", { Field: "Location" })
    }
  }, [isDirtyLocation])

  useEffect(() => {
    if (isDirtyPhone) track("User changes personal details", { Field: "Phone" })
  }, [isDirtyPhone])

  useEffect(() => {
    if (isDirtyPronouns) {
      track("User changes personal details", { Field: "Pronouns" })
    }
  }, [isDirtyPronouns])

  const validateDateOfBirthWrapper = (dateOfBirth: string) => {
    clearErrors("root.dateOfBirth")
    const dateOfBirthValidity = validateDateOfBirth(dateOfBirth)
    if (dateOfBirthValidity.error) {
      setError("root.dateOfBirth", {
        message: dateOfBirthValidity.error,
      })
    }
    return dateOfBirthValidity.valid
  }

  const validatePhoneNumber = (
    contactNumber: string,
    defaultCountry: CountryCode
  ) => {
    const defaultCode = getCountryCallingCode(defaultCountry ?? "GB")
    return isValidPhoneNumber(defaultCode + contactNumber, {
      defaultCountry,
    })
  }

  return (
    <form
      className="flex flex-col gap-8"
      onSubmit={async e => {
        e.preventDefault()
        const formValid = await trigger("userInfo", { shouldFocus: true })

        // validate date of birth
        const dateOfBirth = getValues([
          "userInfo.yearOfBirth",
          "userInfo.monthOfBirth",
          "userInfo.dayOfBirth",
        ]).join("-")

        const dateOfBirthValid = validateDateOfBirthWrapper(dateOfBirth)

        // validate phone number
        const phoneInput = getValues("userInfo.phone")
        const phoneValid =
          phoneInput != undefined
            ? validatePhoneNumber(
                phoneInput,
                getValues("userInfo.phoneCountryCode")
              )
            : false

        // validate emergency phone number
        const emergencyPhoneInput = getValues("userInfo.emergencyContactPhone")
        const emergencyPhoneValid =
          emergencyPhoneInput != undefined
            ? validatePhoneNumber(
                emergencyPhoneInput,
                getValues("userInfo.emergencyCountryCode")
              )
            : false

        if (!emergencyPhoneValid) {
          setError("userInfo.emergencyContactPhone", {
            type: "custom",
            message: "Emergency contact number is invalid",
          })
        }

        if (!phoneValid) {
          setError("userInfo.phone", {
            type: "custom",
            message: "Phone number is invalid",
          })
        }

        if (
          formValid &&
          dateOfBirthValid &&
          phoneValid &&
          emergencyPhoneValid
        ) {
          props.onSubmit?.()
        }
      }}
      ref={ref}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <P weight="medium">Your personal details</P>
          <P size="xs">You&apos;ll only need to fill these out once</P>
        </div>
        <div className="flex flex-wrap gap-4">
          <AppointmentDetailsFormField>
            <TextInput
              label="First name*"
              name="userInfo.firstName"
              register={register}
              registerOptions={{ required: "First name is required" }}
            />
          </AppointmentDetailsFormField>
          <AppointmentDetailsFormField>
            <TextInput
              label="Last name*"
              name="userInfo.lastName"
              register={register}
              registerOptions={{ required: "Last name is required" }}
            />
          </AppointmentDetailsFormField>
          <AppointmentDetailsFormField>
            <TextInput
              label="Pronouns"
              name="userInfo.pronouns"
              register={register}
            />
          </AppointmentDetailsFormField>
          <AppointmentDetailsFormField>
            <div className="flex flex-col gap-1 w-full">
              <Label tooltip="Used rarely, but in cases where we need to refer you to another organisation or in the case of an emergency.">
                Date of birth*
              </Label>
              <div className="flex gap-2">
                <TextInput
                  name="userInfo.dayOfBirth"
                  placeholder="DD"
                  type="number"
                  registerOptions={{
                    required: "Required",
                    onChange: () => {
                      clearErrors("root.dateOfBirth")
                    },
                    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                      if (event.target.value.length === 1) {
                        setValue(
                          "userInfo.dayOfBirth",
                          event.target.value.padStart(2, "0")
                        )
                      }
                    },
                  }}
                  register={register}
                />
                <TextInput
                  name="userInfo.monthOfBirth"
                  placeholder="MM"
                  type="number"
                  registerOptions={{
                    required: "Required",
                    onChange: () => {
                      clearErrors("root.dateOfBirth")
                    },
                    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                      if (event.target.value.length === 1) {
                        setValue(
                          "userInfo.monthOfBirth",
                          event.target.value.padStart(2, "0")
                        )
                      }
                    },
                  }}
                  register={register}
                />
                <TextInput
                  name="userInfo.yearOfBirth"
                  placeholder="YYYY"
                  type="number"
                  registerOptions={{
                    required: "Required",
                    onChange: () => {
                      clearErrors("root.dateOfBirth")
                    },
                    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                      if (event.target.value.length === 2) {
                        setValue(
                          "userInfo.yearOfBirth",
                          event.target.value.padStart(
                            4,
                            // Figure out the most sensible guess for birth year century
                            Math.floor(
                              new Date().getFullYear() -
                                parseInt(event.target.value)
                            ).toString()
                          )
                        )
                      }
                    },
                  }}
                  register={register}
                />
              </div>
              <ErrorText name="root.dateOfBirth" />
            </div>
          </AppointmentDetailsFormField>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <P weight="medium">Clinical information</P>
          <P size="xs">
            We need this just in case we need to get in touch if we’re worried
            about you. Read more in our boundaries{" "}
            <a
              className="underline"
              href={SPILL_EXTERNAL_LINKS.BOUNDARIES.link}
              rel="noreferrer"
              target="blank"
            >
              here
            </a>
            .
          </P>
        </div>
        <div className="flex flex-wrap gap-4">
          <AppointmentDetailsFormField>
            <TextInput
              autoComplete="postal-code"
              label="Rough location i.e. postcode*"
              name="userInfo.location"
              register={register}
              registerOptions={{ required: "Location is required" }}
              tooltip="Used rarely, but in an emergency it's important for Spill to know which regional services to contact."
            />
          </AppointmentDetailsFormField>
          <AppointmentDetailsFormField>
            <div className="flex flex-col gap-1">
              <Label htmlFor="userInfo.phone">Phone*</Label>
              <div className="flex gap-2">
                <div className="flex">
                  <Select.Root
                    onValueChange={(event: CountryCode) => {
                      return setValue("userInfo.phoneCountryCode", event)
                    }}
                    defaultValue={getValues("userInfo.phoneCountryCode")}
                  >
                    <Select.Trigger size={undefined}>
                      <Select.Value placeholder="Select country code" />
                    </Select.Trigger>
                    <Select.Content>
                      {phoneCountryCodes.map(code => {
                        return (
                          <Select.Item
                            key={code.countryCode}
                            value={code.countryCode}
                          >
                            +{code.phonePrefix}
                          </Select.Item>
                        )
                      })}
                    </Select.Content>
                  </Select.Root>
                </div>
                <div className="grow">
                  <TextInput
                    name="userInfo.phone"
                    register={register}
                    registerOptions={{
                      required: "Phone number is required",
                    }}
                    tooltip="Another way for your therapist to contact you should video conferencing not be an option."
                  />
                </div>
              </div>
            </div>
          </AppointmentDetailsFormField>
          <AppointmentDetailsFormField>
            <TextInput
              label="Emergency contact name*"
              name="userInfo.emergencyContactName"
              register={register}
              registerOptions={{ required: "Emergency contact is required" }}
              tooltip="It's important in the case of an emergency that Spill has someone you have selected to contact."
            />
          </AppointmentDetailsFormField>
          <AppointmentDetailsFormField>
            <div className="flex flex-col gap-1">
              <Label htmlFor="userInfo.emergencyContactPhone">
                Emergency contact number*
              </Label>
              <div className="flex gap-2">
                <div className="flex">
                  <Select.Root
                    onValueChange={(event: CountryCode) => {
                      return setValue("userInfo.emergencyCountryCode", event)
                    }}
                    defaultValue={getValues("userInfo.emergencyCountryCode")}
                  >
                    <Select.Trigger size={undefined}>
                      <Select.Value placeholder="Select country code" />
                    </Select.Trigger>
                    <Select.Content>
                      {phoneCountryCodes.map(code => {
                        return (
                          <Select.Item
                            key={code.countryCode}
                            value={code.countryCode}
                          >
                            +{code.phonePrefix}
                          </Select.Item>
                        )
                      })}
                    </Select.Content>
                  </Select.Root>
                </div>
                <div className="grow">
                  <TextInput
                    name="userInfo.emergencyContactPhone"
                    register={register}
                    registerOptions={{
                      required: "Emergency contact is required",
                    }}
                    tooltip="It's important in the case of an emergency that Spill has someone you have selected to contact."
                  />
                </div>
              </div>
            </div>
          </AppointmentDetailsFormField>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <P weight="medium">Personal email access</P>
          <P size="xs">
            You’ll be able to log in to your sessions using this email. We will
            also send session reminders here.
          </P>
        </div>
        <TextInput
          label="Personal email address*"
          name="userInfo.email"
          type="email"
          register={register}
          registerOptions={{ required: "Email is required" }}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <P weight="medium">Boundaries and cancellation</P>
          <Checkbox
            id="boundaries"
            name="userInfo.boundaries"
            label={
              <span className="font-normal">
                I’ve read and agreed to the{" "}
                <a
                  className="underline"
                  href={SPILL_EXTERNAL_LINKS.BOUNDARIES.link}
                  rel="noreferrer"
                  target="blank"
                >
                  boundaries
                </a>
                *
              </span>
            }
            register={register}
            registerOptions={{ required: "You must agree to the boundaries" }}
          />
          <Checkbox
            id="notice"
            name="userInfo.notice"
            label={
              <span className="font-normal">
                I agree to give at least 24 hours notice if I can’t attend*
              </span>
            }
            register={register}
            registerOptions={{
              required:
                "You must agree to give 24 hours notice if you can't attend",
            }}
          />
        </div>
      </div>

      <Button type="submit" variant="primary">
        Next
      </Button>
    </form>
  )
}

const AppointmentDetailsFormField: React.FC<{ children?: JSX.Element }> = ({
  children,
}: {
  children?: JSX.Element
}) => {
  return (
    <div className="w-full lg:w-[calc(50%-theme('spacing.2'))]">{children}</div>
  )
}
