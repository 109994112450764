import { useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Alert,
  Button,
  Form,
  H2,
  Input,
  Label,
  P,
  SelectableCard,
  Stepper,
  Tabs,
  CopyField,
  TextArea,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { XMarkIcon, LinkIcon } from "@heroicons/react/24/outline"
import { toast } from "sonner"

import {
  formatEmailParameters,
  useSendEmail,
} from "common/hooks/api/useSendEmail"
import {
  InvitePeopleGetUserQuery,
  InvitePeopleCreateUserInviteMutation,
  InvitePeopleCreateUserInviteMutationVariables,
  InvitePeopleGiftTherapyExtensionMutation,
  InvitePeopleGiftTherapyExtensionMutationVariables,
  SpillSubscriptionPlan,
  CompanyOnboardingStep,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useOnboarding } from "common/context/onboardingContext"

import { mutations } from "../components/InvitePeople/InvitePeople.mutations"
import { queries } from "../components/InvitePeople/InvitePeople.queries"
import { formSchema } from "../components/InvitePeople/InvitePeople.schema"

import type { InvitePeopleForm } from "../components/InvitePeople/InvitePeople.schema"

export const InvitePeople: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { markAsComplete, stepsProgress } = useOnboarding()
  const [step, setStep] = useState(0)

  const { data: userData, loading: userLoading } =
    useQuery<InvitePeopleGetUserQuery>(queries.getUser, {
      fetchPolicy: "cache-first",
    })

  const requiresPayment = userData?.user?.company?.subscriptionStatus === null
  const subscriptionPlan = userData?.user?.company?.subscriptionPlan
  const isStarterPlan = subscriptionPlan === SpillSubscriptionPlan.STARTER

  const form = useForm<InvitePeopleForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      sessionCount: "4",
      notificationType: "link",
      email: "",
      note: "",
    },
  })

  const [
    createUserInvite,
    { data: createUserInviteData, loading: createUserInviteLoading },
  ] = useMutation<
    InvitePeopleCreateUserInviteMutation,
    InvitePeopleCreateUserInviteMutationVariables
  >(mutations.createUserInvite)

  const [giftTherapyExtension, { loading: giftTherapyExtensionLoading }] =
    useMutation<
      InvitePeopleGiftTherapyExtensionMutation,
      InvitePeopleGiftTherapyExtensionMutationVariables
    >(mutations.giftTherapyExtension)

  const [emailUser, emailUserResult] = useSendEmail()

  if (isUserLoading || userLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const sendTestEmail = async () => {
    const primaryEmail = userData?.user?.primaryEmail

    if (primaryEmail != undefined && primaryEmail !== "") {
      await emailUser({
        variables: {
          emails: [primaryEmail],
          templateName:
            form.getValues("note") !== undefined &&
            form.getValues("note") !== ""
              ? "welcome-gift-with-message"
              : "welcome-gift-without-message",
          parameters: formatEmailParameters({
            message: form.getValues("note"),
          }),
        },
      })

      return toast.info(`An email has been sent to ${primaryEmail ?? "you"}`)
    }

    return toast.error("Unable to find your email")
  }

  const handleRecipient = async () => {
    const valid = await form.trigger(["firstName", "lastName"], {
      shouldFocus: true,
    })

    if (valid) {
      if (isStarterPlan) {
        setStep(1)
      } else {
        const firstName = form.getValues("firstName")
        const lastName = form.getValues("lastName")
        await createUserInvite({
          variables: {
            firstName,
            lastName,
          },
        })
        setStep(2)
      }
    }
  }

  const handleSubmit = async (values: InvitePeopleForm) => {
    if (values.notificationType === "email" && values.email === "") {
      toast.error("Please enter an email address")
      return
    }

    const invite = await createUserInvite({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
      },
    })

    await giftTherapyExtension({
      variables: {
        gift: {
          noteFromAdmin: values.note ?? "",
          numberOfSessions: parseInt(values.sessionCount),
          userId: invite?.data?.createUserInvite.userId ?? "",
        },
      },
    })

    if (values.notificationType === "email") {
      await emailUser({
        variables: {
          emails: [values.email],
          templateName:
            values.note !== undefined && values.note !== ""
              ? "welcome-gift-with-message"
              : "welcome-gift-without-message",
          parameters: formatEmailParameters({
            message: form.getValues("note"),
            inviteUrl: invite?.data?.createUserInvite.inviteUrl,
          }),
        },
      })
    }

    setStep(2)

    if (!stepsProgress.hasAddedTeam && markAsComplete) {
      await markAsComplete(CompanyOnboardingStep.ADDED_TEAM)
    }
  }

  const newUser = createUserInviteData?.createUserInvite
  const inviteUrlInputValue = requiresPayment
    ? "https://adsf.spill.chat/asfa?invite=asfhafsdkfh"
    : (newUser?.inviteUrl ?? "")
  const isLoading =
    createUserInviteLoading ||
    giftTherapyExtensionLoading ||
    emailUserResult.loading

  const goBack = () => {
    if (location.key === "default") {
      navigate("/admin")
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <Helmet title="Invite a person | Spill" />

      <div className="bg-mono-white z-20 overflow-hidden">
        <div className="absolute top-6 right-6 z-20 bg-mono-white rounded-full">
          <span className="cursor-pointer">
            <XMarkIcon className="w-8 h-8" onClick={() => goBack()} />
          </span>
        </div>
        <div className="absolute top-0 left-0 py-24 px-6 md:pl-[16.5rem] lg:px-12 lg:pl-72 overflow-scroll w-full h-full">
          <div className="max-w-screen-sm mx-auto w-full">
            <div className="flex justify-center mb-12 pointer-events-none">
              <Stepper
                currentStep={Number(step)}
                setStep={value => setStep(value)}
                steps={[
                  {
                    icon: "User",
                    title: "Recipient",
                  },
                  {
                    icon: "Cog",
                    title: "Configure",
                  },
                  {
                    icon: "Check",
                    title: "Access",
                  },
                ]}
                orientation="horizontal"
              />
            </div>
            <Tabs.Root defaultValue={step.toString()} value={step.toString()}>
              <Form.Root {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  <Tabs.Content value="0">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <H2>Who would you like to provide support to?</H2>
                        <P>
                          Add the name of the employee who would benefit from
                          support on Spill. You can add more people later if you
                          need to.
                        </P>
                      </div>
                      <div className="grid lg:grid-cols-2 gap-4">
                        <Form.Field
                          control={form.control}
                          name="firstName"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <Input
                                  {...field}
                                  onKeyDown={async e => {
                                    if (e.key === "Enter") {
                                      e.preventDefault()
                                      await handleRecipient()
                                    }
                                  }}
                                  label={{ children: "First name" }}
                                />
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                        <Form.Field
                          control={form.control}
                          name="lastName"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <Input
                                  {...field}
                                  onKeyDown={async e => {
                                    if (e.key === "Enter") {
                                      e.preventDefault()
                                      await handleRecipient()
                                    }
                                  }}
                                  label={{ children: "Last name" }}
                                />
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                      </div>
                      <Button
                        onClick={async () => await handleRecipient()}
                        variant="primary"
                      >
                        Next
                      </Button>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="1">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-2">
                        <H2>Configure their support</H2>
                        <P>
                          Configure the settings for the support you're sending
                        </P>
                      </div>
                      <div className="flex flex-col gap-2">
                        <Label>
                          After an initial consultation, how many sessions can
                          they have?
                        </Label>
                        <Form.Field
                          control={form.control}
                          name="sessionCount"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <div className="grid lg:grid-cols-3 gap-4">
                                  {[
                                    {
                                      value: "4",
                                    },
                                    {
                                      value: "6",
                                    },
                                    {
                                      value: "8",
                                    },
                                  ].map(item => {
                                    return (
                                      <div key={item.value}>
                                        <SelectableCard
                                          size="sm"
                                          checked={field.value === item.value}
                                          title={`${item.value} sessions`}
                                          subtitle={""}
                                          type="radio"
                                          value={item.value}
                                          {...form.register("sessionCount")}
                                          onClick={() => {
                                            form.setValue(
                                              "sessionCount",
                                              item.value as "4" | "6" | "8"
                                            )
                                          }}
                                        />
                                      </div>
                                    )
                                  })}
                                </div>
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <Label>
                          How would you like to notify them about access to
                          Spill?
                        </Label>
                        <div>
                          <Form.Field
                            control={form.control}
                            name="notificationType"
                            render={({ field }) => (
                              <Form.Item>
                                <Form.Control>
                                  <div className="grid lg:grid-cols-3 gap-4">
                                    {[
                                      {
                                        title: "Access link",
                                        subtitle: "You share a link with them",
                                        value: "link",
                                      },
                                      {
                                        title: "Email notification",
                                        subtitle: "Spill will email them",
                                        value: "email",
                                      },
                                    ].map(item => {
                                      return (
                                        <div key={item.value}>
                                          <SelectableCard
                                            size="sm"
                                            checked={field.value === item.value}
                                            title={item.title}
                                            subtitle={item.subtitle}
                                            type="radio"
                                            {...form.register(
                                              "notificationType"
                                            )}
                                            value={item.value}
                                            onClick={() => {
                                              form.setValue(
                                                "notificationType",
                                                item.value as "email" | "link"
                                              )
                                            }}
                                          />
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Form.Control>
                                <Form.Message />
                              </Form.Item>
                            )}
                          />
                        </div>
                      </div>
                      {form.getValues("notificationType") === "email" && (
                        <>
                          <Form.Field
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                              <Form.Item>
                                <Form.Control>
                                  <Input
                                    placeholder="mail@example.com"
                                    {...field}
                                    label={{
                                      children:
                                        "What's the best email to send their invite to?",
                                    }}
                                  />
                                </Form.Control>
                                <Form.Message />
                              </Form.Item>
                            )}
                          />
                          <Form.Field
                            control={form.control}
                            name="note"
                            render={({ field }) => (
                              <Form.Item>
                                <Form.Control>
                                  <TextArea
                                    placeholder="Write text here..."
                                    className="h-48"
                                    {...field}
                                    label={{
                                      children:
                                        "Add a note to their welcome email (optional)",
                                    }}
                                  />
                                </Form.Control>
                                <Form.Message />
                                <div
                                  className="underline cursor-pointer"
                                  onKeyDown={async e => {
                                    if (e.key === "Enter") {
                                      await sendTestEmail()
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                  onClick={async () => await sendTestEmail()}
                                >
                                  <P>
                                    Send a test email to yourself to see what it
                                    looks like
                                  </P>
                                </div>
                              </Form.Item>
                            )}
                          />
                        </>
                      )}
                      <div className="flex items-center gap-4">
                        <Button
                          onClick={() => setStep(step - 1)}
                          variant="secondary"
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          loading={isLoading}
                        >
                          Create invite
                        </Button>
                      </div>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="2">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <H2>{newUser?.firstName ?? ""}'s account is set up</H2>
                        {requiresPayment && (
                          <Link to={"/admin/billing"}>
                            <Alert
                              title="Add payment details in order to send access"
                              variant="warning"
                            >
                              <P size="xs">
                                In order to send access to anyone you first need
                                to add your payment details.
                              </P>
                            </Alert>
                          </Link>
                        )}
                        {form.getValues("notificationType") === "link" ? (
                          <P>
                            We've configured the support for{" "}
                            {`${newUser?.firstName ?? ""} ${newUser?.lastName ?? ""}`}
                            . Here's their access link:
                          </P>
                        ) : (
                          <>
                            <P>
                              An invite has been sent to{" "}
                              {form.getValues("email")} with a link to sign in.
                            </P>
                            <P>
                              You can find the link again at anytime on the
                              Support page.
                            </P>
                          </>
                        )}
                      </div>
                      {form.getValues("notificationType") === "link" && (
                        <div className="flex flex-col gap-2">
                          <div>
                            <CopyField
                              copyValue={inviteUrlInputValue}
                              disabled={requiresPayment}
                              onCopied={() =>
                                toast.success("Invite link copied!")
                              }
                              icon={<LinkIcon />}
                            />
                          </div>

                          <P muted size="xs">
                            You can find this anytime on the Therapy tab
                          </P>
                        </div>
                      )}
                      <Button variant="secondary" asChild>
                        {requiresPayment ? (
                          <Link to="/admin/billing">Go to Billing</Link>
                        ) : (
                          <Link to="/admin/therapy">Go to Support</Link>
                        )}
                      </Button>
                    </div>
                  </Tabs.Content>
                </form>
              </Form.Root>
            </Tabs.Root>
          </div>
        </div>
      </div>
    </>
  )
}
