/**
 * Typeguard that checks if a value is of a given enum type.
 * @see https://stackoverflow.com/a/58278753
 */
export const isEnum = <T extends object>(
  value: unknown,
  enumeration: T
): value is T[keyof T] => {
  return Object.values(enumeration).includes(value as T[keyof T])
}
