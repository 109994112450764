import { Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { NotFound404Page } from "common/components/NotFound404Page"
import { RESOURCES_FEATURES } from "common/constants/features"

export function ResourcesRouter(): JSX.Element {
  return (
    <Routes>
      {Object.values(RESOURCES_FEATURES).map(feature => (
        <Route
          key={feature.title}
          path={feature.path}
          element={
            <>
              <Helmet title={`Spill | ${feature.title}`} />
              <feature.component />
            </>
          }
        />
      ))}
      <Route path="*" element={<NotFound404Page />} />
    </Routes>
  )
}
