import {
  ApolloClient,
  FieldFunctionOptions,
  FieldPolicy,
  HttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
  Reference,
  from,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { print } from "graphql/language/printer"
import * as Sentry from "@sentry/react"
import {
  addMonths,
  isAfter,
  isBefore,
  parseISO,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns"
import { toast } from "sonner"

import { config, environment } from "config"
import { mergeArrayByField } from "common/helpers/mergeArrayByField"
import { ObjToRecord } from "types/helpers"
import {
  BenchmarkGroupPulseResponseAggregatesByMonth,
  CompanyBenchmarkGroupPulseResponseAggregatesByMonthArgs,
  CompanyPulseResponseAggregatesByMonth,
  CompanyPulseResponseAggregatesByMonthArgs,
  CrisisLine,
  FeelingAggregates,
  PulseResponse,
  QueryCrisisLineArgs,
  QueryPersonalResponsesArgs,
  QueryPulseResponseArgs,
  QueryUserByIdArgs,
  SharedPulseResponse,
  User,
} from "types/graphql"

import introspectionResult from "./graphql/introspection"

/**
 * Configure connection to GraphQL API
 * @see {@link https://www.apollographql.com/docs/react/api/link/apollo-link-http}
 */
const httpLink = new HttpLink({
  uri: `${config.spill.gateway.baseUrl}/graphql`,
  credentials: "include",
})

/**
 * Handle GraphQL and network errors
 * @see {@link https://www.apollographql.com/docs/react/api/link/apollo-link-error}
 */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const context = {
    contexts: {
      GraphQL: {
        "Operation Name": operation.operationName,
        Query: print(operation.query),
      },
    },
  }

  const showToastIfSessionsPageError = () => {
    if (window.location.pathname.includes("/therapy/sessions")) {
      toast.error(
        'An error occured! If you\'re trying to join a therapy session, please follow the "Get help" link in your email.',
        {
          duration: 10000,
        }
      )
    }
  }

  if (networkError) {
    Sentry.captureException(networkError, context)
    showToastIfSessionsPageError()
    // eslint-disable-next-line no-console
    console.error("Apollo: Network error", networkError)
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      Sentry.captureException(new Error(graphQLError.message), context)
      showToastIfSessionsPageError()
      // eslint-disable-next-line no-console
      console.error(`Apollo: GraphQL error`, graphQLError)
    })
  }
})

/**
 * Cache field policies
 * @see {@link https://www.apollographql.com/docs/react/caching/cache-field-behavior}
 */
const benchmarkGroupPulseResponseAggregatesByMonth: FieldPolicy<
  readonly BenchmarkGroupPulseResponseAggregatesByMonth[],
  readonly BenchmarkGroupPulseResponseAggregatesByMonth[],
  readonly BenchmarkGroupPulseResponseAggregatesByMonth[],
  FieldFunctionOptions<
    ObjToRecord<CompanyBenchmarkGroupPulseResponseAggregatesByMonthArgs>
  >
> = {
  keyArgs: false,
  merge: mergeArrayByField("month"),
  // Could do with extracting this into single function as used in 2 places
  read(existing = [], { args, readField }) {
    if (!args) return existing
    if (existing === null) return []
    return existing.filter(ref => {
      const month = readField<string>("month", ref)
      if (month === undefined) return false
      const isSameAsOrAfterFromMonth = isAfter(
        new Date(month),
        startOfMonth(subMonths(new Date(args.fromMonth), 1))
      )
      const isSameAsOrBeforeToMonth = isBefore(
        new Date(month),
        startOfMonth(addMonths(new Date(args.toMonth), 1))
      )
      return isSameAsOrAfterFromMonth && isSameAsOrBeforeToMonth
    })
  },
}
const crisisLine: FieldPolicy<
  CrisisLine,
  CrisisLine,
  Reference,
  FieldFunctionOptions<ObjToRecord<QueryCrisisLineArgs>>
> = {
  // See https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
  read(_, { args, toReference }) {
    if (!args || args["id"] === undefined) return undefined
    return toReference({
      __typename: "CrisisLine",
      id: args["id"],
    })
  },
}
const feelingAggregates: FieldPolicy<readonly FeelingAggregates[]> = {
  merge: mergeArrayByField("month"),
}
const personalResponses: FieldPolicy<
  readonly PulseResponse[],
  readonly PulseResponse[],
  readonly PulseResponse[],
  FieldFunctionOptions<ObjToRecord<QueryPersonalResponsesArgs>>
> = {
  keyArgs: false,
  read(existing = [], { args, readField }) {
    if (!args) return existing
    if (existing === null) return []
    return existing.filter(ref => {
      const createdAt = readField<string>("createdAt", ref)
      if (createdAt === undefined) return false
      // Return any PulseResponses created after today - args.days
      return isAfter(parseISO(createdAt), subDays(new Date(), args.days))
    })
  },
}
const pulseResponse: FieldPolicy<
  SharedPulseResponse,
  SharedPulseResponse,
  Reference,
  FieldFunctionOptions<ObjToRecord<QueryPulseResponseArgs>>
> = {
  // See https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
  read(_, { args, toReference }) {
    if (!args || args["id"] === undefined) return undefined
    return toReference({
      __typename: "SharedPulseResponse",
      id: args["id"],
    })
  },
}
const pulseResponseAggregatesByMonth: FieldPolicy<
  readonly CompanyPulseResponseAggregatesByMonth[],
  readonly CompanyPulseResponseAggregatesByMonth[],
  readonly CompanyPulseResponseAggregatesByMonth[],
  FieldFunctionOptions<ObjToRecord<CompanyPulseResponseAggregatesByMonthArgs>>
> = {
  keyArgs: false,
  merge: mergeArrayByField("month"),
  // Could do with extracting this into single function as used in 2 places
  read(existing = [], { args, readField }) {
    if (!args) return existing
    if (existing === null) return []
    return existing.filter(ref => {
      const month = readField<string>("month", ref)
      if (month === undefined) return false
      const isSameAsOrAfterFromMonth = isAfter(
        new Date(month),
        startOfMonth(subMonths(new Date(args.fromMonth), 1))
      )
      const isSameAsOrBeforeToMonth = isBefore(
        new Date(month),
        startOfMonth(addMonths(new Date(args.toMonth), 1))
      )
      return isSameAsOrAfterFromMonth && isSameAsOrBeforeToMonth
    })
  },
}
const userById: FieldPolicy<
  User,
  User,
  Reference,
  FieldFunctionOptions<ObjToRecord<QueryUserByIdArgs>>
> = {
  // See https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
  read(_, { args, toReference }) {
    if (!args || args["id"] === undefined) return undefined
    return toReference({
      __typename: "User",
      id: args["id"],
    })
  },
}

/**
 * Cache config
 * @see {@link https://www.apollographql.com/docs/react/caching/cache-configuration#configuration-options}
 */
export const inMemoryCacheConfig: InMemoryCacheConfig = {
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    AvailabilitySlot: {
      keyFields: false,
    },
    Company: {
      fields: {
        benchmarkGroupPulseResponseAggregatesByMonth,
        pulseResponseAggregatesByMonth,
      },
    },
    CompanyPulseResponseAggregatesByMonth: {
      fields: {
        feelingAggregates,
      },
    },
    CompanyUsage: {
      merge: true,
    },
    Meeting: {
      keyFields: ["urlId"],
    },
    Query: {
      fields: {
        crisisLine,
        personalResponses,
        pulseResponse,
        userById,
      },
    },
    UpcomingUsage: {
      merge: true,
    },
    UsageRollup: {
      merge: true,
    },
    Workspace: {
      keyFields: ["externalId"],
    },
  },
}

/**
 * Init Apollo Client
 * @see {@link https://www.apollographql.com/docs/react/api/core/ApolloClient}
 */

export const apolloClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(inMemoryCacheConfig),
  // Connect to dev tools when in development or staging
  connectToDevTools: import.meta.env.DEV || environment === "staging",
})
