
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "PulseResponse": [
      "PulseResponseScoreAndFeelings",
      "PulseResponseSharedAndUnshared"
    ],
    "PulseResponseCommon": [
      "PulseResponseScoreAndFeelings",
      "PulseResponseSharedAndUnshared"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "PulseResponse": [
      "PulseResponseScoreAndFeelings",
      "PulseResponseSharedAndUnshared"
    ],
    "PulseResponseCommon": [
      "PulseResponseScoreAndFeelings",
      "PulseResponseSharedAndUnshared"
    ]
  }
};
      export default result;
    