import { gql } from "@apollo/client"

export const queries = {
  getUserList: gql`
    query InviteBereavementUserList {
      user {
        primaryEmail
        company {
          users {
            id
            fullName
            accountStatus
            platformUsers {
              id
              email
            }
          }
          pendingUsers {
            id
            email
            name
          }
          featuresAndSettings {
            userTherapyCap {
              active
              value
            }
          }
        }
      }
    }
  `,
}
