import { MutationTuple, useMutation } from "@apollo/client"
import { gql } from "@apollo/client"

import {
  KeyValuePair,
  SendEmailsMutation,
  SendEmailsMutationVariables,
} from "types/graphql"

export const mutations = {
  sendEmail: gql(`
    mutation SendEmails(
      $emails: [String!]!
      $templateName: String!
      $parameters: [KeyValuePair!]!
    ) {
      sendTemplatedUserEmailsWithMessage(
        emails: $emails
        templateName: $templateName
        parameters: $parameters
      )
    }
  `),
}

export const formatEmailParameters = (
  parameters: Record<string, string | number | null | undefined>
): KeyValuePair[] =>
  Object.entries(parameters).flatMap(([key, value]) => {
    if (value == null || value == "") {
      return []
    }
    return { key, value: String(value) }
  })

export const useSendEmail = (): MutationTuple<
  SendEmailsMutation,
  SendEmailsMutationVariables
> => {
  return useMutation<SendEmailsMutation, SendEmailsMutationVariables>(
    mutations.sendEmail
  )
}
