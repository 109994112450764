import { gql } from "@apollo/client"

export const mutations = {
  createTherapyPackage: gql(`
    mutation InviteParenthoodCreateTherapyPackage(
      $adminNote: String
      $expirationDate: DateTime!
      $numberOfSessions: Float!
      $packageType: PackageType!
      $userIds: [ID!]!
    ) {
      createTherapyPackages(
        adminNote: $adminNote
        expirationDate: $expirationDate
        numberOfSessions: $numberOfSessions
        packageType: $packageType
        userIds: $userIds
      ) {
        id
      }
    }
  `),
  makeAppUser: gql`
    mutation PeopleListItemMakeAppUser($platformUserId: ID!) {
      makeAppUserForPlatformUser(platformUserId: $platformUserId) {
        id
        user {
          id
        }
      }
    }
  `,
}
