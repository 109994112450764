import { Navigate, Route, Routes } from "react-router-dom"
import { FunctionComponent, useRef } from "react"
import { Helmet } from "react-helmet-async"
import { useQuery, gql } from "@apollo/client"
import { isAfter, parseISO, subMinutes } from "date-fns"
import { z } from "zod"
import { captureMessage } from "@sentry/react"
import { PACKAGE_FIELDS } from "graphql/fragments/therapy-packages.fragment"

import { NotFoundPage } from "common/components/NotFoundPage"
import { SpillMessagePage } from "features/messages/pages/SpillMessagePage"
import { ConditionalRedirect } from "common/components/ConditionalRedirect"
import {
  PackageType,
  TherapyAccessReason,
  TherapyRouterGetDataQuery,
  TherapyRouterGetDataQueryVariables,
} from "types/graphql"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"
import { TherapySpecialisedSupportPage } from "features/therapy-specialised-support/therapy-specialised-support.page"
import { TherapySpecialisedSupportPackagePage } from "features/therapy-specialised-support-package/therapy-specialised-support-package.page"
import { TherapySpecialisedSupportRequest } from "features/therapy-specialised-support-request/therapy-specialised-support-request.page"

import { BereavementPage } from "../admin/bereavement/bereavement.page"
import { TherapyAdhdPage } from "../therapy-adhd/therapy-adhd.page"
import { ParenthoodPage } from "../admin/parenthood/parenthood.page"

import { AppointmentBookingPage } from "./pages/AppointmentBookingPage"
import { AppointmentReschedulePage } from "./pages/AppointmentReschedulePage"
import { AskATherapistPage } from "./pages/AskATherapistPage"
import { SessionsPage } from "./pages/SessionsPage/index"
import { TherapyExtensionRequest } from "./pages/TherapyExtensionRequest"
import { Private } from "./pages/Private"
import { PlusOnePage } from "./pages/PlusOne"
import { PmiPage } from "./pages/PmiPage"

export const queries = {
  getData: gql`
    query TherapyRouterGetData($filter: UserAppointmentsFilter) {
      user {
        id
        accountStatus
        featuresAndSettings {
          plusOne {
            value
          }
          pmi {
            value
          }
        }
        accessToTherapyFeatures {
          askATherapist {
            hasAccess
            reason
          }
          consultations {
            hasAccess
            reason
          }
          oneOffs {
            hasAccess
            reason
          }
          private {
            hasAccess
            reason
          }
        }
        appointments(filter: $filter) {
          id
          startsAt
          status
          counsellor {
            id
            calendarId
          }
        }
        company {
          subscriptionStatus
          id
        }
        ...PackageFields
      }
    }
    ${PACKAGE_FIELDS}
  `,
}

export const TherapyRouter: FunctionComponent = () => {
  const queryAppointmentsFrom = useRef(subMinutes(new Date(), 50))
  const { flags } = useUser()
  const { data, loading } = useQuery<
    TherapyRouterGetDataQuery,
    TherapyRouterGetDataQueryVariables
  >(queries.getData, {
    variables: {
      filter: { from: queryAppointmentsFrom.current.toISOString() },
    },
  })

  const upcomingAppointments = (data?.user?.appointments ?? []).filter(a =>
    isAfter(parseISO(a.startsAt), queryAppointmentsFrom.current)
  )
  const hasUpcomingAppointment = upcomingAppointments.length > 0

  const therapyPackages = data?.user?.therapyPackages
  const bereavementPackage = therapyPackages?.find(
    packageDetails =>
      packageDetails.identifier === PackageType.BEREAVEMENT.toString()
  )

  const parenthoodPackage = therapyPackages?.find(
    packageDetails =>
      packageDetails.identifier === PackageType.PARENTHOOD.toString()
  )
  const hasBereavement = bereavementPackage !== undefined
  const hasParenthood = parenthoodPackage !== undefined
  const hasAdhdSupport = data?.user?.featuresAndSettings.adhdSupport.value
  const accessRulesSchema = z.object({
    accessToTherapyFeatures: z.object({
      askATherapist: z.array(
        z.object({
          hasAccess: z.boolean(),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
      consultations: z.array(
        z.object({
          hasAccess: z.boolean(),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
      oneOffs: z.array(
        z.object({
          hasAccess: z.boolean(),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
      private: z.array(
        z.object({
          hasAccess: z.boolean(),
          reason: z.nativeEnum(TherapyAccessReason),
        })
      ),
    }),
    company: z.object({
      subscriptionStatus: z.nullable(z.string()),
    }),
  })
  const sessionBookingRules = accessRulesSchema.safeParse(data?.user)

  /**
   * We need to check if the user has access to the session booking page
   * If something doesn't happen to work correctly, we optimistically allow access
   */
  const canAccessSessionBooking = () => {
    // Something went wrong with our understanding if the user has access. Let's assume they do.
    if (!sessionBookingRules.success) {
      captureMessage(
        `Failed to parse session booking rules for user ${
          data?.user?.id ?? ""
        }: ${sessionBookingRules.error.errors.map(e => e.message).join(", ")}`
      )
      return true
    }

    // If the user is viewing the confirmation screen, we should allow them to see it
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("confirm") === "true") {
      return true
    }

    const constulationFeature =
      sessionBookingRules.data.accessToTherapyFeatures.consultations.at(0)
    const oneOffFeature =
      sessionBookingRules.data.accessToTherapyFeatures.oneOffs.at(0)
    const privateFeature =
      sessionBookingRules.data.accessToTherapyFeatures.private.at(0)

    if (
      constulationFeature &&
      !constulationFeature?.hasAccess &&
      oneOffFeature &&
      !oneOffFeature?.hasAccess &&
      privateFeature &&
      !privateFeature?.hasAccess
    ) {
      return false
    }

    if (hasUpcomingAppointment) {
      return false
    }

    return true
  }

  if (loading) {
    return null
  }

  return (
    <Routes>
      <Route
        path="ask-a-therapist"
        element={
          <ConditionalRedirect
            conditions={
              sessionBookingRules.success &&
              sessionBookingRules.data.accessToTherapyFeatures.askATherapist.at(
                0
              )?.hasAccess === false
            }
            to="/therapy/sessions"
          >
            <Helmet title="Ask a Therapist | Spill" />
            <AskATherapistPage />
          </ConditionalRedirect>
        }
      />
      <Route path="messages">
        <Route
          index
          element={<Navigate replace to="/therapy/ask-a-therapist" />}
        />
        <Route path=":messageId" element={<SpillMessagePage />} />
      </Route>
      <>
        <Route path="sessions">
          <Route
            index
            element={
              <>
                <Helmet title="Upcoming sessions | Spill" />
                <SessionsPage />
              </>
            }
          />
          <Route path="request" element={<TherapyExtensionRequest />} />
          <Route
            path=":appointmentId/reschedule"
            element={
              <>
                <Helmet title="Reschedule session | Spill" />
                <div className="max-w-screen-xl">
                  <AppointmentReschedulePage />
                </div>
              </>
            }
          />
        </Route>
      </>
      <Route
        path="sessions/book" // note: we require params here to take you to the right appointment type
        element={
          <ConditionalRedirect
            conditions={canAccessSessionBooking() === false}
            to="/therapy/sessions"
          >
            <Helmet title="Book a session | Spill" />
            <AppointmentBookingPage />
          </ConditionalRedirect>
        }
      />
      <Route path="sessions/private*" element={<Private />} />
      {/* Redirect legacy routes */}
      <Route
        path="book/consultation"
        element={<Navigate replace to="/therapy/sessions/" />}
      />
      <Route
        path="book/one-off"
        element={<Navigate replace to="/therapy/sessions/" />}
      />
      <Route
        path="video/book"
        element={<Navigate replace to="/therapy/sessions/" />}
      />
      <Route path="specialised-support">
        <Route index element={<TherapySpecialisedSupportPage />} />
        {hasBereavement == true && (
          <Route path="bereavement" element={<BereavementPage />} />
        )}
        {hasAdhdSupport == true && (
          <Route path="adhd" element={<TherapyAdhdPage />} />
        )}
        {hasParenthood == true && (
          <Route path="parenthood" element={<ParenthoodPage />} />
        )}
        <Route path=":packageId">
          <Route index element={<TherapySpecialisedSupportPackagePage />} />
          <Route
            path="request"
            element={<TherapySpecialisedSupportRequest />}
          />
        </Route>
      </Route>
      {data?.user?.featuresAndSettings.plusOne.value === true && (
        <Route path="plus-one" element={<PlusOnePage />} />
      )}
      {flags[FEATURE_FLAGS.PMI_INTEGRATION] === true &&
        data?.user?.featuresAndSettings.pmi.value === true && (
          <Route path="sessions/pmi" element={<PmiPage />} />
        )}
      <Route path="*" element={<NotFoundPage className="grow" />} />
    </Routes>
  )
}
