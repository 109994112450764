import { Button, Form, H1, H4, Input } from "@spillchat/puddles"
import { useEffect } from "react"

import { MultiStepFormStepProps } from "common/components/MultiStepForm/MultiStepFormStepProps"

import { QuoteSignupForm } from "./QuoteSignup.schema"

export const QuoteSignupUser: React.FunctionComponent<
  MultiStepFormStepProps<QuoteSignupForm>
> = ({ form, reverseAction }: MultiStepFormStepProps<QuoteSignupForm>) => {
  useEffect(() => {
    form.clearErrors("companyName")
    form.clearErrors("email")
  }, [])

  return (
    <div className="flex flex-col items-center gap-8">
      <H1>Great, let's get you a quote</H1>
      <H4>
        You can access your quote anytime and we can send you a copy of your
        quote over email.
      </H4>
      <div className="space-y-4 w-full max-w-sm">
        <Form.Field
          control={form.control}
          name="companyName"
          render={({ field }) => (
            <Form.Item>
              <Form.Control>
                <Input
                  {...field}
                  placeholder="Company name"
                  label={{ children: "Company name" }}
                  onChange={event => {
                    field.onChange(event)
                    form.clearErrors("companyName")
                  }}
                />
              </Form.Control>
              <Form.Message />
            </Form.Item>
          )}
        />
        <Form.Field
          control={form.control}
          name="email"
          render={({ field }) => (
            <Form.Item>
              <Form.Control>
                <Input
                  {...field}
                  placeholder="Email address"
                  label={{ children: "Email address" }}
                  onChange={event => {
                    field.onChange(event)
                    form.clearErrors("email")
                  }}
                />
              </Form.Control>
              <Form.Message />
            </Form.Item>
          )}
        />
      </div>
      <div className="flex gap-2">
        <Button type="button" variant="secondary" onClick={reverseAction}>
          Back
        </Button>
        <Button
          type="submit"
          variant="primary"
          loading={form.formState.isSubmitting}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}
