import { gql } from "@apollo/client"

export const queries = {
  getPmiSettingValueAndDetails: gql`
    query PmiSettingsGetPmiSettingValueAndDetails {
      user {
        id
        company {
          id
          featuresAndSettings {
            id
            pmi {
              active
              value
            }
            userTherapyCap {
              value
            }
          }
          pmi {
            id
            provider
            userInstructions
          }
        }
      }
    }
  `,
}
