import { gql, useMutation } from "@apollo/client"
import { FormProvider, useForm } from "react-hook-form"
import { toast } from "sonner"
import { P } from "@spillchat/puddles"

import { Dialog } from "common/components/Dialog"
import {
  UserAccountLinkingModalMakeAppUserMutation,
  UserAccountLinkingModalMakeAppUserMutationVariables,
  UserAccountLinkingModalMergeWithAppUserMutation,
  UserAccountLinkingModalMergeWithAppUserMutationVariables,
  UserAccountLinkingModalUsersForPlatformFieldsFragment,
} from "types/graphql"

import { PlatformUserOrUser } from "../AccountLinking.types"

import { SearchExternalUsersCombobox } from "./SearchExternalUsersCombobox"

import type { FunctionComponent } from "react"
import type { FieldValues } from "./UserAccountLinkingModal.types"

export const fragments = {
  usersForPlatform: gql`
    fragment UserAccountLinkingModalUsersForPlatformFields on PlatformUser {
      id
      name
      email
      user {
        id
      }
    }
  `,
}

export const mutations = {
  makeAppUser: gql`
    mutation UserAccountLinkingModalMakeAppUser($platformUserId: ID!) {
      makeAppUserForPlatformUser(platformUserId: $platformUserId) {
        id
        user {
          id
        }
      }
    }
  `,
  mergeWithAppUser: gql`
    mutation UserAccountLinkingModalMergeWithAppUser(
      $platformUserId: ID!
      $userId: ID!
    ) {
      mergePlatformUser(platformUserId: $platformUserId, userId: $userId) {
        id
        user {
          id
        }
      }
    }
  `,
}

interface UserAccountLinkingModalProps {
  pendingUsers: UserAccountLinkingModalUsersForPlatformFieldsFragment[]
  existingUser: PlatformUserOrUser
  isVisible: boolean
  onSuccessfulLink: (userId: string, platformUserId: string) => void
  onDontLink: (userId: string) => void
  newPlatformType: string
  onClose: () => void
}

export const UserAccountLinkingModal: FunctionComponent<
  UserAccountLinkingModalProps
> = props => {
  const formMethods = useForm<FieldValues>({
    defaultValues: {
      pendingUser: null,
    },
  })

  const pendingUser = formMethods.watch("pendingUser")

  const [mergeWithAppUser, { loading: mergeWithAppUserLoading }] = useMutation<
    UserAccountLinkingModalMergeWithAppUserMutation,
    UserAccountLinkingModalMergeWithAppUserMutationVariables
  >(mutations.mergeWithAppUser)

  const [makeAppUser] = useMutation<
    UserAccountLinkingModalMakeAppUserMutation,
    UserAccountLinkingModalMakeAppUserMutationVariables
  >(mutations.makeAppUser)

  return (
    <Dialog
      canClose
      isOpen={props.isVisible}
      onClose={props.onClose}
      maxWidth="2xl"
      title={`Link ${props.existingUser.displayName}${
        props.existingUser.displayName.endsWith("s") ? "'" : "'s"
      } account`}
      buttons={[
        {
          children: "Connect account",
          disabled: pendingUser == null,
          loading: mergeWithAppUserLoading,
          onClick: async () => {
            try {
              let appUserId = null
              if ("platformUserId" in props.existingUser) {
                const { data } = await makeAppUser({
                  variables: {
                    platformUserId: props.existingUser.platformUserId,
                  },
                })
                appUserId = data?.makeAppUserForPlatformUser?.user?.id
                if (appUserId == null) {
                  toast(
                    "We were unable to link the account. Please try again, or contact us at support@spill.chat"
                  )
                  return
                }
              } else {
                appUserId = props.existingUser.id
              }
              const response = await mergeWithAppUser({
                variables: {
                  userId: appUserId,
                  platformUserId: pendingUser!.id,
                },
              })
              if (response.data?.mergePlatformUser?.id !== undefined) {
                props.onSuccessfulLink(
                  "platformUserId" in props.existingUser
                    ? props.existingUser.platformUserId
                    : props.existingUser.id,
                  pendingUser!.id
                )
                props.onClose()
              }
              if (response.errors) {
                toast(
                  "We were unable to link the account. Please try again, or contact us at support@spill.chat"
                )
              }
            } catch {
              toast(
                "We were unable to link the account. Please try again, or contact us at support@spill.chat"
              )
            }
          },
        },
        {
          children: `This person is not on ${props.newPlatformType}`,
          onClick: () => {
            props.onDontLink(
              "id" in props.existingUser
                ? props.existingUser.id
                : props.existingUser.platformUserId
            )
            props.onClose()
          },
          variant: "secondary",
        },
      ]}
    >
      <FormProvider {...formMethods}>
        <>
          <div className="space-y-3">
            <h5>Search users</h5>
            <P>
              Connect this person's {props.newPlatformType} account to their
              existing one
            </P>
            <SearchExternalUsersCombobox options={props.pendingUsers} />
          </div>
        </>
      </FormProvider>
    </Dialog>
  )
}
