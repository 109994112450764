import { z } from "zod"

export const pmiRequestSchema = z.object({
  accountNumber: z.string(),
  sessionsRequested: z
    .number()
    .min(1, {
      message: "Number of sessions requested must be positive",
    })
    .max(100, {
      message: "Number of sessions requested cannot be more than 100",
    })
    .or(z.literal("")),
})

export type PmiRequest = z.infer<typeof pmiRequestSchema>
