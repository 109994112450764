import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { captureException, captureMessage } from "@sentry/react"
import { useAsync } from "react-use"
import Session from "supertokens-auth-react/recipe/session"
import {
  consumeCode,
  createCode as createOTP,
  getLoginAttemptInfo,
  setLoginAttemptInfo,
} from "supertokens-auth-react/recipe/passwordless"
import { signOut as signOutWithSupertokens } from "supertokens-auth-react/recipe/session"
import { useUnleashContext } from "@unleash/proxy-client-react"
import { apolloClient } from "apolloClient"

import { SIGN_IN_OUTCOME } from "common/constants"
import { gatewayFetch } from "common/helpers/gatewayFetch"
import { useSearchParams } from "common/helpers/useSearchParams"

import type { FunctionComponent } from "react"
import type {
  AuthContextGetCommonUserQuery as GetCommonUserQueryData,
  AuthContextGetCommonUserQueryVariables as GetCommonUserQueryVars,
  AuthContextCommonUserFieldsFragment,
  AuthContextGetUnverifiedUserQuery as GetUnverifiedUserQueryData,
  AuthContextGetUnverifiedUserQueryVariables as GetUnverifiedUserQueryVars,
  PlatformType,
} from "types/graphql"

/**
 * Constants
 */
const SEARCH_PARAMS = { MAGIC: "magic", SIGN_IN_OUTCOME: "signInOutcome" }

/**
 * Type definitions
 */
type ConsumeOTP = (args?: Parameters<typeof consumeCode>[0]) => Promise<{
  error?: string
  status?: Awaited<ReturnType<typeof consumeCode>>["status"]
}>

type CommonUser = AuthContextCommonUserFieldsFragment

type AuthContextData = {
  consumeOTP: ConsumeOTP
  createOTP: typeof createOTP
  hasInitialOTPBeenSent: () => Promise<boolean>
  setLoginAttemptInfo: typeof setLoginAttemptInfo
  signOut: () => Promise<void>
  signInOutcome: string | null
  /** a user if logged in, `undefined` if we're still determining whether they're logged in,
  `null` if they're not logged in */
  user: CommonUser | null | undefined
  isUserLoading: boolean
  unverifiedPlatformUser: {
    userId: string
    platformUserId: string
    platformUserPlatform: PlatformType
    appUserDetails: {
      id: string
      name: string
      platformType: string
    }[]
  } | null
}

type AuthContextProviderProps = {
  children: ReactNode
}

const commonCompanyFields = gql`
  fragment AuthContextCommonCompanyFields on Company {
    id
    subscriptionPlan
    isLegacyPricing
  }
`

/**
 * GraphQL fragments, queries and mutations
 */
const fragments = {
  commonCompanyFields,
  commonUserFields: gql`
    fragment AuthContextCommonUserFields on User {
      id
      role
      primaryEmail
      displayName
      revokedAt
      createdAt
      featuresAndSettings {
        id
        adviceLibrary {
          value
        }
        askATherapist {
          value
        }
        pulse {
          value
        }
      }
      company {
        ...AuthContextCommonCompanyFields
      }
    }
    ${commonCompanyFields}
  `,
}

const queries = {
  getCommonUser: gql`
    query AuthContextGetCommonUser {
      user {
        ...AuthContextCommonUserFields
      }
    }
    ${fragments.commonUserFields}
  `,
  getUnverifiedUser: gql`
    query AuthContextGetUnverifiedUser {
      unverifiedPlatformUser {
        userId
        platformUserId
        platformUserPlatform
        appUserDetails {
          id
          name
          platformType
        }
      }
    }
  `,
}

/**
 * Context initialisation
 */
const defaultAuthContextData = {
  consumeOTP: async () => await Promise.reject(new Error()),
  createOTP,
  hasInitialOTPBeenSent: async () => {
    // https://supertokens.com/docs/passwordless/custom-ui/login-otp#how-to-detect-if-the-user-is-in-step-1-or-in-step-2-state
    return (await getLoginAttemptInfo()) !== undefined
  },
  setLoginAttemptInfo,
  signOut: async () => {
    return await Promise.resolve()
  },
  signInOutcome: null,
  isUserLoading: true,
  user: null,
  unverifiedPlatformUser: null,
}

const AuthContext = createContext<AuthContextData>(defaultAuthContextData)

export function useAuth(): AuthContextData {
  return useContext(AuthContext)
}

export const AuthProvider: FunctionComponent<
  AuthContextProviderProps
> = props => {
  const [signInOutcome, setSignInOutcome] = useState<string | null>(null)
  const updateUnleashContext = useUnleashContext()

  // Session state
  const sessionContext = Session.useSessionContext()

  // Search params
  const [searchParams, { delete: deleteSearchParam }] = useSearchParams()
  const magicParam = searchParams.get(SEARCH_PARAMS.MAGIC)
  const signInOutcomeParam = searchParams.get(SEARCH_PARAMS.SIGN_IN_OUTCOME)

  // Gets common user data
  const [
    getCommonUser,
    {
      data: getCommonUserData,
      loading: getCommonUserLoading,
      refetch: refetchCommonUser,
      called: getCommonUserCalled,
    },
  ] = useLazyQuery<GetCommonUserQueryData, GetCommonUserQueryVars>(
    queries.getCommonUser,
    { fetchPolicy: "network-only" }
  )

  const [
    getUnverifiedUser,
    {
      data: getUnverifiedUserData,
      loading: getUnverifiedUserLoading,
      called: getUnverifiedUserCalled,
    },
  ] = useLazyQuery<GetUnverifiedUserQueryData, GetUnverifiedUserQueryVars>(
    queries.getUnverifiedUser,
    { fetchPolicy: "network-only" }
  )

  // Submits Supertokens one time passcode to sign in
  const consumeOTP: ConsumeOTP = async args => {
    const response = await consumeCode(args)

    switch (response.status) {
      case "OK":
        await refetchCommonUser()
        return { status: "OK" }
      case "EXPIRED_USER_INPUT_CODE_ERROR":
        return {
          status: "EXPIRED_USER_INPUT_CODE_ERROR",
          error:
            "That code has expired. Please regenerate a new one and try again.",
        }
      case "INCORRECT_USER_INPUT_CODE_ERROR": {
        const attemptsLeft =
          response.maximumCodeInputAttempts -
          response.failedCodeInputAttemptCount
        return {
          status: "INCORRECT_USER_INPUT_CODE_ERROR",
          error: `Wrong code! Please try again. Number of attempts left: ${attemptsLeft}`,
        }
      }
      case "RESTART_FLOW_ERROR":
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.
        // https://supertokens.com/docs/passwordless/custom-ui/login-otp#step-2-resending-a-new-otp
        return { status: "RESTART_FLOW_ERROR" }
      default:
        // this can happen if the user tried an incorrect OTP too many times.
        return { error: "Login failed. Please try again." }
    }
  }

  // Handle magic code search param
  useAsync(async () => {
    if (magicParam === null) return
    try {
      await gatewayFetch({
        path: "/auth/magic-jwt",
        method: "POST",
        body: { jwt: magicParam },
      })
      // handle race condition where user queries already ran
      await apolloClient.resetStore()
    } catch (err) {
      captureException(err)
    }
    deleteSearchParam(SEARCH_PARAMS.MAGIC)
  }, [magicParam])

  // Handle sign in outcome search param
  useEffect(() => {
    if (signInOutcomeParam === null) return
    setSignInOutcome(signInOutcomeParam)
    deleteSearchParam(SEARCH_PARAMS.SIGN_IN_OUTCOME)
    if (signInOutcomeParam !== SIGN_IN_OUTCOME.SUCCESS) {
      captureMessage("Slack sign in failed", {
        extra: { signInOutcome: signInOutcomeParam },
        level: "info",
      })
    }
  }, [signInOutcomeParam])

  // Stores common user (only fields that work for both)
  // Returns undefined when loading
  // Returns null if unauthenticated
  const user: CommonUser | null | undefined = getCommonUserData?.user

  // set feature flag context
  useEffect(() => {
    updateUnleashContext({
      userId: user?.id,
      properties: { companyId: user?.company?.id ?? "" },
    }).catch(err => captureException(err))
  }, [user?.id, user?.company?.id])

  // Signs user out
  const signOut = async (): Promise<void> => {
    try {
      if (window.location.pathname != "/signin") {
        await signOutWithSupertokens()
        await gatewayFetch({ method: "POST", path: "/auth/signout" })
      }
    } catch (err) {
      captureException(err)
    }
  }

  // Gets user data once Supertokens session has finished loading
  useEffect(() => {
    if (!sessionContext.loading && magicParam === null) void getCommonUser()
  }, [sessionContext, magicParam])

  useEffect(() => {
    if (
      getCommonUserCalled &&
      !getCommonUserLoading &&
      getCommonUserData?.user == null
    ) {
      void getUnverifiedUser()
    }
  }, [getCommonUserCalled, getCommonUserLoading, getCommonUserData?.user])

  useEffect(() => {
    if (
      getCommonUserCalled &&
      !getCommonUserLoading &&
      getCommonUserData?.user === null &&
      getUnverifiedUserCalled &&
      !getUnverifiedUserLoading &&
      getUnverifiedUserData?.unverifiedPlatformUser === null &&
      sessionContext.loading === false
    ) {
      void signOut()
    }
  }, [
    getCommonUserCalled,
    getCommonUserLoading,
    getCommonUserData?.user,
    getUnverifiedUserCalled,
    getUnverifiedUserLoading,
    getUnverifiedUserData?.unverifiedPlatformUser == null,
    sessionContext,
  ])

  const unverifiedPlatformUser = getUnverifiedUserData?.unverifiedPlatformUser
    ? {
        ...getUnverifiedUserData.unverifiedPlatformUser,
        platformUserPlatform: getUnverifiedUserData.unverifiedPlatformUser
          .platformUserPlatform as PlatformType,
        appUserDetails:
          getUnverifiedUserData.unverifiedPlatformUser.appUserDetails.flatMap(
            details => [
              {
                ...details,
                platformType: details.platformType as PlatformType,
              },
            ]
          ),
      }
    : null

  return (
    <AuthContext.Provider
      value={{
        ...defaultAuthContextData,
        isUserLoading: user === undefined,
        consumeOTP,
        signInOutcome,
        signOut,
        user,
        unverifiedPlatformUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
