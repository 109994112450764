import * as Sentry from "@sentry/react"
import { Button, H1, H2, H3, HeaderCard, P } from "@spillchat/puddles"
import { FacetHits, SearchClient } from "algoliasearch"
import { FunctionComponent, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useInstantSearch } from "react-instantsearch"
import { toast } from "sonner"

import ImageBereavement from "common/assets/images/product/specialised-support/therapy-bereavement.png"
import ImageParenthood from "common/assets/images/product/specialised-support/therapy-parenthood.png"
import { useAuth } from "common/context/authContext"
import { config } from "config"

import { AutocompleteSearchBox } from "../AutocompleteSearchBox"

import { SearchResultHits } from "./SearchResultHits"

type LandingPageProps = {
  searchClient: SearchClient
  showingResults: boolean
  setShowingResults: (value: boolean) => void
}

export const AdviceLibraryLanding: FunctionComponent<LandingPageProps> = ({
  searchClient,
  showingResults,
  setShowingResults,
}) => {
  const { user } = useAuth()
  const { setIndexUiState, indexUiState, refresh } = useInstantSearch()
  const [recommendedTopics, setRecommendedTopics] = useState<FacetHits[]>([])
  const [showRecommendedTopics, setShowRecommendedTopics] = useState(false)
  const isAATEnabled = user?.featuresAndSettings.askATherapist.value === true

  useEffect(() => {
    async function getFacets() {
      const { facetHits } = await searchClient.searchForFacetValues({
        indexName: config.adviceLibrary.searchIndexName,
        facetName: "tags",
        searchForFacetValuesRequest: {
          maxFacetHits: 15,
        },
      })

      setRecommendedTopics(facetHits)
    }

    getFacets().catch(error => {
      Sentry.captureException(error)
      toast.error("Unable to fetch latest questions")
    })
  }, [])

  return (
    <div className="min-h-full grid lg:grid-cols-3 lg:gap-12">
      <div className="col-span-3 lg:col-span-2">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <H1>Advice Library</H1>
              <P muted>
                Browse answers from therapists to the 200 most common questions
                about life and mental health
              </P>
            </div>
            <AutocompleteSearchBox
              onSubmit={() => setShowingResults(true)}
              searchClient={searchClient}
            />
          </div>

          <div className="flex flex-col gap-8">
            <div className="flex flex-col">
              {indexUiState.query == null && <H2>Latest</H2>}
              <SearchResultHits
                showingResults={showingResults}
                setShowingResults={setShowingResults}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 lg:col-span-1">
        <div className="flex flex-col sticky top-0">
          {isAATEnabled && (
            <div className="flex flex-col gap-4 border-b py-8">
              <div className="flex flex-col gap-4">
                <H3>Got your own questions?</H3>
                <P muted>
                  Use the Ask a Therapist feature to submit a question and one
                  of our therapists will reply within two days.
                </P>
              </div>
              <Button>
                <Link to="/therapy/ask-a-therapist">Ask a Therapist</Link>
              </Button>
            </div>
          )}
          {recommendedTopics?.length > 0 && (
            <div className="flex flex-col gap-4 border-b py-8">
              <div className="flex flex-col gap-4">
                <H3>Recommended topics</H3>
                <div className="flex flex-wrap items-center gap-2">
                  {recommendedTopics
                    .slice(0, showRecommendedTopics ? 25 : 5)
                    .map(({ count, value }, i) => {
                      return (
                        <Button
                          key={i}
                          variant={
                            indexUiState.query === value
                              ? "primary"
                              : "secondary"
                          }
                          size="sm"
                          onClick={() => {
                            const query =
                              value !== indexUiState.query ? value : ""
                            setIndexUiState({ query })
                            refresh()
                          }}
                        >
                          {value} ({count})
                        </Button>
                      )
                    })}
                </div>
                <div className="">
                  <Button
                    size="sm"
                    variant="tertiary"
                    onClick={() =>
                      setShowRecommendedTopics(!showRecommendedTopics)
                    }
                  >
                    {showRecommendedTopics ? "See less" : "See more"}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {indexUiState.query === "bereavement" && (
            <div className="flex flex-col gap-4 border-b py-8">
              <div className="flex flex-col gap-4">
                <H3>Bereavement support</H3>
                <div className="flex gap-4">
                  <div className="flex flex-col gap-4">
                    <P size="xs" muted>
                      Your company pays for specialist Bereavement support
                      on&nbsp;Spill
                    </P>
                    <Button variant="secondary">
                      <Link
                        target="_blank"
                        to="https://spill.notion.site/Supporting-bereaved-employees-9ba168e8b01a4f578765d1cbd4d97630"
                      >
                        Find out more
                      </Link>
                    </Button>
                  </div>
                  <img
                    className="w-20 h-20"
                    src={ImageBereavement}
                    alt="Someone has a Spill session using their laptop"
                  />
                </div>
              </div>
            </div>
          )}
          {indexUiState.query === "parenting" && (
            <div className="flex flex-col gap-4 border-b py-8">
              <div className="flex flex-col gap-4">
                <H3>Parenthood support</H3>
                <div className="flex gap-4">
                  <div className="flex flex-col gap-4">
                    <P size="xs" muted>
                      Your company pays for specialist Parenthood support
                      on&nbsp;Spill
                    </P>
                    <Button variant="secondary">
                      <Link
                        target="_blank"
                        to="https://spill.notion.site/Parenthood-support-with-Spill-ff79db72829f43fb964c308f4ca873d7"
                      >
                        Find out more
                      </Link>
                    </Button>
                  </div>
                  <img
                    className="w-20 h-20"
                    src={ImageParenthood}
                    alt="Someone has a Spill session using their laptop"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4 border-b py-8">
            <div className="flex flex-col gap-4">
              <H3>Still need help?</H3>
              <P muted>
                If you're still struggling with something, speaking to someone
                in private can help.
              </P>
            </div>
            <HeaderCard
              title="Book therapy"
              icon={{
                name: "User",
                type: "outline",
              }}
              description="Speak with one of Spill's counsellors"
            >
              <div className="flex gap-4">
                <Button variant="secondary" size="sm">
                  <Link to="/therapy/sessions">Go to Sessions</Link>
                </Button>
              </div>
            </HeaderCard>
          </div>
        </div>
      </div>
    </div>
  )
}
