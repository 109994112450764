import { useMutation, useQuery } from "@apollo/client"
import { Button, H1, Indicator, P } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { toast } from "sonner"
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"

import { LoadingPage } from "common/components/LoadingPage"
import { convertPlatformTypeToIndicator } from "features/admin/helpers/convertPlatformTypeToIndicator"
import {
  AccessGetUserQuery,
  AccessSendInvitesMutation,
  AccessSendInvitesMutationVariables,
  CompanyPlatform,
  PlatformType,
  SpillSubscriptionPlan,
} from "types/graphql"
import { Dialog } from "common/components/Dialog"

import { convertPlatformTypeToName } from "../admin/helpers/convertPlatformTypeToName"

import { queries } from "./access.queries"
import { AccessList } from "./components/AccessList"
import { mutations } from "./access.mutations"

export const Access: FunctionComponent = () => {
  const [showInviteConfirmation, setShowInviteConfirmation] = useState(false)

  const { data, loading } = useQuery<AccessGetUserQuery>(queries.accessGetUser)

  const [sendInvites] = useMutation<
    AccessSendInvitesMutation,
    AccessSendInvitesMutationVariables
  >(mutations.sendInvites)

  if (loading) {
    return <LoadingPage />
  }

  const platformTypes = new Set(
    data?.user?.company?.platforms.map(platform => platform.platformType)
  )

  const channels = new Set(
    data?.user?.company?.platforms
      .filter(platform => platform.groups.length > 0)
      .flatMap(platform => platform.groups.map(group => group.name))
  )

  const hasSlackOrTeams =
    platformTypes.has(PlatformType.SLACK) ||
    platformTypes.has(PlatformType.TEAMS)

  const subscriptionPlan = data?.user?.company?.subscriptionPlan

  const canCreatePlatform =
    subscriptionPlan != null
      ? [SpillSubscriptionPlan.TEAM, SpillSubscriptionPlan.ESSENTIAL].includes(
          subscriptionPlan
        )
      : false

  const companyPlatform = data?.user?.company?.platforms?.find(
    ({ platformType }) =>
      [PlatformType.SLACK, PlatformType.TEAMS].includes(platformType)
  )

  const targetCompanyPlatform =
    companyPlatform ?? (data?.user?.company?.platforms[0] as CompanyPlatform)

  const platformTypeName = convertPlatformTypeToName(
    targetCompanyPlatform.platformType
  )

  const showOnboardingInvite =
    targetCompanyPlatform.platformType !== PlatformType.EMAIL &&
    !targetCompanyPlatform.hasAnnouncedOnboarding

  return (
    <>
      <Helmet title="Access | Spill" />
      <main className="flex flex-col p-0">
        <H1>Access</H1>
        {(platformTypes.size > 0 || channels.size > 0) && (
          <div>
            <div className="grid lg:grid-cols-3 gap-4 w-full">
              {platformTypes.size > 0 && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Access methods</P>
                  <div className="flex items-center gap-2">
                    {Array.from(platformTypes.values()).map(platform => {
                      return (
                        <Indicator
                          key={platform}
                          variant={convertPlatformTypeToIndicator(platform)}
                        >
                          <span>{convertPlatformTypeToName(platform)}</span>
                        </Indicator>
                      )
                    })}
                  </div>
                  {!hasSlackOrTeams && canCreatePlatform && (
                    <Button variant="tertiary" size="sm" asChild>
                      <Link to="/admin/access/install" className="!p-0">
                        Add Slack or MS Teams access
                      </Link>
                    </Button>
                  )}
                  {subscriptionPlan === SpillSubscriptionPlan.STARTER && (
                    <Button variant="tertiary" size="sm" asChild>
                      <div className="flex items-center gap-1 !p-0">
                        <InformationCircleIcon className="w-4 h-4" />
                        <Link to="/admin/therapy" className="!p-0">
                          Go to Therapy to invite new employees
                        </Link>
                      </div>
                    </Button>
                  )}
                </div>
              )}
              {channels.size > 0 && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Channels</P>
                  <div className="flex flex-wrap items-center gap-2">
                    {Array.from(channels.values()).map(channel => {
                      return (
                        <Indicator variant="ok" key={channel}>
                          <span className="whitespace-nowrap">#{channel}</span>
                        </Indicator>
                      )
                    })}
                  </div>
                  <Button variant="tertiary" size="sm" asChild>
                    <div className="flex items-center gap-1 !p-0">
                      <InformationCircleIcon className="w-4 h-4" />
                      <Link
                        to="https://spill.notion.site/Change-the-channel-Spill-is-in-9292d7ef384e4dd689a52a77648989bb"
                        target="_blank"
                        className="!p-0"
                      >
                        Want to change the channel Spill is in?
                      </Link>
                    </div>
                  </Button>
                </div>
              )}
              {hasSlackOrTeams && channels.size == 0 && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Channels</P>
                  <div className="flex items-start gap-2">
                    <ExclamationCircleIcon className="size-4 text-yellow-400" />
                    <div className="flex flex-col gap-2">
                      <P size="xs">
                        Finish adding Spill to a channel to give
                        employees&nbsp;access.
                      </P>
                      <Button variant="tertiary" size="sm" asChild>
                        <Link
                          to={`/admin/access/install/${targetCompanyPlatform.platformType.toLowerCase()}/success?companyPlatformId=${targetCompanyPlatform.id}`}
                        >
                          Add now
                        </Link>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {showOnboardingInvite && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Onboarding notification</P>

                  <P size="xs">
                    Notify your team members that they have access to Spill
                    through {platformTypeName}
                  </P>
                  <div className="flex flex-row space-x-4 items-baseline">
                    <div className="flex">
                      <Button
                        variant="primary"
                        size="sm"
                        loading={loading}
                        onClick={() => setShowInviteConfirmation(true)}
                      >
                        Send invites via {platformTypeName}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <AccessList />
      </main>
      <Dialog
        isOpen={showInviteConfirmation}
        title="Are you sure?"
        canClose
        onClose={() => setShowInviteConfirmation(false)}
        buttons={[
          {
            key: 123,
            variant: "secondary",
            children: "Cancel",
            onClick: () => setShowInviteConfirmation(false),
          },
          {
            key: 321,
            variant: "primary",
            children: "Confirm",
            onClick: async () => {
              try {
                const response = await sendInvites({
                  variables: {
                    companyPlatformId: targetCompanyPlatform.id,
                  },
                })
                if (response.data?.broadcastOnboardingMessage !== undefined) {
                  toast.success("Onboarding messages sent")
                  setShowInviteConfirmation(false)
                }
              } catch {
                toast.error("Failed to send invites. Please try again.")
              }
            },
          },
        ]}
      >
        <P size="default">
          You&apos;re about to message every person you&apos;ve given access to.
          We're just checking you're happy to do this!
        </P>
      </Dialog>
    </>
  )
}
