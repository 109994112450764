import { FunctionComponent, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useBoolean } from "react-use"

import { useAnalytics } from "common/context/analyticsContext"
import { TextArea } from "common/components/FormElements/TextArea"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"

import { FormSection } from "./FormSection"

type Props = {
  idx: number
  question: string
  placeholder: string
}

export const ContextSection: FunctionComponent<Props> = ({
  idx,
  question,
  placeholder,
}) => {
  const { formState, getFieldState, register } = useFormContext<FormValues>()

  const isDirty = getFieldState(
    `baselineQuestions.${idx}.answer`,
    formState
  ).isDirty

  const { track } = useAnalytics()
  const [hasTracked, toggleHasTracked] = useBoolean(false)

  useEffect(() => {
    if (!hasTracked && isDirty) {
      track(
        "User enters baseline question",
        { Field: "Extra context" },
        toggleHasTracked
      )
    }
  }, [hasTracked, isDirty])

  return (
    <FormSection title={`${idx}. ${question}*`}>
      <input
        hidden
        value={"baseline"}
        {...register(`baselineQuestions.${idx}.section`)}
      />
      <input
        hidden
        value={question}
        {...register(`baselineQuestions.${idx}.question`)}
      />
      <TextArea
        name={`baselineQuestions.${idx}.answer`}
        placeholder={placeholder}
        register={register}
        registerOptions={{
          required: {
            value: true,
            message: "Required",
          },
        }}
        rows={6}
      />
    </FormSection>
  )
}
