export const SIGN_IN_OUTCOME = {
  SUCCESS: "success",
  /** The user most likely cancelled the sign in. */
  DENIED: "denied",
  /** There's no corresponding Spill user. */
  MISSING: "missing",
  /** The workspace doesn't even exist in Spill's database. */
  MISSING_WORKSPACE: "missing-workspace",
  /** The user's access has been revoked */
  NO_ACCESS: "no-access",
  /** An internal server error occurred. */
  ERROR: "error",
}
