import { gql } from "@apollo/client"

export const queries = {
  getOnboardingSteps: gql`
    query OnboardingSteps {
      user {
        id
        company {
          id
          billableUserCount
          subscriptionStatus
          subscriptionPlan
          inActiveTrialPeriod
          onboardingSteps {
            hasApprovedSettings
            hasAddedTeam
            hasSeenUserView
          }
          platforms {
            id
            hasAnnouncedOnboarding
            hasCompletedIntegration
            platformType
          }
        }
      }
    }
  `,
}
