/**
 * An animated version of the 'cloud' icon that can be found as a static SVG in
 * /src/common/assets/images/moods/5.svg
 **/

import React from "react"
import { motion, useAnimation } from "framer-motion"

import { AnimatedIconProps } from "./types"

export const Cloudy: React.FunctionComponent<AnimatedIconProps> = props => {
  const cloudAnimation = useAnimation()

  React.useEffect(() => {
    if (props.isAnimating) {
      cloudAnimation
        .start({
          y: -5,
          transition: {
            repeat: Infinity,
            repeatType: "reverse",
            duration: 1.5,
          },
        })
        .catch(() => {})
    } else {
      cloudAnimation.stop()
    }
  }, [props.isAnimating])

  return (
    <motion.svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        animate={cloudAnimation}
        d="M39.4981 37.0649H8.85816C6.6436 37.1113 4.50131 36.2761 2.90256 34.7429C1.30381 33.2098 0.379578 31.1044 0.333165 28.8899C0.286752 26.6753 1.12199 24.533 2.6551 22.9343C4.1882 21.3355 6.29363 20.4113 8.50818 20.3649C8.40036 19.744 8.34682 19.115 8.34818 18.4848C8.34501 16.0376 9.12463 13.6535 10.5731 11.681C12.0216 9.70848 14.063 8.25097 16.399 7.52146C18.735 6.79196 21.2431 6.82872 23.5567 7.62637C25.8703 8.42402 27.8681 9.94073 29.2582 11.9549C30.5581 11.2554 32.012 10.8911 33.4882 10.8949C35.8751 10.8949 38.1643 11.8431 39.8521 13.5309C41.54 15.2187 42.4882 17.5079 42.4882 19.8949C42.4878 20.2291 42.4678 20.563 42.4282 20.8949C44.2676 21.5762 45.8084 22.8834 46.7804 24.5872C47.7524 26.291 48.0936 28.2826 47.7441 30.2128C47.3945 32.143 46.3766 33.8885 44.8689 35.1432C43.3611 36.3979 41.4597 37.0818 39.4981 37.0749V37.0649ZM8.85816 22.8549C7.34718 22.9173 5.91885 23.5615 4.87183 24.6527C3.82481 25.7439 3.24018 27.1976 3.24018 28.7099C3.24018 30.2221 3.82481 31.6758 4.87183 32.767C5.91885 33.8582 7.34718 34.5024 8.85816 34.5649H39.4981C40.9386 34.5568 42.3257 34.0184 43.3944 33.0526C44.4631 32.0868 45.1387 30.7612 45.292 29.3289C45.4454 27.8966 45.0659 26.458 44.2259 25.2878C43.3859 24.1176 42.1443 23.2977 40.7382 22.9848L39.4682 22.7149L39.7882 21.4649C39.9168 20.9479 39.9839 20.4176 39.9882 19.8849C39.9855 18.1627 39.2995 16.5119 38.0808 15.2951C36.8621 14.0783 35.2104 13.3949 33.4882 13.3949C32.0878 13.3941 30.7255 13.8506 29.6082 14.6949L28.4381 15.5649L27.7481 14.2748C26.7945 12.5269 25.2881 11.1446 23.4648 10.3443C21.6415 9.54403 19.6042 9.37101 17.6721 9.85233C15.7399 10.3337 14.022 11.4421 12.7871 13.0042C11.5523 14.5663 10.8704 16.4938 10.8482 18.4848C10.8468 19.4151 10.9919 20.3398 11.2782 21.2249L11.7982 22.8549H8.85816Z"
      />
    </motion.svg>
  )
}
