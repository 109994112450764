import { gql, useQuery } from "@apollo/client"
import { Modal } from "@spillchat/puddles"

import { ConsentPage } from "features/auth/pages/ConsentPage"
import {
  SpillSubscriptionPlan,
  type RequireConsentGetUserQuery as QueryData,
  type RequireConsentGetUserQueryVariables as QueryVariables,
} from "types/graphql"
import { useModalCookieBannerAwareness } from "common/hooks/useModalCookieBannerAwareness"

import type { FunctionComponent } from "react"

export const fragments = {
  queryFields: gql`
    fragment RequireConsentQueryFields on Query {
      user {
        id
        hasAgreedToTsAndCs
        hasAgreedToHealthData
        hasAgreedToSharingPolicy
        company {
          id
          subscriptionPlan
        }
      }
    }
  `,
}

export const queries = {
  getUser: gql`
    query RequireConsentGetUser {
      ...RequireConsentQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const RequireAgreements: FunctionComponent = () => {
  useModalCookieBannerAwareness()

  const { data, loading } = useQuery<QueryData, QueryVariables>(
    queries.getUser,
    { fetchPolicy: "cache-and-network" }
  )

  if (loading) return null

  const isStarterPlan =
    data?.user?.company?.subscriptionPlan === SpillSubscriptionPlan.STARTER

  const acceptedTerms =
    data?.user?.hasAgreedToSharingPolicy === true &&
    data?.user?.hasAgreedToHealthData === true &&
    data?.user?.hasAgreedToTsAndCs === true

  const acceptedTeamTerms =
    data?.user?.hasAgreedToHealthData === true &&
    data?.user?.hasAgreedToTsAndCs === true

  if (isStarterPlan ? !acceptedTerms : !acceptedTeamTerms)
    return (
      <Modal.Root defaultOpen={true}>
        <Modal.Content
          className="md:min-w-[876px]"
          hideDefaultClose
          onInteractOutside={e => e.preventDefault()}
        >
          <ConsentPage />
        </Modal.Content>
      </Modal.Root>
    )

  return null
}
