import { isAfter, isValid, parseISO, subYears } from "date-fns"

interface DateOfBirthValididity {
  valid: boolean
  error: string
}

export const monthMap = [
  { label: "January", value: 1, displayValue: "01" },
  { label: "February", value: 2, displayValue: "02" },
  { label: "March", value: 3, displayValue: "03" },
  { label: "April", value: 4, displayValue: "04" },
  { label: "May", value: 5, displayValue: "05" },
  { label: "June", value: 6, displayValue: "06" },
  { label: "July", value: 7, displayValue: "07" },
  { label: "August", value: 8, displayValue: "08" },
  { label: "September", value: 9, displayValue: "09" },
  { label: "October", value: 10, displayValue: "10" },
  { label: "November", value: 11, displayValue: "11" },
  { label: "December", value: 12, displayValue: "12" },
]

export const validateDateOfBirth = (
  dateOfBirth: string
): DateOfBirthValididity => {
  const currentTimestamp = new Date()

  const maxDate = subYears(currentTimestamp, 18)
  const parsedDateOfBirth = parseISO(dateOfBirth)

  if (dateOfBirth.split("-").filter(Boolean).length < 3) {
    // At least one field is missing - errors will be added individually
    return {
      valid: false,
      error: "",
    }
  }

  if (!isValid(parsedDateOfBirth)) {
    return {
      valid: false,
      error: "Your date of birth is invalid. Check the format is DD-MM-YYYY",
    }
  }

  if (isAfter(parsedDateOfBirth, maxDate)) {
    return {
      valid: false,
      error: "You must be 18 or older to use this service",
    }
  }

  return {
    valid: true,
    error: "",
  }
}
