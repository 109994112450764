import { useQuery } from "@apollo/client"

import { queries } from "features/admin/components/OnboardingSteps/onboarding.queries"
import { OnboardingSteps } from "features/admin/components/OnboardingSteps"
import {
  CompanyPlatform,
  OnboardingStepsQuery,
  PlatformType,
  SpillSubscriptionPlan,
} from "types/graphql"

type UseAdminOnboardingParams = {
  subscriptionPlan: SpillSubscriptionPlan | undefined
}

export function useAdminOnboarding({
  subscriptionPlan,
}: UseAdminOnboardingParams): {
  steps: OnboardingSteps | null
  isComplete: boolean | null
  isOnboarding: boolean
  helpers?: {
    hasInvitedUsers: boolean
    hasCompletedIntegration: boolean
  }
} {
  const { data } = useQuery<OnboardingStepsQuery>(queries.getOnboardingSteps, {
    fetchPolicy: "cache-and-network",
  })

  if (
    subscriptionPlan == null ||
    subscriptionPlan === SpillSubscriptionPlan.LITE ||
    subscriptionPlan === SpillSubscriptionPlan.PROPER
  ) {
    return {
      steps: null,
      isComplete: null,
      isOnboarding: false,
    }
  }

  const hasInvitedUsers = (data?.user?.company?.billableUserCount ?? 0) > 1

  const hasCompletedIntegration =
    data?.user?.company?.platforms?.some(
      ({ platformType, hasCompletedIntegration }) => {
        return (
          hasCompletedIntegration &&
          [PlatformType.SLACK, PlatformType.TEAMS].includes(platformType)
        )
      }
    ) ?? false

  const companyPlatform = data?.user?.company?.platforms?.find(
    ({ platformType }) =>
      [PlatformType.SLACK, PlatformType.TEAMS].includes(platformType)
  )

  const targetCompanyPlatform =
    companyPlatform ?? (data?.user?.company?.platforms[0] as CompanyPlatform)

  const hasAddedTeam =
    data?.user?.company?.onboardingSteps.hasAddedTeam ?? false

  const hasAnnouncedOnboarding =
    targetCompanyPlatform?.platformType !== PlatformType.EMAIL &&
    targetCompanyPlatform?.hasAnnouncedOnboarding

  const inActiveTrialPeriod = data?.user?.company?.inActiveTrialPeriod ?? false

  const steps: OnboardingSteps = {
    [SpillSubscriptionPlan.STARTER]: [
      {
        label: "Create first account for team member",
        completed: hasAddedTeam,
        href:
          subscriptionPlan === SpillSubscriptionPlan.STARTER
            ? "/admin/access/invite"
            : "/admin/access",
      },
      {
        label: "Add your card details",
        completed: data?.user?.company?.subscriptionStatus != null,
        href: "/admin/billing",
        disabled: inActiveTrialPeriod,
      },
    ],
    [SpillSubscriptionPlan.ESSENTIAL]: [
      {
        label: "Add your card details",
        completed: data?.user?.company?.subscriptionStatus != null,
        href: "/admin/billing",
        disabled: inActiveTrialPeriod,
      },
      {
        label: "Add Spill to Slack, MS Teams or via Email",
        completed: hasInvitedUsers || hasCompletedIntegration,
        href: "/admin/access",
      },
      {
        label: "Invite your team to Spill",
        completed: hasAddedTeam || hasAnnouncedOnboarding,
        href: "/admin/access",
      },
    ],
    [SpillSubscriptionPlan.TEAM]: [
      {
        label: "Add your card details",
        completed: data?.user?.company?.subscriptionStatus != null,
        href: "/admin/billing",
        disabled: inActiveTrialPeriod,
      },
      {
        label: "Add Spill to Slack, MS Teams or via Email",
        completed: hasInvitedUsers || hasCompletedIntegration,
        href: "/admin/access",
      },
      {
        label: "Invite your team to Spill",
        completed: hasAddedTeam || hasAnnouncedOnboarding,
        href: "/admin/access",
      },
    ],
  }

  const currentStep = steps[subscriptionPlan]

  const isComplete = currentStep
    .filter(step => step.disabled !== true)
    .map(step => step.completed)
    .every(condition => condition === true)

  return {
    steps,
    isComplete,
    isOnboarding: steps != null && !isComplete,
    helpers: {
      hasInvitedUsers,
      hasCompletedIntegration,
    },
  }
}
