import { BooksAndVideos } from "features/resources/pages/BooksAndVideos"
import { CrisisLines } from "features/resources/pages/CrisisLines"
import { Exercises } from "features/resources/pages/Exercises"

type ResourceFeatures = Record<
  "EXERCISES" | "BOOKS_AND_VIDEOS" | "CRISIS_LINES",
  {
    title: string
    path: string
    component: () => JSX.Element
  }
>

export const RESOURCES_FEATURES: ResourceFeatures = {
  EXERCISES: {
    title: "Exercises",
    path: "exercises",
    component: Exercises,
  },
  BOOKS_AND_VIDEOS: {
    title: "Books & videos",
    path: "books-and-videos",
    component: BooksAndVideos,
  },
  CRISIS_LINES: {
    title: "Crisis lines",
    path: "crisis-lines",
    component: CrisisLines,
  },
}
