import { CheckIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import { cn } from "@spillchat/puddles"
import { FunctionComponent, PropsWithChildren } from "react"

interface OnboardinStepProps {
  completed: boolean
}

export const OnboardingStep: FunctionComponent<
  PropsWithChildren<OnboardinStepProps>
> = props => {
  return (
    <div
      className={cn(
        "group flex items-center p-4 bg-spill-grey-100 border border-spill-grey-200 rounded-lg transition",
        {
          "bg-spill-teal-100 text-teal-600 border border-teal-200 hover:bg-teal-200 pointer-events-none":
            props.completed,
        }
      )}
    >
      <div className="grow">{props.children}</div>
      {props.completed ? (
        <CheckIcon className="size-4 text-teal-600" />
      ) : (
        <div className="opacity-0 group-hover:opacity-100 transition-opacity">
          <ChevronRightIcon className="size-4" />
        </div>
      )}
    </div>
  )
}
