import { parseISO } from "date-fns"
import { isNil } from "lodash"

import { config } from "config"
import { createICSFileURL } from "common/helpers/createICSFileURL"
import { createAddToGoogleCalendarURL } from "common/helpers/createAddToGoogleCalendarURL"
import { Appointment, Counsellor } from "types/graphql"

import { getSessionLength } from "./getSessionLength"

export const createAppointmentCalendarURLs = (
  appointment: Pick<
    Appointment,
    "id" | "appointmentType" | "startsAt" | "roomUrl" | "title"
  > & {
    counsellor: Pick<Counsellor, "firstName" | "videoCallUrl">
  },
  timeZone?: string
): { googleCalendarURL: string; iCalendarURL: string } => {
  const title = `Spill ${appointment.title ?? "Therapy Session"} with ${appointment.counsellor.firstName}`

  const videoCallUrl = `${config.spill.webApp.baseUrl}/therapy/sessions/${appointment.id}/join`

  const description = `${
    !isNil(videoCallUrl)
      ? `${videoCallUrl}

`
      : ""
  }Manage Appointment: ${config.spill.webApp.baseUrl}/therapy/sessions

Thank you for booking your session with Spill ❤️

Questions? We're always around at therapy@spill.chat.

Spill`

  // default our sessions to be 50 minutes long
  const duration =
    appointment.appointmentType != null
      ? getSessionLength(appointment.appointmentType)
      : 50

  return {
    googleCalendarURL: createAddToGoogleCalendarURL({
      description,
      location: videoCallUrl ?? undefined,
      startsAt: parseISO(appointment.startsAt),
      title,
      timeZone,
      duration,
    }),
    iCalendarURL: createICSFileURL({
      description,
      location: videoCallUrl ?? undefined,
      startsAt: parseISO(appointment.startsAt),
      title,
      duration,
    }),
  }
}
