/**
 * A component that renders a modal to introduce the user to the caps
 */

import { Button, Modal, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { gql, useQuery } from "@apollo/client"
import { useLocation } from "react-router-dom"

import { useApp } from "common/context/appContext"
import {
  IntroducingUserCapsGetUserQuery,
  IntroducingUserCapsGetUserQueryVariables,
  UserNotificationType,
} from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment IntroducingUserCapsQueryFields on Query {
      user {
        id
        allActiveUserNotifications {
          id
          notificationType
          acknowledgedAt
        }
      }
    }
  `,
}

export const queries = {
  getUser: gql`
    query IntroducingUserCapsGetUser {
      ...IntroducingUserCapsQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const IntroducingUserCaps: FunctionComponent = () => {
  const { acknowledgeUserNotification } = useApp()
  const location = useLocation()
  const isAdminPath = location.pathname.startsWith("/admin")
  const isPulsePath = location.pathname.startsWith("/pulse")

  const { data, loading: notificationsLoading } = useQuery<
    IntroducingUserCapsGetUserQuery,
    IntroducingUserCapsGetUserQueryVariables
  >(queries.getUser, {
    fetchPolicy: "cache-first",
  })

  if (notificationsLoading || isAdminPath || isPulsePath) {
    return null
  }

  if (
    data?.user?.allActiveUserNotifications.find(
      notification =>
        notification.notificationType ===
        UserNotificationType.INTRODUCING_USER_CAPS
    )?.acknowledgedAt !== null
  ) {
    return null
  }

  return (
    <Modal.Root defaultOpen={true}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            A clearer way to see and manage your therapy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-5">
            <P muted>
              Spill has introduced new tools to help your company manage their
              therapy plan.
            </P>
            <P muted>
              This means you can now…
              <ul className="list-disc pl-4">
                <li className="text-sm">
                  See any upcoming or past sessions on your Sessions page
                </li>
                <li className="text-sm">
                  Keep track of how many sessions you get as part of your
                  company plan
                </li>
                <li className="text-sm">
                  Easily switch to use Spill privately or request more sessions
                  from your company
                </li>
              </ul>
            </P>
            <P muted>
              If you have any questions, email us at{" "}
              <strong>
                <a
                  className="underline underline-offset-2"
                  href="mailto:therapy@spill.chat"
                >
                  therapy@spill.chat
                </a>
              </strong>{" "}
              — we’d be more than happy to help.
            </P>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Close>
            <Button
              variant="primary"
              onClick={() => {
                acknowledgeUserNotification.acknowledge(
                  data?.user?.allActiveUserNotifications.find(
                    notification =>
                      notification.notificationType ===
                      UserNotificationType.INTRODUCING_USER_CAPS
                  )?.id ?? ""
                )
              }}
            >
              Got it
            </Button>
          </Modal.Close>
          <Button variant="tertiary" asChild>
            <a
              href="https://spill.notion.site/Introducing-individual-therapy-caps-f6996eecf0d042f59aec2a369d8547fc"
              title="Learn more about our caps and budgets"
            >
              Learn more
            </a>
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  )
}
