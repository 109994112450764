import { MediaType, Resource } from "../types"

function mediaTypeEmoji(type: MediaType): string {
  return {
    [MediaType.Book]: "📖",
    [MediaType.Podcast]: "🎧",
    [MediaType.Video]: "🎬",
    [MediaType.Series]: "📺",
  }[type]
}

type ResourceCardProps = {
  resource: Resource
}

export function ResourceCard({ resource }: ResourceCardProps): JSX.Element {
  const {
    title,
    authors,
    mediaType,
    durationInMinutes,
    numOfEpisodes,
    description,
    sourceUrl,
  } = resource

  return (
    <a
      className="box break-inside-avoid-column flex flex-col gap-4"
      href={sourceUrl}
      rel="noopener noreferrer"
      target="_blank"
    >
      <header>
        <h5>{title}</h5>
        <p>by {authors.join(", ")}</p>
      </header>

      <div className="flex flex-row justify-between gap-1 text-sm items-center">
        <span className="text-grey-600">
          {mediaTypeEmoji(mediaType)} {mediaType}
          {numOfEpisodes !== null && <span>: {numOfEpisodes} episodes</span>}
        </span>
        {durationInMinutes !== null && (
          <span className="text-grey-600">⏳ {durationInMinutes} min</span>
        )}
      </div>

      <p className="text-grey-600 font-light hidden md:block">{description}</p>
    </a>
  )
}
