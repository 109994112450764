/**
 * Create array of length filled with computed values, optionally unique.
 * @param length Length of array
 * @param factory Function to determine value for each item
 * @param options.unique Removes all duplicate values
 * @returns
 */
import { uniqBy, ValueIteratee } from "lodash"

export function createArray<T>(
  length: number,
  factory: (index: number) => T,
  options: { unique: boolean | ValueIteratee<T> } = { unique: false }
): T[] {
  const array = new Array(length).fill(null).map((_, index) => factory(index))
  if (options.unique === true) return Array.from(new Set(array))
  if (options.unique !== false) return uniqBy(array, options.unique)
  return array
}
