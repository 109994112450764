import { gql } from "@apollo/client"

export const queries = {
  getUsage: gql`
    query AdminTherapyIndexGetUsage {
      user {
        id
        company {
          id
          name
          trialStartDate
          hasInvitedUsers
          isLegacyPricing
          subscriptionPlan
          therapyBudgetResetDate
          budgetYearUsage {
            totalBillableUsage
          }
          featuresAndSettings {
            userCanRequestTherapy {
              value
            }
            preApproveTherapy {
              value
            }
            therapyPackage {
              active
            }
            adhdSupport {
              active
              value
            }
            customSupport {
              active
            }
          }
          allCustomCompanyPackageSettings {
            name
            iconUrl
            numberMonthsToUse
            numberSessions
          }
        }
      }
    }
  `,
}
