import { Helmet } from "react-helmet-async"

import type { ReactNode } from "react"

type ErrorPageProps = {
  children?: ReactNode
  showContactInfo?: boolean
}

export function ErrorPage({
  children,
  showContactInfo = true,
}: ErrorPageProps): JSX.Element {
  return (
    <>
      <Helmet>
        <title>Spill | Oops!</title>
      </Helmet>
      <div className="flex flex-col grow items-center justify-center w-full">
        <header>
          <h1 className="text-teal-600">Oops! Something went wrong.</h1>
        </header>

        <div className="flex flex-col p-20 gap-10 text-center text-blue-800 text-3xl">
          {children}
        </div>

        {showContactInfo && (
          <p className="italic text-lg">
            If the issue persists, contact us at{" "}
            <a className="font-bold" href="mailto:hi@spill.chat">
              hi@spill.chat
            </a>
            .
          </p>
        )}
      </div>
    </>
  )
}
