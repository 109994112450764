import { gql } from "@apollo/client"

export const mutations = {
  triggerCodes: gql`
    mutation LinkUserLandingTriggerCodes {
      triggerCodes
    }
  `,
  abortMerging: gql`
    mutation LinkUserLandingAbortMerge {
      abortMerge
    }
  `,
}
