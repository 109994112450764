import { ColumnDef } from "@tanstack/react-table"
import {
  Button,
  DataTable,
  Indicator,
  IndicatorProps,
} from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import { TherapyExtension, TherapyExtensionStatus } from "types/graphql"
import { formatDate } from "common/helpers/formatDate"

export type AdminTherapySpecialisedSupportRequestTableRow = TherapyExtension

type AdminTherapySpecialisedSupportRequestTableProps = {
  data: AdminTherapySpecialisedSupportRequestTableRow[]
}

export const AdminTherapySpecialisedSupportRequestTable: FunctionComponent<
  AdminTherapySpecialisedSupportRequestTableProps
> = ({ data }: AdminTherapySpecialisedSupportRequestTableProps) => {
  const columns: ColumnDef<AdminTherapySpecialisedSupportRequestTableRow>[] = [
    {
      accessorKey: "userDisplayName",
      header: "Name",
      cell: ({ row }) => {
        const invite = row.original
        return (
          <span className="block py-2">
            {invite.user?.displayName ?? "A user"}
          </span>
        )
      },
    },
    {
      accessorKey: "createdAt",
      header: "Request date",
      cell: ({ row }) => {
        const invite = row.original
        return (
          <span className="block py-2">
            {invite.createdAt != null
              ? formatDate(new Date(invite.createdAt))
              : "N/A"}
          </span>
        )
      },
    },
    {
      accessorKey: "expiryDate",
      header: "Expiry date",
      cell: ({ row }) => {
        const invite = row.original
        const date =
          invite.expiryDate != null
            ? formatDate(new Date(invite.expiryDate))
            : null
        return <span className="block py-2">{date ?? "N/A"}</span>
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const indicatorMap = {
          [TherapyExtensionStatus.PENDING]: {
            value: "Pending",
            variant: "warning",
          },
          [TherapyExtensionStatus.EXPIRED]: {
            value: "Expired",
            variant: "issue",
          },
          [TherapyExtensionStatus.APPROVED]: {
            value: "Approved",
            variant: "ok",
          },
          [TherapyExtensionStatus.DECLINED]: {
            value: "Declined",
            variant: "issue",
          },
          [TherapyExtensionStatus.CANCELLED_BY_USER]: {
            value: "Cancelled",
            variant: "issue",
          },
        }

        const invite = row.original
        const indicatorProps = indicatorMap[invite.status]
        return (
          <Indicator
            variant={
              (indicatorProps.variant as IndicatorProps["variant"]) ?? "info"
            }
          >
            {indicatorProps.value}
          </Indicator>
        )
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const invite = row.original
        if (invite.status === TherapyExtensionStatus.PENDING) {
          return (
            <Button variant="secondary" size="sm" asChild>
              <Link to={`request/${invite.id}`}>Review</Link>
            </Button>
          )
        }

        return <></>
      },
    },
  ]

  return <DataTable data={data} columns={columns} showPagination={true} />
}
