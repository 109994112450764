import { gql } from "@apollo/client"

export const mutations = {
  createPlatformUsers: gql`
    mutation InviteEmailCreatePlatformUsers(
      $platformId: ID!
      $platformUsers: [CreatePlatformUsersInput!]!
    ) {
      createPlatformUsers(
        platformId: $platformId
        platformUsers: $platformUsers
      ) {
        id
      }
    }
  `,
}
