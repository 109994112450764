import { gql, useQuery } from "@apollo/client"
import { format, isSameMonth, subMonths } from "date-fns"

import { EmotionsChangeBarChart } from "features/admin/components/EmotionsChangeBarChart"

import type {
  EmotionsChangeBarChartsGetCompanyQuery as QueryData,
  EmotionsChangeBarChartsGetCompanyQueryVariables as QueryVariables,
} from "types/graphql"

const fragments = {
  companyFields: gql`
    fragment EmotionsChangeBarChartsCompanyFields on Company {
      id
      pulseResponseAggregatesByMonth(fromMonth: $fromMonth, toMonth: $toMonth) {
        month
        someFeelingsSharedResponseCount
      }
      ...EmotionsChangeBarChartCompanyFields
    }
    ${EmotionsChangeBarChart.fragments.companyFields}
  `,
}

const queries = {
  getCompany: gql`
    query EmotionsChangeBarChartsGetCompany(
      $fromMonth: Month!
      $toMonth: Month!
    ) {
      company {
        ...EmotionsChangeBarChartsCompanyFields
      }
    }
    ${fragments.companyFields}
  `,
}

const variables = {
  ...EmotionsChangeBarChart.variables,
}

const lastMonth = subMonths(new Date(), 1)

export function EmotionsChangeBarCharts(): JSX.Element {
  const { data, loading } = useQuery<QueryData, QueryVariables>(
    queries.getCompany,
    {
      fetchPolicy: "no-cache",
      variables,
    }
  )

  const aggregates = data?.company.pulseResponseAggregatesByMonth ?? []

  const someFeelingsSharedResponseCountLastMonth = aggregates.find(i =>
    isSameMonth(lastMonth, new Date(i.month))
  )?.someFeelingsSharedResponseCount

  return (
    <div className="cursor-default flex flex-col gap-md">
      <header>
        <h4>Emotions in {format(lastMonth, "LLLL")}</h4>
        <p>
          See whether the proportion of people sharing an emotion has gone up or
          down since the previous month.
        </p>
      </header>

      <div className="flex flex-col lg:flex-row gap-md items-stretch w-full">
        {aggregates.length === 0 ||
        someFeelingsSharedResponseCountLastMonth === 0 ? (
          <p className="italic">
            Your team didn't submit any responses last month. This chart will
            only show with at least 1 response.
          </p>
        ) : !loading ? (
          <>
            <div className="flex flex-col gap-md grow">
              <header>
                <h6>Positive emotions</h6>
              </header>

              <EmotionsChangeBarChart connotation="positive" />
            </div>

            <div className="bg-grey-100 h-0.5 lg:h-auto lg:w-0.5 rounded-full" />

            <div className="flex flex-col gap-md grow">
              <header>
                <h6>Negative emotions</h6>
              </header>

              <EmotionsChangeBarChart connotation="negative" />
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

EmotionsChangeBarCharts.fragments = fragments
EmotionsChangeBarCharts.queries = queries
EmotionsChangeBarCharts.variables = variables
