import { PropsWithChildren } from "react"
import { Helmet } from "react-helmet-async"

export function NotFound404Page({ children }: PropsWithChildren): JSX.Element {
  return (
    <>
      <Helmet title="Spill | Page Not Found" />
      <div className="grow min-h-screen w-full flex flex-col justify-center items-center">
        <p className="font-display text-teal-600 text-5xl font-bold">
          404 Not Found
        </p>
        {children}
      </div>
    </>
  )
}
