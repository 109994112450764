import { gql } from "@apollo/client"

export const mutations = {
  approveExtension: gql`
    mutation AdminTherapySpecialisedSupportReviewApprove(
      $id: ID!
      $note: String
    ) {
      approveTherapyExtension(id: $id, noteFromAdmin: $note) {
        id
        status
      }
    }
  `,
  declineExtension: gql`
    mutation AdminTherapySpecialisedSupportReviewDecline(
      $id: ID!
      $note: String
    ) {
      declineTherapyExtension(id: $id, noteFromAdmin: $note) {
        id
        status
      }
    }
  `,
}
