import { useMutation } from "@apollo/client"
import { FormProvider, useForm } from "react-hook-form"
import { useState, type FunctionComponent } from "react"
import { toast } from "sonner"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useUser } from "common/context/userContext"
import { useAnalytics } from "common/context/analyticsContext"

import { mutations } from "../feedback.mutations"
import { FeedbackFormFields, FeedbackFormStepLabels } from "../feedback.types"

import { FeedbackFormSession } from "./FeedbackFormSession"
import { FeedbackFormReview } from "./FeedbackFormReview"
import { FeedbackFormFeedback } from "./FeedbackFormFeedback"
import { FeedbackFormSuccess } from "./FeedbackFormSuccess"

import type {
  FeedbackFormV5FormMutation,
  FeedbackFormV5FormMutationVariables,
  FeedbackFormPublicReviewMutation,
  FeedbackFormPublicReviewMutationVariables,
  FeedbackTherapySessionV1Input,
} from "types/graphql"

interface FeedbackFormV5Props {
  entityId: string
}

export const FeedbackFormV5: FunctionComponent<FeedbackFormV5Props> = ({
  entityId,
}) => {
  const user = useUser()
  const { track } = useAnalytics()
  const [step, setStep] = useState<FeedbackFormStepLabels>("session")

  const formMethods = useForm<FeedbackFormFields>({
    defaultValues: {
      companyPublicReview: {
        includeName: false,
        message: undefined,
        score: undefined,
      },
      messageToTherapist: undefined,
      messageToAdmin: {
        includeName: false,
        message: undefined,
      },
      recommendSpill: undefined,
      metTheirGoals: undefined,
      wouldSeeAgain: undefined,
      gotWhatTheyNeededContext: undefined,
    },
  })

  const handleError = () => {
    return toast.error(
      "Sorry, something went wrong. You can email therapy@spill.chat if it persists."
    )
  }

  const [submitFeedbackV5, { loading }] = useMutation<
    FeedbackFormV5FormMutation,
    FeedbackFormV5FormMutationVariables
  >(mutations.submitFeedbackV5, {
    onError: () => {
      handleError()
    },
  })

  const [createReview] = useMutation<
    FeedbackFormPublicReviewMutation,
    FeedbackFormPublicReviewMutationVariables
  >(mutations.createCompanyPublicReview, {
    onError: () => {
      handleError()
    },
  })

  const onSubmit = async (formFields: FeedbackFormFields) => {
    const input: FeedbackTherapySessionV1Input = {
      entityId,
      metTheirGoals: formFields.metTheirGoals,
      recommendSpill: formFields.recommendSpill,
      wouldSeeAgain: formFields.wouldSeeAgain === 1 ? true : false,
      gotWhatTheyNeededContext: formFields.gotWhatTheyNeededContext ?? null,
    }

    const positiveExperience = formFields.recommendSpill >= 8

    if (step === "session") {
      if (positiveExperience) {
        track("Completed Session Step")
        return setStep("feedback")
      }

      const { data } = await submitFeedbackV5({
        variables: {
          input,
        },
      })

      if (data?.submitFeedbackTherapySessionV1 === true) {
        track("Completed Session Step")
        return setStep("success")
      }
    }

    if (step === "feedback") {
      if (formFields.messageToAdmin?.message != undefined) {
        input.messageToAdmin = {
          message: formFields.messageToAdmin?.message,
          includeName: Boolean(formFields.messageToAdmin?.includeName),
        }
      }

      input.messageToTherapist = formFields.messageToTherapist

      if (formFields.channelReview?.message != undefined) {
        input.channelReview = {
          message: formFields.channelReview?.message,
          includeName: Boolean(formFields.channelReview.includeName),
        }
      }

      const { data } = await submitFeedbackV5({
        variables: {
          input,
        },
      })

      if (data?.submitFeedbackTherapySessionV1 === true) {
        track("Completed Feedback Step")
        setStep("review")
      }
    }

    if (step === "review") {
      if (
        formFields.companyPublicReview?.message === undefined ||
        formFields.companyPublicReview?.score === undefined
      ) {
        return
      }

      const review = await createReview({
        variables: {
          input: {
            message: formFields.companyPublicReview?.message ?? "",
            score: formFields.companyPublicReview?.score ?? 0,
            userName:
              Boolean(formFields.companyPublicReview?.includeName) === true
                ? user.displayName
                : undefined,
          },
        },
      })

      if (review.data !== undefined) {
        track("Completed Review Step")
        return setStep("success")
      }
    }
  }

  if (loading)
    return (
      <div className="flex items-center justify-center">
        <LoadingSpinner sizeInPixels={48} />
      </div>
    )

  return (
    <div className="flex justify-center items-center">
      <div className="p-6 lg:p-8 xs:w-full lg:max-w-screen-sm">
        <FormProvider {...formMethods}>
          <form
            className="flex flex-col gap-8 w-full"
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            {step === "session" && <FeedbackFormSession />}
            {step === "feedback" && (
              <FeedbackFormFeedback stepNumber={1} onSetStep={setStep} />
            )}
            {step === "review" && (
              <FeedbackFormReview stepNumber={2} onSetStep={setStep} />
            )}
            {step === "success" && <FeedbackFormSuccess />}
          </form>
        </FormProvider>
      </div>
    </div>
  )
}
