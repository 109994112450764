import { P } from "@spillchat/puddles"
import { Link } from "react-router-dom"
import { Hit } from "algoliasearch"
import { FunctionComponent } from "react"
import { format } from "date-fns"

import { AdviceHit } from "./SearchResultHits"
import { AdviceLibraryTags } from "./AdviceLibraryTags"

type AdviceLibraryResultProps = {
  hit: Hit<AdviceHit>
}

export const AdviceLibraryResult: FunctionComponent<
  AdviceLibraryResultProps
> = ({ hit }) => {
  return (
    <Link
      to={`/advice-library/${hit.objectID}`}
      className="flex flex-col gap-4 py-8"
      key={hit.objectID}
    >
      <div>
        <P>{hit.question}</P>
      </div>
      <div className="flex flex-wrap flex-col lg:flex-row justify-between lg:items-center gap-4">
        {hit.tags?.length > 0 && <AdviceLibraryTags tags={hit.tags} />}
        {hit.answered_at_timestamp != null && (
          <div className="whitespace-nowrap">
            <P size="xs" muted>
              {format(
                new Date(hit.answered_at_timestamp * 1000),
                "d MMMM yyyy"
              )}
            </P>
          </div>
        )}
      </div>
    </Link>
  )
}
