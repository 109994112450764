import {
  ApolloClient,
  ApolloConsumer,
  HttpLink,
  InMemoryCache,
  gql,
} from "@apollo/client"
import { FunctionComponent, useEffect, useRef, useState } from "react"
import { Button, Input, Label, cn } from "@spillchat/puddles"

import { useAuth } from "common/context/authContext"
import { config } from "config"
import {
  ImpersonationPanelGetUsersQuery,
  ImpersonationPanelGetUsersQueryVariables,
} from "types/graphql"

const queries = {
  getUsers: gql(`
    query ImpersonationPanelGetUsers($companyId: ID!) {
    _company(_id: $companyId) { users { id, displayName, role } }
    }
  `),
}

const LOCAL_STORAGE_KEY = "impersonatingUser"
const impersonatingUserFromLocalStorage =
  localStorage.getItem(LOCAL_STORAGE_KEY)
const defaultLink = {
  credentials: "include",
  uri: `${config.spill.gateway.baseUrl}/graphql`,
}

export const ImpersonationPanel: FunctionComponent = () => {
  const [toImpersonate, setToImpersonate] = useState(
    impersonatingUserFromLocalStorage ?? ""
  )
  const [isImpersonating, setIsImpersonating] = useState(toImpersonate !== "")
  const [canChooseUser, setCanChooseUser] = useState(false)

  const [companyId, setCompanyId] = useState("")
  const [users, setUsers] = useState<
    { id: string; displayName: string; role: string }[]
  >([])
  const { user } = useAuth()

  const superUserClient = useRef(
    new ApolloClient({
      cache: new InMemoryCache(),
      credentials: "include",
      uri: `${config.spill.gateway.baseUrl}/graphql/internal`,
    })
  )

  useEffect(() => {
    try {
      if (localStorage.getItem("impersonatingUser") != null) {
        // @todo -> Fix this so we can refresh and continue impersonating
        // setUpImpersonateLink()
        setToImpersonate(localStorage.getItem("impersonatingUser") ?? "")
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }, [])

  useEffect(() => {
    if (companyId === "") {
      return
    }
    void superUserClient.current
      .query<
        ImpersonationPanelGetUsersQuery,
        ImpersonationPanelGetUsersQueryVariables
      >({
        query: queries.getUsers,
        variables: { companyId },
      })
      .then(c => setUsers(c.data._company?.users ?? []))
  }, [companyId])

  const setUpImpersonateLink = (
    client: ApolloClient<object>,
    disableImpersonate?: boolean
  ) => {
    if (disableImpersonate === true) {
      setToImpersonate("")
      setIsImpersonating(false)
      const httpLink = new HttpLink(defaultLink)

      client.setLink(httpLink)

      localStorage.removeItem("impersonatingUser")
      void client.refetchQueries({ include: "all" })
    } else {
      const httpLink = new HttpLink({
        ...defaultLink,
        headers: { "X-Spill-Impersonate": toImpersonate },
      })
      client.setLink(httpLink)
      localStorage.setItem("impersonatingUser", toImpersonate)
      setIsImpersonating(true)
      void client.refetchQueries({ include: "all" })
    }
  }

  if (user == null) {
    return null
  }

  return (
    <ApolloConsumer>
      {client => {
        return (
          <div className="flex flex-col items-center fixed bottom-0 w-full ">
            {isImpersonating && (
              <div className="bg-red-200/40 text-xxs text-black font-bold border border-red-200 border-b-0 h-6 p-1 rounded-t-md w-4/6 backdrop-blur-md flex flex-row justify-center gap-10 items-center">
                Currently impersonating an account. This may cause changes to
                production data.
              </div>
            )}
            <div
              className={cn(
                "w-full backdrop-blur-md bg-grey-100/50 flex flex-row justify-center gap-10 py-2 rounded-none items-center",
                isImpersonating && "bg-red-400/10 border border-red-200"
              )}
            >
              <div className="flex items-center justify-between">
                <div className="flex gap-8">
                  <Input
                    label={{
                      children: "Enter a company ID:",
                    }}
                    value={companyId}
                    type="text"
                    className="w-fit"
                    onChange={e => setCompanyId(e.target.value)}
                    onBlur={() => {
                      if (companyId === "") {
                        setCanChooseUser(false)
                      } else {
                        setCanChooseUser(true)
                      }
                    }}
                  />
                  <div className="flex flex-col gap-1">
                    <Label>And pick a user:</Label>
                    <select
                      onChange={e => setToImpersonate(e.target.value)}
                      className="p-3 rounded-md border border-spill-grey-200 min-w-[200px] text-sm font-inter"
                      disabled={!canChooseUser}
                    >
                      <option value="">Myself please</option>
                      {
                        // sort the users to put the admins at the top
                        [...users]
                          .sort((a, b) => {
                            const roleA = a.role.toLowerCase()
                            const roleB = b.role.toLowerCase()

                            if (roleA === "admin" && roleB !== "admin")
                              return -1
                            if (roleA !== "admin" && roleB === "admin") return 1
                            return 0
                          })
                          .map(user => (
                            <option key={user.id} value={user.id}>
                              {user.displayName} ({user.role})
                            </option>
                          ))
                      }
                    </select>
                  </div>
                </div>
                <span className="h-full w-[1px] bg-spill-grey-200 mx-4" />
                <Input
                  label={{
                    children: "Or enter a user ID:",
                  }}
                  value={toImpersonate}
                  type="text"
                  onChange={e => setToImpersonate(e.target.value)}
                />
              </div>
              <Button
                variant="primary"
                type="submit"
                disabled={toImpersonate === "" && isImpersonating === false}
                onClick={() => {
                  setUpImpersonateLink(client, isImpersonating)
                }}
              >
                {isImpersonating ? "Stop impersonating" : "Start impersonating"}
              </Button>
            </div>
          </div>
        )
      }}
    </ApolloConsumer>
  )
}
