import { gql } from "@apollo/client"

export const mutations = {
  updateTherapyProfile: gql`
    mutation ProfileInfoUpdateUserProfile(
      $firstName: String
      $lastName: String
      $displayName: String
      $pronouns: String
      $dateOfBirth: Date
      $phone: String
    ) {
      updateAppUser(
        firstName: $firstName
        lastName: $lastName
        displayName: $displayName
      ) {
        id
        firstName
        lastName
        displayName
      }
      updateTherapyProfile(
        userInfo: {
          pronouns: $pronouns
          dateOfBirth: $dateOfBirth
          firstName: $firstName
          lastName: $lastName
          phoneNumber: $phone
        }
      ) {
        pronouns
        dateOfBirth
        firstName
        lastName
        phoneNumber
      }
    }
  `,
}
