import { useEffect } from "react"
import { Navigate, useParams } from "react-router-dom"
import { gql, useLazyQuery } from "@apollo/client"

import { MessagePage } from "./Message"

import type { FunctionComponent } from "react"
import type {
  SpillMessagesPageGetMessageQuery as QueryData,
  SpillMessagesPageGetMessageQueryVariables as QueryVars,
} from "types/graphql"

const queries = {
  getMessage: gql`
    query SpillMessagesPageGetMessage($id: ID!) {
      message(id: $id) {
        id
        feature
      }
    }
  `,
}

/**
 * Page to display any message from platform-wrapper's messages table
 *
 * Therapy conversation messages get redirected.
 */
export const SpillMessagePage: FunctionComponent = () => {
  const { messageId } = useParams()

  const [getMessage, { data }] = useLazyQuery<QueryData, QueryVars>(
    queries.getMessage
  )

  useEffect(() => {
    if (messageId !== undefined) {
      void getMessage({ variables: { id: messageId } })
    }
  }, [messageId])

  const isAATConversation = data?.message?.feature === "therapy_conversation"

  if (isAATConversation) return <Navigate replace to="/therapy/messages" />

  return (
    <div className="p-6 lg:p-8">
      <MessagePage />
    </div>
  )
}
