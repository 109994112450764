import { FEELINGS } from "features/pulse/constants/emotions"

import type { Feeling, SharedPulseResponse } from "types/graphql"

export type AlertColorTypes = "primary" | "warning" | "danger" | "gray"

export const ALERT_COLORS: Record<AlertColorTypes, string> = {
  danger: "tertiary-300",
  warning: "yellow-200",
  primary: "teal-200",
  gray: "grey-200",
}

export function decideMoodColor(
  score: SharedPulseResponse["score"]
): AlertColorTypes {
  if (typeof score !== "number") return "gray"
  if (score < 6) return "danger"
  if (score < 8) return "warning"

  return "primary"
}

export function decideFeelingColor(feeling: Feeling): AlertColorTypes {
  if (typeof FEELINGS[feeling]?.connotation === "undefined") return "gray"
  else {
    return FEELINGS[feeling]?.connotation === "positive" ? "primary" : "danger"
  }
}
