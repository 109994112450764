import { gql } from "@apollo/client"

export const mutations = {
  toggleAdhdSupport: gql(`
    mutation InviteAdhdToggle($adhdSupport: Boolean, $userTherapyCap: Int) {
      updateCompanySettings(adhdSupport: $adhdSupport, userTherapyCap: $userTherapyCap) {
        id
        adhdSupport {
          active
          value
        }
      }
    }
  `),
  updateAdhdCoPayAddon: gql(`
    mutation InviteAdhdUpdateCoPay($enabled: Boolean!, $companyContribution: Float!) {
      createOrUpdateCoPayAddOn(enabled: $enabled, companyContribution: $companyContribution) {
        id
        properties {
          enabled
          companyContribution
        }
      }
    }
  `),
}
