import { useLocation, useNavigate } from "react-router-dom"

type GoBackProps = { to?: string }

export function useGoBack(): (props?: GoBackProps) => void {
  const location = useLocation()
  const navigate = useNavigate()

  const goBack = (props?: GoBackProps) => {
    if (props?.to != null) {
      return navigate(props?.to)
    }

    if (location.key === "default") {
      return navigate("/admin")
    } else {
      return navigate(-1)
    }
  }

  return goBack
}
