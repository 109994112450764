/**
 * A page for the user to manage their billing information.
 * We have two available routes, the default route of / which unless they've
 * got an active subscription, will show them a "Give therapy to individuals" page, or
 * if they have an active subscription, will show them a "Manage your subscription" page.
 * The second page is the /summary route, which will show them a summary of the cost of
 * their subscription.
 */

import { FunctionComponent, useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { useApp } from "common/context/appContext"

import { BillingDetails } from "./Billing/index"

export const Billing: FunctionComponent = () => {
  const { setPageBlob } = useApp()

  useEffect(() => {
    setPageBlob("none")
  }, [])

  return (
    <>
      <Helmet title="Billing | Spill" />

      <Routes>
        <Route path="/" element={<BillingDetails />} />
      </Routes>
    </>
  )
}
