import { gql, useMutation } from "@apollo/client"
import { FunctionComponent, useEffect } from "react"
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { Button, P, H2 } from "@spillchat/puddles"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  CheckoutSuccessCompleteCheckoutMutation,
  SpillSubscriptionPlan,
  SpillSubscriptionStatus,
} from "types/graphql"
import { useApp } from "common/context/appContext"

const mutations = {
  completeCheckout: gql`
    mutation CheckoutSuccessCompleteCheckout($sessionId: String!) {
      completeCheckout(sessionId: $sessionId) {
        id
        subscriptionStatus
        subscriptionPlan
        featuresAndSettings {
          id
          userCanRequestTherapy {
            value
          }
          userTherapyCap {
            value
          }
          sessionPack {
            value
          }
        }
      }
    }
  `,
}

export const CheckoutSuccess: FunctionComponent = () => {
  const [searchParams] = useSearchParams()
  const { setBannerContent } = useApp()
  const id = searchParams.get("session_id")

  const navigate = useNavigate()
  const [completeCheckout, { data, loading, called }] =
    useMutation<CheckoutSuccessCompleteCheckoutMutation>(
      mutations.completeCheckout
    )

  useEffect(() => {
    if (id != null) {
      void completeCheckout({
        variables: { sessionId: id },
      })
    }
  }, [id])

  // Won't change now but this can probably be put in
  // useMutation onCompleted method
  useEffect(() => {
    if (called && !loading) {
      if (
        data?.completeCheckout.subscriptionStatus ===
        SpillSubscriptionStatus.ACTIVE
      ) {
        setBannerContent(null)
      } else {
        navigate("/admin/checkout/failure")
      }
    }
  }, [data, called, loading])

  if (id == null) {
    return <Navigate to="/admin/checkout/failure" />
  }

  if (loading) {
    return (
      <>
        <Helmet title="Checkout Success | Spill" />
        <div className="flex flex-col space-y-10">
          <H2>Completing checkout</H2>
          <LoadingSpinner sizeInPixels={60} />
        </div>
      </>
    )
  }

  const isTeamPlan =
    data?.completeCheckout.subscriptionPlan === SpillSubscriptionPlan.TEAM
  const isEssentialPlan =
    data?.completeCheckout.subscriptionPlan === SpillSubscriptionPlan.ESSENTIAL
  return (
    <>
      <Helmet title="Checkout Success | Spill" />

      <div className="flex flex-col space-y-10">
        <H2>Confirmed subscription</H2>
        <div className="max-w-2xl space-y-4">
          {!isEssentialPlan && (
            <P>
              You can now give Spill access to any employees who need it right
              now.
            </P>
          )}
          {isTeamPlan && (
            <>
              <P>
                Your account settings allow people to book{" "}
                <strong>
                  {
                    data?.completeCheckout?.featuresAndSettings?.userTherapyCap
                      .value
                  }{" "}
                  sessions
                </strong>{" "}
                and if they finish these and want to continue they will{" "}
                <strong>
                  {data?.completeCheckout?.featuresAndSettings
                    ?.userCanRequestTherapy.value === false
                    ? "pay for more sessions themselves."
                    : "be able to make a request for additional sessions."}
                </strong>
              </P>
              <P>
                You can update these settings if you would like to, or start
                inviting your team to give immediate access to therapy.
              </P>
            </>
          )}
          {isEssentialPlan && (
            <>
              <P>You can now give Spill access to your team.</P>
              <P>
                Your account settings allow the team to book up to{" "}
                <strong>
                  {data?.completeCheckout?.featuresAndSettings?.sessionPack
                    .value ?? 0}{" "}
                  {data?.completeCheckout?.featuresAndSettings?.sessionPack
                    .value !== 1
                    ? "sessions"
                    : "session"}{" "}
                  each month.
                </strong>{" "}
                If these sessions are used up and they wish to continue, they
                will{" "}
                <strong>
                  {data?.completeCheckout?.featuresAndSettings
                    ?.userCanRequestTherapy.value === false
                    ? "pay for more sessions themselves."
                    : "be able to make a request for additional sessions."}
                </strong>
              </P>
              <P>
                If you have any questions, drop us a line at
                real-person@spill.chat.
              </P>
            </>
          )}
        </div>
        <Button>
          <Link to="/admin">Go to dashboard</Link>
        </Button>
      </div>
    </>
  )
}
