import { BoltIcon } from "@heroicons/react/24/outline"
import { Button, P } from "@spillchat/puddles"
import { FunctionComponent, ReactNode } from "react"
import { Link } from "react-router-dom"

import { PmiRequest, PmiRequestStatus } from "types/graphql"

interface CustomAlertContent {
  title: ReactNode
  body: ReactNode
}

interface PmiCustomAlertProps {
  mostRecentPmiRequest?: PmiRequest
}

export const PmiCustomAlert: FunctionComponent<PmiCustomAlertProps> = ({
  mostRecentPmiRequest,
}) => {
  let customAlertContent: CustomAlertContent

  switch (mostRecentPmiRequest?.status) {
    case PmiRequestStatus.OPEN:
      customAlertContent = {
        title: (
          <>
            You&apos;ve requested more sessions through your private medical
            insurance
          </>
        ),
        body: (
          <P>
            Your request will be handled within 7 working days. If it&apos;s
            approved, you&apos;ll be able to see your upcoming sessions on this
            page.
          </P>
        ),
      }
      break

    case PmiRequestStatus.APPROVED:
      customAlertContent = {
        title: (
          <>
            You&apos;ve been approved for{" "}
            {mostRecentPmiRequest?.numberOfSessions} session
            {mostRecentPmiRequest?.numberOfSessions === 1 ? "" : "s"} through
            your private medical insurance
          </>
        ),
        body: <P>You can join your upcoming sessions from this page.</P>,
      }
      break

    case PmiRequestStatus.CLOSED:
    case undefined:
      customAlertContent = {
        title: "Get more sessions through your company health insurance",
        body: (
          <>
            <P>
              If you run out of sessions on Spill, you may be eligible for extra
              therapy via your private medical insurance policy.
            </P>
            <P>
              <Button variant="tertiary" asChild>
                <Link to="/therapy/sessions/pmi">Find out more</Link>
              </Button>
            </P>
          </>
        ),
      }
      break

    default:
      return null
  }

  return (
    <div className="col-span-1 md:col-span-4 xl:col-span-3 max-w-xs md:max-w-full">
      <div className="flex flex-col gap-2 p-4 rounded-lg items-start justify-start bg-spill-blue-100">
        <div className="flex flex-row items-center gap-2">
          <BoltIcon className="shrink-0 size-4" />
          <P weight="medium">{customAlertContent.title}</P>
        </div>
        {customAlertContent.body}
      </div>
    </div>
  )
}
