import { FunctionComponent } from "react"
import { H3, P } from "@spillchat/puddles"
import { CheckCircleIcon } from "@heroicons/react/24/outline"

import { cn } from "common/helpers/cn"
import { Step } from "features/therapy/types"

interface AppointmentFormSectionProps {
  index: number
  openStepIndex: number
  setOpenStepIndex: (index: number) => void
  steps: Step[]
}

export const AppointmentFormSection: FunctionComponent<
  AppointmentFormSectionProps
> = props => {
  const step = props.steps[props.index]!
  const isStepOrPrevStepComplete =
    step.isComplete || props.steps[props.index - 1]?.isComplete === true
  const lastStepIndex = props.steps.length - 1
  const isDisabled =
    !isStepOrPrevStepComplete ||
    (props.openStepIndex === lastStepIndex && props.index < lastStepIndex)
  const isOpen = props.openStepIndex === props.index

  return (
    <details
      className="border border-grey-200 max-w-screen-lg rounded-2xl open:shadow-lg"
      open={isOpen}
    >
      <summary
        className={cn(
          "flex items-center justify-between p-4 lg:p-8 rounded-2xl focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-blue-800",
          {
            "cursor-pointer": !isDisabled && props.index !== lastStepIndex,
            "cursor-not-allowed": isDisabled,
          }
        )}
        onClick={e => {
          e.preventDefault()
          if (!isDisabled) props.setOpenStepIndex(props.index)
        }}
      >
        <div className="flex gap-6 items-center">
          <P weight="medium">
            <span className="whitespace-nowrap">
              {props.index + 1} of {props.steps.length}
            </span>
          </P>
          <H3>{step.title}</H3>
        </div>
        {step.isComplete && (
          <div className="text-teal-400">
            <CheckCircleIcon className="size-6" />
          </div>
        )}
      </summary>
      <div className="p-4 lg:p-8 pt-0 space-y-8">
        {isOpen ? step.component : null}
      </div>
    </details>
  )
}
