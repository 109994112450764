import { IndicatorProps } from "@spillchat/puddles"

import { PlatformType } from "types/graphql"

export function convertPlatformTypeToIndicator(
  platformType: PlatformType
): IndicatorProps["variant"] {
  return (
    {
      [PlatformType.SLACK]: "ok",
      [PlatformType.TEAMS]: "info",
      [PlatformType.EMAIL]: "info",
      [PlatformType.CUSTOM]: "info",
    } as Record<PlatformType, IndicatorProps["variant"]>
  )[platformType]
}
