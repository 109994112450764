import { FunctionComponent } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"

import usingSpillLogo from "common/assets/logo/spill-logo-white-teal.svg"
import {
  AppointmentStatus,
  AppointmentTypeFeature,
  FeedbackAppointmentQuery,
  FeedbackAppointmentQueryVariables,
} from "types/graphql"
import { LoadingPage } from "common/components/LoadingPage"
import { NotFound404Page } from "common/components/NotFound404Page"
import { useAnalytics } from "common/context/analyticsContext"

import { FeedbackFormV5 } from "../components/FeedbackFormV5"
import { FeedbackConsultation } from "../components/FeedbackConsultation"
import { queries } from "../feedback.queries"

export const TherapySessionFeedbackPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const { track } = useAnalytics()
  const { appointmentId } = useParams()

  if (appointmentId === undefined) {
    navigate("/")
    return
  }

  const { data, loading } = useQuery<
    FeedbackAppointmentQuery,
    FeedbackAppointmentQueryVariables
  >(queries.appointment, {
    variables: { appointmentId },
    onCompleted(data) {
      if (data.appointment != null) {
        track("Landed on Feedback Form", {
          appointmentType: data.appointment.appointmentType,
        })
      }
    },
  })

  if (loading) {
    return <LoadingPage />
  }

  const appointment = data?.appointment

  if (appointment == null) {
    return <NotFound404Page />
  }

  const { appointmentType, numberInCourse, status } = appointment

  /**
   * Whether the number in course means we want to request feedback.
   *
   * For non-course appointments, `numberInCourse` is always 1, so
   * we'll always ask for those.
   *
   * For course appointments, we only want to ask on the 1st and every 6th.
   */
  const isNumberInCourseForFeedback =
    numberInCourse === 1 || numberInCourse % 6 === 0

  const isAppointmentTypeToSkip =
    appointmentType == null ||
    [
      AppointmentTypeFeature.PRIVATE_THERAPY_SESSION,
      AppointmentTypeFeature.PRIVATE_COURSE_SESSION_25_MINUTES,
      AppointmentTypeFeature.PLUS_ONE,
    ].includes(appointmentType)

  // Adding tracking because we're sending out too many feedback requests
  track("Feedback being sent for appointment", {
    isNumberInCourseForFeedback,
    isAppointmentTypeToSkip,
    appointmentId,
  })
  if (
    isAppointmentTypeToSkip ||
    status !== AppointmentStatus.CONFIRMED ||
    !isNumberInCourseForFeedback
  ) {
    navigate("/")
    return
  }

  const isConsultation = [
    AppointmentTypeFeature.SERIES_CONSULTATION_SESSION,
    AppointmentTypeFeature.BEREAVEMENT_CONSULTATION,
    AppointmentTypeFeature.PARENTHOOD_CONSULTATION,
  ].includes(appointmentType)

  return (
    <div className="bg-spill-grey-100 gap-2 min-h-screen h-full flex flex-col justify-between">
      <div className="p-6">
        <img src={usingSpillLogo} alt="Spill Logo" className="h-6" />
      </div>
      <div className="grow flex items-center justify-center">
        {isConsultation ? (
          <FeedbackConsultation entityId={appointmentId} />
        ) : (
          <FeedbackFormV5 entityId={appointmentId} />
        )}
      </div>
    </div>
  )
}
