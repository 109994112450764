import { FunctionComponent, useState } from "react"
import { useMutation, gql } from "@apollo/client"
import { Link } from "react-router-dom"
import { useInterval } from "react-use"
import { isNil } from "lodash"
import {
  differenceInHours,
  differenceInMinutes,
  format,
  formatDistance,
  parseISO,
} from "date-fns"
import { Button, H4, P } from "@spillchat/puddles"
import { toast } from "sonner"

import { createAppointmentCalendarURLs } from "common/helpers/createAppointmentCalendarURLs"
import { useAnalytics } from "common/context/analyticsContext"
import { Dialog } from "common/components/Dialog"
import { Tooltip } from "common/components/Tooltip"
import {
  SessionCardAppointmentFieldsFragment,
  SessionCardCancelAppointmentMutation,
  SessionCardCancelAppointmentMutationVariables,
  SessionCardUserFieldsFragment,
} from "types/graphql"

export const fragments = {
  appointment: gql`
    fragment SessionCardAppointmentFields on Appointment {
      id
      appointmentType
      roomUrl
      startsAt
      title
      counsellor {
        id
        firstName
        videoCallUrl
      }
    }
  `,
  user: gql`
    fragment SessionCardUserFields on User {
      id
      primaryEmail
      therapyProfile {
        email
      }
    }
  `,
}

export const mutations = {
  cancelAppointment: gql(`
    mutation SessionCardCancelAppointment($appointmentId: ID!) {
      cancelAppointment(appointmentId: $appointmentId) {
        id
        status
      }
    }
  `),
}

interface SessionCardProps {
  appointment: SessionCardAppointmentFieldsFragment
  isNextAppointment?: boolean
  user: SessionCardUserFieldsFragment
}

// WARNING: will be replaced by src/features/therapy/pages/SessionPage/SessionCard.tsx
// once web-app.all.capsenabledsessionspage release flag is fully deployed
export const SessionCard: FunctionComponent<SessionCardProps> = ({
  isNextAppointment = false,
  ...props
}) => {
  const { track } = useAnalytics()

  const appointment = props.appointment
  const user = props.user

  const [now, setNow] = useState(new Date())
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
    useState(false)

  // Update component every minute
  useInterval(() => setNow(new Date()), 60000)

  const [cancelAppointment, { loading: cancelAppointmentLoading }] =
    useMutation<
      SessionCardCancelAppointmentMutation,
      SessionCardCancelAppointmentMutationVariables
    >(mutations.cancelAppointment, {
      refetchQueries: [
        "SessionsPageGetUserQuery",
        "SessionsPageGetData",
        "TherapyRouterGetData",
        "TherapistSelectionCarouselGetData",
      ],
      variables: { appointmentId: appointment.id },
    })

  const email = user.therapyProfile?.email ?? user.primaryEmail
  const startsAtDate = parseISO(appointment.startsAt)
  const minutesUntilAppointment = differenceInMinutes(startsAtDate, now)
  const roomUrl = appointment.roomUrl
  const { googleCalendarURL, iCalendarURL } =
    createAppointmentCalendarURLs(appointment)
  const hasAppointmentStarted = minutesUntilAppointment < 0
  const isAppointmentWithin24Hours =
    differenceInHours(parseISO(appointment.startsAt), now, {
      roundingMethod: "floor",
    }) < 24

  const therapyRoomUrl = `/therapy/sessions/${appointment.id}/join`

  return (
    <>
      {/* Appointment cancellation confirmation dialog */}
      <Dialog
        buttons={[
          {
            children: cancelAppointmentLoading
              ? "Cancelling session"
              : "Cancel session",
            loading: cancelAppointmentLoading,
            onClick: async () => {
              const cancelledAppointment = await cancelAppointment()
              if (!cancelledAppointment.errors) {
                setIsCancellationDialogOpen(false)
              } else {
                toast.error(
                  "We were unable to cancel your session. Please try again later, or contact therapy@spill.chat"
                )
              }
            },
            variant: "primary",
          },
        ]}
        canClose
        isOpen={isCancellationDialogOpen}
        onClose={() => setIsCancellationDialogOpen(false)}
        title="Cancel your session"
      >
        {isAppointmentWithin24Hours && (
          <p>
            You are cancelling within 24 hours. This means we will still pay the
            therapist, use one session from your allowance, and still charge
            your company.
            <br />
          </p>
        )}
        <p>Are you sure you want to cancel?</p>
      </Dialog>
      <div className="bg-mono-white border border-grey-200 p-8 rounded-xl space-y-4">
        <H4>
          {format(parseISO(appointment.startsAt), "eeee, dd MMMM, h:mmaaa")}{" "}
          with {appointment.counsellor.firstName} (50min)
        </H4>
        {isNextAppointment && !isNil(email) && (
          <P>
            Confirmation & reminders are going to <strong>{email}</strong>
          </P>
        )}
        <br />
        <div className="flex gap-4">
          {!hasAppointmentStarted && (
            <Button
              onClick={() => {
                track("User clicked add to calendar", { Calendar: "Google" })
              }}
              variant="primary"
            >
              <Link to={googleCalendarURL} rel="noreferrer" target="_blank">
                Add to Google
              </Link>
            </Button>
          )}
          {isNextAppointment && !isNil(roomUrl) && (
            <Button
              onClick={() =>
                track("User Clicked Join Therapy Session", {
                  "Appointment ID": appointment.id,
                  Location: therapyRoomUrl,
                })
              }
              disabled={minutesUntilAppointment > 3}
              variant="primary"
            >
              <Link to={therapyRoomUrl} rel="noreferrer" target="_blank">
                Join{" "}
                {minutesUntilAppointment > 0
                  ? `in ${formatDistance(now, startsAtDate)}`
                  : "now"}
              </Link>
            </Button>
          )}
        </div>
        {!hasAppointmentStarted && (
          <footer className="flex gap-2 justify-between">
            <P size="xs">
              <a
                className="inline-flex underline"
                download="spill_session.ics"
                href={iCalendarURL}
                onClick={() => {
                  track("User clicked add to calendar", { Calendar: "iCal" })
                }}
              >
                Or add to iCal/Outlook
              </a>
            </P>

            <div className="flex gap-[inherit]">
              <P size="xs">
                <button
                  className="appearance-none underline"
                  onClick={() => setIsCancellationDialogOpen(true)}
                >
                  Cancel
                </button>
              </P>
              <span className="select-none text-xs">|</span>
              {isAppointmentWithin24Hours ? (
                <Tooltip content="Unable to reschedule as the appointment is less than 24 hours away.">
                  <span className=" cursor-not-allowed text-grey-400 underline">
                    <P size="xs">Reschedule</P>
                  </span>
                </Tooltip>
              ) : (
                <P size="xs">
                  <Link
                    className="underline"
                    to={`/therapy/sessions/${appointment.id}/reschedule`}
                  >
                    Reschedule
                  </Link>
                </P>
              )}
            </div>
          </footer>
        )}
      </div>
    </>
  )
}
