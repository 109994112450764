import { Accordion, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

const accordionItems = [
  {
    trigger: "Who are Spill's bereavement specialists?",
    content: (
      <P>
        Our bereavement specialists have extensive experience working with grief
        and loss, having worked in a variety of settings including hospices and
        bereavement charities. They've all completed additional training in
        working with bereavement and, like all our counsellors, go through our
        5-stage hiring process before working with us. If you're looking for
        something specific or would like more information about your counsellor
        before booking, please drop us an email at therapy@spill.chat.
      </P>
    ),
  },
  {
    trigger: "Is Spill confidential?",
    content: (
      <>
        <P>
          Spill sessions are entirely confidential. That means we do not tell
          anyone from your company anything you share with your counsellor or
          dates and times of when you&apos;re meeting.
        </P>
        <P>
          Because we bill on a pay-as-you-go basis, if you do request sessions
          from your company they will see it on their invoice. However, unless
          you are the only person to request (and include your name) or the only
          person on your company&apos;s Spill account, they will not know who
          had the session.{" "}
        </P>
      </>
    ),
  },
  {
    trigger: "How quickly can I speak to someone?",
    content: (
      <P>
        With next-day availability, you should always be able to speak to
        someone as soon as you need to.
      </P>
    ),
  },
  {
    trigger: "How is bereavement support different from therapy?",
    content: (
      <>
        <P>
          Whereas therapy will be with any of the 80+ counsellors who work with
          Spill, bereavement support is with a much smaller group of bereavement
          specialists who have specific experience working with loss. You
          don&apos;t have to talk about the person you&apos;ve lost, but
          they&apos;ll be equipped to discuss your grief with you if you&apos;d
          like to.
        </P>
        <P>
          You&apos;ll also have longer to use your sessions, with more
          flexibility as to when you use them. Finally, bereavement support is
          in addition to any sessions you&apos;re provided automatically by your
          company.
        </P>
      </>
    ),
  },
  {
    trigger: "Do I have to use my sessions now?",
    content: (
      <P>
        No. Your six sessions are yours to use any time you&apos;d like over the
        next two years, whether you book them all in now or wait a while before
        booking them in. You can also have some sessions now, and save the rest
        to use later. The only way you might lose access to these sessions is if
        your company stops paying for Spill and closes their account.
      </P>
    ),
  },
]

export const FAQBereavement: FunctionComponent = () => {
  return <Accordion items={accordionItems} />
}
