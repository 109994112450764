import type { Exercise } from "../constants/exercises"

type ExerciseCardProps = {
  exercise: Exercise
}

export function ExerciseCard({ exercise }: ExerciseCardProps): JSX.Element {
  /*
    TODO: 
      make this a link to a page that hosts the exercise in code 
      the problem here is the instructions are all formatted 
      slightly differently. Rather than coding individual components 
      I felt it might be better to do instructions in markdown 
      and use a markdown renderer
  */
  return (
    <a
      className="box p-0"
      href={exercise.pdfUrl}
      target="_"
      rel="noopener noreferrer"
    >
      <img
        src={exercise.imgUrl}
        alt={exercise.imgUrl}
        className="h-56 w-full object-cover object-top"
      />

      <div className="flex flex-col p-4 lg:p-6 gap-xs">
        <header className="flex justify-between">
          <h5>{exercise.title}</h5>
          <p className="not-italic text-sm">
            ⏳ {exercise.durationMinutes} mins
          </p>
        </header>
        <p>{exercise.description}</p>
      </div>
    </a>
  )
}
