import { gql } from "@apollo/client"

export const queries = {
  getData: gql`
    query TherapyAdhdData {
      user {
        revokedAt
        therapyUsageCap
        companyTherapyCap
        numberSessionsUsedInCapPeriod
        company {
          name
          isLegacyPricing
          inActiveTrialPeriod
          subscriptionStatus
          trialStartDate
          budgetYearUsage {
            totalBillableUsage
          }
          currentSessionPackUsage
        }
        featuresAndSettings {
          oneOffs {
            value
          }
          userCanRequestTherapy {
            value
          }
          yearlyTherapyBudget {
            value
          }
          userTherapyCap {
            active
          }
          sessionPack {
            value
          }
          courses {
            value
          }
          adviceLibrary {
            value
          }
        }
        accessToTherapyFeatures {
          oneOffs {
            hasAccess
            reason
          }
        }
      }
    }
  `,
}
