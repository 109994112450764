import { Link, useLocation } from "react-router-dom"
import { Button, H1 } from "@spillchat/puddles"

import { cn } from "common/helpers/cn"
import { useAuth } from "common/context/authContext"

import type { FunctionComponent } from "react"

interface NotFoundPageProps {
  className?: string
}

/**
 * Not found 404 page/component for Spill v3
 */
export const NotFoundPage: FunctionComponent<NotFoundPageProps> = ({
  className,
}) => {
  const { user } = useAuth()
  const location = useLocation()

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center relative",
        className
      )}
    >
      <div className="flex flex-col items-center gap-5">
        <H1>Page not found</H1>
        {user != null ? (
          <Button asChild>
            <Link to="/">Go to Spill home</Link>
          </Button>
        ) : (
          <Button asChild>
            <Link
              to={{
                pathname: "/signin",
                search: `redirect=${encodeURIComponent(
                  `${location.pathname}${location.search}${location.hash}`
                )}`,
              }}
            >
              Sign in
            </Link>
          </Button>
        )}
      </div>
    </div>
  )
}
