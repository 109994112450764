import { gql } from "@apollo/client"

export const mutations = {
  submitFeedbackV5: gql`
    mutation FeedbackFormV5Form($input: FeedbackTherapySessionV1Input!) {
      submitFeedbackTherapySessionV1(input: $input)
    }
  `,
  createCompanyPublicReview: gql`
    mutation FeedbackFormPublicReview($input: CompanyPublicReviewInput!) {
      createCompanyPublicReview(input: $input) {
        id
      }
    }
  `,
  submitFeedbackConsultation: gql`
    mutation FeedbackConsultationForm($input: FeedbackConsultationV1Input!) {
      submitFeedbackConsultationV1(input: $input)
    }
  `,
}
