import { z } from "zod"

export const formSchema = z.object({
  userFacingDescription: z.string().min(1, {
    message: "Please enter a description",
  }),
  numberSessions: z.number().min(2, {
    message: "Please select a minimum of 2 sessions",
  }),
  numberMonthsToUse: z.number().nullable().optional().default(null),
  forAllEmployees: z.boolean(),
  visibleToAllEmployees: z.boolean(),
  icon: z.string().min(1, {
    message: "Please choose an icon",
  }),
  name: z.string().min(1, {
    message: "Please choose a name",
  }),
})

export type AdminTherapySpecialisedSupportEditForm = z.infer<typeof formSchema>
