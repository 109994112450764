import { gql } from "@apollo/client"

export const fragments = {
  getLoginMethods: gql`
    fragment LoginMethodsGetLoginMethods on User {
      platformUsers {
        email
        createdAt
        platform {
          platformType
        }
        isPersonal
      }
      therapyProfile {
        email
      }
    }
  `,
}
