/**
 * Displays a form for the user to enter the OTP sent to their email address
 */

import { FunctionComponent } from "react"
import { H3, P } from "@spillchat/puddles"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "sonner"

import { OTPInput } from "common/components/OTPInput"

export const OTPConfirmation: FunctionComponent = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const emailAddress = searchParams.get("email")

  if (emailAddress == null) {
    toast.error(
      "We were unable to determine the email address you tried to register with. Please try again"
    )
    navigate("/")
    return null
  }

  return (
    <div className="flex flex-col gap-4">
      <H3>Check your email for a code</H3>
      <P>
        We've sent a 9-character code to {emailAddress}. The code expires
        shortly so please enter it soon.
      </P>
      {/* TODO The failure case is nonsense but there's no other place to handle it automatically */}
      <OTPInput
        align="left"
        email={emailAddress}
        onOTPSuccess={() => navigate("/")}
        onOTPFailure={() => navigate("/")}
      />
    </div>
  )
}
