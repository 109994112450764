/**
 * Format date to ISO 8601 compliant year and month string, enabling us to
 * work with monthly data without the hassles of timezones.
 * This format is also used in our custom GraphQL Month scalar.
 */
import { format } from "date-fns"

export function formatMonth(date: Date): string {
  return format(date, "yyyy-MM")
}
