import { gql, useMutation, useQuery } from "@apollo/client"
import { Button, H1, H3, P, Tooltip, CopyField } from "@spillchat/puddles"
import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet-async"
import { toast } from "sonner"
import { captureException } from "@sentry/react"
import {
  ArrowUturnLeftIcon,
  CalendarIcon,
  GiftIcon,
  ShareIcon,
  LinkIcon,
} from "@heroicons/react/24/outline"

import { config } from "config"
import {
  PlusOneCreatePlusOneInviteMutation,
  PlusOneUserPlusOneInviteQuery,
  SpillSubscriptionPlan,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useAuth } from "common/context/authContext"

const queries = {
  userPlusOneInvite: gql`
    query PlusOneUserPlusOneInvite {
      user {
        id
        plusOneInvite
        therapyUsageCap
        numberSessionsUsedInCapPeriod
        company {
          subscriptionPlan
        }
      }
    }
  `,
}

const mutations = {
  createPlusOneInvite: gql`
    mutation PlusOneCreatePlusOneInvite {
      createPlusOneInvite
    }
  `,
}

export const PlusOnePage: FunctionComponent = () => {
  const { user } = useAuth()

  const { data, loading, refetch } = useQuery<PlusOneUserPlusOneInviteQuery>(
    queries.userPlusOneInvite
  )

  const [createPlusOneInvite, { loading: mutationLoading }] =
    useMutation<PlusOneCreatePlusOneInviteMutation>(
      mutations.createPlusOneInvite
    )

  async function handleGenerateLink() {
    const { data } = await createPlusOneInvite()

    if (data?.createPlusOneInvite === true) {
      void refetch()
      toast.success("New Plus One link generated")
    } else {
      captureException("Could not generate Plus One invite for user", {
        user: { id: user?.id },
      })
      toast.error("Oops! There was an error generating your Plus One link")
    }
  }

  const plusOneInviteCode = data?.user?.plusOneInvite
  const plusOneInviteLink =
    plusOneInviteCode != null
      ? `${config.spill.webApp.baseUrl}/signup/plus-one/${plusOneInviteCode}`
      : null

  const hasAtLeastOneCredit =
    data?.user?.therapyUsageCap ??
    0 - (data?.user?.numberSessionsUsedInCapPeriod ?? 0)

  const { subscriptionPlan } = data?.user?.company ?? {}
  const isLegacyPlan =
    subscriptionPlan != null
      ? [SpillSubscriptionPlan.LITE, SpillSubscriptionPlan.PROPER].includes(
          subscriptionPlan
        )
      : false

  return (
    <>
      <Helmet title="Plus One | Spill" />
      <section className="flex flex-col gap-32">
        <div className="flex flex-col gap-12">
          <H1>Send a Plus One</H1>
          <div className="grid grid-cols-3 gap-10 w-full">
            <GreyInfoBox
              icon={<GiftIcon className="size-8" />}
              title="Send free therapy"
              description="Offer a 50 minute session of therapy to a friend or family member, for free."
            />

            {isLegacyPlan ? (
              <GreyInfoBox
                icon={<ArrowUturnLeftIcon className="size-8" />}
                title="Don't let your credits go to waste"
                description="Plus One only makes use of unused credits from your company plan."
              />
            ) : (
              <GreyInfoBox
                icon={<ShareIcon className="size-8" />}
                title="Share your therapy allowance"
                description="Plus One sessions come out of your individual session credits."
              />
            )}

            <GreyInfoBox
              icon={<CalendarIcon className="size-8" />}
              title="Resets every 6 months"
              description="You can share your Plus One link once every 6 months."
            />
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center">
            <LoadingSpinner sizeInPixels={50} />
          </div>
        ) : (
          <div className="h-full flex justify-center items-center">
            <div className="flex flex-col items-center gap-7 text-center">
              <H3>Link to share</H3>
              <span className="w-80">
                <P>
                  {plusOneInviteLink == null
                    ? "Generate a link for somebody to sign up and book a free session on Spill."
                    : "Simply copy the invitation link to your clipboard and send it to your chosen person."}
                </P>
              </span>

              {plusOneInviteLink != null ? (
                <CopyField
                  copyValue={plusOneInviteLink}
                  onCopied={() => {
                    toast.success("Link copied to clipboard")
                  }}
                  icon={<LinkIcon />}
                />
              ) : (
                <>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      {!hasAtLeastOneCredit && (
                        <Tooltip.Content>
                          You don't have enough therapy credits to be able to
                          gift a Plus One session.
                        </Tooltip.Content>
                      )}
                      <Tooltip.Trigger>
                        <Button
                          variant="secondary"
                          onClick={handleGenerateLink}
                          disabled={mutationLoading || !hasAtLeastOneCredit}
                        >
                          <span className="flex gap-3 items-center">
                            {mutationLoading ? "Generating" : "Generate"}{" "}
                            shareable link{" "}
                            {mutationLoading && (
                              <LoadingSpinner sizeInPixels={15} />
                            )}
                          </span>
                        </Button>
                      </Tooltip.Trigger>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                  <span className="w-80">
                    <P muted size="xs">
                      Generating a link will automatically deduct one session
                      from your allowance
                    </P>
                  </span>
                </>
              )}
            </div>
          </div>
        )}
      </section>
    </>
  )
}

interface GreyInfoBoxProps {
  icon: React.ReactNode
  title: string
  description: string
}

const GreyInfoBox: FunctionComponent<GreyInfoBoxProps> = (
  props: GreyInfoBoxProps
) => {
  return (
    <div className="bg-grey-100 rounded-lg flex flex-col p-5 gap-8">
      {props.icon}
      <div className="flex flex-col gap-3">
        <P weight="medium">{props.title}</P>
        <P size="xs">{props.description}</P>
      </div>
    </div>
  )
}
