import { gql } from "@apollo/client"

export const queries = {
  getCounsellor: gql`
    query AdviceLibraryGetCounsellor($counsellorId: ID!) {
      questionAnsweringCounsellor(id: $counsellorId) {
        avatarUrl
        bio
        firstName
        lastName
        professionalBody
      }
    }
  `,
}
