import { FunctionComponent, useEffect } from "react"
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { P } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import "@spillchat/puddles/dist/puddles.css"

// Context imports
import { useApp } from "common/context/appContext"
import { useAuth } from "common/context/authContext"
// Common component imports
import { ConditionalRedirect } from "common/components/ConditionalRedirect"
import { Dialog } from "common/components/Dialog"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { NotFoundPage } from "common/components/NotFoundPage"
// Layout imports
import {
  NavigationLayout,
  fragments as NavigationLayoutFragments,
} from "common/components/Layouts/NavigationLayout"
// Admin imports
import { AdminRouter, fragments as AdminRouterFragments } from "features/admin"
// Auth imports
import {
  RequireAgreements,
  fragments as RequireConsentFragments,
} from "features/auth/components/RequireAgreement"
import {
  RequireRole,
  fragments as RequireRoleFragments,
} from "features/auth/components/RequireRole"
import { fragments as RequireSubscriptionPlanFragments } from "features/auth/components/RequireSubscriptionPlan"
// Feedback imports
import { TherapySessionFeedbackPage } from "features/feedback/pages/TherapySessionFeedbackPage"
// Messages imports
import { SpillMessagePage } from "features/messages/pages/SpillMessagePage"
// Pulse imports
import { PulseRouter } from "features/pulse"
// Therapy imports
import { TherapyRouter } from "features/therapy"
import {
  Spill3AppGetUserQuery as QueryData,
  Spill3AppGetUserQueryVariables as QueryVariables,
  SpillSubscriptionPlan,
  SpillSubscriptionStatus,
  UserRole,
} from "types/graphql"
import { GetHelp } from "features/admin/pages/GetHelp"
import { ResourcesRouter } from "features/resources"
import { AppointmentJoinPage } from "features/therapy/pages/AppointmentJoinPage"
import { UserProvider } from "common/context/userContext"
import { ProfilePage } from "features/profile/pages/Profile"
import { TriageFeedback } from "features/feedback/pages/TriageFeedback"
import { ImpersonationPanel } from "common/components/ImpersonationPanel"
import { config } from "config"
import {
  IntroducingUserCaps,
  fragments as IntroducingUserCapsFragments,
} from "common/components/modals/IntroducingUserCaps"
import { OnboardingProvider } from "common/context/onboardingContext"
import { AdviceLibraryRouter } from "features/advice-library"
import { LinkAccount } from "features/link-account/link-account.page"
import { ProductFruitsWrapper } from "common/components/ProductFruitsWrapper"

/**
 * There's a few things we need to know about the user when they first land on the app.
 * If they're authenticated, we need to know their role, and if they're an admin, we need
 * to figure out if they've got any pending invites.
 */
const fragments = {
  queryFields: gql`
    fragment Spill3AppQueryFields on Query {
      user {
        id
        role
        primaryEmail
        accountStatus
        company {
          id
          subscriptionPlan
          subscriptionStatus
          hasInvitedUsers
        }
        ...RequireRoleUserFields
      }
      ...AdminRouterQueryFields
      ...NavigationLayoutQueryFields
      ...RequireConsentQueryFields
      ...RequireSubscriptionPlanQueryFields
      ...IntroducingUserCapsQueryFields
    }
    ${AdminRouterFragments.queryFields}
    ${NavigationLayoutFragments.queryFields}
    ${RequireConsentFragments.queryFields}
    ${RequireSubscriptionPlanFragments.queryFields}
    ${RequireRoleFragments.userFields}
    ${IntroducingUserCapsFragments.queryFields}
  `,
}

export const queries = {
  getUser: gql`
    query Spill3AppGetUser {
      ...Spill3AppQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const App: FunctionComponent = () => {
  const { user, isUserLoading } = useAuth()
  const { setBannerContent, appModalContent, setAppModalContent } = useApp()
  const location = useLocation()

  /**
   * Let's add the spill3 className to the body so we can change the background
   * (We'll also remove it on unmount even though it's not strictly necessary)
   */
  useEffect(() => {
    document.body.classList.add("bg-spill-mono-white")

    return () => {
      document.body.classList.remove("bg-spill-mono-white")
    }
  }, [])

  // We try to get the basic user details on load. We need the
  // role in order to determine any subsequent queries we need to make.
  const { data, loading: userLoading } = useQuery<QueryData, QueryVariables>(
    queries.getUser,
    {
      fetchPolicy: "cache-first",
    }
  )

  const subscriptionStatus = data?.user?.company?.subscriptionStatus
  const subscriptionPlan = data?.user?.company?.subscriptionPlan

  useEffect(() => {
    if (
      user != null &&
      data?.user?.role === UserRole.ADMIN &&
      (subscriptionStatus === SpillSubscriptionStatus.INACTIVE ||
        subscriptionStatus == null) &&
      subscriptionPlan === SpillSubscriptionPlan.STARTER &&
      location.pathname !== "/admin/quote"
    ) {
      setBannerContent(
        <P>
          Give your employees access to therapy by{" "}
          <Link className="underline" to="/admin/billing">
            setting up your Spill subscription
          </Link>
        </P>
      )
    } else {
      setBannerContent(null)
    }
  }, [user == null, subscriptionStatus, subscriptionPlan])

  if (isUserLoading || userLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  return (
    <UserProvider>
      <ProductFruitsWrapper />
      <OnboardingProvider>
        <Routes>
          {/* Fullscreen routes */}
          <Route path="feedback">
            <Route
              path="session/:appointmentId"
              element={<TherapySessionFeedbackPage />}
            />

            <Route path="triage/*" element={<TriageFeedback />} />
          </Route>

          <Route
            path="/pulse/*"
            element={
              <div className="flex min-h-screen w-full flex-col items-center justify-center md:flex-row">
                <div className="flex min-w-0 max-w-screen-2xl grow flex-col">
                  <PulseRouter />
                </div>
              </div>
            }
          />

          <Route
            path="/therapy/sessions/:appointmentId/join"
            element={
              <>
                <Helmet title="Join session | Spill" />
                <AppointmentJoinPage />
              </>
            }
          />

          <Route element={<NavigationLayout data={data} />}>
            <Route
              index
              element={
                <>
                  {userLoading ? (
                    <div className="flex h-full items-center justify-center">
                      <LoadingSpinner sizeInPixels={50} />
                    </div>
                  ) : (
                    <ConditionalRedirect
                      conditions={data?.user?.role === UserRole.ADMIN}
                      to="/admin"
                    >
                      <Navigate to="/therapy/sessions" />
                    </ConditionalRedirect>
                  )}
                </>
              }
            />
            <Route
              // if this path changes the frontFormUrl also needs updating in the
              // front form config
              path="help"
              element={
                <GetHelp frontFormUrl="https://webhook.frontapp.com/forms/59a6bbf15a7ec52638c7/IpDxv7s9GpDLC8FnZTGfN8VPquEbGEtBdmj66R7wMm4zeWZYhL98bhOqUAggWrfY4w0lRGZue9SUH7Qc07h3NIw_m0sWxS2MwCI2g6FwDWOkkwXaIXRYhh1GpQOHjw" />
              }
            />

            <Route path="profile" element={<ProfilePage />} />
            <Route path="profile/link-account" element={<LinkAccount />} />

            <Route
              path="therapy/*"
              element={
                <>
                  <RequireAgreements />
                  <TherapyRouter />
                </>
              }
            />
            <Route
              path="admin/*"
              element={
                <RequireRole role={UserRole.ADMIN}>
                  <AdminRouter />
                </RequireRole>
              }
            />

            <Route path="feedback">
              <Route
                path="session/:appointmentId"
                element={<TherapySessionFeedbackPage />}
              />

              <Route path="triage/*" element={<TriageFeedback />} />
            </Route>

            <Route path="messages/:messageId" element={<SpillMessagePage />} />

            <Route path="/resources/*" element={<ResourcesRouter />} />

            <Route path="advice-library/*" element={<AdviceLibraryRouter />} />

            <Route path="*" element={<NotFoundPage className="grow" />} />
          </Route>
        </Routes>

        {appModalContent != null && (
          <Dialog
            title={appModalContent.title ?? undefined}
            isOpen={appModalContent != null}
            maxWidth={appModalContent.size ?? "5xl"}
            canClose
            onClose={() => setAppModalContent(null)}
          >
            {appModalContent.content}
          </Dialog>
        )}

        {/* Here's a modal for caps and budgets based on looking for it in the notifications */}
        <IntroducingUserCaps />
        {config.impersonateEnabled && <ImpersonationPanel />}
      </OnboardingProvider>
    </UserProvider>
  )
}
