import { createContext, useContext, useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { queries } from "Spill3App"

import { AppBanner } from "common/components/AppBanner"
import { DialogMaxWidth } from "common/components/Dialog"

import type {
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
} from "react"

type AppModalContent = {
  content: ReactNode
  title: string | null
  size: DialogMaxWidth | null
} | null

interface AppContextData {
  isBannerVisible: boolean
  bannerContent: ReactNode | null
  appModalContent: AppModalContent
  /**
   * @deprecated Page blobs are no longer part of the design, so they will no longer
   * render on the page. Functions will be removed in the future.
   */
  pageBlob: "top" | "bottom" | "none"
  setBannerContent: Dispatch<SetStateAction<ReactNode | null>>
  /**
   * Allows us to add a background blob to the top or bottom of the page.
   */
  setPageBlob: Dispatch<SetStateAction<"top" | "bottom" | "none">>
  setAppModalContent: Dispatch<SetStateAction<AppModalContent>>
  acknowledgeUserNotification: {
    acknowledge: (notificationId: string) => void
    loading: boolean
  }
  /**
   * Hint the user to use the user type admin/user toggle
   */
  setHintUserSwitch: Dispatch<SetStateAction<boolean>>
  hintUserSwitch: boolean
  appTimeZone: string
  setAppTimeZone: Dispatch<SetStateAction<string>>
}

// Current time zone string from the user's browser
const BROWSER_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone
const INITIAL_TIME_ZONE =
  localStorage.getItem("appTimeZone") ?? BROWSER_TIME_ZONE

const AppContext = createContext<AppContextData>({
  isBannerVisible: false,
  pageBlob: "none",
  bannerContent: null,
  appModalContent: null,
  setBannerContent: () => {},
  setPageBlob: () => {},
  setAppModalContent: () => {},
  acknowledgeUserNotification: {
    acknowledge: () => {},
    loading: false,
  },
  setHintUserSwitch: () => {},
  hintUserSwitch: false,
  appTimeZone: INITIAL_TIME_ZONE,
  setAppTimeZone: () => {},
})

export const useApp = (): AppContextData => useContext(AppContext)

/**
 * An array of routes where we just do NOT want to show the banner.
 * We can usually control this using the `setBannerContent` function
 * but this will lose the content. This is a quick fix for one route atm.
 */
const routesToHideBanner = ["/signup/plan"]

/**
 * We want the app to be able to dismiss notifications from anywhere in the app.
 */
export const mutations = {
  acknowledgeUserNotification: gql`
    mutation AcknowledgeUserNotification($userNotificationId: ID!) {
      acknowledgeUserNotification(userNotificationId: $userNotificationId)
    }
  `,
}

export const AppProvider: FunctionComponent<PropsWithChildren> = props => {
  /**
   * We sometimes show an app-wide banner at the top of the page. This is used to show things like
   * "Set up your subscription" etc. When this is visible, we need to calc the height of the banner
   * so that we don't add any unnecessary scrollbars.
   */
  const [bannerContent, setBannerContent] = useState<ReactNode>(null)
  const [appModalContent, setAppModalContent] = useState<AppModalContent>(null)
  const [pageBlob, setPageBlob] = useState<"top" | "bottom" | "none">("none")
  const [hintUserSwitch, setHintUserSwitch] = useState(false)
  const [appTimeZone, setAppTimeZone] = useState<string>(INITIAL_TIME_ZONE)
  const [acknowledgeUserNotification, { loading: notificationLoading }] =
    useMutation(mutations.acknowledgeUserNotification, {
      refetchQueries: [queries.getUser],
    })

  useEffect(() => {
    // Only store timezone information if it is different from system default
    if (appTimeZone !== BROWSER_TIME_ZONE) {
      localStorage.setItem("appTimeZone", appTimeZone)
    } else {
      localStorage.removeItem("appTimeZone")
    }
  }, [appTimeZone])

  const isBannerVisible = bannerContent !== null

  return (
    <AppContext.Provider
      value={{
        isBannerVisible,
        bannerContent,
        pageBlob,
        appModalContent,
        setBannerContent,
        setPageBlob,
        setAppModalContent,
        setHintUserSwitch,
        hintUserSwitch,
        acknowledgeUserNotification: {
          acknowledge: async (notificationId: string) => {
            await acknowledgeUserNotification({
              variables: {
                userNotificationId: notificationId,
              },
            })
          },
          loading: notificationLoading,
        },
        appTimeZone,
        setAppTimeZone,
      }}
    >
      {bannerContent !== null &&
        !routesToHideBanner.includes(window.location.pathname) && (
          <AppBanner>{bannerContent}</AppBanner>
        )}

      {props.children}
    </AppContext.Provider>
  )
}
