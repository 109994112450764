import { useMutation, useQuery } from "@apollo/client"
import {
  CalendarDateRangeIcon,
  ChatBubbleLeftIcon,
  ChevronLeftIcon,
  EyeSlashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"
import {
  Alert,
  Button,
  H1,
  H2,
  H3,
  HeaderCard,
  P,
  ProgressBar,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"

import customPlaceholder from "common/assets/images/product/specialised-support/therapy-custom-grey.png"
import { Dialog } from "common/components/Dialog"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { formatDate, monthsToDate } from "common/helpers/formatDate"
import {
  AppointmentTypeFeature,
  TherapyExtensionStatus,
  TherapySpecialisedSupportPackageCancelMutation,
  TherapySpecialisedSupportPackageCancelMutationVariables,
  TherapySpecialisedSupportPackageLifeEventQuery,
  TherapySpecialisedSupportPackageLifeEventQueryVariables,
  TherapySpecialisedSupportPackageQuery,
  TherapySpecialisedSupportPackageQueryVariables,
} from "types/graphql"

import { mutations } from "./therapy-specialised-support-package.mutations"
import { queries } from "./therapy-specialised-support-package.queries"

export const TherapySpecialisedSupportPackagePage: FunctionComponent = () => {
  const navigate = useNavigate()
  const params = useParams<{ packageId: string | undefined }>()

  const [cancelRequestModal, setCancelRequestModal] = useState(false)
  const toggleCancelRequestModal = () => {
    setCancelRequestModal(!cancelRequestModal)
  }

  const { data, called, loading } = useQuery<
    TherapySpecialisedSupportPackageQuery,
    TherapySpecialisedSupportPackageQueryVariables
  >(queries.getPackage, {
    variables: {
      id: params.packageId ?? "",
    },
    nextFetchPolicy: "network-only",
  })

  const { data: lifeEvent } = useQuery<
    TherapySpecialisedSupportPackageLifeEventQuery,
    TherapySpecialisedSupportPackageLifeEventQueryVariables
  >(queries.getLifeEvent, {
    variables: {
      id: data?.getCompanyPackageSettingById?.lifeEventId ?? "",
    },
  })

  const user = data?.user

  const pkg = data?.getCompanyPackageSettingById
  const pkgRequests = data?.user?.therapyExtensions.filter(
    extension => extension.companyPackageSetting?.id === params.packageId
  )

  const currentTherapyPackage = data?.user?.therapyPackages?.find(pkg2 => {
    return pkg2.identifier === pkg?.id
  })?.therapyPackages[0]

  const pkgRequest =
    pkgRequests && pkgRequests.length > 0
      ? pkgRequests.reduce((a, b) => {
          return new Date(a.createdAt) > new Date(b.createdAt) ? a : b
        })
      : null

  const [cancelRequest, { loading: cancelLoading }] = useMutation<
    TherapySpecialisedSupportPackageCancelMutation,
    TherapySpecialisedSupportPackageCancelMutationVariables
  >(mutations.cancelRequest, {
    refetchQueries: ["TherapySpecialisedSupportPackage"],
    variables: {
      id: pkgRequest?.id ?? "",
    },
    onCompleted() {
      toggleCancelRequestModal()
      toast("Request has been cancelled")
    },
  })

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  return (
    <>
      <Helmet title={`${pkg?.name ?? "Specialised support"} | Spill`} />
      <div className="flex flex-col gap-12">
        <Button variant="tertiary" asChild>
          <Link to="/therapy/specialised-support" className="no-underline">
            <div className="flex gap-1 items-center">
              <ChevronLeftIcon className="size-4" />
              <P weight="medium">Back</P>
            </div>
          </Link>
        </Button>
        <div className="flex flex-col-reverse lg:flex-row gap-8 lg:grow">
          <div className="flex flex-col gap-5 lg:w-2/3">
            <H1>{pkg?.name}</H1>
            <div className="flex flex-col gap-3">
              <H3>How it works</H3>
              <P muted>{pkg?.userFacingDescription}</P>
            </div>
          </div>
          <div className="grow">
            <div className="lg:bg-transparent bg-grey-100 rounded-lg flex items-center justify-center lg:min-w-32 lg:min-h-32 aspect-square">
              <img
                src={pkg?.iconUrl ?? customPlaceholder}
                alt="Custom support"
                className="rounded-lg lg:w-full lg:h-full flex aspect-ratio"
              />
            </div>
          </div>
        </div>
        {pkgRequest?.status !== TherapyExtensionStatus.APPROVED && (
          <>
            <hr />
            <div className="flex flex-col gap-8 max-w-md">
              <div className="flex flex-col gap-3">
                <H2>Request this support</H2>
                <P muted>
                  Send a request to your admin to access this support.
                </P>
              </div>
              <div className="grid">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-4">
                    <H3>What's included</H3>
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-4">
                        <UsersIcon className="text-blue-800 size-6" />
                        <P muted>Specialists tailored to your needs</P>
                      </div>
                      <div className="flex items-center gap-4">
                        <ChatBubbleLeftIcon className="text-blue-800 size-6" />
                        <P muted>
                          {pkg?.numberSessions} sessions on top of your regular
                          allowance
                        </P>
                      </div>
                      <div className="flex items-center gap-4">
                        <CalendarDateRangeIcon className="text-blue-800 size-6" />
                        <P muted>
                          Support{" "}
                          {pkg?.numberMonthsToUse != null
                            ? `for ${pkg?.numberMonthsToUse} ${pkg?.numberMonthsToUse > 1 ? " months" : " month"}`
                            : "indefinitely"}
                        </P>
                      </div>
                    </div>
                  </div>
                  {(pkgRequest == null ||
                    pkgRequest?.status === TherapyExtensionStatus.DECLINED ||
                    pkgRequest?.status ===
                      TherapyExtensionStatus.CANCELLED_BY_USER) && (
                    <>
                      <Button size="sm" variant="primary" asChild>
                        <Link to="request">Request support</Link>
                      </Button>
                      <div className="flex items-center gap-2">
                        <EyeSlashIcon className="size-4 text-teal-400" />
                        <P size="xs" muted>
                          Your name won't be shared unless you choose to
                        </P>
                      </div>
                    </>
                  )}
                  {pkgRequest?.status === TherapyExtensionStatus.PENDING && (
                    <>
                      <Button
                        size="sm"
                        variant="destructive"
                        disabled={cancelLoading}
                        onClick={() => toggleCancelRequestModal()}
                      >
                        Cancel request
                      </Button>
                      <P size="xs" muted>
                        We've sent the request to your admins. They'll have 7
                        days to make a decision. Once they've done this, we'll
                        let you know.
                      </P>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {pkgRequest?.status === TherapyExtensionStatus.APPROVED && (
          <div className="flex flex-col gap-8 xl:max-w-2xl">
            <div className="flex flex-col gap-3">
              <H2>Support</H2>
              <P muted>
                You get extra sessions on top of your regular allowance to speak
                to a&nbsp;specialist.
              </P>
            </div>
            <div className="flex gap-8 w-full">
              {currentTherapyPackage != null ? (
                <div className="flex flex-col lg:flex-row gap-5 items-end">
                  <ProgressBar
                    progressColour="spill-teal-600"
                    progressBackgroundColour="spill-teal-100"
                    currentProgress={
                      currentTherapyPackage?.numberSessionsUsed ?? 0
                    }
                    totalProgress={currentTherapyPackage?.numberOfSessions ?? 0}
                    title={`${pkg?.name} credits`}
                    progressDetail={`credits used`}
                  />
                  <ProgressBar
                    totalProgress={user?.therapyUsageCap ?? 0}
                    currentProgress={user?.numberSessionsUsedInCapPeriod ?? 0}
                    title={`Spill credits`}
                    progressDetail={`credits used`}
                  />
                </div>
              ) : (
                <Alert
                  variant="warning"
                  title="We currently cannot calculate your session usage. Please try again later or email therapy@spill.chat."
                ></Alert>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <P weight="medium">
                You were given {pkg?.name}{" "}
                {pkg?.numberMonthsToUse != null
                  ? `until ${formatDate(monthsToDate(pkg?.numberMonthsToUse))}`
                  : "indefinitely"}
              </P>
              <P size="xs" muted>
                If you use all your specialist credits before then, you can use
                your regular session credits to book with the same specialist.
              </P>
            </div>
            <HeaderCard
              title="Specialist support session"
              icon={{
                name: "User",
                type: "outline",
              }}
              description="Book a session with your specialist"
            >
              <div className="flex flex-wrap gap-4">
                <Button
                  variant="secondary"
                  size="sm"
                  disabled={lifeEvent == null}
                  onClick={() => {
                    const appointmentTypeFeature =
                      AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION
                    navigate(
                      `/therapy/sessions/book?appointmentType=${appointmentTypeFeature}&lifeEvent=${lifeEvent?.getLifeEventFromId?.slug}`
                    )
                  }}
                >
                  Book a session
                </Button>
              </div>
            </HeaderCard>
          </div>
        )}
      </div>
      <Dialog
        canClose
        isOpen={cancelRequestModal}
        onClose={() => toggleCancelRequestModal()}
        title="Cancel request?"
        buttons={[
          {
            key: "cancel",
            variant: "secondary",
            children: "Not now",
            onClick: () => toggleCancelRequestModal(),
          },
          {
            key: "confirm",
            variant: "destructive",
            children: "Cancel request",
            onClick: async () => {
              await cancelRequest()
              toggleCancelRequestModal()
            },
          },
        ]}
      >
        <P>
          You will be able to request this again if you change your&nbsp;mind.
        </P>
      </Dialog>
    </>
  )
}
