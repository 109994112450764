import { Input, H2, Form } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { toast } from "sonner"
import { useMutation } from "@apollo/client"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormFieldWrapper } from "components/form/FormFieldWrapper"

import {
  LoginMethodsGetLoginMethodsFragment,
  LoginMethodsUpdatePersonalEmailMutation,
  LoginMethodsUpdatePersonalEmailMutationVariables,
  PlatformType,
} from "types/graphql"

import { mutations } from "./LoginMethods.mutations"
import { loginMethodsSchema, LoginMethodsValues } from "./LoginMethods.schema"

type LoginMethodsProps = {
  loginMethods?: LoginMethodsGetLoginMethodsFragment | null
}

export const LoginMethods: FunctionComponent<LoginMethodsProps> = props => {
  const companyEmails = new Set<string>()
  const allNonPersonalEmails = new Set<string>()
  const nonCompanyEmails = new Set<string>()

  if (props.loginMethods != null) {
    props.loginMethods?.platformUsers.forEach(platformUser => {
      if (
        platformUser.platform?.platformType != null &&
        [PlatformType.CUSTOM, PlatformType.SLACK, PlatformType.TEAMS].includes(
          platformUser.platform.platformType
        )
      ) {
        // But if they have an email, also show them as a work email
        if (platformUser.email != null) {
          companyEmails.add(platformUser.email.toLowerCase())
        }
      }
      // if they're explicitly set to personal, don't add to allNonPersonalEmails, as companyEmails pulls from there
      else if (platformUser.email != null && platformUser.isPersonal == true) {
        nonCompanyEmails.add(platformUser.email.toLowerCase())
      } else if (platformUser.email != null) {
        allNonPersonalEmails.add(platformUser.email.toLowerCase())
      }
    })
    // this is insufficient, but the backend will handle it
    if (companyEmails.size === 0 && allNonPersonalEmails.size > 0) {
      companyEmails.add([...allNonPersonalEmails][0]!)
    }
  }

  const personalEmails = props.loginMethods?.platformUsers.filter(
    platformUser => {
      return platformUser.email != null && platformUser.isPersonal === true
    }
  )

  const personalEmail =
    personalEmails && personalEmails.length > 0
      ? (personalEmails.reduce((a, b) => {
          return new Date(a.createdAt) > new Date(b.createdAt) ? a : b
        }).email ?? "")
      : ""

  const companyEmail = [...companyEmails]

  const forms = {
    personalEmail: useForm<LoginMethodsValues>({
      resolver: zodResolver(loginMethodsSchema),
      values: {
        personalEmail,
      },
    }),
  }

  const [updateLoginMethods] = useMutation<
    LoginMethodsUpdatePersonalEmailMutation,
    LoginMethodsUpdatePersonalEmailMutationVariables
  >(mutations.updatePersonalEmail, {
    refetchQueries: ["ProfilePageGetUser"],
    onCompleted: () => toast.success("Personal email updated"),
    onError: () => {
      toast.error("It looks like something went wrong, please try again.")
    },
  })

  return (
    <div className="flex flex-col gap-12">
      <H2>Login details</H2>
      <div className="flex flex-col gap-5">
        <FormFieldWrapper
          label="Company email"
          displayValue={companyEmail[0] ?? ""}
        >
          <Form.Label>Company email</Form.Label>
        </FormFieldWrapper>
        <Form.Root {...forms.personalEmail}>
          <FormFieldWrapper<LoginMethodsValues>
            onSubmit={forms.personalEmail.handleSubmit(async values => {
              await updateLoginMethods({
                variables: {
                  email: values.personalEmail,
                },
              })
            })}
            description="You can use this as an alternative way to log in"
            label="Personal email"
            displayValue={forms.personalEmail.getValues("personalEmail")}
            values={["personalEmail"]}
          >
            <Form.Field
              control={forms.personalEmail.control}
              name="personalEmail"
              render={({ field }) => (
                <Form.Item>
                  <Form.Label className="text-mono-black">
                    Personal email
                  </Form.Label>
                  <Form.Control>
                    <Input {...field} />
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
          </FormFieldWrapper>
        </Form.Root>
      </div>
    </div>
  )
}
