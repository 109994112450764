import { gql, useQuery } from "@apollo/client"
import { isNil, isString } from "lodash"
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import {
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  LinkIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline"

import type {
  CrisisLine,
  CrisisLineCardGetCrisisLineQuery as QueryData,
  CrisisLineCardGetCrisisLineQueryVariables as QueryVariables,
} from "types/graphql"

const fragments = {
  crisisLineFields: gql`
    fragment CrisisLineCardCrisisLineFields on CrisisLine {
      id
      category
      description
      name
      website
      contacts {
        email
        notes
        phoneNumber
        smsNumber
      }
    }
  `,
}

const queries = {
  getCrisisLine: gql`
    query CrisisLineCardGetCrisisLine($id: ID!) {
      crisisLine(id: $id) {
        ...CrisisLineCardCrisisLineFields
      }
    }
    ${fragments.crisisLineFields}
  `,
}

type CrisisLineCardProps = {
  crisisLineId: CrisisLine["id"]
}

export function CrisisLineCard({
  crisisLineId,
}: CrisisLineCardProps): JSX.Element | null {
  const { data } = useQuery<QueryData, QueryVariables>(queries.getCrisisLine, {
    fetchPolicy: "cache-only",
    variables: { id: crisisLineId },
  })

  const crisisLine = data?.crisisLine

  if (isNil(crisisLine)) return null

  const { contacts, description, name, website } = crisisLine

  return (
    <article className="box flex flex-col gap-sm">
      <h5>{name}</h5>

      <ul className="flex flex-col gap-sm">
        {isString(website) && (
          <div className="flex items-center gap-xs text-grey-600">
            <LinkIcon className="shrink-0 size-4" />
            <p className="leading-5 text-grey-600">
              <a
                href={website}
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {website}
              </a>
            </p>
          </div>
        )}

        {(contacts ?? []).map(contact => {
          const email = contact.email
          const phone = contact.phoneNumber
          const sms = contact.smsNumber
          const isEmail = isString(email)
          const isPhone = isString(phone)
          const isSMS = isString(sms)

          return (
            <div
              key={contact.email}
              className="flex items-center gap-xs text-grey-600"
            >
              {isEmail && <EnvelopeIcon className="shrink-0 size-4" />}
              {isPhone && <PhoneIcon className="shrink-0 size-4" />}
              {isSMS && <ChatBubbleLeftIcon className="shrink-0 size-4" />}

              <p className="leading-5 text-grey-600">
                {isEmail && <a href={`mailto:${email}`}>{email}</a>}
                {isPhone && (
                  <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
                )}
                {isSMS && <a href={`sms:${sms}`}>{formatPhoneNumber(sms)}</a>}
                {isNil(contact.notes) ? null : (
                  <span className="cursor-default text-grey-600">
                    {" "}
                    ({contact.notes})
                  </span>
                )}
              </p>
            </div>
          )
        })}
      </ul>

      <p className="cursor-default">{description}</p>
    </article>
  )
}

CrisisLineCard.fragments = fragments
CrisisLineCard.queries = queries

function formatPhoneNumber(phoneNumber: string): string {
  return isValidPhoneNumber(phoneNumber)
    ? parsePhoneNumber(phoneNumber)?.formatNational()
    : phoneNumber
}
