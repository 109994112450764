/**
 * Sign up form for the buyer. Collects details about the user, their email and company name.
 * Upon submission, the user will be sent an OTP to their email address.
 */

import { gql, useMutation } from "@apollo/client"
import { setLoginAttemptInfo } from "supertokens-auth-react/recipe/passwordless"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Button, Form, H2, Input, P } from "@spillchat/puddles"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "sonner"

import { useAnalytics } from "common/context/analyticsContext"
import {
  SpillSubscriptionPlan,
  type SignUpFormSignUpBuyerMutation as MutationData,
  type SignUpFormSignUpBuyerMutationVariables as MutationVars,
} from "types/graphql"

import { SignupFormFieldValues } from ".."

const mutations = {
  signUpBuyer: gql`
    mutation SignUpFormSignUpBuyer(
      $displayName: String!
      $email: String!
      $companyName: String!
      $hasAgreedToTsAndCs: Boolean!
      $hasAgreedToHealthData: Boolean!
      $hasConfirmedAtLeast18: Boolean!
      $subscriptionPlan: SpillSubscriptionPlan
      $trial: Boolean!
      $sessionPackSize: Int
    ) {
      signUpBuyer(
        displayName: $displayName
        email: $email
        companyName: $companyName
        hasAgreedToTsAndCs: $hasAgreedToTsAndCs
        hasAgreedToHealthData: $hasAgreedToHealthData
        hasConfirmedAtLeast18: $hasConfirmedAtLeast18
        subscriptionPlan: $subscriptionPlan
        trial: $trial
        sessionPackSize: $sessionPackSize
      ) {
        deviceId
        preAuthSessionId
      }
    }
  `,
}

const formSchema = z.object({
  displayName: z.string().min(1, {
    message: "Please enter your first name",
  }),
  email: z
    .string()
    .min(1, {
      message: "Please enter your email address",
    })
    .email({ message: "Please enter a valid email address" }),
  companyName: z.string().min(1, {
    message: "Please enter your company name",
  }),
  hasAgreedToTsAndCs: z.boolean().default(true).catch(true),
  hasAgreedToHealthData: z.boolean().default(true).catch(true),
  hasConfirmedAtLeast18: z.boolean().default(true).catch(true),
})

type signUpForm = z.infer<typeof formSchema>

export const SignUpForm: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams()
  const form = useForm<signUpForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      displayName: "",
      companyName: "",
      email: "",
    },
  })
  const [signUpBuyer, { loading: mutationLoading }] = useMutation<
    MutationData,
    MutationVars
  >(mutations.signUpBuyer, {
    onError: () => {
      toast.error(
        "We were unable to create your account at this time. Please try again or contact us at support@spill.chat"
      )
    },
    onCompleted: async data => {
      const { deviceId, preAuthSessionId } = data.signUpBuyer
      await setLoginAttemptInfo({
        attemptInfo: {
          deviceId,
          preAuthSessionId,
          flowType: "USER_INPUT_CODE",
        },
      })
      navigate(`/signup/confirm?email=${form.getValues("email")}`)
    },
  })

  const onSubmit = (values: signUpForm) => {
    const queryPlan = searchParams.get("plan")?.toUpperCase()

    const subscriptionPlan =
      queryPlan != null &&
      Object.values(SpillSubscriptionPlan).includes(
        queryPlan as SpillSubscriptionPlan
      )
        ? (queryPlan.toUpperCase() as SpillSubscriptionPlan)
        : SpillSubscriptionPlan.STARTER

    const queryTrial = searchParams.get("trial")
    const packSizeStr = searchParams.get("pack-size")
    const sessionPackSize =
      packSizeStr != null ? Number.parseFloat(packSizeStr) : null
    const trial = queryTrial?.toLowerCase() === "true"

    void signUpBuyer({
      variables: {
        ...values,
        subscriptionPlan,
        trial,
        sessionPackSize,
      },
    })
  }

  const navigate = useNavigate()
  const { search } = useLocation()
  const { track } = useAnalytics()
  const suggestedPlan = (
    new URLSearchParams(search).get("plan") ?? ""
  ).toLowerCase()
  localStorage.setItem("suggestedPlan", suggestedPlan)

  const watchedFields = form.watch()

  // We have analytics here. We only want to trigger it once so when the\
  // user interacts with the input, we set the state to true and then
  // we won't trigger the analytics again.
  const [inputAnalyticsRun, setInputAnalyticsRun] = useState({
    displayNameRun: false,
    companyNameRun: false,
    emailRun: false,
    hasAgreedToTsAndCsRun: false,
    hasAgreedToHealthDataRun: false,
    hasConfirmedAtLeast18Run: false,
  })

  /**
   * This is purely here for analytics. We track when the user types into an input,
   * or checks a checkbox. We only want to track this once, so we use the state
   * to track whether we've already tracked it. If we haven't, we track it and
   * then set the state to true so we don't track it again.
   */
  useEffect(() => {
    const keys = Object.keys(watchedFields) as (keyof SignupFormFieldValues)[]

    keys.forEach(key => {
      if (
        watchedFields[key] !== "" &&
        watchedFields[key] !== false &&
        inputAnalyticsRun[`${key}Run`] === false
      ) {
        setInputAnalyticsRun(prevState => ({
          ...prevState,
          [`${key}Run`]: true,
        }))

        // We can either type or check a checkbox, so we need to check
        // which one we're doing and then track it accordingly.
        if (key === "hasAgreedToTsAndCs" || key === "hasAgreedToHealthData") {
          if (
            watchedFields.hasAgreedToHealthData &&
            watchedFields.hasAgreedToTsAndCs
          ) {
            track(
              "Admin selected 'Consent for 18+ and Privacy notice' on sign-up page"
            )
          }
        } else {
          track("Admin typed into signup form field", {
            "Form field": key.replace(/([A-Z])/g, " $1").trim(),
          })
        }
      }
    })
  }, [watchedFields])
  // End of analytics

  return (
    <>
      <div className="flex flex-col justify-center py-4">
        <H2>Create a free account</H2>
      </div>

      <Form.Root {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <Form.Field
            control={form.control}
            name="displayName"
            render={({ field }) => (
              <Form.Item>
                <Form.Control>
                  <Input
                    placeholder="Your name"
                    {...field}
                    label={{ children: "Name" }}
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
          <Form.Field
            control={form.control}
            name="companyName"
            render={({ field }) => (
              <Form.Item>
                <Form.Control>
                  <Input
                    placeholder="Company name"
                    {...field}
                    label={{ children: "Company name" }}
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
          <Form.Field
            control={form.control}
            name="email"
            render={({ field }) => (
              <Form.Item>
                <Form.Control>
                  <Input
                    placeholder="Email address"
                    {...field}
                    label={{ children: "Email address" }}
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
          <P size="xs">
            I’ve read and agreed to the{" "}
            <a
              href="https://www.spill.chat/legals/ts-and-cs"
              target="_blank"
              rel="noreferrer"
            >
              terms
            </a>{" "}
            and understood the{" "}
            <a
              href="https://www.spill.chat/legals/privacy-notice"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              privacy notice.
            </a>
          </P>
          <Button type="submit" variant="primary" loading={mutationLoading}>
            Create account
          </Button>
        </form>
      </Form.Root>
    </>
  )
}
