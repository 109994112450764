import { Button, H1, H3, H4, P } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { useQuery } from "@apollo/client"
import { Navigate } from "react-router-dom"
import { createPortal } from "react-dom"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

import {
  PmiPageGetUserQuery,
  PmiPageGetUserQueryVariables,
} from "types/graphql"
import {
  pmiProviderDetails,
  pmiProviders,
  PmiProviderType,
} from "features/admin/pages/Therapy/Settings/PmiSetupForm/PmiSetupForm.types"
import { LoadingSpinner } from "common/components/LoadingSpinner"

import { PmiPageFAQ } from "./PmiPageFAQ"
import { queries } from "./PmiPage.queries"
import { PmiRequestForm } from "./PmiRequestForm"

export const PmiPage: FunctionComponent = () => {
  const [isPmiFormOpen, setPmiFormOpen] = useState(false)

  const { data, loading } = useQuery<
    PmiPageGetUserQuery,
    PmiPageGetUserQueryVariables
  >(queries.getUser)

  const pmiDetails = data?.user?.company?.pmi

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  if (pmiDetails == null) {
    return <Navigate to="/therapy/sessions" />
  }

  return (
    <div className="space-y-12">
      <section className="flex flex-col gap-8">
        <H1>Health insurance sessions</H1>
        <div className="flex flex-col gap-4">
          <H3>Insurance provider</H3>
          <div className="flex items-center">
            {(pmiProviders as readonly string[]).includes(
              pmiDetails.provider
            ) && (
              <img
                src={
                  pmiProviderDetails[pmiDetails.provider as PmiProviderType]
                    .image
                }
                alt={`${pmiDetails.provider} logo`}
                className="w-20 h-20 me-2 object-cover rounded-lg"
              />
            )}
            <H4>{pmiDetails.provider}</H4>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <H3>How it works</H3>
          <P>
            You can make a claim with your insurance provider to pay for a
            course of therapy with a private therapist. This is because talking
            therapy is covered by your private medical insurance policy, which
            is a separate company benefit to Spill.
          </P>
          <P>
            After letting us know that you&apos;d like to continue through your
            private medical insurance, we&apos;ll check if any of your previous
            therapists are registered with your health insurer and get in touch
            with recommended next steps.
          </P>
          <P>
            Note that this process is still in still in beta meaning that it
            might be a bit more clunky than it would be when it&apos;s fully
            featured.
          </P>
          <div className="my-2">
            <Button onClick={() => setPmiFormOpen(true)}>
              Start the process
            </Button>
          </div>
          <div className="flex gap-1 lg:items-center">
            <InformationCircleIcon className="inline size-4" />
            <P size="xs" muted>
              After starting the process, somebody from Spill will reach out
              within 1 working day with next steps.
            </P>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <H3>FAQs</H3>
          <div className="max-w-screen-sm">
            <PmiPageFAQ />
          </div>
        </div>
      </section>
      {isPmiFormOpen &&
        createPortal(
          <PmiRequestForm setOpen={setPmiFormOpen} pmiDetails={pmiDetails} />,
          document.body
        )}
    </div>
  )
}
