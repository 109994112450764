/**
 * Displays a dialog that allows the user to either reschedule or cancel their appointment
 */

import { FunctionComponent, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { differenceInHours, parseISO } from "date-fns"
import { useInterval } from "react-use"
import { toast } from "sonner"

import { Dialog } from "common/components/Dialog"
import { Appointment } from "types/graphql"

export const mutations = {
  cancelAppointment: gql`
    mutation ModifyAppointmentCancelAppointment($appointmentId: ID!) {
      cancelAppointment(appointmentId: $appointmentId) {
        id
        status
      }
    }
  `,
}

interface ModifyAppointmentDialogProps {
  isDialogOpen: boolean
  onDialogClose: () => void
  appointment: Pick<Appointment, "id" | "startsAt">
}

export const ModifyAppointmentDialog: FunctionComponent<
  ModifyAppointmentDialogProps
> = props => {
  const [now, setNow] = useState(new Date())
  const [cancelAppointment, { loading: cancelAppointmentLoading }] =
    useMutation(mutations.cancelAppointment, {
      refetchQueries: [
        "SessionsPageGetUserQuery",
        "TherapyRouterGetData",
        "TherapistSelectionCarouselGetData",
      ],
      variables: { appointmentId: props.appointment.id },
    })

  // Update component every minute
  useInterval(() => setNow(new Date()), 60000)

  const isAppointmentWithin24Hours =
    differenceInHours(parseISO(props.appointment.startsAt), now, {
      roundingMethod: "floor",
    }) < 24

  return (
    <Dialog
      buttons={[
        {
          children: cancelAppointmentLoading
            ? "Cancelling session"
            : "Cancel session",
          loading: cancelAppointmentLoading,
          onClick: async () => {
            const cancelledAppointment = await cancelAppointment()
            if (!cancelledAppointment.errors) {
              props.onDialogClose()
            } else {
              toast.error(
                "We were unable to cancel your session. Please try again later, or contact therapy@spill.chat"
              )
            }
          },
          variant: "primary",
        },
      ]}
      canClose
      isOpen={props.isDialogOpen}
      onClose={props.onDialogClose}
      title="Cancel your session"
    >
      <p>
        {isAppointmentWithin24Hours && (
          <>
            <p>
              You are cancelling within 24 hours. This means we will still pay
              the therapist, use one session from your allowance, and still
              charge your company.
            </p>
            <br />
          </>
        )}
        <p>Are you sure you want to cancel?</p>
      </p>
    </Dialog>
  )
}
