import { FunctionComponent, useEffect } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { captureException } from "@sentry/react"

import { ErrorPage } from "common/components/ErrorPage"
import { LoadingPage } from "common/components/LoadingPage"
import { useAuth } from "common/context/authContext"
import { Pulse, fragments as PulseFragments } from "features/pulse/pages/Pulse"
import { Burnout } from "features/pulse/pages/Burnout"
import {
  MeetingGetMeetingQuery as QueryData,
  MeetingGetMeetingQueryVariables as QueryVariables,
} from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment MeetingQueryFields on Query {
      meeting(urlId: $meetingUrlId) {
        urlId
        currentVersionNumber
        redirectUrl
      }
      ...PulseQueryFields
    }
    ${PulseFragments.queryFields}
  `,
}

export const queries = {
  getMeeting: gql`
    query MeetingGetMeeting($meetingUrlId: ID!) {
      ...MeetingQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const Meeting: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const { meetingUrlId } = useParams()

  const [getMeeting, { data, error, loading }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(queries.getMeeting)

  useEffect(() => {
    if (meetingUrlId !== undefined) {
      void getMeeting({ variables: { meetingUrlId } })
    }
  }, [meetingUrlId])

  if (isUserLoading || loading) return <LoadingPage />

  if (error !== undefined || data === undefined) {
    return (
      <ErrorPage showContactInfo>
        <p>We couldn't get your meeting information.</p>
        <p>Please refresh the page to try again.</p>
      </ErrorPage>
    )
  }

  const { meeting } = data

  if (!meeting) {
    return (
      <>
        <ErrorPage showContactInfo>
          <p>We can't find this meeting! Please check the link is correct.</p>
        </ErrorPage>
      </>
    )
  }

  const version = meeting.currentVersionNumber

  if (version === 5) return <Pulse />

  if (version === 7) return <Burnout />

  captureException(`Unknown version ${version}`)

  // default to version 5
  return <Pulse />
}
