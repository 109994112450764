import { gql } from "@apollo/client"

export const mutations = {
  createPmiRequest: gql`
    mutation PmiRequestFormCreatePmiRequest(
      $accountNumber: String
      $numberOfSessions: Float
      $provider: String!
    ) {
      createPmiRequest(
        accountNumber: $accountNumber
        numberOfSessions: $numberOfSessions
        provider: $provider
      ) {
        id
        accountNumber
        numberOfSessions
        provider
      }
    }
  `,
}
