import { FunctionComponent, useState } from "react"
import { Alert, Button, Modal } from "@spillchat/puddles"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useForm, FormProvider, SubmitHandler } from "react-hook-form"
import { toast } from "sonner"

import {
  AccountAndBillingGetCompanyDetailsQuery,
  AccountAndBillingGetCompanyDetailsUpdateCompanySettingsMutation,
  AccountAndBillingGetCompanyDetailsUpdateCompanySettingsMutationVariables,
  SpillSubscriptionPlan,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useUser } from "common/context/userContext"
import { UpgradePlanModal } from "features/admin/components/upgrade-plan/UpgradePlanModal"

import { SessionCapSettings } from "./SessionCapSettings"
import { UserTherapyCap } from "./TherapyTimeCap"
import { ExtensionRequests } from "./ExtensionRequests"
import { FeatureAvailability } from "./FeatureAvailability"
import { PlusOneSettings } from "./PlusOneSettings"

export interface BillingFormValues {
  userTherapyCap: string | null
  userCanRequestTherapy: boolean
  oneOffs: boolean
  courses: boolean
  library: boolean
  askATherapist: boolean
  adviceLibrary: boolean
  yearlyTherapyBudget: string
  plusOne: boolean
}

/**
 * We get all the values that we need and we'll pass them down to the other components.
 */
const queries = {
  getCompanyTherapyPlan: gql`
    query AccountAndBillingGetCompanyDetails {
      user {
        id
        upcomingCapResetDate
        company {
          id
          billableUserCount
          subscriptionPlan
          lockedBudget
          subscriptionStatus
          isLegacyPricing
          budgetYearUsage {
            totalBillableUsage
          }
          therapyBudgetResetDate
          featuresAndSettings {
            id
            courses {
              active
              value
            }
            oneOffs {
              active
              value
            }
            askATherapist {
              active
              value
            }
            adviceLibrary {
              active
              value
            }
            userTherapyCap {
              active
              value
            }
            userCanRequestTherapy {
              active
              value
            }
            yearlyTherapyBudget {
              active
              value
            }
            plusOne {
              active
              value
            }
          }
          recommendedYearlyBudget {
            lowerBound
            spillRecommendation
            upperBound
          }
        }
      }
    }
  `,
}

const mutations = {
  updateCompanySettings: gql`
    mutation AccountAndBillingGetCompanyDetailsUpdateCompanySettings(
      $userTherapyCap: Int
      $userCanRequestTherapy: Boolean!
      $oneOffs: Boolean
      $courses: Boolean
      $yearlyTherapyBudget: Int
      $adviceLibrary: Boolean
      $askATherapist: Boolean
      $plusOne: Boolean
    ) {
      updateCompanySettings(
        userTherapyCap: $userTherapyCap
        userCanRequestTherapy: $userCanRequestTherapy
        oneOffs: $oneOffs
        courses: $courses
        yearlyTherapyBudget: $yearlyTherapyBudget
        adviceLibrary: $adviceLibrary
        askATherapist: $askATherapist
        plusOne: $plusOne
      ) {
        id
        userTherapyCap {
          active
          value
        }
        userCanRequestTherapy {
          active
          value
        }
        oneOffs {
          active
          value
        }
        courses {
          active
          value
        }
        yearlyTherapyBudget {
          active
          value
        }
        adviceLibrary {
          active
          value
        }
        askATherapist {
          active
          value
        }
        plusOne {
          active
          value
        }
      }
    }
  `,
}

export const AccountSettings: FunctionComponent = () => {
  const { company } = useUser()
  const { data, loading } = useQuery<AccountAndBillingGetCompanyDetailsQuery>(
    queries.getCompanyTherapyPlan,
    { fetchPolicy: "cache-first" }
  )
  const [planChangeModal, setPlanChangeModal] = useState(false)
  const isLegacyPricing = company.isLegacyPricing
  const [updateCompanySettings, { loading: updatingPlan }] = useMutation<
    AccountAndBillingGetCompanyDetailsUpdateCompanySettingsMutation,
    AccountAndBillingGetCompanyDetailsUpdateCompanySettingsMutationVariables
  >(mutations.updateCompanySettings, {
    onCompleted() {
      toast.success("Your settings have been updated.")
    },
    onError() {
      toast.error("Something went wrong, please try again or get in touch.")
    },
    update(cache, { data: updatedData }) {
      if (!data?.user?.company) {
        return
      }
      if (updatedData == null) {
        return
      }
      const { updateCompanySettings } = updatedData
      cache.modify({
        id: cache.identify(data.user.company),
        fields: {
          featuresAndSettings(existingFeaturesAndSettings = {}) {
            return updateCompanySettings ?? existingFeaturesAndSettings
          },
        },
      })
    },
  })

  const methods = useForm<BillingFormValues>()
  const isDirty = Object.entries(methods.formState.dirtyFields).length > 0

  const onSubmit: SubmitHandler<BillingFormValues> = async data => {
    const updatedDetails = await updateCompanySettings({
      variables: {
        // Default value will work. But form will change number to string if user changes the value.
        userTherapyCap:
          data.userTherapyCap == null ? null : parseInt(data.userTherapyCap),
        userCanRequestTherapy: data.userCanRequestTherapy,
        oneOffs: data.oneOffs,
        courses: data.courses,
        yearlyTherapyBudget: parseInt(data.yearlyTherapyBudget),
        adviceLibrary: data.adviceLibrary,
        askATherapist: data.askATherapist,
        plusOne: data.plusOne,
      },
    })

    if (updatedDetails.errors) {
      toast.error("Something went wrong, please try again or get in touch.")
    }

    if (updatedDetails.data?.updateCompanySettings) {
      methods.reset({}, { keepValues: true, keepDirty: false })
    }
  }

  const featuresAndSettings = data?.user?.company?.featuresAndSettings

  return (
    <div className="flex flex-col relative space-y-10 h-full w-full">
      {loading ? (
        <div className="flex w-full h-full justify-center items-center">
          <LoadingSpinner sizeInPixels={32} />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col gap-12"
          >
            {/* What features do the company make available to their users */}
            {isLegacyPricing ? null : (
              <FeatureAvailability
                oneOffs={featuresAndSettings?.oneOffs}
                courses={featuresAndSettings?.courses}
                askATherapist={featuresAndSettings?.askATherapist}
                adviceLibrary={featuresAndSettings?.adviceLibrary}
                subscriptionPlan={data?.user?.company?.subscriptionPlan ?? null}
              />
            )}

            {/* How the company wants to handle extensions. Allow them, or not */}
            <ExtensionRequests
              userCanRequestTherapy={featuresAndSettings?.userCanRequestTherapy}
              isLegacyPricing={data?.user?.company?.isLegacyPricing}
              subscriptionPlan={data?.user?.company?.subscriptionPlan ?? ""}
            />

            {/*  Sets the time cap available to each user */}
            <UserTherapyCap
              subscriptionPlan={data?.user?.company?.subscriptionPlan}
              userTherapyCap={featuresAndSettings?.userTherapyCap}
              upcomingCapResetDate={data?.user?.upcomingCapResetDate}
            />

            {/* Allows the user to change the max amount of therapy the company provides */}
            {isLegacyPricing ? null : (
              <SessionCapSettings
                subscriptionPlan={
                  data?.user?.company?.subscriptionPlan ?? undefined
                }
                recommendedYearlyBudget={{
                  ...data?.user?.company?.recommendedYearlyBudget,
                  therapyBudgetResetDate:
                    data?.user?.company?.therapyBudgetResetDate,
                }}
                yearlyTherapyBudget={featuresAndSettings?.yearlyTherapyBudget}
                minimumBudgetLimit={
                  data?.user?.company?.budgetYearUsage?.totalBillableUsage ?? 0
                }
                lockedBudget={data?.user?.company?.lockedBudget}
              />
            )}
            {/* Plus One feature section */}
            <PlusOneSettings
              plusOneFeaturesAndSettings={featuresAndSettings?.plusOne}
            />
            <div className="flex gap-4">
              <Button
                variant="primary"
                onClick={methods.handleSubmit(onSubmit)}
                loading={updatingPlan}
                disabled={methods.formState.errors.yearlyTherapyBudget != null}
              >
                Save changes
              </Button>
              {data?.user?.company?.subscriptionPlan ===
                SpillSubscriptionPlan.STARTER && (
                <Button
                  variant="secondary"
                  onClick={() => setPlanChangeModal(true)}
                >
                  Upgrade to Team Plan
                </Button>
              )}
            </div>
          </form>
          {isDirty && (
            <div className="fixed top-0 right-8 w-fit justify-self-end cursor-s-resize">
              <Alert title="You have unsaved changes" variant="warning" />
            </div>
          )}
        </FormProvider>
      )}
      <Modal.Root open={planChangeModal} onOpenChange={setPlanChangeModal}>
        <Modal.Content
          className="md:min-w-[876px]"
          onInteractOutside={e => {
            e.preventDefault()
            setPlanChangeModal(false)
          }}
        >
          <UpgradePlanModal onClose={() => setPlanChangeModal(false)} />
        </Modal.Content>
      </Modal.Root>
    </div>
  )
}
