/**
 * Page that shows the checkins for the team.
 */
import { FunctionComponent, useEffect, useRef } from "react"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import {
  format,
  isSameYear,
  max as maxDate,
  min as minDate,
  parseISO,
  subMonths,
} from "date-fns"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { inflect } from "inflection"
import { maxBy } from "lodash"
import cn from "classnames"
import { P, H1, Tabs, Alert } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"

import { BenchmarkGroup } from "features/admin/components/BenchmarkGroup"
import { EmotionsBenchmarkLineChart } from "features/admin/components/EmotionsBenchmarkLineChart"
import { EmotionsChangeBarCharts } from "features/admin/components/EmotionsChangeBarCharts"
import { KeyInsightsReadMore } from "features/admin/components/KeyInsights/KeyInsightsReadMore"
import { KeyInsightTrends } from "features/admin/components/KeyInsights/KeyInsightTrends"
import { MoodBenchmarkChart } from "features/admin/components/MoodBenchmarkChart"
import { PulseResponseSharePercentage } from "features/admin/components/PulseResponseSharePercentage"
import { TrendsFeedbackWidget } from "features/admin/components/TrendsFeedbackWidget"
import {
  AlertColorTypes,
  decideMoodColor,
} from "features/pulse/helpers/decideAlertColor"
import { pulseResponseHasWarning } from "features/pulse/helpers/pulseResponseHasWarning"
import { PulseResponsesTable } from "features/pulse/components/PulseResponsesTable"
import { PulseSummaryAlert } from "features/pulse/components/PulseSummaryAlert"
import {
  EventType,
  TeamCheckinGetMeetingEventQuery as GetMeetingEventData,
  TeamCheckinGetMeetingEventQueryVariables as GetMeetingEventVars,
  TeamCheckinGetRecentMeetingEventsQuery as GetRecentMeetingEventsData,
  TeamCheckinGetRecentMeetingEventsQueryVariables as GetRecentMeetingEventsVars,
} from "types/graphql"
import { formatMonth } from "common/helpers/formatMonth"
import { CheckIns } from "features/pulse/pages/CheckIns"

const SEARCH_PARAMS = {
  DATE: "date",
  MEETING_URL_ID: "meetingUrlId",
}

export const fragments = {
  getMeetingEventQueryFields: gql`
    fragment TeamCheckinGetMeetingEventQueryFields on Query {
      meetingEvent(date: $date, meetingUrlId: $meetingUrlId) {
        id
        date
        responseCount
        meeting {
          urlId
          name
        }
        sharedPulseResponses {
          id
          feelings
          name
          score
        }
        eventType
      }
    }
  `,
  getRecentMeetingEvents: gql`
    fragment TeamCheckinGetRecentMeetingEventsQueryFields on Query {
      company {
        pulseMeetings {
          urlId
        }
        pulseResponseAggregatesByMonth(
          fromMonth: $fromMonth
          toMonth: $toMonth
        ) {
          month
          responseCount
        }
      }
      recentMeetingEvents {
        id
        date
        meeting {
          urlId
          name
        }
        sharedPulseResponses {
          id
          score
        }
        eventType
      }
    }
  `,
}

export const queries = {
  getMeetingEvent: gql`
    query TeamCheckinGetMeetingEvent($date: DateTime!, $meetingUrlId: ID!) {
      ...TeamCheckinGetMeetingEventQueryFields
    }
    ${fragments.getMeetingEventQueryFields}
  `,
  getRecentMeetingEvents: gql`
    query TeamCheckinGetRecentMeetingEvents(
      $fromMonth: Month!
      $toMonth: Month!
    ) {
      ...TeamCheckinGetRecentMeetingEventsQueryFields
    }
    ${fragments.getRecentMeetingEvents}
  `,
}

const variables = {
  fromMonth: formatMonth(
    minDate([
      subMonths(new Date(), 6),
      new Date(EmotionsBenchmarkLineChart.variables.fromMonth),
      new Date(EmotionsChangeBarCharts.variables.fromMonth),
      new Date(MoodBenchmarkChart.variables.fromMonth),
      new Date(PulseResponseSharePercentage.variables.fromMonth),
    ])
  ),
  toMonth: formatMonth(
    maxDate([
      new Date(),
      new Date(EmotionsBenchmarkLineChart.variables.toMonth),
      new Date(EmotionsChangeBarCharts.variables.toMonth),
      new Date(MoodBenchmarkChart.variables.toMonth),
      new Date(PulseResponseSharePercentage.variables.toMonth),
    ])
  ),
}

export const TeamCheckin: FunctionComponent = () => {
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const date = searchParams.get(SEARCH_PARAMS.DATE)
  const meetingUrlId = searchParams.get(SEARCH_PARAMS.MEETING_URL_ID)
  const navigate = useNavigate()

  const meetingEventsListRef = useRef<HTMLUListElement>(null)
  const selectedMeetingEventRef = useRef<HTMLButtonElement>(null)

  const {
    data: getRecentMeetingEventsData,
    loading: getRecentMeetingEventsLoading,
  } = useQuery<GetRecentMeetingEventsData, GetRecentMeetingEventsVars>(
    queries.getRecentMeetingEvents,
    { variables, fetchPolicy: "no-cache" }
  )

  const [getMeetingEvent, { data: getMeetingEventData }] = useLazyQuery<
    GetMeetingEventData,
    GetMeetingEventVars
  >(queries.getMeetingEvent)

  const recentMeetingEvents =
    getRecentMeetingEventsData?.recentMeetingEvents ?? []
  const responses =
    getMeetingEventData?.meetingEvent?.sharedPulseResponses ?? []
  const responseCount =
    getMeetingEventData?.meetingEvent?.responseCount ?? responses.length
  const responsesWithWarning = responses.filter(pulseResponseHasWarning)
  const currentPath = pathname.split("/").pop()

  useEffect(() => {
    if (
      pathname.split("/").pop() === "recent" &&
      (date === null || meetingUrlId === null) &&
      recentMeetingEvents?.[0]
    ) {
      setSearchParams(
        {
          date: recentMeetingEvents[0].date,
          meetingUrlId: recentMeetingEvents[0].meeting.urlId,
        },
        { replace: true }
      )
    }
  }, [date, recentMeetingEvents, meetingUrlId, pathname])

  useEffect(() => {
    if (meetingUrlId != null && date != null) {
      void getMeetingEvent({ variables: { date, meetingUrlId } })
    }
  }, [date, meetingUrlId])

  const aggregates =
    getRecentMeetingEventsData?.company.pulseResponseAggregatesByMonth ?? []
  const maxMonthlyResponseCount =
    maxBy(aggregates, "responseCount")?.responseCount ?? 0

  if (getRecentMeetingEventsLoading) return null

  return (
    <>
      <Helmet title="Check-ins | Spill" />
      <div className="flex flex-col">
        <H1>How your team is feeling</H1>
        <section className="flex flex-col pt-8">
          <Tabs.Root
            defaultValue="recent"
            className="w-full"
            value={currentPath === "checkins" ? "recent" : currentPath}
          >
            <Tabs.List>
              <Tabs.Trigger value="recent" onClick={() => navigate("recent")}>
                Recent
              </Tabs.Trigger>
              <Tabs.Trigger value="trends" onClick={() => navigate("trends")}>
                Trends
              </Tabs.Trigger>
              <Tabs.Trigger
                value="meetings"
                onClick={() => navigate("meetings")}
              >
                Meetings
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="recent">
              <>
                {recentMeetingEvents.length > 0 ? (
                  <div className="flex flex-col space-y-8 mt-8">
                    <ul
                      className="flex gap-sm overflow-x-auto p-0.5"
                      ref={meetingEventsListRef}
                    >
                      {(recentMeetingEvents ?? []).map(meetingEvent => {
                        const isSelected =
                          meetingEvent.meeting.urlId === meetingUrlId &&
                          meetingEvent.date === date

                        const meetingEventScoreBreakdown =
                          meetingEvent.sharedPulseResponses.reduce<
                            Record<AlertColorTypes, number>
                          >(
                            (prev, { score }) => {
                              prev[decideMoodColor(score)]++
                              return prev
                            },
                            { gray: 0, danger: 0, warning: 0, primary: 0 }
                          )

                        const meetingEventDateISO = parseISO(meetingEvent.date)

                        return (
                          <button
                            key={meetingEvent.id}
                            className={cn(
                              "btn btn-white flex-col gap-0 h-auto overflow-hidden p-0 shrink-0",
                              {
                                "outline outline-blue-800": isSelected,
                              },
                              isSelected
                                ? "bg-blue-100 hover:bg-blue-100"
                                : "bg-blue-100 hover:bg-blue-200"
                            )}
                            onClick={() => {
                              setSearchParams(
                                {
                                  date: formatISODateForSearchParam(
                                    meetingEvent.date
                                  ),
                                  meetingUrlId: meetingEvent.meeting.urlId,
                                },
                                { replace: true }
                              )
                            }}
                            {...(isSelected
                              ? { ref: selectedMeetingEventRef }
                              : {})}
                          >
                            <header className="flex flex-col gap-xs items-start px-3 py-3">
                              <P weight="medium">{meetingEvent.meeting.name}</P>
                              <P size="xs" muted>
                                {format(
                                  meetingEventDateISO,
                                  isSameYear(meetingEventDateISO, new Date())
                                    ? "MMM do"
                                    : "MMM do, yyyy"
                                )}
                              </P>
                            </header>
                            <div className="flex h-2 w-full">
                              {meetingEvent.eventType === EventType.BURNOUT ? (
                                <span className="bg-grey-200 w-full" />
                              ) : (
                                Object.entries(meetingEventScoreBreakdown).map(
                                  ([color, count]) => {
                                    return (
                                      <span
                                        key={color}
                                        className={cn({
                                          "bg-grey-200": color === "gray",
                                          "bg-red-200": color === "danger",
                                          "bg-yellow-200": color === "warning",
                                          "bg-teal-200": color === "primary",
                                        })}
                                        style={{
                                          width: `${
                                            (count /
                                              meetingEvent.sharedPulseResponses
                                                .length) *
                                            100
                                          }%`,
                                        }}
                                      />
                                    )
                                  }
                                )
                              )}
                            </div>
                          </button>
                        )
                      })}
                    </ul>

                    {responseCount !== undefined && (
                      <div className="bg-blue-200 cursor-default flex h-8 items-center justify-center leading-none px-4 rounded-full self-start">
                        <P size="xs">
                          {responses.length} of {responseCount}{" "}
                          {inflect("person", responseCount)} shared their
                          feelings
                        </P>
                      </div>
                    )}

                    {responsesWithWarning.length > 0 && (
                      <PulseSummaryAlert
                        title={`We’ve identified ${
                          responsesWithWarning.length
                        } ${inflect(
                          "person",
                          responsesWithWarning.length
                        )} that may be struggling`}
                        body="If their mood stays low or shows a big drop, a Spill therapist will reach out to see whether they need any support."
                      >
                        <PulseResponsesTable
                          className="p-2 lg:p-3"
                          id="checkInSummaryWarningResponsesTable"
                          responses={responsesWithWarning}
                          showTableHead={false}
                          showWarnings={false}
                        />
                      </PulseSummaryAlert>
                    )}

                    <PulseResponsesTable
                      className="p-0"
                      id="checkInSummaryResponsesTable"
                      responses={responses}
                      showWarnings={false}
                    />
                  </div>
                ) : (
                  <div className="mt-4">
                    <Alert
                      variant="warning"
                      title="As soon as you have a recent meeting with a Check-in attached, you’ll be able to view the results here."
                    />
                  </div>
                )}
              </>
            </Tabs.Content>
            <Tabs.Content value="trends">
              <>
                {maxMonthlyResponseCount >= 3 ? (
                  <div className="space-y-8">
                    <div className="grid gap-8">
                      <div className="grid grid-cols-1 xl:grid-cols-2 gap-[inherit] items-start">
                        <div className="border border-spill-grey-200 box">
                          <KeyInsightTrends />
                        </div>
                        <div className="border border-spill-grey-200 box h-full">
                          <PulseResponseSharePercentage />
                        </div>
                      </div>
                    </div>
                    <div className="border border-spill-grey-200 box">
                      <EmotionsChangeBarCharts />
                    </div>
                    <BenchmarkGroup />
                    <div className="border border-spill-grey-200 box">
                      <MoodBenchmarkChart />
                    </div>
                    <div className="border border-spill-grey-200 box">
                      <EmotionsBenchmarkLineChart />
                    </div>
                    <div className="flex flex-col lg:flex-row gap-8">
                      <div className="border border-spill-grey-200 box w-full">
                        <KeyInsightsReadMore />
                      </div>
                      <div className="border border-spill-grey-200 box w-full">
                        <TrendsFeedbackWidget />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <Alert
                      variant="warning"
                      title="As soon as you have enough responses for the last month, you'll see your insights here."
                    />
                  </div>
                )}
              </>
            </Tabs.Content>
            <Tabs.Content value="meetings">
              <CheckIns hideTitle />
            </Tabs.Content>
          </Tabs.Root>
        </section>
      </div>
    </>
  )
}

const formatISODateForSearchParam = (date: string) => {
  return format(parseISO(date), "yyyy-LL-dd")
}
