import { Button, H2, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"

export const PmiRequestFormConfirmation: FunctionComponent = () => {
  const navigate = useNavigate()

  return (
    <>
      <H2>Your details have been sent to Spill&apos;s operations team</H2>
      <div className="mb-4">
        <P>
          Somebody from Spill will reach out in the next 24 hours with
          instructions for what to do next.
        </P>
      </div>
      <Button onClick={() => navigate("/therapy/sessions")}>
        Back to sessions
      </Button>
    </>
  )
}
