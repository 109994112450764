/**
 * Some animations are reusable accross multiple icons. So we declare them here.
 * We can write them as functions, so we can add some variety to the animations, such
 * as different easing functions, different timing, and different delays.
 */
import { TargetAndTransition } from "framer-motion"

/**
 * An animation that will give the impression of rain by moving the
 * relevant SVG path up & down
 */
export const rain = (
  duration: number = 0.75,
  delay = 0
): string | string[] | TargetAndTransition => {
  return {
    y: [0, Math.floor(Math.random() * (10 - 5 + 1) + 5)],
    transition: {
      repeat: Infinity,
      repeatType: "loop",
      duration,
      delay,
      ease: "easeInOut",
    },
  }
}
