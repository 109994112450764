import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { gql, useLazyQuery } from "@apollo/client"
import { format } from "date-fns"
import mixpanel from "mixpanel-browser"

import { useAnalytics } from "common/context/analyticsContext"

import type {
  UseAnalyticsPulseGetMeetingQuery,
  UseAnalyticsPulseGetMeetingQueryVariables,
} from "types/graphql"

const GET_MEETING = gql`
  query UseAnalyticsPulseGetMeeting($meetingUrlId: ID!) {
    meeting(urlId: $meetingUrlId) {
      urlId
    }
  }
`

export function useAnalyticsPulse(): {
  track: typeof mixpanel.track
} {
  const { meetingUrlId } = useParams()
  const { track } = useAnalytics()

  const [getMeeting, { called, data }] = useLazyQuery<
    UseAnalyticsPulseGetMeetingQuery,
    UseAnalyticsPulseGetMeetingQueryVariables
  >(GET_MEETING)

  useEffect(() => {
    if (meetingUrlId !== undefined && !called) {
      // TODO: Populate catch with something useful
      getMeeting({ variables: { meetingUrlId } }).catch(() => {})
    }
  }, [meetingUrlId])

  return {
    track: (...args) => {
      const [event, properties = {}, ...rest] = args

      return track(
        event,
        {
          "Meeting URL ID": data?.meeting?.urlId,
          Time: format(new Date(), "HHmm"),
          ...properties,
        },
        ...rest
      )
    },
  }
}
