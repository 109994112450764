import { gql } from "@apollo/client"

export const queries = {
  appointment: gql`
    query FeedbackAppointment($appointmentId: ID!) {
      appointment(id: $appointmentId) {
        appointmentType
        status
        numberInCourse
      }
    }
  `,
  getReview: gql`
    query FeedbackFormReviewGetReview($companyId: ID!) {
      userCompanyPublicReview(companyId: $companyId) {
        message
        score
      }
    }
  `,
}
