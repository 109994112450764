/**
 * Component that renders children only if the currently authenticated user's
 * company has a specific subscription plan.
 */
import { FunctionComponent, PropsWithChildren, ReactNode } from "react"
import { gql, useQuery } from "@apollo/client"

import { NotAuthorisedPage } from "common/components/NotAuthorisedPage"
import {
  RequireSubscriptionPlanGetCompanyQuery as QueryData,
  RequireSubscriptionPlanGetCompanyQueryVariables as QueryVars,
  SpillSubscriptionPlan,
} from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment RequireSubscriptionPlanQueryFields on Query {
      company {
        id
        subscriptionPlan
      }
    }
  `,
}

export const queries = {
  getCompany: gql`
    query RequireSubscriptionPlanGetCompany {
      ...RequireSubscriptionPlanQueryFields
    }
    ${fragments.queryFields}
  `,
}

interface RequireSubscriptionPlanProps extends PropsWithChildren {
  fallback?: ReactNode
  subscriptionPlan: SpillSubscriptionPlan
}

export const RequireSubscriptionPlan: FunctionComponent<
  RequireSubscriptionPlanProps
> = props => {
  const { fallback = <NotAuthorisedPage /> } = props

  const { data } = useQuery<QueryData, QueryVars>(queries.getCompany, {
    fetchPolicy: "cache-and-network",
  })

  if (data?.company.subscriptionPlan !== props.subscriptionPlan) {
    return <>{fallback}</>
  }

  return <>{props.children}</>
}
