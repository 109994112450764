import { FunctionComponent, PropsWithChildren } from "react"

import { useAuth } from "common/context/authContext"

import { ConditionalRedirect } from "./ConditionalRedirect"

interface RedirectProps extends PropsWithChildren {
  /**
   * The path to redirect to if the user is already authenticated
   */
  to?: string
  /**
   * If this case is true, the redirect will not happen.
   */
  unless?: boolean
}

/**
 * A wrapper component that users the ConditionalRedirect component to
 * redirect the user to a different page if they are authenticated.
 */
export const UnauthenticatedRoute: FunctionComponent<RedirectProps> = ({
  to = "/",
  // We default this to false, so that we don't override default behaviour
  unless = false,
  children,
}) => {
  const { user, isUserLoading } = useAuth()

  if (isUserLoading) return null

  return (
    <ConditionalRedirect conditions={[user != null]} unless={unless} to={to}>
      {children}
    </ConditionalRedirect>
  )
}
