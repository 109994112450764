import { gql } from "@apollo/client"

export const queries = {
  getPackage: gql`
    query TherapySpecialisedSupportPackage($id: ID!) {
      user {
        id
        therapyUsageCap
        numberSessionsUsedInCapPeriod
        therapyExtensions {
          id
          companyPackageSetting {
            id
            numberSessions
            numberMonthsToUse
          }
          expiryDate
          status
          createdAt
        }
        therapyPackages {
          identifier
          therapyPackages {
            numberSessionsUsed
            numberOfSessions
            expirationDate
          }
          companyPackageSetting {
            id
            numberSessions
          }
          mostRecentTherapyExtensionRequest {
            id
          }
        }
      }
      getCompanyPackageSettingById(companyPackageSettingId: $id) {
        id
        name
        icon
        iconUrl
        userFacingDescription
        numberSessions
        numberMonthsToUse
        lifeEventId
      }
    }
  `,
  getLifeEvent: gql`
    query TherapySpecialisedSupportPackageLifeEvent($id: String!) {
      getLifeEventFromId(id: $id) {
        id
        name
        slug
      }
    }
  `,
}
