import { gql, useQuery } from "@apollo/client"

import { meanBy } from "common/helpers/meanBy"

import type {
  Meeting,
  CurrentTeamMoodGetMeetingQuery as QueryData,
  CurrentTeamMoodGetMeetingQueryVariables as QueryVariables,
} from "types/graphql"

const fragments = {
  meetingFields: gql`
    fragment UseCurrentMeetingMoodFields on Meeting {
      urlId
      sharedPulseResponses {
        score
      }
    }
  `,
}

const queries = {
  GET_MEETING: gql`
    query UseCurrentMeetingMoodMoodGetMeeting($meetingUrlId: ID!) {
      meeting(urlId: $meetingUrlId) {
        ...UseCurrentMeetingMoodFields
      }
    }
    ${fragments.meetingFields}
  `,
}

export function useCurrentMeetingMood(
  meetingUrlId: Meeting["urlId"]
): number | null {
  const { data } = useQuery<QueryData, QueryVariables>(queries.GET_MEETING, {
    variables: { meetingUrlId },
  })

  const responsesWithSharedScore = data?.meeting?.sharedPulseResponses.filter(
    res => res.score
  )

  const meanScore = meanBy(responsesWithSharedScore ?? [], "score")
  if (meanScore === null) return null

  return meanScore
}

useCurrentMeetingMood.fragments = fragments
useCurrentMeetingMood.queries = queries
