import type { FieldMergeFunction, Reference, StoreObject } from "@apollo/client"

/**
 * Apollo Client cache merge function for non-normalised arrays.
 * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
 */
export function mergeArrayByField<T extends Reference | StoreObject>(
  fieldName: string
): FieldMergeFunction<T[]> {
  return (existing, incoming, { readField, mergeObjects }) => {
    if (!existing) return incoming
    const merged = [...existing]
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const fieldIndexMap: Record<string, number> = Object.create(null)
    existing.forEach((aggregates, index) => {
      const field = readField<string>(fieldName, aggregates)
      if (field !== undefined) fieldIndexMap[field] = index
    })
    incoming.forEach(aggregates => {
      const field = readField<string>(fieldName, aggregates)
      if (field === undefined) return
      const index = fieldIndexMap[field]
      if (typeof index === "number") {
        // Merge the new aggregates data with the existing aggregates data.
        merged[index] = mergeObjects(merged[index]!, aggregates)
      } else {
        // First time we've seen this aggregates in this array.
        fieldIndexMap[field] = merged.length
        merged.push(aggregates)
      }
    })
    return merged
  }
}
