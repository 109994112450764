import { useQuery } from "@apollo/client"
import { ActionCard, Alert, Button, H1, H2, H4, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import {
  ArrowDownTrayIcon,
  BookOpenIcon,
  CheckIcon,
  CurrencyPoundIcon,
  HeartIcon,
  UserIcon,
  UsersIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline"

import {
  QuoteViewGetQuoteQuery,
  QuoteViewGetQuoteQueryVariables,
  SpillSubscriptionPlan,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { formatPounds } from "common/helpers/formatNumber"
import { useOnboarding } from "common/context/onboardingContext"

import { QuoteViewTable } from "../components/quote-view/QuoteViewTable"
import { queries } from "../components/quote-view/QuoteView.queries"
import { QuoteViewFAQ } from "../components/quote-view/QuoteViewFAQ"
import { getSessionPackForNumberEmployees } from "../helpers/sessionPack"

export const QuoteView: FunctionComponent = () => {
  const { stepsProgress } = useOnboarding()

  const { data: quoteData, loading: quoteLoading } = useQuery<
    QuoteViewGetQuoteQuery,
    QuoteViewGetQuoteQueryVariables
  >(queries.getQuote, {
    fetchPolicy: "cache-first",
  })

  const employeeCount = quoteData?.getCompanyTherapyQuoteNumberEmployees ?? 0
  const sessionPack = getSessionPackForNumberEmployees(employeeCount)
  const quotePlan = quoteData?.getCompanyTherapyQuoteRecommendedPlan

  const therapyNeeds = (quoteData?.getCompanyTherapyNeeds ?? []).length
    ? [quoteData?.getCompanyTherapyNeeds[0] ?? ""]
    : []

  const isStarter = quotePlan === SpillSubscriptionPlan.STARTER
  const defaultPrice = isStarter ? 90 : 70 // starter or essential plan

  const singleSessionPrice =
    quoteData?.user?.company?.planPrice?.perSession ?? defaultPrice
  const sessionCount = 6

  if (quoteLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  return (
    <>
      <Helmet title="View your quote | Spill" />
      <>
        <H1>View your quote</H1>
        {isStarter ? (
          <>
            <H4 sectionHeader>Pricing</H4>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="flex flex-col gap-4">
                <P weight="medium">Subscription fee</P>
                <div className="flex flex-col gap-1">
                  <P>
                    <strong>£0</strong> per month
                  </P>
                  <P muted size="xs">
                    Spill is a pay as you go service meaning you only pay for
                    the sessions used.
                  </P>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <P weight="medium">Cost of therapy</P>
                <P>
                  <strong>{singleSessionPrice}</strong> per 50 minute session
                </P>
              </div>
              <div className="flex flex-col gap-4">
                <P weight="medium">All the benefits of Spill therapy</P>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-center">
                    <CheckIcon className="size-4 text-teal-400" />
                    <P>Course of&nbsp;therapy</P>
                  </div>
                  <div className="flex gap-2 items-center">
                    <CheckIcon className="size-4 text-teal-400" />
                    <P>Access to next-day session&nbsp;slots</P>
                  </div>
                  <div className="flex gap-2 items-center">
                    <CheckIcon className="size-4 text-teal-400" />
                    <P>Handpicked&nbsp;counsellors</P>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <H4 sectionHeader>Recommended subscription</H4>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="flex flex-col gap-4">
                <P weight="medium">
                  <span className="capitalize">
                    {quoteData?.getCompanyTherapyQuoteRecommendedPlan?.toLocaleLowerCase()}
                  </span>{" "}
                  Plan
                </P>
                <div className="flex flex-col gap-1">
                  <P>
                    <strong>{sessionPack?.price}</strong> per month
                  </P>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <P weight="medium">How it works</P>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-start">
                    <ArrowDownTrayIcon className="size-4" />
                    <P>Sessions available first come, first&nbsp;served</P>
                  </div>
                  {sessionPack?.sessionCount !== undefined && (
                    <div className="flex gap-2 items-start">
                      <HeartIcon className="size-4" />
                      <P>
                        {sessionPack?.sessionCount}{" "}
                        {sessionPack?.sessionCount === 1
                          ? "session"
                          : "sessions"}{" "}
                        per month ({sessionPack?.sessionCount * 12}
                        &nbsp;annually)
                      </P>
                    </div>
                  )}
                  <div className="flex gap-2 items-start">
                    <BookOpenIcon className="size-4" />
                    <P>Advice Library to use&nbsp;anytime</P>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <P weight="medium">All the benefits of Spill therapy</P>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-center">
                    <CheckIcon className="size-4 text-teal-400" />
                    <P>Book sessions in 3&nbsp;clicks</P>
                  </div>
                  <div className="flex gap-2 items-center">
                    <CheckIcon className="size-4 text-teal-400" />
                    <P>Handpicked counsellors</P>
                  </div>
                  <div className="flex gap-2 items-center">
                    <CheckIcon className="size-4 text-teal-400" />
                    <P>Slack &amp; Teams&nbsp;integration</P>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isStarter && (
          <div className="flex flex-col gap-4">
            <H4 sectionHeader>Cost to support your employees</H4>
            {quoteData?.getCompanyTherapyNeeds !== undefined && (
              <>
                <QuoteViewTable
                  data={therapyNeeds}
                  {...{ singleSessionPrice, sessionCount }}
                />
              </>
            )}
          </div>
        )}
        <H4 sectionHeader>Summary</H4>
        <div className="grid grid-cols-2 gap-8">
          {isStarter ? (
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <UserIcon className="size-4" />
                  <P>1 supported employee</P>
                </div>
                <P>£0</P>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <HeartIcon className="size-4" />
                  <P>Up to 6 sessions</P>
                </div>
                {quoteData?.getCompanyTherapyNeeds !== undefined && (
                  <P>
                    {formatPounds(
                      (singleSessionPrice * sessionCount +
                        singleSessionPrice / 2) *
                        therapyNeeds.length
                    )}
                  </P>
                )}
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <CurrencyPoundIcon className="size-4" />
                  <P>Subscription fee</P>
                </div>
                <P>£0</P>
              </div>
              <hr />
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <P>Maximum total cost</P>
                </div>
                {quoteData?.getCompanyTherapyNeeds !== undefined && (
                  <P>
                    {formatPounds(
                      (singleSessionPrice * sessionCount +
                        singleSessionPrice / 2) *
                        therapyNeeds.length
                    )}
                  </P>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <P weight="medium">Cost breakdown</P>
              <hr />
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <UsersIcon className="size-4" />
                  <P>
                    <span className="capitalize">
                      {quoteData?.getCompanyTherapyQuoteRecommendedPlan?.toLocaleLowerCase()}
                    </span>{" "}
                    Plan ({sessionPack?.sessionCount} session pack)
                  </P>
                </div>
                <div>{sessionPack?.price}</div>
              </div>
              <hr />
              <P size="xs" muted>
                You can also give specific employees extra sessions if they ever
                need more support. Sessions are charged on a pay-as-you-go basis
                at {singleSessionPrice} per session.
              </P>
            </div>
          )}
          {isStarter && (
            <div>
              <Alert
                variant="info"
                title="Spill is free until somebody uses it"
              >
                <P size="xs">
                  This quote is an estimate, and we only charge for the sessions
                  you actually use. If people don&apos;t use the support offered
                  to them, you don&apos;t pay anything.
                </P>
              </Alert>
            </div>
          )}
        </div>
        {isStarter ? (
          <>
            {!stepsProgress.hasAddedTeam && (
              <div className="bg-spill-grey-100 w-full p-8 lg:p-12 text-center rounded-lg flex flex-col gap-4 justify-center items-center">
                <div className="max-w-2xl">
                  <H2>
                    Offer support to somebody in your&nbsp;team.
                    <br />
                    Set up your first employee with&nbsp;Spill.
                  </H2>
                </div>
                <Button asChild variant="primary">
                  <Link to="/admin/access/invite">
                    Configure support for first employee
                  </Link>
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="bg-spill-grey-100 w-full p-8 lg:p-12 text-center rounded-lg flex flex-col gap-4 justify-center items-center">
            <div className="max-w-2xl">
              <H2>
                Book an onboarding call to get set up – or to ask some more
                questions before&nbsp;committing
              </H2>
            </div>
            <Button asChild variant="primary">
              <a
                href="https://www.spill.chat/book-demo"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a call with Spill
              </a>
            </Button>
          </div>
        )}
        <H4 sectionHeader>FAQs</H4>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <QuoteViewFAQ />
          </div>
          <div className="col-span-1">
            <ActionCard
              title="Have more questions? Book a call."
              description="If you have any questions about Spill you can book a call and speak with anyone at Spill anytime."
              icon={<PhoneIcon />}
              action={{
                onClick: () => {
                  window.open("https://www.spill.chat/book-demo", "_blank")
                },
                type: "feature",
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </>
    </>
  )
}
