import cn from "classnames"

import { AlertColorTypes } from "features/pulse/helpers/decideAlertColor"

import type { ComponentProps } from "react"

type PillProps = ComponentProps<"span"> & {
  color: AlertColorTypes
}

export function Pill({
  children,
  className,
  color,
  ...rest
}: PillProps): JSX.Element {
  return (
    <span
      className={cn(
        "cursor-default flex h-6 sm:h-8 items-center justify-center leading-none px-4 rounded-full text-xs sm:text-sm",
        className,
        {
          "bg-yellow-200": color === "warning",
          "bg-teal-200": color === "primary",
          "bg-red-200": color === "danger",
          "bg-grey-200": color === "gray",
        }
      )}
      {...rest}
    >
      {children}
    </span>
  )
}
