import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"

type Cookiebot = {
  consent: {
    marketing: boolean
    necessary: boolean
    preferences: boolean
    statistics: boolean
  }
}

declare global {
  interface Window {
    Cookiebot?: Cookiebot
  }
}

type ConsentContextData = {
  consent?: Cookiebot["consent"]
}

const ConsentContext = createContext<ConsentContextData>({})

export function useConsent(): ConsentContextData {
  return useContext(ConsentContext)
}

type ConsentProviderProps = {
  children: ReactNode
}

export function ConsentProvider({
  children,
}: ConsentProviderProps): JSX.Element {
  const [consent, setConsent] = useState<ConsentContextData["consent"]>()

  useEffect(() => {
    window.addEventListener("CookiebotOnAccept", () => {
      setConsent(window.Cookiebot?.consent)
    })
  }, [])

  return (
    <ConsentContext.Provider value={{ consent }}>
      {children}
    </ConsentContext.Provider>
  )
}
