import { gql, useMutation } from "@apollo/client"
import { AnimatePresence, motion } from "framer-motion"
import { FunctionComponent, useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { captureException } from "@sentry/react"

import {
  TeamsPermissionsPendingTryFinishingTeamsSetupMutation as MutationData,
  TeamsPermissionsPendingTryFinishingTeamsSetupMutationVariables as MutationVariables,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { NotFound404Page } from "common/components/NotFound404Page"

import { platformSetupAnimationVariants } from "."

const mutations = {
  tryFinishingTeamsSetup: gql`
    mutation TeamsPermissionsPendingTryFinishingTeamsSetup(
      $state: String!
      $platformExternalId: ID!
    ) {
      companyPlatformId: tryFinishingTeamsSetup(
        state: $state
        platformExternalId: $platformExternalId
      )
    }
  `,
}

const TEAMS_PATH = "/admin/access/install/teams"

/**
 * Shown after the user has granted permissions to the Spill MS Teams app.
 *
 * It can take a few minutes for Microsoft to allow us to use the permissions,
 * so we poll the backend until they're ready to be used.
 */
export const TeamsPermissionsPending: FunctionComponent = () => {
  const { platformExternalId } = useParams()

  const [searchParams] = useSearchParams()
  const state = searchParams.get("state")

  const [tryFinishingTeamsSetup] = useMutation<MutationData, MutationVariables>(
    mutations.tryFinishingTeamsSetup
  )

  const navigate = useNavigate()
  const restartFlowWithError = (errorMessage: string) => {
    navigate(`${TEAMS_PATH}?error=${encodeURIComponent(errorMessage)}`)
  }

  useEffect(() => {
    if (platformExternalId === undefined || state === null) return

    let isCancelled = false

    const check = async () => {
      try {
        const { data } = await tryFinishingTeamsSetup({
          variables: { platformExternalId, state },
        })

        if (!isCancelled && typeof data?.companyPlatformId === "string") {
          // Setup finished successfully, go to success page.
          navigate(
            `${TEAMS_PATH}/success?companyPlatformId=${data.companyPlatformId}`
          )
        }
      } catch (error) {
        captureException(error)
        if (!isCancelled) restartFlowWithError("an unknown error occurred")
      }
    }

    // Check every 10 seconds.
    const intervalId = setInterval(check, 10_000)

    // After 10 minutes, time out.
    const timeoutId = setTimeout(
      () => {
        restartFlowWithError("waiting for permissions timed out")
      },
      10 * 60 * 1000
    )

    return () => {
      isCancelled = true
      clearInterval(intervalId)
      clearTimeout(timeoutId)
    }
  }, [platformExternalId, state])

  if (platformExternalId === undefined || state === null) {
    return <NotFound404Page />
  }

  return (
    <AnimatePresence>
      <motion.div
        key="content"
        variants={platformSetupAnimationVariants}
        className="flex justify-center align-center items-center flex-col gap-12"
        transition={{
          duration: 1.25,
          staggerChildren: 0.25,
          delay: 0.2,
        }}
        initial="initial"
        animate="animate"
      >
        <motion.h2
          className="text-center text-blue-800 text-5xl"
          variants={platformSetupAnimationVariants}
        >
          We're linking Spill to your organization
        </motion.h2>
        <p className="text-lg text-blue-800 mb-12">
          This may take a few minutes as Microsoft securely checks permissions.
        </p>
        <LoadingSpinner sizeInPixels={64} />
      </motion.div>
    </AnimatePresence>
  )
}
