// Category, MediaType and Level enums are directly visible in the UI
export enum Category {
  Self = "Self",
  Relationships = "Relationships",
  Work = "Work",
}

export enum MediaType {
  Book = "Book",
  Podcast = "Podcast",
  Series = "Series",
  Video = "Video",
}

export enum Level {
  Starter = "Starter",
  Advanced = "Advanced",
}

type ResourceCommon = {
  title: string
  subtitle: string | null
  authors: string[]
  category: Category
  mediaType: MediaType
  durationInMinutes: number | null
  numOfEpisodes: number | null
  description: string
  sourceUrl: string
  level: Level
}

type BookResource = ResourceCommon & {
  mediaType: MediaType.Book
  durationInMinutes: null
  numOfEpisodes: null
}

type VideoResource = ResourceCommon & {
  mediaType: MediaType.Video
  durationInMinutes: number
  numOfEpisodes: null
}

type PodcastResource = ResourceCommon & {
  mediaType: MediaType.Podcast
  durationInMinutes: number
  numOfEpisodes: number
}

type SeriesResource = ResourceCommon & {
  mediaType: MediaType.Series
  durationInMinutes: number
  numOfEpisodes: number
}

export type Resource =
  | BookResource
  | PodcastResource
  | SeriesResource
  | VideoResource

export type CrisisLine = {
  name: string
  description: string
  website?: string
  phone?: string
  email?: string
}
