import MarkdownToJSX from "markdown-to-jsx"
import cn from "classnames"
import { merge } from "lodash"
import { P } from "@spillchat/puddles"

import type { MarkdownToJSX as TMarkdownToJSX } from "markdown-to-jsx"

const defaultOptions: TMarkdownToJSX.Options = {
  overrides: {
    a: {
      props: {
        className: "underline",
        target: "_blank",
        rel: "noopener noreferrer",
      },
    },
    blockquote: {
      props: { className: "pl-4 border-l-4 border-blue-100" },
    },
    hr: {
      props: { className: "border-b-2 border-grey-200 mx-[25%] my-8" },
    },
    ol: { props: { className: "list-decimal pl-4" } },
    p: { props: { className: "mb-2 last:mb-0" }, component: P },
    ul: { props: { className: "list-disc pl-4" } },
  },
}

type MarkdownProps = {
  children: string
  className?: string
  options?: TMarkdownToJSX.Options
}

export function Markdown({
  children,
  className,
  options = {},
}: MarkdownProps): JSX.Element {
  return (
    <MarkdownToJSX
      className={cn("whitespace-pre-line", className)}
      options={merge(defaultOptions, options)}
    >
      {children}
    </MarkdownToJSX>
  )
}
