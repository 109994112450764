import { Link as ReactLink } from "react-router-dom"

export function FeedbackConfirmationTriage(): JSX.Element {
  return (
    <main className="w-full flex flex-col items-center gap-16 py-24">
      <header className="space-y-2">
        <h1>Thanks for your Feedback</h1>
      </header>

      <div className="flex flex-col sm:flex-row flex-wrap gap-4">
        <ReactLink to="/" className="btn grow whitespace-nowrap">
          Return Home
        </ReactLink>
      </div>
    </main>
  )
}
