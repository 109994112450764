import { H4, P, Indicator } from "@spillchat/puddles"
import { format } from "date-fns"
import { FunctionComponent } from "react"

type TrialWidgetProps = {
  currentSessionPackUsage: number | null | undefined
  sessionPackCount: number | null | undefined
  currentSessionPackUsagePercentage: number
  inActiveTrialPeriod: boolean | null | undefined
  trialPeriodEndDate: string | null | undefined
  hasInactiveTrial: boolean | null | undefined
}

const formatDate = (date: string) => format(new Date(date), "dd MMM yyyy")

export const TrialWidget: FunctionComponent<TrialWidgetProps> = ({
  currentSessionPackUsage,
  sessionPackCount,
  currentSessionPackUsagePercentage,
  inActiveTrialPeriod,
  trialPeriodEndDate,
  hasInactiveTrial,
}) => {
  return (
    <div className="rounded-lg bg-mono-white border p-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 items-baseline">
            <H4>
              {currentSessionPackUsage}/{sessionPackCount}
            </H4>
            <P>trial sessions used</P>
          </div>
          <div className="bg-grey-200 rounded-lg w-full h-2">
            <div
              className="bg-teal-400 rounded-lg h-2"
              style={{
                width:
                  currentSessionPackUsagePercentage > 100
                    ? 100
                    : currentSessionPackUsagePercentage + "%",
              }}
            ></div>
          </div>
        </div>
        {trialPeriodEndDate !== undefined && trialPeriodEndDate !== null && (
          <div className="flex gap-2 items-center">
            {inActiveTrialPeriod === true && (
              <>
                <Indicator variant="ok">Trial</Indicator>
                {trialPeriodEndDate !== null && (
                  <P size="xs">Expires {formatDate(trialPeriodEndDate)}</P>
                )}
              </>
            )}
            {hasInactiveTrial === true && (
              <>
                <Indicator variant="issue">Trial</Indicator>
                {trialPeriodEndDate !== null && (
                  <P size="xs">Expired {formatDate(trialPeriodEndDate)}</P>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
