export type BurnoutQuestion = {
  title: string
  text: string
  fieldName: "chronicallyTired" | "hopelessAtWork" | "achievingLessThanIShould"
}

export const chronicallyTired: BurnoutQuestion = {
  title: "I am chronically tired and often wake up exhausted",
  text: "Persistent fatigue and tiredness can be an indicator of burnout.",
  fieldName: "chronicallyTired",
}

export const hopelessAtWork: BurnoutQuestion = {
  title: "I feel hopeless at work",
  text: "You might feel more negative, cynical or self-critical than usual.",
  fieldName: "hopelessAtWork",
}

export const achievingLessThanIShould: BurnoutQuestion = {
  title: "I feel that I am achieving less than I should",
  text: "Completing familiar work tasks more slowly or with less care could be a sign of burnout. You might feel like you're not getting much done, even though you're trying your best.",
  fieldName: "achievingLessThanIShould",
}

export const BURNOUT_LEVELS: string[] = [
  "Never",
  "A few times a year or less",
  "Once a month or less",
  "A few times a month",
  "Once a week",
  "A few times a week",
  "Every day",
]
