import { gql } from "@apollo/client"

export const mutations = {
  requestPackage: gql(`
    mutation TherapySpecialisedSupportRequestForm(
      $request: RequestTherapyPackageInput!
    ) {
      requestTherapyPackage(request: $request) {
        id
      }
    }
  `),
}
