import React, { StrictMode, useEffect } from "react"
import ReactDom from "react-dom"
import { createRoot } from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { ApolloProvider } from "@apollo/client"
import { IntlProvider } from "react-intl"
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react"
import Passwordless from "supertokens-auth-react/recipe/passwordless"
import Session from "supertokens-auth-react/recipe/session"
import ThirdParty from "supertokens-auth-react/recipe/thirdparty"
import { Toaster, toast } from "sonner"
import { FlagProvider } from "@unleash/proxy-client-react"
import { Alert, P } from "@spillchat/puddles"
import { HelmetProvider } from "react-helmet-async"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid"

import * as mixpanel from "config/vendor/mixpanel"
import { config } from "config"
import { SIGN_IN_OUTCOME } from "common/constants"
import { AnalyticsProvider } from "common/context/analyticsContext"
import { AppProvider } from "common/context/appContext"
import { AuthProvider, useAuth } from "common/context/authContext"
import { ConsentProvider } from "common/context/consentContext"
import {
  TeamsContextProvider,
  isTeams,
  useTeams,
} from "common/context/teamsContext"
import ScrollToTop from "common/helpers/scrollToTop"
import { ErrorPage } from "common/components/ErrorPage"
import { UnauthenticatedRoute } from "common/components/UnauthenticatedRoute"
import { ImpersonationPanel } from "common/components/ImpersonationPanel"
import { SignInPage } from "features/admin/pages/SignInPage"
import { SuperTokensCallback } from "features/auth/pages/SuperTokensCallback"
import { UserInviteLandingPage } from "features/auth/pages/UserInviteLandingPage"
import { BuyerSignup } from "features/signup"
import { DocumentRouter } from "features/documents"
import { PlatformSetup } from "features/admin/pages/PlatformSetup"
import { QuoteSignup } from "features/quote-signup/QuoteSignup"
import { RequireAuth } from "features/auth/components/RequireAuth"
import { LinkUser } from "features/auth/pages/LinkUser"

import { App as Spill3App } from "./Spill3App"
import { apolloClient } from "./apolloClient"

import "./index.css"

Sentry.init({
  debug: config.environment === "development",
  dsn: config.sentry.dsn,
  // Comment this out to enable sending Sentry events in development
  enabled: config.environment !== "development",
  environment: config.environment,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    /^Internal GraphQL Error$/,
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: config.environment === "development" ? 1 : 0.02,
  tracesSampleRate: config.environment === "production" ? 0.2 : 1,
})

mixpanel.load()

SuperTokens.init({
  appInfo: {
    appName: "Spill",
    apiDomain: config.spill.gateway.baseUrl,
    websiteDomain: window.location.origin,
    apiBasePath: "/supertokens",
  },
  enableDebugLogs: config.superTokens.enableDebugLogs,
  recipeList: [
    Session.init(
      isTeams ? { isInIframe: true, tokenTransferMethod: "header" } : {}
    ),
    Passwordless.init({
      contactMethod: "EMAIL",
      signInUpFeature: {
        disableDefaultUI: true,
      },
    }),
    ThirdParty.init({
      signInAndUpFeature: {
        disableDefaultUI: true,
        providers: [
          {
            id: "ms_teams",
            name: "MS Teams",
          },
          {
            id: "slack",
            name: "Slack",
          },
        ],
      },
    }),
  ],
})

const flagConfig = {
  url: config.spill.gateway.baseUrl + "/proxy",
  clientKey: config.unleash.clientKey,
  refreshInterval: 60,
  appName: "web-app",
}

/**
 * We use axe to help us improve the accessibility of our app. We only want to
 * run this in development mode, so we only import it here.
 */
if (["development", "staging"].includes(config.environment)) {
  // Dynamically import axe so it doesn't get bundled in production
  const initialiseAxe = async () => {
    const axe = await import("@axe-core/react").then(axe => axe.default)
    void axe(React, ReactDom, 1000)
  }
  void initialiseAxe()
}

/**
 * We have two versions of the app, v2 and v3. We want to render the correct
 * version based on the env config as well as the url the user is visiting.
 * This is because we're migrating users over to 3 over a period of time, rather
 *  If the user is a known spill3 user (has a session) we want to render v3
 * than just flipping a switch. The logic is as follows:
 *  If the user is visiting a reservedSpill3Route, we render v3
 *  If the user doesn't have a session, we render v2
 */
const SpillAppWrapper = Sentry.withProfiler(() => {
  const { signInOutcome, user, unverifiedPlatformUser } = useAuth()
  const { isTeams } = useTeams()

  // Show error toast on failed Slack sign in.
  useEffect(() => {
    switch (signInOutcome) {
      case SIGN_IN_OUTCOME.MISSING:
        toast.error(
          "Unable to sign in. You are not in a Slack channel with the Spill app installed."
        )
        break
      case SIGN_IN_OUTCOME.MISSING_WORKSPACE:
        toast.error(
          "Unable to sign in. Please speak with your workspace admin."
        )
        break
      case SIGN_IN_OUTCOME.NO_ACCESS:
        toast.error(
          "Your account has been deactivated. Speak with your Spill admin to regain access."
        )
        break
      case SIGN_IN_OUTCOME.DENIED:
      case SIGN_IN_OUTCOME.ERROR:
        toast.error("Unable to sign in. Please try again.")
        break
    }
  }, [signInOutcome])

  if (unverifiedPlatformUser != null) {
    return <LinkUser />
  }

  //  this is a hack for teams users not being in a channel/team
  if (isTeams && user === null) {
    return (
      <div className="w-full h-full p-10">
        <Alert
          variant="warning"
          title="You currently don't have access to Spill."
        >
          <P size="xs">
            You must have access to use Spill. This could be because you aren't
            in a team with the Spill app or your access may have been revoked.
            Contact your Spill admin for help. If you are still having trouble
            please contact real-person@spill.chat.
          </P>
        </Alert>
      </div>
    )
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/documents/*" element={<DocumentRouter />} />
        {/* Allow both unauthenticated and authenticated users to view install instructions. */}
        <Route path="/admin/access/install/*" element={<PlatformSetup />} />
        <Route
          path="/invite"
          element={
            <UnauthenticatedRoute>
              <UserInviteLandingPage />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/signin"
          element={
            <UnauthenticatedRoute>
              <SignInPage />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/signup/*"
          element={
            <UnauthenticatedRoute>
              <BuyerSignup />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/quote"
          element={
            <UnauthenticatedRoute>
              <QuoteSignup />
            </UnauthenticatedRoute>
          }
        />
        <Route path="/auth/callback/post" element={<SuperTokensCallback />} />

        <Route path="/error/*" element={<ErrorPage />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Spill3App />
            </RequireAuth>
          }
        />
      </Routes>
      {config.impersonateEnabled && <ImpersonationPanel />}
    </>
  )
})

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <StrictMode>
    <IntlProvider locale="en">
      <FlagProvider config={flagConfig}>
        <ConsentProvider>
          <BrowserRouter>
            <ApolloProvider client={apolloClient}>
              <TeamsContextProvider>
                <SuperTokensWrapper>
                  <AuthProvider>
                    <AnalyticsProvider>
                      <HelmetProvider>
                        <AppProvider>
                          <Toaster
                            richColors
                            expand
                            icons={{
                              success: <CheckCircleIcon />,
                              info: <InformationCircleIcon />,
                              warning: <ExclamationTriangleIcon />,
                              error: <ExclamationCircleIcon />,
                            }}
                            toastOptions={{
                              unstyled: true,
                              classNames: {
                                toast:
                                  "flex items-center w-full p-4 gap-1 bg-grey-100 rounded-lg border",
                                title:
                                  "font-inter text-sm leading-tight tracking-[-0.28px] font-medium",
                                description:
                                  "font-inter text-xs tracking-[-0.28px] font-normal",
                              },
                            }}
                          />
                          <SpillAppWrapper />
                        </AppProvider>
                      </HelmetProvider>
                    </AnalyticsProvider>
                  </AuthProvider>
                </SuperTokensWrapper>
              </TeamsContextProvider>
            </ApolloProvider>
          </BrowserRouter>
        </ConsentProvider>
      </FlagProvider>
    </IntlProvider>
  </StrictMode>
)
