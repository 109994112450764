import { ColumnDef } from "@tanstack/react-table"
import { Button, DataTable } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline"

export type AdminTherapySpecialisedSupportEnrolTableRow = {
  id: string
  userDisplayName: string
  numberOfSessions: number
  expirationDate: string
}

type AdminTherapySpecialisedSupportEnrolTableProps = {
  data: AdminTherapySpecialisedSupportEnrolTableRow[]
  onRemove: (id: number) => void
}

export const AdminTherapySpecialisedSupportEnrolTable: FunctionComponent<
  AdminTherapySpecialisedSupportEnrolTableProps
> = ({
  data,
  onRemove: handleRemove,
}: AdminTherapySpecialisedSupportEnrolTableProps) => {
  const columns: ColumnDef<AdminTherapySpecialisedSupportEnrolTableRow>[] = [
    {
      accessorKey: "userDisplayName",
      header: "Name",
      cell: ({ row }) => {
        const invite = row.original
        return <span className="block py-2">{invite.userDisplayName}</span>
      },
    },
    {
      accessorKey: "numberOfSessions",
      header: "Sessions",
      cell: ({ row }) => {
        const invite = row.original
        return <span className="block py-2">{invite.numberOfSessions}</span>
      },
    },
    {
      accessorKey: "expirationDate",
      header: "Expiry date",
      cell: ({ row }) => {
        const invite = row.original
        const date =
          invite.expirationDate != null ? new Date(invite.expirationDate) : null
        return (
          <span className="block py-2">{date?.toDateString() ?? "N/A"}</span>
        )
      },
    },
    {
      id: "action",
      cell: ({ row }) => {
        return (
          <Button variant="tertiary">
            <XMarkIcon
              className="size-4"
              onClick={() => handleRemove(row.index)}
            />
          </Button>
        )
      },
    },
  ]

  return <DataTable data={data} columns={columns} showPagination={false} />
}
