import { useForm } from "react-hook-form"
import { STGeneralError } from "supertokens-website/utils/error"
import { Button, P } from "@spillchat/puddles"
import { Fragment, type ChangeEvent, type FunctionComponent } from "react"

import { createArray } from "common/helpers/createArray"
import { useAnalytics } from "common/context/analyticsContext"
import { useAuth } from "common/context/authContext"

import type { SubmitHandler } from "react-hook-form"

interface FieldValues {
  code: string[]
}

interface OTPInputProps {
  email: string
  align: "left" | "center"
  onOTPSuccess: () => void
  onOTPFailure: () => void
}

export const OTPInput: FunctionComponent<OTPInputProps> = props => {
  const { track } = useAnalytics()
  const { consumeOTP } = useAuth()

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
    setFocus,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: {
      // Init with empty string for each character
      code: createArray(9, () => ""),
    },
  })

  const onSubmit: SubmitHandler<FieldValues> = async values => {
    try {
      track("User enters OTP")

      const response = await consumeOTP({ userInputCode: values.code.join("") })

      switch (response.status) {
        case "OK":
          props.onOTPSuccess()
          break
        case "RESTART_FLOW_ERROR":
          // this can happen if the user has already successfully logged in into
          // another device whilst also trying to login to this one.
          // https://supertokens.com/docs/passwordless/custom-ui/login-otp#step-2-resending-a-new-otp
          props.onOTPFailure()
          break
        default:
          // this happes if the user tried an incorrect OTP
          if (response.error !== undefined) {
            setError("code.8", { message: response.error })
          }
      }
    } catch (err) {
      if (STGeneralError.isThisError(err)) {
        // this may be a custom error message sent from gateway,
        // or if some input is not valid.
        setError("code.8", { message: err.message })
      } else {
        setError("code.8", { message: "Oops! Something went wrong." })
      }
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-4 ${props.align === "center" ? "text-center" : "text-left"}`}
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-1 lg:gap-2 font-bold items-center text-base lg:text-2xl rounded-md w-fit">
          {createArray(9, index => (
            <Fragment key={index}>
              <input
                {...register(`code.${index}`, {
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value !== "" && index < 8) {
                      setFocus(`code.${index + 1}`)
                    }
                    if (e.target.value === "" && index > 0) {
                      setFocus(`code.${index - 1}`)
                    }
                  },
                })}
                autoComplete="off"
                // This autofocuses the first input so we're happy with it
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={index === 0}
                className="aspect-square w-8 h-8 border border-grey-200 capitalize rounded-md text-center max-w-[48px] focus:outline-none focus:ring-2"
                maxLength={1}
                onFocus={e => {
                  e.target.select()
                }}
                onPasteCapture={e => {
                  setFocus("code.8")
                  const pastedCode = e.clipboardData
                    .getData("Text")
                    .replace(/\s|-/g, "")
                  for (let i = 0; i < 9; i++) {
                    setValue(`code.${i}`, pastedCode[i] ?? "")
                  }
                }}
              />
              {[2, 5].includes(index) && <span key={`${index}dash`}>-</span>}
            </Fragment>
          ))}
        </div>
        {errors.code && errors.code[8] && (
          <span className="text-red-400">
            <P size="xs">{errors.code[8].message}</P>
          </span>
        )}
        <P size="xs">Can't find your code? Check your spam folder!</P>
      </div>
      <div
        className={`flex flex-col gap-2 ${props.align === "center" ? "items-center" : "items-start"}`}
      >
        <Button loading={isSubmitting} type="submit">
          Confirm code
        </Button>
      </div>
    </form>
  )
}
