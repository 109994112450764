import { FunctionComponent, useEffect } from "react"
import { getTimezoneOffset } from "date-fns-tz"
import { useFormContext } from "react-hook-form"
import { sortBy } from "lodash"
import { Select, Form } from "@spillchat/puddles"

import { TIME_ZONES } from "common/constants"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { useApp } from "common/context/appContext"

// Select option props
const options = sortBy(
  TIME_ZONES.flatMap(timeZone => {
    const [continent, regionOrCity] = timeZone.split("/")

    if (continent === undefined || regionOrCity === undefined) return []

    // Timezone offset in milliseconds
    const offset = getTimezoneOffset(timeZone) / 60 / 60 / 1000
    const offsetHours = String(offset - (offset % 1))
    const offsetMinutes = offset % 1 === 0 ? "00" : Math.abs((offset % 1) * 60)

    return {
      group: continent,
      key: timeZone,
      // We show GMT instead of the more modern UTC
      // because it's more familiar with UK audiences
      // https://www.nngroup.com/articles/time-zone-selectors/#:~:text=GMT%20Slightly%20More%20Familiar%20than%20UTC
      label: `(GMT${
        offset === 0 ? "±" : offset > 0 ? "+" : ""
      }${offsetHours}:${offsetMinutes})`,
      offset,
      value: timeZone,
    }
  }),
  ["offset", "group"]
)

export const TimeZoneSelect: FunctionComponent = () => {
  const { appTimeZone, setAppTimeZone } = useApp()
  const { control, watch } = useFormContext<FormValues>()

  const { timeZone } = watch()

  useEffect(() => setAppTimeZone(timeZone), [timeZone])

  return (
    <Form.Field
      name="timeZone"
      control={control}
      render={({ field }) => (
        <Select.Root
          onValueChange={field.onChange}
          defaultValue={
            options.find(element => element.key === appTimeZone)?.value
          }
        >
          <Select.Trigger size="sm">
            <Select.Value placeholder="Select timezone" />
          </Select.Trigger>
          <Select.Content>
            {options.map(option => (
              <Select.Item key={option.key} value={option.value}>
                {option.key.replaceAll("_", " ")} {option.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      )}
    />
  )
}
