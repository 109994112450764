import { ComponentPropsWithoutRef, FunctionComponent } from "react"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import cn from "classnames"

import { Tooltip } from "common/components/Tooltip"

interface LabelProps extends ComponentPropsWithoutRef<"label"> {
  bold?: boolean
  size?: "sm" | "md"
  tooltip?: ComponentPropsWithoutRef<typeof Tooltip>["content"]
}

export const Label: FunctionComponent<LabelProps> = ({
  bold = true,
  children,
  className,
  htmlFor,
  size = "md",
  tooltip,
}) => (
  <label
    className={cn(
      "flex font-inter gap-2 items-center text-black tracking-[0.2px] w-full",
      {
        "font-semibold": bold,
        "text-xs": size === "sm",
        "text-sm": size === "md",
      },
      className
    )}
    htmlFor={htmlFor}
  >
    {children}
    {tooltip !== undefined && (
      <Tooltip content={tooltip} followMouse>
        <InformationCircleIcon className="w-4 h-4" />
      </Tooltip>
    )}
  </label>
)
