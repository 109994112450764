/**
 * Component that displays information for the user about what the key insights
 * are and where to read details about improving them.
 */

import { motion } from "framer-motion"

import LogoNoBackground from "common/assets/logo/no-background-logo.png"
import { ExternalLink } from "common/components/ExternalLink"

export const KeyInsightsReadMore: React.FunctionComponent = () => {
  return (
    <div className="flex flex-col items-center w-full py-8 mt-2 md:mt-0 h-full">
      <div className="w-10/12 flex flex-col items-center">
        <motion.img
          animate={{
            y: [0, 10, 0],
            transition: {
              duration: 10,
              repeat: Infinity,
              repeatType: "loop",
            },
          }}
          src={LogoNoBackground}
          alt="The Spill Logo"
          className="w-28 mb-8"
        />
        <motion.div
          className="mb-4"
          animate={{
            opacity: [0.1, 0.36, 0.1],
            transition: {
              duration: 10,
              repeat: Infinity,
              repeatType: "loop",
            },
          }}
          style={{
            width: "233px",
            height: "28px",
            left: "175px",
            top: "265px",
            background:
              "radial-gradient(50% 50% at 50% 50%, #35D0BA 0%, rgba(217, 217, 217, 0) 100%)",
          }}
        />
        <h5 className="text-blue-800 text-md font-bold mb-4">
          Want to improve your insight scores?
        </h5>
        <p className="text-md text-center">
          <ExternalLink to="https://www.spill.chat/resources#employee-wellbeing">
            Read our resources
          </ExternalLink>{" "}
          to see how you can improve your company metrics!
        </p>
      </div>
    </div>
  )
}
