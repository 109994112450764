import { useMutation, useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  Form,
  H2,
  Label,
  P,
  SelectableCard,
  Tabs,
  TextArea,
  ModalFullScreenInner,
  Breadcrumbs,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useForm } from "react-hook-form"
import { Link, useParams } from "react-router-dom"

import {
  AdminTherapySpecialisedSupportPackageQuery,
  TherapySpecialisedSupportRequestFormMutationVariables,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useGoBack } from "common/hooks/useGoBack"

import { mutations } from "./therapy-specialised-support-request.mutations"
import { queries } from "./therapy-specialised-support-request.queries"
import { formSchema } from "./therapy-specialised-support-request.schema"

import type { TherapySpecialisedSupportRequestForm } from "./therapy-specialised-support-request.schema"

export const TherapySpecialisedSupportRequest: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const { packageId } = useParams()
  const goBack = useGoBack()
  const [step, setStep] = useState(0)

  const { data: packageData, loading: packageLoading } =
    useQuery<AdminTherapySpecialisedSupportPackageQuery>(queries.getPackage, {
      variables: {
        id: packageId,
      },
    })

  const form = useForm<TherapySpecialisedSupportRequestForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      packageSettingsId: packageId,
      noteFromUser: "",
      isAnonymous: false,
    },
  })

  const [requestPackage, { loading }] = useMutation<
    TherapySpecialisedSupportRequestFormMutationVariables,
    TherapySpecialisedSupportRequestFormMutationVariables
  >(mutations.requestPackage, {
    refetchQueries: ["TherapySpecialisedSupportPackage"],
  })

  if (isUserLoading || packageLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  const handleRecipient = () => setStep(1)

  const handleSubmit = async (
    request: TherapySpecialisedSupportRequestForm
  ) => {
    await requestPackage({
      variables: { request },
    })

    return setStep(2)
  }

  const tabs = [
    { label: "Request", isActive: step >= 0, step: 0 },
    { label: "Anonymity", isActive: step >= 1, step: 1 },
    { label: "Confirmation", isActive: step >= 2, step: 2 },
  ]

  return (
    <>
      <Helmet title="Request support | Spill" />

      <ModalFullScreenInner title="Request support" onClose={() => goBack()}>
        <div className="max-w-screen-sm mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
          <Tabs.Root
            defaultValue={step.toString()}
            value={step.toString()}
            className="flex flex-col gap-6 lg:gap-12"
          >
            <Form.Root {...form}>
              <Breadcrumbs
                onStepChange={setStep}
                currentStep={step}
                tabs={tabs}
              ></Breadcrumbs>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Tabs.Content value="0">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-3">
                      <H2>Request support</H2>
                      <P muted>
                        You can add a short message to your admin to let them
                        know why you'd like this support.
                      </P>
                    </div>
                    <div className="flex flex-col gap-4">
                      <Form.Field
                        control={form.control}
                        name="noteFromUser"
                        render={({ field }) => (
                          <Form.Item>
                            <Form.Control>
                              <TextArea
                                placeholder="Type something"
                                className="h-48"
                                {...field}
                                label={{
                                  children: "Note",
                                }}
                              />
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                    </div>
                    <div className="flex justify-between items-center">
                      <Button
                        onClick={() => handleRecipient()}
                        variant="primary"
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content value="1">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-3">
                      <H2>Would you like to send your request anonymously?</H2>
                      <P muted>
                        Choose how you want the support to be announced.
                      </P>
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label>Notification</Label>
                      <div>
                        <Form.Field
                          control={form.control}
                          name="isAnonymous"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <div className="grid lg:grid-cols-2 gap-4">
                                  {[
                                    {
                                      title: "Include my name",
                                      subtitle: "Admin will know who you are",
                                      value: false,
                                    },
                                    {
                                      title: "Anonymously",
                                      subtitle: "Admin won't know who you are",
                                      value: true,
                                    },
                                  ].map(item => {
                                    return (
                                      <SelectableCard
                                        key={item.title}
                                        size="sm"
                                        checked={field.value === item.value}
                                        title={item.title}
                                        subtitle={item.subtitle}
                                        type="radio"
                                        {...form.register("isAnonymous")}
                                        value={item.value ? 1 : 0}
                                        onClick={() => {
                                          form.setValue(
                                            "isAnonymous",
                                            item.value
                                          )
                                        }}
                                      />
                                    )
                                  })}
                                </div>
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <Button
                        onClick={() => setStep(step - 1)}
                        variant="secondary"
                      >
                        Back
                      </Button>
                      <Button type="submit" variant="primary" loading={loading}>
                        Confirm
                      </Button>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content value="2">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>
                        Your request for{" "}
                        {packageData?.getCompanyPackageSettingById?.name} has
                        been sent
                      </H2>
                      <P>
                        We've sent the request to your admins. They'll have 7
                        days to make a decision. Once they've done this, we'll
                        let you know.
                      </P>
                    </div>
                    <Button asChild>
                      <Link
                        to={`/therapy/specialised-support/${packageData?.getCompanyPackageSettingById?.id}`}
                      >
                        Finish
                      </Link>
                    </Button>
                  </div>
                </Tabs.Content>
              </form>
            </Form.Root>
          </Tabs.Root>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
