import { FunctionComponent, useEffect, useRef } from "react"
import { Button, H4, P } from "@spillchat/puddles"
import { ApolloQueryResult, useMutation, useQuery } from "@apollo/client"
import { toast } from "sonner"

import {
  PmiSettingsTurnOffPmiSettingMutationVariables,
  PmiSettingsGetPmiSettingValueAndDetailsQuery,
  PmiSettingsTurnOffPmiSettingMutation,
} from "types/graphql"

import {
  pmiProviderDetails,
  pmiProviders,
  PmiProviderType,
} from "../../PmiSetupForm/PmiSetupForm.types"
import { mutations } from "../PmiSettings.mutations"
import { queries } from "../PmiSettings.queries"

interface PmiEnabledCardProps {
  provider: string | undefined
  refetch: () => Promise<
    ApolloQueryResult<PmiSettingsGetPmiSettingValueAndDetailsQuery>
  >
}

export const PmiEnabledCard: FunctionComponent<PmiEnabledCardProps> = ({
  provider,
  refetch,
}) => {
  const innerRef = useRef<HTMLDivElement>(null)
  const outerRef = useRef<HTMLDivElement>(null)

  const { data } = useQuery<PmiSettingsGetPmiSettingValueAndDetailsQuery>(
    queries.getPmiSettingValueAndDetails
  )
  const userTherapyCap =
    data?.user?.company?.featuresAndSettings.userTherapyCap.value

  const resizeInnerRef = () => {
    if (innerRef.current && outerRef.current) {
      innerRef.current.style.marginTop = "0"
      innerRef.current.style.marginTop = `${
        outerRef.current.scrollHeight - innerRef.current.scrollHeight
      }px`
    }
  }

  const [turnOff, { loading: turningOff }] = useMutation<
    PmiSettingsTurnOffPmiSettingMutation,
    PmiSettingsTurnOffPmiSettingMutationVariables
  >(mutations.turnOffPmiSetting, {
    onCompleted() {
      toast.success("Your PMI pathway has been removed.")
    },
    onError() {
      toast.error("Something went wrong, please try again or get in touch.")
    },
  })

  const providerImage =
    provider !== undefined &&
    (pmiProviders as readonly string[]).includes(provider)
      ? pmiProviderDetails[provider as PmiProviderType].image
      : undefined

  useEffect(() => {
    resizeInnerRef()
    window.addEventListener("resize", resizeInnerRef)
    return () => {
      window.removeEventListener("resize", () => resizeInnerRef)
    }
  }, [])

  return (
    <div className="col-span-2">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label
        className="group relative ring-2 focus-within:ring-red-600 ring-blue-800 flex flex-col justify-end md:min-h-36 p-6 bg-card border border-spill-grey-200 rounded-lg text-card-foreground w-full cursor-pointer overflow-hidden"
        onMouseDown={event => event.preventDefault()}
        onTouchStart={event => event.preventDefault()}
      >
        <input
          type="checkbox"
          tabIndex={0}
          checked={true}
          onChange={event => {
            // Don't immediately uncheck: wait for user to confirm first
            event.preventDefault()
          }}
          className="sr-only"
        />
        <div ref={outerRef} className="grow basis-full mb-1 -me-1">
          {providerImage !== undefined && (
            <>
              <img
                src={providerImage}
                alt={`${provider} logo`}
                className="absolute top-[1.125rem] right-[1.125rem] w-10 h-10 object-cover rounded-lg"
              />
              <div
                className="float-right w-10 h-10 -mt-1.5 ms-5 -me-1.5 mb-2"
                role="presentation"
              />
            </>
          )}
          <div ref={innerRef}>
            <H4>PMI pathway with {provider ?? "unspecified provider"}</H4>
          </div>
        </div>
        <div className="grid grid-rows-[1fr] transition-all duration-500 group-focus-within:opacity-0 group-focus-within:grid-rows-[0fr]">
          <div className="flex items-end overflow-hidden">
            <P muted>Employees can continue with private medical insurance</P>
          </div>
        </div>
        <div className="flex items-end transition-all duration-500 h-0 overflow-hidden pointer-events-none opacity-0 group-focus-within:h-10 group-focus-within:opacity-100 group-focus-within:pointer-events-auto">
          <Button
            size="sm"
            variant="destructive"
            onClick={async () => {
              await turnOff({ variables: { userTherapyCap } })
              await refetch()
            }}
            loading={turningOff}
          >
            Remove pathway
          </Button>
        </div>
      </label>
    </div>
  )
}
