import { gql } from "@apollo/client"

export const queries = {
  getPackage: gql`
    query AdminTherapySpecialisedSupportEdit($id: ID!) {
      user {
        id
        company {
          id
          openTherapyExtensions {
            id
            companyPackageSetting {
              id
              numberSessions
            }
            createdAt
            expiryDate
            isAnonymous
            originType
            packageType
            status
          }
        }
      }
      getCompanyPackageSettingById(companyPackageSettingId: $id) {
        id
        name
        icon
        state
        iconUrl
        numberMonthsToUse
        numberSessions
        forAllEmployees
        visibleToAllEmployees
        userFacingDescription
      }
      getCompanyPackageSettingSupportedEmployees(companyPackageSettingId: $id) {
        companyPackageSettingId
        createdAt
        expirationDate
        numberOfSessions
        userDisplayName
      }
      getAllNonCancelledTherapyExtensionsForTherapyPackageId(packageId: $id) {
        id
        createdAt
        expiryDate
        isAnonymous
        originType
        packageType
        status
        user {
          displayName
        }
      }
    }
  `,
}
