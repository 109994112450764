import { useMedia } from "react-use"

import { useTheme } from "common/hooks/useTheme"

import type { DefaultTheme } from "tailwindcss/types/generated/default-theme"

export function useBreakpoint(
  breakpoint: keyof DefaultTheme["screens"]
): boolean {
  const { screens } = useTheme()

  return useMedia(`(min-width: ${screens[breakpoint]})`)
}
