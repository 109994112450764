/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/**
 * This is the home page for the admin
 * They can see an overview of various usages by the users
 * It's available at /admin
 */

import { useQuery } from "@apollo/client"
import {
  BellAlertIcon,
  CalendarDaysIcon,
  EnvelopeIcon,
  GiftIcon,
  HeartIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"
import { Button, H1, H4, P, Separator } from "@spillchat/puddles"
import {
  differenceInDays,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from "date-fns"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"

import Logo from "common/assets/logo/no-background-logo.png"
import { useAnalytics } from "common/context/analyticsContext"
import { useAdminOnboarding } from "common/hooks/useAdminOnboarding"
import { OnboardingModal } from "features/admin/components/OnboardingModal/OnboardingModal"
import {
  OnboardingEnabledPlans,
  OnboardingSteps,
} from "features/admin/components/OnboardingSteps"
import {
  AdminHomeGetCompanyQuery,
  AdminHomeGetCompanyQueryVariables,
  SpillSubscriptionPlan,
} from "types/graphql"

import { ProductUpdates } from "./components/ProductUpdates"
import { RecommendedActions } from "./components/RecommendedActions"
import { queries } from "./Home.queries"

export const AdminHome: React.FunctionComponent = () => {
  const { track } = useAnalytics()

  const { data, loading } = useQuery<
    AdminHomeGetCompanyQuery,
    AdminHomeGetCompanyQueryVariables
  >(queries.getCompany, {
    fetchPolicy: "cache-and-network",
  })

  const company = data?.user?.company
  const { hasCompletedAdminOnboarding } = data?.user ?? {}
  const allUsageData = company?.allUsage
  let lastMonthUsageData = allUsageData?.[allUsageData.length - 1]
  const subscriptionPlan = data?.user?.company?.subscriptionPlan
  const isStarterPlan = subscriptionPlan === SpillSubscriptionPlan.STARTER
  const inActiveTrial = company?.inActiveTrialPeriod ?? false
  const sessionPackValue = company?.featuresAndSettings.sessionPack.value ?? 0

  const { steps, isOnboarding } = useAdminOnboarding({ subscriptionPlan })

  if (
    (allUsageData?.length ?? 0) > 1 &&
    // we checked that there is elements, so it won't be undefined
    new Date(lastMonthUsageData!.month).getMonth() === new Date().getMonth()
  ) {
    lastMonthUsageData = allUsageData?.[allUsageData.length - 2]
  }

  // if we're in a trial, show the sessions used in the trial period,
  // otherwise show the previous month's usage data
  const calculateTotalSessions = () => {
    if (inActiveTrial) return company?.currentSessionPackUsage ?? 0
    return lastMonthUsageData?.therapyHours ?? 0
  }

  const daysLeftInTrial = () => {
    if (company?.trialPeriodEndDate == null) {
      return 0
    }

    const numberDaysLeft = differenceInDays(
      company?.trialPeriodEndDate,
      new Date()
    )

    return Math.max(0, numberDaysLeft)
  }

  if (loading) {
    return null
  }

  const featuresAndSettings = data?.user?.company?.featuresAndSettings

  const handleVideoTracking = () => {
    track("Onboarding Video Clicked")
  }

  const lastMonthStart = startOfMonth(subMonths(new Date(), 1))
  const lastMonthEnd = endOfMonth(subMonths(new Date(), 1))

  const lastMonthStartFormated = format(new Date(lastMonthStart), "d MMM yyyy")
  const lastMonthEndFormated = format(new Date(lastMonthEnd), "d MMM yyyy")

  const trialStart = company?.trialStartDate
  const trialEnd = company?.trialPeriodEndDate

  const trialStartFormated =
    trialStart != null ? format(new Date(trialStart), "d MMM yyyy") : null
  const trialEndFormated =
    trialEnd != null ? format(new Date(trialEnd), "d MMM yyyy") : null

  const SummaryGrid = () => {
    return (
      <div className="flex flex-1 flex-col gap-8">
        <H4 sectionHeader>Spill at a glance</H4>
        <div className="flex flex-col gap-3">
          <motion.div
            className="flex flex-col rounded-lg border border-grey-200"
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <div className="flex w-full flex-col justify-between lg:h-48 lg:flex-row">
              <div className="p-4 flex h-full min-h-40 flex-1 flex-col justify-between lg:border-r border-b lg:border-b-0 border-grey-200">
                <div className="flex items-center justify-between gap-2 font-inter font-semibold">
                  <H4>People</H4>
                  <UsersIcon className="size-6" />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-baseline gap-2">
                    <H1>{company?.billableUserCount ?? 0}</H1>
                  </div>
                  <Button size="sm" variant="tertiary" asChild>
                    <Link to="access">Go to Access</Link>
                  </Button>
                </div>
              </div>

              {!inActiveTrial && (
                <div className="p-4 flex flex-1 min-h-40 flex-col justify-between gap-2 border-b lg:border-b-0 border-grey-200">
                  <div className="flex items-center justify-between gap-2 font-inter font-semibold">
                    <H4>People struggling</H4>
                    <BellAlertIcon className="size-6" />
                  </div>
                  <div className="flex flex-col">
                    {featuresAndSettings?.pulse.value === true ? (
                      <H1>
                        {lastMonthUsageData?.distinctUserPulseTriages ?? 0}
                      </H1>
                    ) : (
                      <>
                        {featuresAndSettings?.pulse.active === false ? (
                          <Button size="sm" variant="tertiary" asChild>
                            <Link
                              to="https://spill.notion.site/Give-your-employees-access-to-more-12349f92fc6b80ae911ec2ddae0861d8"
                              target="_blank"
                            >
                              Add to your plan
                            </Link>
                          </Button>
                        ) : (
                          <P muted>
                            You're currently not tracking struggling users.
                            Access the{" "}
                            <Link to="/admin/therapy/settings">settings</Link>{" "}
                            page to enable it
                          </P>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {inActiveTrial && (
                <div className="p-4 flex flex-1 flex-col justify-between">
                  <div className="flex items-center justify-between gap-2 font-inter font-semibold">
                    {inActiveTrial && <H4>Days left in trial</H4>}
                    <CalendarDaysIcon className="size-6" />
                  </div>
                  <H1>{daysLeftInTrial()}</H1>
                </div>
              )}
            </div>

            <div className="lg:block hidden">
              <hr />
            </div>

            <div className="flex w-full flex-col justify-between lg:h-48  lg:flex-row">
              <div className="p-4 flex flex-1 min-h-40 flex-col justify-between lg:border-r border-b lg:border-b-0 border-grey-200">
                <div className="flex items-center justify-between gap-2 font-inter font-semibold">
                  {!inActiveTrial && <H4>Therapy sessions</H4>}
                  {inActiveTrial && <H4>Sessions used</H4>}
                  <HeartIcon className="size-6" />
                </div>
                <div className="flex flex-col">
                  <H1>{calculateTotalSessions() ?? 0}</H1>
                  <Button size="sm" variant="tertiary" asChild>
                    <Link to="dashboard/engagement">Go to Engagement</Link>
                  </Button>
                </div>
              </div>

              {!inActiveTrial && (
                <div className="p-4 flex flex-1 min-h-40 flex-col justify-between gap-2">
                  <div className="flex items-center justify-between gap-2 font-inter font-semibold">
                    <H4>Ask a Therapist</H4>
                    <EnvelopeIcon className="size-6" />
                  </div>
                  <div className="flex flex-col">
                    {featuresAndSettings?.askATherapist.value === true ? (
                      <H1>{lastMonthUsageData?.askATherapistQuestions ?? 0}</H1>
                    ) : (
                      <>
                        {featuresAndSettings?.askATherapist.active === false ? (
                          <Button size="sm" variant="tertiary" asChild>
                            <Link
                              to="https://spill.notion.site/Give-your-employees-access-to-more-12349f92fc6b80ae911ec2ddae0861d8"
                              target="_blank"
                            >
                              Add to your plan
                            </Link>
                          </Button>
                        ) : (
                          <P muted>
                            Ask a Therapist is currently disabled. Access the{" "}
                            <Link to="/admin/therapy/settings">settings</Link>{" "}
                            page to enable it
                          </P>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              {inActiveTrial && (
                <div className="p-4 flex flex-1 flex-col justify-between">
                  <div className="flex items-center justify-between gap-2 font-inter font-semibold">
                    {inActiveTrial && <H4>Sessions in trial</H4>}
                    <GiftIcon className="size-6" />
                  </div>
                  <H1>{sessionPackValue}</H1>
                </div>
              )}
            </div>
          </motion.div>
          <div className="flex flex-col items-center gap-1 lg:flex-row justify-between">
            <P muted size="xs">
              Data for{" "}
              {inActiveTrial
                ? `${trialStartFormated} - ${trialEndFormated}`
                : `${lastMonthStartFormated} - ${lastMonthEndFormated}`}
            </P>
            <Button variant="tertiary" asChild size="sm">
              {isStarterPlan ? (
                <Link to="/admin/billing">Upgrade to see full engagement</Link>
              ) : (
                <Link to="/admin/dashboard/engagement">
                  See full engagement
                </Link>
              )}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  if (isOnboarding && steps) {
    return (
      <>
        <Helmet title="Welcome | Spill" />
        {hasCompletedAdminOnboarding === false && isStarterPlan && (
          <OnboardingModal />
        )}
        <div className="flex flex-col space-y-10">
          <H1>Welcome to Spill</H1>
          <div className="flex w-full flex-col gap-16 align-top lg:flex-row">
            {!isStarterPlan ? (
              <SummaryGrid />
            ) : (
              <div className="flex flex-1 flex-col gap-6">
                <H4 sectionHeader>How Spill works</H4>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: "0",
                  }}
                >
                  <iframe
                    className=""
                    onClick={() => handleVideoTracking()}
                    src="https://www.loom.com/embed/750b42830f774de3a06e67a80f2f6c69?sid=9e17a5a2-32fb-42c8-bf66-07f993f851e5?hide_share=true&hideEmbedTopBar=true&hide_title=true&hide_owner=true"
                    title="How Spill works"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url(${Logo})`,
                      backgroundPosition: "center",
                      backgroundSize: "20% 45%",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </div>
              </div>
            )}

            <div className="flex flex-1 flex-col gap-6">
              <H4 sectionHeader>Onboarding steps</H4>
              <OnboardingSteps
                steps={steps}
                subscriptionPlan={subscriptionPlan as OnboardingEnabledPlans}
              />
            </div>
          </div>
          <Separator />
          <ProductUpdates productUpdates={data} />
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet title="Dashboard | Spill" />
      <div className="flex flex-col space-y-10">
        <H1>Dashboard</H1>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
          <div className="lg:col-span-7">
            <SummaryGrid />
          </div>
          <div className="lg:col-span-5">
            <RecommendedActions />
          </div>
        </div>
        <Separator />
        <ProductUpdates productUpdates={data} />
      </div>
    </>
  )
}
