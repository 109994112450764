import { H1, TextArea, P, Button, H2, Checkbox } from "@spillchat/puddles"
import { useFormContext } from "react-hook-form"
import { Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { StarIcon } from "@heroicons/react/24/solid"

import { useUser } from "common/context/userContext"
import {
  FeedbackFormReviewGetReviewQuery,
  FeedbackFormReviewGetReviewQueryVariables,
} from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"

import { FeedbackFormFields, FeedbackFormStepLabels } from "../feedback.types"
import { queries } from "../feedback.queries"

type FeedbackFormReviewProps = {
  stepNumber: number
  onSetStep: (step: FeedbackFormStepLabels) => void
}

export const FeedbackFormReview = ({
  stepNumber,
  onSetStep: setStep,
}: FeedbackFormReviewProps): JSX.Element => {
  const user = useUser()
  const { track } = useAnalytics()
  const formMethods = useFormContext<FeedbackFormFields>()
  formMethods.watch([
    "companyPublicReview.message",
    "companyPublicReview.score",
  ])

  const { data } = useQuery<
    FeedbackFormReviewGetReviewQuery,
    FeedbackFormReviewGetReviewQueryVariables
  >(queries.getReview, {
    variables: { companyId: user.company.id ?? "" },
    onCompleted(data) {
      const { message, score } = data?.userCompanyPublicReview ?? {}
      if (message !== undefined && score !== undefined) {
        formMethods.setValue("companyPublicReview.message", message)
        formMethods.setValue("companyPublicReview.score", score)
      }
    },
  })

  const reviewExists = data?.userCompanyPublicReview !== null

  const updateSelected = (
    nameOfScale: string,
    newSelectedValue: number | string
  ): void => {
    formMethods.setValue(
      nameOfScale as keyof FeedbackFormFields,
      newSelectedValue
    )
  }

  const canSubmit = [
    formMethods.getValues("companyPublicReview.message"),
    formMethods.getValues("companyPublicReview.score"),
  ].every(value => value !== undefined && value !== "")

  return (
    <>
      <>
        <div className="flex flex-col gap-3">
          <H1>We're so glad to hear&nbsp;it</H1>
          <P>
            We'd love it if you could share a few words about how your
            experience has impacted you and why you'd recommend Spill. We'll
            only share what you say with people you opt in to sharing with.
          </P>
        </div>
        <div className="flex flex-col gap-3">
          <P size="xs" muted>
            Step {stepNumber} of 3
          </P>
          <H2>Give a public&nbsp;review</H2>
        </div>
        <div className="flex flex-col gap-3">
          <TextArea
            id="companyPublicReview.message"
            className={`h-24 w-full max-w-full border-0 ${
              !reviewExists ? "" : "pointer-events-none select-none"
            }`}
            readOnly={reviewExists}
            placeholder={data?.userCompanyPublicReview?.message ?? ""}
            label={{
              children: (
                <div className="flex flex-col gap-1">
                  {!reviewExists ? (
                    <>
                      <P weight="medium">
                        Give Spill a rating to put on the careers page of your
                        company website
                      </P>
                      <P>
                        This will let your company tell potential candidates how
                        they look after the mental health of their staff. Your
                        rating will remain anonymous.{" "}
                      </P>
                    </>
                  ) : (
                    <P>
                      You've previously left this review - if you'd like to
                      change it, please email therapy@spill.chat and we'll edit
                      it for you. This helps your company tell potential
                      candidates how they look after the mental health of
                      their&nbsp;staff.
                    </P>
                  )}
                </div>
              ),
            }}
            onChange={e =>
              updateSelected("companyPublicReview.message", e.target.value)
            }
          />
          <div className="flex items-center justify-between gap-4">
            <div className="grow">
              <P weight="medium">{user.displayName}</P>
            </div>
            {!reviewExists && (
              <div>
                <Checkbox
                  id="includeName"
                  value={
                    +formMethods.getValues("companyPublicReview.includeName")
                  }
                  onCheckedChange={value =>
                    updateSelected("companyPublicReview.includeName", +value)
                  }
                  label={{
                    children: <>Include my name</>,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <P weight="medium">
            {reviewExists
              ? `You've given Spill ${data?.userCompanyPublicReview?.score} stars:`
              : "How many stars would you give Spill?"}
          </P>
          <div className="flex items-center gap-2">
            {Array.from({ length: 5 }, (_v, i) => {
              return (
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      updateSelected("companyPublicReview.score", i + 1)
                    }
                  }}
                  className={
                    !reviewExists
                      ? "cursor-pointer"
                      : "pointer-events-none select-none"
                  }
                  key={i}
                  onClick={() =>
                    updateSelected("companyPublicReview.score", i + 1)
                  }
                >
                  <StarIcon
                    className={
                      i + 1 <=
                      formMethods.getValues("companyPublicReview.score")
                        ? "text-teal-400 transition size-8"
                        : "text-grey-200 transition size-8"
                    }
                  />
                </span>
              )
            })}
          </div>
        </div>
        <div className="flex gap-4 items-center justify-between">
          <div className="flex gap-4 items-center">
            {!reviewExists ? (
              <>
                <Button type="submit" variant="primary" disabled={!canSubmit}>
                  Submit
                </Button>
                <Button asChild variant="secondary">
                  <Link to="/" onClick={() => track("Cancelled Review Step")}>
                    Cancel
                  </Link>
                </Button>
              </>
            ) : (
              <Button onClick={() => setStep("success")}>Finish</Button>
            )}
          </div>
        </div>
      </>
    </>
  )
}
