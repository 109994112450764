import { ChevronLeftIcon } from "@heroicons/react/24/outline"
import { Button, P } from "@spillchat/puddles"
import { ReactNode } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export function BackButton({ to }: { to?: string }): ReactNode {
  const location = useLocation()
  const navigate = useNavigate()

  const handleNavigate = () => {
    if (to != null) {
      return navigate(to)
    }

    if (location.key === "default") {
      return navigate("/admin")
    }

    return navigate(-1)
  }

  return (
    <Button variant="tertiary" asChild onClick={() => handleNavigate()}>
      <span className="cursor-pointer no-underline">
        <div className="flex gap-1 items-center">
          <ChevronLeftIcon className="size-4" />
          <P weight="medium">Back</P>
        </div>
      </span>
    </Button>
  )
}
