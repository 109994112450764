import { useState } from "react"
import cn from "classnames"

import { PageHeader } from "common/components/PageHeader"

import { ResourceCard } from "../components/ResourceCard"
import { RESOURCES } from "../constants/resources"
import { Category, Level } from "../types"

import type { MouseEvent, ReactNode } from "react"

export function BooksAndVideos(): JSX.Element {
  const [categoryFilter, setCategoryFilter] = useState<Category>(Category.Self)
  const availableCategories = Object.values(Category)

  const handleCategoryFilterClick = (event: MouseEvent<HTMLButtonElement>) => {
    const selectedCategory = event.currentTarget.value as unknown as Category
    setCategoryFilter(selectedCategory)
  }

  const filteredResources = RESOURCES.filter(resource => {
    return categoryFilter === null || resource.category === categoryFilter
  })

  const starterResources = filteredResources.filter(resource => {
    return resource.level === Level.Starter
  })

  const advancedResources = filteredResources.filter(resource => {
    return resource.level === Level.Advanced
  })

  return (
    <main className="p-0">
      <PageHeader
        title="Books and videos"
        subtitle="Some handpicked recommendations from our therapists and other passionate people at Spill HQ."
      />

      <nav className="flex flex-col sm:flex-row gap-sm">
        {availableCategories.map(categoryName => (
          <button
            key={categoryName}
            className={cn("btn-gray", {
              "btn-secondary": categoryName === categoryFilter,
            })}
            value={categoryName}
            onClick={handleCategoryFilterClick}
          >
            {categoryName}
          </button>
        ))}
      </nav>

      <section className="flex flex-col gap-md">
        <h3>🚀 Starter</h3>
        <Grid>
          {starterResources.map(resource => (
            <ResourceCard
              key={`${resource.title}-${resource.mediaType}`}
              resource={resource}
            />
          ))}
        </Grid>
      </section>

      <section className="flex flex-col gap-md">
        <h3>🌟 Advanced</h3>
        <Grid>
          {advancedResources.map(resource => (
            <ResourceCard
              key={`${resource.title}-${resource.mediaType}`}
              resource={resource}
            />
          ))}
        </Grid>
      </section>
    </main>
  )
}

function Grid({ children }: { children: ReactNode }): JSX.Element {
  return (
    <div className="columns-1 sm:columns-2 md:columns-1 lg:columns-2 xl:columns-3 space-y-md gap-md">
      {children}
    </div>
  )
}
