import { gql } from "@apollo/client"

export const USER_LIST_FIELDS = gql`
  fragment UserListFields on User {
    id
    primaryEmail
    company {
      users {
        id
        fullName
        platformUsers {
          email
        }
        accountStatus
      }
    }
  }
`
