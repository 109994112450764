import { gql } from "@apollo/client"

export const fragments = {
  getUser: gql`
    fragment ProfileInfoGetUser on User {
      id
      firstName
      lastName
      displayName
      therapyProfile {
        firstName
        lastName
        dateOfBirth
        pronouns
        phoneNumber
      }
    }
  `,
}
