import { Indicator } from "@spillchat/puddles"
import { FunctionComponent } from "react"

type AdviceLibraryTagsProps = {
  tags: string[]
}

export const AdviceLibraryTags: FunctionComponent<AdviceLibraryTagsProps> = ({
  tags,
}) => {
  return (
    <div className="flex flex-wrap gap-2 items-baseline">
      {tags.slice(0, 4).map(tag => {
        return (
          <Indicator key={tag} variant="info">
            {tag}
          </Indicator>
        )
      })}
    </div>
  )
}
