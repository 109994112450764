import { Widget } from "@typeform/embed-react"

import { useAuth } from "common/context/authContext"

export const TrendsFeedbackWidget: React.FunctionComponent = () => {
  const { user } = useAuth()

  return (
    <div className="gap-lg w-full">
      <header>
        <h4>Feedback</h4>
      </header>
      <Widget
        hideHeaders
        hideFooter
        height={300}
        id="M362puWD"
        opacity={0}
        {...(user ? { hidden: { user_id: user.id } } : {})}
      />
    </div>
  )
}
