import { gql, useLazyQuery } from "@apollo/client"
import { FunctionComponent, PropsWithChildren, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { parseISO } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { Button, P } from "@spillchat/puddles"
import { Link } from "react-router-dom"
import {
  CalendarIcon,
  ClockIcon,
  ComputerDesktopIcon,
  Square2StackIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import { cn } from "common/helpers/cn"
import { createAppointmentCalendarURLs } from "common/helpers/createAppointmentCalendarURLs"
import { useElementBreakpoint } from "common/hooks/useElementBreakpoint"
import { FormValues as AppointmentBookingPageFormValues } from "features/therapy/pages/AppointmentBookingPage"
import { BookableAppointmentType } from "types/graphql"
import {
  AppointmentConfirmationAppointmentQuery,
  AppointmentConfirmationAppointmentQueryVariables,
} from "types/graphql"
import { useApp } from "common/context/appContext"
import { HeroIcon } from "common/helpers/heroIcons"

type FormValues = Pick<
  AppointmentBookingPageFormValues,
  "appointment" | "appointmentType" | "timeZone" | "userInfo"
>

const queries = {
  appointment: gql`
    query AppointmentConfirmationAppointment($appointmentId: ID!) {
      appointment(id: $appointmentId) {
        id
        roomUrl
        startsAt
        title
        counsellor {
          id
          firstName
          videoCallUrl
        }
      }
    }
  `,
}

export const AppointmentConfirmation: FunctionComponent = () => {
  const [ref, breakpoints] = useElementBreakpoint<
    HTMLDivElement,
    400 | 600 | 800
  >([400, 600, 800])
  const { appTimeZone } = useApp()

  const { watch } = useFormContext<FormValues>()
  const { appointment, appointmentType, timeZone, userInfo } = watch()
  const [getAppointmentRoomUrl, { data, loading }] = useLazyQuery<
    AppointmentConfirmationAppointmentQuery,
    AppointmentConfirmationAppointmentQueryVariables
  >(queries.appointment)

  useEffect(() => {
    if (appointment?.id !== undefined)
      void getAppointmentRoomUrl({
        variables: { appointmentId: appointment?.id },
      })
  }, [appointment])

  if (!appointment || loading || !userInfo) return null

  const startTimeDate = parseISO(appointment.startsAt)

  const halfSessions = [
    BookableAppointmentType.SERIES_CONSULTATION_SESSION,
    BookableAppointmentType.COURSE_SESSION_25_MINUTES,
    BookableAppointmentType.PRIVATE_COURSE_SESSION_25_MINUTES,
    BookableAppointmentType.BEREAVEMENT_CONSULTATION,
    BookableAppointmentType.PARENTHOOD_CONSULTATION,
  ]
  const duration =
    appointmentType != null && halfSessions.includes(appointmentType)
      ? "25 mins"
      : "50 mins"

  const { googleCalendarURL, iCalendarURL } = data?.appointment
    ? createAppointmentCalendarURLs(data.appointment, appTimeZone)
    : { googleCalendarURL: null, iCalendarURL: null }

  return (
    <div className="flex flex-col gap-8 items-center text-center" ref={ref}>
      <Item icon={UsersIcon} iconSize={64}>
        Your therapist will be {appointment?.counsellor.firstName}
      </Item>
      <hr className="border-grey-400 w-full" />
      <ul
        className={cn("gap-8 grid w-full", {
          "grid-cols-2": breakpoints[0],
          "grid-cols-4": breakpoints[600],
        })}
      >
        <Item icon={Square2StackIcon}>{data?.appointment?.title}</Item>
        <Item icon={ComputerDesktopIcon}>{duration}, remote</Item>
        <Item icon={CalendarIcon}>
          {formatInTimeZone(startTimeDate, timeZone, "eee d MMM")}
        </Item>
        <Item icon={ClockIcon}>
          {formatInTimeZone(startTimeDate, timeZone, "h:mmaaa")} ({appTimeZone})
        </Item>
      </ul>
      <hr className="border-grey-400 w-full" />
      <P> We’ll email you the link to your session 2 hours in advance.</P>
      {userInfo.email !== undefined && (
        <P>
          We’ve also emailed you this session confirmation at{" "}
          <strong>{userInfo.email}</strong>.
        </P>
      )}
      <div className="flex flex-wrap gap-4 justify-center">
        {googleCalendarURL != null && iCalendarURL != null && (
          <>
            <Button variant="secondary">
              <Link to={googleCalendarURL} rel="noreferrer" target="_blank">
                Add to Google
              </Link>
            </Button>
            <Button variant="secondary">
              <a href={iCalendarURL} download="spill_session.ics">
                Add to iCal/Outlook
              </a>
            </Button>
          </>
        )}
        <Button asChild>
          <a href="/therapy/sessions">Done</a>
        </Button>
      </div>
    </div>
  )
}

interface ItemProps extends PropsWithChildren {
  icon: HeroIcon
  iconSize?: number
}

const Item: FunctionComponent<ItemProps> = ({ children, icon: Icon }) => (
  <div className="flex flex-col gap-4 items-center">
    <Icon className="size-12" />
    <P weight="medium">{children}</P>
  </div>
)
