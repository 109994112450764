import { Button, H2, H4, Modal, P } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { useMutation } from "@apollo/client"

import {
  OnboardingModalCompleteAdminOnboardingMutation,
  OnboardingModalCompleteAdminOnboardingMutationVariables,
} from "types/graphql"
import { useModalCookieBannerAwareness } from "common/hooks/useModalCookieBannerAwareness"

import Step1 from "./step-1.svg?react"
import Step2 from "./step-2.svg?react"
import Step3 from "./step-3.svg?react"
import { mutations } from "./OnboardingModal.mutations"

export const OnboardingModal: FunctionComponent = () => {
  useModalCookieBannerAwareness()

  const [step, setStep] = useState(0)

  const stepState = [
    {
      title: "How will they access support?",
      text: "Any employee you invite to therapy gets a personal link to a private and secure web portal. You can send them the link yourself or we can do it for you.",
    },
    {
      title: "What support will they be offered?",
      text: "Each employee can have a series of sessions with dedicated support from a qualified counsellor as standard. They can speak with their therapist over phone or video call.",
    },
    {
      title: "Who delivers the counselling?",
      text: "All Spill counsellors are registered with the BACP (or equivalent), have over 200+ hours of clinical practice and go through our rigorous 8-step hiring process.",
    },
  ]

  const [completeAdminOnboarding] = useMutation<
    OnboardingModalCompleteAdminOnboardingMutation,
    OnboardingModalCompleteAdminOnboardingMutationVariables
  >(mutations.completeAdminOnboarding, {
    refetchQueries: "all",
  })

  const handleComplete = async () => {
    setStep(-1)
    await completeAdminOnboarding()
  }

  if (step == -1) {
    return null
  }

  return (
    <Modal.Root defaultOpen={true}>
      <Modal.Content
        className="md:min-w-[675px]"
        hideDefaultClose={true}
        onInteractOutside={e => e.preventDefault()}
      >
        <div className="flex flex-col items-center gap-6 p-12">
          <H2>How Spill works</H2>
          <div className="grid lg:grid-cols-2 gap-4 min-h-56 items-center">
            <div className="flex flex-col gap-2">
              <span>{step + 1}/3</span>
              <H4>{stepState[step]?.title}</H4>
              <P weight="medium">{stepState[step]?.text}</P>
            </div>
            <div>
              {step === 0 && <Step1 />}
              {step === 1 && <Step2 />}
              {step === 2 && <Step3 />}
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div>
              {step > 0 && (
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => setStep(step - 1)}
                >
                  Back
                </Button>
              )}
            </div>
            <div>
              {step < 2 ? (
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setStep(step + 1)}
                >
                  Next
                </Button>
              ) : (
                <Button
                  size="sm"
                  variant="primary"
                  onClick={async () => await handleComplete()}
                >
                  Finish
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  )
}
