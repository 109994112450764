import { Accordion, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"

const accordionItems = [
  {
    trigger: "Who are the counsellors on Spill?",
    content: (
      <P>
        We&apos;re proud of our 5-stage hiring process which selects for only
        the most competent counsellors. It takes a lot of work (we only accept
        13% of counsellors who apply) but means that you will always talk to
        someone who delivers truly excellent therapy. Our counsellors also cover
        80+ areas of focus and 10+ languages so if you&apos;re looking for
        something specific, you can select for it at the booking process or drop
        us an email at therapy@spill.chat.
      </P>
    ),
  },
  {
    trigger: "Is Spill confidential?",
    content: (
      <>
        <P>
          Spill sessions are entirely confidential. That means we do not tell
          anyone from your company anything you share with your counsellor or
          dates and times of when you’re meeting.
        </P>
        <P>
          Because we bill on a pay-as-you-go basis, if you do request sessions
          from your company they will see it on their invoice. However, unless
          you are the only person to request (and include your name) or the only
          person on your company’s Spill account, they will not know who had the
          session.{" "}
        </P>
      </>
    ),
  },
  {
    trigger: "How quickly can I speak to someone?",
    content: (
      <P>
        With same-day availability, you can always speak to someone as soon as
        you need to.
      </P>
    ),
  },
  {
    trigger: "How is therapy on Spill different?",
    content: (
      <>
        <P>
          Spill provides humanistic therapy which (at a high level) works by the
          therapist first trying to understand you without judgement, prejudice,
          or telling you what to do. As the therapy progresses, your therapist
          may begin to challenge you more and invite you to question your
          assumptions.
        </P>
        <P>
          This type of therapy lends itself very well to almost all mental
          health areas and allows Spill to work with people who are anywhere
          along the mental health spectrum. Essentially, the vast majority of
          people benefit from the basic principle of another human first taking
          the time to understand them before offering their input. Over time, we
          have seen that offering humanistic therapy greatly reduces the chance
          that someone who is struggling will disengage from the process.
        </P>
        <P>
          We could fill hundreds of toggle boxes with how this works but that
          wouldn’t be very user friendly, so if you want to read more about
          Spill therapy we’ve written it up{" "}
          <a
            href="https://spill.notion.site/How-Spill-s-mental-health-support-works-8ae646a05bff4a368578725cfd807bd6"
            target="_blank"
            rel="noreferrer"
            className="underline underline-offset-2"
          >
            here
          </a>{" "}
          instead.
        </P>
      </>
    ),
  },
  {
    trigger: "Should I do therapy?",
    content: (
      <P>
        55% of people feel less-than-good emotionally during the course of a
        normal year. Therapy is proven to help those people get back to their
        best. Still, starting therapy is a big step so we asked people who’ve
        done therapy what they’d say to those hesitant to start.{" "}
        <a
          href="https://spill.notion.site/Should-I-do-therapy-0c870fe2b2bc4e238160ba3ae55b5eba"
          target="_blank"
          rel="noreferrer"
          className="underline underline-offset-2"
        >
          It’s worth a read.
        </a>{" "}
      </P>
    ),
  },
]

export const FAQ: FunctionComponent = () => {
  return <Accordion items={accordionItems} />
}
