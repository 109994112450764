import { gql } from "@apollo/client"

export const queries = {
  getRequest: gql`
    query AdminTherapySpecialisedSupportRequest(
      $requestId: ID!
      $packageId: ID!
    ) {
      getTherapyExtension(id: $requestId) {
        id
        expiryDate
        createdAt
        isAnonymous
        noteFromUser
        numberOfSessions
        status
        user {
          id
          displayName
        }
      }
      getCompanyPackageSettingById(companyPackageSettingId: $packageId) {
        id
        name
        icon
        state
        iconUrl
        numberMonthsToUse
        numberSessions
        forAllEmployees
        visibleToAllEmployees
        userFacingDescription
      }
    }
  `,
}
