import { FunctionComponent, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet-async"
import { Button, CopyField, H1, H4, P, Tooltip } from "@spillchat/puddles"
import { LockClosedIcon } from "@heroicons/react/24/outline"
import { toast } from "sonner"
import { LinkIcon } from "@heroicons/react/24/outline"

import { config } from "config"
import SampleLineChartTeamMood12M from "common/assets/images/sampleLineChartTeamMood12M.svg?react"
import { CreateMeetingDialog } from "features/pulse/components/CreateMeetingDialog"
import {
  CheckInsGetPulseMeetingsQuery as QueryData,
  CheckInsGetPulseMeetingsQueryVariables as QueryVars,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useAuth } from "common/context/authContext"
import { ExternalLink } from "common/components/ExternalLink"

export const fragments = {
  queryFields: gql`
    fragment CheckInsQueryFields on Query {
      company {
        id
        pulseMeetings {
          urlId
          createdBy {
            id
            displayName
          }
          name
          redirectUrl
        }
      }
    }
  `,
}

export const queries = {
  getPulseMeetings: gql`
    query CheckInsGetPulseMeetings {
      ...CheckInsQueryFields
    }
    ${fragments.queryFields}
  `,
}

interface CheckInsProps {
  hideTitle?: boolean
}

export const CheckIns: FunctionComponent<CheckInsProps> = props => {
  const { user } = useAuth()
  const [showCreateMeetingDialog, setShowCreateMeetingDialog] = useState(false)

  const { data, loading } = useQuery<QueryData, QueryVars>(
    queries.getPulseMeetings
  )

  const isPulseEnabled = user?.featuresAndSettings.pulse.value ?? false
  const pulseMeetings = data?.company.pulseMeetings ?? []

  const webAppBaseUrl = new URL(config.spill.webApp.baseUrl)

  if (loading)
    return (
      <div className="flex w-full h-full justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )

  return (
    <>
      <Helmet title="Set up Team Check-ins | Spill" />

      <CreateMeetingDialog
        isOpen={showCreateMeetingDialog}
        onClose={() => setShowCreateMeetingDialog(false)}
        onCreateMeetingRefetchQueries={[queries.getPulseMeetings]}
      />

      <div className="flex flex-col py-8 space-y-8">
        {props.hideTitle !== true && <H1>Set up Team Check-ins</H1>}

        <div className="space-y-8">
          <header className="space-y-4">
            <H4 sectionHeader>Team Check-ins</H4>
            <P>
              Team check-ins enable us to know your team's mood each week and,
              based on this information, get help to those who need it.
            </P>
          </header>

          <div className="flex flex-row items-center gap-4">
            <Button
              onClick={() => setShowCreateMeetingDialog(true)}
              size="sm"
              disabled={!isPulseEnabled}
            >
              Add new Check-in
            </Button>
            {!isPulseEnabled && (
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger>
                    <LockClosedIcon className="size-4 ml-2" />
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                    <P>This feature is only available to our Team plan.</P>
                    <br />
                    <P>
                      Discover how{" "}
                      <ExternalLink to="https://spill.notion.site/How-do-Spill-check-ins-work-50e396d98d5c4509b8df033fa68c9a24">
                        Check-ins
                      </ExternalLink>{" "}
                      can help your team be proactive about their mental health.
                    </P>
                    <br />
                  </Tooltip.Content>
                </Tooltip.Root>
              </Tooltip.Provider>
            )}
            <Button asChild variant="tertiary" size="sm">
              <a
                href="https://spill.notion.site/All-about-Check-ins-55fe4c0b2c874fbdbbaab3a8c43e6911"
                rel="noreferrer"
                target="_blank"
              >
                Learn more or watch the demo here
              </a>
            </Button>
          </div>

          {pulseMeetings.length > 0 ? (
            // List of pulse meetings
            <ul className="flex flex-col">
              <li className="grid grid-cols-2 space-x-2">
                <P weight="medium">Title</P>
                <P weight="medium">Meeting link</P>
              </li>
              {pulseMeetings.map(pulseMeeting => (
                <li
                  key={pulseMeeting.urlId}
                  className="border-b last:border-b-0 grid grid-cols-2 items-center last:pb-0 py-3 space-x-2 text-sm"
                >
                  <P>
                    <span className="truncate">
                      {pulseMeeting.name ?? "Unnamed meeting"}
                    </span>
                  </P>

                  <CopyField
                    disabled={!isPulseEnabled}
                    copyValue={`${webAppBaseUrl.host}/pulse/${pulseMeeting.urlId}`}
                    onCopied={() => {
                      toast.success("Link copied to clipboard")
                    }}
                    icon={<LinkIcon />}
                  />
                </li>
              ))}
            </ul>
          ) : (
            // Empty state
            <SampleLineChartTeamMood12M width="100%" />
          )}
        </div>
      </div>
    </>
  )
}
