import { ComponentPropsWithoutRef, ReactElement } from "react"
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { groupBy, isEmpty } from "lodash"
import {
  useFormContext,
  type FieldValues,
  type Path,
  type RegisterOptions,
  type UseFormRegister,
} from "react-hook-form"

import { cn } from "common/helpers/cn"
import { Label } from "common/components/FormElements/Label"
import { ErrorText } from "common/components/FormElements/ErrorText"
import { Tooltip, TooltipProps } from "common/components/Tooltip"

export interface SelectProps<FormValues extends FieldValues>
  extends Omit<ComponentPropsWithoutRef<"select">, "size"> {
  /**
   * Class names to apply to wrapping element.
   */
  classNameWrapper?: ComponentPropsWithoutRef<"div">["className"]
  /**
   * Id for the select input.
   */
  id?: string
  /**
   * Optional label for the input.
   */
  label?: ComponentPropsWithoutRef<"label">["children"]
  /**
   * Label component props primarily used for tweaking label styling contextually.
   */
  labelProps?: Omit<ComponentPropsWithoutRef<typeof Label>, "children">
  /**
   * Name of the field that we're adding to the form. This is used to create the
   * react-hook-form register function.
   */
  name: Path<FormValues>
  /**
   * Options that we want to pass to the select input.
   */
  options: (Omit<ComponentPropsWithoutRef<"option">, "value"> & {
    group?: string
    value: string
  })[]
  /**
   * We use the register from the useForm hook to register the input with react-hook-form.
   * The key name should match the id of the input. This will be passed from the parent component.
   *
   */
  register?: UseFormRegister<FormValues>
  /**
   * Allows us to provide a set of additional options and validation rules to the input.
   */
  registerOptions?: RegisterOptions<FormValues>
  showClearButton?: boolean
  size?: "sm" | "md" | "lg"
  tooltip?: TooltipProps["content"]
}

export const Select = <FormValues extends FieldValues>({
  className,
  classNameWrapper,
  disabled,
  id,
  label,
  labelProps = {},
  name,
  options,
  register,
  registerOptions,
  showClearButton = false,
  size = "md",
  tooltip,
  ...rest
}: SelectProps<FormValues>): ReactElement<FormValues> => {
  const { setValue, watch } = useFormContext<FormValues>()
  const groupedOptions = groupBy(options, "group")

  const value = watch(name)
  const hasValueAndClearButton = showClearButton && !isEmpty(value)

  return (
    <div className={cn("flex flex-col gap-2", classNameWrapper)}>
      {label !== undefined && (
        <Label htmlFor={id ?? name} {...labelProps}>
          {label}
        </Label>
      )}
      <Tooltip content={tooltip} followMouse>
        <div
          className={cn("relative rounded-md", {
            "border border-grey-200":
              disabled !== true && !hasValueAndClearButton,
            "cursor-not-allowed": disabled,
            "h-8 text-sm": size === "sm",
            "h-10": size === "md",
            "h-12": size === "lg",
          })}
        >
          <select
            className={cn(
              "appearance-none cursor-pointer h-full pl-2 ring-0 rounded-[inherit] transition-colors w-full disabled:bg-grey-100 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-800",
              {
                "bg-mono-white": !hasValueAndClearButton,
                "bg-teal-100": hasValueAndClearButton,
                "pr-8": size === "sm",
                "pr-10": size === "md",
                "pr-12": size === "lg",
              },
              className
            )}
            disabled={disabled}
            id={id ?? name}
            onKeyDown={e => {
              switch (e.code) {
                case "ArrowDown":
                case "ArrowUp":
                  if (hasValueAndClearButton) e.preventDefault()
                  break
                case "Backspace":
                case "Escape":
                case "Space":
                  if (hasValueAndClearButton) {
                    e.preventDefault()
                    // @ts-expect-error Empty string is invalid but we set it anyway
                    setValue(name, "")
                  }
                  break
              }
            }}
            onMouseDown={e => {
              if (hasValueAndClearButton) {
                e.preventDefault()
                // @ts-expect-error Empty string is invalid but we set it anyway
                setValue(name, "")
              }
            }}
            {...(register && register(name, registerOptions))}
            {...rest}
          >
            {Object.entries(groupedOptions).map(([group, options]) => {
              const optionElements = options.map(option => (
                <option key={option.key} {...option}>
                  {option.label}
                </option>
              ))

              if (group === "undefined") return optionElements

              return (
                <optgroup key={group} label={group}>
                  {optionElements}
                </optgroup>
              )
            })}
          </select>
          {hasValueAndClearButton ? (
            <XMarkIcon
              className={cn(
                "-translate-y-1/2 absolute pointer-events-none top-1/2 w-4 h-4",
                {
                  "right-2": size === "sm",
                  "right-3": size === "md",
                  "right-4": size === "lg",
                }
              )}
            />
          ) : (
            <ChevronDownIcon
              className={cn(
                "-translate-y-1/2 absolute pointer-events-none top-1/2 w-4 h-4",
                {
                  "right-2": size === "sm",
                  "right-3": size === "md",
                  "right-4": size === "lg",
                }
              )}
            />
          )}
        </div>
      </Tooltip>
      <ErrorText name={name} />
    </div>
  )
}
