import { captureException } from "@sentry/react"
import { useEffect, useState } from "react"
import { useLocation, useSearchParams, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { toast } from "sonner"
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-auth-react/recipe/thirdparty"
import { STGeneralError } from "supertokens-website/utils/error"
import { Helmet } from "react-helmet-async"
import { Button, Form, Input } from "@spillchat/puddles"

import { SIGN_IN_OUTCOME } from "common/constants"
import { useAnalytics } from "common/context/analyticsContext"
import { useAuth } from "common/context/authContext"
import { OTPInputPage } from "common/components/OTPInputPage"
import { gatewayFetch } from "common/helpers/gatewayFetch"
import { Link } from "common/components/Link"
import { LogoWordmark } from "common/components/logo/LogoWordmark"

import type { FunctionComponent } from "react"

interface FieldValues {
  email: string
}

export const SignInPage: FunctionComponent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { track } = useAnalytics()
  const { createOTP, hasInitialOTPBeenSent, isUserLoading, signInOutcome } =
    useAuth()

  const onSuccessRedirectURL = searchParams.get("redirect") ?? "/"

  const form = useForm<FieldValues>({
    defaultValues: {
      email: searchParams.get("email") ?? "",
    },
    mode: "onChange",
  })

  const [showOTPInputPage, setShowOTPInputPage] = useState(false)
  const [email, setEmail] = useState<string>()

  useEffect(() => {
    if (isUserLoading) return
    void gatewayFetch({ method: "POST", path: "/auth/signout" })

    track("User Shown Login Screen", {
      Path: location.pathname,
      Search: location.search,
    })
    // Track same event as above but outside of Mixpanel
    void gatewayFetch({
      method: "POST",
      path: "/metrics/login-form",
      body: { path: location.pathname, search: location.search },
    })

    void hasInitialOTPBeenSent().then(res => {
      if (res) setShowOTPInputPage(true)
    })
  }, [isUserLoading])

  if (showOTPInputPage && email !== undefined) {
    return (
      <OTPInputPage
        email={email}
        onCancel={() => setShowOTPInputPage(false)}
        onSuccess={() => navigate(onSuccessRedirectURL)}
      />
    )
  }

  const onSubmit = async (values: { email: string }) => {
    try {
      track("User enters email to log in")
      // send OTP
      setEmail(values.email)
      await createOTP({ email: values.email })
      setShowOTPInputPage(true)
    } catch (err) {
      if (STGeneralError.isThisError(err)) {
        // this may be a custom error message sent from gateway,
        // or if the email is not valid.
      }
    }
  }

  const onSignIn = async (thirdPartyId: string) => {
    const redirectUri = new URL("/auth/callback/post", window.location.origin)
    localStorage.setItem("postLoginRedirect", onSuccessRedirectURL)
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId,
        frontendRedirectURI: redirectUri.toString(),
      })

      window.location.assign(authUrl)
    } catch (err: unknown) {
      if (
        (err as { isSuperTokensGeneralError?: boolean })
          ?.isSuperTokensGeneralError === true
      ) {
        // this may be a custom error message sent from the API by you.
        toast.error((err as { message: string }).message)
      } else {
        captureException(err)
        toast.error("Oops! Something went wrong.")
      }
    }
  }

  return (
    <>
      <Helmet title="Sign in | Spill" />
      <div className="flex items-center justify-center col-span-5 lg:col-span-3 min-h-screen relative">
        <div className="fixed top-8 left-8 flex gap-2 items-center">
          <LogoWordmark />
        </div>
        <div className="box backdrop-blur-3xl bg-mono-white/50 flex flex-col items-center gap-6 max-w-sm w-full p-6 relative z-10 rounded-xl">
          <div className="flex flex-col items-center gap-4">
            <h1 className="text-3xl">Sign in to Spill</h1>
          </div>

          <div className="flex flex-col gap-2 w-full">
            <Button
              asChild
              variant="secondary"
              onClick={async () => await onSignIn("slack")}
            >
              <button className="!w-full">Sign in with Slack</button>
            </Button>
            <Button
              asChild
              variant="secondary"
              onClick={async () => await onSignIn("ms_teams")}
            >
              <button className="!w-full">Sign in with MS Teams</button>
            </Button>

            <div className="flex gap-2 items-center py-2">
              <div className="flex-1 h-px bg-mono-black/25" />
              <p className="text-black/50 select-none text-xs">
                for those with email access
              </p>
              <div className="flex-1 h-px bg-mono-black/25" />
            </div>

            <Form.Root {...form}>
              <form
                className="flex flex-col items-center gap-2"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <Form.Field
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <Form.Item className="w-full">
                      <Form.Control>
                        <Input
                          placeholder="mail@example.com"
                          {...field}
                          type="email"
                          label={{ children: "Email address" }}
                        />
                      </Form.Control>
                      <Form.Message />
                    </Form.Item>
                  )}
                />
                <div className="flex flex-col items-center gap-4 w-full">
                  <Button asChild type="submit" variant="primary">
                    <button type="submit" className="!w-full">
                      Sign in with email
                    </button>
                  </Button>
                  <Button asChild variant="tertiary">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://spill.notion.site/Having-trouble-logging-in-89edca1ea7f9451386fdfb389dbcf744"
                    >
                      Having trouble logging in?
                    </a>
                  </Button>
                </div>
              </form>
            </Form.Root>
          </div>

          {(signInOutcome === SIGN_IN_OUTCOME.MISSING ||
            signInOutcome === SIGN_IN_OUTCOME.MISSING_WORKSPACE) && (
            <div className="bg-yellow-300 px-3 py-2 rounded-lg">
              <p className="text-black text-xs">
                We couldn't authenticate you, ensure you have a Spill account by
                being in a Slack channel that has the @Spill Slack app in.
              </p>
            </div>
          )}

          {(signInOutcome === SIGN_IN_OUTCOME.DENIED ||
            signInOutcome === SIGN_IN_OUTCOME.ERROR) && (
            <div className="bg-yellow-300 px-3 py-2 rounded-lg">
              <p className="text-black text-xs">
                Something went wrong and we couldn't sign you in. Please try
                again, and if the problem persists contact us at{" "}
                <a className="underline" href="mailto:hi@spill.chat">
                  hi@spill.chat
                </a>
                .
              </p>
            </div>
          )}
        </div>
        <div className="fixed bottom-4 left-4">
          <Button asChild variant="tertiary">
            <Link to="/signup">Sign up as a new company</Link>
          </Button>
        </div>
      </div>
    </>
  )
}
