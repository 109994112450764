import { gql, useQuery } from "@apollo/client"

import { meanBy } from "common/helpers/meanBy"
import { MOOD_IMAGE_MAP } from "features/pulse/constants/emotions"

import type {
  CurrentTeamMoodGetMeetingQuery as QueryData,
  CurrentTeamMoodGetMeetingQueryVariables as QueryVariables,
} from "types/graphql"

const fragments = {
  meetingFields: gql`
    fragment CurrentTeamMoodMeetingFields on Meeting {
      urlId
      sharedPulseResponses {
        score
      }
    }
  `,
}

const queries = {
  GET_MEETING: gql`
    query CurrentTeamMoodGetMeeting($meetingUrlId: ID!) {
      meeting(urlId: $meetingUrlId) {
        ...CurrentTeamMoodMeetingFields
      }
    }
    ${fragments.meetingFields}
  `,
}

type CurrentTeamMoodProps = {
  meetingUrlId: string
}

export function CurrentTeamMood({
  meetingUrlId,
}: CurrentTeamMoodProps): JSX.Element | null {
  const { data } = useQuery<QueryData, QueryVariables>(queries.GET_MEETING, {
    fetchPolicy: "cache-only",
    variables: { meetingUrlId },
  })

  const meanScore = meanBy(data?.meeting?.sharedPulseResponses ?? [], "score")
  if (meanScore === null) return null
  const meanScoreFixed = meanScore.toFixed(1)

  let moodImageIndex = 3
  if (meanScore >= 6 && meanScore < 8) moodImageIndex = 6
  if (meanScore >= 8) moodImageIndex = 9

  return (
    <div className="flex justify-center items-center space-x-4">
      <img
        alt="Current team mood"
        className="w-28"
        src={MOOD_IMAGE_MAP[moodImageIndex]}
      />
      <span className="font-semibold text-5xl text-black">
        {meanScoreFixed}
      </span>
    </div>
  )
}

CurrentTeamMood.fragments = fragments
CurrentTeamMood.queries = queries
