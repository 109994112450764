import { Button, H3, P } from "@spillchat/puddles"
import { useMutation } from "@apollo/client"
import { FunctionComponent, useEffect } from "react"

import { LogoWordmark } from "common/components/logo/LogoWordmark"
import { useAuth } from "common/context/authContext"
import {
  LinkUserLandingAbortMergeMutation,
  LinkUserLandingTriggerCodesMutation,
  PlatformType,
} from "types/graphql"

import { mutations } from "./LinkUserLanding.mutations"

type Props = {
  onCodesSent: () => void
  onMergeAborted: () => void
}

export const LinkUserLanding: FunctionComponent<Props> = (props: Props) => {
  const { unverifiedPlatformUser } = useAuth()

  const [triggerCodes, { data: triggerCodesData }] =
    useMutation<LinkUserLandingTriggerCodesMutation>(mutations.triggerCodes)
  const [abortMerging, { data: abortMergingData }] =
    useMutation<LinkUserLandingAbortMergeMutation>(mutations.abortMerging)

  const sendCodes = () => {
    void triggerCodes()
  }

  useEffect(() => {
    if (triggerCodesData?.triggerCodes === true) {
      props.onCodesSent()
    }
  }, [triggerCodesData])

  useEffect(() => {
    if (abortMergingData?.abortMerge === true) {
      props.onMergeAborted()
    }
  }, [abortMergingData])

  const abortMerge = () => {
    void abortMerging()
  }

  if (unverifiedPlatformUser == null) {
    return null
  }

  function toUserPlatformName(platform: PlatformType): string {
    let platformName: string | null = null
    switch (platform) {
      case PlatformType.CUSTOM:
        platformName = "custom integration"
        break
      case PlatformType.SLACK:
        platformName = "Slack workspace"
        break
      case PlatformType.TEAMS:
        platformName = "MS Teams workspace"
        break
      case PlatformType.EMAIL:
        platformName = "email account"
        break
    }
    return platformName
  }

  function toUserPlatformAccountName(platform: PlatformType): string {
    let platformName: string | null = null
    switch (platform) {
      case PlatformType.CUSTOM:
        platformName = "custom integration"
        break
      case PlatformType.SLACK:
        platformName = "Slack account"
        break
      case PlatformType.TEAMS:
        platformName = "MS Teams account"
        break
      case PlatformType.EMAIL:
        platformName = "email address"
        break
    }
    return platformName
  }

  const newPlatformName = toUserPlatformName(
    unverifiedPlatformUser.platformUserPlatform
  )

  const oldPlatformAccountName =
    unverifiedPlatformUser.appUserDetails.length == 1
      ? toUserPlatformAccountName(
          unverifiedPlatformUser.appUserDetails[0]!.platformType as PlatformType
        )
      : "Spill account" // If user has email & teams & slack already

  return (
    <div className="flex items-center justify-center col-span-5 lg:col-span-3 min-h-screen relative">
      <div className="fixed top-8 left-8 flex gap-2 items-center">
        <LogoWordmark />
      </div>
      <div className="min-h-screen flex items-center justify-center text-center">
        <div className="max-w-sm mx-auto">
          <div className="flex flex-col items-center gap-4">
            <div className="flex flex-col gap-4 px-4 md:px-0">
              <H3>Confirm {oldPlatformAccountName}</H3>
              <P>Your company has added Spill to your {newPlatformName}.</P>
              <P>
                Connect your{" "}
                {toUserPlatformAccountName(
                  unverifiedPlatformUser.platformUserPlatform
                )}{" "}
                with your {oldPlatformAccountName} to log in correctly.
              </P>
              {unverifiedPlatformUser.appUserDetails.map(pu => (
                <P key={pu.id} weight="medium">
                  {pu.platformType[0]?.toUpperCase() +
                    pu.platformType.slice(1).toLowerCase()}
                  : {pu.name}
                </P>
              ))}

              <div className="flex flex-col items-center justify-center">
                <Button onClick={sendCodes} asChild={true}>
                  <button className="max-w-full">
                    Confirm {oldPlatformAccountName}
                  </button>
                </Button>
                <br></br>
                <Button variant="tertiary" asChild={true} onClick={abortMerge}>
                  <button className="max-w-full">
                    This is not my {oldPlatformAccountName}
                  </button>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
