import { FetchResult, gql, useMutation, useQuery } from "@apollo/client"
import { Button, Checkbox, H2, P } from "@spillchat/puddles"
import { useState, type FunctionComponent, useEffect } from "react"

import { queries as RequireConsentQueries } from "features/auth/components/RequireAgreement"
import RelaxedSpilly from "common/assets/images/spilly/laptopChill.svg"
import {
  ConsentPageAgreeToSharingPolicyMutation,
  ConsentPageAgreeToSharingPolicyMutationVariables,
  ConsentPageGetUserQuery,
  ConsentPageTermsAgreeMutation,
  ConsentPageTermsAgreeMutationVariables,
  SpillSubscriptionPlan,
} from "types/graphql"
import { ConsentPageGetUserQueryVariables } from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment ConsentPageQueryFields on Query {
      user {
        id
        hasAgreedToTsAndCs
        hasAgreedToHealthData
        hasAgreedToSharingPolicy
        company {
          id
          subscriptionPlan
        }
      }
    }
  `,
}

export const queries = {
  getUser: gql`
    query ConsentPageGetUser {
      ...ConsentPageQueryFields
    }
    ${fragments.queryFields}
  `,
}

const mutations = {
  agreeToTerms: gql`
    mutation ConsentPageTermsAgree(
      $hasAgreedToHealthDataProcessing: Boolean!
      $hasAgreedToTsAndCs: Boolean!
      $hasConfirmedIsAtLeast18: Boolean!
    ) {
      agreeToTerms(
        hasAgreedToHealthDataProcessing: $hasAgreedToHealthDataProcessing
        hasAgreedToTsAndCs: $hasAgreedToTsAndCs
        hasConfirmedIsAtLeast18: $hasConfirmedIsAtLeast18
      ) {
        id
        hasAgreedToTsAndCs
        hasAgreedToHealthData
      }
    }
  `,

  agreeSharingPolicy: gql`
    mutation ConsentPageAgreeToSharingPolicy {
      agreeToSharingPolicy {
        id
        hasAgreedToSharingPolicy
      }
    }
  `,
}

export const ConsentPage: FunctionComponent = () => {
  const [agreesTermsAndConditions, setAgreesTermsAndConditions] =
    useState(false)
  const [requiresTermsAndConditions, setRequiresTermsAndConditions] =
    useState(false)

  const { data } = useQuery<
    ConsentPageGetUserQuery,
    ConsentPageGetUserQueryVariables
  >(queries.getUser)

  const { subscriptionPlan } = data?.user?.company ?? {}

  const [agreeToTerms, { loading: agreeingToTerms }] = useMutation<
    ConsentPageTermsAgreeMutation,
    ConsentPageTermsAgreeMutationVariables
  >(mutations.agreeToTerms, {
    refetchQueries: [RequireConsentQueries.getUser],
  })

  const [agreeToSharing, { loading: agreeingToSharing }] = useMutation<
    ConsentPageAgreeToSharingPolicyMutation,
    ConsentPageAgreeToSharingPolicyMutationVariables
  >(mutations.agreeSharingPolicy, {
    refetchQueries: [RequireConsentQueries.getUser],
  })

  useEffect(() => {
    const requiresTermsAndConditions =
      data?.user?.hasAgreedToTsAndCs === false ||
      data?.user?.hasAgreedToHealthData === false
    if (requiresTermsAndConditions) {
      setRequiresTermsAndConditions(requiresTermsAndConditions)
    } else {
      setAgreesTermsAndConditions(true)
    }
  }, [data])

  const agreeToAllTerms = async () => {
    type AgreementTypes =
      | ConsentPageTermsAgreeMutation
      | ConsentPageAgreeToSharingPolicyMutation

    const requests: Promise<FetchResult<AgreementTypes>>[] = [
      agreeToTerms({
        variables: {
          hasAgreedToHealthDataProcessing: true,
          hasAgreedToTsAndCs: true,
          hasConfirmedIsAtLeast18: true,
        },
      }),
    ]

    if (subscriptionPlan === SpillSubscriptionPlan.STARTER) {
      requests.push(agreeToSharing())
    }

    return await Promise.all(requests)
  }

  return (
    <div className="flex flex-col p-4 lg:p-16 gap-4 md:gap-12 overflow-y-scroll overscroll-y-contain h-screen lg:h-auto">
      <div className="flex gap-24 justify-between">
        <div className="flex flex-col gap-4">
          <H2>
            Your therapy sessions are confidential, but your company sees
            the&nbsp;bill
          </H2>
          <P>
            Your company&apos;s admin has offered to pay for session credits
            with Spill. We bill those sessions on a pay as you go basis, so
            they&apos;ll know how many sessions are used at the company level.
          </P>
        </div>
        <img
          src={RelaxedSpilly}
          alt="Relaxed Spilly"
          className="max-w-sm hidden md:block"
        />
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-6">
        <div className="bg-spill-blue-100 rounded-2xl p-6 w-full flex flex-col gap-4">
          <P>
            <strong>Your company will see:</strong>
          </P>
          <P>
            <ul className="list-disc px-4">
              <li className="text-sm pb-2">
                How many sessions of therapy they are paying for at a company
                level
              </li>
              <li className="text-sm">
                Any feedback or thank you messages you specifically opt in to
                sending
              </li>
            </ul>
          </P>
        </div>
        <div className="bg-teal-100 rounded-2xl p-6 w-full flex flex-col gap-4">
          <P>
            <strong>Your company will never see:</strong>
          </P>
          <P>
            <ul className="list-disc px-4">
              <li className="text-sm pb-2">
                Anything you share with your counsellor
              </li>
              <li className="text-sm pb-2">
                Any details about your counsellor, their specialisms, or pre
                session information
              </li>
              <li className="text-sm">
                Dates or times of sessions, regardless of whether or not you
                attend them
              </li>
            </ul>
          </P>
        </div>
      </div>

      {requiresTermsAndConditions ? (
        <div className="flex gap-2 w-full justify-center">
          <Checkbox
            id="termsOfUser"
            value={String(agreesTermsAndConditions)}
            onClick={() => setAgreesTermsAndConditions(prev => !prev)}
            label={{
              children: (
                <>
                  I&apos;ve read and agreed to the terms and understood the{" "}
                  <a
                    href="https://www.spill.chat/legals/privacy-notice"
                    className="underline"
                    title="The Spill privacy notice"
                  >
                    privacy notice
                  </a>
                  .
                </>
              ),
            }}
          />
        </div>
      ) : null}

      <div className="w-full flex justify-center">
        <Button
          variant="primary"
          disabled={agreesTermsAndConditions === false}
          onClick={async () => await agreeToAllTerms()}
          loading={agreeingToTerms || agreeingToSharing}
        >
          I understand
        </Button>
      </div>
    </div>
  )
}
