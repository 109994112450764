import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { gql, useMutation, useLazyQuery } from "@apollo/client"
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { Helmet } from "react-helmet-async"
import { Button, H2, P } from "@spillchat/puddles"

import { NotFound404Page } from "common/components/NotFound404Page"
import { V5MoodScoreQuestion } from "features/pulse/components/V5MoodScoreQuestion"
import { V5FeelingsQuestion } from "features/pulse/components/V5FeelingsQuestion"
import { SharingQuestion } from "features/pulse/components/SharingQuestion"
import { PulseResults } from "features/pulse/pages/PulseResults"
import {
  PulseCreatePulseResponseV5Mutation as MutationData,
  PulseCreatePulseResponseV5MutationVariables as MutationVariables,
  PulseGetMeetingQuery as QueryData,
  PulseGetMeetingQueryVariables as QueryVariables,
  SpillSubscriptionPlan,
  SpillSubscriptionStatus,
} from "types/graphql"
import { V5FieldValues } from "features/pulse/types"

export const fragments = {
  queryFields: gql`
    fragment PulseQueryFields on Query {
      meeting(urlId: $meetingUrlId) {
        urlId
        currentVersionNumber
        redirectUrl
        company {
          id
          subscriptionPlan
          subscriptionStatus
        }
      }
      user {
        id
        displayName
        company {
          id
        }
      }
    }
  `,
}

export const queries = {
  getMeeting: gql`
    query PulseGetMeeting($meetingUrlId: ID!) {
      ...PulseQueryFields
    }
    ${fragments.queryFields}
  `,
  createPulseResponse: gql`
    mutation PulseCreatePulseResponseV5(
      $isScoreShared: Boolean!
      $meetingUrlId: ID!
      $name: String!
      $otherFeelings: [String!]
      $score: Int!
      $sharedFeelings: [String!]!
      $unsharedFeelings: [String!]!
      $otherFeelingsShared: [String!]!
    ) {
      createPulseResponseV5(
        meetingUrlId: $meetingUrlId
        content: {
          isScoreShared: $isScoreShared
          name: $name
          otherFeelings: $otherFeelings
          score: $score
          sharedFeelings: $sharedFeelings
          unsharedFeelings: $unsharedFeelings
          otherFeelingsShared: $otherFeelingsShared
        }
      ) {
        ... on PulseResponseSharedAndUnshared {
          id
          createdAt
          name
          score
          sharedFeelings
          unsharedFeelings
          userId
          otherFeelingsShared
        }
      }
    }
  `,
}

export const Pulse: FunctionComponent = () => {
  const { meetingUrlId } = useParams()
  const navigate = useNavigate()

  const [questionID, setQuestionID] = useState<number>(0)
  const nextQuestion = () => setQuestionID(questionID + 1)
  const prevQuestion = () => setQuestionID(questionID - 1)

  const methods = useForm<V5FieldValues>({
    defaultValues: {
      feelings: [],
      isMoodShared: false,
      mood: null,
      otherFeelings: [],
      sharedFeelings: [],
      otherFeelingsShared: [],
      userName: "",
    },
    mode: "onChange",
    shouldUseNativeValidation: true,
  })

  const { getValues, handleSubmit } = methods

  const [getMeeting, { data }] = useLazyQuery<QueryData, QueryVariables>(
    queries.getMeeting
  )

  useEffect(() => {
    if (meetingUrlId !== undefined) {
      void getMeeting({ variables: { meetingUrlId } })
    }
  }, [meetingUrlId])

  const meeting = data?.meeting

  const [submitPulseResponseV5] = useMutation<MutationData, MutationVariables>(
    queries.createPulseResponse,
    {
      context: { credentials: "include" },
      onCompleted(data) {
        if (
          data?.createPulseResponseV5.__typename ===
          "PulseResponseSharedAndUnshared"
        ) {
          const responseId = data.createPulseResponseV5.id
          const userId = data?.createPulseResponseV5.userId
          if (responseId !== undefined && userId === null) {
            sessionStorage.setItem("latestResponseId", responseId)
          }
          return navigate("results")
        }
      },
      refetchQueries: [queries.getMeeting],
    }
  )

  if (!meeting) return null

  const onSubmit = async () => {
    const {
      feelings,
      isMoodShared,
      mood,
      otherFeelings,
      sharedFeelings,
      otherFeelingsShared,
      userName,
    } = getValues()

    if (mood === null) return

    const isSomethingShared =
      sharedFeelings.length > 0 ||
      isMoodShared ||
      otherFeelingsShared.length > 0

    await submitPulseResponseV5({
      variables: {
        isScoreShared: isMoodShared,
        meetingUrlId: meeting.urlId,
        name: isSomethingShared ? userName : "",
        otherFeelings,
        score: Number(mood),
        sharedFeelings,
        otherFeelingsShared,
        unsharedFeelings: feelings.filter(f => !sharedFeelings.includes(f)),
      },
    })
  }

  const isStarterPlan =
    data.meeting?.company.subscriptionPlan === SpillSubscriptionPlan.STARTER
  const isCompanyInactive =
    data.meeting?.company.subscriptionStatus ===
    SpillSubscriptionStatus.INACTIVE

  const error = (() => {
    if (isStarterPlan) {
      return {
        title: "Oops! Looks like you're on the wrong plan.",
        description:
          "If you want to use Team Check-in, contact your admin to upgrade.",
      }
    }
    if (isCompanyInactive) {
      return {
        title: "Oops! Looks like you need to activate your account.",
        description:
          "If you want to use Team Check-in, contact your admin to reactivate.",
      }
    }
    return null
  })()

  return (
    <>
      <Helmet title="Spill | Pulse" />

      <div className="absolute inset-0 overflow-hidden">
        {backgroundClassNames
          .sort(() => 0.5 - Math.random())
          .slice(0, 2)
          .map((className, i) => (
            <Circle key={i} className={className} questionId={questionID} />
          ))}
      </div>

      <FormProvider {...methods}>
        <form
          className="flex flex-col items-center justify-center z-10"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Routes>
            <Route
              index
              element={
                error ? (
                  <div className="box flex flex-col items-center max-w-2xl p-8 space-y-6">
                    <H2>{error?.title}</H2>
                    <P>{error?.description}</P>
                    <P>You can join the meeting below.</P>
                    <Button asChild>
                      <Link
                        to={data.meeting?.redirectUrl ?? ""}
                        rel="noopener noreferrer"
                      >
                        Join meeting
                      </Link>
                    </Button>
                  </div>
                ) : (
                  <>
                    {questionID === 0 && (
                      <V5MoodScoreQuestion nextQuestion={nextQuestion} />
                    )}
                    {questionID === 1 && (
                      <V5FeelingsQuestion
                        nextQuestion={nextQuestion}
                        prevQuestion={prevQuestion}
                      />
                    )}
                    {questionID === 2 && (
                      <SharingQuestion
                        meetingUrlId={meeting.urlId}
                        prevQuestion={prevQuestion}
                      />
                    )}
                  </>
                )
              }
            />

            <Route
              path="results"
              element={<PulseResults meetingUrlId={meeting.urlId} />}
            />

            <Route path="*" element={<NotFound404Page />} />
          </Routes>
        </form>
      </FormProvider>
    </>
  )
}

/**
 * Got distracted making an animated background for Pulse.
 * Didn't want to delete as actually turned out pretty cool
 * but haven't specced it so just gonna leave here in case.
 */
const backgroundClassNames = [
  "bg-yellow-200",
  "bg-teal-200",
  "bg-red-200",
  "bg-blue-200",
]

interface CircleProps {
  className: string
  questionId: number
}

const Circle: FunctionComponent<CircleProps> = props => {
  const { pathname } = useLocation()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const circleProps = useMemo(
    () => ({
      className: `absolute aspect-square duration-[1500ms] opacity-75 rounded-full -translate-x-1/2 -translate-y-1/2 ${props.className}`,
      style: {
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
        width: "100vmin",
      },
    }),
    [isMounted, pathname, props.questionId]
  )

  return <div {...circleProps} />
}
