import { gql } from "@apollo/client"

export const fragments = {
  queryFields: gql`
    fragment RecommendedActionsQueryFields on Query {
      user {
        id
        company {
          id
          isLegacyPricing
          subscriptionPlan
          trialPeriodEndDate
          recommendedActions {
            id
            companyId
            title
            message
            icon
            url
            dismissable
            createdAt
            expiryDate
          }
        }
      }
    }
  `,
}

export const queries = {
  getCompany: gql`
    query RecommendedActionsGetCompany {
      ...RecommendedActionsQueryFields
    }
    ${fragments.queryFields}
  `,
}
