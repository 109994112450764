import { H1, Tabs } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { useLocation, useNavigate } from "react-router-dom"

type AdminTherapyNavigationProps = {
  tabLinks: {
    label: string
    path: string
    visible: boolean | undefined
  }[]
}

export const AdminTherapyNavigation: FunctionComponent<
  AdminTherapyNavigationProps
> = ({ tabLinks }: AdminTherapyNavigationProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const currentPath = pathname.split("/").pop()
  return (
    <div className="flex flex-col gap-8">
      <H1>Therapy</H1>
      <div className="max-w-full overflow-x-scroll h-full">
        <Tabs.Root value={currentPath}>
          <Tabs.List>
            {tabLinks
              .filter(tab => tab.visible === true)
              .map(tab => {
                return (
                  <Tabs.Trigger
                    key={tab.path}
                    value={tab.path}
                    onClick={() => navigate("/admin/therapy/" + tab.path)}
                  >
                    {tab.label}
                  </Tabs.Trigger>
                )
              })}
          </Tabs.List>
        </Tabs.Root>
      </div>
    </div>
  )
}
