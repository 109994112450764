import { useEffect } from "react"

export function useModalCookieBannerAwareness(): void {
  useEffect(() => {
    const cookieBanner = document.getElementById("CybotCookiebotDialog")
    if (cookieBanner) {
      cookieBanner.style.pointerEvents = "auto"
    }
  }, [document.body.style.pointerEvents])
}
