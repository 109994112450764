import { Fragment, useMemo } from "react"
import { gql, useQuery } from "@apollo/client"
import { addDays, isSameMonth, subMonths } from "date-fns"
import { clamp, isNumber, maxBy } from "lodash"
import cn from "classnames"
import { inflect } from "inflection"

import { Feeling } from "types/graphql"
import { formatMonth } from "common/helpers/formatMonth"
import { Tooltip } from "common/components/Tooltip"
import { FEELINGS } from "features/pulse/constants/emotions"
import { useAnalytics } from "common/context/analyticsContext"

import type {
  EmotionsChangeBarChartGetCompanyQuery as QueryData,
  EmotionsChangeBarChartGetCompanyQueryVariables as QueryVariables,
} from "types/graphql"
import type { Connotation } from "features/pulse/types"

const fragments = {
  companyFields: gql`
    fragment EmotionsChangeBarChartCompanyFields on Company {
      id
      pulseResponseAggregatesByMonth(fromMonth: $fromMonth, toMonth: $toMonth) {
        month
        responseCount
        someFeelingsSharedResponseCount
        feelingAggregates {
          feeling
          sharedCount
          sharedPercent
        }
      }
    }
  `,
}

const queries = {
  getCompany: gql`
    query EmotionsChangeBarChartGetCompany(
      $fromMonth: Month!
      $toMonth: Month!
    ) {
      company {
        ...EmotionsChangeBarChartCompanyFields
      }
    }
    ${fragments.companyFields}
  `,
}

const variables = {
  fromMonth: formatMonth(subMonths(new Date(), 2)),
  toMonth: formatMonth(subMonths(new Date(), 1)),
}

type EmotionsChangeBarChartProps = {
  connotation: Connotation
}

export function EmotionsChangeBarChart({
  connotation,
}: EmotionsChangeBarChartProps): JSX.Element | null {
  const { data } = useQuery<QueryData, QueryVariables>(queries.getCompany, {
    fetchPolicy: "cache-first",
    variables,
  })
  const { track } = useAnalytics()

  const aggregates = data?.company?.pulseResponseAggregatesByMonth ?? []
  const aggregatesLastMonth = getAggregatesForMonth(aggregates, 1)
  const aggregatesPenultimateMonth = getAggregatesForMonth(aggregates, 2)
  const someFeelingsSharedResponseCountLastMonth =
    aggregatesLastMonth?.someFeelingsSharedResponseCount
  const maxFeelingPercentageLastMonth = maxBy(
    aggregatesLastMonth?.feelingAggregates,
    "sharedPercent"
  )?.sharedPercent
  const upperRange = isNumber(maxFeelingPercentageLastMonth)
    ? Math.ceil(clamp(maxFeelingPercentageLastMonth + 10, 0, 100) / 10) * 10
    : 50

  const feelings = useMemo(() => {
    return Object.values(FEELINGS).filter(
      feeling => feeling.connotation === connotation
    )
  }, [connotation])

  const chartData = useMemo(() => {
    return feelings
      .map(feeling => {
        const feelingAggregatesLastMonth = getFeelingAggregates(
          aggregatesLastMonth,
          feeling.noun
        )
        const feelingAggregatesPenultimateMonth = getFeelingAggregates(
          aggregatesPenultimateMonth,
          feeling.noun
        )
        return {
          ...feeling,
          countLastMonth: feelingAggregatesLastMonth?.sharedCount ?? null,
          percentLastMonth: feelingAggregatesLastMonth?.sharedPercent ?? null,
          percentPenultimateMonth:
            feelingAggregatesPenultimateMonth?.sharedPercent ?? null,
        }
      })
      .sort((a, b) => {
        if (a.percentLastMonth === null || b.percentLastMonth === null) return 1
        return b.percentLastMonth - a.percentLastMonth
      })
  }, [data])

  return (
    <div
      className="grid gap-sm h-full items-center w-full"
      style={{
        gridTemplateColumns:
          "fit-content(25%) fit-content(25%) 1fr fit-content(25%)",
        gridTemplateRows: `repeat(${feelings.length}, fit-content(${Math.ceil(
          100 / (feelings.length + 1)
        )}%)) 1fr`,
        placeItems: "flex-end stretch",
      }}
    >
      {chartData.map(feeling => {
        const feelingPercentageChange =
          isNumber(feeling.percentLastMonth) &&
          isNumber(feeling.percentPenultimateMonth)
            ? Math.round(
                feeling.percentLastMonth - feeling.percentPenultimateMonth
              )
            : null

        const tooltipContent = `${
          isNumber(feeling.percentLastMonth)
            ? Math.round(feeling.percentLastMonth)
            : "-"
        }%${
          isNumber(feeling.countLastMonth) &&
          isNumber(someFeelingsSharedResponseCountLastMonth)
            ? `\n${feeling.countLastMonth} ${inflect(
                "instance",
                feeling.countLastMonth
              )} out of ${someFeelingsSharedResponseCountLastMonth} shared ${inflect(
                "response",
                someFeelingsSharedResponseCountLastMonth
              )}`
            : ""
        }`

        return (
          <Fragment key={feeling.noun}>
            <img
              alt={`${feeling.noun} icon`}
              className="h-8 w-8"
              src={feeling.src}
            />
            <div>
              <span className="capitalize font-semibold">{feeling.noun}</span>
            </div>
            <Tooltip
              content={tooltipContent}
              followMouse
              id={`${feeling.noun}-current`}
            >
              <div
                className={cn("h-full rounded-lg transition", {
                  "bg-teal-600 hover:bg-teal-600/80":
                    connotation === "positive",
                  "bg-red-400 hover:bg-red-400/80": connotation === "negative",
                })}
                onMouseEnter={() =>
                  track("Admin hover over emotions change chart", {
                    feeling: feeling.noun,
                    trending:
                      feelingPercentageChange === null
                        ? "no change"
                        : feelingPercentageChange > 0
                          ? "up"
                          : "down",
                  })
                }
                style={{
                  width: `${
                    ((feeling.percentLastMonth ?? 0) / upperRange) * 100
                  }%`,
                }}
              />
            </Tooltip>
            <div
              className={cn(
                "flex font-semibold h-full items-center justify-center px-2 rounded-lg text-sm",
                {
                  "bg-grey-100":
                    feelingPercentageChange === 0 ||
                    feelingPercentageChange === null,
                  "bg-teal-600":
                    (connotation === "positive" &&
                      feelingPercentageChange !== null &&
                      feelingPercentageChange > 0) ||
                    (connotation === "negative" &&
                      feelingPercentageChange !== null &&
                      feelingPercentageChange < 0),
                  "bg-red-400":
                    (connotation === "positive" &&
                      feelingPercentageChange !== null &&
                      feelingPercentageChange < 0) ||
                    (connotation === "negative" &&
                      feelingPercentageChange !== null &&
                      feelingPercentageChange > 0),
                }
              )}
            >
              {feelingPercentageChange === null
                ? "-"
                : `${
                    feelingPercentageChange >= 0 ? "+" : ""
                  }${feelingPercentageChange}%`}
            </div>
          </Fragment>
        )
      })}
      <div className="col-start-3 col-end-3 flex justify-between">
        {new Array(
          Math.round(upperRange > 50 ? upperRange / 20 : upperRange / 10) + 1
        )
          .fill(null)
          .map((_, index) => (
            <span key={index} className="font-semibold text-sm">
              {index * (upperRange > 50 ? 20 : 10)}%
            </span>
          ))}
      </div>
    </div>
  )
}

EmotionsChangeBarChart.fragments = fragments
EmotionsChangeBarChart.queries = queries
EmotionsChangeBarChart.variables = variables

function sameMonth(month: string, monthsAgo: number) {
  return isSameMonth(
    subMonths(new Date(), monthsAgo),
    // adding days so the month is correct for all timezones
    addDays(new Date(month), 2)
  )
}

function getAggregatesForMonth(
  pulseResponseAggregatesByMonth: QueryData["company"]["pulseResponseAggregatesByMonth"],
  monthsAgo: number = 1
) {
  return (
    (pulseResponseAggregatesByMonth ?? []).find(i =>
      sameMonth(i.month, monthsAgo)
    ) ?? null
  )
}

function getFeelingAggregates(
  pulseResponseAggregatesByMonth:
    | QueryData["company"]["pulseResponseAggregatesByMonth"][number]
    | null,
  feeling: Feeling
) {
  return (pulseResponseAggregatesByMonth?.feelingAggregates ?? []).find(
    i => i.feeling === feeling
  )
}
