export const productionConfig = {
  acuity: {
    isTrialFieldId: "10168447",
    ownerId: "19456943",
    userExternalIdFieldId: "7931424",
    userIdFieldId: "7931425",
    workspaceExternalIdFieldId: "7931422",
  },
  acuity3: {
    appointmentTypeIds: {
      teamPlanSeries: "45798125",
      teamPlanSingle: "45799114",
    },
    ownerId: "19456943",
    therapySessionAcuityAppointmentTypeId: "41388624",
    fieldIds: {
      spillUserId: "7931425",
      pronouns: "12880090",
      approximateLocation: "12880092",
      emergencyContactName: "12880093",
      emergencyContactNumber: "12880095",
    },
  },
  adviceLibrary: {
    appId: "SXHQW50MCI",
    readKey: "6828c62a52700f5af3553f0dc9c16c8a",
    searchIndexName: "advice_library",
    searchSuggetionsIndexName: "advice_library_query_suggestions",
  },
  google: {
    clientId:
      "246272913629-6tciaaod2jbsmm0cv6c5uqehf8aqhdt0.apps.googleusercontent.com",
    analyticsTrackingId: "G-XFM234KWJT",
  },
  mixpanel: {
    apiHost: "https://metrics.spill.chat",
    token: "ed346db54476037775ae9a4fd800ae81",
    sendEvents: true,
  },
  unleash: {
    clientKey: "FMcKRJQeq0bdiMkddpRFvmOcxOml1vkP02JeCWpEns3agr8Yk9ItyQ==",
  },
}
