import { autocomplete, AutocompleteOptions } from "@algolia/autocomplete-js"
import { BaseItem } from "@algolia/autocomplete-core"
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions"
import { SearchClient } from "algoliasearch"
import {
  createElement,
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { createRoot, Root } from "react-dom/client"
import { useSearchBox } from "react-instantsearch"

import { config } from "config"

import "@algolia/autocomplete-theme-classic"

type AutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
  onSubmit: () => void
  searchClient: SearchClient
}

/**
 * Component that uses @algolia/autocomplete-js package to
 * replace their <SearchBox /> component.
 *
 * [Docs](https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/using-react/)
 */
export const AutocompleteSearchBox: FunctionComponent<AutocompleteProps> = ({
  onSubmit,
  searchClient,
  ...autocompleteConfig
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const panelRootRef = useRef<Root | null>(null)
  const rootRef = useRef<HTMLElement | null>(null)

  const { query, refine: setQuery, clear } = useSearchBox()

  const plugins = useMemo(() => {
    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: `${config.adviceLibrary.searchIndexName}_query_suggestions`,
      transformSource({ source }) {
        return {
          ...source,
          sourceId: "querySuggestionsPlugin",
          onSelect({ item }) {
            setQuery(item.query)
            onSubmit()
          },
          async getItems(params) {
            if (!params.state.query) {
              return []
            }

            return await source.getItems(params)
          },
        }
      },
    })

    return [querySuggestions]
  }, [])

  useEffect(() => {
    if (containerRef.current == null) {
      return undefined
    }

    const search = autocomplete({
      onReset: () => clear(),
      container: containerRef.current,
      placeholder: "Type anything...",
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (panelRootRef.current == null || rootRef.current !== root) {
          rootRef.current = root

          panelRootRef.current?.unmount()
          panelRootRef.current = createRoot(root)
        }

        panelRootRef.current.render(children)
      },
      initialState: { query },
      onSubmit: ({ state }) => {
        setQuery(state.query)
        onSubmit()
      },
      plugins,
      openOnFocus: true,
      ...autocompleteConfig,
    })

    return () => {
      search.destroy()
    }
  }, [plugins, query])

  return <div ref={containerRef} className="w-full" />
}
