import { gql, useLazyQuery } from "@apollo/client"
import { FunctionComponent, useEffect } from "react"
import { differenceInMinutes, parseISO } from "date-fns"
import { FormProvider, useForm } from "react-hook-form"
import { Link, useParams } from "react-router-dom"
import { inRange, isNil } from "lodash"
import { Button, H1 } from "@spillchat/puddles"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import { NotFoundPage } from "common/components/NotFoundPage"
import { useApp } from "common/context/appContext"
import { isEnum } from "types/typeguards"
import {
  AppointmentStatus,
  BookableAppointmentType,
  AppointmentReschedulePageGetDataQuery as QueryData,
  AppointmentReschedulePageGetDataQueryVariables as QueryVars,
} from "types/graphql"
import { FormValues as AppointmentBookingPageFormValues } from "features/therapy/pages/AppointmentBookingPage"

import { ErrorInfo } from "./ErrorInfo"
import { FormSections } from "./FormSections"

export const fragments = {
  queryFields: gql`
    fragment AppointmentReschedulePageQueryFields on Query {
      appointment(id: $id) {
        id
        appointmentType
        startsAt
        status
        counsellor {
          id
          calendarId
          firstName
          videoCallUrl
        }
      }
      user {
        id
        therapyProfile {
          email
        }
      }
    }
  `,
}

export const queries = {
  getData: gql`
    query AppointmentReschedulePageGetData($id: ID!) {
      ...AppointmentReschedulePageQueryFields
    }
    ${fragments.queryFields}
  `,
}

type FormValues = Pick<
  AppointmentBookingPageFormValues,
  | "appointment"
  | "appointmentType"
  | "filter"
  | "startTime"
  | "timeZone"
  | "userInfo"
>

export const AppointmentReschedulePage: FunctionComponent = () => {
  const { appTimeZone } = useApp()
  const { appointmentId } = useParams()

  const formMethods = useForm<FormValues>({
    defaultValues: {
      startTime: undefined,
      timeZone: appTimeZone,
      userInfo: {
        boundaries: undefined,
        notice: undefined,
      },
    },
  })
  const { setValue } = formMethods

  const [getAppointment, { data, previousData }] = useLazyQuery<
    QueryData,
    QueryVars
  >(queries.getData)

  const appointmentType = data?.appointment?.appointmentType
  const counsellor = data?.appointment?.counsellor
  const email = data?.user?.therapyProfile?.email
  const videoCallUrl = counsellor?.videoCallUrl

  // Fetch existing appointment
  useEffect(() => {
    if (appointmentId !== undefined) {
      void getAppointment({ variables: { id: appointmentId } })
    }
  }, [appointmentId])

  // Set form appointment type to existing appointment type
  useEffect(() => {
    if (isEnum(appointmentType, BookableAppointmentType)) {
      setValue("appointmentType", appointmentType)
    }
  }, [appointmentType])

  // Set form email to stored user's email
  useEffect(() => {
    if (!isNil(email)) setValue("userInfo.email", email)
  }, [email])

  useEffect(() => {
    if (counsellor?.id !== undefined) {
      setValue("filter.counsellorIds", [counsellor.id])
    }
  }, [counsellor?.id])

  if (data?.appointment === undefined) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  if (data.appointment === null) {
    return <NotFoundPage className="min-h-screen" />
  }

  if (
    [AppointmentStatus.CANCELLED, AppointmentStatus.NOSHOW].includes(
      data.appointment.status
    )
  ) {
    return (
      <ErrorInfo description="This session cannot be resheduled as it has been cancelled.">
        <Button asChild>
          <Link to="/therapy/sessions">Manage sessions</Link>
        </Button>
      </ErrorInfo>
    )
  }

  const startsAtDiffInMins = differenceInMinutes(
    parseISO(
      // At first data.appointment.startsAt is the original start time
      // but then changes to the rescheduled time post mutation.
      // previousData.appointment.startsAt is undefined until the mutation
      // completes, then is the original start time post-mutation.
      // We use previousData post-mutation to avoid immediately showing the sub 24h
      // error message if the user reschedules to a time less than 24h away.
      previousData?.appointment
        ? previousData.appointment.startsAt
        : data.appointment.startsAt
    ),
    new Date(),
    { roundingMethod: "floor" }
  )

  // Appointment started more than 50 mins ago
  if (startsAtDiffInMins < -50) {
    return (
      <ErrorInfo description="This session cannot be rescheduled as it has already happened.">
        <Button asChild>
          <Link to="/therapy/sessions">Manage sessions</Link>
        </Button>
      </ErrorInfo>
    )
  }

  // Appointment started less than 50 mins ago
  if (inRange(startsAtDiffInMins, -50, 0)) {
    return (
      <ErrorInfo description="This session cannot be rescheduled as it has already started.">
        {inRange(startsAtDiffInMins, -25, 0) && !isNil(videoCallUrl) ? (
          <Button asChild>
            <Link to={videoCallUrl ?? ""}>Join now</Link>
          </Button>
        ) : (
          <Button asChild>
            <Link to="/therapy/sessions">Manage sessions</Link>
          </Button>
        )}
      </ErrorInfo>
    )
  }

  // Appointment starts in less than 24 hours
  if (startsAtDiffInMins < 24 * 60) {
    return (
      <ErrorInfo
        description={`You cannot reschedule this session as it starts in less than 24 hours.\nYou can cancel the session but we will still pay the therapist and therefore still charge your company.`}
      >
        <Button asChild>
          <Link to="/therapy/sessions">Manage sessions</Link>
        </Button>
      </ErrorInfo>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <form className="space-y-8">
        <H1>Reschedule session</H1>
        <FormSections data={data} />
      </form>
    </FormProvider>
  )
}
