import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { Button, P } from "@spillchat/puddles"
import { ReactNode } from "react"
import { Link } from "react-router-dom"

export default function AdminTherapyProcessingAlert(): ReactNode {
  return (
    <div className="flex flex-col gap-2 rounded-lg p-4 bg-yellow-100">
      <ExclamationCircleIcon className="text-yellow-600 size-6" />
      <P weight="medium">Your package isn't live yet</P>
      <P>
        It will take 2–3 working days to process while we find the right
        specialists. Until then, nobody will be able to see or use this package.
      </P>
      <P>
        If this support is urgent,{" "}
        <Button asChild variant="tertiary">
          <Link to="/admin/help?topic=therapy_access">get in touch</Link>
        </Button>
        .
      </P>
    </div>
  )
}
