import {
  useRef,
  type FunctionComponent,
  useState,
  useLayoutEffect,
} from "react"
import { gql, useQuery } from "@apollo/client"
import { H4, P } from "@spillchat/puddles"
import { FolderIcon, TruckIcon } from "@heroicons/react/24/outline"

import {
  ExtensionRequestListGetDataQuery,
  SpillSubscriptionStatus,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useUser } from "common/context/userContext"

import { ExtensionRequestListItem } from "./ExtensionRequestListItem"

export const queries = {
  getData: gql`
    query ExtensionRequestListGetData {
      user {
        id
        displayName
      }
      company {
        id
        isLegacyPricing
        subscriptionStatus
        inActiveTrialPeriod
        budgetYearUsage {
          totalBillableUsage
        }
        featuresAndSettings {
          id
          yearlyTherapyBudget {
            value
          }
          userCanRequestTherapy {
            value
          }
          userTherapyCap {
            value
          }
          sessionPack {
            active
          }
        }
        openTherapyExtensions {
          id
          expiryDate
          isAnonymous
          noteFromUser
          numberOfSessions
          companyPackageSetting {
            id
            name
            numberSessions
          }
          user {
            id
            displayName
          }
        }
        planPrice {
          perSession
        }
      }
    }
  `,
}

export const ExtensionRequestList: FunctionComponent = () => {
  const { company } = useUser()
  const { data, loading } = useQuery<ExtensionRequestListGetDataQuery>(
    queries.getData,
    { fetchPolicy: "network-only" }
  )
  const extensionListRef = useRef<HTMLUListElement>(null)
  const [extensionListTop, setExtensionListTop] = useState<number>(
    extensionListRef.current?.getBoundingClientRect().top ?? 0
  )

  useLayoutEffect(() => {
    const { top } = extensionListRef.current?.getBoundingClientRect() ?? {
      top: 0,
    }
    setExtensionListTop(top)
  }, [loading])

  const isLegacyPricing = company.isLegacyPricing

  // this can be moved below feature flag check when isLegacy pricing is removed
  if (loading) {
    return (
      <div className="w-full flex items-center justify-center gap-2 flex-col p-8">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  if (isLegacyPricing) {
    return (
      <div className="flex flex-col items-center text-center gap-4 mt-2">
        <div className="bg-grey-200 rounded-full p-2 mt-2">
          <TruckIcon className="size-8" />
        </div>
        <H4>Coming soon</H4>
        <P size="xs">
          We’re launching better visibility of additional session requests.{" "}
          <br />
          Check back in a few weeks!
        </P>
      </div>
    )
  }

  if (data != null && data.company.openTherapyExtensions.length === 0) {
    return (
      <div className="w-full flex items-center justify-center gap-2 flex-col p-8 text-center">
        <FolderIcon className="size-6 text-teal-400" />
        <P weight="medium">No extension requests</P>
        <P size="xs" muted>
          You'll see extension requests come through here when somebody submits
          one
        </P>
      </div>
    )
  }

  const featuresAndSettings = data?.company?.featuresAndSettings

  return (
    <ul
      className="flex flex-col gap-4 overflow-y-auto h-full"
      ref={extensionListRef}
      style={{
        height: `calc(100vh - ${extensionListTop + 66}px)`,
      }}
    >
      {data?.company.openTherapyExtensions.map(extension => {
        const doesApprovalRequireBudgetIncrease =
          data.company.budgetYearUsage != null &&
          featuresAndSettings?.yearlyTherapyBudget.value != null &&
          data.company.budgetYearUsage.totalBillableUsage +
            (extension.numberOfSessions ?? 0) >
            featuresAndSettings.yearlyTherapyBudget.value
        return (
          <ExtensionRequestListItem
            key={`ext-req-list-${extension.id}`}
            extension={extension}
            doesApprovalRequireBudgetIncrease={
              doesApprovalRequireBudgetIncrease
            }
            newYearlyBudget={{
              numberOfSessions: extension.numberOfSessions ?? 0,
              totalBillableUsage:
                data?.company?.budgetYearUsage?.totalBillableUsage,
            }}
            userTherapyCap={featuresAndSettings?.userTherapyCap.value}
            userCanRequestTherapy={
              featuresAndSettings?.userCanRequestTherapy.value
            }
            companyBudget={featuresAndSettings?.yearlyTherapyBudget.value}
            companyHasActiveSubscription={
              data.company.subscriptionStatus === SpillSubscriptionStatus.ACTIVE
            }
            singleSessionPrice={data.company.planPrice?.perSession}
            companyHasSessionPack={
              data.company.featuresAndSettings.sessionPack.active
            }
            companyInActiveTrial={data.company.inActiveTrialPeriod}
          />
        )
      })}
    </ul>
  )
}
