import { FunctionComponent, useEffect, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { gql } from "@apollo/client"
import { isEmpty } from "lodash"
import { Tag } from "@spillchat/puddles"

import { SelectProps } from "common/components/FormElements/Select"
import { Skeleton } from "common/components/Skeleton"
import { FormValues } from "features/therapy/pages/AppointmentBookingPage"
import { LanguageSelectQueryFieldsFragment } from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment LanguageSelectQueryFields on MatchingCriteriaOptions {
      languageCodes
    }
  `,
}

interface LanguageSelectProps
  extends Omit<SelectProps<FormValues>, "options" | "register"> {
  data?: LanguageSelectQueryFieldsFragment
}

export const LanguageSelect: FunctionComponent<LanguageSelectProps> = ({
  data,
}) => {
  const { getValues, setValue, watch } = useFormContext<FormValues>()
  const value = watch("filter.language")
  const supportedLanguageCodes = data?.languageCodes ?? []
  const isUnsupported =
    value !== null && !isEmpty(value) && !supportedLanguageCodes.includes(value)

  useEffect(() => {
    if (isUnsupported) {
      return setValue("filter.language", "")
    }
  }, [isUnsupported])

  const onLanguageSelect = (value: string) => {
    setValue("filter.previousCounsellorId", null)

    if (value === getValues("filter.language")) {
      setValue("filter.language", null)
    } else {
      setValue("filter.language", value)
    }
  }

  const options = useMemo(() => {
    if (data?.languageCodes === undefined) return []

    const availableLanguageCodes = new Set(data?.languageCodes)

    return [
      ...Array.from(availableLanguageCodes)
        .sort((a, b) => a.localeCompare(b))
        .flatMap(code => {
          if (code === "") return []
          const label = new Intl.DisplayNames([code], { type: "language" }).of(
            code
          )
          if (label === undefined) return []
          return { key: code, label, value: code }
        }),
    ]
  }, [JSON.stringify(data?.languageCodes)])

  if (!data) return <Skeleton className="grow h-10 rounded-md" />

  return (
    <div className="flex gap-2 flex-wrap lg:max-h-44 overflow-auto">
      {options.map(option => {
        return (
          <Tag
            key={option.value}
            selected={getValues("filter.language") === option.value}
            onSelect={() => onLanguageSelect(option.value)}
            size="xs"
          >
            {option.label}
          </Tag>
        )
      })}
    </div>
  )
}
