import mixpanel from "mixpanel-browser"

import { config } from "config"

export const load = (): void => {
  mixpanel.init(config.mixpanel.token, {
    api_host: config.mixpanel.apiHost,
    debug: config.environment === "development",
    disable_cookie: true,
  })
}
