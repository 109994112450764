import { useMemo } from "react"
import Fuse, { IFuseOptions } from "fuse.js"

export function useFuse<T>(data: T[], options: IFuseOptions<T>): Fuse<T> {
  const {
    // Sort results by how closely they match with the search term
    shouldSort = true,
    // How fuzzy the search should be; 0 is perfect match, 1 is complete mismatch
    threshold = 0.3,
    ...otherOptions
  } = options

  return useMemo(() => {
    return new Fuse(data, { shouldSort, threshold, ...otherOptions })
  }, [data])
}
