import { gql, useQuery } from "@apollo/client"
import {
  addDays,
  differenceInCalendarMonths,
  format,
  isSameMonth,
  subMonths,
} from "date-fns"
import { clamp, isNumber, maxBy } from "lodash"
import { inflect } from "inflection"
import cn from "classnames"

import { createArray } from "common/helpers/createArray"
import { formatMonth } from "common/helpers/formatMonth"
import { Tooltip } from "common/components/Tooltip"
import { ShareEverythingPercentage } from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"

import type { ComponentProps } from "react"
import type {
  PulseResponseSharePercentageGetCompanyQuery,
  PulseResponseSharePercentageGetCompanyQueryVariables,
} from "types/graphql"

const fragments = {
  companyFields: gql`
    fragment PulseResponseSharePercentageCompanyFields on Company {
      id
      benchmarkGroup {
        id
        shareEverythingPercentage
      }
      pulseResponseAggregatesByMonth(fromMonth: $fromMonth, toMonth: $toMonth) {
        everythingSharedResponseCount
        everythingSharedResponsePercent
        month
        responseCount
      }
    }
  `,
}

const queries = {
  getCompany: gql`
    query PulseResponseSharePercentageGetCompany(
      $fromMonth: Month!
      $toMonth: Month!
    ) {
      company {
        ...PulseResponseSharePercentageCompanyFields
      }
    }
    ${fragments.companyFields}
  `,
}

const variables = {
  fromMonth: formatMonth(subMonths(new Date(), 7)),
  toMonth: formatMonth(subMonths(new Date(), 1)),
}

export function PulseResponseSharePercentage({
  className,
}: Pick<ComponentProps<"div">, "className">): JSX.Element {
  const { data } = useQuery<
    PulseResponseSharePercentageGetCompanyQuery,
    PulseResponseSharePercentageGetCompanyQueryVariables
  >(queries.getCompany, { fetchPolicy: "cache-first", variables })
  const { track } = useAnalytics()

  const maxEverythingSharedResponsePercent = maxBy(
    data?.company.pulseResponseAggregatesByMonth,
    "everythingSharedResponsePercent"
  )?.everythingSharedResponsePercent

  const upperRange = isNumber(maxEverythingSharedResponsePercent)
    ? Math.ceil(clamp(maxEverythingSharedResponsePercent + 10, 0, 100) / 10) *
      10
    : 50

  // Number of months we've requested data for.
  // Used to determine number of chart data items.
  const numMonths = differenceInCalendarMonths(
    new Date(variables.toMonth),
    new Date(variables.fromMonth)
  )

  const chartData = createArray(numMonths, index => {
    const month = subMonths(new Date(), numMonths - index)
    const item = (data?.company.pulseResponseAggregatesByMonth ?? []).find(
      i => {
        // adding days to the month to avoid timezone issues
        return isSameMonth(addDays(new Date(i.month), 2), month)
      }
    )
    return {
      date: month,
      month: formatMonth(month),
      everythingSharedResponseCount: item
        ? item.everythingSharedResponseCount
        : null,
      everythingSharedResponsePercent: item
        ? item.everythingSharedResponsePercent
        : null,
      responseCount: item ? item.responseCount : null,
    }
  })

  const shareEverythingPercentage = Math.round(
    chartData[chartData.length - 1]?.everythingSharedResponsePercent ?? 0
  )

  const shareAverage =
    data?.company.benchmarkGroup?.shareEverythingPercentage ===
    ShareEverythingPercentage.ABOVE_AVERAGE
      ? "above average"
      : data?.company.benchmarkGroup?.shareEverythingPercentage ===
          ShareEverythingPercentage.BELOW_AVERAGE
        ? "below average"
        : "the average"

  return (
    <div
      className={cn(
        "aspect-golden xl:aspect-auto cursor-default flex flex-col gap-md xl:h-full w-full",
        className
      )}
    >
      <header>
        <h4>How open is your team?</h4>
        <p>
          {shareEverythingPercentage}% of Check-in responses in{" "}
          {format(subMonths(new Date(), 1), "LLLL")} chose to share 'everything'
          with the team. That's {shareAverage} compared to other Spill
          companies.
        </p>
      </header>
      <div
        className="aspect-[2/1] md:aspect-auto sm:h-full grid gap-sm items-end"
        style={{
          gridTemplateColumns: `fit-content(25%) repeat(${numMonths}, 1fr)`,
          gridTemplateRows: "1fr fit-content(50%)",
        }}
      >
        {/* Y axis */}
        <div className="flex flex-col-reverse h-full items-end justify-between">
          {new Array(
            Math.round(upperRange > 50 ? upperRange / 20 : upperRange / 10) + 1
          )
            .fill(null)
            .map((_, index) => (
              <span key={index} className="font-semibold leading-none text-sm">
                {index * (upperRange > 50 ? 20 : 10)}%
              </span>
            ))}
        </div>
        {/* Bars */}
        {chartData.map(i => {
          const tooltipContent =
            i.everythingSharedResponsePercent === null ||
            i.everythingSharedResponseCount === null ||
            i.responseCount === null
              ? ""
              : `${Math.round(i.everythingSharedResponsePercent)}%\n${
                  i.everythingSharedResponseCount
                } of ${i.responseCount} ${inflect("response", i.responseCount)}`
          return (
            <Tooltip
              key={i.month}
              content={tooltipContent}
              followMouse
              id={`${i.month}`}
            >
              <div
                className="cursor-help grow bg-blue-400 hover:bg-blue-400/80 rounded-md transition-colors"
                onMouseEnter={() =>
                  track("Admin hover a bar in the share percentage chart", {
                    monthHovered: i.month,
                    percentageShared: i.everythingSharedResponsePercent,
                  })
                }
                style={{
                  height: `${
                    i.everythingSharedResponsePercent === null
                      ? 0
                      : (i.everythingSharedResponsePercent / upperRange) * 100
                  }%`,
                }}
              />
            </Tooltip>
          )
        })}
        {/* X axis */}
        <span />
        {chartData.map(i => (
          <span key={i.month} className="leading-none text-center w-full">
            {format(i.date, "LLL")}
          </span>
        ))}
      </div>
    </div>
  )
}

PulseResponseSharePercentage.fragments = fragments
PulseResponseSharePercentage.queries = queries
PulseResponseSharePercentage.variables = variables
