import { ColumnDef } from "@tanstack/react-table"
import { DataTable, Indicator } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { isPast, isToday } from "date-fns"

import { AdminReadableUserTherapyPackage } from "types/graphql"

export type AdminTherapySpecialisedSupportTableRow =
  AdminReadableUserTherapyPackage

type AdminTherapySpecialisedSupportTableProps = {
  data: AdminTherapySpecialisedSupportTableRow[]
}

export const AdminTherapySpecialisedSupportTable: FunctionComponent<
  AdminTherapySpecialisedSupportTableProps
> = ({ data }: AdminTherapySpecialisedSupportTableProps) => {
  const columns: ColumnDef<AdminTherapySpecialisedSupportTableRow>[] = [
    {
      accessorKey: "userDisplayName",
      header: "Name",
      cell: ({ row }) => {
        const invite = row.original
        return <span className="block py-2">{invite.userDisplayName}</span>
      },
    },
    {
      accessorKey: "numberOfSessions",
      header: "Sessions",
      cell: ({ row }) => {
        const invite = row.original
        return <span className="block py-2">{invite.numberOfSessions}</span>
      },
    },
    {
      accessorKey: "expirationDate",
      header: "Expiry date",
      cell: ({ row }) => {
        const invite = row.original
        const date =
          invite.expirationDate != null ? new Date(invite.expirationDate) : null
        return (
          <span className="block py-2">{date?.toDateString() ?? "N/A"}</span>
        )
      },
    },
    {
      accessorKey: "createdAt",
      header: "Start date",
      cell: ({ row }) => {
        const invite = row.original
        const date =
          invite.createdAt != null ? new Date(invite.createdAt) : null
        return (
          <span className="block py-2">{date?.toDateString() ?? "N/A"}</span>
        )
      },
    },
    {
      header: "Status",
      cell: ({ row }) => {
        const invite = row.original
        const date =
          invite.expirationDate != null ? new Date(invite.expirationDate) : null
        const isExpired = date != null ? isPast(date) || isToday(date) : false
        return (
          <Indicator variant={isExpired ? "info" : "ok"}>
            {isExpired ? "Finished" : "Active"}
          </Indicator>
        )
      },
    },
  ]

  return <DataTable data={data} columns={columns} showPagination={true} />
}
