import { Category, Level, MediaType, Resource } from "../types"

export const RESOURCES: Resource[] = [
  {
    title: "Why Romantics are Ruining Love",
    subtitle: null,
    authors: ["Alain de Botton"],
    category: Category.Relationships,
    mediaType: MediaType.Video,
    durationInMinutes: 20,
    numOfEpisodes: null,
    description:
      "This Google Zeitgeist talk sees London-based philosopher and writer Alain de Botton put forward an intriguing argument - romanticism is killing love and reviewing our expectations might just be the key to better relationships. Watch if you want to feel optimistic.",
    sourceUrl:
      "https://www.youtube.com/watch?v=R2v8TywXjLA&list=PLHMsX2GvtDqeCL2aqEw85iHko49Z7cqB0",
    level: Level.Starter,
  },
  {
    title: "On Sex",
    subtitle: null,
    authors: ["Alain de Botton"],
    category: Category.Relationships,
    mediaType: MediaType.Video,
    durationInMinutes: 15,
    numOfEpisodes: null,
    description:
      "We might be living in liberated times, but our societal relationship with sex is far from perfect. Even while sex is everywhere, good sex often feels harder and harder to attain. Why? Watch if you want to feel emotionally woke.",
    sourceUrl:
      "https://www.youtube.com/watch?v=osd9AKRCFRM&list=PLHMsX2GvtDqeCL2aqEw85iHko49Z7cqB0",
    level: Level.Starter,
  },
  {
    title: "The secret to desire in a long-term relationship",
    subtitle: null,
    authors: ["Esther Perel"],
    category: Category.Relationships,
    mediaType: MediaType.Video,
    durationInMinutes: 19,
    numOfEpisodes: null,
    description:
      "In this classic TED Talk, our old-time favourite Esther Perel brings a clear-headed understanding to the challenges faced by modern relationships. A must watch if you are or ever want to be in a long-term relationship. Watch if you want to feel hopeful.",
    sourceUrl: "https://www.youtube.com/watch?v=sa0RUmGTCYY",
    level: Level.Starter,
  },
  {
    title: "Status Anxiety",
    subtitle: null,
    authors: ["Alain de Botton"],
    category: Category.Relationships,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "An accessible non-fiction analysis of this central emotion in human life, its history and its impact in the modern world. Read if you want to feel inspired and knowledgeable.",
    sourceUrl: "https://www.goodreads.com/book/show/23425.Status_Anxiety",
    level: Level.Starter,
  },
  {
    title: "Mating in Captivity",
    subtitle: "Reconciling the Erotic and the Domestic",
    authors: ["Esther Perel"],
    category: Category.Relationships,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This non-fiction book by the famed psychotherapist is a must read for anyone considering having, or staying in, a relationship. Esther's TED talks and podcasts are also incredible. Read if you want to feel inspired and knowledgeable.",
    sourceUrl: "https://www.goodreads.com/book/show/27485.Mating_in_Captivity",
    level: Level.Starter,
  },
  {
    title: "Impro",
    subtitle: null,
    authors: ["Keith Johnstone"],
    category: Category.Relationships,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This short non-fiction account of Mr Johnstone's groundbreaking technique for training improvisation theatre actors is mandatory reading in many forward-looking organisations. If you live in London, you can also attend impro workshops that use these techniques. Read if you want to feel self-aware.",
    sourceUrl: "https://www.goodreads.com/book/show/306940.Impro",
    level: Level.Starter,
  },
  {
    title: "Where do We Begin",
    subtitle: null,
    authors: ["Esther Perel"],
    category: Category.Relationships,
    mediaType: MediaType.Podcast,
    durationInMinutes: 42,
    numOfEpisodes: 20,
    description:
      "This podcast is one of the best relationship guides out there: heartwarming and humbling stories with real couples. Watch if you want to feel inspired and hopeful.",
    sourceUrl: "https://open.spotify.com/show/3fKOTwtnX5oZLaiNntKWAV",
    level: Level.Advanced,
  },
  {
    title: "Modern Love",
    subtitle: null,
    authors: ["Amazon"],
    category: Category.Relationships,
    mediaType: MediaType.Series,
    durationInMinutes: 30,
    numOfEpisodes: 8,
    description:
      "This heart-warming and wise collection of stories might just give you a bit of inspiration when it comes to love in the modern world. Watch if you want to feel happy.",
    sourceUrl: "https://www.youtube.com/watch?v=m3MjhYFpJMk",
    level: Level.Advanced,
  },
  {
    title: "On Becoming a Person",
    subtitle: null,
    authors: ["Carl Rogers"],
    category: Category.Relationships,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This twentieth century classic by the father of the person centred psychoanalytic school of thought is a heart-warming guide into how to be less judgemental, with yourself as well as others. Read if you want to feel good.",
    sourceUrl:
      "https://www.goodreads.com/book/show/174879.On_Becoming_a_Person",
    level: Level.Advanced,
  },
  {
    title: "The Mom Test",
    subtitle:
      "How to talk to customers & learn if your business is a good idea when everyone is lying to you by Rob Fitzpatrick",
    authors: ["Rob Fitzpatrick"],
    category: Category.Relationships,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "Although this short non-fiction business hack book is nothing to do with emotions, it's definitely a good tool for anyone dealing with other humans. Read if you want to feel powerful.",
    sourceUrl: "https://www.goodreads.com/book/show/47883410-the-mom-test",
    level: Level.Advanced,
  },
  {
    title: "Heavyweight",
    subtitle: null,
    authors: ["Jonathan Goldstein"],
    category: Category.Relationships,
    mediaType: MediaType.Podcast,
    durationInMinutes: 40,
    numOfEpisodes: 35,
    description:
      "All four seasons of this Gimlet podcast, which you can find on Spotify, are worth a listen. Each episode is a funny heartwarming account of reconnection and overcoming. Listen if you want to feel happy.",
    sourceUrl: "https://gimletmedia.com/shows/heavyweight",
    level: Level.Advanced,
  },
  {
    title: "This is Water",
    subtitle: null,
    authors: ["David Foster Wallace"],
    category: Category.Self,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This short commencement address is an unsentimental analysis of the emotional intelligence required to live and thrive in 'the trenches of daily adult existence'. Available in both print and on YouTube. Read if you want to feel awed.",
    sourceUrl: "https://www.goodreads.com/book/show/5986375-this-is-water",
    level: Level.Starter,
  },
  {
    title: "Paper Towns",
    subtitle: null,
    authors: ["John Green"],
    category: Category.Self,
    mediaType: MediaType.Video,
    durationInMinutes: 18,
    numOfEpisodes: null,
    description:
      "This funny and charming TED Talk is an ode to learning and the incredible superpowers that it can grant us when we adopt it as a habit. It's also an introduction to the world of online learning, which - if you don't already - you should really know about, as it's the future. Watch if you want to feel inspired and clued in.",
    sourceUrl:
      "https://www.youtube.com/watch?v=NgDGlcxYrhQ&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT&index=18&t=0s",
    level: Level.Starter,
  },
  {
    title: "Why You Feel What You Feel",
    subtitle: null,
    authors: ["Alan Watkins"],
    category: Category.Self,
    mediaType: MediaType.Video,
    durationInMinutes: 20,
    numOfEpisodes: null,
    description:
      "This informative and funny TED Talk is a basic lesson in developmental psychology. It's the first thing you need to learn before you can begin to transform your own reality, like Neo from The Matrix. Watch if you want to feel empowered.",
    sourceUrl:
      "https://www.youtube.com/watch?v=h-rRgpPbR5w&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT",
    level: Level.Starter,
  },
  {
    title: "Men, Women and Worthiness",
    subtitle: null,
    authors: ["Brené Brown"],
    category: Category.Self,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This non-fiction overview of the research on shame sheds light onto this often destructive emotion and shows how liberating it can be to let it go. This author's TED talks are also recommended. Read if you want to feel uplifted, inspired, and knowledgeable.",
    sourceUrl:
      "https://www.goodreads.com/book/show/15894631-men-women-and-worthiness",
    level: Level.Starter,
  },
  {
    title: "The Blank Slate",
    subtitle: null,
    authors: ["Steven Pinker"],
    category: Category.Self,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This non-fiction overview of the latest on our understanding of the human mind will help you see yourself as never before. Read if you want to feel awed.",
    sourceUrl: "https://www.goodreads.com/it/book/show/5752.The_Blank_Slate",
    level: Level.Starter,
  },
  {
    title: "Thinking, Fast and Slow",
    subtitle: null,
    authors: ["Daniel Kahneman"],
    category: Category.Self,
    mediaType: MediaType.Video,
    durationInMinutes: 61,
    numOfEpisodes: null,
    description:
      "This Talk at Google by Nobel Prize winner Daniel Kahneman is an overview of his groundbreaking book of the same title. The last few decades have revolutionised our understanding of the mind. Read if you want to feel both clever and wise.",
    sourceUrl:
      "https://www.youtube.com/watch?v=CjVQJdIrDJ0&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT",
    level: Level.Advanced,
  },
  {
    title: "The Science of Self-Compassion",
    subtitle: null,
    authors: ["Kristin Neff"],
    category: Category.Self,
    mediaType: MediaType.Video,
    durationInMinutes: 41,
    numOfEpisodes: null,
    description:
      "Feeling like all this talk about self-compassion is just touchy-feely hippy nonsense? Well, hold that thought. Maybe some hard cold science in this clear-minded Talk at Google will change your mind. Watch if you want to feel challenged.",
    sourceUrl:
      "https://www.youtube.com/watch?v=y0gtnOXAp-U&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT",
    level: Level.Advanced,
  },
  {
    title: "Crash Course Psychology>",
    subtitle: null,
    authors: ["Crash Course"],
    category: Category.Self,
    mediaType: MediaType.Series,
    durationInMinutes: 10,
    numOfEpisodes: 40,
    description:
      "This introductory ‘lecture’ series is a collection of videos designed to teach you the basic concept of psychology in fun-to-watch 10min videos. Watch if you want to learn about the mind.",
    sourceUrl:
      "https://www.youtube.com/playlist?list=PL8dPuuaLjXtOPRKzVLY0jJY-uHOH9KVU6",
    level: Level.Advanced,
  },
  {
    title: "When Breath Becomes Air",
    subtitle: null,
    authors: ["Paul Kalanithi"],
    category: Category.Self,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "An uplifting memoir of the author's rise to the top of his profession and what it all meant when he was diagnosed with stage IV metastatic lung cancer. Read if you want to feel a new appreciation for being alive.",
    sourceUrl:
      "https://www.goodreads.com/book/show/25899336-when-breath-becomes-air",
    level: Level.Advanced,
  },
  {
    title: "Reasons to Stay Alive",
    subtitle: null,
    authors: ["Matt Haig"],
    category: Category.Self,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "A memoir of the author's battle with major anxiety and depression and how he builds a life in spite of them. Read if you want to feel inspired.",
    sourceUrl:
      "https://www.goodreads.com/book/show/25733573-reasons-to-stay-alive",
    level: Level.Advanced,
  },
  {
    title: "The Body Keeps the Score",
    subtitle: null,
    authors: ["Bessel van der Kolk"],
    category: Category.Self,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This non-fiction account by the Harvard psychiatrist looks at how mind and body are one and why no attempt at changing our emotions can succeed without taking into account 'the brother pig'. Read if you want to feel knowledgeable.",
    sourceUrl:
      "https://www.goodreads.com/book/show/18693771-the-body-keeps-the-score",
    level: Level.Advanced,
  },
  {
    title: "The Happy Secret to Better Work",
    subtitle: null,
    authors: ["Shawn Anchor"],
    category: Category.Work,
    mediaType: MediaType.Video,
    durationInMinutes: 12,
    numOfEpisodes: null,
    description:
      "This fun TED Talk is a great introduction to the concept of positive psychology: don’t succeed so you become happy, but get happy so you can succeed. Watch if you want to feel happier.",
    sourceUrl:
      "https://www.ted.com/talks/shawn_achor_the_happy_secret_to_better_work?language=en",
    level: Level.Starter,
  },
  {
    title: "On Work You Love",
    subtitle: null,
    authors: ["Benjamin Todd"],
    category: Category.Work,
    mediaType: MediaType.Video,
    durationInMinutes: 15,
    numOfEpisodes: null,
    description:
      "Should we follow our ‘passion’ in order to find work we ‘love’? Research shows that’s no certain path to happiness. Instead, try doing what’s valuable. Watch if you want to feel challenged.",
    sourceUrl:
      "https://www.youtube.com/watch?v=MKlx1DLa9EA&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT",
    level: Level.Starter,
  },
  {
    title: "The Habits of Effective Artists",
    subtitle: null,
    authors: ["Andrew Price"],
    category: Category.Work,
    mediaType: MediaType.Video,
    durationInMinutes: 20,
    numOfEpisodes: null,
    description:
      "What are 7 habits that could make the difference between seeing through that art project and not doing so? In conduction with ‘Atomic Habits’, below, this might just make your dream a reality. Watch if you want to feel motivated.",
    sourceUrl:
      "https://www.youtube.com/watch?v=vM39qhXle4g&list=PLHMsX2GvtDqcwLRIGXRVE_joa-OsU3gtT",
    level: Level.Starter,
  },
  {
    title: "The Book of Life",
    subtitle: null,
    authors: ["School of Life"],
    category: Category.Work,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This collection of short articles is a great place to start learning more about our feelings at work. Read if you want to feel informed.",
    sourceUrl: "https://www.theschooloflife.com/thebookoflife/category/work",
    level: Level.Starter,
  },
  {
    title: "The Pleasures and Sorrows of Work",
    subtitle: null,
    authors: ["Alain de Botton"],
    category: Category.Work,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "An accessible non-fiction analysis of the hopes and anxieties we bring to the activity that takes up most of our waking hours. Read if you want to feel inspired and knowledgeable.",
    sourceUrl:
      "https://www.goodreads.com/book/show/5293573-the-pleasures-and-sorrows-of-work",
    level: Level.Starter,
  },
  {
    title: "Lean In",
    subtitle: "Women, Work, and the Will to Lead",
    authors: ["Sheryl Sandberg"],
    category: Category.Work,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This non-fiction account of women's life in the workplace is a good grounding on the topic, whether you are a woman or a man. Read to feel inspired.",
    sourceUrl: "https://www.goodreads.com/book/show/16071764-lean-in",
    level: Level.Starter,
  },
  {
    title: "The Lean Startup",
    subtitle: null,
    authors: ["Eric Ries"],
    category: Category.Work,
    mediaType: MediaType.Video,
    durationInMinutes: 58,
    numOfEpisodes: null,
    description:
      "This Talk at Google is a summary of the book with the same name, by its author. Anyone can be an entrepreneur if they have the right mindset. Watch to feel motivated.",
    sourceUrl: "https://www.youtube.com/watch?v=fEvKo90qBns",
    level: Level.Advanced,
  },
  {
    title: "Atomic Habits",
    subtitle: null,
    authors: ["James Clear"],
    category: Category.Work,
    mediaType: MediaType.Video,
    durationInMinutes: 46,
    numOfEpisodes: null,
    description:
      "Good habits make time work for you: bad habits make time work against you. You are what you do most often. And small changed compound into huge ones. Watch if you want to feel inspired and motivated.",
    sourceUrl: "https://www.youtube.com/watch?v=Q8ApZXWgJq4",
    level: Level.Advanced,
  },
  {
    title: "Fulfilment at Work",
    subtitle: null,
    authors: ["Alain de Botton"],
    category: Category.Work,
    mediaType: MediaType.Video,
    durationInMinutes: 91,
    numOfEpisodes: null,
    description:
      "Work is part of our identity: at dinner parties it’s the first piece of information we reveal about ourselves. Can work be meaningful? How can we make it so? Watch if you want to feel more self-aware.",
    sourceUrl:
      "https://www.youtube.com/watch?v=PyTBza9Nhrk&list=PLHMsX2GvtDqeCL2aqEw85iHko49Z7cqB0",
    level: Level.Advanced,
  },
  {
    title: "Working",
    subtitle:
      "People Talk about What They Do All Day and How They Feel about What They Do",
    authors: ["Studs Terkel"],
    category: Category.Work,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This non-fiction collection of interviews is a heartwarming and inspiring survey of why people do what they do, where they find inspiration, and what keeps them happy in their working lives. Read if you want to feel love for the human kind.",
    sourceUrl: "https://www.goodreads.com/book/show/59649.Working",
    level: Level.Advanced,
  },
  {
    title: "Never Split the Difference",
    subtitle: "Negotiating as if Your Life Depended on It",
    authors: ["Chris Voss"],
    category: Category.Work,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This entertaining non-fiction account of an ex-FBI's manual for negotiating with others makes for a pretty helpful tool in everyday conversation. Read if you want to feel powerful.",
    sourceUrl:
      "https://www.goodreads.com/book/show/26156469-never-split-the-difference",
    level: Level.Advanced,
  },
  {
    title: "Zen and the Art of Motorcycle Maintenance",
    subtitle: "An Inquiry Into Values",
    authors: ["Robert Pirsig"],
    category: Category.Work,
    mediaType: MediaType.Book,
    durationInMinutes: null,
    numOfEpisodes: null,
    description:
      "This American cult-classic is an uplifting account of how to find meaning in the everyday. Read if you want to feel inspired.",
    sourceUrl:
      "https://www.goodreads.com/book/show/629.Zen_and_the_Art_of_Motorcycle_Maintenance",
    level: Level.Advanced,
  },
]
