import { config } from "config"

export type Exercise = {
  title: string
  description: string
  durationMinutes: number
  pdfUrl: string
  imgUrl: string
  imgAltText: string
}

type Exercises = {
  title: string
  exercises: Exercise[]
}

export const EXERCISES: Exercises[] = [
  {
    title: "Relationships",
    exercises: [
      {
        title: "Circles of Connection",
        description: "An exercise to audit your social life",
        durationMinutes: 15,
        pdfUrl: `${config.s3.baseUrl}/exercises/relationships/circles-of-connection.pdf`,
        imgUrl: `${config.s3.baseUrl}/exercises/relationships/circles-of-connection.png`,
        imgAltText: "Circles of connection exercise image",
      },
      {
        title: "Drama Triangle",
        description:
          "An exercise to help you better understand arguments with your partner",
        durationMinutes: 15,
        pdfUrl: `${config.s3.baseUrl}/exercises/relationships/drama-triangle.pdf`,
        imgUrl: `${config.s3.baseUrl}/exercises/relationships/drama-triangle.png`,
        imgAltText: "Drama triangle exercise image",
      },
    ],
  },
  {
    title: "Hobbies and Habits",
    exercises: [
      {
        title: "100 Blocks Exercise",
        description: "An exercise to audit how you spend your free time",
        durationMinutes: 15,
        pdfUrl: `${config.s3.baseUrl}/exercises/hobbies-and-habits/100-blocks.pdf`,
        imgUrl: `${config.s3.baseUrl}/exercises/hobbies-and-habits/100-blocks.png`,
        imgAltText: "100 Blocks Exercise",
      },
      {
        title: "Habit Change Framework",
        description: "An exercise to help you change a bad habit",
        durationMinutes: 10,
        pdfUrl: `${config.s3.baseUrl}/exercises/hobbies-and-habits/habit-change.pdf`,
        imgUrl: `${config.s3.baseUrl}/exercises/hobbies-and-habits/habit-change.png`,
        imgAltText: "Habit Change Framework",
      },
    ],
  },
  {
    title: "Self Understanding",
    exercises: [
      {
        title: "5 Whys Exercise",
        description: "An exercise to work out what’s behind your emotions",
        durationMinutes: 20,
        pdfUrl: `${config.s3.baseUrl}/exercises/self-understanding/5-whys.pdf`,
        imgUrl: `${config.s3.baseUrl}/exercises/self-understanding/5-whys.png`,
        imgAltText: "5 Whys Exercise",
      },
      {
        title: "Top tail learning",
        description: "An exercise to develop gratitude and awareness",
        durationMinutes: 10,
        pdfUrl: `${config.s3.baseUrl}/exercises/self-understanding/top-tail-learning.pdf`,
        imgUrl: `${config.s3.baseUrl}/exercises/self-understanding/top-tail-learning.png`,
        imgAltText: "Top tail learning",
      },
    ],
  },
  {
    title: "Supporting Others",
    exercises: [
      {
        title: "Depression checklist",
        description:
          "A checklist to help support someone you know with depression",
        durationMinutes: 2,
        pdfUrl: `${config.s3.baseUrl}/exercises/supporting-others/depression-support-checklist.pdf`,
        imgUrl: `${config.s3.baseUrl}/exercises/supporting-others/depression-support-checklist.png`,
        imgAltText: "Depression checklist",
      },
      {
        title: "Anxiety Checklist",
        description:
          "A checklist to help support someone you know with anxiety",
        durationMinutes: 2,
        pdfUrl: `${config.s3.baseUrl}/exercises/supporting-others/anxiety-support-checklist.pdf`,
        imgUrl: `${config.s3.baseUrl}/exercises/supporting-others/anxiety-support-checklist.png`,
        imgAltText: "Anxiety Checklist",
      },
    ],
  },
]
