import { ChevronDownIcon } from "@heroicons/react/24/outline"
import cn from "classnames"

import type { ComponentProps } from "react"

export function Select({
  children,
  className,
  disabled,
  onChange,
  ...rest
}: ComponentProps<"select">): JSX.Element {
  return (
    <div
      className={cn(
        "btn btn-gray btn-sm cursor-pointer sm:btn-md relative w-full",
        className ?? "",
        { disabled }
      )}
      style={{ padding: 0 }}
    >
      <select
        className="appearance-none bg-transparent border-none cursor-[inherit] h-full leading-5 outline-none pl-3 lg:pl-4 pr-6 lg:pr-8 rounded-lg w-full"
        disabled={disabled}
        onChange={onChange}
        {...rest}
      >
        {children}
      </select>
      <div className="absolute aspect-square flex h-full items-center justify-center pointer-events-none pr-3 lg:pr-4 right-0 top-0">
        <ChevronDownIcon />
      </div>
    </div>
  )
}
