import { z } from "zod"

import { sizeCardTitles } from "./QuoteSignupSize"

export const formSchema = z
  .object({
    firstName: z.string().min(1, {
      message: "Please enter a first name",
    }),
    size: z
      .number()
      .min(0)
      .max(sizeCardTitles.length - 1),
    types: z.array(z.string()),
    email: z.string().email({ message: "Please enter a valid email" }),
    companyName: z.string().min(1, {
      message: "Please enter a company name",
    }),
    numberEmployees: z.number({ message: "Please enter a number" }).min(0),
  })
  .refine(data => data.size <= data.numberEmployees, {
    message:
      "Number of employees must be at least equal to the number chosen on the previous screen",
    path: ["numberEmployees"],
  })

export type QuoteSignupForm = z.infer<typeof formSchema>
