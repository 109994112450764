import { Button, Form, H1, H4, P, cn } from "@spillchat/puddles"
import { useEffect } from "react"

import { MultiStepFormStepProps } from "common/components/MultiStepForm/MultiStepFormStepProps"

import { QuoteSignupForm } from "./QuoteSignup.schema"

export const QuoteSignupType: React.FunctionComponent<
  MultiStepFormStepProps<QuoteSignupForm>
> = ({ form, reverseAction }: MultiStepFormStepProps<QuoteSignupForm>) => {
  useEffect(() => {
    form.clearErrors("types")
  }, [])

  const typeMap = [
    { label: "Bereavement", value: "bereavement" },
    { label: "Low mood", value: "lowMood" },
    { label: "Anxiety", value: "anxiety" },
    { label: "Traumatic event", value: "traumaticEvent" },
    { label: "Relationship breakdown", value: "relationshipBreakdown" },
    { label: "Stress", value: "stress" },
    { label: "General mental health", value: "generalMentalHealth" },
  ]

  form.watch("types")

  const struggleCount = +form.getValues("size")

  return (
    <div className="flex flex-col items-center gap-8">
      <H1>What can Spill support your team with?</H1>
      {struggleCount !== 1 ? (
        <>
          <H4>
            We use this to recommend which features to activate on your plan and
            never share this information with anyone else.
          </H4>
        </>
      ) : (
        <>
          <H4>
            We use this to estimate how many sessions your team may need and
            never share this information with anyone else.
          </H4>
        </>
      )}
      <div className="flex flex-col items-center gap-8 max-w-lg w-full">
        {struggleCount !== 1 ? (
          <P>Select any options that apply</P>
        ) : (
          <P>Select the most relevant option</P>
        )}
        <Form.Field
          control={form.control}
          name="types"
          render={({ field }) => (
            <Form.Item className="w-full">
              <Form.Control>
                <div className="flex flex-wrap gap-4">
                  {typeMap.map(type => {
                    return (
                      <div key={type.value}>
                        <Button
                          asChild
                          tabIndex={0}
                          onClick={() => {
                            const newArray: string[] = [...field.value]

                            if (struggleCount !== 1) {
                              if (newArray.indexOf(type.value) !== -1) {
                                const value = newArray.indexOf(type.value)
                                newArray.splice(value, 1)
                              } else {
                                newArray.push(type.value)
                              }
                            } else {
                              newArray.length = 0
                              newArray.push(type.value)
                            }

                            form.setValue("types", newArray)
                          }}
                          variant={
                            field.value.includes(type.value)
                              ? "primary"
                              : "secondary"
                          }
                        >
                          <span
                            className={cn([
                              "whitespace-nowrap",
                              field.value.includes(type.value)
                                ? "!bg-yellow-100 text-mono-black"
                                : "",
                            ])}
                          >
                            {type.label}
                          </span>
                        </Button>
                      </div>
                    )
                  })}
                </div>
              </Form.Control>
              <Form.Message />
            </Form.Item>
          )}
        />
        <div className="flex gap-2">
          <Button type="button" variant="secondary" onClick={reverseAction}>
            Back
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={form.getValues("types").length === 0}
            loading={form.formState.isSubmitting}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}
