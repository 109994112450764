import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { signInAndUp } from "supertokens-auth-react/recipe/thirdparty"

async function handleLoginCallback(
  navigate: (path: string) => void,
  onSuccessRedirectURL: string
) {
  try {
    const response = await signInAndUp()

    if (response.status === "OK") {
      window.location.assign(onSuccessRedirectURL)
    } else {
      // SuperTokens requires that the third party provider gives an email
      // for the user. If that's not the case, sign up / in will fail.

      toast.error(
        "There was an issuing signing in. Please contact Spill support at support@spill.chat."
      )
      navigate("/auth") // redirect back to login page
    }
  } catch (err: unknown) {
    if (
      (err as { isSuperTokensGeneralError?: boolean })
        ?.isSuperTokensGeneralError === true
    ) {
      // this may be a custom error message sent from the API by you.
      toast.error((err as { message: string }).message)
    } else {
      toast.error(
        "Oops! Something went wrong. We were not able to sign you in. Please contact Spill support at support@spill.chat."
      )
    }
    navigate("/auth")
  }
}

export function SuperTokensCallback(): JSX.Element {
  const navigate = useNavigate()

  const onSuccessRedirectURL = localStorage.getItem("postLoginRedirect")

  /**
   * The purpose of this component is to invoke `signInAndUp` once
   * to exchange the exchange token issued by Microsoft for a
   * Supertokens access token.
   *
   * However, due to react v18's new development "feature" (explained
   * [here](https://byby.dev/useeffect-run-twice)), when in <StrictMode />
   * and in a development environment, components are mounted and unmounted
   * once before they actually mount. This is to try and catch production
   * issues when components aren't properly cleaned up.
   *
   * Hence this weird useEffect. If we invoke `signInAndUp` twice, things
   * do work as expected but the 2nd invocation causes a backend issue
   * where the exchange token has already been redeemed.
   */
  useEffect(() => {
    let unmounted = false

    setTimeout(
      () =>
        !unmounted &&
        void handleLoginCallback(navigate, onSuccessRedirectURL ?? "/"),
      1
    )

    return () => void (unmounted = true)
  }, [])

  return <></>
}
