/**
 * An animated version of the 'light rain' icon that can be found as a static SVG in
 * /src/common/assets/images/moods/3.svg
 **/

import React from "react"
import { motion, useAnimation } from "framer-motion"

import { AnimatedIconProps } from "./types"
import { rain } from "./animations"

export const LightRain: React.FunctionComponent<AnimatedIconProps> = props => {
  // We have two different animations for rain, so we can offset some of the paths
  const rainAnimation = useAnimation()
  const rainAnimation2 = useAnimation()

  // Create an array of the animations, so we can orchestrate them nicer.
  const rainAnimations = [rainAnimation, rainAnimation2]

  React.useEffect(() => {
    if (props.isAnimating) {
      rainAnimation.start(rain(1.75)).catch(() => {})
      rainAnimation2.start(rain(1.75, 0.5)).catch(() => {})
    } else {
      rainAnimations.forEach(animation => animation.stop())
    }
  }, [props.isAnimating])

  return (
    <motion.svg
      width="49"
      height="52"
      viewBox="0 0 49 52"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path d="M39.8718 38.3517H8.93951C6.70382 38.3986 4.54109 37.5554 2.92709 36.0076C1.31309 34.4599 0.380038 32.3344 0.333183 30.0987C0.286327 27.863 1.12953 25.7003 2.67727 24.0863C4.225 22.4723 6.35051 21.5392 8.5862 21.4924C8.47734 20.8656 8.42329 20.2306 8.42467 19.5944C8.42147 17.1238 9.20852 14.717 10.6708 12.7257C12.1332 10.7343 14.1941 9.2629 16.5523 8.52644C18.9106 7.78997 21.4426 7.82708 23.7783 8.63234C26.1139 9.4376 28.1308 10.9688 29.5341 13.0021C30.8465 12.296 32.3143 11.9282 33.8045 11.932C36.2143 11.932 38.5253 12.8893 40.2292 14.5932C41.9331 16.2971 42.8904 18.6082 42.8904 21.0179C42.89 21.3553 42.8698 21.6924 42.8298 22.0274C44.6868 22.7153 46.2423 24.035 47.2236 25.755C48.2049 27.4751 48.5493 29.4857 48.1964 31.4342C47.8435 33.3828 46.8159 35.145 45.2938 36.4117C43.7716 37.6784 41.8521 38.3688 39.8718 38.3618V38.3517ZM8.93951 24.0061C7.41412 24.0692 5.97216 24.7195 4.91515 25.8211C3.85814 26.9227 3.26793 28.3903 3.26793 29.917C3.26793 31.4437 3.85814 32.9113 4.91515 34.0129C5.97216 35.1145 7.41412 35.7648 8.93951 35.8278H39.8718C41.326 35.8197 42.7263 35.2762 43.8052 34.3012C44.8842 33.3261 45.5661 31.9879 45.721 30.5419C45.8758 29.0959 45.4927 27.6436 44.6446 26.4622C43.7966 25.2809 42.5432 24.4532 41.1237 24.1374L39.8416 23.8648L40.1646 22.6029C40.2945 22.081 40.3622 21.5456 40.3665 21.0078C40.3639 19.2692 39.6713 17.6027 38.441 16.3743C37.2107 15.1458 35.5431 14.4559 33.8045 14.4559C32.3908 14.4551 31.0154 14.9159 29.8875 15.7683L28.7063 16.6466L28.0097 15.3443C27.0469 13.5796 25.5262 12.1841 23.6855 11.3762C21.8448 10.5683 19.7881 10.3936 17.8375 10.8795C15.887 11.3655 14.1526 12.4845 12.906 14.0615C11.6594 15.6385 10.971 17.5844 10.9485 19.5944C10.9471 20.5335 11.0936 21.467 11.3826 22.3606L11.9076 24.0061H8.93951Z" />
      <motion.path
        animate={rainAnimation}
        d="M11.5594 40.5479C11.2247 40.5479 10.9038 40.6808 10.6671 40.9175C10.4304 41.1541 10.2975 41.4751 10.2975 41.8098V49.8861C10.2975 50.2208 10.4304 50.5418 10.6671 50.7784C10.9038 51.0151 11.2247 51.148 11.5594 51.148C11.8941 51.148 12.2151 51.0151 12.4517 50.7784C12.6884 50.5418 12.8213 50.2208 12.8213 49.8861V41.8098C12.8213 41.4751 12.6884 41.1541 12.4517 40.9175C12.2151 40.6808 11.8941 40.5479 11.5594 40.5479Z"
      />
      <motion.path
        animate={rainAnimation2}
        d="M22.4824 40.5479C22.1477 40.5479 21.8268 40.6808 21.5901 40.9175C21.3534 41.1541 21.2205 41.4751 21.2205 41.8098V49.8861C21.2205 50.2208 21.3534 50.5418 21.5901 50.7784C21.8268 51.0151 22.1477 51.148 22.4824 51.148C22.8171 51.148 23.1381 51.0151 23.3747 50.7784C23.6114 50.5418 23.7443 50.2208 23.7443 49.8861V41.8098C23.7443 41.4751 23.6114 41.1541 23.3747 40.9175C23.1381 40.6808 22.8171 40.5479 22.4824 40.5479Z"
      />
      <motion.path
        animate={rainAnimation}
        d="M33.4054 40.5479C33.0707 40.5479 32.7498 40.6808 32.5131 40.9175C32.2765 41.1541 32.1435 41.4751 32.1435 41.8098V43.8289C32.1435 44.1635 32.2765 44.4845 32.5131 44.7212C32.7498 44.9578 33.0707 45.0908 33.4054 45.0908C33.7401 45.0908 34.0611 44.9578 34.2977 44.7212C34.5344 44.4845 34.6673 44.1635 34.6673 43.8289V41.8098C34.6673 41.4751 34.5344 41.1541 34.2977 40.9175C34.0611 40.6808 33.7401 40.5479 33.4054 40.5479Z"
      />
      <motion.path
        animate={rainAnimation2}
        d="M33.4054 46.0597C33.0689 46.065 32.7479 46.2025 32.5119 46.4423C32.2758 46.6822 32.1435 47.0053 32.1435 47.3419C32.1435 47.6765 32.2765 47.9975 32.5131 48.2342C32.7498 48.4708 33.0707 48.6038 33.4054 48.6038C33.7401 48.6038 34.0611 48.4708 34.2977 48.2342C34.5344 47.9975 34.6673 47.6765 34.6673 47.3419C34.6728 47.1737 34.6441 47.0062 34.5829 46.8495C34.5217 46.6928 34.4293 46.5502 34.3113 46.4303C34.1932 46.3103 34.0521 46.2157 33.8964 46.152C33.7407 46.0883 33.5736 46.0569 33.4054 46.0597Z"
      />
      <motion.path
        animate={rainAnimation}
        d="M17.0209 40.5479C16.6862 40.5479 16.3652 40.6808 16.1286 40.9175C15.8919 41.1541 15.759 41.4751 15.759 41.8098V43.8289C15.759 44.1635 15.8919 44.4845 16.1286 44.7212C16.3652 44.9578 16.6862 45.0908 17.0209 45.0908C17.3556 45.0908 17.6766 44.9578 17.9132 44.7212C18.1499 44.4845 18.2828 44.1635 18.2828 43.8289V41.8098C18.2828 41.4751 18.1499 41.1541 17.9132 40.9175C17.6766 40.6808 17.3556 40.5479 17.0209 40.5479Z"
      />
      <motion.path
        animate={rainAnimation2}
        d="M17.0209 46.0597C16.6844 46.065 16.3634 46.2025 16.1273 46.4423C15.8913 46.6822 15.7589 47.0053 15.759 47.3419C15.759 47.6765 15.8919 47.9975 16.1286 48.2342C16.3652 48.4708 16.6862 48.6038 17.0209 48.6038C17.3556 48.6038 17.6766 48.4708 17.9132 48.2342C18.1499 47.9975 18.2828 47.6765 18.2828 47.3419C18.2883 47.1737 18.2596 47.0062 18.1984 46.8495C18.1372 46.6928 18.0448 46.5502 17.9267 46.4303C17.8087 46.3103 17.6676 46.2157 17.5119 46.152C17.3562 46.0883 17.1891 46.0569 17.0209 46.0597Z"
      />
      <motion.path
        animate={rainAnimation}
        d="M38.8674 40.5479C38.7013 40.5465 38.5366 40.5783 38.3829 40.6412C38.2292 40.7042 38.0895 40.797 37.9721 40.9145C37.8546 41.0319 37.7617 41.1716 37.6988 41.3253C37.6359 41.479 37.6041 41.6437 37.6055 41.8098V45.848C37.6055 46.1827 37.7384 46.5036 37.9751 46.7403C38.2117 46.977 38.5327 47.1099 38.8674 47.1099C39.2021 47.1099 39.5231 46.977 39.7597 46.7403C39.9964 46.5036 40.1293 46.1827 40.1293 45.848V41.8098C40.1293 41.4751 39.9964 41.1542 39.7597 40.9175C39.5231 40.6808 39.2021 40.5479 38.8674 40.5479Z"
      />
      <motion.path
        animate={rainAnimation2}
        d="M27.9742 40.5479C27.6395 40.5479 27.3185 40.6808 27.0819 40.9175C26.8452 41.1541 26.7123 41.4751 26.7123 41.8098V45.8479C26.7123 46.1826 26.8452 46.5036 27.0819 46.7403C27.3185 46.9769 27.6395 47.1099 27.9742 47.1099C28.3089 47.1099 28.6299 46.9769 28.8665 46.7403C29.1032 46.5036 29.2361 46.1826 29.2361 45.8479V41.8098C29.2361 41.4751 29.1032 41.1541 28.8665 40.9175C28.6299 40.6808 28.3089 40.5479 27.9742 40.5479Z"
      />
    </motion.svg>
  )
}
