import { useQuery } from "@apollo/client"
import { FunctionComponent, useState } from "react"
import { Alert, Button, H3, P } from "@spillchat/puddles"
import { Link } from "react-router-dom"
import { format } from "date-fns"

import {
  AdminTherapyNeedsQuery,
  AdminTherapyNeedsQueryVariables,
} from "types/graphql"
import { DowngradeChart } from "features/admin/pages/Therapy/components/DowngradeChart"
import { UsageChart } from "features/admin/pages/Therapy/components/UsageChart"
import { Dialog } from "common/components/Dialog"
import { ChangePlanModal } from "features/admin/pages/Therapy/components/ChangePlanModal"
import { LoadingPage } from "common/components/LoadingPage"

import { queries } from "./admin-therapy-needs.queries"

export const AdminTherapyNeeds: FunctionComponent = () => {
  const [showingChangePlanModal, setShowingChangePlanModal] = useState(false)

  const { data, loading } = useQuery<
    AdminTherapyNeedsQuery,
    AdminTherapyNeedsQueryVariables
  >(queries.getNeedsData)

  const isLegacyPricing = data?.user?.company?.isLegacyPricing
  const yearlyTherapyBudget =
    data?.user?.company?.featuresAndSettings?.yearlyTherapyBudget?.value
  const totalUsage = data?.user?.company?.budgetYearUsage?.totalBillableUsage
  const showBudgetWarning =
    isLegacyPricing === false &&
    yearlyTherapyBudget != null &&
    yearlyTherapyBudget > 0 &&
    totalUsage != null &&
    totalUsage >= yearlyTherapyBudget * 0.8
  const sessionPackActive =
    data?.user?.company?.featuresAndSettings.sessionPack.active ?? false
  const hasTrial =
    sessionPackActive !== null &&
    sessionPackActive !== undefined &&
    data?.user?.company?.trialStartDate !== null
  const isBudgetLocked = data?.user?.company?.lockedBudget

  const suggestDowngrade = data?.user?.company?.billingAdvice == true

  if (loading == true) {
    return <LoadingPage />
  }

  const renderAppropriateChart = ({
    setShowingChangePlanModal,
  }: {
    setShowingChangePlanModal: (newValue: boolean) => void
  }) => {
    if (suggestDowngrade) return <DowngradeChart />
    return (
      <UsageChart
        hidePositiveFeedback={showBudgetWarning}
        setShowingChangePlanModal={setShowingChangePlanModal}
      />
    )
  }

  return (
    <div className="flex flex-col md:flex-row mt-4 gap-8">
      <div className="flex flex-col gap-6 md:w-2/3 sticky top-32">
        <div className="mt-2 flex flex-col gap-2">
          {renderAppropriateChart({ setShowingChangePlanModal })}

          {suggestDowngrade && (
            <Button onClick={() => setShowingChangePlanModal(true)}>
              Change plan
            </Button>
          )}
        </div>
        {showBudgetWarning && (
          <Alert
            title="Your company is above 80% of your therapy budget."
            variant="warning"
          >
            {isBudgetLocked == true ? (
              <P size="xs">
                Once you hit your budget, employees will not be able to book
                therapy sessions unless they pay privately.{" "}
                {data?.user?.company?.therapyBudgetResetDate != null
                  ? `Your budget resets
                on ${format(
                  new Date(data?.user?.company?.therapyBudgetResetDate),
                  "d MMM yyyy"
                )}. `
                  : ""}
                <Link className="underline" to="/admin/help">
                  Contact us
                </Link>{" "}
                to increase your budget.
              </P>
            ) : (
              <P size="xs">
                Go to{" "}
                <Link className="underline" to="admin/therapy/settings">
                  Therapy Settings
                </Link>{" "}
                to increase your budget. If you don’t want to, employees who
                want more therapy can book privately.{" "}
                {data?.user?.company?.therapyBudgetResetDate != null
                  ? `Your budget resets on ${format(
                      new Date(data?.user?.company?.therapyBudgetResetDate),
                      "d MMM yyyy"
                    )}.`
                  : ""}
              </P>
            )}
          </Alert>
        )}
        {hasTrial && (
          <div className="flex flex-col gap-8 mt-8">
            <div className="flex flex-col gap-2">
              <H3>Want to keep using Spill after your trial?</H3>
              <P>
                Great news! You can continue with our Essential Plan to cover
                your whole team. Book a call below to set up or drop us an email
                on{" "}
                <a className="underline" href="mailto:hi@spill.chat">
                  hi@spill.chat
                </a>
                .
              </P>
            </div>
            <div className="p-6 bg-grey-100 rounded-lg max-w-sm">
              <div className="flex flex-col gap-4">
                <H3>Book a demo to learn more</H3>
                <P>
                  If you'd like to see exactly how the plan works, you can book
                  in a quick demo with someone from Spill.
                </P>
                <Button asChild variant="secondary">
                  <Link
                    to="https://www.spill.chat/book-demo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book a demo
                  </Link>
                </Button>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <H3>If you don't want to buy Spill</H3>
              <P>
                If you don’t want to continue with Spill, you don’t need to do
                anything. Your trial will expire after 30 days.
              </P>
            </div>
          </div>
        )}
        {!hasTrial && sessionPackActive && (
          <Button asChild>
            <Link to="/admin/session-pack">Change session pack</Link>
          </Button>
        )}
      </div>
      <Dialog
        isOpen={showingChangePlanModal}
        maxWidth="5xl"
        canClose
        onClose={() => setShowingChangePlanModal(false)}
      >
        <ChangePlanModal
          onCloseModal={() => setShowingChangePlanModal(false)}
        />
      </Dialog>
    </div>
  )
}
