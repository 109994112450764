import { gql } from "@apollo/client"

export const mutations = {
  turnOffPmiSetting: gql`
    mutation PmiSettingsTurnOffPmiSetting($userTherapyCap: Int) {
      updateCompanySettings(pmi: false, userTherapyCap: $userTherapyCap) {
        id
        pmi {
          active
          value
        }
      }
    }
  `,
}
