import { ColumnDef } from "@tanstack/react-table"
import { Button, DataTable } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import { SpillSubscriptionPlan } from "types/graphql"

import { SupportTableAction } from "./SupportTableAction"
import { SupportTableLink } from "./SupportTableLink"

export type SupportTableRow = {
  id: string
  userId: string
  fullName: string
  inviteUrl?: string | null
}

type SupportTableProps = {
  hasActiveSubscription: boolean
  data: SupportTableRow[]
  canInviteUsers: boolean
  subscriptionPlan: SpillSubscriptionPlan
}

export const SupportTable: FunctionComponent<SupportTableProps> = ({
  data,
  hasActiveSubscription,
  canInviteUsers,
  subscriptionPlan,
}: SupportTableProps) => {
  const SupportTableActions: FunctionComponent = () => {
    return (
      <div className="flex justify-end w-full">
        <Button asChild size="sm" disabled={!canInviteUsers}>
          <Link
            to={
              subscriptionPlan === SpillSubscriptionPlan.STARTER
                ? "/admin/access/invite"
                : "/admin/access"
            }
          >
            Invite new user
          </Link>
        </Button>
      </div>
    )
  }

  const columns: ColumnDef<SupportTableRow>[] = [
    {
      accessorKey: "fullName",
      header: "Name",
      cell: ({ row }) => {
        const invite = row.original
        return <span className="block py-2">{invite.fullName}</span>
      },
    },
    {
      accessorKey: "inviteUrl",
      header: "Invite Link",
      cell: ({ row }) => {
        return (
          <SupportTableLink
            invite={row.original}
            hasActiveSubscription={hasActiveSubscription}
          />
        )
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const invite = row.original
        return <SupportTableAction invites={invite} total={data.length} />
      },
    },
  ]

  return (
    <DataTable
      data={data}
      columns={columns}
      showPagination={true}
      tableActions={<SupportTableActions />}
    />
  )
}
