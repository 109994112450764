import { FunctionComponent } from "react"
import { useQuery } from "@apollo/client"
import { H1, P, H2, Alert } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { Navigate } from "react-router-dom"
import {
  CalendarDateRangeIcon,
  ChatBubbleLeftIcon,
  Square2StackIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import imageParenthood from "common/assets/images/product/specialised-support/therapy-parenthood.png"
import imageBereavement from "common/assets/images/product/specialised-support/therapy-bereavement.png"
import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  PackageType,
  TherapyExtensionStatus,
  TherapySpecialisedSupportIndexQuery,
  TherapySpecialisedSupportIndexQueryVariables,
} from "types/graphql"
import { SpecialisedSupportItem } from "features/specialised-support/components/SpecialisedSupportItem"

import { queries } from "./therapy-specialised-support.queries"

export const TherapySpecialisedSupportPage: FunctionComponent = () => {
  const { data, called, loading } = useQuery<
    TherapySpecialisedSupportIndexQuery,
    TherapySpecialisedSupportIndexQueryVariables
  >(queries.getPackages)

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const packages = data?.user?.visibleUnclaimedTherapyPackages
  const therapyPackages = data?.user?.therapyPackages

  const bereavementPackage = therapyPackages?.find(packageDetails => {
    return packageDetails.identifier === PackageType.BEREAVEMENT.toString()
  })

  const parenthoodPackage = therapyPackages?.find(packageDetails => {
    return packageDetails.identifier === PackageType.PARENTHOOD.toString()
  })

  const hasBereavement = bereavementPackage !== undefined
  const hasParenthood = parenthoodPackage !== undefined
  const hasAdhdSupport =
    data?.user?.featuresAndSettings.adhdSupport.value ?? false

  if (!packages) {
    return <Navigate to="/" />
  }

  const packagesAvailable = [
    hasBereavement,
    hasParenthood,
    hasAdhdSupport,
    packages != null && packages.length > 0,
    therapyPackages != null && therapyPackages.length > 0,
  ].some(packages => packages === true)

  return (
    <>
      <Helmet title="Specialised support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex flex-col gap-4 max-w-screen-sm">
          <H1>Specialist support</H1>
          <H2>How it works</H2>
          <P muted>
            Your company may have set up additional therapy policies that are
            designed to support you through particular life events or
            situations. You can see which specialist support packages your
            company has authorised on this page.
          </P>
          <P muted>
            The options below are policies already set in place by your company.
          </P>
        </div>
        {!packagesAvailable ? (
          <div className="max-w-screen-sm">
            <Alert title="There are currently no packages available" />
          </div>
        ) : (
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
            {hasBereavement && (
              <SpecialisedSupportItem
                title="Bereavement support"
                subtitle="For grieving employees who might be on leave, or struggling on the anniversary of losing a loved one."
                list={[
                  {
                    Icon: UsersIcon,
                    text: "Sessions with a bereavement specialist",
                  },
                  {
                    Icon: Square2StackIcon,
                    text: "A default of 6 sessions per package",
                  },
                  {
                    Icon: CalendarDateRangeIcon,
                    text: "Sessions can be used over a period of two years",
                  },
                ]}
                primaryButton={{
                  text: "Get started",
                  href: "/therapy/specialised-support/bereavement",
                }}
                image={imageBereavement}
              />
            )}
            {hasParenthood && (
              <SpecialisedSupportItem
                title="Parenthood support"
                subtitle="For new parents going through the joys of welcoming a child and the challenges around returning to work."
                list={[
                  {
                    Icon: UsersIcon,
                    text: "Sessions with a parenthood specialist",
                  },
                  {
                    Icon: Square2StackIcon,
                    text: "A recommended 8 sessions per package",
                  },
                  {
                    Icon: CalendarDateRangeIcon,
                    text: "Sessions can be used over a period of two years",
                  },
                ]}
                primaryButton={{
                  text: "Get started",
                  href: "/therapy/specialised-support/parenthood",
                }}
                image={imageParenthood}
              />
            )}
            {hasAdhdSupport && (
              <SpecialisedSupportItem
                title="ADHD support"
                subtitle="For employees that experience symptoms of Attention Deficit Hyperactivity Disorder, whether they've had a diagnosis or not."
                list={[
                  {
                    Icon: UsersIcon,
                    text: "Sessions with an ADHD specialist",
                  },
                ]}
                primaryButton={{
                  text: "Get started",
                  href: "/therapy/specialised-support/adhd",
                }}
                image={imageAdhd}
              />
            )}
            {packages.map((pkg, index) => {
              return (
                <SpecialisedSupportItem
                  key={index}
                  title={pkg.name ?? "Custom package"}
                  subtitle={pkg.userFacingDescription ?? ""}
                  statusButton={
                    pkg.mostRecentRequest != null &&
                    pkg?.mostRecentRequest.status ===
                      TherapyExtensionStatus.PENDING
                      ? {
                          text: "Requested",
                          variant: "pending",
                        }
                      : undefined
                  }
                  primaryButton={{
                    text:
                      pkg.mostRecentRequest != null &&
                      pkg.mostRecentRequest.status ===
                        TherapyExtensionStatus.PENDING
                        ? "View package"
                        : "Get started",
                    href: "/therapy/specialised-support/" + pkg.id,
                  }}
                  image={pkg.iconUrl ?? ""}
                  list={[
                    {
                      Icon: ChatBubbleLeftIcon,
                      text: `${pkg.numberSessions} sessions`,
                    },
                    {
                      Icon: CalendarDateRangeIcon,
                      text:
                        pkg.numberMonthsToUse != null
                          ? `Support for ${pkg.numberMonthsToUse} months`
                          : "Support indefinitely",
                    },
                  ]}
                />
              )
            })}
            {therapyPackages?.map((pkg, index) => {
              return (
                <SpecialisedSupportItem
                  key={index}
                  title={pkg.companyPackageSetting?.name ?? "Custom package"}
                  subtitle={
                    pkg.companyPackageSetting?.userFacingDescription ?? ""
                  }
                  primaryButton={{
                    text: "View package",
                    href: "/therapy/specialised-support/" + pkg.identifier,
                  }}
                  image={pkg.companyPackageSetting?.iconUrl ?? ""}
                  list={[
                    {
                      Icon: ChatBubbleLeftIcon,
                      text: `${pkg.numberSessionsGiven} sessions`,
                    },
                  ]}
                />
              )
            })}
          </div>
        )}
      </section>
    </>
  )
}
