import { useEffect } from "react"

import { useDocumentHasFocus } from "common/hooks/useDocumentHasFocus"

import type { QueryResult } from "@apollo/client"

type UsePollWhenDocumentHasFocusProps = {
  /** Interval to poll at. */
  pollInterval: number
  /** Start polling on component mount. */
  pollOnMount?: boolean
  /** refetch function from @apollo/client useQuery or useLazyQuery. */
  refetch: QueryResult["refetch"]
  /** startPolling function from @apollo/client useQuery or useLazyQuery. */
  startPolling: QueryResult["startPolling"]
  /** stopPolling function from @apollo/client useQuery or useLazyQuery. */
  stopPolling: QueryResult["stopPolling"]
}

/**
 * Hook to toggle GQL query polling when document focus state changes.
 */
export function usePollWhenDocumentHasFocus({
  pollInterval,
  pollOnMount = true,
  refetch,
  startPolling,
  stopPolling,
}: UsePollWhenDocumentHasFocusProps): void {
  const documentHasFocus = useDocumentHasFocus()

  useEffect(() => {
    if (pollOnMount) startPolling(pollInterval)
    return () => stopPolling()
  }, [])

  useEffect(() => {
    if (documentHasFocus) {
      void refetch()
      startPolling(pollInterval)
    } else stopPolling()
  }, [documentHasFocus])
}
