import { Button, H2, H3, P, TextArea } from "@spillchat/puddles"
import { useEffect } from "react"

import { MultiStepFormStepProps } from "common/components/MultiStepForm/MultiStepFormStepProps"

import { PmiSetup } from "../PmiSetupForm.schema"
import { pmiProviderDetails } from "../PmiSetupForm.types"

export const PmiSetupInstructions: React.FunctionComponent<
  MultiStepFormStepProps<PmiSetup>
> = ({ form }: MultiStepFormStepProps<PmiSetup>) => {
  useEffect(() => {
    form.clearErrors("accessInstructions")
  }, [])

  return (
    <div className="flex flex-col gap-4">
      <H2>
        Add instructions for employees on how to access their health insurance
      </H2>
      <H3>Insurance provider</H3>
      <div className="flex items-center">
        <img
          src={pmiProviderDetails[form.getValues("provider")].image}
          alt={`${pmiProviderDetails[form.getValues("provider")].name} logo`}
          className="w-20 h-20 me-2 object-cover rounded-lg"
        />
        <P weight="medium">
          {pmiProviderDetails[form.getValues("provider")].name}
        </P>
      </div>
      <P>
        Provide details on where employees can find their account information
        (notably their policy details, their insurance membership or account
        number, and anything else that may be relevant)
      </P>
      <TextArea
        rows={5}
        className="h-auto"
        {...form.register("accessInstructions", {
          onChange: () => {
            form.clearErrors("accessInstructions")
          },
        })}
      />
      {form.formState.errors.accessInstructions?.message !== undefined && (
        <p className="font-inter text-red-400 text-sm font-medium tracking-[0.2px]">
          {form.formState.errors.accessInstructions.message}
        </p>
      )}
      <Button
        type="submit"
        variant="primary"
        loading={form.formState.isSubmitting}
      >
        Save
      </Button>
    </div>
  )
}
