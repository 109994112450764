import { useEffect, useState } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { useSearchParams } from "react-router-dom"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import { PageHeader } from "common/components/PageHeader"
import { Select } from "common/components/Select"
import { CrisisLineCard } from "features/resources/components/CrisisLineCard"
import { CrisisLineCategory, Region } from "types/graphql"

import type { ChangeEventHandler } from "react"
import type {
  CrisisLinesGetCrisisLinesQuery as CrisisLinesQueryData,
  CrisisLinesGetCrisisLinesQueryVariables as CrisisLinesQueryVariables,
  CrisisLinesGetEmergencyCrisisLineQuery as EmergencyCrisisLineQueryData,
  CrisisLinesGetEmergencyCrisisLineQueryVariables as EmergencyCrisisLineQueryVariables,
} from "types/graphql"

const fragments = {
  crisisLineFields: gql`
    fragment CrisisLinesCrisisLineFields on CrisisLine {
      id
      ...CrisisLineCardCrisisLineFields
    }
    ${CrisisLineCard.fragments.crisisLineFields}
  `,
}

const queries = {
  getEmergencyCrisisLine: gql`
    query CrisisLinesGetEmergencyCrisisLine($region: Region!) {
      crisisLines(category: Emergency, region: $region) {
        ...CrisisLinesCrisisLineFields
      }
    }
    ${fragments.crisisLineFields}
  `,
  getCrisisLines: gql`
    query CrisisLinesGetCrisisLines(
      $category: CrisisLineCategory
      $region: Region!
    ) {
      crisisLines(category: $category, region: $region) {
        ...CrisisLinesCrisisLineFields
      }
    }
    ${fragments.crisisLineFields}
  `,
}

export function CrisisLines(): JSX.Element {
  const [region, setRegion] = useState(Region.UK)

  const [searchParams, setSearchParams] = useSearchParams()
  const category = searchParams.get("category") as CrisisLineCategory

  const [getEmergencyCrisisLines, { data: emergencyCrisisLinesData }] =
    useLazyQuery<
      EmergencyCrisisLineQueryData,
      EmergencyCrisisLineQueryVariables
    >(queries.getEmergencyCrisisLine)

  const [
    getCrisisLines,
    { data: crisisLinesData, loading: crisisLinesLoading },
  ] = useLazyQuery<CrisisLinesQueryData, CrisisLinesQueryVariables>(
    queries.getCrisisLines
  )

  useEffect(() => {
    if (region !== undefined) {
      void getEmergencyCrisisLines({ variables: { region } })
    }
  }, [region])

  useEffect(() => {
    if (region !== undefined) {
      void getCrisisLines({ variables: { category, region } })
    }
  }, [category, region])

  const onChangeCategory: ChangeEventHandler<HTMLSelectElement> = e => {
    if (e.target.value === "All" && searchParams.has("category")) {
      searchParams.delete("category")
    } else searchParams.set("category", e.target.value)
    setSearchParams(searchParams, { replace: true })
  }

  const crisisLines = crisisLinesData?.crisisLines ?? []

  const subtitle = (() => {
    const emergencyLineDescription =
      emergencyCrisisLinesData?.crisisLines[0]?.description
    if (typeof emergencyLineDescription === "string") {
      return `${emergencyLineDescription} There are a number of other specialist lines you can call for help, too. We’ve made a list of them below.`
    }
    return "There are a number of specialist lines you can call for help. We’ve made a list of them below."
  })()

  return (
    <main className="p-0">
      <PageHeader title="Crisis lines" subtitle={subtitle} />

      <div className="flex">
        <Select onChange={onChangeCategory} value={category} className="grow">
          <option value="All">All</option>

          {Object.values(CrisisLineCategory)
            .filter(c => c !== CrisisLineCategory.Emergency)
            .map(category => (
              <option key={category} value={category}>
                {formatCategoryEnum(category)}
              </option>
            ))}
        </Select>

        <Select
          onChange={e => setRegion(e.target.value as Region)}
          value={region}
          className="ml-4"
        >
          {Object.values([Region.UK, Region.US]).map(region => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
        </Select>
      </div>

      {crisisLinesLoading ? (
        <div className="flex grow items-center justify-center">
          <LoadingSpinner sizeInPixels={64} />
        </div>
      ) : (
        <>
          {crisisLines.length === 0 ? (
            <p>No results, select another category</p>
          ) : (
            <div className="columns-1 sm:columns-2 md:columns-1 lg:columns-2 gap-md space-y-md">
              {crisisLines.map(crisisLine => (
                <CrisisLineCard
                  key={crisisLine.id}
                  crisisLineId={crisisLine.id}
                />
              ))}
            </div>
          )}
        </>
      )}
    </main>
  )
}

function formatCategoryEnum(str: string): string {
  return str
    .replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, s => s.charAt(0).toUpperCase() + s.substring(1))
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
}
