import { gql } from "@apollo/client"

export const mutations = {
  completeAdminOnboarding: gql`
    mutation OnboardingModalCompleteAdminOnboarding {
      completeAdminOnboarding {
        id
      }
    }
  `,
}
