import { useFormContext } from "react-hook-form"
import { useEffect } from "react"

import { V7FieldValues } from "features/pulse/types"
import {
  BurnoutQuestion,
  BURNOUT_LEVELS,
} from "features/pulse/constants/burnoutQuestions"

import { useAnalyticsPulse } from "../hooks/useAnalyticsPulse"

type V7BurnoutQuestionProps = {
  question: BurnoutQuestion
}

export function V7BurnoutQuestion({
  question,
}: V7BurnoutQuestionProps): JSX.Element {
  const { track } = useAnalyticsPulse()
  const { trigger, watch } = useFormContext<V7FieldValues>()

  watch(question.fieldName)

  useEffect(() => {
    track("User opened burnout question", { questionTitle: question.title })
    void trigger([question.fieldName])
  }, [])

  return (
    <div className="flex flex-col gap-md items-center max-w-lg w-full">
      <header className="cursor-default w-full">
        <h2 className="leading-snug text-grey-100">{question.title}</h2>
        <p className="text-grey-100">{question.text}</p>
      </header>

      <div className="flex flex-col gap-sm w-full">
        {BURNOUT_LEVELS.map((text, key) => (
          <RadioInput key={key} value={key} text={text} question={question} />
        ))}
      </div>
    </div>
  )
}

type RadioInputProps = {
  value: number
  text: string
  question: BurnoutQuestion
}

function RadioInput({ value, text, question }: RadioInputProps) {
  const { track } = useAnalyticsPulse()
  const { register, getValues } = useFormContext<V7FieldValues>()

  return (
    <label
      key={value}
      className="btn-neutral-dark btn-lg justify-start text-left w-full"
    >
      <input
        className="peer opacity-0 h-0 w-0 pointer-events-none"
        type="radio"
        value={value}
        {...register(question.fieldName, {
          onChange: () => {
            const newRating = getValues(question.fieldName)
            track("User selected burnout rating", {
              question: question.title,
              rating: newRating,
            })
          },
          validate: value => value !== null,
        })}
      />
      {text}
    </label>
  )
}
