import { FunctionComponent, PropsWithChildren } from "react"

interface ErrorInfoProps extends PropsWithChildren {
  description: string
  title?: string
}

export const ErrorInfo: FunctionComponent<ErrorInfoProps> = ({
  children,
  description,
  title = "Unable to reschedule this session",
}) => (
  <div className="flex flex-col gap-6 items-center justify-center min-h-screen p-8 text-center w-full">
    <header className="space-y-2">
      <h1 className="text-4xl">{title}</h1>
      <p className="whitespace-pre">{description}</p>
    </header>
    {children}
  </div>
)
