import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { Button } from "@spillchat/puddles"
import { FormEvent } from "react"

type FeedbackFormProps = {
  feedbackOn: string
  score: number | null
  comment: string
  setScore: (p: number | null) => void
  setComment: (p: string) => void
  showError: boolean
  onSubmit: (event: FormEvent) => Promise<void>
}

export function FeedbackFormTriage({
  feedbackOn,
  score,
  setScore,
  comment,
  setComment,
  showError,
  onSubmit,
}: FeedbackFormProps): JSX.Element {
  return (
    <main className="h-full w-full justify-center items-center">
      <div className="text-center">
        <h2>
          Give us feedback on {feedbackOn}
          <br />
          We read every piece of feedback we get!
        </h2>
      </div>

      <form className="flex flex-col gap-md" onSubmit={onSubmit}>
        <textarea
          className="box tracking-wide grow h-32 p-sm focus:outline-grey-600 overflow-auto"
          placeholder="What would you like Spill to know?"
          required
          value={comment}
          onChange={event => void setComment(event.currentTarget.value)}
        />

        <div className="box bg-opacity-95 flex flex-col gap-sm">
          <label htmlFor="score" className="h5">
            How likely are you to recommend Spill?
          </label>
          <div className="w-full grid grid-cols-4 md:grid-cols-11 gap-sm sm:gap-xs">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => (
              <label key={value} className="aspect_square flex cursor-pointer">
                <input
                  className="peer opacity-0 h-0 w-0 pointer-events-none"
                  id={value.toString()}
                  type="radio"
                  name="score"
                  value={value}
                  readOnly
                  required
                  checked={score === value}
                  onClick={event =>
                    void setScore(parseInt(event.currentTarget.value))
                  }
                />
                <span className="bg-grey-200 flex flex-col outline outline-2 outline-transparent hover:transition-all peer-checked:bg-yellow-400  hover:outline-grey-600 hover:bg-yellow-100 items-center justify-center peer-focus:outline-grey-600 p-2 sm:p-4 peer-checked:bg-opacity-100 rounded-lg w-full">
                  {value}
                </span>
              </label>
            ))}
          </div>
        </div>
        <Button type="submit">Submit</Button>
      </form>
      <div className="sm:h-12">
        {/* Always have fixed-height, so the page doesn't "jump" when error message is shown */}
        {showError && (
          <div className="text-red-400 text-center">
            <p className="">
              <ExclamationTriangleIcon className="inline mx-1 size-3" />
              Something went wrong while submitting your feedback. Please try
              again.
            </p>
            <p className="p-2 text-sm italic">
              If the issue persists, contact us at{" "}
              <a href="mailto:real-person@spill.chat" className="text-blue-900">
                hi@spill.chat
              </a>
            </p>
          </div>
        )}
      </div>
    </main>
  )
}
