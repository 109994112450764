import { z } from "zod"

import { pmiProviders } from "./PmiSetupForm.types"

export const pmiSetupSchema = z.object({
  provider: z.enum(pmiProviders, {
    message: "Please select a provider from the list",
  }),
  accessInstructions: z.string().min(1, {
    message: "Please provide some access instructions",
  }),
})

export type PmiSetup = z.infer<typeof pmiSetupSchema>
