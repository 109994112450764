import { Button, Form, H1, Input } from "@spillchat/puddles"
import { useEffect } from "react"

import { MultiStepFormStepProps } from "common/components/MultiStepForm/MultiStepFormStepProps"

import { QuoteSignupForm } from "./QuoteSignup.schema"

export const QuoteSignupName: React.FunctionComponent<
  MultiStepFormStepProps<QuoteSignupForm>
> = ({ form }: MultiStepFormStepProps<QuoteSignupForm>) => {
  useEffect(() => {
    form.clearErrors("firstName")
  }, [])

  return (
    <div className="flex flex-col items-center gap-8">
      <H1>First things first, what's your first name?</H1>
      <div className="space-y-8 max-w-sm w-full">
        <Form.Field
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <Form.Item>
              <Form.Control>
                <Input {...field} placeholder="First name" />
              </Form.Control>
              <Form.Message />
            </Form.Item>
          )}
        />
        <Button
          type="submit"
          variant="primary"
          loading={form.formState.isSubmitting}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
