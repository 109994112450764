import { Button, H2, P, Select } from "@spillchat/puddles"
import { useEffect } from "react"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

import { MultiStepFormStepProps } from "common/components/MultiStepForm/MultiStepFormStepProps"
import { useUser } from "common/context/userContext"

import { PmiSetup } from "../PmiSetupForm.schema"
import { pmiProviderDetails, PmiProviderType } from "../PmiSetupForm.types"

export const PmiSetupProvider: React.FunctionComponent<
  MultiStepFormStepProps<PmiSetup>
> = ({ form }: MultiStepFormStepProps<PmiSetup>) => {
  const user = useUser()

  useEffect(() => {
    form.clearErrors("provider")
  }, [])

  return (
    <div className="flex flex-col gap-4">
      <H2>Add your insurance provider</H2>
      <P>
        Don't see your insurance provider here?{" "}
        <Button variant="tertiary" asChild>
          <a
            href={`https://spillchat.typeform.com/to/lOEazBbD#user_id=${user.id ?? ""}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Get in touch
          </a>
        </Button>
      </P>
      <div className="flex flex-col gap-2 my-5">
        <Select.Root
          defaultValue={form.getValues("provider") ?? ""}
          onValueChange={value => {
            form.clearErrors("provider")
            form.setValue("provider", value as PmiProviderType)
          }}
        >
          <Select.Trigger className="h-fit [&>span]:line-clamp-none">
            <Select.Value placeholder="Select provider" />
          </Select.Trigger>
          <Select.Content className="w-[calc(100vw-3rem)] [@media(min-width:560px)]:w-[32rem]">
            {Object.values(pmiProviderDetails).map(details => (
              <Select.Item
                key={details.name}
                value={details.name}
                className="-ms-6 w-[33.5rem]"
              >
                <div className="flex items-center h-10">
                  <img
                    src={details.image}
                    alt={`${details.name} logo`}
                    className="w-10 h-10 z-10 me-2 object-cover rounded-lg"
                  />
                  <P weight="medium">{details.name}</P>
                </div>
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
        {form.formState.errors.provider?.message !== undefined && (
          <p className="font-inter text-red-400 text-sm font-medium tracking-[0.2px]">
            {form.formState.errors.provider.message}
          </p>
        )}
      </div>
      <div className="flex gap-1 items-center">
        <InformationCircleIcon className="size-4" />
        <P>
          Read more about how the integration will work while it's in beta{" "}
          <Button variant="tertiary" asChild>
            <a
              href="https://spill.notion.site/How-Spill-works-with-private-medical-insurance-7eaf9b1ef5894717915bf5b259cd1122"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </Button>
        </P>
      </div>
      <Button type="submit" variant="primary">
        Next
      </Button>
    </div>
  )
}
