import { FunctionComponent } from "react"

import { LoadingSpinner } from "common/components/LoadingSpinner"

export const PmiLoadingCard: FunctionComponent = () => {
  return (
    <div className="col-span-2">
      <div className="flex h-full w-full flex-col justify-center items-center rounded-lg border border-spill-grey-200 bg-card p-6 text-card-foreground">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    </div>
  )
}
