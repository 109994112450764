import { z } from "zod"

export const formSchema = z.object({
  userId: z.string().min(1, {
    message: "Please select an employee",
  }),
  notificationType: z.enum(["email", "none"]),
  note: z
    .string()
    .max(250, {
      message: "Please limit your note to 250 characters",
    })
    .optional(),
})

export type InviteBereavementForm = z.infer<typeof formSchema>
