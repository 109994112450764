import { H1, H4 } from "@spillchat/puddles"
import { useMutation, useQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"

import {
  QuoteSignupGetUserQuery,
  QuoteSignupGetUserQueryVariables,
  QuoteSignupNeedsMutation,
  QuoteSignupNeedsMutationVariables,
  QuoteSignupUpdateCompanySessionPackMutation,
  QuoteSignupUpdateCompanySessionPackMutationVariables,
  SpillSubscriptionPlan,
} from "types/graphql"
import { OTPInput } from "common/components/OTPInput"
import { mutations } from "features/auth/components/QuoteSignup.mutations"
import { useAuth } from "common/context/authContext"
import { queries } from "features/auth/components/QuoteSignup.queries"
import { useAnalytics } from "common/context/analyticsContext"
import { getSessionPackForNumberEmployees } from "features/admin/helpers/sessionPack"

import { QuoteSignupForm } from "./QuoteSignup.schema"

type QuoteSignupAuthProps = QuoteSignupForm & { onCancel: () => void }

export const QuoteSignupAuth: React.FunctionComponent<QuoteSignupAuthProps> = ({
  types,
  email,
  numberEmployees,
  onCancel,
}: QuoteSignupAuthProps) => {
  const { track } = useAnalytics()
  const { user } = useAuth()
  const navigate = useNavigate()

  const [createCompanyTherapyQuote] = useMutation<
    QuoteSignupNeedsMutation,
    QuoteSignupNeedsMutationVariables
  >(mutations.createCompanyTherapyQuote)

  const [updateCompanySessionPack] = useMutation<
    QuoteSignupUpdateCompanySessionPackMutation,
    QuoteSignupUpdateCompanySessionPackMutationVariables
  >(mutations.updateCompanySessionPack)

  const { refetch } = useQuery<
    QuoteSignupGetUserQuery,
    QuoteSignupGetUserQueryVariables
  >(queries.getUser, {
    skip: user == null,
    fetchPolicy: "network-only",
  })

  const handleOTPSuccess = async () => {
    await refetch()
    await createCompanyTherapyQuote({
      variables: {
        therapyNeeds: types,
        numberEmployees: Number(numberEmployees),
        recommendedPlan:
          numberEmployees === 1
            ? SpillSubscriptionPlan.STARTER
            : SpillSubscriptionPlan.ESSENTIAL,
      },
    })

    const sessionPack = getSessionPackForNumberEmployees(numberEmployees)
    // if the company is on the Essential plan, we want to also set their recommended session pack value
    await updateCompanySessionPack({
      variables: {
        sessionPack: sessionPack?.sessionCount ?? 1,
      },
    })
    track("Completed Step of Quote Signup", { step: "Create Quote" })
    navigate("/admin/quote")
  }

  return (
    <div className="flex flex-col items-center gap-8 max-w-screen-sm">
      <H1>Confirm your email address</H1>
      <H4>
        We've sent a 9-character code to {email}. The code expires shortly so
        please enter it soon.
      </H4>
      <div className="space-y-4 w-full max-w-sm">
        <OTPInput
          align="center"
          email={email}
          onOTPSuccess={async () => await handleOTPSuccess()}
          onOTPFailure={() => onCancel()}
        />
      </div>
    </div>
  )
}
