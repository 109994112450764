import { useState, FunctionComponent } from "react"
import { attemptRefreshingSession } from "supertokens-auth-react/recipe/session"

import { LinkUserLanding } from "../components/LinkUserLanding"
import { LinkUserEnterCode } from "../components/LinkUserEnterCode"

export const LinkUser: FunctionComponent = () => {
  const [hasSentCodes, setHasSentCodes] = useState(false)

  const onSentCodes = () => {
    setHasSentCodes(true)
  }

  const onEitherFlowFinished = () => {
    void attemptRefreshingSession().then(() => window.location.assign("/"))
  }

  if (hasSentCodes) {
    return <LinkUserEnterCode onUserMerged={onEitherFlowFinished} />
  }
  return (
    <LinkUserLanding
      onCodesSent={onSentCodes}
      onMergeAborted={onEitherFlowFinished}
    />
  )
}
