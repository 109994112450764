import { FunctionComponent, useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { useFormContext } from "react-hook-form"
import { pick } from "lodash"
import { Button, H2, H3, Input, Label, P } from "@spillchat/puddles"

import { FEELINGS, MOOD_IMAGE_MAP } from "features/pulse/constants/emotions"
import { useAnalyticsPulse } from "features/pulse/hooks/useAnalyticsPulse"
import {
  Feeling,
  Meeting,
  SharingQuestionGetUserQuery as QueryData,
  SharingQuestionGetUserQueryVariables as QueryVars,
} from "types/graphql"
import { V5FieldValues } from "features/pulse/types"

export const fragments = {
  queryFields: gql`
    fragment SharingQuestionQueryFields on Query {
      user {
        id
        displayName
      }
    }
  `,
}

export const queries = {
  getUser: gql`
    query SharingQuestionGetUser {
      ...SharingQuestionQueryFields
    }
    ${fragments.queryFields}
  `,
}

interface SharingQuestionProps {
  meetingUrlId: Meeting["urlId"]
  prevQuestion: () => void
}

export const SharingQuestion: FunctionComponent<
  SharingQuestionProps
> = props => {
  const { track } = useAnalyticsPulse()
  const { formState, register, setValue, watch } =
    useFormContext<V5FieldValues>()

  const { data } = useQuery<QueryData, QueryVars>(queries.getUser)

  const feelings = watch("feelings")
  const isMoodShared = watch("isMoodShared")
  const mood = watch("mood")
  const otherFeelings = watch("otherFeelings")
  const sharedFeelings = watch("sharedFeelings")
  const otherFeelingsShared = watch("otherFeelingsShared")

  const [hasSelected, setHasSelected] = useState(false)

  const isSomethingShared =
    sharedFeelings.length > 0 || isMoodShared || otherFeelingsShared.length > 0

  useEffect(() => {
    setHasSelected(false)
    setValue("isMoodShared", false)
    setValue("sharedFeelings", [])
    setValue("otherFeelingsShared", [])
    track("User Opened Feelings Sharer")
  }, [])

  useEffect(() => {
    if (typeof data?.user?.displayName === "string") {
      setValue("userName", data.user.displayName, { shouldValidate: true })
    }
  }, [data?.user])

  const feelingsList = pick(FEELINGS, feelings)

  const isEverythingSelected =
    feelings.every(i => sharedFeelings.includes(i)) &&
    isMoodShared === true &&
    otherFeelings.length == otherFeelingsShared.length
  const isNothingSelected =
    sharedFeelings.filter(v => typeof v === "string").length === 0 &&
    isMoodShared === false &&
    otherFeelings.length == 0

  const selectEverything = (): void => {
    setHasSelected(true)
    setValue("isMoodShared", true)
    setValue("sharedFeelings", feelings)
    setValue("otherFeelingsShared", otherFeelings)
    track("User Selected Share Feeling", { Choice: "Everything" })
  }

  const selectNothing = (): void => {
    setHasSelected(true)
    setValue("isMoodShared", false)
    setValue("sharedFeelings", [])
    setValue("otherFeelingsShared", [])
    track("User Selected Share Feeling", { Choice: "Nothing" })
  }

  return (
    <main className="justify-center">
      <div className="flex flex-col gap-2">
        <H2>How much do you want to share?</H2>
        <P>
          Spill will always be able to see your answers, but you can choose to
          share some feelings with your team.
        </P>
      </div>

      <div className="box flex flex-col gap-md">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-sm">
          <button
            className={`border flex flex-col gap-1 col-span-2 cursor-pointer p-4 rounded-lg text-left ${
              hasSelected && isEverythingSelected
                ? "bg-teal-400 border-teal-600 text-mono-white"
                : "border-teal-400 hover:bg-teal-100 text-teal-600"
            }`}
            onClick={selectEverything}
            type="button"
          >
            <H3>Everything</H3>
            <P>
              Share all of today&apos;s responses with your team and company
              admins.
            </P>
          </button>

          <button
            className={`cursor-pointer flex flex-col gap-1 border col-span-2 p-4 rounded-lg bg-red-light text-left ${
              hasSelected && isNothingSelected
                ? "bg-red-400 border-red-600 text-mono-white"
                : "border-red-400 hover:bg-red-100 text-red-600"
            }
            `}
            onClick={selectNothing}
            type="button"
          >
            <H3>Nothing</H3>
            <P>Don’t share anything with your team or company admins.</P>
          </button>

          {mood !== null && (
            <label className="cursor-pointer flex">
              <input
                className="peer opacity-0 h-0 w-0 pointer-events-none"
                type="checkbox"
                {...register("isMoodShared", {
                  onChange: () => {
                    setHasSelected(true)
                  },
                })}
                onKeyDown={event => {
                  if (event.key === "Enter") event.preventDefault()
                }}
                onSubmit={event => event.preventDefault()}
              />
              <div className="bg-grey-100 flex flex-col hover:transition-all peer-checked:bg-yellow-400 hover:bg-yellow-200 items-center justify-center p-2 sm:p-4 peer-checked:bg-opacity-100 rounded-lg w-full">
                <img
                  alt={`a mood of ${mood}`}
                  className="aspect-square h-12 sm:h-16 select-none"
                  src={MOOD_IMAGE_MAP[mood]}
                />
                <p>{mood}/10</p>
              </div>
            </label>
          )}

          {/**
           * Checkbox arrays with single checkbox set field value as [false] instead of [] when deselecting all checkboxes.
           * This hack ensures field value is [] instead not [false] when all checkboxes unselected.
           * @see https://github.com/react-hook-form/react-hook-form/issues/476#issuecomment-553849830
           */}
          <input
            type="checkbox"
            className="hidden"
            {...register("sharedFeelings")}
          />

          {(Object.keys(feelingsList) as Feeling[]).map(feelingKey => (
            <FeelingCheckbox
              key={feelingKey}
              feelingKey={feelingKey}
              setHasSelected={setHasSelected}
            />
          ))}

          {otherFeelings.map((feelingKey, index) => (
            <OtherFeelingCheckbox
              key={index}
              feelingKey={feelingKey}
              setHasSelected={setHasSelected}
            />
          ))}

          {hasSelected && isSomethingShared && (
            <div className="flex flex-col gap-2 justify-start items-start">
              <Label htmlFor="display-name">Display name</Label>
              <Input
                disabled={!isSomethingShared}
                id="display-name"
                type="text"
                {...register("userName", {
                  required: hasSelected && isSomethingShared,
                  pattern: /^(\p{L}+\s+)*\p{L}+\s*$/u,
                  maxLength: 30,
                  minLength: 2,
                })}
              />
            </div>
          )}
        </div>

        <footer className="flex gap-sm justify-between">
          <Button
            variant="secondary"
            onClick={props.prevQuestion}
            type="button"
          >
            Back
          </Button>
          <Button
            disabled={
              !formState.isValid || !hasSelected || formState.isSubmitting
            }
            type="submit"
          >
            Continue
          </Button>
        </footer>
      </div>
    </main>
  )
}

interface FeelingCheckboxProps {
  feelingKey: Feeling
  setHasSelected: (value: boolean) => void
}

const FeelingCheckbox: FunctionComponent<FeelingCheckboxProps> = props => {
  const { track } = useAnalyticsPulse()
  const { register } = useFormContext<V5FieldValues>()

  const {
    adjective,
    noun,
    src,
  }: { adjective: string; noun: string; src: string } =
    FEELINGS[props.feelingKey]

  return (
    <label className="cursor-pointer flex">
      <input
        className="peer opacity-0 h-0 w-0 pointer-events-none"
        type="checkbox"
        value={noun}
        onKeyDown={event => {
          if (event.key === "Enter") event.preventDefault()
        }}
        {...register("sharedFeelings", {
          onChange: ({ target: { checked, value } }) => {
            props.setHasSelected(true)
            // react-hook-form onChange typing is weak, hence the assertion.
            // This is what they suggest in their docs unfortunately.
            track(
              (checked as boolean) === true
                ? "User Selected Share Feeling"
                : "User Unselected Share Feeling",
              { Choice: "Custom", Feeling: value as string }
            )
          },
        })}
        onSubmit={event => event.preventDefault()}
      />
      <div className="bg-grey-100 flex flex-col hover:transition-all peer-checked:bg-yellow-400 hover:bg-yellow-200 items-center justify-center p-2 sm:p-4 peer-checked:bg-opacity-100 rounded-lg w-full">
        <img
          alt={`${adjective} emoji`}
          className="aspect-square h-12 sm:h-16 select-none"
          src={src}
        />
        <p className="capitalize">{adjective}</p>
      </div>
    </label>
  )
}

interface OtherFeelingCheckboxProps {
  feelingKey: string
  setHasSelected: (value: boolean) => void
}

const OtherFeelingCheckbox: FunctionComponent<
  OtherFeelingCheckboxProps
> = props => {
  const { track } = useAnalyticsPulse()
  const { register } = useFormContext<V5FieldValues>()

  return (
    <label className="cursor-pointer flex">
      <input
        className="peer opacity-0 h-0 w-0 pointer-events-none"
        type="checkbox"
        value={props.feelingKey}
        onKeyDown={event => {
          if (event.key === "Enter") event.preventDefault()
        }}
        {...register("otherFeelingsShared", {
          onChange: ({ target: { checked, value } }) => {
            props.setHasSelected(true)
            // react-hook-form onChange typing is weak, hence the assertion.
            // This is what they suggest in their docs unfortunately.
            track(
              (checked as boolean) === true
                ? "User Selected Share Feeling"
                : "User Unselected Share Feeling",
              { Choice: "Custom", Feeling: value as string }
            )
          },
        })}
        onSubmit={event => event.preventDefault()}
      />
      <div className="bg-grey-100 flex flex-col hover:transition-all peer-checked:bg-yellow-400 hover:bg-yellow-200 items-center justify-center p-2 sm:p-4 peer-checked:bg-opacity-100 rounded-lg w-full">
        <p className="capitalize">{props.feelingKey}</p>
      </div>
    </label>
  )
}
