import { motion } from "framer-motion"

import type { FunctionComponent } from "react"

interface AnimatedBlobProps {
  fill: string
  values: string
}

export const AnimatedBlob: FunctionComponent<AnimatedBlobProps> = props => (
  <motion.svg
    animate={{ originX: "50%", originY: "50%", opacity: 1, scale: 1 }}
    initial={{ originX: "50%", originY: "50%", opacity: 0, scale: 0.8 }}
    transition={{ damping: 100, type: "spring" }}
    version="1.1"
    viewBox="0 0 500 500"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path className="origin-center" fill={props.fill}>
      <animate
        attributeName="d"
        dur="60s"
        repeatCount="indefinite"
        values={props.values}
      />
    </path>
  </motion.svg>
)
