/**
 * The Get help page.
 * Allows the user to get the email address to send a support request to Spill.
 * Or allows them to use the RequestCallback modal to request a callback from Spill.
 */

import { Helmet } from "react-helmet-async"
import { gql, useQuery } from "@apollo/client"
import {
  ActionCard,
  Button,
  H1,
  H4,
  Input,
  Label,
  TextArea,
  Select,
} from "@spillchat/puddles"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { toast } from "sonner"
import { BookOpenIcon } from "@heroicons/react/24/outline"

import { GetHelpGetUserQuery, UserRole } from "types/graphql"
import { FAQ } from "common/components/FAQ"

export const fragments = {
  queryFields: gql`
    fragment GetHelpQueryFields on Query {
      user {
        id
        primaryEmail
        displayName
        role
      }
    }
  `,
}

export const queries = {
  getUser: gql`
    query GetHelpGetUser {
      ...GetHelpQueryFields
    }
    ${fragments.queryFields}
  `,
}

interface GetHelpFormValues {
  topic: string
  email: string
  body: string
  attachments: FileList
  userId: string
  userRole: UserRole
}

export const GetHelp: React.FunctionComponent<{ frontFormUrl: string }> = ({
  frontFormUrl,
}: {
  frontFormUrl: string
}) => {
  const [formCode, setFormCode] = useState<"error" | "success" | null>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const [searchParams] = useSearchParams()
  const defaultTopic = searchParams.get("topic")
  const { handleSubmit, register, setValue } = useForm<GetHelpFormValues>({
    defaultValues: {
      topic: "",
      email: "",
      body: "",
      userId: "",
    },
  })
  const { data } = useQuery<GetHelpGetUserQuery>(queries.getUser, {
    fetchPolicy: "cache-first",
    onCompleted: data => {
      if (data.user != null) {
        setValue("userId", data.user.id)
        setValue("userRole", data.user.role)
        setValue("email", data.user.primaryEmail ?? "")
      }
    },
  })

  useEffect(() => {
    const currentCode = searchParams.get("code")
    if (currentCode !== null) {
      setFormCode(currentCode as "error" | "success")
      if (currentCode === "success") {
        formRef.current?.reset()
      }
      if (currentCode === "error") {
        toast.error("Something went wrong. Please try again.")
      }
    }
  }, [searchParams])

  const onSubmit = () => formRef.current?.submit()

  const topics = [
    { label: "Pick an option", value: "–", disabled: true },
    { label: "Access to therapy", value: "therapy_access" },
    { label: "A technical issue", value: "technical_issue" },
    ...(data?.user?.role === UserRole.ADMIN
      ? [{ label: "A billing question", value: "billing" }]
      : []),
    { label: "Something else", value: "something_else" },
  ]

  if (formCode === "success") {
    return (
      <>
        <Helmet title="Help | Spill" />
        <H1>Get in touch</H1>
        <div className="flex flex-col space-y-10">
          <p className="text-spill-grey-200">
            Thank you for getting in touch. We will get back to you as soon as
            possible.
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet title="Help | Spill" />

      <H1>Help and support</H1>
      <div className="grid lg:grid-cols-12 w-full">
        <div className="flex flex-col col-span-4 gap-8">
          <H4 sectionHeader>Get help from Spill</H4>
          <form
            ref={formRef}
            method="POST"
            action={frontFormUrl}
            encType="multipart/form-data"
            className="flex flex-col gap-4 max-w-sm"
            acceptCharset="utf-8"
          >
            <div className="flex flex-col gap-2">
              <Label htmlFor="topic">What do you need help with?</Label>
              <Select.Root
                {...register("topic")}
                defaultValue={defaultTopic ?? ""}
              >
                <Select.Trigger>
                  <Select.Value placeholder="Pick an option" defaultValue="–" />
                </Select.Trigger>
                <Select.Content>
                  {topics.map(topic => {
                    return (
                      <Select.Item
                        key={topic.value}
                        value={topic.value}
                        disabled={topic.disabled}
                      >
                        {topic.label}
                      </Select.Item>
                    )
                  })}
                </Select.Content>
              </Select.Root>
            </div>

            <Input
              type="email"
              label={{
                children: "Email",
                alignment: "left",
              }}
              {...register("email", { required: true })}
              placeholder="name@company.com"
            />

            <TextArea
              label={{ children: "Message" }}
              {...register("body", { required: true })}
              placeholder="Please describe your issue here."
              className="h-50"
              rows={5}
            />

            <div className="">
              <label className="flex" htmlFor="attachments">
                <input
                  type="file"
                  id="attachments"
                  className="py-2 block w-full text-sm text-grey-600 font-inter file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:bg-spill-grey-50 file:text-spill-mono-black hover:file:bg-spill-grey-200 file:cursor-pointer"
                  {...register("attachments")}
                />
              </label>
            </div>

            {/* HIDDEN INPUTS  */}
            {data?.user?.id !== null && (
              <input
                {...register("userId")}
                type="hidden"
                value={data?.user?.id}
              />
            )}
            {data?.user?.role !== null && (
              <input
                {...register("userRole")}
                type="hidden"
                value={data?.user?.role}
              />
            )}
            <div>
              <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
            </div>
          </form>
        </div>
        <div className="flex flex-col gap-8 col-span-6 col-start-6">
          <H4 sectionHeader>Browse questions we've had before</H4>
          <ActionCard
            title="Guide to Spill therapy"
            description="Read our guide on how Spill's mental health support works. It covers the types of therapy we provide, how we choose our therapists, and why our therapy will always be delivered by a human."
            style={{ width: "100%" }}
            action={{
              onClick: () => {
                window.open(
                  "https://spill.notion.site/How-Spill-s-mental-health-support-works-8ae646a05bff4a368578725cfd807bd6?pvs=74",
                  "_blank"
                )
              },
              type: "feature",
            }}
            icon={<BookOpenIcon />}
          />
          <FAQ />
        </div>
      </div>
    </>
  )
}
