import { gql } from "@apollo/client"

export const mutations = {
  turnOnPmiSettingAndAddDetails: gql`
    mutation PmiSetupFormTurnOnPmiSettingAndAddDetails(
      $provider: String!
      $userInstructions: String!
      $userTherapyCap: Int
    ) {
      updateCompanySettings(pmi: true, userTherapyCap: $userTherapyCap) {
        id
        pmi {
          active
          value
        }
      }
      setPmi(provider: $provider, userInstructions: $userInstructions) {
        id
        provider
        userInstructions
      }
    }
  `,
}
